import React, { Component } from 'react';
import AmCharts from "@amcharts/amcharts3-react";

class Serial extends Component {



  render() {

    const { unc, pnm, others } = this.props

    const config = {
      "type": "serial",
      "theme": "none",
      "dataProvider": [{
        "country": 'UNC',
        "visits": unc,
        "color": "#FFCC00",
        "bullet": "https://www.amcharts.com/lib/images/faces/A04.png"
      }, {
        "country": "PNM",
        "visits": pnm,
        "color": "#FF3300",
        "bullet": "https://www.amcharts.com/lib/images/faces/A04.png"
      }, {
        "country": "Others",
        "visits": others,
        "color": "#848484",
        "bullet": "https://www.amcharts.com/lib/images/faces/A04.png"
      }],
      "valueAxes": [{
        "gridAlpha": 0,
        "tickLength": 0,
        "labelsEnabled": false,
        "axisThickness": 0,
      }],
      "gridAboveGraphs": true,
      "startDuration": 1,
      "colorField": "#ff0000",
      "graphs": [{
        "fillColorsField": "color",
        "balloonText": "[[category]]: <b>[[value]]</b> PDs",
        "fillAlphas": 1,
        "lineAlpha": 0.2,
        "type": "column",
        "valueField": "visits",
        "labelText": "[[value]]",
        "fontSize": 20,
      }],
      "categoryField": "country",
      "categoryAxis": {
        "gridPosition": "start",
        "gridAlpha": 0,
        "tickPosition": "start",
        "tickLength": 20,
        "dashLength": 0,
        "fontSize": 14,
      },
    };

    return (
      <div className="winningSerial">
        <div className="ovalvalue"><span> Total PDs</span><br />{unc + pnm + others}</div>
        <AmCharts.React className="amchart_serial" options={config} />
      </div>
    )

  }
}

export default Serial;
