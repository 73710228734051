import React, { Component } from 'react';
import Header from '../Includes/header';
import PieChart from '../Components/PieChart';
import NewsFlash from './NewsFlash';
import Map from '../Components/Map';



class LiveCount extends Component{
    constructor(props){
        super(props);
        this.state = {
            hideSidebar: true
        }
      }

      showPanel(){
        this.setState({hideSidebar: !this.state.hideSidebar})
     }


  render(){
    let ToggleID = this.state.hideSidebar ? "hide" : "show";

    return (
      <div>
        <div className="nav_panel">
             <button className="btn" onClick={this.showPanel.bind(this)}><i className="fa fa-bars"></i></button>
            <h3>Live Count</h3>
        </div>
        <div className="sideBar" id={ToggleID}>
            <Header />
        </div>

          <div className="container-fluid page page_live_count" style={{marginTop:'7rem'}}>
              <div className="row">
                <div className="col-sm-12 col-lg-10 offset-1 sm-m-0 sm_100">
                        <div className="row count_row">
                            <div className="col-sm-2 border-right">
                                <div className="count_head text-center" style={{marginTop:'-2px'}}>Total Vote Share:</div>
                            </div>
                            <div className="col-sm-3 border-right">
                                <div className="count_head text-center">PNM <br /> 45</div>
                                <div className="count_up"><img src="../img/up-arrow.png" className="img-fluid" /></div>
                            </div>
                            <div className="col-sm-3 border-right">
                                <div className="count_head text-center">UNC <br /> 23</div>
                                <div className="count_up"><img src="../img/down-arrow.png" className="img-fluid" /></div>
                            </div>
                            <div className="col-sm-2 border-right">
                                    <div className="count_head text-center">COP <br /> 23</div>
                            </div>
                            <div className="col-sm-2">
                                    <div className="count_head text-center">Others <br /> 23</div>
                            </div>
                        </div>
                </div>
              </div>



              <div className="row">

                  <div className="col-sm-6 offset-1 sm-60 sm-m-0">
                        <div className="mapshadow_box">
                            <div className="map_cont">
                                {/* <img src="img/trinidadAndTobagoHigh.svg" className="img-fluid" /> */}
                                <Map />
                            </div>
                        </div>
                  </div>

                  <div className="col-sm-4 form_field sm-40">
                        <div className="const_chart">
                                <h3>West Trinidad</h3>
                                <PieChart />
                        </div>

                        <div className="candi_carou">
                               <NewsFlash />
                        </div>
                  </div>

              </div>
          </div>
      </div>
    )
  }
}

export default LiveCount;
