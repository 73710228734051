import React, { Component } from 'react';
import AmCharts from "@amcharts/amcharts3-react";

class PieChart extends Component {

  render() {
    const { pnm, unc, cop, thc, tnv, star, ind, idp, ncp, nnv, ilp, msj, others, notVoted } = this.props
    const config = {
      "type": "pie",
      "theme": "none",
      "dataProvider": [{
        "country": "PNM",
        "litres": pnm,
        "color": '#FF3300'
      }, {
        "country": "UNC",
        "litres": unc,
        "color": '#FFD633'
      }, {
        "country": "COP",
        "litres": cop,
        "color": '#0E0E0D'
      }, {
        "country": "THC",
        "litres": thc,
        "color": '#01DF01'
      }, {
        "country": "ILP",
        "litres": ilp,
        "color": '#F781F3'
      }, {
        "country": "TNV",
        "litres": tnv,
        "color": '#B404AE'
      }, {
        "country": "STAR",
        "litres": star,
        "color": '#00FFFF'
      }, {
        "country": "IND",
        "litres": ind,
        "color": '#FF8000'
      }, {
        "country": "IDP",
        "litres": idp,
        "color": '#0B3861'
      }, {
        "country": "NCP",
        "litres": ncp,
        "color": '#084B8A'
      }, {
        "country": "NNV",
        "litres": nnv,
        "color": '#4B610B'
      }, {
        "country": "MSJ",
        "litres": msj,
        "color": '#FE2E64'
      }, {
        "country": "Others",
        "litres": others,
        "color": '#848484'
      }, {
        "country": "Not Voted",
        "litres": notVoted,
        "color": '#808080'
      }],
      "valueField": "litres",
      "titleField": "country",
      "colorField": "color",
      "radius":80,
      "fontSize": 13,
      "balloon": {
        "fixedPosition": true
      }
    };

    return (
      <div>
        <AmCharts.React className="amchart_pie" style={{height:380}} options={config} />
      </div>
    )

  }
}

export default PieChart;
