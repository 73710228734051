import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import moment from 'moment'
import Datatable from 'react-bs-datatable'; 

import { NotificationContainer, NotificationManager } from 'react-notifications';
// redux action
import * as actions from '../actions';
import { connect } from 'react-redux';


class StreetListingDetail extends Component{
  constructor(props) {
    super(props);
 
    this.state = { 
        tofuIsReady:false,
        street_all_list:[]
    };
  }
 //Call to get Street List 
 componentDidMount() {
  this.props.getAllSteet();
}
    
    render(){


        const header = [
            { title: 'S.No.', prop: 'sn', sortable: true, filterable: true },
            { title: 'Name', prop: 'name', sortable: true, filterable: true },
            { title: 'Constituency', prop: 'Constituency', sortable: true},
            { title: 'Polling Div', prop: 'polling_Div', sortable: true},
          ];
           
          var body=[];
          if(this.props.street_all_list.length>0)
          {
            this.props.street_all_list.map((data,index)=>{
              body.push({
                    sn: index +1 ,
                    name: data.street_name,
                    Constituency:data.constituencyID.constituencyName,
                    polling_Div:data.pollingDivisionID.pollingDivisionName,   
                  })
             
            }) 
          
            //console.log(body);
          }
          
          
          
          
          
          
        
          const onSortFunction = {
            date(columnValue) {
              // Convert the string date format to UTC timestamp
              // So the table could sort it by number instead of by string
              return moment(columnValue, 'Do MMMM YYYY').valueOf();
            },
          };
           
          const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'There is no data to be displayed',
          };



    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
                      <h1 className="text-primary ">Street Listing</h1>
                  </div>
            </div>   


            <div className="row mt-5 px-4">

            <div className="col-sm-12 mb-4">
                <div className="shadow_box custom_datatable">
                        <Datatable
                            tableHeader={header}
                            tableBody={body}
                            keyName="userTable"
                            tableClass="striped hover responsive"
                            rowsPerPage={10}
                            rowsPerPageOption={[10, 20, 30,40]}
                            initialSort={{prop: "username", isAscending: true}}
                            onSort={onSortFunction}
                            labels={customLabels}
                        />
                </div>
            </div>        
                    
                                    
            </div> 


        </div>


      </div>
    )
  }
}
const mapStateToProps = ({ survey }) => {

  const { street_all_list } = survey;
  return { street_all_list }
}
export default connect(mapStateToProps, actions)(StreetListingDetail);
