import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
// redux action
import * as actions from '../actions';
import { connect } from 'react-redux';
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment'
import Datatable from 'react-bs-datatable';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


var Filters = {
    constituencyID: "",
    pollingDivisionID: "",
    dataForYear: "",
    // newVotersInThisYear: "",
    // gender: "",
    // descreased: "",
    // partyName: "",
    // employement: "",
    // mobile: "",
    // gpsLocation: "",
    // vehicleBooking: "",
    houses: 0,
    streets: 0,
    // ageFrom: 0,
    // ageTo: 0,
    limit: 1000,
    page: 0,
    voted:7,
    waitingForVote:"",
    notVoted:"",

}


class HourlySlotVoters extends Component {
    csvLink = React.createRef()
    constructor() {
        super();
        this.state = {
            modalIsOpen: false,
            modalIsOpenHouse: false,
            role: '1',
            voter_res: [],
            houses_res: [],
            street_res: [],
            constituency_list: [],
            voter_info: [{
                voter_name: '',
                prefered_party: '',
                pollingDivisionName: '',
                street_name: '',
                mobile_no: '',
                gender: '',
                email: '',
                dob: '',
                constituencyName: '',
                address: '',
                apartment: '',
                building_no: ''
            }],
            // years: [],
            // startYear: "",
            // endYear: "",
            selectedContituency: "",
            // selected_gender: "",
            // selected_deceased: "",
            currentValues: [],
            downloadData: [],
            headers: [],
            // employementArray: [],
            // is_mobile: "",
            // is_gps: "",
            // vehicleBooking: "",
            selected_tab: 0,
            // age_from: '',
            // age_to: '',
            // age_from_array: [],
            // age_to_array: [],
            editable: false,
            selectedPollingDiv: "",
            selectedTimeSlot: "",
            timeSlot:"",

            voterID: "",
            name: "",
            gender: "",
            party_name: "",
            dob: "",
            building_no: "",
            apartment: "",
            address: "",
            volunteer: "",
            descreased: "",
            email: "",
            mobile_no: "",
            home_phone_no: "",
            employment: "",
            marital_status: 0,
            no_of_voters: 0,
            cab: ""
        };

    }

    componentWillMount() {

        this.setState({
            role: localStorage.getItem('role')
        })
        console.log(localStorage.getItem('role'))

    }

    async componentDidMount() {
        this.setState({
            role: localStorage.getItem('role')
        })
        const getAllVoters = {
            constituencyID: "",
            pollingDivisionID: "",
            dataForYear: "",
            // newVotersInThisYear: "",
            // gender: "",
            // descreased: "",
            // partyName: "",
            // employement: "",
            // mobile: "",
            // gpsLocation: "",
            // vehicleBooking: "",
            houses: 0,
            streets: 0,
            selected_tab: 0,
            page: 0,
            limit: 1000,
            voted:"7",
            waitingForVote:"",
            notVoted:"",

            currentPage: 1
        }
        await    this.props.listConsituency();
        await   this.props.getVoters_poll(getAllVoters);
        await    this.props.getPollingDiv("");

       
    }
    //Get poll Voter Result
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.houses_res !== prevState.houses_res) {
            return { houses_res: nextProps.houses_res };
        }
        if (nextProps.street_res !== prevState.street_res) {
            return { street_res: nextProps.street_res };
        }

        else return null;
    }

    //On constituency Changed
     ConstituencyChanged = e => {
        if (e.target.value == '') {
            this.setState({ selectedContituency: e.target.value })
            if (this.state.selected_tab === 0) {
                Filters.voted = "7";
                Filters.waitingForVote = "";
                Filters.notVoted = "";
            }
            if (this.state.selected_tab === 1) {
                Filters.waitingForVote = "7";
                Filters.voted = "";
                Filters.limit = 1000;
                Filters.notVoted = "";
            }
            if (this.state.selected_tab === 2) {
                Filters.waitingForVote = "";
                Filters.voted = "";
                Filters.limit = 1000;
                Filters.notVoted = "8";
            }
            Filters.timeSlot = "";
            Filters.constituencyID = "";
            this.props.getVoters_poll(Filters);
        } else {
            if (this.state.selected_tab === 0) {
                Filters.voted = "7";
                Filters.waitingForVote = "";
                Filters.notVoted = "";
            }
            if (this.state.selected_tab === 1) {
                Filters.waitingForVote = "7";
                Filters.voted = "";
                Filters.notVoted = "";
                Filters.limit = 1000;
            }
            if (this.state.selected_tab === 2) {
                Filters.voted = "";
                Filters.waitingForVote = "";
                Filters.notVoted = "8";
            }
            Filters.timeSlot = "";
            this.setState({ timeSlot: "" });
            Filters.pollingDivisionID="";
            this.setState({ selectedPollingDiv:""});
            Filters.constituencyID = e.target.value;
            console.log(e.target.value);
            this.setState({ selectedContituency: e.target.value });
             this.props.getPollingDiv(e.target.value);
             this.props.getVoters_poll(Filters);
        }
    };


    //PollingDivChanged
    PollingDivChanged = e => {
        if (e.target.value == '') {
            this.setState({ selectedPollingDiv: e.target.value })
            if (this.state.selected_tab === 0) {
                Filters.voted = "7";
                Filters.waitingForVote = "";
                Filters.notVoted = "";
                
            }
            if (this.state.selected_tab === 1) {
                Filters.waitingForVote = "7";
                Filters.voted = "";
                Filters.notVoted = "";
                Filters.limit = 1000;
            }
            if (this.state.selected_tab === 2) {
                Filters.waitingForVote = "";
                Filters.voted = "";
                Filters.notVoted = "8";
                Filters.limit = 1000;
            }
            Filters.timeSlot = "";
            Filters.pollingDivisionID = "";
             this.props.getVoters_poll(Filters);
        } else {
            if (this.state.selected_tab === 0) {
                Filters.voted = "7";
                Filters.waitingForVote = "";
                Filters.notVoted = "";
            }
            if (this.state.selected_tab === 1) {
                Filters.waitingForVote = "7";
                Filters.voted ="";
                Filters.notVoted = "";
                Filters.limit = 1000;
            }
            if (this.state.selected_tab === 2) {
                Filters.waitingForVote = "";
                Filters.voted ="";
                Filters.notVoted = "8";
                Filters.limit = 1000;
            }
            Filters.timeSlot = "";
            this.setState({ timeSlot: "" });
            Filters.pollingDivisionID = e.target.value;
            console.log(e.target.value);
            this.setState({ selectedPollingDiv: e.target.value })
             this.props.getVoters_poll(Filters);
        }
    };

    TimeSlotChanged = e => {
        if (e.target.value == '') {
            this.setState({timeSlot : e.target.value })
            if (this.state.selected_tab === 0) {
                Filters.voted = "7";
                Filters.waitingForVote = "";
                Filters.notVoted = "";
            }
            if (this.state.selected_tab === 1) {
                Filters.waitingForVote = "7";
                Filters.voted = "";
                Filters.notVoted = "";
                Filters.limit = 1000;
            }
            if (this.state.selected_tab === 2) {
                Filters.waitingForVote = "";
                Filters.voted = "";
                Filters.notVoted = "8";
                Filters.limit = 1000;
            }
            Filters.timeSlot = "";
             this.props.getVoters_poll(Filters);
        } else {
            if (this.state.selected_tab === 0) {
                Filters.voted = "7";
                Filters.waitingForVote = "";
                Filters.notVoted = "";
            }
            if (this.state.selected_tab === 1) {
                Filters.waitingForVote = "7";
                Filters.voted ="";
                Filters.notVoted = "";
                Filters.limit = 1000;
            }
            if (this.state.selected_tab === 2) {
                Filters.waitingForVote = "";
                Filters.voted ="";
                Filters.notVoted = "8";
                Filters.limit = 1000;
            }
            Filters.timeSlot = e.target.value;
            console.log(e.target.value);
            this.setState({ timeSlot: e.target.value })
             this.props.getVoters_poll(Filters);
        }
    };

    //Get Tab Value
    getTabVal = (index, label) => {

        this.setState({ selected_tab: index })

        if (index === 0) {
            Filters.voted = "7";
            Filters.waitingForVote = "";
            Filters.notVoted = "";
             this.props.getVoters_poll(Filters);
        }
        if (index === 1) {
            Filters.voted = "";
            Filters.waitingForVote = "7";
            Filters.notVoted = "";
            Filters.limit = 1000;
             this.props.getVoters_poll(Filters);
        }
        if (index === 2) {
            Filters.voted = "";
            Filters.waitingForVote = "";
            Filters.notVoted = "8";
            Filters.limit = 1000;
             this.props.getVoters_poll(Filters);
        }

        console.log(index, label)
    }

    handleChecked = (event) => {

        var test = this.state.currentValues;
        if (event.target.checked === true) {
            test.push(event.target.value)
        }
        else {
            var index = test.indexOf(event.target.value);
            if (index > -1) {
                test.splice(index, 1);
            }
        }
        this.setState({ currentValues: test }, () => {
            console.log(this.state.currentValues)
            if (this.state.currentValues.length > 0) {
                if (this.state.selected_tab === 0) {
                    Filters.voted = "7";
                    Filters.notVoted = "";
                    Filters.waitingForVote = "";
                }
                if (this.state.selected_tab === 1) {
                    Filters.waitingForVote = "7";
                    Filters.voted = "";
                    Filters.notVoted = "";
                    Filters.limit = 1000;
                }
                if (this.state.selected_tab === 1) {
                    Filters.waitingForVote = "";
                    Filters.voted = "";
                    Filters.notVoted = "8";
                    Filters.limit = 1000;
                }

                Filters.constituencyID = this.state.selectedContituency;
                Filters.pollingDivisionID = this.state.selectedPollingDiv;
                Filters.partyName = this.state.currentValues;
                 this.props.getVoters_poll(Filters);

            }
            else {
                if (this.state.selected_tab === 0) {
                    Filters.voted = "7";
                    Filters.waitingForVote = "";
                    Filters.notVoted = "";
                }
                if (this.state.selected_tab === 1) {
                    Filters.waitingForVote = "7";
                    Filters.voted = "";  
                    Filters.notVoted = "";
                    Filters.limit = 1000;
                }
                if (this.state.selected_tab === 2) {
                    Filters.waitingForVote = "";
                    Filters.voted = "";  
                    Filters.notVoted = "8";
                    Filters.limit = 1000;
                }
                
                Filters.constituencyID = this.state.selectedContituency;
               Filters.pollingDivisionID = this.state.selectedPollingDiv;
                 this.props.getVoters_poll(Filters);
            }
        })


    }
  
    download = (event) => {
        var voterDataArray = [];
        var headers = [
            { label: "voterSystemID", key: "voterSystemID" },
            { label: "constituency", key: "constituencyID" },
            { label: "pollingDivision", key: "pollingDivisionID" },
            { label: "Name", key: "name" },
            { label: "Gender", key: "gender" },
            { label: "Email", key: "email" },
            { label: "DOB", key: "dob" },
            { label: "Apartment", key: "apartment" },
            { label: "Building", key: "building_no" },
            { label: "Address", key: "address" },
            { label: "Vehicle Requested", key: "cab" },
            { label: "Mobile", key: "mobile_no" },
            { label: "Party Name", key: "party_name" },
        ];


        if (this.state.street_res.length > 0) {
            this.state.street_res.map((voterData) => {
                voterDataArray.push({
                    voterSystemID:voterData.voterSystemID,
                    constituencyID:voterData.constituencyID.constituencyName,
                    pollingDivisionID:voterData.pollingDivisionID.pollingDivisionName,
                    name: voterData.name,
                    gender: voterData.gender,
                    email: voterData.email,
                    dob: voterData.dob,
                    apartment: voterData.apartment,
                    building_no: voterData.building_no,
                    address: voterData.address,
                    cab: voterData.cab,
                    mobile_no: voterData.mobile_no,
                    party_name: voterData.party_name,
                })
            })
        }
        this.setState({ downloadData: voterDataArray, headers: headers }, () => {
            this.csvLink.current.link.click()
        })

    }

   
    handleUpdateVoter = () => {
        this.setState(state => ({
            editable: !state.editable
        }));
    };

  

    onPageNavigate = (next) => {
        console.log('vk')
    }

    render() {
        // console.log('Role Value');
        // console.log(this.state.employementArray.indexOf(1));


        const headerNotVoted = [
            { title: 'S. No.', prop: 'sno', sortable: true },
            { title: 'voter System ID', prop: 'voterSystemID', sortable: true },
                { title: 'Name', prop: 'name', sortable: true },
                { title: 'Contact no', prop: 'mobile_no', sortable: true },
                { title: 'BuildingNo', prop: 'building_no', sortable: true },
                { title: 'ApartmentNo', prop: 'apartment', sortable: true },
                { title: 'Street', prop: 'address', sortable: true },
                
        ];
        var bodyNotVoted = [];
        if (this.props.voter_res.length > 0) {
            this.props.voter_res.map((voterData) => {
                bodyNotVoted.push({
                    sno: <p className="font-weight-bold">{bodyNotVoted.length+1}</p>,
                    name: <p className="font-weight-bold">{voterData.name}</p>,
                    address: <p className="font-weight-bold">{voterData.address}</p>,
                    voterSystemID: <p className="font-weight-bold">{voterData.voterSystemID}</p>,
                    mobile_no: <p className="font-weight-bold">{voterData.mobile_no}</p>,
                    apartment: <p className="font-weight-bold">{voterData.apartment}</p>,
                    building_no: <p className="font-weight-bold">{voterData.building_no}</p>,
                    
                })

            })
        }


        const headerhouse = [
            { title: 'S. No.', prop: 'sno', sortable: true },
            { title: 'voter System ID', prop: 'voterSystemID', sortable: true },
                { title: 'Name', prop: 'name', sortable: true },
                { title: 'Contact no', prop: 'mobile_no', sortable: true },
                { title: 'BuildingNo', prop: 'building_no', sortable: true },
                { title: 'ApartmentNo', prop: 'apartment', sortable: true },
                { title: 'Street', prop: 'address', sortable: true },
                
        ];
        var bodyHouses = [];
        if (this.props.houses_res.length > 0) {
            this.props.houses_res.map((voterData) => {
                bodyHouses.push({
                    sno: <p className="font-weight-bold">{bodyHouses.length+1}</p>,
                    name: <p className="font-weight-bold">{voterData.name}</p>,
                    address: <p className="font-weight-bold">{voterData.address}</p>,
                    voterSystemID: <p className="font-weight-bold">{voterData.voterSystemID}</p>,
                    mobile_no: <p className="font-weight-bold">{voterData.mobile_no}</p>,
                    apartment: <p className="font-weight-bold">{voterData.apartment}</p>,
                    building_no: <p className="font-weight-bold">{voterData.building_no}</p>,
                    
                })

            })
        }


        const headerStreets = [
            { title: 'S. No.', prop: 'sno', sortable: true },
            { title: 'voter System ID', prop: 'voterSystemID', sortable: true },
                { title: 'Name', prop: 'name', sortable: true },
                { title: 'Contact no', prop: 'mobile_no', sortable: true },
                { title: 'BuildingNo', prop: 'building_no', sortable: true },
                { title: 'ApartmentNo', prop: 'apartment', sortable: true },
                { title: 'Street', prop: 'address', sortable: true },
            ];

            

        var body = [];
        if (this.props.street_res.length > 0) {
            this.props.street_res.map((voterData) => {
                body.push({
                    sno: <p className="font-weight-bold">{body.length+1}</p>,
                    name: <p className="font-weight-bold">{voterData.name}</p>,
                    address: <p className="font-weight-bold">{voterData.address}</p>,
                    voterSystemID: <p className="font-weight-bold">{voterData.voterSystemID}</p>,
                    mobile_no: <p className="font-weight-bold">{voterData.mobile_no}</p>,
                    apartment: <p className="font-weight-bold">{voterData.apartment}</p>,
                    building_no: <p className="font-weight-bold">{voterData.building_no}</p>,
                   
                })

            })

            //console.log(body);
        }

        const onSortFunction = {
            date(columnValue) {
                return moment(columnValue, 'Do MMMM YYYY').valueOf();
            },
        };

        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'There is no data to be displayed',
        };

        //console.log('Total Data')
        //console.log(this.props.total_voters,this.props.total_houses,this.props.total_streets)
        return (

            <div>

                <div className="container-fluid">
                    <div className="row my-3 px-4 printRow">
                        <div className="col-sm-4">
                            <h1 className="text-primary ">Hourly Slot Wise Voters</h1>
                        </div>
                        <div className="col-sm-8 printfright">
                            <div className="title_right_sec">

                                <div className="form-group ml-6">
                                    <select className="form-control" id="const_sele"
                                        onChange={this.ConstituencyChanged}
                                        value={this.state.selectedContituency}
                                    >
                                        <option value="">Select a Constituency</option>
                                        {this.props.constituency_list.map((data, index) => {
                                            return (
                                                <option value={data._id}>
                                                    {data.constituencyName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="form-group ml-3">
                                <select
                                                        className="form-control"
                                                        id="const_sele"
                                                        name="consti"
                                                        onChange={this.PollingDivChanged}
                                                        value={this.state.selectedPollingDiv}
                                                    >
                                                        
                                                        <option value="" >Select a Polling Station</option>
                                                        {this.props.polling_div.map((data, index) => {
                                                            return (
                                                                <option value={data._id}>
                                                                    {data.pollingDivisionName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                </div>
                                <div className="form-group ml-3">
                  <select className="form-control pull-right"
                    onChange={this.TimeSlotChanged}
                    value={this.state.timeSlot}
                   
                  >
                 
                   <option value="" >Select a Time Slot</option>
                    <option value="7:00 to 8:00">7:00 to 8:00</option>
                    <option value="8:00 to 9:00">8:00 to 9:00</option>
                    <option value="9:00 to 10:00">9:00 to 10:00</option>
                    <option value="10:00 to 11:00">10:00 to 11:00</option>
                    <option value="11:00 to 12:00">11:00 to 12:00</option>
                    <option value="12:00 to 13:00">12:00 to 13:00</option>
                    <option value="13:00 to 14:00">13:00 to 14:00</option>
                    <option value="14:00 to 15:00">14:00 to 15:00</option>
                    <option value="15:00 to 16:00">15:00 to 16:00</option>
                    <option value="16:00 to 17:00">16:00 to 17:00</option>
                    <option value="17:00 to 18:00">17:00 to 18:00</option>
                  </select>
                </div>

                            <button className="btn_cust btn-primary pull-left mt-1 printHide" onClick={this.download}>Download Yet To Vote List</button>
                                <button className="btn_cust btn-primary pull-left mt-1 ml-2 printHide" onClick={() => window.print()}>Print</button>
                                <CSVLink
                                    data={this.state.downloadData}
                                    headers={this.state.headers}
                                    filename="data.csv"
                                    className="hidden"
                                    ref={this.csvLink}
                                    target="_blank" />


                            </div>
                        </div>
                        </div>
                    </div>

                   


                    <div className="row px-4 mt-5">
                        <div className="col-sm-12">
                            <Tabs onSelect={(index, label) => this.getTabVal(index, label)} selected={this.state.selected_tab}>

                              
                            
                                <Tab label={`Voted(${this.props.total_houses})`}>
                               
                                    <div className="shadow_box tableScroll mb-1 mt-3 p-0">
                                    <div class="p-1 mb-2 bg-success text-white">
                                        <div className="card">
                                        
                                            <div className="card custom_datatable voter_datatable">
                                            
                                                {(this.props.loading) ?

                                                    <div class="spinner-border text-primary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> :
                                                    <Datatable
                                                        tableHeader={headerhouse}
                                                        tableBody={bodyHouses}
                                                        keyName="userTable"
                                                        tableClass="striped hover responsive"
                                                        rowsPerPage={50}
                                                        rowsPerPageOption={[50, 100, 150, 200]}
                                                        initialSort={{ prop: "username", isAscending: true }}
                                                        onSort={onSortFunction}
                                                        labels={customLabels}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
</div>
                                </Tab>


                                <Tab label={`Yet To Vote(${this.props.total_streets})`}type>
                                
                                    <div className="shadow_box tableScroll mb-5 mt-3 p-0">
                                    <div class="p-1 mb-2 bg-warning text-dark">
                                        <div className="card">
                                            <div className="card custom_datatable voter_datatable">
                                                {(this.props.loading) ?

                                                    <div class="spinner-border text-primary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> :
                                                    
                                                    <Datatable
                                                        tableHeader={headerStreets}
                                                        FixedHeader="true"
                                                        tableBody={body}
                                                        keyName="userTable"
                                                        
                                                        tableClass="striped hover responsive"
                                                        rowsPerPage={50}
                                                        rowsPerPageOption={[50, 100, 150, 200]}
                                                        initialSort={{ prop: "username", isAscending: true }}
                                                        onSort={onSortFunction}
                                                        labels={customLabels}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                </Tab>
                                <Tab label={`Not Voted(${this.props.total_voters})`}type>
                                
                                    <div className="shadow_box tableScroll mb-5 mt-3 p-0">
                                    <div class="p-1 mb-2 bg-danger text-white">
                                        <div className="card">
                                            <div className="card custom_datatable voter_datatable">
                                                {(this.props.loading) ?

                                                    <div class="spinner-border text-primary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> :
                                                    
                                                    <Datatable
                                                        tableHeader={headerNotVoted}
                                                        FixedHeader="true"
                                                        tableBody={bodyNotVoted}
                                                        keyName="userTable"
                                                        
                                                        tableClass="striped hover responsive"
                                                        rowsPerPage={50}
                                                        rowsPerPageOption={[50, 100, 150, 200]}
                                                        initialSort={{ prop: "username", isAscending: true }}
                                                        onSort={onSortFunction}
                                                        labels={customLabels}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                </Tab>
                                
                                <Tab label={`Voter Turnout (${this.props.total_houses})  ${((this.props.total_houses)/(this.props.total_houses +this.props.total_streets)*100).toFixed(2)}%`}disabled>
                                
                                </Tab>
                               
                                
                            </Tabs>
                        </div>
                </div>
                </div>
        )
    }
}
const mapStateToProps = ({ poll, campTeam }) => {
    const { voter_res, houses_res, street_res, total_voters, total_houses, total_streets, loading } = poll;
    const { constituency_list, polling_div } = campTeam;
    return { voter_res, constituency_list, polling_div, houses_res, street_res, total_voters, total_houses, total_streets, loading };
};
export default connect(
    mapStateToProps,
    actions
)(HourlySlotVoters);
