import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import MapPreviousResult from '../Components/MapPreviousResult';
import Modal from 'react-modal';

// import InputColor from 'react-input-color';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};




class AnalyticsPreviousResult extends Component {
  constructor(props) {
    super(props);
    this.childRef = null
    this.state = {
      modalIsOpen: false,
      modalIsOpen2: false,
      constituency_list: [],
      lowVoting: '',
      margPollVot: 40,
      margPollVot2: 20,
      favcolor: '#ff0000',
      favcolor2: '#ff7089',
      currentValues: true,
      division: 'marginal',
      yearValueNew: '2015',
      selectedYearValue: '2015'
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal2 = this.openModal2.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal2() {
    this.setState({ modalIsOpen2: true });
  }

  closeModal2() {
    this.setState({ modalIsOpen2: false });
  }


  //Call Api for consituency Liist
  componentDidMount() {
    // this.childRef.getWrappedInstance().calledByParent()
    this.props.getElectionResultDataWithFilter('2015')
    //this.props.listConsituency();
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.constituency_list !== prevState.constituency_list) {
  //     return { constituency_list: nextProps.constituency_list };
  //   } else return null;
  // }

  yearChange = e => {
    //this.props.getElectionResultDataWithFilter(e.target.value)
    this.setState({
      yearValueNew: e.target.value
    })
    this.childRef.getWrappedInstance().yearChanged(e.target.value)
  }

  //Selected Year Change Here
  selectedYearHere = e => {
    var qryString = "";
    console.log('Year Selected');
    console.log(e.target.value);
    this.setState({
      selectedYearValue: e.target.value
    })
    this.childRef.getWrappedInstance().yearChanged(e.target.value)

  };



  handleLowVoting = e => this.setState({ lowVoting: e.target.value, error: {} });


  handleColor(event) {
    this.setState({
      favcolor: event.target.value
    })
  }

  handleColor2(event) {
    this.setState({
      favcolor2: event.target.value
    })
    this.childRef.getWrappedInstance().changeColor(event.target.value)
    //this.props.handleMarginalColor(event.target.value)

  }

  handleLowVoting(event) {
    this.setState({
      lowVoting: event.target.value
    })
  }

  handleMargPollVot(event) {
    this.childRef.getWrappedInstance().pollingAreaPer(event.target.value)
    this.setState({
      margPollVot: event.target.value
    })
  }

  handleMargPollVot2(event) {
    this.childRef.getWrappedInstance().pollingAreaPer2(event.target.value)
    this.setState({
      margPollVot2: event.target.value
    })
  }


  toogleDivision = (division) => {
    this.childRef.getWrappedInstance().toogleDivision(division)
    this.setState({
      division: division,
    })
  }


  render() {
    console.log(`Color Change ---> ${this.props.marginalColorValue}`)
    console.log('*************');
    console.log(this.props.election_result);
    console.log('*************');
    console.log(this.state.lowVoting);
    //const [color, setColor] = React.useState({});


    console.log(this.state.favcolor);
    console.log(this.state.favcolor2);
    console.log(this.state.lowVoting);
    console.log(this.state.margPollVot);

    console.log('hello code ------------')
    console.log(this.state.division)




    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <div className="col-sm-4 printfleft">
              <h1 className="text-primary ">Previous Results</h1>
            </div>
            <div className="col-sm-8 previCtrl printfright">
              <button class="btn_cust btn-primary pull-right mt-3 ml-2 printHide" onClick={() => window.print()}>Download and Print Report</button>
              <div className="form-group mt-3">

                <select className="form-control pull-right"
                  id="const_sele"
                  onChange={this.selectedYearHere}
                  value={this.state.selectedYearValue}
                >
                  {/* <option value="2002">2002</option>
                  <option value="2007">2007</option>
                  <option value="2010">2010</option> */}
                  <option value="2015">2015</option>
                  <option value="2020">2020</option>
                </select>


              </div>

              <Link
                className="btn_cust btn-primary pull-right mr-3"
                to="#"
                onClick={this.openModal}
              >
                Set Parameter
              </Link>
            </div>
            

            <div className="col-sm-8 previCtrlPolling">

            <button class="btn_cust btn-primary pull-right mt-0 ml-2 printHide" onClick={() => window.print()}>Download and Print Report</button>
              <Link
                className="btn_cust btn-primary pull-right mr-3"
                to="#"
                onClick={this.openModal2}
              >
                Set Parameter
              </Link>
              
            </div>
          </div>

          <div className="pull-right mr-5">
              <b> Select % and Color for Low Voter Turnout / Marginal Area </b> 
            </div>

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary ">Set Parameter On Constituency Level</h1>
              <button className="btn_close" onClick={this.closeModal}>
                x
              </button>
            </div>

            <div className="modal_body form-horizontal">

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  Select your Filter
              </label>
                <div className="col-sm-8">
                  <label>
                    <input type="radio" name="party" checked={(this.state.division == 'low_voting' ? true : false)}
                      onClick={() => this.toogleDivision('low_voting')} />
                    &nbsp; Low Voter Turnout
                </label>
                  <label style={{ marginLeft: '20px' }}>
                    <input type="radio" name="party" checked={(this.state.division == 'marginal' ? true : false)}
                      onClick={() => this.toogleDivision('marginal')} />
                    &nbsp; Marginal Polling Area
                </label>
                </div>


              </div>
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  {(this.state.division == 'low_voting' ? 'Low Voter Turnout' : 'Marginal Polling Area')}:
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    name="margPollVot"
                    value={this.state.margPollVot}
                    placeholder="Value"
                    style={{ width: 68 + '%', float: 'left', marginRight: 10 }}
                    onChange={this.handleMargPollVot.bind(this)}
                  />{' '}
                  %
                </div>
                <div className="col-sm-3">
                  <input
                    type="color"
                    name="favcolor2"
                    className="form-control"
                    value={this.state.favcolor2}
                    style={{ padding: '3px 4px' }}
                    onChange={this.handleColor2.bind(this)}
                  />
                </div>
              </div>
            </div>
          </Modal>


          <Modal
            isOpen={this.state.modalIsOpen2}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary ">Set Parameter On Polling Division Level</h1>
              <button className="btn_close" onClick={this.closeModal2}>
                x
              </button>
            </div>

            <div className="modal_body form-horizontal">

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  Select your Filter
              </label>
                <div className="col-sm-8">
                  <label>
                    <input type="radio" name="party" checked={(this.state.division == 'low_voting' ? true : false)}
                      onClick={() => this.toogleDivision('low_voting')} />
                    &nbsp; Low Voter Turnout
                </label>
                  <label style={{ marginLeft: '20px' }}>
                    <input type="radio" name="party" checked={(this.state.division == 'marginal' ? true : false)}
                      onClick={() => this.toogleDivision('marginal')} />
                    &nbsp; Marginal Polling Area
                </label>
                </div>


              </div>
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  {(this.state.division == 'low_voting' ? 'Low Voter Turnout' : 'Marginal Polling Area')}:
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    name="margPollVot2"
                    value={this.state.margPollVot2}
                    placeholder="Value"
                    style={{ width: 68 + '%', float: 'left', marginRight: 10 }}
                    onChange={this.handleMargPollVot2.bind(this)}
                  />{' '}
                  %
                </div>
                <div className="col-sm-3">
                  <input
                    type="color"
                    name="favcolor2"
                    className="form-control"
                    value={this.state.favcolor2}
                    style={{ padding: '3px 4px' }}
                    onChange={this.handleColor2.bind(this)}
                  />
                </div>
              </div>
            </div>
          </Modal>

          <div className="row px-4 mt-5">
            <div className="col-sm-12">
              <MapPreviousResult ref={ref => this.childRef = ref} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ campTeam, authUser }) => {
  const { constituency_list } = campTeam;
  const { updatedYearValue, election_result, marginalColorValue } = authUser

  return { constituency_list, updatedYearValue, election_result, marginalColorValue };
};

export default connect(mapStateToProps, actions)(AnalyticsPreviousResult);
