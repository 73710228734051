import React, { Component } from 'react';
import {Link} from 'react-router-dom';


import Header from './Includes/header'

import LiveNews from './Includes/livenews'


import ReactSwipe from 'react-swipe';
import Map from './Components/Map';


class Welcome extends Component {
constructor(props){
  super(props);
  this.state={username: "",password: "",redirectToReferrer: false
  }
  //this.login = this.login.bind(this);
  //this.onChange = this.onChange.bind(this);
 }

 change = e => {
    //this.props.onChange({ [e.target.name]: e.target.value });
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  redirectionCheck = ()=>
  {
    console.log('Yes');

    return (this.props.history.push('/home'))

  }

 onSubmit = e => {
     e.preventDefault();
     //console.log('ssssss'+this.state.username);
    // this.props.onSubmit(this.state);
        //this.props.onSubmit(this.state);
        //API Call
        if (this.state.username && this.state.password)
        {
          console.log('ssssss'+this.state.username);
          this.redirectionCheck();
        }
       // clear form
       this.setState({
         username: "",
         password: ""
       });
   };

render() {

    return (
    //   <div style={{background: 'url(img/rct-session-banner.jpg) center no-repeat', backgroundSize: 'cover', height:"100vh"}}>
      <div style={{height:"100vh"}}>
          {/* <div className="live_news">
            <LiveNews />
          </div> */}
        <Header />

        <section className="position-relative header_bg">
            <div className="home-table">
                <div className="home-table-center">
                    <div className="container z-index position-relative">
                        <div className="row login_main">

                            <div className="col-sm-6">

                                    <div className="row">
                                            {/* <div className="col-md-12 pl-5">
                                                <h4>Explore via map</h4>
                                                <div className="login_map">
                                                    <Map />
                                                </div>
                                            </div> */}

{/* 
                                            <div className="col-md-5 party_container">
                                                <h3 className="text-center">Who will give you the next <span className="text-custom">PM</span> ?</h3>

                                                <div className="row mt-3 current_pm">
                                                    <div className="col-lg-6 p-0 text-center party_block">
                                                        <img src="img/PNC.jpg" alt="image" className="img-fluid mx-auto d-block" />
                                                        <Link to="/" className="btn btn-gradient btn-vote mx-auto">Vote</Link>
                                                    </div>
                                                    <div className="col-lg-6 p-0 text-center party_block">
                                                        <img src="img/COP.jpg" alt="image" className="img-fluid mx-auto d-block" />
                                                        <Link to="/" className="btn btn-gradient btn-vote mx-auto">Vote</Link>
                                                    </div>
                                                    <div className="col-lg-6 p-0 text-center party_block">
                                                        <img src="img/PNM.jpg" alt="image" className="img-fluid mx-auto d-block" />
                                                        <Link to="/" className="btn btn-gradient btn-vote mx-auto">Vote</Link>
                                                    </div>
                                                    <div className="col-lg-6 p-0 text-center party_block">
                                                        <img src="img/others.jpg" alt="image" className="img-fluid mx-auto d-block" />
                                                        <Link to="/" className="btn btn-gradient btn-vote mx-auto">Vote</Link>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </div>
                                
                            </div>

                            <div className="col-sm-6">
                                <div className="row">
                                        <div className="col-sm-12 btn_bot_lft" style={{ marginTop:'42px'}}>
                                            {/* <Link to="/voter-list" className="btn btn-gradient live_count"><i className="fa fa-file-text-o"></i> Voters List</Link>
                                            <Link to="/election-result" className="btn btn-gradient live_count"><i className="fa fa-bullhorn"></i> Past Election Results</Link>    */}
                                            {/* <Link to="/voter-list-comparison" className="btn btn-gradient live_count"><i className="fa fa-exchange"></i> Voter List Comparison</Link> */}
                                            {/* <Link to="/prediction-result" className="btn btn-gradient live_count"><i className="fa fa-bullhorn"></i> Prediction Result</Link>*/}
                                    </div> 
                                    <div className="col-sm-12">
                                            {/* <div className="voting_card">
                                                <ReactSwipe
                                                className="carousel"
                                                swipeOptions={{ continuous: true, auto: 3000, }}>
                                                    <div>
                                                        <img src="img/trend1.jpg" className="img-fluid" />
                                                    </div>

                                                    <div>
                                                        <img src="img/trend2.jpg" className="img-fluid" />
                                                    </div>
                                                </ReactSwipe>

                                            </div> */}
                                    </div>
                                </div>
                            </div>



                        </div>

                        {/* <div className="chat_box">
                            <Link to="/">
                                <span>Click here to chat</span>
                                <img src="img/robot.png" alt="image" />
                            </Link>
                        </div> */}


                    </div>
                </div>
            </div>
            <div className="mycontainer">
            <img src="img/voting.jpg" alt="#" />
            <Link to="/login">
            <button className="mybtn" ><i className="fa fa-lock"></i> &nbsp; Admin Login</button>
            </Link>
            </div>
            {/* <div class="container">
            <img src="img/voting.jpg" alt="Admin Login"/>
            <button class="btn">Admin Login</button>
            </div> */}
            {/* <div className="curv-img">
                <svg xmlns="http:/www.w3.org/2000/svg" width="1919.76" height="327.903"><path fill="#f8f9fa" d="M1919.76 132.428c-90.511-64.67-174.365-144.792-314.866-123.125-94.441 14.563-142.181 105.921-220.98 148.217-117.85 63.256-155.979 35.449-264.385 14.607-84.57-16.26-117.255-29.063-199.787-.493-84.986 29.419-134.191 125.289-239.288 124.882-121.481-.472-157.447-90.325-248.953-163.594C317.535 41.67 228.822 15.373 79.5 1.958 51.97-.516 25.538-.52 0 1.242v326.661h1919.76V132.428z"></path></svg>
            </div> */}

        </section>


        {/* <Footer /> */}

      </div>
    );
  }
}
export default Welcome;
