/*
 src/reducers/ActiveUserReducer.js
*/
/**
 * Auth User Reducers
 */
import isEmpty from '../validation/is-empty';
import {
  ADD_TEAM,
  LIST_TEAM,
  LIST_CONSTITUENCY,
  LIST_POLLING_DIV,
  LIST_STREET_CAPTAINS_TEAM,
  CLEAR_DATA,
  LIST_POLL_WORKER,
  LIST_SURVEY_WORKER,
  LIST_VEHICLE_PROVIDER,
  LIST_STREET_BASED_ON_POLLING_DIVISION
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
  loading: true,
  team_res: '',
  constituency_list: [],
  polling_div: [],
  team_list: [],
  street_captians_list: [],
  poll_workerCMg: [],
  survey_workerCMg: [],
  vehicle_provider_listCMg: [],
  list_of_street: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CONSTITUENCY:
      return {
        ...state,
        constituency_list: action.payload
      };

    case LIST_POLLING_DIV:
      return {
        ...state,
        polling_div: action.payload, list_of_street: []
      };

    case CLEAR_DATA:
      // debugger;
      return {
        ...state,
        polling_div: []
      };

    case LIST_POLL_WORKER:
      console.log(action.payload)
      return {
        ...state,
        poll_workerCMg: action.payload,
      };

    case LIST_SURVEY_WORKER:
      console.log(action.payload)
      return {
        ...state,
        survey_workerCMg: action.payload
      };

    case LIST_VEHICLE_PROVIDER:
      console.log(action.payload)
      return {
        ...state,
        vehicle_provider_listCMg: action.payload
      };

    //LIST_TEAM
    case LIST_TEAM:
      console.log('************');
      console.log(action.payload);
      console.log('************');
      return {
        ...state,
        team_list: action.payload
      };

    //LIST_STREET_CAPTAINS_TEAM
    case LIST_STREET_CAPTAINS_TEAM:
      return {
        ...state,
        street_captians_list: action.payload
      };

    //LIST_STREET_BASED_ON_POLLING_DIVISION
    case LIST_STREET_BASED_ON_POLLING_DIVISION:
     // debugger
      console.log(action.payload)
      return {
        ...state,
        list_of_street: action.payload
      }

    default:
      return { ...state };
  }
};
