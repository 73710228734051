import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// redux action
import { updatePassword } from './actions';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            otp:"",
            passError: "",
            otpError:"",
            userIDFromStorage: ""
        }
        //this.login = this.login.bind(this);
        //this.onChange = this.onChange.bind(this);
    }

    async componentDidMount(){
        this.setState({
            userIDFromStorage: await localStorage.getItem('userID')
        })
    }

    
    validate = () => {
        let isError = false;
        
        if (this.state.otp == "") {
            isError = true;
            this.setState({ otpError: 'Please enter the OTP !' })
        }

        if (this.state.password == "") {
            isError = true;
            this.setState({ passError: 'Password needs to be filled !' })
        }
        return isError;
    };

    onSubmit = async (e) => {

        //const userID = await localStorage.getItem('userID')

        e.preventDefault();
        const err = this.validate();
        if (!err) {
            const data = {
                userID: this.state.userIDFromStorage,
                password: this.state.password,
                otp_code: this.state.otp
            }

            console.log(data)
            this.props.updatePassword(data, this.props.history)
            //this.props.history.push('/home')
        }

    };

    handleChange = (e) => {
        e.preventDefault()
        console.log(e.target.value)
        this.setState({ role: e.target.value });
    }

    selectedDashboard = (e) => {
        this.setState({
            dashboard: e.target.value
        });
    }


    render() {

        return (
            // <div style={{background: 'url(img/rct-session-banner.jpg) center no-repeat', backgroundSize: 'cover', height:'100vh'}}>
            <div style={{ height: '100vh' }}>
                <section className="position-relative header_bg" style={{ margin: 0, }}>
                    <div className="container-fluid z-index position-relative">

                        <div className="col-md-6 col-lg-4 mt-5 ml-auto mr-auto">
                            <div className="login_logo text-center">
                                <Link className="logo" to="/">
                                    {/* <img src="img/logo.png" alt="" className="img-fluid logo-light" /> */}
                                </Link>
                            </div>

                            <div className="contact_form login_form">
                                <div className="home_content text-center mb-20">
                                    <h1>Update Password</h1>
                                </div>
                                <form>
                                    <div className="row" id="login">
                                        

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input type="password" name="password" id="password" value={this.state.password} className="form-control" onChange={e => this.setState({password:e.target.value})} placeholder="Enter New Password" />
                                                <label className="error">{this.state.passError}</label>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input type="text" name="otp" id="otp" value={this.state.otp} className="form-control" onChange={e => this.setState({otp:e.target.value})} placeholder="Enter OTP" />
                                                <label className="error">{this.state.otpError}</label>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-lg-12 text-center">
                                            {/* {this.refs.loginRole.value === "" ? "heii": ""}                                               */}
                                            <button className="btn btn-success w-100 btn_login" onClick={e => this.onSubmit(e)}><i className="fa fa-sign-in fa-fw" aria-hidden="true"></i>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <NotificationContainer />
                        </div>

                        

                    </div>
                </section>
            </div>
        );
    }
}
//export default Login;
const mapStateToProps = ({ authUser }) => {
    const { user, loading, isAuthenticated } = authUser;
    return { user, loading, isAuthenticated }
}
export default connect(mapStateToProps, {
    updatePassword,
})(Login);