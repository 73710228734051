import React, { Component } from 'react';
import ReactSwipe from 'react-swipe';

class NewsFlash extends Component{

  render(){
    let reactSwipeEl;

    return (
      <div>
        <div className="news_head">Candidate Newsflash</div>
        <ReactSwipe
          className="carousel"
          swipeOptions={{ continuous: false }}
          ref={el => (reactSwipeEl = el)}
        >
          <div>
            <div className="row">
                  <div className="col-sm-5">
                      <img src="../img/candi_img.png" className="img-fluid img-thumbnail" />
                  </div>
                  <div className="col-sm-7 candi_deta">
                      <h3>Candidate Name</h3>
                      <h4>Constituency Name</h4>
                      <h4>Party Name</h4>
                      <h2>Votes - 2005451</h2>
                      <p className="text-success">Status (Winner)</p>
                  </div>
            </div>
          </div>

          <div>
            <div className="row">
                  <div className="col-sm-5">
                      <img src="../img/candi_img.png" className="img-fluid img-thumbnail" />
                  </div>
                  <div className="col-sm-7 candi_deta">
                      <h3>Candidate Name</h3>
                      <h4>Constituency Name</h4>
                      <h4>Party Name</h4>
                      <h2>Votes - 2005451</h2>
                      <p className="text-danger">Status (Looser)</p>
                  </div>
            </div>
          </div>
          
          <div>
            <div className="row">
                  <div className="col-sm-5">
                      <img src="../img/candi_img.png" className="img-fluid img-thumbnail" />
                  </div>
                  <div className="col-sm-7 candi_deta">
                      <h3>Candidate Name</h3>
                      <h4>Constituency Name</h4>
                      <h4>Party Name</h4>
                      <h2>Votes - 2005451</h2>
                      <p className="text-success">Status (Winner)</p>
                  </div>
            </div>
          </div>
        </ReactSwipe>
        
        <div className="news_controls">
          <button onClick={() => reactSwipeEl.prev()}><i className="fa fa-chevron-left"></i></button>
          <button onClick={() => reactSwipeEl.next()}><i className="fa fa-chevron-right"></i></button>
        </div>
      </div>
  )

  }
}

export default NewsFlash;
