import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';
// redux action
import * as actions from '../../actions';
import { connect } from 'react-redux';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class Survey extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      selectedConst: '',
      selected_polling_div: '',
      polling_div: [],
      selectedTeam: '',
      survey_name: '',
      description: '',
      streetID: '',
      is_active: false,
      survey_res: [],
      team_list: [],
      selected_street: [],
      street_list: [],
      errors: {}
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  //Call Api for consituency List and survey List
  componentDidMount() {
    this.setState({
      constituencyID: localStorage.getItem('constituencyID'),
      pollingDivisionID: localStorage.getItem('pollingDivisionID')
    }, () => {
      console.log('Polling Div');
      console.log(this.state.pollingDivisionID);
      this.props.getSteet(this.state.pollingDivisionID);
    })
    this.props.getSurveyList();
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    //Get survey List
    if (nextProps.survey_res !== prevState.survey_res) {
      return { survey_res: nextProps.survey_res };
    }


    //Get Street List
    if (nextProps.street_list !== prevState.street_list) {
      return { street_list: nextProps.street_list };
    } else return null;
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, selectedConst: '' });
  }





  //set state on text change
  onChange = e =>
    this.setState({ [e.target.name]: e.target.value, errors: {} });

  //StreetChanged
  streetnewChanged = e => {
    let selected = [...this.refs.streetList.selectedOptions].map(o => o.value);
    this.setState({ selected_street: selected, errors: {} });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    const {
      survey_name,
      description,
      selectedConst,
      selected_polling_div,
      selectedTeam,
      selected_street,
      is_active
    } = this.state;
    //Name
    if (!survey_name) {
      formIsValid = false;
      errors['survey_name'] = 'Cannot be empty';
    }
    if (!description) {
      formIsValid = false;
      errors['description'] = 'Cannot be empty';
    }

    if (!selectedConst) {
      formIsValid = false;
      errors['selectedConst'] = 'Please select constituency';
    }

    if (selected_polling_div.length == 0) {
      formIsValid = false;
      errors['selected_polling_div'] = 'Please select polling division';
    }


    this.setState({ errors: errors });
    return formIsValid;
  }

  //Add Survey
  _addSurvey = () => {
    if (this.handleValidation()) {
      const addsurveyData = {
        survey_name: this.state.survey_name,
        description: this.state.description,
        constituencyID: this.state.selectedConst,
        pollingDivisionID: this.state.selected_polling_div,
        campaingnTeamID: this.state.selectedTeam,
        streetID: this.state.selected_street,
        is_active: this.state.is_active
      };
      console.log('Add survey Data');
      console.log(addsurveyData);
      this.props.addSurvey(addsurveyData, this.props.history);
      this.setState({ modalIsOpen: false });
    } else {
      console.log(this.state.errors);
    }
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <div className="col-sm-6 printfleft">
              <h1 className="text-primary ">Survey</h1>
            </div>
            <div className="col-sm-6 printfright">
              <div className="title_right_sec">
                <div className="form-group printHide">
                  <button class="btn_cust btn-primary ml-3"  onClick={() => window.print()}>Download and Print Report</button>
                </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary ">Survey</h1>
              <button className="btn_close" onClick={this.closeModal}>
                x
              </button>
            </div>

            <div className="modal_body form-horizontal">
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  Survey Name:
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="survey_name"
                    placeholder="Enter Survey name"
                    onChange={this.onChange}
                  />
                  <span className="error">
                    {this.state.errors['survey_name']}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <label
                  className="control-label col-sm-4 pr-0"
                  htmlFor="description"
                >
                  Description:
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="form-control"
                    id="description"
                    placeholder="Enter Description"
                    rows="3"
                    name="description"
                    onChange={this.onChange}
                  ></textarea>
                  <span className="error">
                    {this.state.errors['description']}
                  </span>
                </div>
              </div>

              {/* <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0">
                  Team Name:
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-control"
                    name="TeamName"
                    onChange={this.TeamChanged}
                  >
                    <option value="">Select a Team</option>
                    {this.state.team_list.map((data, index) => {
                      return (
                        <option value={data._id}>{data.campaign_name}</option>
                      );
                    })}
                  </select>
                  <span className="error">
                    {this.state.errors['selectedTeam']}
                  </span>
                </div>
              </div> */}

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  Assign Streets:
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-control"
                    multiple
                    ref="streetList"
                    name="streetList"
                    onChange={this.streetnewChanged}
                  >
                    {this.state.street_list.map((data, index) => {
                      return (
                        <option value={data._id}>{data.street_name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-8 offset-sm-4">
                  <label style={{ display: 'flex' }}>
                    <Toggle
                      defaultChecked={this.state.is_active}
                      icons={false}
                      onChange={() =>
                        this.setState({ is_active: !this.state.is_active })
                      }
                    />
                    &nbsp; Switch toggle to active Global
                  </label>
                </div>
              </div>

              <div className="row mb-3">
                <div className="offset-4 col-sm-8">
                  <button
                    className="btn btn-sm btn-success"
                    onClick={this._addSurvey}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <div className="row mt-5 px-4">
            {this.props.survey_res.length > 0
              ? this.props.survey_res.map(data => {
                return (
                  <div className="col-sm-4">
                    <div className="shadow_box surveyBox p-0">
                      <div className="card">
                        <div className="card-body">
                          <Link
                            to={{
                              pathname: '/survey-question/',
                              state: {
                                survey_id: data._id,
                                survey_name: data.survey_name,
                                description: data.description,
                                is_active: data.is_active
                              }
                            }}
                          >
                            <div className="row">
                              <div className="col-sm-9">
                                <h5 className="card-title text-primary">
                                  {data.survey_name}
                                </h5>
                                <p className="card-text">
                                  {data.description}
                                </p>
                              </div>

                              <div
                                className={
                                  data.is_active
                                    ? 'status_corner text-success'
                                    : 'status_corner text-danger'
                                }
                              >
                                <i className="fa fa-circle"></i>{' '}
                                {data.is_active ? 'Live' : 'Not Live'}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ survey, campTeam }) => {
  const { survey_res, street_list } = survey;
  const { constituency_list, polling_div, team_list } = campTeam;

  return { survey_res, constituency_list, polling_div, team_list, street_list };
};
export default connect(
  mapStateToProps,
  actions
)(Survey);
