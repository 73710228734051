import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import MapConstituencyListing from '../Components/MapConstituencyListing';


class ConstituencyListingDetail extends Component {
  constructor() {
    super();

    this.state = {
      constituency_list: []
    };

  }


  //Call Api for consituency Liist
  componentDidMount() {
    this.props.listConsituency();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.constituency_list !== prevState.constituency_list) {
      return { constituency_list: nextProps.constituency_list };
    } else return null;
  }

  render() {
    console.log('*************');
    console.log(this.state.constituency_list);
    console.log('*************');
    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4">
            <div className="col-sm-4">
              <h1 className="text-primary ">Constituency Listing</h1>
            </div>
          </div>

          <div className="row px-4 mt-5">
            <div className="col-sm-12">
              <MapConstituencyListing />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ campTeam }) => {
  const { constituency_list } = campTeam;

  return { constituency_list };
};

export default connect(mapStateToProps, actions)(ConstituencyListingDetail);
