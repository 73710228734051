import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AmCharts from "@amcharts/amcharts3-react";

import Map from '../Components/Map';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Moment from 'react-moment';
import moment from 'moment'


class ManagePayments extends Component {

  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      perSurveyAmount: 0,
      pollWorkerPerHourSittingCost: 0,
      vehicleProviderPerKMCost: 0,
      payment_error: '',
      managePaymentID: "",
      is_called: 0,
      paymentLastValuesUpdated: "",
      updateAt: ""
    };

  }
  //Get Payment Mehtod
  componentDidMount() {
    this.props.getPaymentRate();
    this.setState({ is_called: 1 })
  }


  componentDidUpdate(nextProps, prevState) {
    if (this.props.managePaymentID !== prevState.managePaymentID) {
      console.log('************')
      console.log(this.props.managePaymentID)
      console.log('************')
      this.setState({
        perSurveyAmount: this.props.perSurveyAmount,
        pollWorkerPerHourSittingCost: this.props.pollWorkerPerHourSittingCost,
        vehicleProviderPerKMCost: this.props.vehicleProviderPerKMCost,
        managePaymentID: this.props.managePaymentID,
        paymentLastValuesUpdated: this.props.paymentLastValuesUpdated,
        updateAt: this.props.updatedAt

      });
    }
  }

  updatePayment = () => {
    console.log('Update clicked');
    console.log(this.state.perSurveyAmount);
    console.log(this.state.pollWorkerPerHourSittingCost);
    console.log(this.state.vehicleProviderPerKMCost);
    const { perSurveyAmount, pollWorkerPerHourSittingCost, vehicleProviderPerKMCost } = this.state;
    if (perSurveyAmount == 0 && pollWorkerPerHourSittingCost == 0 && vehicleProviderPerKMCost == 0) {
      this.setState({ payment_error: 'Please insert value before submitting' });
      return false;
    }
    else {
      var updateData = { perSurveyAmount: perSurveyAmount, pollWorkerPerHourSittingCost: pollWorkerPerHourSittingCost, vehicleProviderPerKMCost: vehicleProviderPerKMCost }
      if (this.state.managePaymentID != "") {
        updateData.managePaymentID = this.state.managePaymentID;
      }
      console.log(updateData);
      this.props.updatePayment(updateData)
      var data = "Latest Payment Last Values Updated on : 25 August, 2019 <br/>Previous data will not take this effect.";
    }

  }

  render() {
    console.log('Per survey Amt');
    console.log(this.props.perSurveyAmount);
    const config = {
      "type": "pie",
      "labelRadius": -55,
      "labelText": "[[percents]]%",
      "fontSize": 14,
      "dataProvider": [{
        "country": "Poll Division 1",
        "litres": 80,
        "color": '#fb5858',
      }, {
        "country": "Poll Division 2",
        "litres": 30,
        "color": '#f6fb61',
      }],
      "valueField": "litres",
      "titleField": "country",
      "colorField": "color",
      "radius": "35%",
      "legend": {
        "position": "right",
        "generateFromData": true,
        "marginTop": 200,
      }
    };

    return (
      <div>

        <div className="container-fluid">
          <div className="row my-3 px-4">
            <div className="col-sm-6">
              <h1 className="text-primary ">Manage Payments</h1>
            </div>
          </div>


          <div className="row px-4">
            <div className="col-sm-6">


              <div className="shadow_box mb-3 p-0">
                <div className="card">
                  <ul className="list-group list-group-flush listContainer">
                    <li className="list-group-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      Per Survey Amount

                                    <input type="text" className="form-control" placeholder="$" style={{ width: 'auto' }} value={this.state.perSurveyAmount} onChange={(e) => this.setState({ perSurveyAmount: e.target.value, payment_error: '' })} />
                    </li>
                    <li className="list-group-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      Poll worker Per Hour Sitting Cost

                                    <input type="text" className="form-control" placeholder="$" style={{ width: 'auto' }} value={this.state.pollWorkerPerHourSittingCost} onChange={(e) => this.setState({ pollWorkerPerHourSittingCost: e.target.value, payment_error: '' })} />
                    </li>
                    <li className="list-group-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      Vehicle Provider Per Trip Cost

                                    <input type="text" className="form-control" placeholder="$" style={{ width: 'auto' }} value={this.state.vehicleProviderPerKMCost} onChange={(e) => this.setState({ vehicleProviderPerKMCost: e.target.value, payment_error: '' })} />
                    </li>
                  </ul>
                </div>
              </div>
              <label className="error">{this.state.payment_error}</label>
              <br />
              <p className="error"> Latest Payment Last Values Updated On : <Moment format="MMMM Do YYYY, h:mm:ss a">
                {this.props.updatedAt}
              </Moment></p>
              <p className="error">Previous data will not take this effect.</p>


              <button className="btn btn-success btn-sm mt-3" onClick={this.updatePayment}>Update</button>


            </div>
          </div>




        </div>
        <NotificationContainer />

      </div>
    )
  }
}

const mapStateToProps = ({ payment }) => {
  console.log(payment)
  const { perSurveyAmount, pollWorkerPerHourSittingCost, vehicleProviderPerKMCost, managePaymentID, paymentLastValuesUpdated, updatedAt, allvalues } = payment;
  return { perSurveyAmount, pollWorkerPerHourSittingCost, vehicleProviderPerKMCost, managePaymentID, paymentLastValuesUpdated, updatedAt, allvalues };
};
export default connect(
  mapStateToProps,
  actions
)(ManagePayments);
