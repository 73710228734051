import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';


import Map from '../Components/SurveyMap';

import MapSurvey2 from '../Components/Map';


class AnalyticsGoogleSurvey extends Component {

    constructor(props) {
        super(props);
        this.childRef = null
        this.state = {
            selectedYear: '2019',
            searchString: 'unc'
        };
    }

    componentDidMount() {
        this.props.getGoogleTrendsData('unc', '2019')
    }

    yearChange = e => this.setState({
        selectedYear: e.target.value
    })

    searchString = e => {
        this.childRef.getWrappedInstance().searchString(e.target.value)
        this.setState({
            searchString: e.target.value
        })
    }


    render() {



        return (
            <div>
                <div className="container-fluid">
                    <div className="row my-3 px-4 printRow">
                        <div className="col-sm-4 printfleft">
                            <h1 className="text-primary">Google vs Survey</h1>
                        </div>
                        <div className="col-sm-8 printfright">
                            <button class="btn_cust btn-primary pull-right mt-3 ml-2 printHide"  onClick={() => window.print()}>Download and Print Report</button>
                           
                            <Link className="btn_cust btn-primary pull-right mt-3" to="#" onClick={() => this.props.getGoogleTrendsData(this.state.searchString, this.state.selectedYear)}>Enter</Link>
                            <div className="form-group mt-3">
                                <select className="form-control pull-right mr-2" id="const_sele">

                                    <option>Jan-Dec</option>
                                </select>
                            </div>
                            <div className="form-group mt-3">
                                <select
                                    className="form-control pull-right mr-2"
                                    id="const_sele"
                                    onChange={this.yearChange}
                                >
                                    <option value='2019'>2019</option>
                                    <option value='2020'>2020</option>
                                </select>
                            </div>

                            <div className="form-group mt-3">
                                <select
                                    className="form-control pull-right  mr-2"
                                    id="const_sele"
                                    onChange={this.searchString}
                                >
                                    <option value="unc">UNC</option>
                                    <option value="pnm">PNM</option>
                                    <option value="kamla persad">Kamla Persad</option>
                                    <option value="keith rowley">Keith Rowley</option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <div className="row px-4 mt-5">
                        <div className="col-sm-6">
                            <h3 className="text-center">Google Survey</h3>
                            <div className="mapshadow_box">
                                <div className="map_cont survey_map">
                                    <Map ref={ref => this.childRef = ref} newValue={this.state.searchString} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <h3 className="text-center">EMS Survey</h3>
                            <div className="mapshadow_box">
                                <div className="map_cont survey_map">
                                    <MapSurvey2 />
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>
        )
    }
}

const mapStateToProps = ({ campTeam, authUser }) => {
    const { constituency_list } = campTeam;
    const { updatedYearValue, election_result, marginalColorValue } = authUser

    return { constituency_list, updatedYearValue, election_result, marginalColorValue };
};

export default connect(mapStateToProps, actions)(AnalyticsGoogleSurvey);

