import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import { connect } from 'react-redux';
import * as actions from '../actions';
import { NotificationContainer } from 'react-notifications';

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import moment from 'moment'
import Datatable from 'react-bs-datatable';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};



class Top10PDsPartywise extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
      role: '1',
      poll_workerCMg: [],
      selectedPollingDiv: "",
      selectedStreet: "",
      v_id: "",
      top_10_party_wise_data: [],
      selectedPartyValue: "UNC",
      selectedContituency: "5d885773b3346c28f2d03158",
      selectedYearValue: "2015"
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(id) {
    this.setState({
      modalIsOpen: true,
      v_id: id
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    //Get Polling Division Name
    if (nextProps.poll_workerCMg !== prevState.poll_workerCMg) {
      return { poll_workerCMg: nextProps.poll_workerCMg };
    }

    if (nextProps.top_10_party_wise_data !== prevState.top_10_party_wise_data) {
      return { top_10_party_wise_data: nextProps.top_10_party_wise_data };
    }
    else return null;
  }

  componentDidMount() {
    this.props.listConsituency();
    this.props.getTop10PDPartyWise(this.state.selectedContituency, this.state.selectedPartyValue, this.state.selectedYearValue)
    this.props.getPWByManager()
  }

  componentWillMount() {

    this.setState({
      role: localStorage.getItem('role')
    })
    console.log(localStorage.getItem('role'))

  }
  //On constituency Changed
  ConstituencyChanged = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    if (e.target.value == '') {
      this.setState({ selectedContituency: e.target.value, selectedStreet: '' })
    } else {
      this.setState({ selectedContituency: e.target.value })
    }
    console.log(this.state.selectedPartyValue)
    debugger

    this.props.getTop10PDPartyWise(e.target.value, this.state.selectedPartyValue, this.state.selectedYearValue)

    // if (e.target.value == '') {
    //   this.setState({ selectedContituency: e.target.value, selectedStreet: '' })
    //   this.props.getPollingDiv(e.target.value);
    //   this.props.getSteet();
    //   this.props.getPWByManager(qryString);


    // } else {

    //   this.setState({ selectedContituency: e.target.value })
    //   this.props.getPollingDiv(e.target.value);
    //   qryString = '?constituencyID=' + e.target.value;
    //   this.props.getPWByManager(qryString);
    // }
  };


  //SelectedParty Change Here
  selectedPartyHere = e => {
    var qryString = "";
    console.log('Party Selected');
    console.log(e.target.value);
    this.setState({
      selectedPartyValue: e.target.value
    })

    this.props.getTop10PDPartyWise(this.state.selectedContituency, e.target.value, this.state.selectedYearValue)
  };

  //Selected Year Change Here
  selectedYearHere = e => {
    var qryString = "";
    console.log('Year Selected');
    console.log(e.target.value);
    this.setState({
      selectedYearValue: e.target.value
    })

    this.props.getTop10PDPartyWise(this.state.selectedContituency, this.state.selectedPartyValue, e.target.value)
  };



  PollingDivChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedPollingDiv: e.target.value, selectedStreet: '' })
      this.props.getSteet();
      qryString = '?constituencyID=' + this.state.selectedContituency;
      this.props.getPWByManager(qryString);


    } else {

      this.setState({ selectedPollingDiv: e.target.value })
      this.props.getSteet(e.target.value);
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + e.target.value;
      this.props.getPWByManager(qryString);
    }
  };
  //On street Changed
  streetChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv;

      this.props.getPWByManager(qryString);

    } else {

      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv + '&streetID=' + e.target.value;

      this.props.getPWByManager(qryString);

    }
  }
  render() {
    console.log('Top 10')
    console.log(this.state.top_10_party_wise_data)
    console.log('**********')
    console.log(this.props.street_list)
    const header = [

      { title: 'S.No', prop: 'sno', sortable: true },
      { title: 'PD No', prop: 'pdno', sortable: true },
      { title: 'No. of Registered Voters 2021', prop: 'registered', sortable: true },
      { title: 'No of voted', prop: 'novoted', sortable: true },
      { title: '% Voted', prop: 'voted', sortable: true },
      { title: 'Party Name Votes', prop: 'partvote', sortable: true },
      { title: 'Party Name Share %', prop: 'partshare', sortable: true },
      { title: 'Party Name2 Votes', prop: 'part2vote', sortable: true },
      { title: 'Party Name2 Share %', prop: 'part2share', sortable: true },
      { title: 'Others Votes', prop: 'othervote', sortable: true },
      { title: 'Others Vote Share%', prop: 'othershare', sortable: true },
    ];



    const body = [
      {
        sno: '1',
        pdno: 'Pushpendra',
        registered: '100',
        novoted: '20',
        voted: '30%',
        partvote: '52',
        partshare: '22',
        part2vote: '22',
        part2share: '22',
        othervote: '20',
        othershare: '50',
      },
      {
        sno: '1',
        pdno: 'Pushpendra',
        registered: '100',
        novoted: '20',
        voted: '30%',
        partvote: '52',
        partshare: '22',
        part2vote: '22',
        part2share: '22',
        othervote: '20',
        othershare: '50',
      },

    ];

    const onSortFunction = {
      date(columnValue) {
        return moment(columnValue, 'Do MMMM YYYY').valueOf();
      },
    };

    const customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };



    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <NotificationContainer />
            <div className="col-sm-4 printfleft">
              <h1 className="text-primary ">Top 10 PDs Party Wise </h1>
            </div>

            <div className="col-sm-8 printfright">
              <div className="title_right_sec">

                <div className="mr-3 mb-3">
                  <select
                    className="form-control pull-right"
                    id="const_sele"
                    name="consti"
                    onChange={this.ConstituencyChanged}
                    value={this.state.selectedContituency}
                  >
                    <option value="">Select a Constituency</option>
                    {this.props.constituency_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.constituencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.selectedYearHere}
                    value={this.state.selectedYearValue}
                  >
                    <option value="2002">2002</option>
                    <option value="2007">2007</option>
                    <option value="2010">2010</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>

                  </select>
                </div>

                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.selectedPartyHere}
                    value={this.state.selectedPartyValue}
                  >
                    <option value="UNC">Top 10 UNC</option>
                    <option value="PNM">Top 10 PNM</option>
                  </select>
                </div>


                <div className="form-group printHide">
                  <button class="btn_cust btn-primary" onClick={() => window.print()}>Download and Print Report</button>
                </div>
              </div>
            </div>

          </div>


          <div className="row mt-5 px-4">
            <div className="col-sm-6">
              <h5>Total No of Polling Division : {this.state.top_10_party_wise_data.length}</h5>
            </div>
            <div className="col-sm-6">
              <h5>Top 10 {this.state.selectedPartyValue} Polling Divisions</h5>
            </div>

            <div className="col-sm-12 mb-4 mt-3">
              <div className="tableScroll">
                <table class="table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>PD No</th>
                      <th>No. of Registered Voters {this.state.selectedYearValue}</th>
                      <th>No. of Voted</th>
                      <th>% Voted</th>
                      {this.state.selectedPartyValue == 'UNC' ? <th>UNC Votes</th> : <th>PNM Votes</th>}
                      {this.state.selectedPartyValue == 'UNC' ? <th>UNC Share %</th> : <th>PNM Share %</th>}
                      {this.state.selectedPartyValue != 'UNC' ? <th>UNC Votes</th> : <th>PNM Votes</th>}
                      {this.state.selectedPartyValue != 'UNC' ? <th>UNC Share %</th> : <th>PNM Share %</th>}

                      <th>Others Votes</th>
                      <th>Others Vote Share%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.top_10_party_wise_data.length == 0 ?

                        <h6>No Data Available</h6>
                        :

                        this.state.top_10_party_wise_data.map((data, index) => {
                          if (index > 9) {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{data.pollingDivisionID}</td>
                                <td>{data.totalVotersinArea}</td>
                                <td>{data.totalValidVotes}</td>
                                {/* <td>{data.percentageVoted.toFixed(2)}</td> */}
                                {this.state.selectedPartyValue == 'UNC' ? <td>{data.unc}</td> : <td>{data.pnm}</td>}
                                {this.state.selectedPartyValue == 'UNC' ? <td>{data.UNCVotesharepercentage.toFixed(2)}</td> : <td>{data.PNMVotesharepercentage.toFixed(2)}</td>}
                                {this.state.selectedPartyValue != 'UNC' ? <td>{data.unc}</td> : <td>{data.pnm}</td>}
                                {this.state.selectedPartyValue != 'UNC' ? <td>{data.UNCVotesharepercentage.toFixed(2)}</td> : <td>{data.PNMVotesharepercentage.toFixed(2)}</td>}

                                <td>{data.others}</td>
                                <td>{data.OthersVotesharepercentage}</td>
                              </tr>
                            )
                          } else {
                            return (
                              <tr style={{ backgroundColor: '#00FFFF' }}>
                                <td>{index + 1}</td>
                                <td>{data.pollingDivisionID}</td>
                                <td>{data.totalVotersinArea}</td>
                                <td>{data.totalValidVotes}</td>
                                <td>{data.percentageVoted.toFixed(2)}</td>
                                {this.state.selectedPartyValue == 'UNC' ? <td>{data.unc}</td> : <td>{data.pnm}</td>}
                                {this.state.selectedPartyValue == 'UNC' ? <td>{data.UNCVotesharepercentage.toFixed(2)}</td> : <td>{data.PNMVotesharepercentage.toFixed(2)}</td>}
                                {this.state.selectedPartyValue != 'UNC' ? <td>{data.unc}</td> : <td>{data.pnm}</td>}
                                {this.state.selectedPartyValue != 'UNC' ? <td>{data.UNCVotesharepercentage.toFixed(2)}</td> : <td>{data.PNMVotesharepercentage.toFixed(2)}</td>}

                                <td>{data.others}</td>
                                <td>{data.OthersVotesharepercentage}</td>
                              </tr>
                            )
                          }

                        })

                    }


                  </tbody>
                </table>
              </div>
            </div>


          </div>


        </div>


      </div>
    )
  }
}

const mapStateToProps = ({ authUser, campTeam, survey }) => {
  const { user, isAuthenticated } = authUser;
  const { loading, constituency_list, polling_div, poll_workerCMg } = campTeam;
  const { street_list, top_10_party_wise_data, loadingPartyWise } = survey;

  return { user, loading, isAuthenticated, constituency_list, polling_div, street_list, top_10_party_wise_data, loadingPartyWise, poll_workerCMg }
}
export default connect(mapStateToProps, actions)(Top10PDsPartywise);

