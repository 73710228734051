import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import AmCharts from "@amcharts/amcharts3-react";

class Serial extends Component {

  constructor(props) {
    super(props);
    this.childRef = null
    this.state = {
      dataForAll: []
    };
  }

  componentDidUpdate(nextProps, prevState) {

    console.log('======Serial2 From component Did Mount===========')
    console.log('PNM')
    console.log(nextProps.trendsResults)
    console.log(this.props.trendsResults) //pnm
    console.log('UNC')
    console.log(nextProps.trendsResults_1)
    console.log(this.props.trendsResults_1) //unc
    console.log('=================')

    if ((this.props.trendsResults !== nextProps.trendsResults) && (this.props.trendsResults !== nextProps.trendsResults_1)) {
      this.prepareData();
    }

  }



  prepareData = () => {
    console.log('prepare Data')

    const list = ['TT-ARI', 'TT-CHA', ' TT-CTT', 'TT-DMN', 'TT-ETO', 'TT-PED', 'TT-POS', 'TT-PRT', 'TT-PTF', 'TT-RCM', 'TT-SFO', 'TT-SGE', 'TT-SIP', 'TT-SJL', 'TT-TUP', 'TT-WTO']
    let arrayNew = []

    list.forEach(element => {
      console.log(element)



      this.props.trendsResults.filter(
        data => data.geoCode === element ? this.props.trendsResults_1.filter(
          data2 => data2.geoCode === element ? arrayNew.push(

            {
              "country": data.geoName,
              "visits": data2.value[0],
              "visits2": data.value[0],
              "color": "#fccc08",
              "color2": "#e8000c"
            }

          ) : null
        ) : null
      );


    })
    console.log('**********')
    console.log(arrayNew)
    this.setState({
      dataForAll: arrayNew
    })

  }

  render() {


    const config = {
      "type": "serial",
      "theme": "none",
      "dataProvider": this.state.dataForAll,
      "valueAxes": [{
        "gridColor": "#FFFFFF",
        "gridAlpha": 0.2,
        "dashLength": 0,
        "fontSize": 15
      }],
      "gridAboveGraphs": true,
      "startDuration": 1,
      "colorField": "#ff0000",
      "graphs": [{
        "fillColorsField": "color",
        "balloonText": "[[category]]: <b>[[value]]</b>",
        "fillAlphas": 0.8,
        "lineAlpha": 0.2,
        "type": "column",
        "valueField": "visits"
      }, {
        "fillColorsField": "color2",
        "balloonText": "[[category]]: <b>[[value]]</b>",
        "fillAlphas": 0.8,
        "lineAlpha": 0.2,
        "type": "column",
        "valueField": "visits2"
      }],
      "chartCursor": {
        "categoryBalloonEnabled": false,
        "cursorAlpha": 0,
        "zoomable": false
      },
      "categoryField": "country",
      "categoryAxis": {
        "gridPosition": "start",
        "gridAlpha": 0,
        "tickPosition": "start",
        "tickLength": 20,
        "fontSize": 15,
        "labelRotation": 45
      },
    };

    return (
      <div>
        <AmCharts.React className="amchart_serial" options={config} />
      </div>
    )

  }
}

const mapStateToProps = ({ campTeam, authUser }) => {
  const { constituency_list } = campTeam;
  const { updatedYearValue, election_result, marginalColorValue, trendsResults, trendsResults_1 } = authUser

  return { constituency_list, updatedYearValue, election_result, marginalColorValue, trendsResults, trendsResults_1 };
};

export default connect(mapStateToProps, actions)(Serial);

