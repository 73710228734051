import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import { connect } from 'react-redux';
import * as actions from '../actions';
import { NotificationContainer } from 'react-notifications';

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import moment from 'moment'
import Datatable from 'react-bs-datatable';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};



class TenNewVotersPDwise extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
      role: '1',
      poll_workerCMg: [],
      selectedContituency: "5d885773b3346c28f2d0317e",
      selectedPollingDiv: "",
      selectedStreet: "",
      v_id: "",
      ten_new_voters_pd_wise: [],
      selectedPreviousYearValue: "",
      selectedNewYearValue: "",
      selectedYearValue: "2015",
      selectedYearValue2: "2019"
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(id) {
    this.setState({
      modalIsOpen: true,
      v_id: id
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    //Get Polling Division Name
    if (nextProps.poll_workerCMg !== prevState.poll_workerCMg) {
      return { poll_workerCMg: nextProps.poll_workerCMg };
    }

    if (nextProps.ten_new_voters_pd_wise !== prevState.ten_new_voters_pd_wise) {
      return { ten_new_voters_pd_wise: nextProps.ten_new_voters_pd_wise };
    }
    else return null;
  }

  componentDidMount() {
    this.props.listConsituency();

    this.props.getPWByManager()
    this.props.get10NewVotersPDwiseData(this.state.selectedContituency, this.state.selectedYearValue, this.state.selectedYearValue2)
  }

  componentWillMount() {

    this.setState({
      role: localStorage.getItem('role')
    })
    console.log(localStorage.getItem('role'))

  }
  //On constituency Changed
  ConstituencyChanged = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    this.setState({ selectedContituency: e.target.value })

    this.props.get10NewVotersPDwiseData(e.target.value, this.state.selectedYearValue, this.state.selectedYearValue2)
    this.props.loaderValueChange()
    // if (e.target.value == '') {
    //   this.setState({ selectedContituency: e.target.value, selectedStreet: '' })
    //   this.props.get10NewVotersPDwiseData(e.target.value)

    // } else {

    //   this.props.get10NewVotersPDwiseData(this.state.selectedContituency)
    // }
  };

  //On selectedPreviousYear Changed
  selectedPreviousYear = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    if (e.target.value == '') {
      this.setState({ selectedPreviousYearValue: e.target.value }, () => console.log(this.state.selectedPreviousYearValue))


    } else {


    }
  };


  //On selectedPreviousYear Changed
  selectedNewYear = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    if (e.target.value == '') {
      this.setState({ selectedNewYearValue: e.target.value }, () => console.log(this.state.selectedNewYearValue))


    } else {



    }
  };


  PollingDivChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedPollingDiv: e.target.value, selectedStreet: '' })
      this.props.getSteet();
      qryString = '?constituencyID=' + this.state.selectedContituency;
      this.props.getPWByManager(qryString);


    } else {

      this.setState({ selectedPollingDiv: e.target.value })
      this.props.getSteet(e.target.value);
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + e.target.value;
      this.props.getPWByManager(qryString);
    }
  };
  //On street Changed
  streetChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv;

      this.props.getPWByManager(qryString);

    } else {

      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv + '&streetID=' + e.target.value;

      this.props.getPWByManager(qryString);

    }
  }


  //Selected Year Change Here
  selectedYearHere = e => {
    var qryString = "";
    console.log('Year Selected');
    console.log(e.target.value);
    this.setState({
      selectedYearValue: e.target.value
    })

    this.props.get10NewVotersPDwiseData(this.state.selectedContituency, e.target.value, this.state.selectedYearValue2)
    this.props.loaderValueChange()
  };


  //Selected Year2 Change Here
  selectedYearHere2 = e => {
    var qryString = "";
    console.log('Year Selected');
    console.log(e.target.value);
    this.setState({
      selectedYearValue2: e.target.value
    })

    this.props.get10NewVotersPDwiseData(this.state.selectedContituency, this.state.selectedYearValue, e.target.value)
    this.props.loaderValueChange()
  };

  render() {
    console.log('**********')
    console.log(this.props.street_list)
    console.log('10 New Voters from render')
    console.log(this.state.ten_new_voters_pd_wise)
    console.log(this.props.ten_new_voters_pd_wise)

    var highestValue = 0;
    if (this.state.ten_new_voters_pd_wise.length != 0) {
      for (var i = 0; i < this.state.ten_new_voters_pd_wise.length; i++) {
        if (this.state.ten_new_voters_pd_wise[i].noOfNewVoters > highestValue) {
          highestValue = this.state.ten_new_voters_pd_wise[i].noOfNewVoters
        }
      }
    }

    console.log(`Higest Value is ${highestValue}`)
    console.log(this.state.selectedContituency)

    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <NotificationContainer />
            <div className="col-sm-12">
              <h1 className="text-primary ">Top 10 New Voters PD wise</h1>
            </div>

            <div className="col-sm-12">
              <div className="title_right_sec">

                <div className="mr-3 mb-3">
                  <select
                    className="form-control pull-right"
                    id="const_sele"
                    name="consti"
                    onChange={this.ConstituencyChanged}
                    value={this.state.selectedContituency}
                  >
                    <option value="">Select a Constituency</option>
                    {this.props.constituency_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.constituencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.selectedYearHere}
                    value={this.state.selectedYearValue}
                  >
                    <option value="2002">2002</option>
                    <option value="2007">2007</option>
                    <option value="2010">2010</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>

                  </select>
                </div>

                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.selectedYearHere2}
                    value={this.state.selectedYearValue2}
                  >
                    <option value="2002">2002</option>
                    <option value="2007">2007</option>
                    <option value="2010">2010</option>
                    <option value="2015">2015</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                  </select>
                </div>


                <div className="form-group printHide">
                  <button class="btn_cust btn-primary" onClick={() => window.print()}>Download and print Report</button>
                </div>
              </div>
            </div>

          </div>

          {(!(this.props.loading10NewVoters)) ?

            <div className="row mt-5 px-4">
              <div className="col-sm-6">
                <h5>Highest No. Of New Voters : {highestValue}</h5>
              </div>

              <div className="col-sm-12 mb-4 mt-3">
                <div className="shadow_box tableScroll">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>PD No.</th>
                        <th>No. Of Registered Voters {this.state.selectedYearValue}</th>
                        <th>No. Of Registered Voters {this.state.selectedYearValue2}</th>
                        <th>No. Of New Voters</th>
                        <th>% Of New Voters</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.ten_new_voters_pd_wise.map((data, index) => {

                          if (index > 9) {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{data.pollingDivisionID}</td>
                                <td>{data.noOfRegisteredVotersYear1}</td>
                                <td>{data.noOfRegisteredVotersYear2}</td>
                                <td>{data.noOfNewVoters}</td>
                                <td>{data.PercentageOfNewVoters.toFixed(2)}</td>
                              </tr>
                            )
                          }

                          return (
                            <tr style={{ backgroundColor: '#00FFFF	' }}>
                              <td>{index + 1}</td>
                              <td>{data.pollingDivisionID}</td>
                              <td>{data.noOfRegisteredVotersYear1}</td>
                              <td>{data.noOfRegisteredVotersYear2}</td>
                              <td>{data.noOfNewVoters}</td>
                              <td>{data.PercentageOfNewVoters.toFixed(2)}</td>
                            </tr>
                          )

                        })
                      }

                    </tbody>
                  </table>
                </div>
              </div>


            </div>

            :

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          }





        </div>


      </div>
    )
  }
}

const mapStateToProps = ({ authUser, campTeam, survey }) => {
  const { user, isAuthenticated } = authUser;
  const { loading, constituency_list, polling_div, poll_workerCMg } = campTeam;
  const { street_list, ten_new_voters_pd_wise, loading10NewVoters } = survey;

  return { user, loading, isAuthenticated, constituency_list, polling_div, street_list, ten_new_voters_pd_wise, loading10NewVoters, poll_workerCMg }
}
export default connect(mapStateToProps, actions)(TenNewVotersPDwise);

