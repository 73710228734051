import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import { connect } from 'react-redux';
import * as actions from '../../actions';

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import moment from 'moment'
import Datatable from 'react-bs-datatable';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};



class VehicleProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tofuIsReady: false,
            modalIsOpen: false,

            name: '',
            email: '',
            mobile_no: '',
            office_no: '',
            home_address: '',
            office_address: '',
            role:'5',
            is_same_detail_for_driver:'0',
            constituencyID:'',
            pollingDivisionID:'',
            streetID:'',
            campaingnTeamID:'',
            driver_name: '',
            driver_email: '',
            driver_mobile_no: '',
            driver_office_no: '',
            driver_home_address: '',
            driver_office_address: '',
            startHour: '',
            endHour: '',
            registration_no: '',
            sitting_capacity:'',
            errors: {},
            errors2: {}
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleTofuChange = this.handleTofuChange.bind(this);

    }

    async componentDidMount() {
        this.props.vehicalWorkerCManager()
        this.setState({
            constituencyID: await localStorage.getItem('constituencyIDAdmin'),
            pollingDivisionID: await localStorage.getItem('pollingDivisionID')
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
         if (nextProps.vehicle_provider_listCMg !== prevState.vehicle_provider_listCMg) {
            return { vehicle_provider_listCMg: nextProps.vehicle_provider_listCMg };
        }
        else return null;
    }


    openModal() {
        this.setState({ modalIsOpen: true });
    }


    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    handleTofuChange() {
        this.setState({ 
            tofuIsReady: !this.state.tofuIsReady 
        },()=>console.log(this.state.tofuIsReady));
        
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value, errors: {}, errors2: {} })

    handleValidation() {

        let errors = {};
        let formIsValid = true;
        const {
            name,
            email,
            mobile_no,
            office_no,
            home_address,
            office_address,
            registration_no,
            sitting_capacity,
            startHour,
            endHour
        } = this.state;
        //Name
        if (!name) {
          formIsValid = false;
          errors["nameError"] = "Name cannot be empty";
        }
    
        if (!email) {
          formIsValid = false;
          errors["emailError"] = "Email cannot be empty";
        }
    
        if (!mobile_no) {
          formIsValid = false;
          errors["mobile_noError"] = "Mobile number cannot be empty";
        }
    
        if (!office_no) {
          formIsValid = false;
          errors["office_noError"] = "Office number cannot be empty";
        }
    
        if (!home_address) {
          formIsValid = false;
          errors["home_addressError"] = "Home address cannot be empty";
        }
    
        if (!office_address) {
          formIsValid = false;
          errors["office_addressError"] = "Office address cannot be empty";
        }

        if (!registration_no) {
            formIsValid = false;
            errors["registration_noError"] = "Registration number cannot be empty";
        }

        if (!sitting_capacity) {
            formIsValid = false;
            errors["sitting_capacityError"] = "Sitting capacity cannot be empty";
        }

        if (!startHour) {
            formIsValid = false;
            errors["startHourError"] = "Hours Selection cannot be empty";
        }

        if (!endHour) {
            formIsValid = false;
            errors["endHourError"] = "Hours Selection cannot be empty";
        }

    
        this.setState({ 
            errors: errors 
        },()=>{
            if(!this.state.tofuIsReady){
                this.handleDriverValidation()
            }
        });
        return formIsValid;
      }



      handleDriverValidation() {

        let errors2 = {};
        let formIsValid = true;
        const {
            driver_name,
            driver_email,
            driver_mobile_no,
            driver_office_no,
            driver_home_address,
            driver_office_address
        } = this.state;
        
    
        if (!driver_name) {
            formIsValid = false;
            errors2["driver_nameError"] = "Name cannot be empty";
        }

        if (!driver_email) {
            formIsValid = false;
            errors2["driver_emailError"] = "Email cannot be empty";
        }

        if (!driver_mobile_no) {
            formIsValid = false;
            errors2["driver_mobile_noError"] = "Mobile number cannot be empty";
        }

        if (!driver_office_no) {
            formIsValid = false;
            errors2["driver_office_noError"] = "Office number cannot be empty";
        }

        if (!driver_home_address) {
            formIsValid = false;
            errors2["driver_home_addressError"] = "Home address cannot be empty";
        }

        if (!driver_office_address) {
            formIsValid = false;
            errors2["driver_office_addressError"] = "Office address cannot be empty";
        }
    
        this.setState({ errors2: errors2 });
        return formIsValid;
      }






    _addVehicleProvider = () => {
        this.handleValidation()

        const {
            name,
            email,
            mobile_no,
            office_no,
            home_address,
            office_address,
            role,
            is_same_detail_for_driver,
            constituencyID,
            pollingDivisionID,
            driver_name,
            driver_email,
            driver_mobile_no,
            driver_office_no,
            driver_home_address,
            driver_office_address,
            startHour,
            endHour,
            registration_no,
            sitting_capacity
        } = this.state

        const data = {
            name,
            email,
            mobile_no,
            office_no,
            home_address,
            office_address,
            role,
            is_same_detail_for_driver,
            constituencyID,
            pollingDivisionID,
            driver_detail:{
                driver_name: this.state.tofuIsReady ? name : driver_name,
                driver_email: this.state.tofuIsReady ? email : driver_email,
                driver_mobile_no: this.state.tofuIsReady ? mobile_no : driver_mobile_no,
                driver_office_no: this.state.tofuIsReady ? office_no : driver_office_no,
                driver_home_address: this.state.tofuIsReady ? home_address : driver_home_address,
                driver_office_address: this.state.tofuIsReady ? office_address : driver_office_address,
                startHour,
                endHour
            },
            vehicle_detail:{
                registration_no,
                sitting_capacity
            }
        }

        console.log(data)
        this.props.addVehicleProvider(data)
        this.closeModal()
    }




    render() {


        const header = [
            { title: 'Owner Name', prop: 'name', sortable: true },
            {
                title: 'Driver name',
                prop: 'drivername',
                cell: row => row.driver_detail[0].driver_name,
                sortable: true
            },
            {
                title: 'Active Since',
                prop: 'createdAt',
                cell: row => moment(row.createdAt).format('Do MMMM YYYY'),
                sortable: true
            },
            {
                title: 'Last Active',
                prop: 'updatedAt',
                cell: row => moment(row.updatedAt).format('Do MMMM YYYY'),
                sortable: true
            },
            {
                title: 'Constituency',
                prop: 'constituency',
                cell: row => row.constituencyID.constituencyName,
                sortable: true
            },
            {
                title: 'Polling Division',
                prop: 'polling',
                cell: row => row.pollingDivisionID.pollingDivisionName,
                sortable: true
            },
            { title: 'Total Trips', prop: 'totalTrips', sortable: true },
            {
                title: 'Block',
                prop: 'action',
                cell: row => <Toggle
                    defaultChecked={row.is_active}
                    onChange={() => this.props.pollWorkerStatusUpdate(row._id, row.is_active, 'v')} />
            },
            // {
            //     title: 'View',
            //     prop: 'action',
            //     cell: row => <Link to="/vehicle-provider-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link>
            // },
        ];

        const body = [
            {
                sn: '1',
                ownername: 'Pushpendra',
                drivername: 'Pushpendra',
                activesince: '10 August, 2019',
                lastactive: '25 September, 2019',
                vehicle: 'UP 16 AY 2520',
                team: '5',
                constituency: 'constituency name',
                polling: '25',
                trips: '52',
                action: <div style={{ width: '110px' }}><Toggle
                    defaultChecked={this.state.tofuIsReady}
                    icons={false} className="pull-left mr-3"
                    onChange={this.handleTofuChange} /> <Link to="/vehicle-provider-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
            },
            {
                sn: '1',
                ownername: 'Pushpendra',
                drivername: 'Pushpendra',
                activesince: '10 August, 2019',
                lastactive: '25 September, 2019',
                vehicle: 'UP 16 AY 2520',
                team: '5',
                constituency: 'constituency name',
                polling: '25',
                trips: '52',
                action: <div style={{ width: '110px' }}><Toggle
                    defaultChecked={this.state.tofuIsReady}
                    icons={false} className="pull-left mr-3"
                    onChange={this.handleTofuChange} /> <Link to="/vehicle-provider-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
            },
            {
                sn: '1',
                ownername: 'Pushpendra',
                drivername: 'Pushpendra',
                activesince: '10 August, 2019',
                lastactive: '25 September, 2019',
                vehicle: 'UP 16 AY 2520',
                team: '5',
                constituency: 'constituency name',
                polling: '25',
                trips: '52',
                action: <div style={{ width: '110px' }}><Toggle
                    defaultChecked={this.state.tofuIsReady}
                    icons={false} className="pull-left mr-3"
                    onChange={this.handleTofuChange} /> <Link to="/vehicle-provider-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
            },
            {
                sn: '1',
                ownername: 'Pushpendra',
                drivername: 'Pushpendra',
                activesince: '10 August, 2019',
                lastactive: '25 September, 2019',
                vehicle: 'UP 16 AY 2520',
                team: '5',
                constituency: 'constituency name',
                polling: '25',
                trips: '52',
                action: <div style={{ width: '110px' }}><Toggle
                    defaultChecked={this.state.tofuIsReady}
                    icons={false} className="pull-left mr-3"
                    onChange={this.handleTofuChange} /> <Link to="/vehicle-provider-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
            },
            {
                sn: '1',
                ownername: 'Pushpendra',
                drivername: 'Pushpendra',
                activesince: '10 August, 2019',
                lastactive: '25 September, 2019',
                vehicle: 'UP 16 AY 2520',
                team: '5',
                constituency: 'constituency name',
                polling: '25',
                trips: '52',
                action: <div style={{ width: '110px' }}><Toggle
                    defaultChecked={this.state.tofuIsReady}
                    icons={false} className="pull-left mr-3"
                    onChange={this.handleTofuChange} /> <Link to="/vehicle-provider-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
            }, {
                sn: '1',
                ownername: 'Pushpendra',
                drivername: 'Pushpendra',
                activesince: '10 August, 2019',
                lastactive: '25 September, 2019',
                vehicle: 'UP 16 AY 2520',
                team: '5',
                constituency: 'constituency name',
                polling: '25',
                trips: '52',
                action: <div style={{ width: '110px' }}><Toggle
                    defaultChecked={this.state.tofuIsReady}
                    icons={false} className="pull-left mr-3"
                    onChange={this.handleTofuChange} /> <Link to="/vehicle-provider-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
            },


        ];

        const onSortFunction = {
            date(columnValue) {
                return moment(columnValue, 'Do MMMM YYYY').valueOf();
            },
        };

        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'There is no data to be displayed',
        };



        return (
            <div>
                <div className="container-fluid">
                    <div className="row my-3 px-4 printRow">
                        <div className="col-sm-6 printfleft">
                            <h1 className="text-primary ">Vehicle Provider</h1>
                        </div>
                        <div className="col-sm-6 printfright">
                            <div className="title_right_sec">
                                <button className="btn_cust btn-primary pull-left mt-2 mr-3" onClick={this.openModal}>Add Vehicle Provider</button>
                                <button class="btn_cust btn-primary pull-right mt-2 printHide"  onClick={() => window.print()}>Download and Print Report</button>
                            </div>
                        </div>
                    </div>



                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                            <h1 className="text-primary ">Vehicle Provider</h1>
                            <button className="btn_close" onClick={this.closeModal}>x</button>
                        </div>

                        <div className="modal_body form-horizontal">
                            <h6>Owner Detail</h6>
                            <hr />
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="name">Owner Name:</label>
                                <div className="col-sm-8">
                                    <input type="text" name="name" value={this.state.name} className="form-control" id="name" placeholder="Enter name" onChange={this.onChange}/>
                                    <span className="error">{this.state.errors["nameError"]}</span>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="email">Owner Email:</label>
                                <div className="col-sm-8">
                                    <input type="email" name="email" value={this.state.email} className="form-control" id="email" placeholder="Enter your Email" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["emailError"]}</span>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="mobile">Owner Mobile No:</label>
                                <div className="col-sm-8">
                                    <input type="text" name="mobile_no" value={this.state.mobile_no} className="form-control" id="mobile" placeholder="Enter mobile no" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["mobile_noError"]}</span>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="Office">Owner Office No:</label>
                                <div className="col-sm-8">
                                    <input type="text" name="office_no" value={this.state.office_no} className="form-control" id="Office" placeholder="Enter Office no" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["office_noError"]}</span>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="Home">Owner Home Address:</label>
                                <div className="col-sm-8">
                                    <input type="text" name="home_address" value={this.state.home_address} className="form-control" id="Home" placeholder="Enter Home Address" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["home_addressError"]}</span>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="Address">Owner Office Address:</label>
                                <div className="col-sm-8">
                                    <input type="text" name="office_address" value={this.state.office_address} className="form-control" id="Address" placeholder="Enter Office Address" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["office_addressError"]}</span>
                                    <label className="mt-2">Same detail for driver
                            <Toggle defaultChecked={this.state.tofuIsReady} icons={false} className="pull-left mr-3" onChange={this.handleTofuChange} />
                                    </label>
                                </div>
                            </div>

                            {this.state.tofuIsReady ? "" :
                                <div>
                                    <h6>Driver Detail</h6>
                                    <hr />
                                    <div className="row mb-3">
                                        <label className="control-label col-sm-4 pr-0" htmlFor="name">Name:</label>
                                        <div className="col-sm-8">
                                            <input type="text" name="driver_name" value={this.state.driver_name} className="form-control" id="name" placeholder="Enter name" onChange={this.onChange} />
                                            <span className="error">{this.state.errors2["driver_nameError"]}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="control-label col-sm-4 pr-0" htmlFor="email">Email:</label>
                                        <div className="col-sm-8">
                                            <input type="email" name="driver_email" value={this.state.driver_email} className="form-control" id="email" placeholder="Enter your Email" onChange={this.onChange} />
                                            <span className="error">{this.state.errors2["driver_emailError"]}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="control-label col-sm-4 pr-0" htmlFor="mobile">Mobile No:</label>
                                        <div className="col-sm-8">
                                            <input type="text" name="driver_mobile_no" value={this.state.driver_mobile_no} className="form-control" id="mobile" placeholder="Enter mobile no" onChange={this.onChange} />
                                            <span className="error">{this.state.errors2["driver_mobile_noError"]}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="control-label col-sm-4 pr-0" htmlFor="Office">Office No:</label>
                                        <div className="col-sm-8">
                                            <input type="text" name="driver_office_no" value={this.state.driver_office_no} className="form-control" id="Office" placeholder="Enter Office no" onChange={this.onChange} />
                                            <span className="error">{this.state.errors2["driver_office_noError"]}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="control-label col-sm-4 pr-0" htmlFor="Home">Home Address:</label>
                                        <div className="col-sm-8">
                                            <input type="text" name="driver_home_address" value={this.state.driver_home_address} className="form-control" id="Home" placeholder="Enter Home Address" onChange={this.onChange} />
                                            <span className="error">{this.state.errors2["driver_home_addressError"]}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="control-label col-sm-4 pr-0" htmlFor="Address">Office Address:</label>
                                        <div className="col-sm-8">
                                            <input type="text" name="driver_office_address" value={this.state.driver_office_address} className="form-control" id="Address" placeholder="Enter Office Address" onChange={this.onChange} />
                                            <span className="error">{this.state.errors2["driver_office_addressError"]}</span>
                                        </div>
                                    </div>


                                </div>
                            }



                            <h6>Vehicle Detail</h6>
                            <hr />

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="Registration">Registration No:</label>
                                <div className="col-sm-8">
                                    <input type="text" name="registration_no" value={this.state.registration_no} className="form-control" id="Registration" placeholder="Enter Registration no" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["registration_noError"]}</span>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="name">Sitting Capacity:</label>
                                <div className="col-sm-8">
                                    <input type="text" name="sitting_capacity" value={this.state.sitting_capacity} className="form-control" id="name" placeholder="Enter Sitting Capacity" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["sitting_capacityError"]}</span>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="Slots">Available Slots:</label>
                                <div className="col-sm-4">
                                    <input type="time" name="startHour" value={this.state.startHour} className="form-control" id="Slots" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["startHourError"]}</span>
                                </div>
                                <div className="col-sm-4">
                                    <input type="time" name="endHour" value={this.state.endHour} className="form-control" id="Slots" onChange={this.onChange} />
                                    <span className="error">{this.state.errors["endHourError"]}</span>
                                </div>
                            </div>


                            <div className="row mb-3">
                                <div className="offset-4 col-sm-8">
                                    <button className="btn btn-sm btn-success" onClick={this._addVehicleProvider}>Add</button>
                                </div>
                            </div>


                        </div>

                    </Modal>




                    <div className="row mt-5 px-4">

                        <div className="col-sm-12 mb-4">
                            <div className="shadow_box custom_datatable">
                                <Datatable
                                    tableHeader={header}
                                    tableBody={this.props.vehicle_provider_listCMg}
                                    keyName="userTable"
                                    tableClass="striped hover responsive"
                                    rowsPerPage={5}
                                    rowsPerPageOption={[5, 10, 15, 20]}
                                    initialSort={{ prop: "username", isAscending: true }}
                                    onSort={onSortFunction}
                                    labels={customLabels}
                                />
                            </div>
                        </div>


                    </div>


                </div>


            </div>
        )
    }
}

const mapStateToProps = ({ authUser, campTeam }) => {
    const { user, isAuthenticated } = authUser;
    const { loading, constituency_list, polling_div, team_list, poll_workerCMg, survey_workerCMg, vehicle_provider_listCMg, test } = campTeam;

    return { user, loading, isAuthenticated, constituency_list, polling_div, team_list, poll_workerCMg, survey_workerCMg, test, vehicle_provider_listCMg }
}
export default connect(mapStateToProps, actions)(VehicleProvider);
