import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Toggle from 'react-toggle'
import "react-toggle/style.css"


class Documents extends Component{
  constructor(props) {
    super(props);
 
    this.state = { 
        tofuIsReady:false,
    };
  }
 
    render(){

    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
                      <h1 className="text-primary ">Documents</h1>
                  </div>
                  <div className="col-sm-6">
                        <div className="d-flex pull-right">
                            <div className="d-flex toggle_block">
                                  <h4>Global</h4>
                                  <label>
                                    <Toggle
                                      defaultChecked={this.state.tofuIsReady}
                                      icons={false}
                                      onChange={this.handleTofuChange} />
                                  </label>
                            </div>
                        </div>
                  </div>
            </div>   


            <div className="row mt-5 px-4 leader_row">

                <div className="col-sm-12 mb-4">
                    <div className="shadow_box">
                          <div className="row docu_list">
                              <div className="col-sm-8">
                                    <h3 className="text-primary">Agenda 3</h3>
                                    <p>This Documents belongs to a particular constituency.</p>
                              </div>
                              <div className="col-sm-4">
                                    <div className="d-flex pull-right">
                                        <p>18th June</p>
                                        <Link to="#">
                                            <img className="img-fluid" src="../img/icon_download.png" />
                                        </Link>
                                    </div>
                              </div>
                          </div>
                    </div>
                </div>

                <div className="col-sm-12 mb-4">
                    <div className="shadow_box">
                          <div className="row docu_list">
                              <div className="col-sm-8">
                                    <h3 className="text-primary">Agenda 2</h3>
                                    <p>This Documents belongs to a particular constituency.</p>
                              </div>
                              <div className="col-sm-4">
                                    <div className="d-flex pull-right">
                                        <p>18th June</p>
                                        <Link to="#">
                                            <img className="img-fluid" src="../img/icon_download.png" />
                                        </Link>
                                    </div>
                              </div>
                          </div>
                    </div>
                </div>

                <div className="col-sm-12 mb-4">
                    <div className="shadow_box">
                          <div className="row docu_list">
                              <div className="col-sm-8">
                                    <h3 className="text-primary">Agenda 1</h3>
                                    <p>This Documents belongs to a particular constituency.</p>
                              </div>
                              <div className="col-sm-4">
                                    <div className="d-flex pull-right">
                                        <p>18th June</p>
                                        <Link to="#">
                                            <img className="img-fluid" src="../img/icon_download.png" />
                                        </Link>
                                    </div>
                              </div>
                          </div>
                    </div>
                </div>
                
            </div> 


        </div>


      </div>
    )
  }
}

export default Documents;
