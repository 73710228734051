import React, { Component } from 'react';
import Header from '../Includes/header';

import Map from '../Components/Map2';

class ElectionSchedule extends Component{
    constructor(props){
    super(props);
    this.state = {
        hideSidebar: true
    }
    }

    showPanel(){
    this.setState({hideSidebar: !this.state.hideSidebar})
    }


  render(){
    let ToggleID = this.state.hideSidebar ? "hide" : "show";

    return (
      <div>
        <div className="nav_panel">
            <button className="btn" onClick={this.showPanel.bind(this)}><i className="fa fa-bars"></i></button>
            <h3>Election Schedule</h3>
        </div>
        <div className="sideBar" id={ToggleID}>
            <Header />
        </div>

          <div className="container-fluid" style={{marginTop:'7rem'}}>
                <h1 className="text_elect_dt mb-5 text-center"> Date of Election : <span className="text-danger">7th September 2019</span></h1>

                <Map />                         

          </div>
      </div>
    )
  }
}

export default ElectionSchedule;
