import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import validator from 'validator';

import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';
import Moment from 'react-moment';
// redux action
import * as actions from '../actions';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


class StreetCaptains extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      modalIsOpen2: false,
      modalIsOpen3: false,
      modalIsOpen4: false,
      modalIsOpen5: false,
      deleteStreetCaptain: '',
      tofuIsReady: true,
      constituency_list: [],
      polling_div: [],
      street_captain_name: '',
      street_captain_email: '',
      street_captain_mobile_number: '',
      street_captain_office_number: '',
      street_captain_home_address: '',
      street_captain_office_address: '',
      errors: {},
      selectedConst: '',
      selected_polling_div: '',
      current_teamList_selected: '',
      selected_constituency: '',
      sele_polling_div: '',
    };

    this.openModal = this.openModal.bind(this);
    this.openModal2 = this.openModal2.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);

    this.openModal3 = this.openModal3.bind(this);
    this.closeModal3 = this.closeModal3.bind(this);

    this.openModal4 = this.openModal4.bind(this);
    this.closeModal4 = this.closeModal4.bind(this);

    this.openModal5 = this.openModal5.bind(this);
    this.closeModal5 = this.closeModal5.bind(this);
  }

  //Call Api for consituency List
  componentDidMount() {
    this.props.listConsituency();
    this.props.getStreetCaptainsList();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.constituency_list !== prevState.constituency_list) {
      return { constituency_list: nextProps.constituency_list };
    }



    //Get Polling Division Name
    if (nextProps.polling_div !== prevState.polling_div) {
      return { polling_div: nextProps.polling_div };
    } else return null;
  }

  openModal() {
    this.setState({
      modalIsOpen: true
    });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  openModal2(data) {
    this.setState({
      modalIsOpen2: true,
      deleteStreetCaptain: data.name,
      deleteStreetCaptainID: data._id
    });
  }

  closeModal2() {
    this.setState({
      modalIsOpen2: false
    });
  }

  // Third Model Open
  openModal3() {
    this.setState({
      modalIsOpen3: true,
    })
  }

  // Third Model Close
  closeModal3() {
    this.setState({
      modalIsOpen3: false
    })
  }

  // Fourth Model Open
  openModal4() {
    this.setState({
      modalIsOpen4: true,
    })
  }

  // Fourth Model Close
  closeModal4() {
    this.setState({
      modalIsOpen4: false
    })
  }

  // Fifth Model Open
  openModal5() {
    this.setState({
      modalIsOpen5: true,
    })
  }

  // Fifth Model Close
  closeModal5() {
    this.setState({
      modalIsOpen5: false
    })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value, error: {} });

  //Delete the Captain
  _deleteTheCapatain = () => {
    const deletedata = {
      streetCaptainID: this.state.deleteStreetCaptainID
    };

    console.log(this.state.deleteStreetCaptainID);
    //debugger;
    this.props.deleteStreetCaptain(deletedata);

    this.setState({
      modalIsOpen2: false
    });
  };

  //Constituency Changes
  optionChanged = e => {
    if (e.target.value != '') {
      console.log(e.target.value);
      this.props.getPollingDiv(e.target.value);
    }
    this.setState({ selectedConst: e.target.value, errors: {} });
  };

  pollingChanged = e => {
    if (e.target.value != '') {
      //console.log(e.target.value);
      this.props.getTeamList(e.target.value);
      // this.props.getSteet(e.target.value);
    }
    this.setState({ selected_polling_div: e.target.value, errors: {} });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    const {
      street_captain_name,
      street_captain_email,
      street_captain_mobile_number,
      street_captain_office_number,
      street_captain_home_address,
      street_captain_office_address,
      selected_polling_div,
      selectedConst
    } = this.state;

    //Name
    if (!street_captain_name) {
      formIsValid = false;
      errors['street_captain_name'] = 'Name cannot be empty';
    }

    if (!street_captain_email) {
      formIsValid = false;
      errors['street_captain_email'] = 'Email cannot be empty';
    }

    if (!validator.isEmail(street_captain_email)) {
      formIsValid = false;
      errors['street_captain_email'] = 'Invalid Email ID';
    }

    if (!street_captain_mobile_number) {
      formIsValid = false;
      errors['street_captain_mobile_number'] = 'Mobile Number cannot be empty';
    }

    if (!validator.isMobilePhone(street_captain_mobile_number)) {
      formIsValid = false;
      errors['street_captain_mobile_number'] = 'Invalid Mobile Number';
    }

    // if (!street_captain_office_number) {
    //   formIsValid = false;
    //   errors['street_captain_office_number'] = 'Office Number cannot be empty';
    // }

    // if (!validator.isMobilePhone(street_captain_office_number)) {
    //   formIsValid = false;
    //   errors['street_captain_office_number'] = 'Invalid Office Number';
    // }

    if (!street_captain_home_address) {
      formIsValid = false;
      errors['street_captain_home_address'] = 'Home address cannot be empty';
    }

    // if (!street_captain_office_address) {
    //   formIsValid = false;
    //   errors['street_captain_office_address'] =
    //     'Office address cannot be empty';
    // }

    if (!selectedConst) {
      formIsValid = false;
      errors['selectedConst'] = 'Please select constituency';
    }

    if (selected_polling_div.length == 0) {
      formIsValid = false;
      errors['selected_polling_div'] = 'Please select polling division';
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  _addCampaignTeam = () => {
    const {
      street_captain_name,
      street_captain_email,
      street_captain_mobile_number,
      street_captain_office_number,
      street_captain_home_address,
      street_captain_office_address,
      selectedConst,
      selected_polling_div
    } = this.state;

    if (this.handleValidation()) {
      const addStreetCaptain = {
        name: street_captain_name,
        email: street_captain_email,
        mobile_no: street_captain_mobile_number,
        office_no: street_captain_office_number,
        home_address: street_captain_home_address,
        office_address: street_captain_office_address,
        constituencyID: selectedConst,
        pollingDivisionID: selected_polling_div,
        role: '2'
      };
      console.log(addStreetCaptain);
      this.props.addStreetCaptain(addStreetCaptain);
      this.setState({ modalIsOpen: false });
    }
  };

  // Filter data on Constituency selection
  ConstituencyChanged = e => {
    if (e.target.value == '') {
      console.log(e.target.value);
      this.setState({ sele_polling_div: '' })
      this.props.getPollingDiv(e.target.value);


      this.props.getStreetCaptainsList();
    } else {
      console.log(e.target.value);
      this.props.getPollingDiv(e.target.value);

      this.setState({ selected_constituency: e.target.value })
      this.props.getStreetCaptainsList(e.target.value);
    }
  };
  //Polling Div Changed
  pollingDivChanged = (e) => {
    if (e.target.value == '') {
      console.log(e.target.value);
      this.setState({ sele_polling_div: '', polling_div: [] })

      this.props.getStreetCaptainsList(this.state.selected_constituency);
    } else {

      this.setState({ sele_polling_div: e.target.value })
      this.props.getStreetCaptainsList(this.state.selected_constituency, e.target.value);
    }

  }
  //Change Status For Street Captain
  handleTofuChange = (data) => {
    var status = (data.is_active == true) ? "false" : "true";
    var sc_id = data._id;
    const updateData = {
      streetCaptainID: sc_id,
      is_active: status
    }
    console.log(updateData);
    this.props.updateStreetCaptainStatus(updateData);

  }
  render() {
    console.log('Street caption List')
    console.log(this.props.street_captians_list);
    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <div className="col-sm-4 printfleft">
              <h1 className="text-primary "> Street Captains </h1>
            </div>
            <div className="col-sm-8 printfright">
              <div className="title_right_sec">

                <div className="form-group mb-3 mr-3">
                  <select
                    className="form-control pull-right"
                    id="const_sele"
                    name="consti"
                    onChange={this.ConstituencyChanged}
                  >
                    <option value="">Select a Constituency</option>
                    {this.state.constituency_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.constituencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <select className="form-control pull-right"
                    onChange={this.pollingDivChanged}
                    value={this.state.sele_polling_div}
                  >
                    <option value="">Select a Polling Division</option>
                    {this.state.polling_div.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.pollingDivisionName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group mb-0">
                <Link
                  className="btn_cust btn-primary pull-right ml-3"
                  to="#"
                  onClick={this.openModal}
                >
                  {' '}
                  +ADD
                </Link>
                </div>

                <button class="btn_cust btn-primary pull-right ml-2 printHide"  onClick={() => window.print()}>Download and Print Report</button>

              </div>
            </div>
          </div>

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary "> Street Captains </h1>
              <button className="btn_close" onClick={this.closeModal}>
                {' '}
                x{' '}
              </button>
            </div>

            <div className="modal_body form-horizontal">
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  {' '}
                  Name *:{' '}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="street_captain_name"
                    placeholder="Enter name"
                    onChange={this.onChange}
                  />
                  <span className="error">
                    {this.state.errors['street_captain_name']}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="email">
                  {' '}
                  Email *:{' '}
                </label>
                <div className="col-sm-8">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="street_captain_email"
                    placeholder="Enter your Email"
                    onChange={this.onChange}
                  />
                  <span className="error">
                    {this.state.errors['street_captain_email']}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="mobile">
                  {' '}
                  Mobile No *:{' '}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="mobile"
                    name="street_captain_mobile_number"
                    placeholder="Enter mobile no"
                    onChange={this.onChange}
                  />
                  <span className="error">
                    {this.state.errors['street_captain_mobile_number']}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="Office">
                  {' '}
                  Office No :{' '}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="Office"
                    name="street_captain_office_number"
                    placeholder="Enter Office no"
                    onChange={this.onChange}
                  />

                </div>
              </div>

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="Home">
                  {' '}
                  Home Address *:{' '}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="Home"
                    name="street_captain_home_address"
                    placeholder="Enter Home Address"
                    onChange={this.onChange}
                  />
                  <span className="error">
                    {this.state.errors['street_captain_home_address']}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <label
                  className="control-label col-sm-4 pr-0"
                  htmlFor="Address"
                >
                  {' '}
                  Office Address :{' '}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="Address"
                    name="street_captain_office_address"
                    placeholder="Enter Office Address"
                    onChange={this.onChange}
                  />

                </div>
              </div>

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  {' '}
                  Constituency:{' '}
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-control"
                    id="const_sele"
                    name="constituency"
                    onChange={this.optionChanged}
                  >
                    <option value="">Select a Constituency</option>
                    {this.state.constituency_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.constituencyName}
                        </option>
                      );
                    })}
                  </select>
                  <span className="error">
                    {this.state.errors['selectedConst']}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">
                  {' '}
                  Polling Division:{' '}
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-control"
                    name="polling_div"
                    onChange={this.pollingChanged}
                    disabled={this.state.selectedConst != '' ? false : true}
                  >
                    <option value="">Select a Polling Division</option>
                    {this.state.polling_div.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.pollingDivisionName}
                        </option>
                      );
                    })}
                  </select>
                  <span className="error">
                    {this.state.errors['selected_polling_div']}
                  </span>
                </div>
              </div>

              {/* <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0">
                  {' '}
                  Team Name:{' '}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    value={
                      this.state.team_list.length === 0
                        ? 'Team Name'
                        : this.state.team_list[0].campaign_name
                    }
                    readOnly
                  />
                </div>
              </div> */}

              <div className="row mb-3">
                <div className="offset-4 col-sm-8">
                  <button
                    className="btn btn-sm btn-success"
                    onClick={this._addCampaignTeam}
                  >
                    {' '}
                    Add{' '}
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.modalIsOpen2}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary "> Delete Street Captain </h1>
              <button className="btn_close" onClick={this.closeModal2}>
                {' '}
                x{' '}
              </button>
            </div>

            <div className="modal_body form-horizontal">
              <div className="mb-3">
                <label>
                  {`Are you sure, do you want to permanently delete ${this.state.deleteStreetCaptain} ?`}
                </label>
              </div>

              <div className="row mb-3">
                <div className="offset-4 col-sm-8">
                  <button
                    className="btn btn-sm btn-success"
                    onClick={this._deleteTheCapatain}
                  >
                    {' '}
                    Delete{' '}
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.modalIsOpen3}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary "> Edit Team Name </h1>
              <button className="btn_close" onClick={this.closeModal3}>
                {' '}
                x{' '}
              </button>
            </div>

            <div className="modal_body form-horizontal">
              <div className="mb-3">
                <input type="text" className="form-control mb-3" name="street_name" placeholder="Team Name" />
              </div>

              <div className="row mb-3">
                <div className="offset-4 col-sm-8">
                  <button className="btn btn-sm btn-success">Update</button>
                </div>
              </div>
            </div>
          </Modal>


          <Modal
            isOpen={this.state.modalIsOpen4}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary ">Vehicle Provider</h1>
              <button className="btn_close" onClick={this.closeModal4}>x</button>
            </div>

            <div className="modal_body form-horizontal">


              <div className="row mb-3 listWorkers">

                <div className="col-sm-4">
                  <p>Worker Name <br /> +91-9839398299</p>
                  <Link
                    className="btn_cust btn-primary text-white mr-2 BtnHvrEdit" to="#" onClick={() => { this.openModal5() }}>
                    <i className="fa fa-pencil"> </i>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <p>Worker Name <br /> +91-9839398299</p>
                  <Link
                    className="btn_cust btn-primary text-white mr-2 BtnHvrEdit" to="#" onClick={() => this.openModal5()}>
                    <i className="fa fa-pencil"> </i>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <p>Worker Name <br /> +91-9839398299</p>
                  <Link
                    className="btn_cust btn-primary text-white mr-2 BtnHvrEdit" to="#" onClick={() => { this.openModal5() }}>
                    <i className="fa fa-pencil"> </i>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <p>Worker Name <br /> +91-9839398299</p>
                  <Link
                    className="btn_cust btn-primary text-white mr-2 BtnHvrEdit" to="#" onClick={() => { this.openModal5() }}>
                    <i className="fa fa-pencil"> </i>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <p>Worker Name <br /> +91-9839398299</p>
                  <Link
                    className="btn_cust btn-primary text-white mr-2 BtnHvrEdit" to="#" onClick={() => { this.openModal5() }}>
                    <i className="fa fa-pencil"> </i>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <p>Worker Name <br /> +91-9839398299</p>
                  <Link
                    className="btn_cust btn-primary text-white mr-2 BtnHvrEdit" to="#" onClick={() => { this.openModal5() }}>
                    <i className="fa fa-pencil"> </i>
                  </Link>
                </div>
                <div className="col-sm-4 mt-2">
                  <Link className="btn_cust btn-primary" to="#" onClick={() => { this.openModal5() }}> + Add New Worked</Link>
                </div>
              </div>


            </div>

          </Modal>


          <Modal
            isOpen={this.state.modalIsOpen5}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary "> Add New Worker</h1>
              <button className="btn_close" onClick={this.closeModal5}>
                {' '}
                x{' '}
              </button>
            </div>

            <div className="modal_body form-horizontal">
              <div className="mb-3">
                <input type="text" className="form-control mb-3" name="" placeholder="Worker Name" />
              </div>
              <div className="mb-3">
                <input type="text" className="form-control mb-3" name="" placeholder="+91-9839398299" />
              </div>
              <div className="mb-3">
                <input type="email" className="form-control mb-3" name="" placeholder="Enter Email ID" />
              </div>
              <div className="mb-3">
                <input type="text" className="form-control mb-3" name="" placeholder="Enter  Address" />
              </div>

              <div className="row mb-3">
                <div className="offset-4 col-sm-8">
                  <button className="btn btn-sm btn-success">Add</button>
                </div>
              </div>
            </div>
          </Modal>


          <div className="row mt-5 px-4 leader_row">
            {this.props.street_captians_list.length > 0
              ? this.props.street_captians_list.map(data => {
                return (
                  <div className="col-sm-4">
                    <div className="shadow_box leader_box p-0 pt-3 mb-4">
                      <div className="card">
                        <div className="card-body p-0 px-4">
                          <div className="col-sm-12 text-center">
                            <img
                              className="card-img-top rounded-circle mx-auto img-thumbnail"
                              src="../img/candi_img.png"
                              alt="Card image cap"
                            />
                            <label
                              className="street_toggle"
                              style={{ display: 'block' }}
                            >
                              <span> Inactive </span>
                              <Toggle
                                defaultChecked={data.is_active}
                                icons={false}
                                onChange={() => { this.handleTofuChange(data) }}
                                style={{ marginTop: '5px' }}
                              />
                              <span> Active </span>
                            </label>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <h5 className="card-title text-primary">
                                {data.name}
                              </h5>
                              <p className="card-text">
                                {' '}
                                {/* <strong> Campaign Team Name </strong> */}
                              </p>
                              <p className="card-text">{data.mobile_no}</p>
                              <p className="card-text">{data.email}</p>
                              <p className="card-text">
                                {data.office_address}
                              </p>
                            </div>

                            <div className="col-sm-6 text-right">
                              {/* <Link
                                  className="btn_cust btn-primary text-white mr-2"
                                  to="#"
                                  onClick={() => this.openModal3()}
                                >
                                  {' '}
                                  <i className="fa fa-pencil"> </i>
                                </Link> */}
                              <Link
                                className="btn_cust btn-danger text-white"
                                to="#"
                                onClick={() => this.openModal2(data)}
                              >
                                {' '}
                                <i className="fa fa-trash"> </i>
                              </Link>
                              <p className="text-success mt-3">
                                {' '}
                                <strong> Active Since </strong>
                              </p>
                              <p>
                                <Moment format="Do MMMM, YYYY">
                                  {data.createdAt}
                                </Moment>
                              </p>

                              <p className="text-danger">
                                {' '}
                                <strong> Last Active </strong>
                              </p>
                              <p>
                                <Moment format="Do MMMM, YYYY">
                                  {data.updatedAt}
                                </Moment>
                              </p>
                            </div>
                          </div>

                          <div className="bottom_row">
                            <ul className="block-3">
                              <li>
                                {/* <Link to="#" onClick={()=>{this.openModal4()}}> */}
                                <p> Vehicle Provider </p>
                                <p> {data.total.totalVehicleProvider} </p>
                                {/* </Link> */}
                              </li>
                              <li>
                                <p> Poll Workers</p>
                                <p> {data.total.totalPollWorkers}  </p>
                              </li>
                              <li>
                                {/* <Link to="#" onClick={()=>{this.openModal4()}}> */}
                                <p> Survey Workers </p>
                                <p> {data.total.totalSurveyWorkers}  </p>
                                {/* </Link>   */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <NotificationContainer />

      </div>
    );
  }
}

const mapStateToProps = ({ authUser, campTeam }) => {
  const { user, isAuthenticated } = authUser;
  const {
    loading,
    constituency_list,
    polling_div,
    street_captians_list
  } = campTeam;

  return {
    user,
    loading,
    isAuthenticated,
    constituency_list,
    polling_div,
    street_captians_list
  };
};
export default connect(
  mapStateToProps,
  actions
)(StreetCaptains);
