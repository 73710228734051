import React, { Component } from 'react';

class SurveySetp2 extends Component{

  render(){
    return (
      <div className="row">
            <div className="col-sm-12">
                <h2 className="text-center">Reasons for your Negative Response</h2>
            </div>

            <div className="col-sm-12 survey_cont">
                  <div className="form-group">
                      <label>Reasons for you dissatisfaction</label>
                      <textarea className="form-control" placeholder="" rows="4"></textarea>
                  </div>  

                  <div className="form-group">
                      <label>What is the most important thing that you would want the new government to implement?</label>
                      <textarea className="form-control" placeholder="" rows="4"></textarea>
                  </div> 

                  <div className="form-group">
                      <label>Other option that you would consider</label>
                      <div className="radio">
                        <input id="radio-1" name="radio" type="radio" checked />
                        <label htmlFor="radio-1" className="radio-label">UNC</label>
                      </div>
                      <div className="radio">
                        <input id="radio-2" name="radio" type="radio" />
                        <label  htmlFor="radio-2" className="radio-label">PNM</label>
                      </div>
                      <div className="radio">
                        <input id="radio-3" name="radio" type="radio" />
                        <label  htmlFor="radio-3" className="radio-label">COP</label>
                      </div>
                      <div className="radio">
                        <input id="radio-4" name="radio" type="radio" />
                        <label  htmlFor="radio-4" className="radio-label">Others</label>
                      </div>
                  </div>
                  

            </div>
        </div>
    )
  }
}

export default SurveySetp2;
