import React, { Component } from 'react';
import AmCharts from "@amcharts/amcharts3-react";

class PieChart extends Component{

  render(){
    const config = {
      "type": "pie",
      "labelRadius": -35,
      "labelText": "[[percents]]%",
       "labelsEnabled": false,
      "dataProvider": [ {
        "country": "Poll Division 2",
        "litres": 301.9,
        "color":'#FFFF00',
      }, {
        "country": "Poll Division 3",
        "litres": 201.1,
        "color":'#ff0000',
      },{
        "country": "Poll Division 1",
        "litres": 98.9,
        "color":'#109d58',
      }, {
        "country": "Others",
        "litres": 139.9,
        "color":'#808080',
      }],
      "valueField": "litres",
      "titleField": "country",
      "colorField": "color",
      "radius": "45%",
      "legend": {
        "position":"right",
        "generateFromData": true,
      }
    };

    return (
      <div>
        <AmCharts.React className="amchart_pie" options={config} />
      </div>
  )

  }
}

export default PieChart;
