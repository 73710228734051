/*
 This Action for add survey and add suvey questions.
*/
import jwt_decode from "jwt-decode";
import { NotificationManager } from 'react-notifications';
import {
  API_URl,
  PAYMENT_REQ_DATA,
  PAYMENT_REQ_BY_SC,
  MAJOR_CONCERN,
  PAYMENT_DETAIL_CM,
  PAYMENT_RATE,
  GET_REQUEST_PAYMENT,

} from './types';

// api
import api from '../api';
import axios from 'axios';



///Get Payment Request Data
export const getPaymentRequestByWorker = (queryString = "") => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      console.log(localStorage.getItem("jwtToken"));
      console.log(API_URl + 'campaignmanagers/getStreetCaptainsPaymentRequest' + queryString);
      const paymentData = await axios.get(API_URl + 'campaignmanagers/getStreetCaptainsPaymentRequest' + queryString, header
      );

      if (paymentData.status === 200) {
        console.log('Response from Server');
        console.log(paymentData.data.data)
        dispatch({
          type: PAYMENT_REQ_DATA,
          payload: paymentData.data
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      dispatch({
        type: PAYMENT_REQ_DATA,
        payload: []
      });
    }
  };
};


///Get Payment Request Data
export const getPaymentByStreetCaption = (streetID = "") => {
  return async dispatch => {
    try {
      let url = "";
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      if (streetID != "") {
        url = API_URl + 'streetcaptains/getPaymentRequestByWorker?streetID=' + streetID;
      }
      else {
        url = API_URl + 'streetcaptains/getPaymentRequestByWorker';
      }
      console.log(url);
      const paymentData = await axios.get(url, header
      );

      if (paymentData.status === 200) {
        console.log('Action Data');
        console.log(paymentData.data)

        dispatch({
          type: PAYMENT_REQ_BY_SC,
          payload: paymentData.data
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      dispatch({
        type: PAYMENT_REQ_BY_SC,
        payload: []
      });
    }
  };
};

///Get Major Concern
export const getMajorConcern = (query = "") => {
  console.log(query)
  debugger
  return async dispatch => {
    dispatch({ type: GET_REQUEST_PAYMENT });
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const majorConcern = await axios.get(API_URl + 'campaignmanagers/getMajorConcernData' + query, header
      );
      debugger
      console.log(majorConcern)
      if (majorConcern.status === 200) {
        console.log(majorConcern.data.data)
        dispatch({
          type: MAJOR_CONCERN,
          payload: majorConcern.data.data
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      debugger
      console.log(error.response)
      dispatch({
        type: MAJOR_CONCERN,
        payload: []
      });
    }
  };
};

//paymentByStreetCaption
export const paymentByStreetCaption = (userData) => {
  return async dispatch => {
    try {
      let url = "";
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };

      url = API_URl + 'campaignmanagers/updateStreetCaptainsPaymentDone';

      console.log(url);
      const paymentData = await axios.put(url, userData, header
      );

      if (paymentData.status === 200) {
        NotificationManager.success('Payment Done !');
        dispatch(getPaymentByStreetCaption());
      } else {
        NotificationManager.error('Unable to Update !');
      }
    } catch (error) {
      dispatch(getPaymentByStreetCaption());
      NotificationManager.error('Unable to Update !');
    }
  };
};


///Get Payment Detail Data
export const getPaymentRequestByWorkerDetail = (streetCaptainID, userType) => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      let url = API_URl + 'campaignmanagers/getPaymentRequestByWorkerDetail?streetCaptainID=' + streetCaptainID + '&userType=' + userType;

      console.log(url);
      const paymentDetailData = await axios.get(url, header
      );

      if (paymentDetailData.status === 200) {
        dispatch({
          type: PAYMENT_DETAIL_CM,
          payload: paymentDetailData.data
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      dispatch({
        type: PAYMENT_DETAIL_CM,
        payload: []
      });
    }
  };
};


///Get Payment Detail Data
export const updatePayment = (updateData) => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      let url = API_URl + 'campaignmanagers/addPaymentRate';

      console.log(url);
      const paymentDetailData = await axios.post(url, updateData, header);
      if (paymentDetailData.status === 200) {
        dispatch(getPaymentRate())
        NotificationManager.success('Added Succesfully !');

      } else {
        NotificationManager.error('Added Succesfully !');
      }
    } catch (error) {
      NotificationManager.error(error.response.data.message);
    }
  };
};


export const getPaymentRate = () => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      let url = API_URl + 'campaignmanagers/getPaymentRate';
      const paymentDetailData = await axios.get(url, header
      );

      if (paymentDetailData.status === 200) {
        dispatch({
          type: PAYMENT_RATE,
          payload: paymentDetailData.data.data
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      dispatch({
        type: PAYMENT_RATE,
        payload: []
      });
    }
  };
};