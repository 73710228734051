import React, { Component } from 'react';
import AmCharts from "@amcharts/amcharts3-react";

class DonutChart extends Component{

  render(){
    const config = {
      "type": "pie",
      "labelsEnabled": false,
      "labelText": "[[percents]]",
      "dataProvider": this.props.dataValue,
      "radius": "35%",
      "innerRadius": "70%",
      "valueField": "value",
      "titleField": "data",
      "colorField": "color",
      "legend": {
        "fontSize": 15,
        "align": "center",
        "position": "bottom",
        "marginRight": 21,
        "markerType": "circle",
        "labelText": "[[title]]",
        "valueText": "[[percents]]%",
        "valueWidth":100+'%',
        "textClickEnabled": true
    },
  };
    
    return (
      <div>        
        <AmCharts.React className="amchart_donut" options={config} />
      </div>
  )

  }
}

export default DonutChart;
