
/**
 * Voter Reducers
 */
import isEmpty from '../validation/is-empty';
import {
 LIST_VOTER_POLL, //LIST_VOTER
  VOTED_VOTER ,  // HOUSE_VOTER,
  NOT_VOTED_VOTER, //STREET_VOTER,
   GET_REQUEST_POLL, //GET_REQUEST 
   VOTER_COUNT_DATA_POLL_NULL, //IN CASE OF NULL VALUE
   VOTER_COUNT_DATA_POLL, //VOTER_COUNT_DATA
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
  loading: false,
  voter_res: [], 
  houses_res:[],
  street_res:[],
  total_voters:0,
  total_houses:0,
  total_streets:0,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
      
      case GET_REQUEST_POLL:
      return {
        ...state,
        loading:true
      };
    
    case LIST_VOTER_POLL: //LIST_VOTER:
    return {
        ...state,
        voter_res: action.payload.data[0].data,
        total_voters:(action.payload.total)?action.payload.total.notVoted:0,
        total_houses:(action.payload.total)?action.payload.total.voted:0,
        total_streets:(action.payload.total)?action.payload.total.waitingForVote:0,
        loading:false

      };

    case VOTER_COUNT_DATA_POLL:  // VOTER_COUNT_DATA:
      return {
        ...state,
        //voter_res: [],
       // houses_res:[],

        total_voters:(action.payload.data)?action.payload.data.notVoted:0,
        total_houses:(action.payload.data)?action.payload.data.voted:0,
        total_streets:(action.payload.data)?action.payload.data.waitingForVote:0,
        loading:false
      };

      
     case VOTER_COUNT_DATA_POLL_NULL:  // VOTER_COUNT_DATA:
      return {
        ...state,
        voter_res: [],
        houses_res:[],

        // total_voters:(action.payload.data)?action.payload.data.notVoted:0,
        // total_houses:(action.payload.data)?action.payload.data.voted:0,
        // total_streets:(action.payload.data)?action.payload.data.waitingForVote:0,
        loading:false
      };
      
      case  VOTED_VOTER: //HOUSE_VOTER:
      return {
        ...state,
        houses_res: action.payload.data[0].data,
        total_voters:(action.payload.total)?action.payload.total.notVoted:0,
        total_houses:(action.payload.total)?action.payload.total.voted:0,
        total_streets:(action.payload.total)?action.payload.total.waitingForVote:0,
        loading:false
      };
      case NOT_VOTED_VOTER: //STREET_VOTER:
      return {
        ...state,
        street_res: action.payload.data[0].data,
        total_voters:(action.payload.total)?action.payload.total.notVoted:0,
        total_houses:(action.payload.total)?action.payload.total.voted:0,
        total_streets:(action.payload.total)?action.payload.total.waitingForVote:0,
        loading:false
      };
      
  default:
      return { ...state };
  }
};
