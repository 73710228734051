import React, { Component } from 'react';

import {Route, Link, HashRouter as Router} from 'react-router-dom';
import Map from '../Components/Map';

import Documents from './Documents'
import ContactDetails from './ContactDetails'
import VehiclesRegistrations from './VehiclesRegistrations'
import WheelchairRegistrations from './WheelchairRegistrations'



class Volunteer extends Component{   

  handleLogout=(e)=>
  {
    this.props.history.push('/')
  }
  render(){
    
    return (
      <div>
        <div className="nav_panel">
            <h3>Welcome Volunteer</h3>
        </div>

        <div className="main_container">
        <Router>
          <div>
              <div className="admin_sidebar">
                <div className="NavBlocks">
                    <ul>
                        <li><Link to="/"><i className="fa fa-bullhorn"></i> Take Survey</Link></li>
                        <li><Link to="/"><i className="fa fa-file-text-o"></i> Documents</Link></li>
                        <li><Link to="/contact-details"><i className="fa fa-phone"></i> Contact Details</Link></li>
                        <li><Link to="/vehicles-registrations"><i className="fa fa-bus"></i> Vehicles Registrations</Link></li>
                        <li><Link to="/wheelchair-registrations"><i className="fa fa-wheelchair"></i> Wheelchair Registrations</Link></li>
                        <li><Link to="" onClick={this.handleLogout}><i className="fa fa-sign-out"></i> Logout</Link></li>
                    </ul>
                </div>
              </div>

              <div className="volunt_wrapper">
                  <Route exact path="/" component={Documents}></Route>
                  <Route exact path="/contact-details" component={ContactDetails}></Route>
                  <Route exact path="/vehicles-registrations" component={VehiclesRegistrations}></Route>
                  <Route exact path="/wheelchair-registrations" component={WheelchairRegistrations}></Route>
              </div> 

                <div className="volunt_side">
                      <h4>Volunteer</h4>

                      <div className="volunt_map">
                        <Map />
                      </div>

                      <p><Link to="">Constituency 1</Link></p>
                      <p><Link to="">Polling Division 1</Link></p>
                      <p><Link to="">Group</Link></p>
                  </div>     
          </div>       
        </Router>
        
            
            
        </div>

      </div>
    )
  }
}

export default Volunteer;
