import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import { connect } from 'react-redux';
import * as actions from '../actions';
import { NotificationContainer } from 'react-notifications';


import Map from '../Components/Map';
import PieChart from '../Components/PieChart';


class DontUse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
      role: '1',
      poll_workerCMg: [],
      selectedContituency: "",
      selectedPollingDiv: "",
      selectedStreet: "",
      v_id: ""
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(id) {
    this.setState({
      modalIsOpen: true,
      v_id: id
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    //Get Polling Division Name
    if (nextProps.poll_workerCMg !== prevState.poll_workerCMg) {
      return { poll_workerCMg: nextProps.poll_workerCMg };
    }
    else return null;
  }

  componentDidMount() {
    this.props.listConsituency();

    this.props.getPWByManager()
  }

  componentWillMount() {

    this.setState({
      role: localStorage.getItem('role')
    })
    console.log(localStorage.getItem('role'))

  }
  //On constituency Changed
  ConstituencyChanged = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    if (e.target.value == '') {
      this.setState({ selectedContituency: e.target.value, selectedStreet: '' })
      this.props.getPollingDiv(e.target.value);
      this.props.getSteet();
      this.props.getPWByManager(qryString);


    } else {

      this.setState({ selectedContituency: e.target.value })
      this.props.getPollingDiv(e.target.value);
      qryString = '?constituencyID=' + e.target.value;
      this.props.getPWByManager(qryString);
    }
  };
  PollingDivChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedPollingDiv: e.target.value, selectedStreet: '' })
      this.props.getSteet();
      qryString = '?constituencyID=' + this.state.selectedContituency;
      this.props.getPWByManager(qryString);


    } else {

      this.setState({ selectedPollingDiv: e.target.value })
      this.props.getSteet(e.target.value);
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + e.target.value;
      this.props.getPWByManager(qryString);
    }
  };
  //On street Changed
  streetChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv;

      this.props.getPWByManager(qryString);

    } else {

      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv + '&streetID=' + e.target.value;

      this.props.getPWByManager(qryString);

    }
  }
  render() {

    const genderChartData = [{
      "data": "Male",
      "value":25,
      "color": '#4ca9f8',
  }, {
      "data": "Female",
      "value": 36,
      "color": '#fb5858',
  }, {
      "data": "Others",
      "value": 50,
      "color": '#ffff00',
  }]
    
    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4">
            <NotificationContainer />
            <div className="col-sm-5">
              <h1 className="text-primary">Don't Use</h1>
            </div>

            <div className="col-sm-7">
              <div className="title_right_sec">

                <div className="mr-3 mb-3">
                  <select
                    className="form-control pull-right"
                    id="const_sele"
                    name="consti"
                    onChange={this.ConstituencyChanged}
                    value={this.state.selectedContituency}
                  >
                    <option value="">Select a Constituency</option>
                    {this.props.constituency_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.constituencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.PollingDivChanged}
                    value={this.state.selectedPollingDiv}
                  >
                    <option value="">Select Year</option>
                    {this.props.polling_div.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.pollingDivisionName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                    <button class="btn_cust btn-primary">Download and print Report</button>
                </div>
              </div>
            </div>

          </div>


          <div className="row mt-5 px-4">
            <div className="col-sm-12">
              <h4 className="text-center mb-3">Delhi Assembly Poll Outcome 2020</h4>
              <h5 className="text-center mb-5" style={{fontWeight:'normal'}}>Constituency-wise winners and Victory margin</h5> 
            </div>          

            <div className="col-sm-5 mb-4">
              <div className="shadow_box custom_datatable">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>S No.</th>
                              <th>Constituency</th>
                              <th>Winner</th>
                              <th>Runner Up</th>
                              <th>Victory Margin</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>constituency</td>
                            <td>PNC</td>
                            <td>1</td>
                            <td>20</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>constituency</td>
                            <td>PNC</td>
                            <td>1</td>
                            <td>20</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>constituency</td>
                            <td>PNC</td>
                            <td>1</td>
                            <td>20</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>constituency</td>
                            <td>PNC</td>
                            <td>1</td>
                            <td>20</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>constituency</td>
                            <td>PNC</td>
                            <td>1</td>
                            <td>20</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>constituency</td>
                            <td>PNC</td>
                            <td>1</td>
                            <td>20</td>
                          </tr>
                        </tbody>
                    </table>
              </div>
            </div>

            <div className="col-sm-7">
                    <div className="map_cont">
                        <Map ref={ref => this.childRef = ref} newValue={this.state.searchString} />
                    </div>
            </div>


          </div>


        </div>


      </div>
    )
  }
}

const mapStateToProps = ({ authUser, campTeam, survey }) => {
  const { user, isAuthenticated } = authUser;
  const { loading, constituency_list, polling_div, poll_workerCMg } = campTeam;
  const { street_list } = survey;

  return { user, loading, isAuthenticated, constituency_list, polling_div, street_list, poll_workerCMg }
}
export default connect(mapStateToProps, actions)(DontUse);

