import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import * as actions from '../actions';
import { connect } from 'react-redux';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


class PaymentRequest extends Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
            selectedContituency: "",
            selectedPolingDiv: ""
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    //Get contituency and Polling Div
    async componentDidMount() {
        await this.props.listConsituency();
        this.props.getPaymentRequestByWorker();


    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    ConstituencyChanged = e => {
        if (e.target.value != '') {
            this.setState({ selectedContituency: e.target.value })
            this.props.getPollingDiv(e.target.value);
            let queryString = '?constituencyID=' + e.target.value;

            this.props.getPaymentRequestByWorker(queryString);

        }
        else {
            let dummyContituency = '52885773b3346c28f2d03158';
            this.props.getPollingDiv(dummyContituency);
            this.setState({ selectedContituency: "", selectedPolingDiv: "" })
            this.props.getPaymentRequestByWorker();

        }
    }

    //Polling Div changed
    pollingDivisionChanged = e => {
        if (e.target.value != "") {
            let queryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + e.target.value;

            this.props.getPaymentRequestByWorker(queryString);
            this.setState({ selectedPolingDiv: e.target.value })

        }
        else {
            let queryString = '?constituencyID=' + this.state.selectedContituency;

            this.props.getPaymentRequestByWorker(queryString);
            this.setState({ selectedPolingDiv: "" })
        }
    }

    render() {

        console.log('Payment Data');
        console.log(this.props.payment_data);
        return (
            <div>

                <div className="container-fluid">

                    <div className="row my-3 px-4 printRow">
                        <div className="col-sm-6 printfleft">
                            <h1 className="text-primary ">Payment Request </h1>
                            <p className="text-primary ">Request by Street Captains</p>
                        </div>
                        <div className="col-sm-6 printfright">
                            <div className="title_right_sec">

                                <div className="form-group ml-3">
                                    <select className="form-control" id="const_sele"
                                        onChange={this.ConstituencyChanged}
                                        value={this.state.selectedContituency}
                                    >
                                        <option value="">Select a Constituency</option>
                                        {this.props.constituency_list.map((data, index) => {
                                            return (
                                                <option value={data._id}>
                                                    {data.constituencyName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="form-group ml-3">
                                    <select className="form-control" id="const_sele"
                                        onChange={this.pollingDivisionChanged}
                                    >
                                        <option value="">Select a Polling Division</option>
                                        {this.props.polling_div.map((data, index) => {
                                            return (
                                                <option value={data._id}>
                                                    {data.pollingDivisionName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>



                                {/* <div className="form-group ml-3 pull-right">
                                <select className="form-control pull-right" id="const_sele">
                                    <option>Select a Constituency</option>
                                    <option>Constituency 1</option>
                                    <option>Constituency 2</option>
                                    <option>Constituency 3</option>
                                    <option>Constituency 4</option>
                                </select>
                            </div> */}


                            <button class="btn_cust btn-primary pull-right mt-2 ml-2 printHide"  onClick={() => window.print()}>Download and Print Report</button>


                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="row my-3 px-4 printRow">
                        <div className="col-sm-4 printw30">
                            <h4 className="text-primary">Total amount Due</h4>
                        </div>
                        <div className="col-sm-4 text-center printw30">
                            <h4 className="text-primary">No. of Street Captains : {this.props.noOfStreetCaptains}</h4>
                        </div>
                        <div className="col-sm-4 text-right printw30">
                            <h4 className="text-primary">$ {this.props.grandTotal_CM}</h4>
                        </div>
                    </div>

                    <hr />





                    <div className="row mt-5 px-4 leader_row">

                        {
                            this.props.payment_data && this.props.payment_data.length > 0 ?
                                this.props.payment_data.map((data) => {
                                    return (<div className="col-sm-4">
                                        <div className="shadow_box leader_box p-0 pt-3">
                                            <div className="card">
                                                <div className="card-body p-0 px-4">
                                                    <Link to={{
                                                        pathname: '/payment-request-detail',
                                                        state: {
                                                            streetCaptainID: data.userID,
                                                            userName: data.name,
                                                        }
                                                    }}>
                                                        <div className="row">
                                                            <div className="col-sm-7">
                                                                <h5 className="card-title text-primary">{data.name}</h5>
                                                                <p className="card-text"><strong></strong></p>
                                                                <p className="card-text">{data.mobile}</p>
                                                                <p className="card-text">{data.email}</p>
                                                            </div>

                                                            <div className="col-sm-5 text-right">
                                                                <p className="text-danger"><strong>Total Amount</strong></p>
                                                                <h3 className="font-weight-bold">$ {data.total_amount}</h3>
                                                            </div>
                                                        </div>

                                                        <div className="bottom_row">
                                                            <ul className="block-3">
                                                                <li>
                                                                    <p>Vehicle Provider</p>
                                                                    <p>{data.data.vehicleProvider}</p>
                                                                </li>
                                                                <li>
                                                                    <p>Poll Workers</p>
                                                                    <p>{data.data.pollWorker}</p>
                                                                </li>
                                                                <li>
                                                                    <p>Survey Workers</p>
                                                                    <p>{data.data.surveyWorker}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }) : null
                        }




                    </div>


                </div>


            </div>
        )
    }
}
const mapStateToProps = ({ campTeam, payment }) => {
    const { constituency_list, polling_div } = campTeam;
    const { payment_data, grandTotal_CM, noOfStreetCaptains } = payment;
    return { constituency_list, polling_div, payment_data, grandTotal_CM, noOfStreetCaptains };
};
export default connect(
    mapStateToProps,
    actions
)(PaymentRequest);
