/*
 src/actions/campaignTeamAction.js
*/
import jwt_decode from 'jwt-decode';
import { NotificationManager } from 'react-notifications';
import {
  ADD_TEAM,
  LIST_TEAM,
  LIST_CONSTITUENCY,
  LIST_POLLING_DIV,
  LIST_STREET_CAPTAINS_TEAM,
  CLEAR_DATA,
  API_URl,
  LIST_POLL_WORKER,
  LIST_SURVEY_WORKER,
  LIST_VEHICLE_PROVIDER,
  LIST_STREET_BASED_ON_POLLING_DIVISION
} from './types';
import axios from 'axios';

// api
import api from '../api';

/**Add Campaign Team */
export const addTeam = (data, history) => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const TeamData = await axios.post(API_URl + 'campaignmanagers/addCampaignTeam', data, header);
      //debugger;
      if (TeamData.status === 201) {
        NotificationManager.success('Team has been successfully added');
        dispatch(getTeamList());
      } else {
        NotificationManager.error('Unable to add Team !');
      }
    } catch (error) {
      NotificationManager.error('Unable to add Team !');
    }
  };
};

//Update Team

export const updateTeam = (data, pauseData, history) => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const TeamData = await axios.put(API_URl + 'campaignmanagers/updateCampaignTeam',
        data, header
      );

      const TeamData2 = await axios.put(
        'campaignmanagers/updatePauseCampaign',
        pauseData,
        header
      );

      if (TeamData.status === 200 && TeamData2.status === 200) {
        NotificationManager.success('Team updated successfully ');
        dispatch(getTeamList());
      } else {
        NotificationManager.error('Unable to update Team !');
      }
    } catch (error) {
      NotificationManager.error('Unable to update Team !');
    }
  };
};

/**listConsituency */
export const listConsituency = () => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const ContituencyData = await axios.get(API_URl + 'campaignmanagers/getConstituency', header);

      if (ContituencyData.status === 200) {
        dispatch({
          type: LIST_CONSTITUENCY,
          payload: ContituencyData.data.data
        });
      } else {
        //NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      //NotificationManager.error('Unable to Fetch !');
    }
  };
};

export const getClearData = () => {
  //debugger;
  return dispatch => {
    dispatch({
      type: CLEAR_DATA
    });
  };
};

//Get Polling Division
export const getPollingDiv = ConstituencyID => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const ContituencyData = await axios.get(API_URl + 'campaignmanagers/getPollingDivisions?constituencyID=' + ConstituencyID, header
      );

      if (ContituencyData.status === 200) {
        dispatch({
          type: LIST_POLLING_DIV,
          payload: ContituencyData.data.data
        });
      } else {

        // NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      dispatch({
        type: LIST_POLLING_DIV,
        payload: []
      });
      // NotificationManager.error('Unable to Fetch !');
    }
  };
};




//Get Street List
export const getStreetList = (pollingDivisionID = '') => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const ContituencyData = await axios.get(API_URl + 'admins/getStreet?pollingDivisionID=' + pollingDivisionID, header
      );

      if (ContituencyData.status === 200) {
        dispatch({
          type: LIST_STREET_BASED_ON_POLLING_DIVISION,
          payload: ContituencyData.data.data
        });
      } else {

        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: LIST_STREET_BASED_ON_POLLING_DIVISION,
        payload: []
      });
      NotificationManager.error('Unable to Fetch !');
    }
  };
};


/**getTeamList */
export const getTeamList = (PollingDivID = '', contituencyID = '') => {
  return async dispatch => {
    try {
      var TeamData;
      var result;
      var header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      if (contituencyID != '') {

        TeamData = await axios.get(API_URl + 'campaignmanagers/getCampaignTeam?constituencyID=' + contituencyID, header
        );
      } else if (PollingDivID != '') {
        TeamData = await axios.get(API_URl + 'campaignmanagers/getCampaignTeam?pollingDivisionID=' + PollingDivID, header
        );
      } else {
        TeamData = await axios.get(API_URl + 'campaignmanagers/getCampaignTeam', header);
      }

      if (TeamData.status === 200) {
        if (contituencyID == '') {
          result = TeamData.data.data;
        } else {
          result = TeamData.data.data.filter(
            data => data.constituencyID._id == contituencyID
          );
        }
        dispatch({ type: LIST_TEAM, payload: result });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      NotificationManager.error('Unable to Fetch !');
    }
  };
};

/**getStreetCaptainsList */
export const getStreetCaptainsList = (
  contituencyID = '',
  PollingDivID = ''
) => {
  return async dispatch => {
    try {
      var header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      var StreetCaptainsTeamData;
      var result;
      if (contituencyID != '' && PollingDivID == '') {
        console.log(API_URl + 'campaignmanagers/getStreetCaptains?constituencyID=' + contituencyID);
        StreetCaptainsTeamData = await axios.get(API_URl + 'campaignmanagers/getStreetCaptains?constituencyID=' + contituencyID, header
        );
      }
      else if (contituencyID != '' && PollingDivID != '') {
        StreetCaptainsTeamData = await axios.get(API_URl + 'campaignmanagers/getStreetCaptains?constituencyID=' + contituencyID + '&PollingDivID=' + PollingDivID, header)
        console.log(API_URl + 'campaignmanagers/getStreetCaptains?constituencyID=' + contituencyID + '&PollingDivID=' + PollingDivID);

      }
      else {
        StreetCaptainsTeamData = await axios.get(API_URl + 'campaignmanagers/getStreetCaptains', header
        );
      }

      if (StreetCaptainsTeamData.status === 200) {
        // if (contituencyID == '') {
        //   result = StreetCaptainsTeamData.data.data;
        // } else {
        //   result = StreetCaptainsTeamData.data.data.filter(
        //     data => data.constituencyID._id == contituencyID
        //   );
        // }
        result = StreetCaptainsTeamData.data.data;
        dispatch({
          type: LIST_STREET_CAPTAINS_TEAM,
          payload: result
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      NotificationManager.error('Unable to Fetch !');
    }
  };
};

/**Add StreetCaptain */
export const addStreetCaptain = (data, history) => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const streetCaptionData = await axios.post(
        API_URl + 'campaignmanagers/addStreetCaptains',
        data, header
      );
      if (streetCaptionData.status === 201) {
        NotificationManager.success('Street caption has been successfully added');
        dispatch(getStreetCaptainsList());
      } else {
        NotificationManager.error('Unable to add Street caption !');
      }
    } catch (error) {
      NotificationManager.error('Unable to add Street caption !');
    }
  };
};

/**Delete StreetCaptain */
export const deleteStreetCaptain = (deletedata, history) => {
  return async dispatch => {
    try {
      const streetCaptionData = await axios.delete(API_URl + "campaignmanagers/deleteStreetCaptain", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
        },
        data: deletedata
      })
      if (streetCaptionData.status === 200) {
        //debugger;
        NotificationManager.success(
          'Street Captain has been successfully deleted'
        );
        dispatch(getStreetCaptainsList());
      } else {
        NotificationManager.error('Unable to delete the Street captain !');
      }
    } catch (error) {
      NotificationManager.error('Unable to delete the Street captain !');
    }
  };
};

/**Update StreetCaptain Status*/
export const updateStreetCaptainStatus = (updateData) => {
  return async dispatch => {
    try {
      const streetCaptionData = await axios.put(API_URl + "campaignmanagers/updateStreetCaptainStatus", updateData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
        },

      })
      if (streetCaptionData.status === 200) {
        //debugger;
        NotificationManager.success(
          'Street Captain status has been changed'
        );
        dispatch(getStreetCaptainsList());
      } else {
        NotificationManager.error('Unable to update Street captain !');
      }
    } catch (error) {
      NotificationManager.error('Unable to update Street captain !');
    }
  };
};
/** Poll Worker */
export const pollWorkerCManager = (keyPath = "") => {
  // debugger
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const pollWorkerDataCManager = await axios.get(
        API_URl + 'streetcaptains/getPollWorker?streetCaptainID=' + keyPath, header
      );
      if (pollWorkerDataCManager.status === 200) {
        console.log(pollWorkerDataCManager.data.data)
        dispatch({
          type: LIST_POLL_WORKER,
          payload: pollWorkerDataCManager.data.data
        })
      } else {
        NotificationManager.error('Unable to Fetch Poll Worker At this moment !');
      }
    } catch (error) {
      NotificationManager.error('Unable to Fetch Poll Worker At this moment !');
    }
  };
};

/** Poll Worker For Campagaien Manager*/
export const getPWByManager = (FilterString = "") => {
  // debugger
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      console.log(API_URl + 'streetcaptains/getPollWorker' + FilterString);
      const pollWorkerDataCManager = await axios.get(
        API_URl + 'streetcaptains/getPollWorker' + FilterString, header
      );
      if (pollWorkerDataCManager.status === 200) {
        console.log(pollWorkerDataCManager.data.data)
        dispatch({
          type: LIST_POLL_WORKER,
          payload: pollWorkerDataCManager.data.data
        })
      } else {
        NotificationManager.error('Unable to Fetch Poll Worker At this moment !');
      }
    } catch (error) {
      dispatch({
        type: LIST_POLL_WORKER,
        payload: []
      })
    }
  };
};

/** Survey Worker */
export const surveyWorkerCManager = (keyPath = "") => {

  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const surveyWorkerDataCManager = await axios.get(
        API_URl + 'streetcaptains/getSurveyWorker' + keyPath, header
      );
      if (surveyWorkerDataCManager.status === 200) {
        console.log(surveyWorkerDataCManager.data.data)
        dispatch({
          type: LIST_SURVEY_WORKER,
          payload: surveyWorkerDataCManager.data.data
        })
      } else {
        NotificationManager.error('Unable to Fetch Survey Worker At this moment !');
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: LIST_SURVEY_WORKER,
        payload: []
      })
    }
  };
};


/** Vehical Worker */
 export const vehicalWorkerCManager = (keyPath = "") => {
  // debugger
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const vehicalWorkerDataCManager = await axios.get(
        API_URl + 'streetcaptains/getVehicleProvider' + keyPath, header
      );
      if (vehicalWorkerDataCManager.status === 200) {
        console.log(vehicalWorkerDataCManager.data.data)
        dispatch({
          type: LIST_VEHICLE_PROVIDER,
          payload: vehicalWorkerDataCManager.data.data
        })
      } else {
        NotificationManager.error('Unable to Fetch Vehicle Provider At this moment !');
      }
    } catch (error) {
      dispatch({
        type: LIST_VEHICLE_PROVIDER,
        payload: []
      })
    }
  };
};

//Update Block/Unblock Status

export const pollWorkerStatusUpdate = (id, value, way) => {
  console.log(id)
  console.log(value)
  console.log(way)
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };

      var TeamData;

      if (way === 'p') {
        TeamData = await axios.put(API_URl + 'streetcaptains/blockUnblockPollWorker',
          { pollworkerID: id, is_active: !value }, header
        );
      } else if (way === 's') {
        TeamData = await axios.put(API_URl + 'streetcaptains/blockUnblockSurveyWorker',
          { surveyworkerID: id, is_active: !value }, header
        );
      } else if (way === 'v') {
        TeamData = await axios.put(API_URl + 'streetcaptains/blockUnblockVehicleProvider',
          { vehicleproviderID: id, is_active: !value }, header
        );
      }


      if (TeamData.status === 200) {
        console.log(TeamData.data)
        NotificationManager.success('Status has updated successfully ');
        if (way === 'p') {
          dispatch(pollWorkerCManager());
        } else if (way === 's') {
          dispatch(surveyWorkerCManager());
        } else if (way === 'v') {
          dispatch(vehicalWorkerCManager());
        }

      } else {
        NotificationManager.error('Unable to update status !');
      }
    } catch (error) {
      //debugger
      NotificationManager.error('Unable to update status !');
    }
  };
};


//Delete poll-workerCMG

export const pollWorkerDelete = (id, way) => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };

      console.log(header)
      var TeamData;

      if (way === 'p') {
        TeamData = await axios.delete(API_URl + 'streetcaptains/deletePollWorker',
          { data: { pollworkerID: id } }, header
        );
      } else if (way === 's') {
        TeamData = await axios.delete(API_URl + 'streetcaptains/deleteSurveyWorker',
          { data: { surveyworkerID: id } }, header
        );
      } else if (way === 'v') {
        TeamData = await axios.delete(API_URl + 'streetcaptains/deleteVehicleProvider',
          { data: { vehicleproviderID: id } }, header
        );
      }

      if (TeamData.status === 200) {
        NotificationManager.success('Account Deleted successfully ');
        if (way === 'p') {
          dispatch(pollWorkerCManager());
        } else if (way === 's') {
          dispatch(surveyWorkerCManager());
        } else if (way === 'v') {
          dispatch(vehicalWorkerCManager());
        }
      } else {
        NotificationManager.error('Unable to update status !');
      }
    } catch (error) {
      console.log(error.response)
      //debugger
      NotificationManager.error('Unable to update status !');
    }
  };
};


/**Add Poll Worker Team */
export const addPollworker = (data, ConstituencyID) => {
  //debugger
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const TeamData = await axios.post(API_URl + 'streetcaptains/addPollWorker', data, header);
      //debugger;
      if (TeamData.status === 201) {
        // debugger
        NotificationManager.success('Poll Worker has been successfully added');
        dispatch(pollWorkerCManager());
      } else {
        NotificationManager.error('Unable to add Worker !');
      }
    } catch (error) {
      //debugger
      NotificationManager.error(error.response.data.message);
    }
  };
};


/**Add Survey Worker Team */
export const addSurveyWorker = (data, ConstituencyID) => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const TeamData = await axios.post(API_URl + 'streetcaptains/addSurveyWorker', data, header);
      if (TeamData.status === 201) {
        //debugger
        NotificationManager.success('Survey Worker has been successfully added');
        dispatch(surveyWorkerCManager());
      } else {
        NotificationManager.error('Unable to add Worker !');
      }
    } catch (error) {
      //debugger
      NotificationManager.error(error.response.data.message);
    }
  };
};


/**Add Vehicle Provider */
export const addVehicleProvider = (data) => {
  console.log(data)
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const TeamData = await axios.post(API_URl + 'streetcaptains/addVehicleProvider', data, header);
      //debugger;
      if (TeamData.status === 201) {
        
        NotificationManager.success('Vehicle Provider has been successfully added');
        dispatch(vehicalWorkerCManager());
      } else {
        NotificationManager.error('Unable to add Worker !');
      }
    } catch (error) {
      
      console.log(error.response.data.message)
      NotificationManager.error(error.response.data.message);
    }
  };
};