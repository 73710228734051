import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import moment from 'moment'
import Datatable from 'react-bs-datatable'; 

import {Tabs, Tab} from 'react-bootstrap-tabs';
import * as actions from '../../actions';
import { connect } from 'react-redux';



class PaymentRequestDetail extends Component{
  constructor(props) {
    super(props);
 
    this.state = { 
      streetCaptainID:"",
      userType:4
    };
  }
  async componentDidMount()
  {
    const { streetCaptainID } = this.props.location.state
    console.log('Street Captain ID');
    console.log(streetCaptainID);
    this.setState({streetCaptainID:streetCaptainID})


  }
    render(){


        const header = [
            { title: 'S.No.', prop: 'sn', sortable: true, filterable: true },
            { title: 'Name', prop: 'name', sortable: true },
            { title: 'Total Trips', prop: 'trips', sortable: true },
            { title: 'Total K.M.', prop: 'km', sortable: true },
            { title: 'Total Amount', prop: 'amount', sortable: true },
            { title: 'Action', prop: 'action',  },
          ];
           
          const body = [
            {
              sn: '1',
              name: 'Pushpendra',
              trips: '2',
              km: '14',
              amount: '$ 25',
              action: <button className="btn-sm btn-small btn-success">Pay</button>,
            },
            {
                sn: '1',
                name: 'Pushpendra',
                trips: '2',
                km: '143',
                amount: '$ 285',
                action: <p className="btn-sm btn-small btn-danger">Paid</p>,
              },

          ];
          
           
          const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'There is no data to be displayed',
          };



          const headersurvey = [
            { title: 'S.No.', prop: 'sn', sortable: true, filterable: true },
            { title: 'Name', prop: 'name', sortable: true },
            { title: 'Total Survey', prop: 'survey', sortable: true },
            { title: 'Total Amount', prop: 'amount', sortable: true },
            { title: 'Action', prop: 'action',  },
          ];
           
          const bodysurvey = [
            {
              sn: '1',
              name: 'Pushpendra',
              survey: '2',
              amount: '$ 25',
              action: <button className="btn-sm btn-small btn-success">Pay</button>,
            },
            {
                sn: '1',
                name: 'Pushpendra',
                survey: '2',
                amount: '$ 285',
                action: <p className="btn-sm btn-small btn-danger">Paid</p>,
              },

          ];


          const headerpoll = [
            { title: 'S.No.', prop: 'sn', sortable: true, filterable: true },
            { title: 'Name', prop: 'name', sortable: true },
            { title: 'Total Sitting Hours', prop: 'sitting', sortable: true },
            { title: 'Total Verification', prop: 'verification', sortable: true },
            { title: 'Total Amount', prop: 'amount', sortable: true },
            { title: 'Action', prop: 'action',  },
          ];
           
          const bodypoll = [
            {
              sn: '1',
              name: 'Pushpendra',
              sitting: '2',
              verification: '25/100',
              amount: '$ 25',
              action: <button className="btn-sm btn-small btn-success">Pay</button>,
            },
            {
                sn: '1',
                name: 'Pushpendra',
                sitting: '20',
                verification: '58/100',
                amount: '$ 285',
                action: <p className="btn-sm btn-small btn-danger">Paid</p>,
              },

          ];


    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
                      <h1 className="text-primary ">Payment Request / Dr. Jack</h1>
                  </div>

                  <div className="col-sm-6 text-right">
                      <h4 className="text-primary ">Total amount Due - $ 250</h4>
                  </div>
            </div>   


            <div className="row mt-5 px-4">

            <div className="col-sm-12 mb-4">
                <div className="shadow_box custom_datatable">

                    <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                        <Tab label="Vehicle Provider">
                            <div className="tabContHead">
                                <p>Total Amount</p>
                                <p className="font-weight-bold">$ 100</p>
                            </div>
                            <Datatable
                                tableHeader={header}
                                tableBody={body}
                                keyName="userTable"
                                tableClass="striped hover responsive"
                                rowsPerPage={5}
                                rowsPerPageOption={[5, 10, 15, 20]}
                                initialSort={{prop: "username", isAscending: true}}
                                labels={customLabels}
                            />
                        </Tab>

                        <Tab label="Poll Workers">
                                <div className="tabContHead">
                                    <p>Total Amount</p>
                                    <p className="font-weight-bold">$ 100</p>
                                </div>
                                <Datatable
                                    tableHeader={headerpoll}
                                    tableBody={bodypoll}
                                    keyName="userTable"
                                    tableClass="striped hover responsive"
                                    rowsPerPage={5}
                                    rowsPerPageOption={[5, 10, 15, 20]}
                                    initialSort={{prop: "username", isAscending: true}}
                                    labels={customLabels}
                                />    

                        </Tab>

                        <Tab label="Survey Workers">
                            
                                <div className="tabContHead">
                                    <p>Total Amount</p>
                                    <p className="font-weight-bold">$ 100</p>
                                </div>
                                <Datatable
                                    tableHeader={headersurvey}
                                    tableBody={bodysurvey}
                                    keyName="userTable"
                                    tableClass="striped hover responsive"
                                    rowsPerPage={5}
                                    rowsPerPageOption={[5, 10, 15, 20]}
                                    initialSort={{prop: "username", isAscending: true}}
                                    labels={customLabels}
                                />


                        </Tab>
                    </Tabs>
                        
                </div>
            </div>        
                    
                                    
            </div> 


        </div>


      </div>
    )
  }
}

export default PaymentRequestDetail;
