import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import { connect } from 'react-redux';
import * as actions from '../actions';

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import moment from 'moment'
import Datatable from 'react-bs-datatable';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};



class VehicleProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
      vehicle_provider_listCMg: [],
      selectedContituency: "",
      selectedPollingDiv: "",
      selectedStreet: "",
      modalIsOpen: false,
      v_id: ""
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(id) {
    this.setState({
      modalIsOpen: true,
      v_id: id
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  componentDidMount() {
    this.props.listConsituency();
    this.props.vehicalWorkerCManager()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // debugger
    //Get Polling Division Name
    if (nextProps.vehicle_provider_listCMg !== prevState.vehicle_provider_listCMg) {
      return { vehicle_provider_listCMg: nextProps.vehicle_provider_listCMg };
    }
    else return null;
  }
  //On constituency Changed
  ConstituencyChanged = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    if (e.target.value == '') {
      this.setState({ selectedContituency: e.target.value, selectedStreet: '' })
      this.props.getPollingDiv(e.target.value);
      this.props.getSteet();
      this.props.vehicalWorkerCManager(qryString);


    } else {

      this.setState({ selectedContituency: e.target.value })
      this.props.getPollingDiv(e.target.value);
      qryString = '?constituencyID=' + e.target.value;
      this.props.vehicalWorkerCManager(qryString);
    }
  };
  PollingDivChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedPollingDiv: e.target.value, selectedStreet: '' })
      this.props.getSteet();
      qryString = '?constituencyID=' + this.state.selectedContituency;
      this.props.vehicalWorkerCManager(qryString);


    } else {

      this.setState({ selectedPollingDiv: e.target.value })
      this.props.getSteet(e.target.value);
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + e.target.value;
      this.props.vehicalWorkerCManager(qryString);
    }
  };
  //On street Changed
  streetChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv;

      this.props.vehicalWorkerCManager(qryString);

    } else {

      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv + '&streetID=' + e.target.value;

      this.props.vehicalWorkerCManager(qryString);

    }
  }
  render() {

    console.log('________')
    console.log(this.state.vehicle_provider_listCMg)

    const header = [
      { title: 'Owner Name', prop: 'name', sortable: true },
      {
        title: 'Driver name',
        prop: 'drivername',
        cell: row => row.driver_detail[0].driver_name,
        sortable: true
      },
      {
        title: 'Active Since',
        prop: 'createdAt',
        cell: row => moment(row.createdAt).format('Do MMMM YYYY'),
        sortable: true
      },
      {
        title: 'Last Active',
        prop: 'updatedAt',
        cell: row => moment(row.updatedAt).format('Do MMMM YYYY'),
        sortable: true
      },
      {
        title: 'Constituency',
        prop: 'constituency',
        cell: row => row.constituencyID.constituencyName,
        sortable: true
      },
      {
        title: 'Polling Division',
        prop: 'polling',
        cell: row => row.pollingDivisionID.pollingDivisionName,
        sortable: true
      },
      { title: 'Total Trips', prop: 'totalTrips', sortable: true },
      {
        title: 'Action',
        prop: 'action2',
        cell: row => <div
          style={{ width: '70px' }}>

          <button class="btn_cust btn-danger pull-left mr-3" onClick={() => this.openModal(row._id)}>
            <i class="fa fa-trash"></i>
          </button>
        </div>
      },
      {
        title: 'Block',
        prop: 'action',
        cell: row => <Toggle
          defaultChecked={row.is_active}
          onChange={() => this.props.pollWorkerStatusUpdate(row._id, row.is_active, 'v')} />
      },

    ];

    // const body = [
    //   {
    //     sn: '1',
    //     ownername: 'Pushpendra',
    //     drivername: 'Pushpendra',
    //     activesince: '10 August, 2019',
    //     lastactive: '25 September, 2019',
    //     vehicle: 'UP 16 AY 2520',
    //     team: '5',
    //     constituency: 'constituency name',
    //     polling: '25',
    //     trips: '52',
    //     block: <Toggle
    //       defaultChecked={this.state.tofuIsReady}
    //       icons={false}
    //       onChange={this.handleTofuChange} />,
    //   },
    //   {
    //     sn: '1',
    //     ownername: 'Pushpendra',
    //     drivername: 'Pushpendra',
    //     activesince: '10 August, 2019',
    //     lastactive: '25 September, 2019',
    //     vehicle: 'UP 16 AY 2520',
    //     team: '5',
    //     constituency: 'constituency name',
    //     polling: '25',
    //     trips: '52',
    //     block: <Toggle
    //       defaultChecked={this.state.tofuIsReady}
    //       icons={false}
    //       onChange={this.handleTofuChange} />,
    //   },
    //   {
    //     sn: '1',
    //     ownername: 'Pushpendra',
    //     drivername: 'Pushpendra',
    //     activesince: '10 August, 2019',
    //     lastactive: '25 September, 2019',
    //     vehicle: 'UP 16 AY 2520',
    //     team: '5',
    //     constituency: 'constituency name',
    //     polling: '25',
    //     trips: '52',
    //     block: <Toggle
    //       defaultChecked={this.state.tofuIsReady}
    //       icons={false}
    //       onChange={this.handleTofuChange} />,
    //   },
    //   {
    //     sn: '1',
    //     ownername: 'Pushpendra',
    //     drivername: 'Pushpendra',
    //     activesince: '10 August, 2019',
    //     lastactive: '25 September, 2019',
    //     vehicle: 'UP 16 AY 2520',
    //     team: '5',
    //     constituency: 'constituency name',
    //     polling: '25',
    //     trips: '52',
    //     block: <Toggle
    //       defaultChecked={this.state.tofuIsReady}
    //       icons={false}
    //       onChange={this.handleTofuChange} />,
    //   },
    //   {
    //     sn: '1',
    //     ownername: 'Pushpendra',
    //     drivername: 'Pushpendra',
    //     activesince: '10 August, 2019',
    //     lastactive: '25 September, 2019',
    //     vehicle: 'UP 16 AY 2520',
    //     team: '5',
    //     constituency: 'constituency name',
    //     polling: '25',
    //     trips: '52',
    //     action: <Toggle
    //       defaultChecked={this.state.tofuIsReady}
    //       icons={false}
    //       onChange={this.handleTofuChange} />,
    //   }, {
    //     sn: '1',
    //     ownername: 'Pushpendra',
    //     drivername: 'Pushpendra',
    //     activesince: '10 August, 2019',
    //     lastactive: '25 September, 2019',
    //     vehicle: 'UP 16 AY 2520',
    //     team: '5',
    //     constituency: 'constituency name',
    //     polling: '25',
    //     trips: '52',
    //     action: <Toggle
    //       defaultChecked={this.state.tofuIsReady}
    //       icons={false}
    //       onChange={this.handleTofuChange} />,
    //   },


    // ];

    const onSortFunction = {
      date(columnValue) {
        return moment(columnValue, 'Do MMMM YYYY').valueOf();
      },
    };

    const customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };



    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <div className="col-sm-4 printfleft">
              <h1 className="text-primary ">Vehicle Provider</h1>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={false}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="modal_head">
                <h1 className="text-primary "> Delete Vehicle Provider </h1>
                <button className="btn_close" onClick={this.closeModal}>
                  {' '}
                  x{' '}
                </button>
              </div>

              <div className="modal_body form-horizontal">
                <div className="mb-3">
                  <label>
                    {`Are you sure, do you want to permanently delete this provider ?`}
                  </label>
                </div>

                <div className=" mb-3">
                  <div className="offset-4 col-sm-8">
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() => this.props.pollWorkerDelete(this.state.v_id, 'v')}
                    >
                      {' '}
                      Delete{' '}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <div className="col-sm-8 printfright">
              <div className="title_right_sec">

                <div className="form-inline mr-3 mb-3">
                  <label className="mr-2">Filter By</label>
                  <select
                    className="form-control pull-right"
                    id="const_sele"
                    name="consti"
                    onChange={this.ConstituencyChanged}
                    value={this.state.selectedContituency}
                  >
                    <option value="">Select a Constituency</option>
                    {this.props.constituency_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.constituencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.PollingDivChanged}
                    value={this.state.selectedPollingDiv}
                  >
                    <option value="">Select Polling div</option>
                    {this.props.polling_div.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.pollingDivisionName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <select className="form-control pull-right"
                    onChange={this.streetChanged}
                    value={this.state.selectedStreet}
                  >
                    <option value="">Select Street</option>
                    {this.props.street_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.street_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group printHide">
                  <button class="btn_cust btn-primary"  onClick={() => window.print()}>Download and Print Report</button>
                </div>


              </div>
            </div>

          </div>


          <div className="row mt-5 px-4">

            <div className="col-sm-12 mb-4 printHide">
              <div className="shadow_box custom_datatable">
                <Datatable
                  tableHeader={header}
                  tableBody={this.state.vehicle_provider_listCMg}
                  keyName="userTable"
                  tableClass="striped hover responsive"
                  rowsPerPage={5}
                  rowsPerPageOption={[5, 10, 15, 20]}
                  initialSort={{ prop: "username", isAscending: true }}
                  onSort={onSortFunction}
                  labels={customLabels}
                />
              </div>
            </div>

            
            <div className="printTable"> 
          <table border="1" cellPadding="10">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Owner Name</th>
                <th>Driver Name</th>
                <th>Active Since</th>
                <th>Last Active</th>
                <th>Constituency</th>
                <th>Polling Division</th>
                <th>Total Trips</th>
              </tr>
            </thead>
            <thead>

              {this.state.vehicle_provider_listCMg.map((data, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{data.name}</td>
                      <td>{data.driver_detail.driver_name}</td>
                      <td>{data.createdAt}</td>
                      <td>{data.updatedAt} </td>
                      <td>{data.constituencyID.constituencyName}</td>
                      <td>{data.pollingDivisionID.pollingDivisionName}</td>
                      <td>{data.totalTrips}</td>
                    </tr>
                  )
                })
              }
            </thead>
          </table>
        </div>

          </div>


        </div>


      </div>
    )
  }
}

const mapStateToProps = ({ authUser, campTeam, survey }) => {
  const { user, isAuthenticated } = authUser;
  const { loading, constituency_list, polling_div, vehicle_provider_listCMg, test } = campTeam;
  const { street_list } = survey;
  return { user, loading, isAuthenticated, constituency_list, polling_div, vehicle_provider_listCMg, test, street_list }
}
export default connect(mapStateToProps, actions)(VehicleProvider);

