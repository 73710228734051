import React from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import Welcome from './Welcome';
import Login from './Login';
import About from './About'
import ResetPassword from './ResetPassword'
import Pickup from './Pickup/pickup';
import ElectionSchedule from './ElectionSchedule/ElectionSchedule'
import Survey from './Survey/survey'
import VoterList from './VoterList/VoterList'
import ElectionResults from './ElectionResults/ElectionResults'

import LiveCount from './LiveCount/LiveCount'
import LiveTrends from './LiveTrends/LiveTrends'
import Home from './SuperAdmin/Home'
import Volunteer from './Volunteer/Volunteer'






// import Analytics from './SuperAdmin/Analytics'
// import PartyLeaders from './SuperAdmin/PartyLeaders'
// import AssignPartyLeaders from './SuperAdmin/AssignPartyLeaders'
// import Documents from './SuperAdmin/Documents'
// import SurveyResult from './SuperAdmin/SurveyResult'
// import ElectionVehicles from './SuperAdmin/ElectionVehicles'
// import VolunteerSection from './SuperAdmin/VolunteerSection'
import { Provider} from 'react-redux'
import configureStore from './store';
import jwt_decode from 'jwt-decode';
import setAuthToken from './api/setAuthToken';
import {setCurrentUser} from './actions/ActiveUserAction'


// const Routes = () => 
// (
//     <BrowserRouter>
//       <Switch>
//           <Route exact path="/" component={Welcome}></Route>
//           <Route exact path="/login" component={Login}></Route>
//           <Route exact path="/pickup-service" component={Pickup}></Route>
//           <Route exact path="/election-schedule" component={ElectionSchedule}></Route>
//           <Route exact path="/survey" component={Survey}></Route>
//           <Route exact path="/voter-list" component={VoterList}></Route>
//           <Route exact path="/election-result" component={ElectionResults}></Route>
//           <Route exact path="/live-count" component={LiveCount}></Route>
//           <Route exact path="/live-trends" component={LiveTrends}></Route>
//           <Route exact path="/dashboard" component={Dashboard}></Route>
//           <Route exact path="/volunteer" component={Volunteer}></Route>
//           {/* <Route exact path="/analytics" component={Analytics}></Route>
//           <Route exact path="/party-leaders" component={PartyLeaders}></Route>
//           <Route exact path="/assign-party-leaders" component={AssignPartyLeaders}></Route>
//           <Route exact path="/documents" component={Documents}></Route>
//           <Route exact path="/survey-result" component={SurveyResult}></Route>
//           <Route exact path="/election-vehicles" component={ElectionVehicles}></Route>
//           <Route exact path="/volunteer-section" component={VolunteerSection}></Route> */}
//       </Switch> 
//       </BrowserRouter>
// );
// export default Routes;

class Routes extends React.Component {
    constructor(props){
		super(props)
		this.state ={
		  authUser:false
		}
	  }
	
	  componentDidMount() {
		const token = localStorage.getItem('jwtToken');
		if (token) {
		  setAuthToken(localStorage.jwtToken);
		  const decoded = jwt_decode(localStorage.jwtToken);
		  configureStore().dispatch(setCurrentUser(decoded));
		   // Check for expired token
		  const currentTime = Date.now() / 1000;
		  if (decoded.exp < currentTime) {
			//window.location.href = '/Login';
		  }
	   }
	  }


    render () {
      return(
        <Provider store={configureStore()}>
        <BrowserRouter>
      <Switch>
          <Route exact path="/" component={Welcome}></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/resetPassword" component={ResetPassword}></Route>
          <Route exact path="/pickup-service" component={Pickup}></Route>
          <Route exact path="/election-schedule" component={ElectionSchedule}></Route>
          <Route exact path="/survey" component={Survey}></Route>
          <Route exact path="/voter-list" component={VoterList}></Route>
          <Route exact path="/election-result" component={ElectionResults}></Route>
          <Route exact path="/live-count" component={LiveCount}></Route>
          <Route exact path="/live-trends" component={LiveTrends}></Route>
          <Route exact path="/home" component={Home}></Route>
          <Route exact path="/volunteer" component={Volunteer}></Route>
          {/* <Route exact path="/analytics" component={Analytics}></Route>
          <Route exact path="/party-leaders" component={PartyLeaders}></Route>
          <Route exact path="/assign-party-leaders" component={AssignPartyLeaders}></Route>
          <Route exact path="/documents" component={Documents}></Route>
          <Route exact path="/survey-result" component={SurveyResult}></Route>
          <Route exact path="/election-vehicles" component={ElectionVehicles}></Route>
          <Route exact path="/volunteer-section" component={VolunteerSection}></Route> */}
      </Switch> 
      </BrowserRouter>
    </Provider>)
    }
  }

export default Routes;