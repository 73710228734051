import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import DonutChart from '../Components/DonutChart';
import Serial from '../Components/Serial';



class Dashboard extends Component {

    constructor() {
        super();

        this.state = {
            constituencyID: [],
            polling_div: [],
            list_of_street: [],
            selected_constituency: '',
            sele_polling_div: '',
            selected_street: '',
            role: ''
        };
    }

    async componentDidMount() {
        const pd = await localStorage.getItem('pollingDivisionID')
        this.props.listConsituency()
        this.props.getSteet(pd)

        const role = await localStorage.getItem('role')
        //debugger
        console.log(role)
        if (role == 2) {
            this.setState({
                role: role
            }, async () => {
                this.props.getDashboardData({
                    "constituencyID": await localStorage.getItem('constituencyIDAdmin'),
                    "pollingDivisionID": await localStorage.getItem('pollingDivisionID'),
                    "streetID": ""

                })
            })

        } else {
            this.props.getDashboardData({
                "constituencyID": "",
                "pollingDivisionID": "",
                "streetID": ""

            })
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //    // debugger
    //     if (nextProps.constituency_list !== prevState.constituency_list) {
    //         return { constituency_list: nextProps.constituency_list };
    //     }

    //     //Get Polling Division Name
    //     if (nextProps.polling_div !== prevState.polling_div) {
    //         return { polling_div: nextProps.polling_div };
    //     }

    //     if (nextProps.list_of_street !== prevState.list_of_street) {
    //         return { list_of_streett: nextProps.list_of_street };
    //     }



    // }

    streetChanged = e => {
        if (e.target.value == '') {
            // debugger
            this.setState({ selected_street: '' })
        } else {
            // debugger
            this.setState({ selected_street: e.target.value }, () => {
                this.props.getDashboardData({
                    "constituencyID": this.state.selected_constituency,
                    "pollingDivisionID": this.state.sele_polling_div,
                    "streetID": this.state.selected_street
                })
            })
        }
    };

    pollingDivisionChanged = e => {
        if (e.target.value == '') {
            console.log(e.target.value);
            this.setState({ selected_street: '', sele_polling_div: e.target.value }, () => {
                this.props.getSteet();

                this.props.getDashboardData({
                    "constituencyID": this.state.selected_constituency,
                    "pollingDivisionID": "",
                    "streetID": ""

                })
            })
        } else {
            //debugger

            this.props.getSteet(e.target.value)

            this.setState({ sele_polling_div: e.target.value }, () => {
                this.props.getDashboardData({
                    "constituencyID": this.state.selected_constituency,
                    "pollingDivisionID": this.state.sele_polling_div,
                    "streetID": ""

                })
            })
        }
    };


    ConstituencyChanged = e => {
        if (e.target.value == '') {
            this.props.getPollingDiv(e.target.value);

            this.setState({ sele_polling_div: '', selected_street: '', selected_constituency: e.target.value }, () => {
                this.props.getSteet();
                this.props.getDashboardData({
                    "constituencyID": "",
                    "pollingDivisionID": "",
                    "streetID": ""

                })
            })

        } else {
            //debugger
            console.log(e.target.value);
            this.setState({ selected_constituency: e.target.value, sele_polling_div: '', selected_street: '' }, () => {
                this.props.getPollingDiv(this.state.selected_constituency)
                this.props.getSteet();
                this.props.getDashboardData({
                    "constituencyID": this.state.selected_constituency,
                    "pollingDivisionID": "",
                    "streetID": ""

                })
            })
        }
    };



    render() {
        console.log('%%%%%%%====>')
        console.log(this.props.polling_div)

        if (this.props.dashboardDataResults.length === 0) {
            return (
                <div>
                    <h1 style={{ textAlign: 'center' }}>Please wait we are loading Data From the Server...</h1>
                    <p></p>
                </div>
            )
        } else {
            console.log('%%%%%%%%%')
            console.log('%%%%%%%%%')
            console.log(this.props.dashboardDataResults)
            const { surveyAnalysis, generalAnalysis, HitRate, surveys, age, gender, favouriteParty, descreased, maritalStatus, employment, mobile, Streets } = this.props.dashboardDataResults[0]
            const { Attempts, Voter, VotersNotIntrested, talkingPoint, hitRate } = surveyAnalysis[0]
            const { streetCaptains, surveyWorkers, pollWorkers, vehicleProvider, streets } = generalAnalysis[0]
           
            const a1 = HitRate[0].Voter
            const a2 = HitRate[0].VotersNotIntrested

            const partyChartData = [{
                "data": "PNM",
                "value": 0,
                "color": '#FF0000',
            }, 
            {
                "data": "YEP",
                "value": 0,
                "color": '#800000',
            }, {
                "data": "UNC",
                "value": 0,
                "color": '#ffdb58',
            },
            {
                "data": "ILP",
                "value": 0,
                "color": '#808000',
            },{
                "data": "CA",
                "value": 0,
                "color": '#00FF00',
            }, {
                "data": "TPT",
                "value": 0,
                "color": '#008080',
            }
            , {
                "data": "DDP",
                "value": 0,
                "color": '#0000FF',
            }
            ,{
                "data": "COP",
                "value": 0,
                "color": '#000000',
            },
            {
                "data": "NNV",
                "value": 0,
                "color": '#fb5858',
            },
            {
                "data": "DNA",
                "value": 0,
                "color": '#fb5858',
            },
            //  {
            //     "data": "TOP",
            //     "value": 0,
            //     "color": '#fb5858',
            // }, {
            //     "data": "NCT",
            //     "value": 0,
            //     "color": '#fb5858',
            // },{
            //     "data": "TNV",
            //     "value": 0,
            //     "color": '#fb5858',
            // },
            // {
            //     "data": "TTNCP",
            //     "value": 0,
            //     "color": '#fb5858',
            // },
            // {
            //     "data": "DAC",
            //     "value": 0,
            //     "color": '#fb5858',
            // }, {
            //     "data": "TF",
            //     "value": 0,
            //     "color": '#fb5858',
            // }, 
            // {
            //     "data": "THC",
            //     "value": 0,
            //     "color": '#fb5858',
            // },
            {
                "data": "IND",
                "value": 0,
                "color": '#800080',
             },
            // {
            //     "data": "LOVE",
            //     "value": 0,
            //     "color": '#fb5858',
            // }, {
            //     "data": "IDP",
            //     "value": 0,
            //     "color": '#fb5858',
            // },{
            //     "data": "MSJ",
            //     "value": 0,
            //     "color": '#fb5858',
            // },{
            //     "data": "PDP",
            //     "value": 0,
            //     "color": '#fb5858',
            // },{
            //     "data": "DPTT",
            //     "value": 0,
            //     "color": '#fb5858',
            // }, {
            //     "data": "NAR",
            //     "value": 0,
            //     "color": '#fb5858',
            // }, {
            //     "data": "YOUR",
            //     "value": 0,
            //     "color": '#fb5858',
            // },{
            //     "data": "NJAC",
            //     "value": 0,
            //     "color": '#fb5858',
            // },{
            //     "data": "PP",
            //     "value": 0,
            //     "color": '#fb5858',
            // },
            {
                "data": "PF",
                "value": 0,
                "color": '#000080',
            }
        ]
           
        partyChartData.map(checkData => {
            const ageValue = favouriteParty.filter(data => {
                return data.party_name === checkData.data
            })
            checkData.value = ageValue.length != 0 ? ageValue[0].count : 0
            console.log('@@@@@@@@@@@')
            console.log(checkData)
        })

            const ageChartData = [{
                "data": "18 - 30",
                "value": 0,
                "color": '#4ca9f8',
            }, 
            {
                "data": "31 - 50",
                "value": 0,
                "color": '#fb5858',
            },
             {
                "data": "51 - 70",
                "value": 0,
                "color": '#ffff00',
            }, {
                "data": "70 - 100",
                "value": 0,
                "color": '#11db11',
            }]

            ageChartData.map(checkData => {
                const ageValue = age.filter(data => {
                    return data.age === checkData.data
                })
                checkData.value = ageValue.length != 0 ? ageValue[0].count : 0
                console.log('@@@@@@@@@@@')
                console.log(checkData)
            })

            const mobileChartData = [{
                "data": "Mobile Number Exists",
                "value": mobile[0].hasOwnProperty('mobileExists') ? mobile[0].mobileExists : 0,
                "color": '#4ca9f8',
            }, {
                "data": "Mobile Number Doesn't Exist",
                "value": mobile[0].hasOwnProperty('mobileNotExists') ? mobile[0].mobileNotExists : 0,
                "color": '#fb5858',
            }]

            const descreasedChartData = [{
                "data": "Alive",
                "value": descreased[0].Alive,
                "color": '#4ca9f8',
            }, {
                "data": "Deceased",
                "value": descreased[0].Descreased,
                "color": '#fb5858',
            }]

            const employmentChartData = [{
                "data": "Employed",
                "value": employment[0].Employed,
                "color": '#4ca9f8',
            }, {
                "data": "SelfEmployed",
                "value": employment[0].SelfEmployed,
                "color": '#fb5858',
            }, {
                "data": "Retired",
                "value": employment[0].Retired,
                "color": '#ffff00',
            }, {
                "data": "FullTimeStudent",
                "value": employment[0].FullTimeStudent,
                "color": '#11db11',
            }, {
                "data": "Unspecified",
                "value": employment[0].Unspecified,
                "color": '#ff9933',
            }]

            const genderChartData = [{
                "data": "Male",
                "value": gender[0].male,
                "color": '#4ca9f8',
            }, {
                "data": "Female",
                "value": gender[0].female,
                "color": '#fb5858',
            }, {
                "data": "Others",
                "value": gender[0].others,
                "color": '#ffff00',
            }]

            const hitRateChartData = [{
                "data": "Survey Submitted",
                "value": a1,
                "color": '#4ca9f8',
            }, {
                "data": "Not Interested In Survey",
                "value": a2,
                "color": '#fb5858',
            }]
            return (
                <div>
                    <div className="container-fluid">
                        <div className="row my-3 px-4 printShow printRow">
                            <div className="col-sm-4 printfleft">
                                <h1 className="text-primary ">Dashboard / Results</h1>
                            </div>
                            <div className="col-sm-8 printfright">
                                {this.state.role != 2 ?
                                    <div className="form-group" style={{ display: 'inline-block', width: '100%', marginBottom: 0, }}>
                                        <select
                                            className="form-control pull-right  mr-2"
                                            id="const_sele"
                                            onChange={this.ConstituencyChanged}
                                        >
                                            <option value="">Select a Constituency</option>
                                            {this.props.constituency_list.map((data, index) => {
                                                return (
                                                    <option value={data._id}>
                                                        {data.constituencyName}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    :
                                    null
                                }

                                {this.state.role != 2 ?
                                    <div className="form-group" style={{ display: 'inline-block', width: '100%', marginBottom: 0, }}>
                                        <select
                                            className="form-control pull-right mr-2"
                                            id="const_sele"
                                            onChange={this.pollingDivisionChanged}
                                        >
                                            <option value="">Select a Polling Division</option>
                                            {this.props.polling_div.map((data, index) => {
                                                return (
                                                    <option value={data._id}>
                                                        {data.pollingDivisionName}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div> : null
                                }

                                <div className="form-group" style={{ display: 'inline-block', width: '100%', marginBottom: 0, }}>
                                    {this.props.street_list.length != 0 ?
                                        <select
                                            className="form-control pull-right"
                                            id="const_sele"
                                            onChange={this.streetChanged}
                                        >
                                            <option value="">Select a Street</option>
                                            {this.props.street_list.map((data, index) => {
                                                return (
                                                    <option value={data._id}>
                                                        {data.street_name}
                                                    </option>
                                                );
                                            })}
                                        </select> : null}
                                </div>



                                <div className="form-group text-right printHide">
                                    <button class="btn_cust btn-primary"  onClick={() => window.print()}>Download and Print Report</button>
                                </div>

                            </div>
                            
                        </div>


                        <div className="row px-4 mt-5">
                            <div className="col-sm-12">
                                <h3>Survey Analysis</h3>
                                <div className="shadow_box">
                                    <div className="row column_row">
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Surveys Attempted</p>
                                            <p className="analyvalue">{Attempts}</p>
                                        </div>
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Surveys Submitted</p>
                                            <p className="analyvalue">{Voter}</p>
                                        </div>
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Not Interested In Survey</p>
                                            <p className="analyvalue">{VotersNotIntrested}</p>
                                        </div>
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Major Concerns By Voters</p>
                                            <p className="analyvalue">{talkingPoint}</p>
                                        </div>
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Hit Rate</p>
                                            <p className="analyvalue">{`${(Voter / Attempts * 100).toFixed(2) || 0}%`}</p>
                                        </div>
                                    </div>
                                </div>

                                <h3 className="mt-5">General Analysis</h3>
                                <div className="shadow_box">
                                    <div className="row column_row">
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Street Captains</p>
                                            <p className="analyvalue">{streetCaptains}</p>
                                        </div>
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Survey Workers</p>
                                            <p className="analyvalue">{surveyWorkers}</p>
                                        </div>
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Poll Workers</p>
                                            <p className="analyvalue">{pollWorkers}</p>
                                        </div>
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Vehicle Providers</p>
                                            <p className="analyvalue">{vehicleProvider}</p>
                                        </div>
                                        <div className="col-sm-2 text-center allyt_box">
                                            <p>Streets</p>
                                            <p className="analyvalue">{streets}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row my-5 px-4 printRow">
                            <div className="col-sm-4 printw40">
                                <div className="shadow_box">
                                    <h4>Hit Rate</h4>
                                    
                                    <p style={{ color:'blue'}}><b>(With the Mouseover, you can check % and Count.)</b></p>
                                    <DonutChart dataValue={hitRateChartData} />
                                </div>
                            </div>

                            <div className="col-sm-8 printw60">
                                <div className="shadow_box" style={{ height: '450px', overflow: 'auto' }}>
                                    <h4>Streets</h4>

                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Street Name</th>
                                                <th>Surveys Attempted</th>
                                                <th>Surveys Submitted</th>
                                                <th>Hit Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Streets.map(data => {
                                                return (
                                                    <tr>
                                                        <td>{data.street_name}</td>
                                                        <td>{data.Attempts}</td>
                                                        <td>{data.Voter}</td>
                                                        <td>{(data.Voter / data.Attempts * 100).toFixed(2)}%</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div className="row mt-5 px-4">
                            <div className="col-sm-12">
                                <div className="shadow_box">
                                    <h4>Surveys</h4>

                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Name</th>
                                                <th>Questions</th>
                                                {/* <th>Completed</th> */}
                                                <th>Count of Surveys Submitted</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {surveys.map(data => {
                                                return (
                                                    <tr>
                                                        <td key="1">{data.survey_name}</td>
                                                        <td key="2">{data.numOfQuetions}</td>
                                                        {/* <td key="3">{data.numOfAnswer}</td> */}
                                                        <td key="4">{data.Completed}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5 mb-5 px-4 printRow">
                            <div className="col-sm-4 printw50">
                                <div className="shadow_box">
                                    <h4>Gender</h4>
                                    <DonutChart dataValue={genderChartData} />
                                </div>
                            </div>
                            <div className="col-sm-4 printw50">
                                <div className="shadow_box">
                                    <h4>Age</h4>
                                    <DonutChart dataValue={ageChartData} />
                                </div>
                            </div>
                            <div className="col-sm-4 printw50">
                                <div className="shadow_box">
                                    <h4>Employment</h4>
                                    <DonutChart dataValue={employmentChartData} />
                                </div>
                            </div>
                            <div className="col-sm-4 mt-4 printw50">
                                <div className="shadow_box">
                                    <h4>Preferred Party</h4>
                                    <DonutChart dataValue={partyChartData}/>
                                </div>
                            </div>
                            <div className="col-sm-4 mt-4 printw50">
                                <div className="shadow_box">
                                    <h4>Mobile Numbers</h4>
                                    <DonutChart dataValue={mobileChartData} />
                                </div>
                            </div>
                            <div className="col-sm-4 mt-4 printw50">
                                <div className="shadow_box">
                                    <h4>Alive/Deceased Status</h4>
                                    <DonutChart dataValue={descreasedChartData} />
                                </div>
                            </div>
                        </div>

                        {/* <div className="row mt-5 px-4">
                      <div className="col-sm-4">
                          <div className="shadow_box">
                              <h4>Expected Voter turnout</h4>
                              <DonutChart />
                          </div>
                      </div>
      
                      <div className="col-sm-8">
                          <div className="shadow_box">
                              <h4>Volunteer Groups</h4>
                              
                              <table className="table">
                                  <thead className="thead-light">
                                      <tr>
                                          <th>Constituency</th>
                                          <th>Group Name</th>
                                          <th>Volunteer</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>Constituency</td>
                                          <td>Hello Group</td>
                                          <td>1154</td>
                                      </tr>
                                      <tr>
                                          <td>Constituency</td>
                                          <td>Hello Group</td>
                                          <td>1154</td>
                                      </tr>
                                      <tr>
                                          <td>Constituency</td>
                                          <td>Hello Group</td>
                                          <td>1154</td>
                                      </tr>
                                      <tr>
                                          <td>Constituency</td>
                                          <td>Hello Group</td>
                                          <td>1154</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
      
                  <div className="row my-5 px-4">
                      <div className="col-sm-4">
                          <div className="shadow_box">
                              <h4>Hits</h4>
                              <DonutChart />
                          </div>
                      </div>
      
                      <div className="col-sm-8">
                          <div className="shadow_box">
                              <h4>Previous Year Comparison</h4>
                              <Serial />
                          </div>
                      </div>
                  </div>          */}

                    </div>


                </div>
            )
        }


    }
}

const mapStateToProps = ({ authUser, campTeam, survey }) => {
    const { dashboardDataResults } = authUser
    const { constituency_list, polling_div } = campTeam;
    const { street_list } = survey;

    return { dashboardDataResults, constituency_list, polling_div, street_list };
};

export default connect(mapStateToProps, actions)(Dashboard);

