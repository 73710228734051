import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import moment from 'moment'
import Datatable from 'react-bs-datatable'; 

import {Tabs, Tab} from 'react-bootstrap-tabs';
import * as actions from '../actions';
import { connect } from 'react-redux';



class PaymentRequestDetail extends Component{
  constructor(props) {
    super(props);
 
    this.state = { 
      streetCaptainID:"",
      selected_index:0,
      userName:''
    };
  }
  async componentDidMount()
  {
   if(this.props.location.state)
   {
    const { streetCaptainID,userName } = this.props.location.state
    console.log('Street Captain ID');
    console.log(streetCaptainID);
    this.props.getPaymentRequestByWorkerDetail(streetCaptainID,5);
    this.setState({streetCaptainID:streetCaptainID,userName:userName})
   }
  }
  callDetail=(index)=>{
    console.log('Selected Index');
    console.log(index);
    this.setState({selected_index:index});
    if(index==0){
      this.props.getPaymentRequestByWorkerDetail(this.state.streetCaptainID,5);
    }
   if(index==1){
    this.props.getPaymentRequestByWorkerDetail(this.state.streetCaptainID,3);

   } 
   if(index==2){
    this.props.getPaymentRequestByWorkerDetail(this.state.streetCaptainID,4);

   } 
  }
    render(){
    console.log('Get Data');
    console.log(this.props.payment_detail);  

        const header = [
            { title: 'S.No.', prop: 'sn', sortable: true, filterable: true },
            { title: 'Name', prop: 'name', sortable: true },
            { title: 'Total Trips', prop: 'trips', sortable: true },
            { title: 'Total Amount', prop: 'amount', sortable: true },
            { title: 'Action', prop: 'action',  },
          ];
          
          var body=[];
          if(this.props.payment_detail.length>0 && this.state.selected_index==0)
          {
            this.props.payment_detail.map((data,index)=>{
              body.push({
                    sn: index +1 ,
                    name: data.name,
                    trips: 0,
                    amount:data.totalAmount,
                    action: <button className="btn-sm btn-small btn-success">Pay</button>
                  })
             
            }) 
          
            //console.log(body);
          }


          // const body = [
          //   {
          //     sn: '1',
          //     name: 'Pushpendra',
          //     trips: '2',
          //     km: '14',
          //     amount: '$ 25',
          //     action: <button className="btn-sm btn-small btn-success">Pay</button>,
          //   },
          //   {
          //       sn: '1',
          //       name: 'Pushpendra',
          //       trips: '2',
          //       km: '143',
          //       amount: '$ 285',
          //       action: <p className="btn-sm btn-small btn-danger">Paid</p>,
          //     },

          // ];
          
           
          const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'There is no data to be displayed',
          };



          const headersurvey = [
            { title: 'S.No.', prop: 'sn', sortable: true, filterable: true },
            { title: 'Name', prop: 'name', sortable: true },
            { title: 'Total House', prop: 'totalHouses', sortable: true },
            { title: 'Total Street', prop: 'totalStreets', sortable: true },
            { title: 'Total Amount', prop: 'amount', sortable: true },
            { title: 'Action', prop: 'action',  },
          ];
           
          // const bodysurvey = [
          //   {
          //     sn: '1',
          //     name: 'Pushpendra',
          //     survey: '2',
          //     amount: '$ 25',
          //     action: <button className="btn-sm btn-small btn-success">Pay</button>,
          //   },
          //   {
          //       sn: '1',
          //       name: 'Pushpendra',
          //       survey: '2',
          //       amount: '$ 285',
          //       action: <p className="btn-sm btn-small btn-danger">Paid</p>,
          //     },

          // ];
          var bodysurvey=[];
          if(this.props.payment_detail.length>0 && this.state.selected_index==2)
          {
            
            this.props.payment_detail.map((data,index)=>{
              bodysurvey.push({
                    sn: index +1 ,
                    name: data.name,
                    totalHouses:data.totalHouses,
                    totalStreets:data.totalStreets,
                    amount:data.totalAmount,
                    action: <button className="btn-sm btn-small btn-success">Pay</button>
                  })
             
            }) 
          
           // console.log(bodysurvey);
          }

          const headerpoll = [
            { title: 'S.No.', prop: 'sn', sortable: true, filterable: true },
            { title: 'Name', prop: 'name', sortable: true },
            { title: 'Total Sitting Hours', prop: 'sitting', sortable: true },
            { title: 'Total Verification', prop: 'verification', sortable: true },
            { title: 'Total Amount', prop: 'amount', sortable: true },
            { title: 'Action', prop: 'action',  },
          ];
           
          // const bodypoll = [
          //   {
          //     sn: '1',
          //     name: 'Pushpendra',
          //     sitting: '2',
          //     verification: '25/100',
          //     amount: '$ 25',
          //     action: <button className="btn-sm btn-small btn-success">Pay</button>,
          //   },
          //   {
          //       sn: '1',
          //       name: 'Pushpendra',
          //       sitting: '20',
          //       verification: '58/100',
          //       amount: '$ 285',
          //       action: <p className="btn-sm btn-small btn-danger">Paid</p>,
          //     },

          // ];
          var bodypoll = [];
          if(this.props.payment_detail.length>0 && this.state.selected_index==1)
          {
            this.props.payment_detail.map((data,index)=>{
              bodypoll.push({
                    sn: index +1 ,
                    name: data.name,
                    sitting: 0,
                    verification:data.totalPollingDivision,
                    amount:data.totalAmount,
                    action: <button className="btn-sm btn-small btn-success">Pay</button>
                  })
             
            }) 
          
            console.log(bodypoll);
          }

    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
    <h1 className="text-primary ">Payment Request / {this.state.userName}</h1>
                  </div>

                  <div className="col-sm-6 text-right">
                      <h4 className="text-primary ">Total amount Due - $ {this.props.detail_amt}</h4>
                  </div>
            </div>   


            <div className="row mt-5 px-4">

            <div className="col-sm-12 mb-4">
                <div className="shadow_box custom_datatable">

                    <Tabs onSelect={(index, label) =>{this.callDetail(index)}} selected={this.state.selected_index}>
                        <Tab label="Vehicle Provider">
                            <div className="tabContHead">
                                <p>Total Amount</p>
    <p className="font-weight-bold">$ {this.props.detail_amt}</p>
                            </div>
                            <Datatable
                                tableHeader={header}
                                tableBody={body}
                                keyName="userTable"
                                tableClass="striped hover responsive"
                                rowsPerPage={5}
                                rowsPerPageOption={[5, 10, 15, 20]}
                                initialSort={{prop: "username", isAscending: true}}
                                labels={customLabels}
                            />
                        </Tab>

                        <Tab label="Poll Workers">
                                <div className="tabContHead">
                                    <p>Total Amount</p>
                                    <p className="font-weight-bold">$ {this.props.detail_amt}</p>
                                </div>
                                <Datatable
                                    tableHeader={headerpoll}
                                    tableBody={bodypoll}
                                    keyName="userTable"
                                    tableClass="striped hover responsive"
                                    rowsPerPage={5}
                                    rowsPerPageOption={[5, 10, 15, 20]}
                                    initialSort={{prop: "username", isAscending: true}}
                                    labels={customLabels}
                                />    

                        </Tab>

                        <Tab label="Survey Workers">
                            
                                <div className="tabContHead">
                                    <p>Total Amount</p>
                                    <p className="font-weight-bold">$ {this.props.detail_amt}</p>
                                </div>
                                <Datatable
                                    tableHeader={headersurvey}
                                    tableBody={bodysurvey}
                                    keyName="userTable"
                                    tableClass="striped hover responsive"
                                    rowsPerPage={5}
                                    rowsPerPageOption={[5, 10, 15, 20]}
                                    initialSort={{prop: "username", isAscending: true}}
                                    labels={customLabels}
                                />


                        </Tab>
                    </Tabs>
                        
                </div>
            </div>        
                    
                                    
            </div> 


        </div>


      </div>
    )
  }
}
const mapStateToProps = ({payment}) => {
  const { payment_detail,detail_amt} = payment;
  return {payment_detail,detail_amt};
};
export default connect(
  mapStateToProps,
  actions
)(PaymentRequestDetail);


