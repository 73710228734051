import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Collapsible from 'react-collapsible';
import Modal from 'react-modal';



const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

class VehiclesRegistrations extends Component{
    constructor() {
        super();
     
        this.state = {
          modalIsOpen: false
        };
     
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
      }

      openModal() {
        this.setState({modalIsOpen: true});
      }
     
      afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
      }
     
      closeModal() {
        this.setState({modalIsOpen: false});
      }


    render(){

    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
                      <h1 className="text-primary ">Wheelchair Registrations</h1>
                  </div>
                  <div className="col-sm-6">                        
                        <div className="form-group mt-3">
                            <select className="form-control pull-right w-auto">
                                <option>4:00 pm - 5:00 pm</option>
                                <option>5:00 pm - 6:00 pm</option>
                                <option>6:00 pm - 7:00 pm</option>
                                <option>7:00 pm - 8:00 pm</option>
                            </select>
                        </div>
                        <button className="btn_cust btn-primary pull-right mr-3" onClick={this.openModal}>Register New Wheelchair</button>
                  </div>
            </div>   


            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
            >    
                <div className="modal_head">
                    <h1 className="text-primary ">Fill in your details</h1>
                    <button className="btn_close" onClick={this.closeModal}>x</button>
                </div>
                
                <div className="modal_body form-horizontal">

                    <div className="row mb-3">
                        <label className="control-label col-sm-4 pr-0" htmlFor="name">Name:</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="name" placeholder="Enter name"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="control-label col-sm-4 pr-0" htmlFor="Mobile">Mobile No:</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="Mobile" placeholder="Enter mobile no"/>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="control-label col-sm-4 pr-0" htmlFor="Location">Location:</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="Location" placeholder="Enter Location"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="control-label col-sm-4 pr-0" htmlFor="Polling">Polling Division No:</label>
                        <div className="col-sm-8">
                            <select className="form-control">
                                <option>Select Division</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="control-label col-sm-4 pr-0" htmlFor="Polling">Polling Division Name:</label>
                        <div className="col-sm-8">
                            <select className="form-control">
                                <option>Division</option>
                                <option>Division 1</option>
                                <option>Division 2</option>
                                <option>Division 3</option>
                                <option>Division 4</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="control-label col-sm-4 pr-0" htmlFor="people">Number of people:</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="people" placeholder="Enter Number of people"/>
                        </div>
                    </div>                    
                    <div className="row mb-3">
                        <label className="control-label col-sm-4 pr-0" htmlFor="name">Time:</label>
                        <div className="col-sm-8">
                            <select className="form-control">
                                <option>Select Time</option>
                                <option>11:00 AM - 12:00 PM</option>
                                <option>11:00 AM - 12:00 PM</option>
                                <option>11:00 AM - 12:00 PM</option>
                                <option>11:00 AM - 12:00 PM</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="control-label col-sm-4 pr-0" htmlFor="name">Comments:</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="name" placeholder="Enter Location"/>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="offset-4 col-sm-8">
                            <button className="btn btn-sm btn-success">Register</button> 
                        </div>
                    </div>

                </div>

            </Modal>


            <div className="row mt-5 px-4 leader_row">

                <div className="col-sm-12 mb-4">

                    <Collapsible trigger="Roston Chase, 6464654654 ">
                        <div className="vehi_content_box">
                            <p><span className="text-primary">Location :</span> 5/4, Avenue Road, West Trinidad</p>
                            <p><span className="text-primary">Polling Division No :</span> 5</p>
                            <p><span className="text-primary">Polling Division Name :</span> Division 1</p>
                            <p><span className="text-primary">Number of people :</span> 3</p>
                            <p><span className="text-primary">Time :</span> 4:30 PM</p>
                            <p><span className="text-primary">Comments :</span> 5</p>

                            <Link to="#" className="btn btn-primary pull-right">Assign Driver</Link>
                            <Link to="#" className="btn btn-outline-primary mr-3 pull-right">Reject Request</Link>
                        </div>
                    </Collapsible>

                    <Collapsible trigger="Christopher james, 87864654654 ">
                        <div className="vehi_content_box">
                            <p><span className="text-primary">Location :</span> 5/4, Avenue Road, West Trinidad</p>
                            <p><span className="text-primary">Polling Division No :</span> 5</p>
                            <p><span className="text-primary">Polling Division Name :</span> Division 1</p>
                            <p><span className="text-primary">Number of people :</span> 3</p>
                            <p><span className="text-primary">Time :</span> 4:30 PM</p>
                            <p><span className="text-primary">Comments :</span> 5</p>

                            <Link to="#" className="btn btn-primary pull-right">Assign Driver</Link>
                        </div>
                    </Collapsible>

                    <Collapsible trigger="Andre Asthana, 9894654654 ">
                        <div className="vehi_content_box">
                            <p><span className="text-primary">Location :</span> 5/4, Avenue Road, West Trinidad</p>
                            <p><span className="text-primary">Polling Division No :</span> 5</p>
                            <p><span className="text-primary">Polling Division Name :</span> Division 1</p>
                            <p><span className="text-primary">Number of people :</span> 3</p>
                            <p><span className="text-primary">Time :</span> 4:30 PM</p>
                            <p><span className="text-primary">Comments :</span> 5</p>

                            <Link to="#" className="btn btn-primary pull-right">Assign Driver</Link>
                        </div>
                    </Collapsible>
                    
                    


                </div>

                
            </div> 


        </div>


      </div>
    )
  }
}

export default VehiclesRegistrations;
