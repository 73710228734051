import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { NotificationContainer, NotificationManager } from 'react-notifications';

 class Profile extends Component {
    constructor() {
        super();
        this.state = {
            role: '1',
            name: "",
            email: "",
            mobile_no: "",
            office_no: "",
            home_address: "",
            office_address: "",
            file:"",
            loading:false,
        };
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value,
            [`${e.target.name}Error`]: ''
        })
    }

    componentDidMount() {

        this.setState({
            role: localStorage.getItem('role')
        })
     this.props.getUserProfile();  
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profile_info !== this.props.profile_info) {
            console.log('Did Update')
            console.log(this.props.profile_info);
            this.setState({
                name: this.props.profile_info.hasOwnProperty('name')?this.props.profile_info.name:'',
                email: this.props.profile_info.hasOwnProperty('email')?this.props.profile_info.email:'',
                mobile_no: this.props.profile_info.hasOwnProperty('mobile_no')?this.props.profile_info.mobile_no:'',
                office_no: this.props.profile_info.hasOwnProperty('office_no')?this.props.profile_info.office_no:'',
                home_address:this.props.profile_info.hasOwnProperty('home_address')?this.props.profile_info.home_address:'',
                office_address: this.props.profile_info.hasOwnProperty('office_address')?this.props.profile_info.office_address:'',
                avatar: this.props.profile_info.hasOwnProperty('avatar')?this.props.profile_info.avatar:'../img/candi_img.png',

                
            })
            
            if(this.state.role === "1")
            {
                localStorage.setItem("CM_profile",this.props.profile_info.hasOwnProperty('avatar')?this.props.profile_info.avatar:'')
            }
            else
            {
                localStorage.setItem("SC_profile",this.props.profile_info.hasOwnProperty('avatar')?this.props.profile_info.avatar:'')
    
            }

        
        }
      }
    validate = () => {
        let isError = false;
        // if (this.refs.loginRole.value == "") {
        //     isError = true;
        //     this.setState({ roleError: 'Please select role of User !' })
        // }

        if (this.state.name == "") {
            isError = true;
            this.setState({ nameError: 'Name can not be empty !' })
        }
        if (this.state.email == "") {
            isError = true;
            this.setState({ emailError: 'Email can not be empty !' })
        }
        var reg = /^\S+@\S+\.\S+$/;
        if (reg.test(this.state.email) === false) {
            isError = true;
            this.setState({ emailError: 'Enter a valid email id !' })

        }
        if (this.state.mobile_no == "") {
            isError = true;
            this.setState({ mobile_noError: 'Mobile number can not be empty !' })
        }
        var reg = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
        if (reg.test(this.state.mobile_no) === false) {
            isError = true;
            this.setState({ mobile_noError: 'Enter a valid mobile number !' })

        }
        // if (this.state.office_no == "") {
        //     isError = true;
        //     this.setState({ office_noError: 'Office number can not be empty !' })
        // }
        // var reg = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
        // if (reg.test(this.state.office_no) === false) {
        //     isError = true;
        //     this.setState({ office_noError: 'Enter a valid office number !' })

        // }
        if (this.state.home_address == "") {
            isError = true;
            this.setState({ home_addressError: 'Address can not be empty !' })
        }
        // if (this.state.office_address == "") {
        //     isError = true;
        //     this.setState({ office_addressError: 'Email can not be empty !' })
        // }
        return isError;
    };



    _onChange = (e) => {

        const file    = this.refs.uploadImg.files[0]
        const reader  = new FileReader();
    
        reader.onloadend = () => {
            this.setState({
                avatar: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                avatar :reader.result,
                file:this.refs.uploadImg.files[0]

            })
        } 
        else {
            this.setState({
                avatar: ""
            })
        }
    }

    onSubmit = e => {

        console.log(this.state.name, this.state.email)

        e.preventDefault();
        const err = this.validate();
        if (!err) {
    console.log('Office Addr');
    console.log(this.state.office_address)        
  const data = new FormData();
  const imagedata = this.state.file;
  console.log(imagedata);
  if(imagedata)
  {
    data.append('avatar', imagedata);
  }
  data.append('name', this.state.name);
  data.append('mobile_no', this.state.mobile_no);
  data.append('office_no', this.state.office_no);
  data.append('home_address', this.state.home_address);
  data.append('office_address', this.state.office_address);
  console.log(data);
  this.props.updateProfile(data);
  //this.setState({loading:false});   
   };
 }



    render() {
        const config = {
            "type": "pie",
            "labelRadius": -55,
            "labelText": "[[percents]]%",
            "fontSize": 14,
            "dataProvider": [{
                "country": "Poll Division 1",
                "litres": 80,
                "color": '#fb5858',
            }, {
                "country": "Poll Division 2",
                "litres": 30,
                "color": '#f6fb61',
            }],
            "valueField": "litres",
            "titleField": "country",
            "colorField": "color",
            "radius": "35%",
            "legend": {
                "position": "right",
                "generateFromData": true,
                "marginTop": 200,
            }
        };

        return (
            <div>

                <div className="container-fluid">
                    <div className="row my-3 px-4">
                        <div className="col-sm-6">
                            <h1 className="text-primary ">Profile</h1>
                        </div>
                    </div>
                   
                    <div className="row px-4">
                        <div className="col-sm-6">
                            {this.state.role === "1" ? null :null
                                // <div className="row mb-3">
                                //     <label className="control-label col-sm-4 pr-0" htmlFor="name">Campaign Team*:</label>
                                //     <div className="col-sm-8">
                                //         <input type="text" className="form-control" id="name" value="Campaign Team Name" readOnly />
                                //     </div>
                                // </div>
                            }
                            
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0 mt-5" htmlFor="Image">Profile Image:</label>
                                <div className="col-sm-5 mt-5">
                                    <input
                                        type="file"
                                        className="form-control"
                                        style={{ paddingBottom: 35, }}
                                        id="Image"
                                        ref="uploadImg"
                                        onChange={this._onChange}
                                        name="avatar"
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <img className="rounded-circle mx-auto img-thumbnail profilePic" src={this.state.avatar} alt="Card image cap" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="name">Name*:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        placeholder="Enter name"
                                        value={this.state.name}
                                        onChange={e => this.change(e)}
                                    />
                                    <label className="error">{this.state.nameError}</label>
                                </div>
                            </div>
                            
                            <div className="row mb-3">
                                
                                <label className="control-label col-sm-4 pr-0" htmlFor="email">Email*:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="Enter your Email"
                                        value={this.state.email}
                                        readOnly
                                        //onChange={e => this.change(e)}
                                    />
                                    <label className="error">{this.state.emailError}</label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="mobile">Mobile No*:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="mobile"
                                        name="mobile_no"
                                        placeholder="Enter mobile no"
                                        value={this.state.mobile_no}
                                        onChange={e => this.change(e)}
                                    />
                                    <label className="error">{this.state.mobile_noError}</label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="Office">Office No:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Office"
                                        name="office_no"
                                        placeholder="Enter Office no"
                                        value={this.state.office_no}
                                        onChange={e => this.change(e)}
                                    />
                                    <label className="error">{this.state.office_noError}</label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="Home">Home Address*:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Home"
                                        name="home_address"
                                        placeholder="Enter Home Address"
                                        value={this.state.home_address}
                                        onChange={e => this.change(e)}
                                    />
                                    <label className="error">{this.state.home_addressError}</label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="Address">Office Address:</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Address"
                                        name="office_address"
                                        placeholder="Enter Office Address"
                                        value={this.state.office_address}
                                        onChange={e => this.change(e)}
                                    />
                                    <label className="error">{this.state.office_addressError}</label>
                                </div>
                            </div>
                            {(this.state.loading)?
                           
                           <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>:null
                          }  
                            <div className="row mb-3">
                                <div className="offset-4 col-sm-8">
                                    <button className="btn btn-sm btn-success" onClick={e => this.onSubmit(e)}>Update</button>
                                </div>
                            </div>


                        </div>
                    </div>




                </div>
                <NotificationContainer />            
            </div>
        )
    }
}

const mapStateToProps = ({ authUser}) => {
    const { user, isAuthenticated,profile_info } = authUser;
  
    return {
      user,
      isAuthenticated,
      profile_info
    };
  };
  export default connect(
    mapStateToProps,
    actions
  )(Profile);

