/*
 This Action for add survey and add suvey questions.
*/
import jwt_decode from "jwt-decode";
import { NotificationManager } from 'react-notifications';
import {
  ADD_SURVEY,
  LIST_SURVEY,
  LIST_SURVEY_QUES,
  STREET_LIST,
  STREET_All_LIST,
  API_URl,
  EXCEL_CONCERN_LIST,
  TEN_NEW_VOTERS_PD_WISE,
  LOADER,
  TOP_10_PARTY_WISE_DATA,
  LOADERTWO,
  TOP_10_REGISTERED_VOTERS_PD_WISE,
  CONSTITUENCY_WISE_WINNING_MARGIN
} from './types';
import axios from 'axios';

// api
import api from '../api';

/**Add Survey */
export const addSurvey = (data, history) => {
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const TeamData = await axios.post(API_URl + "campaignmanagers/addSurvey", data, header);
      console.log(TeamData)
      debugger
      if (TeamData.status === 201) {
        NotificationManager.success('Survey has been successfully added');
        dispatch(getSurveyList())
      }
      else {
        NotificationManager.error("Unable to add Survey !");
      }
    } catch (error) {
      NotificationManager.error("Unable to add Survey !");
    }
  }
}

/**getSurveyList */
export const getSurveyList = () => {
  debugger
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const SurveyData = await axios.get(API_URl + "campaignmanagers/getSurvey", header);
      debugger
      console.log(SurveyData)
      if (SurveyData.status === 200) {
        dispatch({ type: LIST_SURVEY, payload: SurveyData.data.data });
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {
      debugger
      console.log(error.responseData)
      NotificationManager.error("Unable to Fetch !");
    }
  }
}

/**getConcernExcelData */
export const getConcernExcelData = (query = "") => {
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const SurveyData = await axios.get(API_URl + "campaignmanagers/getConcernExcelData" + query, header);

      if (SurveyData.status === 200) {

        //console.log(SurveyData.data.data)
        dispatch({ type: EXCEL_CONCERN_LIST, payload: SurveyData.data.data });
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {
      NotificationManager.error("Unable to Fetch !");
    }
  }
}


/**get10NewVotersPDwiseData */
export const get10NewVotersPDwiseData = (value, y1, y2) => {
  console.log('Value from click')
  console.log(value)
  var newData = "5d885773b3346c28f2d0317e"
  var year1 = "2015"
  var year2 = "2019"
  //5d885773b3346c28f2d03157
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const SurveyData = await axios.get(API_URl + "campaignmanagers/get10NewVotersPDWise?year1=" + y1 + "&year2=" + y2 + "&constituencyID=" + value, header);

      if (SurveyData.status === 200) {
        console.log(SurveyData)

        console.log(SurveyData.data.data)
        dispatch({ type: TEN_NEW_VOTERS_PD_WISE, payload: SurveyData.data.data });
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {
      dispatch({ type: TEN_NEW_VOTERS_PD_WISE, payload: [] });
    }
  }
}


/**top10Registered Voters PD wise */
export const top10RegisteredVotersPDWise = (value, year) => {
  debugger
  console.log('Value from Action{}')
  console.log(value)
  console.log(year)
  var localID = "5d885773b3346c28f2d03164"
  var localYear = "2015"
  //5d885773b3346c28f2d03157
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };

      // const SurveyData = await axios.get(API_URl + "campaignmanagers/get10NewVotersPDWise?year1=" + y1 + "&year2=" + y2 + "&constituencyID=" + value, header);
      const SurveyData = await axios.get(API_URl + "campaignmanagers/getTop10RegisteredVotersPDWise?constituencyID=" + value + "&year=" + year, header);

      if (SurveyData.status === 200) {

        console.log(SurveyData.data.data)
        dispatch({ type: TOP_10_REGISTERED_VOTERS_PD_WISE, payload: SurveyData.data.data });
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {
      console.log(error)
      //dispatch({ type: TEN_NEW_VOTERS_PD_WISE, payload: [] });
    }
  }
}

/**loaderValueChange */
export const loaderValueChange = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER, payload: false });
    } catch (error) {
      NotificationManager.error("Unable to Fetch !");
    }
  }
}

/**loaderValueChange */
export const loaderTwoValueChange = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADERTWO, payload: false });
    } catch (error) {
      NotificationManager.error("Unable to Fetch !");
    }
  }
}


/**getTop10PDPartyWise */
export const getTop10PDPartyWise = (value, partyValue, yearValue) => {
  console.log('Value from click')
  console.log(value)
  console.log(partyValue)
  var partyName = "UNC"
  var localYearValue = "2015"
  var newData = "5d885773b3346c28f2d03158"
  return async (dispatch) => {


    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };

      //const SurveyData = await axios.get(API_URl + "campaignmanagers/get10NewVotersPDWise?year1=2015&year2=2019&constituencyID=" + value, header);
      const SurveyData = await axios.get(API_URl + "campaignmanagers/getTop10PDPartyWise?year=" + yearValue + "&party_name=" + partyValue + "&constituencyID=" + value, header);
      console.log(SurveyData)
      debugger
      if (SurveyData.status === 200) {
        console.log('PD Wise')
        console.log(SurveyData.data.data)
        dispatch({ type: TOP_10_PARTY_WISE_DATA, payload: SurveyData.data.data });
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {
      NotificationManager.error("Unable to Fetch !");
    }
  }
}









//listSurveyQuestion
export const listSurveyQuestion = (surveyID) => {
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const SurveyQuesData = await axios.get(API_URl + "campaignmanagers/getSurveyQuestion?surveyID=" + surveyID, header);

      if (SurveyQuesData.status === 200) {
        dispatch({ type: LIST_SURVEY_QUES, payload: SurveyQuesData.data.data });
      }
      else {

        NotificationManager.error("Unable to Fetch ques list!");
      }
    } catch (error) {

      dispatch({ type: LIST_SURVEY_QUES, payload: [] });
    }
  }
}

//listStreet
export const getSteet = (PollingDivID = "") => {
  return async (dispatch) => {
    //debugger
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      if (PollingDivID == "") {
        dispatch({ type: STREET_LIST, payload: [] });
      }
      else {
        const StreetList = await axios.get(API_URl + "admins/getStreet?pollingDivisionID=" + PollingDivID, header);

        if (StreetList.status === 200) {
          dispatch({ type: STREET_LIST, payload: StreetList.data.data });
        }
        else {
          NotificationManager.error("Unable to Fetch !");
        }
      }

    } catch (error) {

      dispatch({ type: STREET_LIST, payload: [] });
    }
  }
}

//list All Street
export const getAllSteet = () => {
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const StreetList = await axios.get(API_URl + "admins/getStreet", header);

      if (StreetList.status === 200) {
        dispatch({ type: STREET_All_LIST, payload: StreetList.data.data });
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {
      NotificationManager.error("Unable to Fetch !");
    }
  }
}

//deleteQues
export const deleteQues = (deletedata, survey_id) => {
  return async (dispatch) => {
    try {

      const SurveyQuesData = await axios.delete(API_URl + "campaignmanagers/deleteSurveyQuestion", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
        },
        data: deletedata
      })

      if (SurveyQuesData.status === 200) {
        NotificationManager.success("Question deleted successfully !");
        dispatch(listSurveyQuestion(survey_id))

      }
      else {
        NotificationManager.error("Unable to delete !");
      }
    } catch (error) {
      NotificationManager.error("Unable to delete !");
    }
  }
}

//deleteSurvey
export const deleteSurvey = (deletedata, history) => {
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const deleteData = await api.delete(API_URl + "campaignmanagers/deleteSurvey", { data: deletedata }, header);

      if (deleteData.status === 200) {
        NotificationManager.success("Survey deleted successfully !");
        history.push('/survey')

      }
      else {
        NotificationManager.error("Unable to delete !");
      }
    } catch (error) {
      NotificationManager.error("Unable to delete !");
    }
  }
}
//Update Survey Status
export const UpdateSurveyStatus = (updateSurveyData) => {
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const updateData = await axios.put(API_URl + "campaignmanagers/setLiveSurvey", updateSurveyData, header);
      //   debugger
      if (updateData.status === 200) {
        NotificationManager.success("Survey status updated successfully !");

      }
      else {
        NotificationManager.error("Unable to update status !");
      }
    } catch (error) {
      NotificationManager.error("Unable to update status !");
    }
  }
}


/**Add Survey Questions */
export const addSurveyQues = (data, surveyID) => {
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const TeamData = await api.post(API_URl + "campaignmanagers/addSurveyQuestion", data, header);
      if (TeamData.status === 201) {
        NotificationManager.success('Survey question has been successfully added');
        dispatch(listSurveyQuestion(surveyID))
      }
      else {
        NotificationManager.error("Unable to add Survey ques!");
      }
    } catch (error) {
      NotificationManager.error("Unable to add Survey ques!");
    }
  }
}



/**Winnign Marin Consitutency */
export const winningMarginContituencyWise = (year) => {
  console.log('Value from click')
  var partyName = "UNC"
  var localYearValue = "2015"
  var newData = "5d885773b3346c28f2d03158"
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };

      //const SurveyData = await axios.get(API_URl + "campaignmanagers/get10NewVotersPDWise?year1=2015&year2=2019&constituencyID=" + value, header);
      const SurveyData = await axios.get(API_URl + "campaignmanagers/getWinnersAndVictoryMargine?year=" + year, header);

      if (SurveyData.status === 200) {
        console.log('CONSTITUENCY WINING')
        console.log(SurveyData.data.data)
        var responseData = SurveyData.data.data
        //debugger
        console.log(responseData)

        var allConcituencies = []

        responseData.forEach(element => {

          let arrayNew = []


          arrayNew.push({ partyName: 'pnm', constituency: element.constituencyName, totalVotes: element.pnm, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#FF3300" })
          arrayNew.push({ partyName: 'unc', constituency: element.constituencyName, totalVotes: element.unc, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#FFCC00" })
          arrayNew.push({ partyName: 'cop', constituency: element.constituencyName, totalVotes: element.cop, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#0E0E0D" })
          arrayNew.push({ partyName: 'thc', constituency: element.constituencyName, totalVotes: element.thc, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#01DF01" })
          arrayNew.push({ partyName: 'tnv', constituency: element.constituencyName, totalVotes: element.tnv, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#B404AE" })
          arrayNew.push({ partyName: 'star', constituency: element.constituencyName, totalVotes: element.star, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#00FFFF" })
          arrayNew.push({ partyName: 'ind', constituency: element.constituencyName, totalVotes: element.ind, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#FF8000" })
          arrayNew.push({ partyName: 'idp', constituency: element.constituencyName, totalVotes: element.idp, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#0B3861" })
          arrayNew.push({ partyName: 'ncp', constituency: element.constituencyName, totalVotes: element.ncp, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#084B8A" })
          arrayNew.push({ partyName: 'nnv', constituency: element.constituencyName, totalVotes: element.nnv, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#4B610B" })
          arrayNew.push({ partyName: 'ilp', constituency: element.constituencyName, totalVotes: element.ilp, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#F781F3" })
          arrayNew.push({ partyName: 'msj', constituency: element.constituencyName, totalVotes: element.msj, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#FE2E64" })
          arrayNew.push({ partyName: 'others', constituency: element.constituencyName, totalVotes: element.others, totalValidVotes: element.totalValidVotes, constituencyID: element._id.constituencyID, partyColor: "#848484" })
          arrayNew.sort((a, b) => b.totalVotes - a.totalVotes)
          allConcituencies.push(arrayNew)
          arrayNew = []
        })
        //debugger
        console.log(allConcituencies)

        var pieChartData = []
        var barChartData = []
        var parties = ['pnm', 'unc', 'cop', 'thc', 'tnv', 'star', 'ind', 'idp', 'ncp', 'nnv', 'ilp', 'msj', 'others']
        var partiesColor = [{ party: 'pnm', color: '#FF3300' }, { party: 'unc', color: '#FFCC00' }, { party: 'cop', color: '#0E0E0D' }, { party: 'thc', color: '#01DF01' },
        { party: 'tnv', color: '#B404AE' }, { party: 'star', color: '#00FFFF' }, { party: 'ind', color: '#FF8000' }, { party: 'idp', color: '#0B3861' }, { party: 'ncp', color: '#084B8A' },
        { party: 'nnv', color: '#4B610B' }, { party: 'ilp', color: '#F781F3' }, { party: 'msj', color: '#FE2E64' }, { party: 'others', color: '#848484' }]

        parties.map(data1 => {
          var count = 0
          var voteCastCount = 0
          var colorCode = ""
          allConcituencies.map(data2 => {
            data2.map(data3 => {
              if (data3.partyName == data1) {
                count = count + data3.totalVotes
                voteCastCount = voteCastCount + data3.totalValidVotes
              }
            })
          })
          var index = partiesColor.findIndex(data => data.party == data1)
          pieChartData.push({ partyName: data1, totalCount: count, voteCastCount: voteCastCount, partyColor: partiesColor[index].color })
          count = 0
          voteCastCount = 0
        })

        console.log(pieChartData)


        parties.map(data1 => {
          var count = 0
          var constituencyArray = []
          allConcituencies.map(data2 => {
            if (data2[0].partyName == data1) {
              count = count + 1
              constituencyArray.push(data2[0].constituencyID)
            }
          })
          var index = partiesColor.findIndex(data => data.party == data1)
          barChartData.push({ partyName: data1, totalWin: count, constituencyAllIDs: constituencyArray, partyColor: partiesColor[index].color })
          count = 0
          constituencyArray = []
        })

        console.log(barChartData)

        var mapDisplayData = []

        barChartData.map(data => {
          if (data.totalWin > 0) {
            data.constituencyAllIDs.map(data2 => {
              mapDisplayData.push({ partyName: data.partyName, constituencyID: data2, partyColor: data.partyColor })
            })
          } else {
            mapDisplayData.push({ partyName: data.partyName, constituencyID: "", partyColor: data.partyColor })
          }
        })

        var finalData = []




        finalData.push({ listDatat: allConcituencies }, { pieChartData: pieChartData }, { barChartData: barChartData },
          { mapDisplayData: mapDisplayData })

        console.log(finalData)

        dispatch({ type: CONSTITUENCY_WISE_WINNING_MARGIN, payload: finalData });
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {
      NotificationManager.error("Unable to Fetch !");
    }
  }
}







/**Winnign Marin PollingDivisions */
export const winningMarginPollingDivisionWise = (id = "5d885773b3346c28f2d0316a", year = "2015") => {
  console.log('Value from click')
  return async (dispatch) => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };

      //const SurveyData = await axios.get(API_URl + "campaignmanagers/get10NewVotersPDWise?year1=2015&year2=2019&constituencyID=" + value, header);
      const SurveyData = await axios.get(API_URl + "campaignmanagers/getWinnersAndVictoryMargine?year=" + year + "&constituencyID=" + id, header);

      if (SurveyData.status === 200) {
        console.log('PollingDivison WINING')
        console.log(SurveyData.data.data)
        var responseData = SurveyData.data.data
        debugger
        console.log(responseData)

        var allConcituencies = []

        responseData.forEach(element => {

          let arrayNew = []


          arrayNew.push({ partyName: 'pnm', constituency: element.pollingDivisionName, totalVotes: element.pnm, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#FF3300" })
          arrayNew.push({ partyName: 'unc', constituency: element.pollingDivisionName, totalVotes: element.unc, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#FFCC00" })
          arrayNew.push({ partyName: 'cop', constituency: element.pollingDivisionName, totalVotes: element.cop, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#0E0E0D" })
          arrayNew.push({ partyName: 'thc', constituency: element.pollingDivisionName, totalVotes: element.thc, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#01DF01" })
          arrayNew.push({ partyName: 'tnv', constituency: element.pollingDivisionName, totalVotes: element.tnv, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#B404AE" })
          arrayNew.push({ partyName: 'star', constituency: element.pollingDivisionName, totalVotes: element.star, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#00FFFF" })
          arrayNew.push({ partyName: 'ind', constituency: element.pollingDivisionName, totalVotes: element.ind, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#FF8000" })
          arrayNew.push({ partyName: 'idp', constituency: element.pollingDivisionName, totalVotes: element.idp, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#0B3861" })
          arrayNew.push({ partyName: 'ncp', constituency: element.pollingDivisionName, totalVotes: element.ncp, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#084B8A" })
          arrayNew.push({ partyName: 'nnv', constituency: element.pollingDivisionName, totalVotes: element.nnv, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#4B610B" })
          arrayNew.push({ partyName: 'ilp', constituency: element.pollingDivisionName, totalVotes: element.ilp, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#F781F3" })
          arrayNew.push({ partyName: 'msj', constituency: element.pollingDivisionName, totalVotes: element.msj, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#FE2E64" })
          arrayNew.push({ partyName: 'others', constituency: element.pollingDivisionName, totalVotes: element.others, totalValidVotes: element.totalValidVotes, constituencyID: element._id.pollingDivisionID, partyColor: "#848484" })
          arrayNew.sort((a, b) => b.totalVotes - a.totalVotes)
          allConcituencies.push(arrayNew)
          arrayNew = []
        })
        //debugger
        console.log(allConcituencies)

        var pieChartData = []
        var barChartData = []
        var parties = ['pnm', 'unc', 'cop', 'thc', 'tnv', 'star', 'ind', 'idp', 'ncp', 'nnv', 'ilp', 'msj', 'others']
        var partiesColor = [{ party: 'pnm', color: '#FF3300' }, { party: 'unc', color: '#FFCC00' }, { party: 'cop', color: '#0E0E0D' }, { party: 'thc', color: '#01DF01' },
        { party: 'tnv', color: '#B404AE' }, { party: 'star', color: '#00FFFF' }, { party: 'ind', color: '#FF8000' }, { party: 'idp', color: '#0B3861' }, { party: 'ncp', color: '#084B8A' },
        { party: 'nnv', color: '#4B610B' }, { party: 'ilp', color: '#F781F3' }, { party: 'msj', color: '#FE2E64' }, { party: 'others', color: '#848484' }]

        parties.map(data1 => {
          var count = 0
          var voteCastCount = 0
          var colorCode = ""
          allConcituencies.map(data2 => {
            data2.map(data3 => {
              if (data3.partyName == data1) {
                count = count + data3.totalVotes
                voteCastCount = voteCastCount + data3.totalValidVotes
              }
            })
          })
          var index = partiesColor.findIndex(data => data.party == data1)
          pieChartData.push({ partyName: data1, totalCount: count, voteCastCount: voteCastCount, partyColor: partiesColor[index].color })
          count = 0
          voteCastCount = 0
        })

        console.log(pieChartData)


        parties.map(data1 => {
          var count = 0
          var constituencyArray = []
          allConcituencies.map(data2 => {
            if (data2[0].partyName == data1) {
              count = count + 1
              constituencyArray.push(data2[0].constituencyID)
            }
          })
          var index = partiesColor.findIndex(data => data.party == data1)
          barChartData.push({ partyName: data1, totalWin: count, constituencyAllIDs: constituencyArray, partyColor: partiesColor[index].color })
          count = 0
          constituencyArray = []
        })

        console.log(barChartData)

        var mapDisplayData = []

        barChartData.map(data => {
          if (data.totalWin > 0) {
            data.constituencyAllIDs.map(data2 => {
              mapDisplayData.push({ partyName: data.partyName, constituencyID: data2, partyColor: data.partyColor })
            })
          } else {
            mapDisplayData.push({ partyName: data.partyName, constituencyID: "", partyColor: data.partyColor })
          }
        })

        var finalData = []




        finalData.push({ listDatat: allConcituencies }, { pieChartData: pieChartData }, { barChartData: barChartData },
          { mapDisplayData: mapDisplayData })

        console.log(finalData)

        dispatch({ type: CONSTITUENCY_WISE_WINNING_MARGIN, payload: finalData });
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {
      NotificationManager.error("Unable to Fetch !");
    }
  }
}