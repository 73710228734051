import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Moment from 'react-moment';
// redux action
import * as actions from '../actions';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


class CampaignTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            modalIsOpen2: false,
            modalIsOpen3: false,
            modalIsOpen4: false,
            pollingDivision_more_display: [],
            pause_the_campagin: true,
            editIsActiveValue: true,
            constituency_list: [],
            polling_div: [],
            selectedConst: "",
            selectedConstName: "",
            selected_polling_div: [],
            polling_div_name: "",
            campaign_name: "",
            start_date: "",
            end_date: "",
            selectedTeamID: "",
            errors: {}
        };


        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.openModal2 = this.openModal2.bind(this);
        this.closeModal2 = this.closeModal2.bind(this);

        this.openModal3 = this.openModal3.bind(this);
        this.closeModal3 = this.closeModal3.bind(this);

        this.openModal4 = this.openModal4.bind(this);
        this.closeModal4 = this.closeModal4.bind(this);
    }

    //Call Api for consituency Liist
    componentDidMount() {
        this.props.listConsituency();
        this.props.getTeamList();
        this.props.getElectionResultDataWithFilter('2010');
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.constituency_list !== prevState.constituency_list) {
            return { constituency_list: nextProps.constituency_list };
        }
        //Get Polling Division Name
        if (nextProps.polling_div !== prevState.polling_div) {
            return { polling_div: nextProps.polling_div };
        }
        else return null;
    }

    //   componentDidUpdate(prevProps, prevState) {
    //    debugger
    //     if(prevProps.constituency_list!==this.props.constituency_list){
    //      //Perform some operation here
    //      this.setState({constituency_list: this.props.constituency_list});

    //    }
    //  }

    openModal() {
        this.setState({
            modalIsOpen: true,
            campaign_name: '',
            selectedConst: '',
            selected_polling_div: [],
            start_date: '',
            polling_div: [],
            end_date: ''
        });
    }


    closeModal() {
        //debugger
        this.props.getClearData()
        this.setState({ modalIsOpen: false, polling_div: [] }, console.log('Polling Division'));

    }

    //Change Date format
    changeDateFormat = (dateres) => {

        var curr_date = dateres.getDate();
        var curr_month = dateres.getMonth() + 1; //Months are zero based
        var curr_year = dateres.getFullYear();
        return (curr_year + "-" + curr_month + "-" + curr_date);
    }

    //edit Modal
    openModal2(data) {
        let res = Array.prototype.map.call(data.pollingDivisionID, s => s.pollingDivisionName).toString(); // "A,B,C"
        var start = new Date(data.start_date.toString());
        var startDate = this.changeDateFormat(start);
        var end = new Date(data.end_date.toString());
        var endDate = this.changeDateFormat(end);
        console.log('********')
        console.log(data.isActive)
        console.log('********')
        this.setState({
            modalIsOpen2: true,
            selectedConst: data.constituencyID._id,
            selectedTeamID: data._id,
            selectedConstName: data.constituencyID.constituencyName,
            start_date: startDate,
            end_date: endDate,
            campaign_name: data.campaign_name,
            polling_div_name: res,
            editIsActiveValue: data.isActive
        }, console.log(`----> ${this.state.editIsActiveValue}`));
    }


    closeModal2() {
        this.setState({ modalIsOpen2: false });
    }

    // Third Model Open
    openModal3(data) {
        this.setState({
            modalIsOpen3: true,
            pollingDivision_more_display: data.pollingDivisionID
        })
    }

    // Third Model Close
    closeModal3() {
        console.log('Yes bro whats up !')
        this.setState({
            modalIsOpen3: false
        })
    }

    // Fourth Model Open
    openModal4() {
        this.setState({
            modalIsOpen4: true,
        })
    }

    // Fourth Model Close
    closeModal4() {
        this.setState({
            modalIsOpen4: false
        })
    }


    //Constituency Changes
    optionChanged = (e) => {
        //alert("Load")
        if (e.target.value != "") {
            this.props.getPollingDiv(e.target.value);
        }
        this.setState({ selectedConst: e.target.value, errors: {} });
    };

    ConstituencyChanged = (e) => {

        if (e.target.value == "") {
            console.log(e.target.value)

            this.props.getTeamList();
        }
        else {
            console.log(e.target.value)

            this.props.getTeamList('', e.target.value);
        }
    };


    //pollingDivChanged
    pollingDivChanged = (e) => {

        let selected = [...this.refs.collegeList.selectedOptions].map(o => o.value)
        this.setState({ selected_polling_div: selected, errors: {} });

    };

    onChange = e => this.setState({ [e.target.name]: e.target.value, errors: {} })

    handleValidation() {

        let errors = {};
        let formIsValid = true;
        const {
            campaign_name,
            selectedConst,
            selected_polling_div,
            start_date,
            end_date

        } = this.state;
        //Name
        if (!campaign_name) {
            formIsValid = false;
            errors["campaign_name"] = "Cannot be empty";
        }

        if (!selectedConst) {
            formIsValid = false;
            errors["selectedConst"] = "Please select constituency";
        }

        if (selected_polling_div.length == 0) {
            formIsValid = false;
            errors["selected_polling_div"] = "Please select polling division";
        }

        if (!start_date) {
            formIsValid = false;
            errors["start_date"] = "Please select start date";
        }

        if (!end_date) {
            formIsValid = false;
            errors["end_date"] = "Please select end date";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    //editTeam    
    editTeam = () => {
        const editTeam = {
            campaingnTeamID: this.state.selectedTeamID,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            isActive: this.state.pause_the_campagin

        }

        const editPauseCampaign = {
            campaingnTeamID: this.state.selectedTeamID,
            isActive: this.state.pause_the_campagin
        }

        this.props.updateTeam(editTeam, editPauseCampaign, this.props.history)
        this.setState({ modalIsOpen2: false })
    }

    //Add Camapign Team
    _addCampaignTeam = () => {
        const {
            campaign_name,
            selectedConst,
            selected_polling_div,
            start_date,
            end_date

        } = this.state;
        if (this.handleValidation()) {
            const addcampTeam = {
                campaign_name: campaign_name,
                constituencyID: selectedConst,
                pollingDivisionID: selected_polling_div,
                start_date: start_date,
                end_date: end_date
            }
            this.props.addTeam(addcampTeam, this.props.history)
            this.setState({ modalIsOpen: false });
        }
    }

    pauseCampagin() {
        this.setState({
            pause_the_campagin: !this.state.pause_the_campagin
        }, console.log(this.state.pause_the_campagin))
    }

    render() {
        console.log(this.props.team_list);
        console.log(this.state.polling_div)
        return (
            <div>

                <div className="container-fluid">
                    <div className="row my-3 px-4">
                        <div className="col-sm-4">
                            <h1 className="text-primary ">Campaign Team</h1>
                        </div>
                        <div className="col-sm-8">
                            <div className="title_right_sec">
                                <Link className="btn_cust btn-primary pull-left mr-3" to="#" onClick={this.openModal}><i className="fa fa-user-plus"></i> &nbsp; ADD NEW TEAM</Link>
                                <div className="form-group pull-right">
                                    <select className="form-control pull-right" id="const_sele"
                                        name="consti"
                                        onChange={this.ConstituencyChanged}
                                    >
                                        <option value="">Select a Constituency</option>
                                        {
                                            this.state.constituency_list.map((data, index) => {
                                                return (<option value={data._id}>{data.constituencyName}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group pull-right  mr-3">
                                    <select className="form-control pull-right">
                                        <option value="">Select a Polling Division</option>
                                        <option>Division 1</option>
                                        <option>Division 2</option>
                                        <option>Division 3</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                            <h1 className="text-primary ">Campaign Team</h1>
                            <button className="btn_close" onClick={this.closeModal}>x</button>
                        </div>

                        <div className="modal_body form-horizontal">

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="name">Campaign Name:</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control"
                                        id="name"
                                        name="campaign_name"
                                        placeholder="Enter Campaign name"
                                        onChange={this.onChange}
                                    />
                                    <span className="error">{this.state.errors["campaign_name"]}</span>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="ConstituencyName">Constituency Name:</label>
                                <div className="col-sm-8">

                                    <select className="form-control"
                                        id="const_sele"
                                        name="constituency"
                                        onChange={this.optionChanged}
                                    >
                                        <option value="">Select a Constituency</option>
                                        {
                                            this.state.constituency_list.map((data, index) => {
                                                return (<option value={data._id}>{data.constituencyName}</option>)
                                            })
                                        }
                                    </select>
                                    <span className="error">{this.state.errors["selectedConst"]}</span>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="ConstituencyName">Pollingg Division:</label>
                                <div className="col-sm-8">
                                    <select className="form-control" multiple
                                        ref="collegeList"
                                        onChange={this.pollingDivChanged}
                                    >   <option>Select Division</option>
                                        {
                                            this.state.polling_div.map((data, index) => {
                                                return (<option value={data._id}>{data.pollingDivisionName}</option>)
                                            })
                                        }
                                    </select>
                                    <span className="error">{this.state.errors["selected_polling_div"]}</span>

                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="date">Start Date:</label>
                                <div className="col-sm-8">
                                    <input type="date" max="9999-12-31" className="form-control"
                                        id="date"
                                        name="start_date"
                                        onChange={this.onChange}
                                    />
                                    <span className="error">{this.state.errors["start_date"]}</span>

                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="date">End Date:</label>
                                <div className="col-sm-8">
                                    <input type="date" max="9999-12-31" className="form-control"
                                        id="date"
                                        name="end_date"
                                        onChange={this.onChange}
                                    />
                                    <span className="error">{this.state.errors["end_date"]}</span>

                                </div>
                            </div>


                            <div className="row mb-3">
                                <div className="offset-4 col-sm-8">
                                    <button className="btn btn-sm btn-success" onClick={this._addCampaignTeam}>Add</button>
                                </div>
                            </div>

                        </div>

                    </Modal>



                    <Modal
                        isOpen={this.state.modalIsOpen2}
                        onRequestClose={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                            <h1 className="text-primary ">Edit {this.state.campaign_name} {this.state.editIsActiveValue}</h1>
                            <button className="btn_close" onClick={this.closeModal2}>x</button>
                        </div>

                        <div className="modal_body form-horizontal">


                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="ConstituencyName">Constituency Name:</label>
                                <div className="col-sm-8">
                                    <label>{this.state.selectedConstName}</label>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="ConstituencyName">Polling Division:</label>
                                <div className="col-sm-8">
                                    <label>{this.state.polling_div_name}</label>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="date">Start Date:</label>
                                <label>{this.state.editIsActiveValue}</label>
                                <div className="col-sm-8">
                                    <input type="date" max="9999-12-31" className="form-control" value={this.state.start_date} id="date" readOnly />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="control-label col-sm-4 pr-0" htmlFor="date">End Date:</label>
                                <div className="col-sm-8">
                                    <input type="date" max="9999-12-31" className="form-control"
                                        value={this.state.end_date}
                                        id="date"
                                        name="end_date"
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>



                            <div className="row mb-3">
                                <div className="col-sm-8 offset-sm-4">
                                    <p>{this.state.editIsActiveValue}</p>
                                    <label><input type="checkbox"
                                        onChange={this.pauseCampagin.bind(this)}
                                    />{!this.state.editIsActiveValue ? 'Resume this campaign' : 'Pause this campaign'}</label>
                                </div>
                            </div>


                            <div className="row mb-3">
                                <div className="offset-4 col-sm-8">
                                    <button className="btn btn-sm btn-success"
                                        onClick={this.editTeam}
                                    >Done</button>
                                </div>
                            </div>

                        </div>

                    </Modal>






                    <Modal
                        isOpen={this.state.modalIsOpen3}
                        onRequestClose={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                            <h1 className="text-primary ">Polling Divisions</h1>
                            <button className="btn_close" onClick={this.closeModal3}>x</button>
                        </div>

                        <div className="modal_body form-horizontal">


                            <div className="row mb-3">

                                <div className="col-sm-8">
                                    {this.state.pollingDivision_more_display.map(data => <p>{data.pollingDivisionName}</p>)}
                                </div>
                            </div>


                        </div>

                    </Modal>




                    <Modal
                        isOpen={this.state.modalIsOpen4}
                        onRequestClose={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                            <h1 className="text-primary ">Street Captain</h1>
                            <button className="btn_close" onClick={this.closeModal4}>x</button>
                        </div>

                        <div className="modal_body form-horizontal">


                            <div className="row mb-3">

                                <div className="col-sm-8">
                                    <p>Street Captain name 1</p>
                                    <p>Street Captain name 2</p>
                                    <p>Street Captain name 3</p>
                                    <p>Street Captain name 4</p>
                                    <p>Street Captain name 5</p>
                                    <p>Street Captain name 6</p>
                                </div>
                            </div>


                        </div>

                    </Modal>










                    <div className="row mt-5 px-4 leader_row">
                        {
                            (this.props.team_list.length > 0) ?
                                this.props.team_list.map((data) => {
                                    return (
                                        <div className="col-sm-4 mt-5">
                                            <div className="shadow_box leader_box p-0 pt-3">
                                                <div className="card">
                                                    <div className="card-body p-0 px-4">

                                                        <div className="row">
                                                            <div className="col-sm-7">
                                                                <h5 className={data.isActive ? "card-title text-primary" : "card-title text-danger"}>{data.campaign_name}</h5>
                                                                <p className="card-text"><strong>  {data.constituencyID.constituencyName}</strong></p>
                                                                <p className="card-text">{(data.pollingDivisionID.length > 0) ? data.pollingDivisionID[0].pollingDivisionName : ''}</p>
                                                                <p>{(data.pollingDivisionID.length > 1) ?
                                                                    <Link to="#" onClick={() => this.openModal3(data)}>more</Link> : ''}</p>
                                                            </div>

                                                            <div className="col-sm-5 text-right">
                                                                <Link className="btn_cust btn-primary" to="#" onClick={() => { this.openModal2(data) }}><i className="fa fa-edit"></i></Link>
                                                                <p className="text-success mt-3"><strong>Active Since</strong></p>
                                                                <p> <Moment format="Do MMMM, YYYY">
                                                                    {data.start_date}
                                                                </Moment></p>
                                                            </div>
                                                        </div>

                                                        <div className="bottom_row">
                                                            <ul>
                                                                <li>
                                                                    <Link to="#" onClick={() => { this.openModal4() }}>
                                                                        <p>Street Captain</p>
                                                                        <p>{data.total.totalStreetCaptain}</p>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#" onClick={() => { this.openModal4() }}>
                                                                        <p>Vehicle Provider</p>
                                                                        <p>{data.total.totalVehicleProvider}</p>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#" onClick={() => { this.openModal4() }}>
                                                                        <p>Poll Workers</p>
                                                                        <p>{data.total.totalPollWorkers}</p>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#" onClick={() => { this.openModal4() }}>
                                                                        <p>Survey Workers</p>
                                                                        <p>{data.total.totalSurveyWorkers}</p>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    )
                                }) : null
                        }


                    </div>


                </div>
                <NotificationContainer />

            </div>
        )
    }
}

const mapStateToProps = ({ authUser, campTeam }) => {
    const { user, isAuthenticated } = authUser;
    const { loading, constituency_list, polling_div, team_list } = campTeam;

    return { user, loading, isAuthenticated, constituency_list, polling_div, team_list }
}
export default connect(mapStateToProps, actions)(CampaignTeam);

