import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import { connect } from 'react-redux';
import * as actions from '../actions';

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import moment from 'moment'
import Datatable from 'react-bs-datatable';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


class SurveyWorker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
      survey_workerCMg: [],
      selectedContituency: "",
      selectedPollingDiv: "",
      selectedStreet: "",
      v_id: ""
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(id) {
    this.setState({
      modalIsOpen: true,
      v_id: id
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  componentDidMount() {
    this.props.listConsituency();
    this.props.surveyWorkerCManager()
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    //Get Polling Division Name
    if (nextProps.survey_workerCMg !== prevState.survey_workerCMg) {
      return { survey_workerCMg: nextProps.survey_workerCMg };
    }
    else return null;
  }

  //On constituency Changed
  ConstituencyChanged = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    if (e.target.value == '') {
      this.setState({ selectedContituency: e.target.value, selectedStreet: '' })
      this.props.getPollingDiv(e.target.value);
      this.props.getSteet();
      this.props.surveyWorkerCManager(qryString);


    } else {

      this.setState({ selectedContituency: e.target.value })
      this.props.getPollingDiv(e.target.value);
      qryString = '?constituencyID=' + e.target.value;
      this.props.surveyWorkerCManager(qryString);
    }
  };
  PollingDivChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedPollingDiv: e.target.value, selectedStreet: '' })
      this.props.getSteet();
      qryString = '?constituencyID=' + this.state.selectedContituency;
      this.props.surveyWorkerCManager(qryString);


    } else {

      this.setState({ selectedPollingDiv: e.target.value })
      this.props.getSteet(e.target.value);
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + e.target.value;
      this.props.surveyWorkerCManager(qryString);
    }
  };
  //On street Changed
  streetChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv;

      this.props.surveyWorkerCManager(qryString);

    } else {

      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv + '&streetID=' + e.target.value;

      this.props.surveyWorkerCManager(qryString);

    }
  }
  render() {

    console.log('*********')
    console.log(this.state.survey_workerCMg)

    const header = [
      { title: 'Name', prop: 'name', sortable: true },
      {
        title: 'Active Since',
        prop: 'createdAt',
        cell: row => moment(row.createdAt).format('Do MMMM YYYY'),
        sortable: true
      },
      {
        title: 'Last Active',
        prop: 'updatedAt',
        cell: row => moment(row.updatedAt).format('Do MMMM YYYY'),
        sortable: true
      },
      {
        title: 'Constituency',
        prop: 'constituency',
        cell: row => row.constituencyID ? row.constituencyID.constituencyName : null,
        sortable: true
      },
      {
        title: 'Polling Division',
        prop: 'pollingDivisionID.pollingDivisionName',
        cell: row => <text>{row.pollingDivisionID.pollingDivisionName}</text>,
        sortable: true
      },
      { title: 'Total Survey Conducted', prop: 'totalSurvey', sortable: true },
      { title: 'Total Areas of Concern Expressed', prop: 'totalTakingPoints', sortable: true },
      {
        title: 'Action',
        prop: 'action2',
        cell: row => <div
          style={{ width: '70px' }}>

          <button class="btn_cust btn-danger pull-left mr-3" onClick={() => this.openModal(row._id)}>
            <i class="fa fa-trash"></i>
          </button>
        </div>
      },
      {
        title: 'Block Worker from Accessing Application',
        prop: 'action',
        cell: row => <Toggle
          defaultChecked={row.is_active}
          onChange={() => this.props.pollWorkerStatusUpdate(row._id, row.is_active, 's')} />
      },
    ];

    const body = [
      {
        sn: '1',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        team: '5',
        constituency: 'constituency name',
        polling: '25',
        survey: '52',
        takingpoints: '22',
        action2: <div style={{ width: '160px' }}><button class="btn_cust btn-primary pull-left mr-3"><i class="fa fa-edit"></i></button><button class="btn_cust btn-danger pull-left mr-3"><i class="fa fa-trash"></i></button></div>,
        action: <Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false}
          onChange={this.handleTofuChange} />,
      },
      {
        sn: '2',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        team: '5',
        constituency: 'constituency name',
        polling: '25',
        survey: '52',
        takingpoints: '22',
        action2: <div style={{ width: '160px' }}><button class="btn_cust btn-primary pull-left mr-3"><i class="fa fa-edit"></i></button><button class="btn_cust btn-danger pull-left mr-3"><i class="fa fa-trash"></i></button></div>,
        action: <Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false}
          onChange={this.handleTofuChange} />,
      },
      {
        sn: '3',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        team: '5',
        constituency: 'constituency name',
        polling: '25',
        survey: '52',
        takingpoints: '22',
        action2: <div style={{ width: '160px' }}><button class="btn_cust btn-primary pull-left mr-3"><i class="fa fa-edit"></i></button><button class="btn_cust btn-danger pull-left mr-3"><i class="fa fa-trash"></i></button></div>,
        action: <Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false}
          onChange={this.handleTofuChange} />,
      },
      {
        sn: '4',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        team: '5',
        constituency: 'constituency name',
        polling: '25',
        survey: '52',
        takingpoints: '22',
        action2: <div style={{ width: '160px' }}><button class="btn_cust btn-primary pull-left mr-3"><i class="fa fa-edit"></i></button><button class="btn_cust btn-danger pull-left mr-3"><i class="fa fa-trash"></i></button></div>,
        action: <Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false}
          onChange={this.handleTofuChange} />,
      },
      {
        sn: '5',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        team: '5',
        constituency: 'constituency name',
        polling: '25',
        survey: '52',
        takingpoints: '22',
        action2: <div style={{ width: '160px' }}><button class="btn_cust btn-primary pull-left mr-3"><i class="fa fa-edit"></i></button><button class="btn_cust btn-danger pull-left mr-3"><i class="fa fa-trash"></i></button></div>,
        action: <Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false}
          onChange={this.handleTofuChange} />,
      },
      {
        sn: '6',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        team: '5',
        constituency: 'constituency name',
        polling: '25',
        survey: '52',
        takingpoints: '22',
        action2: <div style={{ width: '160px' }}><button class="btn_cust btn-primary pull-left mr-3"><i class="fa fa-edit"></i></button><button class="btn_cust btn-danger pull-left mr-3"><i class="fa fa-trash"></i></button></div>,
        action: <Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false}
          onChange={this.handleTofuChange} />,
      },
      {
        sn: '7',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        team: '5',
        constituency: 'constituency name',
        polling: '25',
        survey: '52',
        takingpoints: '22',
        action2: <div style={{ width: '160px' }}><button class="btn_cust btn-primary pull-left mr-3"><i class="fa fa-edit"></i></button><button class="btn_cust btn-danger pull-left mr-3"><i class="fa fa-trash"></i></button></div>,
        action: <Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false}
          onChange={this.handleTofuChange} />,
      },

    ];

    const onSortFunction = {
      date(columnValue) {
        return moment(columnValue, 'Do MMMM YYYY').valueOf();
      },
    };

    const customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
      filterText: "Search records"
    };



    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <div className="col-sm-4 printfleft">
              <h1 className="text-primary ">Survey Worker</h1>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={false}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="modal_head">
                <h1 className="text-primary "> Delete Survey Worker </h1>
                <button className="btn_close" onClick={this.closeModal}>
                  {' '}
                  x{' '}
                </button>
              </div>

              <div className="modal_body form-horizontal">
                <div className="mb-3">
                  <label>
                    {`Are you sure, do you want to permanently delete this worker ?`}
                  </label>
                </div>

                <div className=" mb-3">
                  <div className="offset-4 col-sm-8">
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() => this.props.pollWorkerDelete(this.state.v_id, 's')}
                    >
                      {' '}
                      Delete{' '}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <div className="col-sm-8 printfright">
              <div className="title_right_sec">

                <div className="form-inline mr-3 mb-3">
                  <label className="mr-2">Filter By</label>
                  <select
                    className="form-control pull-right"
                    id="const_sele"
                    name="consti"
                    onChange={this.ConstituencyChanged}
                    value={this.state.selectedContituency}
                  >
                    <option value="">Select a Constituency</option>
                    {this.props.constituency_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.constituencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>


                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.PollingDivChanged}
                    value={this.state.selectedPollingDiv}
                  >
                    <option value="">Select Polling div</option>
                    {this.props.polling_div.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.pollingDivisionName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <select className="form-control pull-right"
                    onChange={this.streetChanged}
                    value={this.state.selectedStreet}
                  >
                    <option value="">Select Street</option>
                    {this.props.street_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.street_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group printHide">
                  <button class="btn_cust btn-primary"  onClick={() => window.print()}>Download and Print Report</button>
                </div>


              </div>
            </div>

          </div>


          <div className="row mt-5 px-4">

            <div className="col-sm-12 mb-4 printHide">
              <div className="shadow_box custom_datatable">
                <Datatable
                  tableHeader={header}
                  tableBody={this.state.survey_workerCMg}
                  keyName="userTable"
                  tableClass="striped hover responsive"
                  rowsPerPage={5}
                  rowsPerPageOption={[5, 10, 15, 20]}
                  initialSort={{ prop: "username", isAscending: true }}
                  onSort={onSortFunction}
                  labels={customLabels}
                />
              </div>
            </div>
            

            <div className="printTable"> 
          <table border="1" cellPadding="10">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Name</th>
                <th>Active Since</th>
                <th>Last Active</th>
                <th>Constituency</th>
                <th>Polling Division</th>
                <th>Total Survey Conducted</th>
                <th>Total Areas of Concern Expressed</th>
              </tr>
            </thead>
            <thead>

              {this.state.survey_workerCMg.map((data, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{data.name}</td>
                      <td>{data.createdAt}</td>
                      <td>{data.updatedAt} </td>
                      <td>{data.constituencyID.constituencyName}</td>
                      <td>{data.pollingDivisionID.pollingDivisionName}</td>
                  <td>{data.totalSurvey}</td>
                      <td>{data.totalTakingPoints}</td>
                    </tr>
                  )
                })
              }
            </thead>
          </table>
        </div>


          </div>


        </div>


      </div>
    )
  }
}

const mapStateToProps = ({ authUser, campTeam, survey }) => {
  const { user, isAuthenticated } = authUser;
  const { loading, constituency_list, polling_div, team_list, poll_workerCMg, survey_workerCMg } = campTeam;
  const { street_list } = survey;

  return { user, loading, isAuthenticated, constituency_list, polling_div, team_list, poll_workerCMg, survey_workerCMg, street_list }
}
export default connect(mapStateToProps, actions)(SurveyWorker);
