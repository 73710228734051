import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import { connect } from 'react-redux';
import * as actions from '../actions';
import { NotificationContainer } from 'react-notifications';


import Map from '../Components/WinningMarginMap';
import PieChart from '../Components/PieChart3';
import Serial from '../Components/Serial3';


class WinnersandVictoryMargin extends Component {
  constructor(props) {
    super(props);
    this.childRef = null
    this.state = {
      tofuIsReady: false,
      role: '1',
      poll_workerCMg: [],
      selectedContituency: "",
      selectedPollingDiv: "",
      selectedStreet: "",
      v_id: "",
      constituency_wise_winning_margin_data: [],
      selectedYearValue: "2015"
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(id) {
    this.setState({
      modalIsOpen: true,
      v_id: id
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    //Get Polling Division Name
    if (nextProps.poll_workerCMg !== prevState.poll_workerCMg) {
      return { poll_workerCMg: nextProps.poll_workerCMg };
    }
    if (nextProps.constituency_wise_winning_margin_data !== prevState.constituency_wise_winning_margin_data) {
      return { constituency_wise_winning_margin_data: nextProps.constituency_wise_winning_margin_data };
    }
    else return null;
  }

  componentDidMount() {
    this.props.listConsituency();

    this.props.getPWByManager()
    this.props.winningMarginContituencyWise(this.state.selectedYearValue)
    //this.props.winningMarginPollingDivisionWise()
  }

  componentWillMount() {

    this.setState({
      role: localStorage.getItem('role')
    })
    console.log(localStorage.getItem('role'))

  }


  //On constituency Changed
  ConstituencyChanged = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    if (e.target.value == '') {
      this.setState({ selectedContituency: e.target.value, selectedStreet: '' })
    } else {
      this.setState({ selectedContituency: e.target.value })
    }
    console.log(this.state.selectedPartyValue)

    if (e.target.value == '') {
      this.props.winningMarginContituencyWise(this.state.selectedYearValue)

    } else {
      this.props.winningMarginPollingDivisionWise(e.target.value, this.state.selectedYearValue)

    }
    debugger

    // this.props.getTop10PDPartyWise(e.target.value, this.state.selectedPartyValue, this.state.selectedYearValue)

  };


  PollingDivChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedPollingDiv: e.target.value, selectedStreet: '' })
      this.props.getSteet();
      qryString = '?constituencyID=' + this.state.selectedContituency;
      this.props.getPWByManager(qryString);


    } else {

      this.setState({ selectedPollingDiv: e.target.value })
      this.props.getSteet(e.target.value);
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + e.target.value;
      this.props.getPWByManager(qryString);
    }
  };
  //On street Changed
  streetChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv;

      this.props.getPWByManager(qryString);

    } else {

      this.setState({ selectedStreet: e.target.value })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv + '&streetID=' + e.target.value;

      this.props.getPWByManager(qryString);

    }
  }


  //Selected Year Change Here
  selectedYearHere = e => {
    var qryString = "";
    console.log('Year Selected');
    console.log(e.target.value);
    this.setState({
      selectedYearValue: e.target.value
    })

    if (this.state.selectedContituency == '') {
      this.props.winningMarginContituencyWise(e.target.value)
    } else {
      this.props.winningMarginPollingDivisionWise(this.state.selectedContituency, e.target.value)
    }
  };



  render() {

    console.log('+++++++++')
    console.log(this.state.constituency_wise_winning_margin_data)
    console.log('+++++++++')

    const genderChartData = [{
      "data": "Male",
      "value": 25,
      "color": '#4ca9f8',
    }, {
      "data": "Female",
      "value": 36,
      "color": '#fb5858',
    }, {
      "data": "Others",
      "value": 50,
      "color": '#ffff00',
    }]

    const barDataValue = this.state.constituency_wise_winning_margin_data[2]
    const pieDataValue = this.state.constituency_wise_winning_margin_data[1]
    const mapDisplayValue = this.state.constituency_wise_winning_margin_data[3]

    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <NotificationContainer />
            <div className="col-sm-5 printfleft">
              <h1 className="text-primary ">Winners and Victory Margin Constituency and PD Wise</h1>
            </div>

            <div className="col-sm-7 printfright">
              <div className="title_right_sec">

                <div className="mr-3 mb-3">
                  <select
                    className="form-control pull-right"
                    id="const_sele"
                    name="consti"
                    onChange={this.ConstituencyChanged}
                    value={this.state.selectedContituency}
                  >
                    <option value="">Select a Constituency</option>
                    {this.props.constituency_list.filter(
                      data =>
                        data.constituencyName == 'TUNAPUNA' ||
                        data.constituencyName == 'MAYARO' ||
                        data.constituencyName == 'BARATARIA/SAN JUAN' ||
                        data.constituencyName == 'POINTE-A-PIERRE' ||
                        data.constituencyName == 'ST.JOSEPH' ||
                        data.constituencyName == 'CHAGUANAS EAST' ||
                        data.constituencyName == 'LA HORQUETTA/TALPARO' ||
                        data.constituencyName == 'SAN FERNANDO WEST' ||
                        data.constituencyName == 'CUMUTO/MANZANILLA' ||
                        data.constituencyName == 'FYZABAD' ||
                        data.constituencyName == 'TOBAGO EAST' ||
                        data.constituencyName == 'TOBAGO WEST' ||
                        data.constituencyName == 'MORUGA/TABLELAND' 
                    )
                      .map(data => {
                        return (
                          <option value={data._id}>
                            {data.constituencyName}
                          </option>
                        )
                      })}

                  </select>
                </div>

                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.selectedYearHere}
                    value={this.state.selectedYearValue}
                  >
                    <option value="2002">2002</option>
                    <option value="2007">2007</option>
                    <option value="2010">2010</option>
                    <option value="2015">2015</option>
                    <option value="2020">2020</option>
                  </select>
                </div>

                <div className="form-group printHide">
                  <button class="btn_cust btn-primary" onClick={() => window.print()}>Download and print Report</button>
                </div>
              </div>
            </div>

          </div>


          <div className="row mt-3 px-4 printmt">
            <div className="col-sm-12">
              <h4 className="text-center mb-3">Trinidad and Tobago election {this.state.selectedYearValue} </h4>
              <h5 className="text-center mb-5 printmb" style={{ fontWeight: 'normal' }}>Winners and Victory Margin Constituency and PD Wise</h5>
            </div>

            <div className="col-sm-6 mb-4 mt-3">
              <div className="shadow_box custom_datatable winner_table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th>Constituency or Polling Division</th>
                      <th>Winner</th>
                      <th>Runner Up</th>
                      <th>Victory Margin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.constituency_wise_winning_margin_data.length != 0 ?

                      this.state.constituency_wise_winning_margin_data[0].listDatat.length != 0 ?

                        this.state.constituency_wise_winning_margin_data[0].listDatat.map((data, index) => {
                          return (
                            <tr style={{ backgroundColor: data[0].partyColor }}>
                              <td style={{ color: data[0].partyName == 'cop' ? 'white' : 'black' }}>{index + 1}</td>
                              <td style={{ color: data[0].partyName == 'cop' ? 'white' : 'black' }}>{data[0].constituency}</td>
                              <td style={{ color: data[0].partyName == 'cop' ? 'white' : 'black' }}>{data[0].partyName.toUpperCase()}</td>
                              <td style={{ color: data[0].partyName == 'cop' ? 'white' : 'black' }}>{data[1].partyName.toUpperCase()}</td>
                              <td style={{ color: data[0].partyName == 'cop' ? 'white' : 'black' }}>{data[0].totalVotes - data[1].totalVotes}</td>
                            </tr>
                          )

                        })

                        :

                        <h6>No Data Available</h6>

                      :

                      <h6>No Data Available</h6>

                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mapshadow_box">
                <div className="map_cont survey_map">

                  {this.state.constituency_wise_winning_margin_data.length != 0 ?
                    <Map
                      blockMapImage={this.state.selectedContituency}
                      mapValueDisplay={mapDisplayValue.mapDisplayData}
                      ref={ref => this.childRef = ref} newValue={this.state.searchString} />
                    :
                    <h6>Waiting for data</h6>
                  }
                </div>
              </div>

              {this.state.constituency_wise_winning_margin_data.length != 0 ?
                <PieChart
                  dataValue={genderChartData}
                  pnm={pieDataValue.pieChartData[0].totalCount}
                  unc={pieDataValue.pieChartData[1].totalCount}
                  cop={pieDataValue.pieChartData[2].totalCount}
                  thc={pieDataValue.pieChartData[3].totalCount}
                  tnv={pieDataValue.pieChartData[4].totalCount}
                  star={pieDataValue.pieChartData[5].totalCount}
                  ind={pieDataValue.pieChartData[6].totalCount}
                  idp={pieDataValue.pieChartData[7].totalCount}
                  ncp={pieDataValue.pieChartData[8].totalCount}
                  nnv={pieDataValue.pieChartData[9].totalCount}
                  ilp={pieDataValue.pieChartData[10].totalCount}
                  msj={pieDataValue.pieChartData[11].totalCount}
                  others={pieDataValue.pieChartData[12].totalCount}
                  notVoted={pieDataValue.pieChartData[0].voteCastCount - pieDataValue.pieChartData[0].totalCount - pieDataValue.pieChartData[1].totalCount
                    - pieDataValue.pieChartData[2].totalCount - pieDataValue.pieChartData[3].totalCount - pieDataValue.pieChartData[4].totalCount -
                    pieDataValue.pieChartData[5].totalCount - pieDataValue.pieChartData[6].totalCount - pieDataValue.pieChartData[7].totalCount -
                    pieDataValue.pieChartData[8].totalCount - pieDataValue.pieChartData[9].totalCount - pieDataValue.pieChartData[10].totalCount
                    - pieDataValue.pieChartData[11].totalCount - pieDataValue.pieChartData[12].totalCount
                  }
                />

                :
                <h6>Waiting for data</h6>
              }

              {this.state.constituency_wise_winning_margin_data.length != 0 ?
                <Serial
                  pnm={barDataValue.barChartData[0].totalWin}
                  unc={barDataValue.barChartData[1].totalWin}
                  others={barDataValue.barChartData[2].totalWin + barDataValue.barChartData[3].totalWin
                    + barDataValue.barChartData[4].totalWin + barDataValue.barChartData[5].totalWin + barDataValue.barChartData[6].totalWin
                    + barDataValue.barChartData[7].totalWin + barDataValue.barChartData[8].totalWin + barDataValue.barChartData[9].totalWin
                    + barDataValue.barChartData[10].totalWin + barDataValue.barChartData[11].totalWin + barDataValue.barChartData[12].totalWin
                  }
                />
                :
                <h6>Waiting for data</h6>
              }
            </div>


          </div>


        </div>


      </div>
    )
  }
}

const mapStateToProps = ({ authUser, campTeam, survey }) => {
  const { user, isAuthenticated } = authUser;
  const { loading, constituency_list, polling_div, poll_workerCMg } = campTeam;
  const { street_list, constituency_wise_winning_margin_data } = survey;

  return { user, loading, isAuthenticated, constituency_list, polling_div, street_list, constituency_wise_winning_margin_data, poll_workerCMg }
}
export default connect(mapStateToProps, actions)(WinnersandVictoryMargin);

