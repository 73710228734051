import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import { connect } from 'react-redux';
import * as actions from '../actions';
import { NotificationContainer } from 'react-notifications';



class CampaignWorkerDetail extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4">
            <NotificationContainer />
            <div className="col-sm-12">
              <h1 className="text-primary ">Coming Soon ...</h1>
            </div>


          </div>



        </div>


      </div>
    )
  }
}

export default CampaignWorkerDetail;

