/*
 This Action for add survey and add suvey questions.
*/
import jwt_decode from "jwt-decode";
import { NotificationManager } from 'react-notifications';
import {
  LIST_VOTER,
  STREET_VOTER,
  HOUSE_VOTER,
  API_URl,
  GET_REQUEST,
  VOTER_COUNT_DATA

} from './types';

// api
import api from '../api';
import axios from 'axios';



//listVoters
export const getVoters = (VotersFilters) => {

  //debugger
  return async (dispatch) => {
    dispatch({ type: GET_REQUEST });
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      let voterList = await axios.post(API_URl + "admins/getVoter", VotersFilters, header, { timeout: 15000 });

      if (voterList.status === 200) {
        if (VotersFilters.houses == 1) {
          console.log(voterList.data)
          dispatch({ type: HOUSE_VOTER, payload: voterList.data });
          dispatch(getVoters_count(VotersFilters))
        }
        else if (VotersFilters.streets == 1) {
          console.log(voterList.data)
          dispatch({ type: STREET_VOTER, payload: voterList.data });
          dispatch(getVoters_count(VotersFilters))
        }
        else {
          console.log(voterList.data)
          dispatch({ type: LIST_VOTER, payload: voterList.data });
          dispatch(getVoters_count(VotersFilters))
        }

      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {

      if (VotersFilters.houses == 1) {
        dispatch({ type: HOUSE_VOTER, payload: { data: [] } });
      }
      else if (VotersFilters.streets == 1) {
        dispatch({ type: STREET_VOTER, payload: { data: [] } });
      }
      else {
        dispatch({ type: LIST_VOTER, payload: { data: [] } });
      }
      NotificationManager.error("Unable to Fetch !");
    }
  }
}




//listVoters_count
export const getVoters_count = (VotersFilters) => {

  return async (dispatch) => {
    //dispatch({ type: GET_REQUEST });
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      let voterList = await axios.post(API_URl + "admins/getVoterCount", VotersFilters, header);

      if (voterList.status === 200) {
        console.log(voterList.data.data)
        dispatch({ type: VOTER_COUNT_DATA, payload: voterList.data.data })
        // if (VotersFilters.houses == 1) {
        //   dispatch({ type: HOUSE_VOTER, payload: voterList.data });
        // }
        // else if (VotersFilters.streets == 1) {
        //   dispatch({ type: STREET_VOTER, payload: voterList.data });
        // }
        // else {
        //   dispatch({ type: LIST_VOTER, payload: voterList.data });
        // }

      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {

      // if (VotersFilters.houses == 1) {
      //   dispatch({ type: HOUSE_VOTER, payload: { data: [] } });
      // }
      // else if (VotersFilters.streets == 1) {
      //   dispatch({ type: STREET_VOTER, payload: { data: [] } });
      // }
      // else {
      //   dispatch({ type: LIST_VOTER, payload: { data: [] } });
      // }
      NotificationManager.error("Unable to Fetch !");
    }
  }
}





//listVoters
export const updateVoter = (VoterData) => {


  return async (dispatch) => {
    dispatch({ type: GET_REQUEST });
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      let voterList = await axios.put(API_URl + "campaignmanagers/updateContact", VoterData, header);

      // debugger
      if (voterList.status === 200) {
        const getAllVoters = {
          constituencyID: "",
          pollingDivisionID: "",
          dataForYear: "",
          newVotersInThisYear: "",
          gender: "",
          descreased: "",
          partyName: "",
          employement: "",
          mobile: "",
          gpsLocation: "",
          vehicleBooking: false,
          houses: 0,
          streets: 0,
          selected_tab: 0
        }
        dispatch(getVoters(getAllVoters));

      }
      else {
        NotificationManager.error("Unable to Update !");
      }
    } catch (error) {
      NotificationManager.error("Unable to Update !");
    }
  }
}
