import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { NotificationContainer, NotificationManager } from 'react-notifications';
// redux action
import * as actions from '../actions';
import { connect } from 'react-redux';

class TalkingView extends Component{
    constructor(props) {
        super(props);
     
        this.state = { 
            tofuIsReady:false,
        };
      }

      componentDidMount()
        {
          this.props.getMajorConcern();  
        }
 
  
  

 render(){     
console.log('Get Major Concern');
console.log(this.props.major_concern);
console.log('**************')
const carCreation = () => {

    if(this.props.major_concern.length>0)
    {
       let heading="";
        return this.props.major_concern.map((concerData)=>{
           
            switch(concerData._id)
           {
            case '0':
            heading='None';
            break;
            case '1':
            heading='Employment (New Employment Opportunities,Job Securities)';
            break;
            case '2':
            heading='Education For Self';
            break;
            case '3':
            heading='Education For Children';
            break;
            case '4':
            heading='Water';
            break;
            case '5':
            heading='Fixing Roads';
            break;
            case '6':
            heading='Crime';
            break;
            case '7':
            heading='Flooding/Drainage';
            break;
            case '8':
            heading='Public Transport';
            break;
            case '9':
            heading='Environment';
            break;
            case '10':
            heading='Other';
            break;
            default:
            heading='Other';
            break;
           }
           return(<div className="shadow_box mb-5 p-0">
            <div className="card">
                <div className="card-header font-weight-bold">{heading}</div>
                <ul className="list-group list-group-flush listContainer">
                    {concerData.data.length>0 && concerData.data.map((listData)=>{
                        return(<li className="list-group-item">
                        {listData.descritption}
                        </li> )  
                    })}
                    
               
                </ul>
            </div>
        </div>)
        
        }) 
    }
}
    return (
      <div>

        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
                      <h1 className="text-primary">Right area of concerns</h1>
                  </div>
            </div>   

             <div className="row mt-5 px-4">

                <div className="col-sm-10">
                    
                    {carCreation()}
                         
                </div>

            </div>


        </div>



      </div>
    )
  }
}
const mapStateToProps = ({ payment }) => {

    const { major_concern } = payment;
    return { major_concern }
}
export default connect(mapStateToProps, actions)(TalkingView);
