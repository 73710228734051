import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import moment from 'moment'
import Datatable from 'react-bs-datatable';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// redux action
import * as actions from '../actions';
import { connect } from 'react-redux';



class ConstituencyListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
    };
  }
  //Call Api for consituency List 
  componentDidMount() {
    this.props.listConsituency();

  }
  render() {

    //console.log(this.props.constituency_list);
    var body = [];
    if (this.props.constituency_list.length > 0) {
      this.props.constituency_list.map((data, index) => {
        body.push({
          sn: index + 1,
          name: data.constituencyName,
          action: <Toggle
            defaultChecked={this.state.tofuIsReady}
            icons={false}
            onChange={this.handleTofuChange} />,
        })

      })

      //console.log(body);
    }

    const header = [
      { title: 'S.No.', prop: 'sn', sortable: true, filterable: true },
      { title: 'Name', prop: 'name', sortable: true, filterable: true },
      { title: 'Action', prop: 'action', },
    ];



    const onSortFunction = {
      date(columnValue) {
        // Convert the string date format to UTC timestamp
        // So the table could sort it by number instead of by string
        return moment(columnValue, 'Do MMMM YYYY').valueOf();
      },
    };

    const customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };



    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4">
            <div className="col-sm-6">
              <h1 className="text-primary ">Constituency Listing</h1>
            </div>

              <div className="col-sm-6">
                <div className="title_right_sec">
                    <div className="form-group">
                      <button class="btn_cust btn-primary"  onClick={() => window.print()}>Download and Print Report</button>
                    </div>
                </div>
              </div>
          </div>


          <div className="row mt-5 px-4">

            <div className="col-sm-12 mb-4 printHide">
              <div className="shadow_box custom_datatable">
                <Datatable
                  tableHeader={header}
                  tableBody={body}
                  keyName="userTable"
                  tableClass="striped hover responsive"
                  rowsPerPage={10}
                  rowsPerPageOption={[10, 20, 30, 40]}
                  initialSort={{ prop: "constituencyName", isAscending: true }}
                  onSort={onSortFunction}
                  labels={customLabels}
                />
              </div>
            </div>

            
            <div className="printTable"> 
          <h1 style={{ marginBottom: 25 }}>Constituency Listing</h1>
          <table border="1" cellPadding="10">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Name</th>
              </tr>
            </thead>
            <thead>

              {this.props.constituency_list.map((data, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{data.constituencyName}</td>
                    </tr>
                  )
                })
              }
            </thead>
          </table>
        </div>



          </div>


        </div>


      </div>
    )
  }
}

const mapStateToProps = ({ campTeam }) => {

  const { constituency_list } = campTeam;

  return { constituency_list }
}
export default connect(mapStateToProps, actions)(ConstituencyListing);

