/*
 src/reducers/ActiveUserReducer.js
*/
/**
 * Auth User Reducers
 */
import isEmpty from '../validation/is-empty';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SET_CURRENT_USER,
  ELECTION_RESULT,
  PROFILE_INFO,
  SET_UPDATED_YEAR_VALUE,
  SET_MARGINAL_COLOR,
  TRENDS_RESULTS,
  SET_SEARCH_STRING_VALUE,
  TRENDS_RESULTS_1,
  DASHBOARD_DATA_RESULTS
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
  user: {},
  loading: true,
  account: null,
  isAuthenticated: false,
  election_result: [],
  candiateResultList: [],
  profile_info: {},
  updatedYearValue: '2015',
  marginalColorValue: '',
  trendsResults: [],
  trendsResults_1: [],
  dashboardDataResults: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      console.log('reducer Called');
      var isAuthenticated = !isEmpty(action.payload);
      console.log(isAuthenticated);
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };

    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };

    case LOGIN_USER_FAILURE:
      return { ...state, loading: false };

    case LOGOUT_USER:
      return { ...state, user: null };

    case PROFILE_INFO:
      return { ...state, profile_info: action.payload };

    case SET_UPDATED_YEAR_VALUE:
      return { ...state, updatedYearValue: action.payload }

    case SET_MARGINAL_COLOR:
      return { ...state, marginalColorValue: action.payload }

    case TRENDS_RESULTS_1:
      return { ...state, trendsResults_1: action.payload }

    case TRENDS_RESULTS:
      return { ...state, trendsResults: action.payload }

    case DASHBOARD_DATA_RESULTS:
      return { ...state, dashboardDataResults: action.payload }

    case SET_SEARCH_STRING_VALUE:
      return { ...state, trendsResults: action.payload }

    case ELECTION_RESULT:

      let vk = [];
      vk.push({ party_cop: action.payload });
      console.log('Reducer Value');
      console.log(vk);
      return {
        ...state,
        election_result: action.payload,
        candiateResultList: vk
      };

    case SIGNUP_USER:
    default:
      return { ...state };
  }
};
