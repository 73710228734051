import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { NotificationContainer } from 'react-notifications';

import { connect } from 'react-redux';
import * as actions from '../../actions';

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import moment from 'moment'
import Datatable from 'react-bs-datatable';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};



class PollWorker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
      modalIsOpen: false,
      role: '1',
      polling_div: [],
      pollworker_name: '',
      pollworker_email: '',
      pollworker_mobile: '',
      pollworker_office: '',
      pollworker_homeAddress: '',
      pollworker_officeAddress: '',
      pollworker_pollingDivision: [],
      poll_workerCMg: [],
      street_list: [],
      pollworker_street: "",

      errors: {}
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    let pollingDivisionID = await localStorage.getItem('pollingDivisionID');
    this.props.getSteet(pollingDivisionID)

    this.props.pollWorkerCManager()
  }

  componentWillMount() {

    this.setState({
      role: localStorage.getItem('role')
    })
    console.log(localStorage.getItem('role'))

  }

  static getDerivedStateFromProps(nextProps, prevState) {

    //Get Street List
    if (nextProps.street_list !== prevState.street_list) {
      return { street_list: nextProps.street_list };
    }
    if (nextProps.poll_workerCMg !== prevState.poll_workerCMg) {
      return { poll_workerCMg: nextProps.poll_workerCMg };
    }
    else return null;
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }


  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value, errors: {} })

  //pollingDivChanged
  pollingDivChanged = (e) => {

    let selected = [...this.refs.collegeList.selectedOptions].map(o => o.value)
    this.setState({ pollworker_pollingDivision: selected, errors: {} });

  };

  handleValidation() {

    let errors = {};
    let formIsValid = true;
    const {
      pollworker_name,
      pollworker_email,
      pollworker_mobile,
      pollworker_office,
      pollworker_homeAddress,
      pollworker_officeAddress,
      pollworker_street
    } = this.state;
    //Name
    if (!pollworker_name) {
      formIsValid = false;
      errors["pollworker_name"] = "Name cannot be empty";
    }

    if (!pollworker_email) {
      formIsValid = false;
      errors["pollworker_email"] = "Email cannot be empty";
    }

    if (!pollworker_mobile) {
      formIsValid = false;
      errors["pollworker_mobile"] = "Mobile number cannot be empty";
    }

    if (!pollworker_homeAddress) {
      formIsValid = false;
      errors["pollworker_homeAddress"] = "Home address cannot be empty";
    }

    if (pollworker_street == "") {
      formIsValid = false;
      errors["pollworker_street"] = "please select street";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  _addPollworker = async () => {

    const {
      pollworker_name,
      pollworker_email,
      pollworker_mobile,
      pollworker_office,
      pollworker_homeAddress,
      pollworker_officeAddress,
      pollworker_pollingDivision,
      pollworker_street
    } = this.state
    if (this.handleValidation()) {
      const addPollWorker = {
        name: pollworker_name,
        email: pollworker_email,
        mobile_no: pollworker_mobile,
        office_no: pollworker_office,
        home_address: pollworker_homeAddress,
        office_address: pollworker_officeAddress,
        pollingDivisionID: await localStorage.getItem('pollingDivisionID'),
        constituencyID: await localStorage.getItem('constituencyIDAdmin'),
        streetID: pollworker_street,

        role: "3"
      }
      console.log(addPollWorker)
      // debugger
      this.props.addPollworker(addPollWorker, await localStorage.getItem('constituencyIDAdmin'))
      this.setState({ modalIsOpen: false });
    }

  }
  streetChanged = (e) => {

    let selected = [...this.refs.streetList.selectedOptions].map(o => o.value)
    console.log(`---> ${selected[0]}`)
    this.setState({ pollworker_street: selected, errors: {} });

  };
  render() {


    const header = [
      { title: 'Name', prop: 'name', sortable: true },
      {
        title: 'Active Since',
        prop: 'createdAt',
        cell: row => moment(row.createdAt).format('Do MMMM YYYY'),
        sortable: true
      },
      {
        title: 'Last Active',
        prop: 'updatedAt',
        cell: row => moment(row.updatedAt).format('Do MMMM YYYY'),
        sortable: true
      },
      {
        title: 'Constituency',
        prop: 'constituency',
        cell: row => row.constituencyID ? row.constituencyID.constituencyName : null,
        sortable: true
      },
      { title: 'Total Data To Verify', prop: 'totalDataToVerify', sortable: true },
      { title: 'Total Verified', prop: 'totalSurvey', sortable: true },
      {
        title: 'Block',
        prop: 'is_active',
        cell: row => <Toggle
          defaultChecked={row.is_active}
          onChange={() => this.props.pollWorkerStatusUpdate(row._id, row.is_active, 'p')} />
      },
    ];

    const body = [
      {
        sn: '1',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        constituency: 'constituency name',
        dataverify: '52',
        verified: '22',
        action: <div style={{ width: '110px' }}><Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false} className="pull-left mr-3"
          onChange={this.handleTofuChange} /> <Link to="/poll-worker-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
      },
      {
        sn: '1',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        constituency: 'constituency name',
        dataverify: '52',
        verified: '22',
        action: <div style={{ width: '110px' }}><Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false} className="pull-left mr-3"
          onChange={this.handleTofuChange} /> <Link to="/poll-worker-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
      },
      {
        sn: '1',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        constituency: 'constituency name',
        dataverify: '52',
        verified: '22',
        action: <div style={{ width: '110px' }}><Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false} className="pull-left mr-3"
          onChange={this.handleTofuChange} /> <Link to="/poll-worker-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
      },
      {
        sn: '1',
        name: 'Pushpendra',
        activesince: '10 August, 2019',
        lastactive: '25 September, 2019',
        constituency: 'constituency name',
        dataverify: '52',
        verified: '22',
        action: <div style={{ width: '110px' }}><Toggle
          defaultChecked={this.state.tofuIsReady}
          icons={false} className="pull-left mr-3"
          onChange={this.handleTofuChange} /> <Link to="/poll-worker-view" className="btn_cust btn-primary"><i className="fa fa-eye"></i></Link></div>,
      },

    ];

    const onSortFunction = {
      date(columnValue) {
        return moment(columnValue, 'Do MMMM YYYY').valueOf();
      },
    };

    const customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };



    return (

      <div>
        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <div className="col-sm-6 printfleft">
              <h1 className="text-primary ">Poll Worker</h1>
            </div>

            <NotificationContainer />

            <div className="col-sm-6 printfright">
              <div className="title_right_sec">
                <button className="btn_cust btn-primary pull-left mt-2 mr-3" onClick={this.openModal}>Add Poll Worker</button>
                <button class="btn_cust btn-primary pull-right mt-2 printHide"  onClick={() => window.print()}>Download and Print Report</button>
              </div>
            </div>

          </div>


          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal_head">
              <h1 className="text-primary ">Poll Worker</h1>
              <button className="btn_close" onClick={this.closeModal}>x</button>
            </div>

            <div className="modal_body form-horizontal">

              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">Name*:</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" name="pollworker_name" id="name" placeholder="Enter name" onChange={this.onChange} />
                  <span className="error">{this.state.errors["pollworker_name"]}</span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="email">Email*:</label>
                <div className="col-sm-8">
                  <input type="email" className="form-control" name="pollworker_email" id="email" placeholder="Enter your Email" onChange={this.onChange} />
                  <span className="error">{this.state.errors["pollworker_email"]}</span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="mobile">Mobile No*:</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" name="pollworker_mobile" id="mobile" placeholder="Enter mobile no" onChange={this.onChange} />
                  <span className="error">{this.state.errors["pollworker_mobile"]}</span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="Office">Office No:</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" name="pollworker_office" id="Office" placeholder="Enter Office no" onChange={this.onChange} />
                  <span className="error">{this.state.errors["pollworker_office"]}</span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="Home">Home Address*:</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" name="pollworker_homeAddress" id="Home" placeholder="Enter Home Address" onChange={this.onChange} />
                  <span className="error">{this.state.errors["pollworker_homeAddress"]}</span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="Address">Office Address:</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control" name="pollworker_officeAddress" id="Address" placeholder="Enter Office Address" onChange={this.onChange} />
                  <span className="error">{this.state.errors["pollworker_officeAddress"]}</span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="control-label col-sm-4 pr-0" htmlFor="name">Streets*:</label>
                <div className="col-sm-8">
                  <select className="form-control" ref="streetList" onChange={this.streetChanged}>
                    <option value="">Select a street</option>
                    {
                      this.state.street_list.map((data, index) => {
                        return (<option value={data._id}>{data.street_name}</option>)
                      })
                    }
                  </select>
                  <span className="error">{this.state.errors["pollworker_street"]}</span>
                </div>
              </div>


              <div className="row mb-3">
                <div className="offset-4 col-sm-8">
                  <button className="btn btn-sm btn-success" onClick={this._addPollworker}>Add</button>
                </div>
              </div>

            </div>

          </Modal>


          <div className="row mt-5 px-4">

            <div className="col-sm-12 mb-4">
              <div className="shadow_box custom_datatable">
                <Datatable
                  tableHeader={header}
                  tableBody={this.state.poll_workerCMg}
                  keyName="userTable"
                  tableClass="striped hover responsive"
                  rowsPerPage={5}
                  rowsPerPageOption={[5, 10, 15, 20]}
                  initialSort={{ prop: "username", isAscending: true }}
                  onSort={onSortFunction}
                  labels={customLabels}
                />
              </div>
            </div>


          </div>


        </div>


      </div>
    )
  }
}

const mapStateToProps = ({ authUser, campTeam, survey }) => {
  const { user, isAuthenticated } = authUser;
  const { loading, constituency_list, polling_div, team_list, poll_workerCMg } = campTeam;
  const { street_list } = survey


  return { user, loading, isAuthenticated, constituency_list, polling_div, team_list, poll_workerCMg, street_list }
}
export default connect(mapStateToProps, actions)(PollWorker);
