import React, { Component } from 'react';
import {Link} from 'react-router-dom';


class LiveNews extends Component {
constructor(props){
    super(props);
  }


render() {
    return (
        <div className="row">
            <div className="col-sm-3 p-0">
                <div className="foot_left">
                    <p className="mb-0 text-center"><i className="fa fa-newspaper-o"></i> News Ticker</p>
                </div>
            </div>
            
            <div className="col-sm-9 p-0">
                <div className="foot_right">
                    <p className="mb-0">Contrary to popular belief, Lorem Ipsum is not.</p>
                </div>
            </div>
        </div>   
    );
  }
}
export default LiveNews;
