/*
 src/actions/ActiveUserAction.js
*/
import jwt_decode from 'jwt-decode';
import { NotificationManager } from 'react-notifications';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  //New Key Added
  SET_CURRENT_USER,
  ELECTION_RESULT,
  API_URl,
  PROFILE_INFO,
  SET_UPDATED_YEAR_VALUE,
  SET_MARGINAL_COLOR,
  TRENDS_RESULTS,
  SET_SEARCH_STRING_VALUE,
  TRENDS_RESULTS_1,
  DASHBOARD_DATA_RESULTS
} from './types';
import setAuthToken from '../api/setAuthToken';
// api
import api from '../api';
import axios from 'axios';


/**Login User */
export const signinUserIn = (data, history) => {
  return async dispatch => {
    try {
      const user = await api.post('user/login', data);
      //debugger

      let dashboardData

      if (user.status === 200) {
        debugger
        console.log(user)
        // Save to localStorage
        const { token, role, name, email, _id } = user.data.data;
        await localStorage.setItem('jwtToken', token);
        console.log('User Data');
        console.log(name);
        console.log(email);
        await localStorage.setItem('user_name', email);
        await localStorage.setItem('name', name);
        await localStorage.setItem('userID', _id)
        if (role == '1') {
          await localStorage.setItem("CM_profile", user.data.data.avatar ? user.data.data.avatar : '');

        }

        if (role == '2') {

          await localStorage.setItem("SC_profile", user.data.data.avatar ? user.data.data.avatar : '');
          await localStorage.setItem('constituencyIDAdmin', user.data.data.constituencyID._id);
          await localStorage.setItem('pollingDivisionID', user.data.data.pollingDivisionID);
          await localStorage.setItem('streetID', user.data.data.streetID);
          await localStorage.setItem('pollingDivisionName', user.data.data.PollingDivisionName.pollingDivisionName);
          await localStorage.setItem('constituencyName', user.data.data.constituencyID.constituencyName);



          dashboardData = {
            "constituencyID": user.data.data.constituencyID._id,
            "pollingDivisionID": user.data.data.pollingDivisionID,
            "streetID": user.data.data.streetID

          }

        }

        dashboardData = {
          "constituencyID": "",
          "pollingDivisionID": "",
          "streetID": ""

        }
        let jwtToken = await localStorage.getItem('jwtToken');
        console.log('Jwt Token');
        console.log(jwtToken);
        //dispatch({ type: LOGIN_USER_SUCCESS, payload: accessToken });
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
        // dispatch(getDashboardData(dashboardData))

        history.push('/home');
        //history.push('/dashboard');
      } else {
        localStorage.removeItem('jwtToken');
        dispatch({ type: LOGIN_USER_FAILURE });
        NotificationManager.error(user.message);
      }
    } catch (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error('Your user and password invalid');
    }
  };
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};


// update the year value
export const updateYearValue = year => {
  return {
    type: SET_UPDATED_YEAR_VALUE,
    payload: year
  };
};

export const handleMarginalColor = color => {
  return {
    type: SET_MARGINAL_COLOR,
    payload: color
  }
}

export const searchStringValue = string => {
  return {
    type: SET_SEARCH_STRING_VALUE,
    payload: string
  }
}


// GET Google Trends Data

export const getGoogleTrendsData = (searchString, year) => {
  console.log(searchString)
  console.log(year)
  return async dispatch => {
    try {
      let header = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        }
      };
      //debugger
      const trendsResults = await api.get(
        `admins/getGoogleTrends?keyword=${searchString}&startTime=${year}-01-01&endTime=${year}-12-31`, header
      );
      if (trendsResults.status === 200) {
        console.log('*******************************');
        console.log(trendsResults.data.data);
        console.log(JSON.parse(trendsResults.data.data).default.geoMapData)
        console.log('*******************************');
        //debugger
        dispatch({
          type: TRENDS_RESULTS,
          payload: JSON.parse(trendsResults.data.data).default.geoMapData
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      //debugger;
      NotificationManager.error('Unable to Fetch !');
    }
  };
};




// GET ELECTION RESULTS DATA WITH FILTER

export const getElectionResultDataWithFilter = (year, id) => {

  // debugger
  console.log(year)
  console.log(id)

  return async dispatch => {
    try {
      let header = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        }
      };

      let electionResults
      if (id) {
        electionResults = await api.get(
          `admins/getElectionResultContstituencyWiseData?year=${year}&constituencyID=${id}`, header
        );
      } else {
        electionResults = await api.get(
          `admins/getElectionResultContstituencyWiseData?year=${year}`, header
        );
      }

      if (electionResults.status === 200) {
        console.log(electionResults)
        // debugger
        console.log('*******************************');
        console.log(electionResults.data.data);
        console.log('*******************************');
        dispatch({
          type: ELECTION_RESULT,
          payload: electionResults.data
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      //debugger;
      NotificationManager.error('Unable to Fetch !');
    }
  };
};

///Get User Profile
export const getUserProfile = () => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      const profileData = await axios.get(API_URl + 'campaignmanagers/getProfile', header
      );

      if (profileData.status === 200) {
        console.log(profileData.data.data[0])
        dispatch({
          type: PROFILE_INFO,
          payload: profileData.data.data[0]
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      NotificationManager.error('Unable to Fetch !');
    }
  };
};

///Get User Profile
export const updateProfile = (formData) => {
  return async dispatch => {
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'multipart/form-data'
        }
      };
      const profileData = await axios.post(API_URl + 'campaignmanagers/updateProfile', formData, header
      );

      if (profileData.status === 200) {
        console.log(profileData.data.data[0])
        dispatch(getUserProfile());
        NotificationManager.success('Updated Successfully !');
      } else {
        NotificationManager.error('Unable to Update !');
      }
    } catch (error) {
      NotificationManager.error('Unable to Update !');
    }
  };
};


// Forget Password

export const forgetPassword = (email, history) => {
  console.log(email)
  console.log(history)
  return async dispatch => {
    try {

      //debugger
      const forgetPasswordResult = await api.get(`user/forgetPassword?email=${email}`);
      if (forgetPasswordResult.status === 200) {
        console.log('*******************************');
        console.log(forgetPasswordResult.data.data);
        console.log('*******************************');
        await localStorage.setItem('userID', forgetPasswordResult.data.data._id)
        //debugger
        history.push('/resetPassword')

      } else {
        NotificationManager.error('Something went wrong');
      }
    } catch (error) {
      console.log(error)
      debugger;
      NotificationManager.error(error.response.data.message);
    }
  };
};



// Update Password

export const updatePassword = (data, history) => {
  console.log(data)
  console.log(history)
  return async dispatch => {
    try {

      //debugger
      const forgetPasswordResult = await api.put(`user/updatePassword`, data);
      if (forgetPasswordResult.status === 200) {
        console.log('*******************************');
        console.log(forgetPasswordResult);
        console.log('*******************************');
        await localStorage.setItem('userID', forgetPasswordResult.data.data._id)

        NotificationManager.success(forgetPasswordResult.data.message);
        setTimeout(() => history.push('/login'), 3000)

        //debugger
        //history.push('/resetPassword')

      } else {
        NotificationManager.error('Something went wrong');
      }
    } catch (error) {
      console.log(error.response)
      NotificationManager.error(error.response.data.message);

    }
  };
};





// GET Google Trends Data

export const getConclusionFromGoogleTrendsData = (searchString, year) => {
  console.log(searchString)
  console.log(year)
  return async dispatch => {
    try {
      let header = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        }
      };
      //debugger
      const trendsResults = await api.get(
        `admins/getGoogleTrends?keyword=${searchString}&startTime=${year}-01-01&endTime=${year}-12-31`, header
      );
      if (trendsResults.status === 200) {
        console.log('*******************************');
        console.log(trendsResults.data.data);
        console.log(JSON.parse(trendsResults.data.data).default.geoMapData)
        console.log('*******************************');
        //debugger
        dispatch({
          type: TRENDS_RESULTS_1,
          payload: JSON.parse(trendsResults.data.data).default.geoMapData
        });
        dispatch(getGoogleTrendsData('pnm', '2019'))
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      //debugger;
      NotificationManager.error('Unable to Fetch !');
    }
  };
};


// Dashboard

export const getDashboardData = (data) => {

  console.log(data)
  return async dispatch => {
    try {
      let header = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json'
        }
      };
      //debugger
      await api.post('campaignmanagers/getDashbaordData', data, header)
      const dashboardResult = await api.post('campaignmanagers/getDashbaordData', data, header)



      if (dashboardResult.status === 200) {

        console.log('*******************************');
        console.log(dashboardResult.data.data);
        console.log('*******************************');

        dispatch({
          type: DASHBOARD_DATA_RESULTS,
          payload: dashboardResult.data.data
        });
      } else {
        NotificationManager.error('Unable to Fetch !');
      }
    } catch (error) {
      var dashboardResult = {
        status: 1,
        message: 'Dashboard result',
        data: [
          {
            surveyAnalysis: [
              {
                _id: null,
                Attempts: 0,
                Voter: 0,
                VotersNotIntrested: 0,
                talkingPoint: 0,
                hitRate: 0
              }
            ],
            generalAnalysis: [
              {
                _id: null,
                streetCaptains: 0,
                surveyWorkers: 0,
                pollWorkers: 0,
                vehicleProvider: 0,
                streets: 0
              }
            ],
            HitRate: [
              {
                _id: null,
                Attempts: 0,
                Voter: 0,
                VotersNotIntrested: 0,
                talkingPoint: 0,
                hitRate: 0
              }
            ],
            Streets: [
            ],
            surveys: [
            ],
            employment: [
              {
                _id: null,
                Employed: 0,
                SelfEmployed: 0,
                Retired: 0,
                FullTimeStudent: 0,
                Unspecified: 0,
                total: 0
              }
            ],
            gender: [
              {
                _id: null,
                male: 0,
                female: 0,
                others: 0,
                total: 0
              }
            ],
            favouriteParty: [],
            descreased: [
              {
                _id: null,
                Alive: 0,
                Descreased: 0
              }
            ],
            mobile: [
              {
                mobileNotExists: 0,
                mobileExists: 0
              }
            ],
            age: []
          }
        ]
      }
      console.log(dashboardResult.data);
      dispatch({
        type: DASHBOARD_DATA_RESULTS,
        payload: dashboardResult.data
      });
      //debugger;
      NotificationManager.error('Unable to Fetch !');
    }
  };
};