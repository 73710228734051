
/**
 * Voter Reducers
 */
import isEmpty from '../validation/is-empty';
import {
  PAYMENT_REQ_DATA,
  PAYMENT_REQ_BY_SC,
  MAJOR_CONCERN,
  PAYMENT_DETAIL_CM,
  PAYMENT_RATE,
  GET_REQUEST_PAYMENT
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
  loading: false,
  payment_data: [],
  payment_data_sc: [],
  major_concern: [],
  grandTotal: 0,
  totaluser: 0,
  grandTotal_CM: 0,
  noOfStreetCaptains: 0,
  payment_detail: [],
  detail_amt: 0,
  perSurveyAmount: 0,
  pollWorkerPerHourSittingCost: 0,
  vehicleProviderPerKMCost: 0,
  managePaymentID: "",
  updatedAt: "",
  allvalues: '',
  allCocerns:[],
  review:[],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_REQUEST_PAYMENT:
    return {
        ...state,
        loading:true
      };

    case PAYMENT_REQ_DATA:
      return {
        ...state,
        payment_data: (action.payload.data) ? action.payload.data : [],
        grandTotal_CM: (action.payload.grandTotal) ? action.payload.grandTotal : 0,
        noOfStreetCaptains: (action.payload.noOfStreetCaptains) ? action.payload.noOfStreetCaptains : 0,

      };
    case PAYMENT_REQ_BY_SC:
      return {
        ...state,
        payment_data_sc: (action.payload.data) ? action.payload.data : [],
        grandTotal: (action.payload.grandTotal) ? action.payload.grandTotal : 0,
        totaluser: (action.payload.data) ? action.payload.data.length : 0,
      };

    case MAJOR_CONCERN:
    return {
        ...state,
        major_concern: (action.payload) ? action.payload : [],
        allCocerns:(action.payload.allCocerns) ? action.payload.allCocerns : [],
        review:(action.payload.review) ? action.payload.review : [],
        loading:false
      };

    case PAYMENT_DETAIL_CM:
      return {
        ...state,
        payment_detail: (action.payload.data) ? action.payload.data : [],
        detail_amt: (action.payload.totalAmount) ? action.payload.totalAmount : 0
      };

    case PAYMENT_RATE:
      console.log('################')
      console.log(action.payload)
      console.log('################')
      return {
        allvalues: action.payload,
        perSurveyAmount: (action.payload.perSurveyAmount) ? action.payload.perSurveyAmount : 0,
        pollWorkerPerHourSittingCost: (action.payload.pollWorkerPerHourSittingCost) ? action.payload.pollWorkerPerHourSittingCost : 0,
        vehicleProviderPerKMCost: (action.payload.vehicleProviderPerKMCost) ? action.payload.vehicleProviderPerKMCost : 0,
        managePaymentID: (action.payload._id) ? action.payload._id : "",
        paymentLastValuesUpdated: (action.payload.paymentLastValuesUpdated) ? action.payload.paymentLastValuesUpdated : "",
        updatedAt: (action.payload.updatedAt) ? action.payload.updatedAt : ""
      }

    default:
      return { ...state };
  }
};
