import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// redux action
import { forgetPassword } from './actions';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            username1: "click2joindaya@gmail.com",
            password: "",
            redirectToReferrer: false,
            role: "",
            emailError: "",
            passError: "",
            roleError: "",
            dashboard: "1"
        }
        //this.login = this.login.bind(this);
        //this.onChange = this.onChange.bind(this);
    }


    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, ()=>console.log(this.state.username));
    };

    
    validate = () => {
        let isError = false;
        
        var reg = /^\S+@\S+\.\S+$/;
        if (reg.test(this.state.username) === false) {
            isError = true;
            this.setState({ emailError: 'Requires valid email !' })

        }
        return isError;
    };

    onSubmit = e => {


        e.preventDefault();
        const err = this.validate();
        if (!err) {
            
            this.props.forgetPassword(this.state.username, this.props.history)
            //this.props.history.push('/home')
        }

    };

    handleChange = (e) => {
        e.preventDefault()
        console.log(e.target.value)
        this.setState({ role: e.target.value });
    }

    selectedDashboard = (e) => {
        this.setState({
            dashboard: e.target.value
        });
    }


    render() {

        return (
            // <div style={{background: 'url(img/rct-session-banner.jpg) center no-repeat', backgroundSize: 'cover', height:'100vh'}}>
            <div style={{ height: '100vh' }}>
                <section className="position-relative header_bg" style={{ margin: 0, }}>
                    <div className="container-fluid z-index position-relative">

                        <div className="col-md-6 col-lg-4 mt-5 ml-auto mr-auto">
                            <div className="login_logo text-center">
                                <Link className="logo" to="/">
                                    {/* <img src="img/logo.png" alt="" className="img-fluid logo-light" /> */}
                                </Link>
                            </div>

                            <div className="contact_form login_form">
                                <div className="home_content text-center mb-20">
                                    <h1>Forget Password</h1>
                                </div>
                                <form>
                                    <div className="row" id="login">
                                        

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input type="text" name="username" id="username" value={this.state.username} className="form-control" onChange={e => this.change(e)} placeholder="Enter registred email" />
                                                <label className="error">{this.state.emailError}</label>
                                            </div>
                                        </div>
                   
                                        <div className="col-lg-12 text-right mb-3">
                                            <Link className="oZoRPi" to="/login">Back To Login</Link>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-lg-12 text-center">
                                            {/* {this.refs.loginRole.value === "" ? "heii": ""}                                               */}
                                            <button className="btn btn-success w-100 btn_login" onClick={e => this.onSubmit(e)}><i className="fa fa-sign-in fa-fw" aria-hidden="true"></i>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <NotificationContainer />
                        </div>

                        

                    </div>
                </section>
            </div>
        );
    }
}
//export default Login;
const mapStateToProps = ({ authUser }) => {
    const { user, loading, isAuthenticated } = authUser;
    return { user, loading, isAuthenticated }
}
export default connect(mapStateToProps, {
    forgetPassword,
})(Login);