import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../actions';

const $ = window.$;

class Map extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            trendsResults: [],
            marginColor: 'orange',
            headerName: '',
            searchPercantile: '',
            searchString2: '',
            dsData: this.props.newValue
        };
      }

    componentDidUpdate(nextProps, prevState){
        console.log('Next Props');
        console.log(nextProps.trendsResults);
        console.log('Pre State');
        console.log(prevState.trendsResults);
      
          if (this.props.trendsResults !== prevState.trendsResults){
            this.setState({
                trendsResults: this.props.trendsResults
            })
          }
         
        }

        searchString = stringval => {
            console.log(`This has been set ----> ${stringval}`)
            this.setState({
                searchString2: stringval,
                dsData:stringval
            })
        }


    componentDidMount() {
        $("#cont17").click(function(){
            $("#map_lrg").hide();
            $(".sml_map").show();
            $("#map_ctry").show();
            $("#cont17State").show();
        });

        $("#dist1").click(function(){
            $("#cont17State").hide();
            $("#map_ctry").hide();
            $("#sml_state").show();
            $("#cont17Dist1").show();
        });

        $("#dist2").click(function(){
            $("#cont17State").hide();
            $(".map").hide();
            $("#map_ctry").hide();
            $("#sml_state").show();
            $("#cont17Dist2").show();
        });

        $("#dist3").click(function(){
            $("#cont17State").hide();
            $(".map").hide();
            $("#map_ctry").hide();
            $("#sml_state").show();
            $("#cont17Dist3").show();
        });

        $("#dist4").click(function(){
            $("#cont17State").hide();
            $(".map").hide();
            $("#map_ctry").hide();
            $("#sml_state").show();
            $("#cont17Dist4").show();
        });

        $("#dist5").click(function(){
            $("#cont17State").hide();
            $(".map").hide();
            $("#map_ctry").hide();
            $("#sml_state").show();
            $("#cont17Dist5").show();
        });

        $("#dist6").click(function(){
            $("#cont17State").hide();
            $(".map").hide();
            $("#map_ctry").hide();
            $("#sml_state").show();
            $("#cont17Dist6").show();
        });

        $("#dist7").click(function(){
            $("#cont17State").hide();
            $(".map").hide();
            $("#map_ctry").hide();
            $("#sml_state").show();
            $("#cont17Dist7").show();
        });

        $(".district_show").click(function(){
            $(".siderbar").show();
        })

        $("#sml_state").click(function(){
            $(".map").hide();
            $(".siderbar").hide();
            $("#map_ctry").show();
            $("#cont17State").show();
        })

        $("#map_ctry").click(function(){
            $(".map").hide();
            $(".sml_map").hide();
            $("#map_lrg").show();
        })

        $(".state_level").mouseover(function(){
            $(".siderbar").show();
        })

  
  
        //   $(document).on('mousemove', function(e){
    
        //       $(".description_survey").css({
        //           left:  e.pageX - 0,
        //           top:   e.pageY - 320
        //       });
          
        //   });
    }


    //On Mouse Enter
    callMapData=(selection_id)=>{
        console.log(this.state.dsData)
        //debugger
        console.log(selection_id)
        let result = this.state.trendsResults.filter(
            data => data.geoCode === selection_id
        )
        console.log(result)
        this.setState({
            headerName: result[0].geoName,
            searchPercantile: result[0].value[0]
        })
    
    }

  render(){
  
    console.log('@@@@@@@@@@@@@@@@@@@')
    console.log(this.state.trendsResults)
    console.log(this.state.headerName)
    console.log(this.state.searchPercantile)
    console.log(`Name -----> ${this.props.newValue}`)
    console.log('@@@@@@@@@@@@@@@@@@@')

    return (
      <div className="googleMap">
      {this.state.headerName == '' ? 
      
      null
      :
    
      <div className="googleSurveyCont">
        <p>
            {this.state.searchString2 == '' ? 'UNC' : this.state.searchString2} in {this.state.headerName} is 
        {
            this.state.searchPercantile > 90 ? ' very much popular ' : 
            this.state.searchPercantile > 60 ? ' popular ' : 
            this.state.searchPercantile > 40 ? ' preferable by the people ' :
            this.state.searchPercantile > 20 ? ' not much poplular ' :
            this.state.searchPercantile > 10 ? ' very rarely searched by people ' :
            ' hardly searched by anyone '
        }
        with the ratio of {this.state.searchPercantile} on the scale of 100
        </p>
        </div>  
    
    }
              
      
                <div className="sml_map">
                    <svg version="1.1" className="map" id="map_ctry" style={{display: 'block'}} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" >
                        <path className="st2" d="M50,847.2c0.4,0,0.9-0.2,1-0.6c1.2-3.8,3.7-6.7,6.6-9.2c1.4-1.2,2.8-2.3,4.2-3.4c1.8-1.4,3.4-2.9,5-4.5
                        c1.3-1.3,2.2-2.9,2.8-4.6c0.2-0.7,0.5-1.4,0.7-2.1c0.5-1.4,1.5-2.3,2.7-3c1.1-0.6,2.3-1,3.6-1.3c2.5-0.6,5-1,7.5-1.3
                        c3.7-0.4,7.4-1,10.9-2.5c1-0.4,2.1-0.5,3.1-0.5c3.1-0.1,6.2-0.3,9.2-0.8c6.1-1,12.1-2.5,17.7-5.1c2.1-1,4.2-2.1,6.1-3.5
                        c1.5-1,2.9-2.2,4.1-3.5c2.9-3.2,5.4-6.6,7.5-10.3c0.8-1.4,1.5-2.8,1.8-4.4c0.3-1.4,0.4-2.7,0-4.1c-0.1-0.5-0.2-1.1-0.4-1.5
                        c-0.7-1.5,0.1-2,1.1-2.5c0.7-0.4,1.5-0.8,2.4-0.8c1.3-0.1,2.5-0.3,3.8-0.4c0.8-0.1,1.7-0.1,2.6-0.1c2.5,0,4.8-0.7,7-1.9
                        c1.8-1,3.6-1.8,5.5-2.6c4.8-2.1,9.8-3.5,14.9-4.5c5.7-1,11.4-1.8,17.1-2.5c2.4-0.3,4.9-0.5,7.3-0.7c3.4-0.3,6.8-0.5,10.2-0.8
                        c7.5-0.7,15-2,22-5c1-0.4,2-0.8,3-1.1c6.7-2.6,12.1-7,16.5-12.7c0.8-1,1.4-2.2,1.9-3.3c0.7-1.4,1.4-2.9,2.1-4.3
                        c0.7-1.2,1.1-2.6,1.4-3.9c0.5-2.1,1.4-4,2.6-5.8c0.2-0.1,0.4-0.2,0.6-0.2c2.8,0,5.5-0.8,8.1-1.6c1.6-0.5,3.2-1.1,4.8-1.7
                        c1.6-0.6,3.2-1.2,4.9-1.4c1.4-0.2,2.7-0.7,3.9-1.4c1.7-0.9,3.3-1.9,5-2.8c0.9-0.5,1.8-1.1,2.7-1.7c0.3,0.2,0.6,0.3,0.8,0.5
                        c3,3.5,5.9,7,8.9,10.5c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.2,0.9-0.2,1.1c-1,0.3-1.6,0.9-1.7,2.1c0,0.8-1,1.1-1.1,1.9
                        c-0.1,0.8-0.5,1.3-1.3,1.6c-0.3,0.1-0.6,0.4-0.6,0.7c-0.3,0.8-0.7,1.5-1.2,2.2c-0.5,0.8-0.4,1.6,0.3,2.4c0.1,0.2,0.3,0.3,0.4,0.5
                        c0.1,0.2,0.2,0.5,0.1,0.6c-0.7,0.9-1.2,2.1-2.4,2.4c-0.5,0.2-0.6,0.6-0.6,1c0,0.3,0.1,0.6,0.1,0.9c0.1,1.3-0.1,1.6-1.3,1.5
                        c-0.9-0.1-1.7,0.1-2.3,0.8c-0.1,0.2-0.3,0.3-0.5,0.4c-0.3,0.1-0.6,0.1-0.9,0c-0.3-0.1-0.6-0.3-0.8-0.5c-0.6-0.5-1.4-0.6-2.1-0.5
                        c-0.4,0.1-0.8,0.3-1.1,0.6c-1.1,1.3-2.2,2.6-2.7,4.3c-0.2,0.5-0.5,1-0.7,1.4c-0.4,1-1.1,1.8-2.1,2.4c-0.7,0.4-1.4,0.9-2.1,1.4
                        c-0.7,0.5-1.3,1.1-1.7,1.9c-0.5,1-1.1,1.8-2,2.4c-0.6,0.4-0.9,1.1-1,1.9c-0.6,4.9-1.3,9.7-1.9,14.5c-0.5,3.7-1.1,7.4-1.6,11
                        c-0.2,1.5-0.3,3-0.3,4.5c0,1.7,0,3.4,0.1,5.1c0,0.4,0.4,0.8,0.8,0.8c1.4,0,2.8,0,4.1,0c0.5,0,0.9-0.5,1-1c0-1.2,0-2.3,0.1-3.5
                        c0-0.5,0.1-1.1,0.1-1.6c0-0.4,0.6-0.8,0.9-0.7c0.8,0.3,1.6,0.5,2.4,0.8c2.5,0.9,5,1.8,7.5,2.8c1,0.4,2,0.4,3.1,0.1
                        c1.9-0.6,3.9-1.1,5.8-1.6c0.6-0.2,1.2-0.3,1.9-0.4c0.7-0.1,1.1,0.1,1.1,0.7c0,0.1,0,0.2,0,0.3c0,3.5,0,7,0,10.5
                        c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.2-0.4,0.4-0.5,0.4c-1.9,0.3-1.9,0.3-2,2.2c0,1,0,1.9,0,2.9c0,0.4,0.2,0.8,0.3,1.2
                        c0.2,0.7,0.2,0.8-0.4,0.8c-1.3-0.1-2,0.5-2.4,1.6c-0.2,0.4-0.3,0.8-0.5,1.2c-0.5,0.8-0.4,1.6,0.2,2.4c0.1,0.2,0.2,0.4,0.2,0.6
                        c0,0.2-0.2,0.5-0.3,0.5c-0.7-0.1-1.5-0.3-2.2-0.3c-1-0.1-1.8,1.1-1.5,2c0.1,0.3,0.3,0.6,0.4,0.9c0.4,0.6,0.4,1.1,0.2,1.8
                        c-0.2,0.5-0.4,1-0.4,1.5c-0.1,0.7,0.1,1.3,0.6,1.7c0.2,0.1,0.3,0.3,0.5,0.4c1.2,1,0.9,2.4,0,3.4c-0.4,0.4-0.8,0.7-1.2,1
                        c-0.6,0.4-1.2,0.9-1.9,1.1c-0.6-0.2-0.8-0.7-1.1-1.1c-1.3-1.8-2.9-3.1-4.8-4.1c-1.7-0.9-3.4-1.8-5.2-2.4c-1.9-0.7-3.9-1.1-5.9-1.1
                        c-1.4,0-2.8,0-4.1,0.1c-1.8,0.1-3.4-0.3-4.7-1.5c-0.3-0.3-0.7-0.5-1-0.8c-1.4-1-3-1.6-4.6-2.1c-0.2-0.1-0.4-0.1-0.6-0.1
                        c-2.4-0.2-4.9-0.1-7.3,0.1c-1.1,0.6-2.3,0.4-3.5,0.2c-2.4-0.3-4.9-0.4-7.3-0.4c-4.5-0.1-8.9,0.5-13.3,1.4c-0.9,0.2-1.9,0.4-2.8,0.6
                        c-3.5,0.9-7,0.8-10.5,0.2c-0.5-0.1-1-0.2-1.6-0.3c-3.2-0.4-6.4-0.2-9.4,1.1c-2.4,1-4.7,2-6.9,3.2c-1.7,0.9-3.3,1.9-5,2.8
                        c-0.7,0.4-1.5,0.8-2.3,1.1c-1.6,0.7-3.1,0.6-4.6-0.1c-0.6-0.3-1.2-0.6-1.5-1.1c-1.6-2.1-3.6-3.6-5.7-5.1c-1.2-0.9-2.3-1.8-3.4-2.9
                        c-1.9-1.9-4.3-2.8-6.9-3c-5.7-0.4-11.2,0.4-16.6,2.3c-3,1.1-5.9,2.3-8.8,3.8c-2.6,1.4-5.2,2.8-7.8,4.3c-4.3,2.5-8.6,5-13,7.5
                        c-0.5,0.3-0.9,0.5-1.4,0.8c-1.3,0.9-2.7,1.2-4.2,1.1c-0.6,0-1.3,0-1.9,0c-1.4-0.1-2.8,0.3-4,0.9c-1.7,0.8-3.4,1.8-4.9,3
                        c-2,1.8-4.2,3.3-6.4,4.8c-1.8,1.2-3.5,2.4-5.2,3.7c-3,2.5-6.4,4.2-10,5.4c-2.4,0.8-4.9,1.3-7.4,1.9c-0.6,0.2-1.2,0.4-1.8,0.6
                        c-2.3,0-4.7,0-7,0c-0.3-0.2-0.5-0.4-0.8-0.5c-1-0.3-2.1-0.5-3.1-0.8c-2.1-0.6-4.2-1.4-6.2-2.6c-1.8-1.1-3.2-2.4-4-4.4
                        c-0.1-0.2-0.1-0.4-0.3-0.6c-0.3-0.3-0.7-0.5-1-0.7C50,848.9,50,848,50,847.2z"></path>
                        <path className="st3" d="M843.1,503.8c0.2,0.4,0.6,0.9,0.7,1.4c0.8,3,1.5,6,1.4,9.1c0,0.3,0,0.6,0,1c0,1.3,0.3,2.5,1.2,3.6
                        c0.5,0.6,0.8,1.2,1,2c0.4,1.6,1.2,3,1.9,4.4c0.5,1.2,1,2.3,1.4,3.5c0.3,0.9,0.3,1.9-0.3,2.8c-0.1,0.2-0.2,0.4-0.3,0.5
                        c-0.5,0.7-0.6,1.4-0.3,2.1c0.5,1.8,1,3.7,1.7,5.5c0.7,1.9,1.6,3.7,2.4,5.6c0.5,1.2,1.1,2.3,2.1,3.2c1.4,1.3,2.1,2.9,2.3,4.8
                        c0.1,0.5,0.1,1.1,0.2,1.6c0.1,0.5,0.3,1,0.6,1.5c1.8,2.4,2.9,5.2,4,8c1.6,3.8,3.1,7.7,4.8,11.5c0.9,1.9,1.9,3.8,2.7,5.8
                        c0.4,1,1.1,1.8,1.9,2.5c1.5,1.1,2.6,2.5,3.6,4.1c0.6,1,1.4,1.8,2.1,2.8c0.3,0.4,0.5,0.9,0.8,1.4c0.1,0.2-0.2,0.7-0.4,0.7
                        c-1.1-0.1-2.1-0.1-3.2-0.2c-0.7,0-1.5-0.1-2.2,0c-0.5,0.1-1,0.4-1.5,0.6c-0.2,0.1-0.3,0.6-0.1,0.8c0.5,0.7,1.2,1.2,2.1,1.4
                        c0.4,0.1,0.9,0.1,1.2,0c0.7-0.2,1.4-0.6,2.1-0.9c0.7,0.3,1.4,0.5,2.1,0.8c0.6,0.2,1.3,0.3,1.9,0c1.6-0.8,3.3-0.7,5-0.1
                        c0.7,0.3,1.4,0.4,2.2,0.4c0.9,0,1.7-0.4,2-1.3c0.3-0.7,0.5-1.4,0.7-2.1c0.2-0.5,1-1,1.6-0.9c0.5,0.1,1.1,0.2,1.5,0.5
                        c1,0.6,2,0.4,3.1,0c1.4-0.5,2.7-1.2,4-1.9c0.4-0.2,0.9-0.1,1.2,0.2c0.4,0.4,0.5,1.1,0.3,1.5c-0.2,0.3-0.4,0.5-0.6,0.8
                        c-0.5,0.6-0.9,1.2-1.1,1.9c-0.2,0.7-0.5,1.4-0.8,2.1c-0.6,1.2-0.9,2.4-0.5,3.7c0.2,0.7-0.2,1.2-0.8,1.6c-0.8,0.5-1.5,1.2-2.4,1.4
                        c-1.1,0.3-2-0.6-3-0.9c-0.8-0.2-1.5-0.8-2.3-1.2c-0.7-0.3-1.3-0.6-2-0.9c-0.6-0.2-1.4,0.1-1.6,0.7c-1.3,3.4-3.4,6.4-4.9,9.7
                        c-0.9,2-2.2,3.9-3.5,5.7c-1.3,1.9-2.1,3.9-2.5,6.1c-0.4,2.1-0.8,4.2-1.3,6.2c-0.3,1.2-0.4,2.3-0.2,3.5c0.3,1.2,0.2,2.3,0,3.5
                        c-0.6,3.3-0.8,6.5-0.3,9.8c0.4,2.5,0.2,5.1-0.3,7.6c-0.4,2-0.7,4-0.4,6c0.1,1.2-0.2,2.3-0.6,3.4c-0.3,0.8-0.4,1.7,0,2.5
                        c0.8,1.8,0.8,3.7,0.6,5.6c-0.2,1.7-0.4,3.4-0.4,5.1c0,2.7-0.2,5.3-0.8,7.9c-0.6,2.8-0.8,5.7-1.1,8.5c-0.3,2.6-0.6,5.3-1.2,7.9
                        c-0.2,0.9-0.3,1.9-0.3,2.9c0,1.6,0.1,3.2,0.2,4.8c0.3,2.1,0.4,4.2,0.4,6.4c0,1.7,0.2,3.4,0.6,5.1c0.4,1.6,0.8,3.3,0.8,5
                        c0.1,1.5,0.5,2.9,0.8,4.4c0.7,3.8,1.2,7.6,1.8,11.3c0.6,4.1,0.9,8.3,1.8,12.3c0.4,1.6,0.8,3.1,1.3,4.6c0.5,1.7,1.1,3.4,1.7,5.1
                        c0.5,1.4,0.9,2.8,0.8,4.4c-0.1,1.2,0.6,2,1.5,2.6c0.9,0.7,2,0.7,2.9,1.3c0.2,0.1,0.3,0.7,0.1,0.9c-0.1,0.1-0.1,0.2-0.2,0.2
                        c-1.4,1-2.9,1.8-4.4,2.5c-0.5,0.2-1.1,0.1-1.5-0.3c-0.8-0.8-1.7-1.2-2.9-1.1c-0.7,0-1-0.3-1.1-0.9c-0.1-0.7-0.3-1.5-0.4-2.2
                        c-0.1-0.5-0.2-1.1-0.1-1.6c0.1-0.8,0.1-1.5-0.3-2.1c-0.6-0.9-0.7-1.8-0.6-2.7c0-0.5,0-1.1,0-1.6c-0.2-1.5-1-2.6-2.4-3.1
                        c-0.4-0.1-0.8-0.3-1.2-0.3c-3.3-0.3-6.6-0.6-9.9-0.2c-1.6,0.2-3.1,0.7-4.4,1.7c-0.2,0.1-0.4,0.2-0.5,0.4c-2,1.8-4.2,3.2-6.4,4.8
                        c-1.1,0.8-2.3,1.6-3.6,2.1c-1.2,0.5-2.3,1.2-3,2.3c-0.9,1.4-2.3,2.2-3.7,2.9c-0.8,0.4-1.6,0.7-2.1,1.4c-0.8,1-1.9,1.6-3.2,2
                        c-0.8,0.3-1.5,0.8-2,1.5c-0.8,1.5-2.3,2.1-3.6,2.8c-2-0.6-3.5-1.8-5-3c-1.1-0.8-1.8-1.7-1.9-3.1c-0.1-1.2-0.9-2.1-1.9-2.8
                        c-0.3-0.2-0.7-0.5-1.1-0.7c-1.1-0.5-1.9-1.4-2.3-2.5c-0.2-0.5-0.4-1-0.6-1.5c-0.7-1.5-1.6-2.7-2.8-3.9c-2-2-3.9-4-5.8-5.9
                        c-1-1-1.9-2-2.9-2.9c-1.3-1.1-2.4-2.4-3.5-3.7c-1.1-1.4-2.3-2.8-3.4-4.2c-0.8-1-1.5-2.1-1.6-3.4c-0.1-1-0.5-1.8-1.2-2.5
                        c-0.2-0.1-0.3-0.3-0.4-0.5c-0.4-0.6-0.6-1.2-0.3-1.8c0.8-1.5,0-2.7-0.7-3.9c-0.4-0.7-1.2-1-1.9-0.7c-0.5,0.2-1,0.4-1.5,0.5
                        c-0.7,0.1-1.5-0.6-1.5-1.3c0-0.1,0-0.2,0-0.3c0.5-1.3,0-2.3-1.2-2.9c-0.4-0.2-0.8-0.5-0.8-0.9c-0.2-1.1-0.8-1.9-1.5-2.8
                        c-0.4-0.5-0.7-1.1-1-1.6c-0.4-0.6-1-1.1-1.8-1.1c-0.3,0-0.7,0-0.9-0.2c-0.4-0.2-0.7-0.5-0.9-0.8c-0.2-0.3-0.3-0.8-0.4-1.2
                        c-0.2-0.9-0.4-1.9-0.6-2.8c-0.4-1.6-1.2-2.9-2.8-3.6c-0.8-0.3-1.4-0.9-2.3-1.4c-0.1-0.7-0.2-1.4-0.2-2.1c0-1,0.1-1.9,0.1-2.9
                        c0-0.9-0.3-1.7-0.9-2.3c-0.1-0.2-0.3-0.3-0.4-0.5c-1.5-1.1-2.5-2.6-3.2-4.3c-0.4-1-1.1-1.8-1.7-2.7c-0.4-0.6-1-1.2-1.7-1.4
                        c-2-0.7-3-2.3-3.9-4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.6-1.9-1.9-3.3-3.4-4.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.7-0.5-1.2-1.2-1.5-2
                        c-0.5-1.1-1-2.1-1.5-3.2c-0.8-1.6-1.6-3.2-2.8-4.6c-0.2-0.2-0.4-0.5-0.5-0.8c-1.1-2.3-2.9-4.2-4.8-5.9c-0.7-0.6-1.3-1.4-1.8-2.3
                        c-1-2.1-2.4-3.8-4-5.3c-1-0.9-1.6-2-2-3.2c-0.3-0.9-0.8-1.8-1-2.7c-0.4-1.4-1.1-2.5-2.2-3.4c0.1-0.7,0.1-1.3,0.2-2
                        c0.1-1.4-0.2-2.7-0.8-4c-0.6-1.2-1.4-2.1-2.7-2.6c-0.4-0.2-0.8-0.3-1.2-0.4c-0.4-0.2-0.7-0.5-0.8-0.9c-0.2-0.6-0.4-1.2-0.6-1.8
                        c-0.3-0.7-1.4-1.2-2.1-1.1c-0.5,0.1-1,0.3-1.5,0.5c-0.2,0.1-0.5,0.5-0.5,0.7c-0.1,0.7-0.1,1.5,0,2.2c0,1.2-0.3,2.1-1.5,2.5
                        c-0.1,0-0.2,0.1-0.3,0.1c-0.8,0.4-1.4,1-1.7,1.8c-0.2,0.4-0.3,0.8-0.5,1.2c-0.3,0.7-0.9,1.2-1.7,1.3c-0.5,0.1-1.1,0.1-1.6,0.1
                        c-0.7,0.1-1.2,0.6-1.3,1.2c-0.2,0.8-0.2,1.7-0.3,2.5c-0.1,0.5-0.4,0.8-0.8,1c-1,0.4-3,1.8-3.8,2.6c-0.6,0.6-2.3,0.6-2.9-0.1
                        c-0.2-0.2-0.4-0.5-0.6-0.8c-0.4-0.6-0.8-1.2-1.5-1.6c-0.6-0.3-0.9-0.8-1-1.5c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.6-0.6-1.1-1.1-1.5
                        c-0.6-0.4-1.3-0.7-1.9-1.2c-0.7-0.5-1.5-0.8-2.4-0.7c-0.7,0.1-1.2-0.2-1.8-0.5c-0.6-0.3-1.2-0.7-1.8-0.7c-1.4,0-2.2-0.5-2.8-1.7
                        c-0.1-0.2-0.3-0.3-0.5-0.4c-0.7-0.5-1.5-0.5-2.1,0.1c-0.7,0.8-1.5,1.4-2.6,1.8c-1.1,0.4-1.8,1.2-1.8,2.4c0,0.2,0,0.4-0.1,0.6
                        c-0.1,0.5-0.7,1-1.1,1c-0.4,0-0.8,0-1.3-0.1c-0.6-0.1-1.3-0.2-1.9-0.2c-1,0-1.6,0.5-1.9,1.3c-0.2,0.7-0.5,1.4-0.7,2.1
                        c-0.4,1-1.3,1.3-2.2,1.6c-0.2,0.1-0.5,0.1-0.6,0c-1.4-1.1-3-0.9-4.6-1.1c-0.3,0-0.6-0.2-0.7-0.6c0-0.4,0-0.8,0-1.3
                        c0.1-0.8-0.1-1.5-0.5-2.1c-0.2-0.3-0.2-0.6-0.3-0.9c0.3-0.2,0.5-0.5,0.8-0.6c0.4-0.2,0.8-0.2,1.2-0.3c1.5-0.3,2-1,1.6-2.5
                        c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.7-0.3-1.4,0-2.1c0.5-1.3-0.4-2.5-1.8-2.7c-0.6-0.1-1.3,0.1-1.9,0.3c-1.3,0.4-2.6,0.8-4,1.1
                        c-0.2,0.1-0.5-0.2-0.8-0.4c-0.1,0-0.1-0.2-0.1-0.3c-0.1-0.3-0.1-0.6-0.2-0.9c-0.4-1.2-1-2.1-2.3-2.4c-0.5-0.1-0.7-0.5-0.7-0.9
                        c0-0.4,0-0.9-0.1-1.3c-0.1-0.4-0.2-0.9-0.5-1.1c-1.3-0.8-1.9-2.3-3.1-3.1c-0.2-1.3-0.3-2.6,0.2-3.8c0.3-0.7,0.1-1.5-0.2-2.2
                        c-0.2-0.5-0.5-1-0.7-1.4c-0.3-0.8,0.1-1.8,0.9-2.1c0.4-0.1,0.8-0.3,1.2-0.3c0.9,0,1.6-0.4,2.1-1.2c0.3-0.5,0.9-0.5,1.4-0.4
                        c0.4,0.1,0.8,0.3,1.2,0.5c0.7,0.2,1.5,0.3,2.2,0c0.3-0.1,0.6-0.4,0.7-0.6c0.2-0.7,0.3-1.4,0.4-2.1c0.8-0.4,1.6-0.8,2.3-1.3
                        c0.8-0.5,0.8-1.6,0.1-2.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.7-0.8-1.8-2.2-1.4-3.8c0-0.2,0.1-0.4,0.2-0.6
                        c0.7-1.8,0.3-2.7-1.4-3.7c-0.9-0.5-1.4-1.3-1.4-2.3c0-0.1,0-0.2,0-0.3c0-0.9-0.4-1.7-1.2-2.1c-0.8-0.4-1.3-1.1-1.7-1.8
                        c-0.4-0.7-1-1.4-1.5-2c-0.7-0.9-1.7-1.3-2.8-1.1c-1.3,0.2-2.3-0.2-3.3-0.9c-0.4-0.3-0.9-0.6-1.4-0.7c-0.8-0.2-1.6-0.6-2.2-1.2
                        c-0.1-0.1-0.2-0.1-0.2-0.2c-0.8-0.6-2.3-2.5-2.7-3.4c-0.2-0.6-0.9-1-1.5-0.9c-0.6,0.1-3.9-0.3-4.5-0.6c-0.4-0.1-0.8-0.3-1.1-0.5
                        c-0.3-0.1-0.3-0.6-0.2-0.8c0.3-0.3,0.6-0.6,0.9-0.9c0.4-0.5,0.4-1.2,0-1.8c-0.6-0.8-1.4-1.2-2.2-0.9c-0.9,0.3-1.8,0.7-2.3,1.6
                        c-0.3,0.5-0.6,0.8-1.2,1.1c-0.3,0.1-0.7-0.1-0.8-0.3c0-0.3-0.1-0.6-0.1-0.9c0-0.9,0-1.7,0-2.6c0-31.5,0-63,0-94.5
                        c0-1.2,0-2.3,0.1-3.5c0-0.4,0.4-0.8,0.9-0.8c0.7,0,1.5-0.1,2.2-0.1c19.7,0,39.4,0,59.1,0c0.6,0,1.3,0.1,1.9,0.1
                        c0.3,0,0.4,0.2,0.3,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.5,0.2-1,0.4-1.5,0.6c-0.3,0.1-0.6,0.2-0.9,0.4c-0.9,0.5-1.4,1.9-0.7,2.8
                        c0.8,1,1.1,2,0.9,3.3c-0.1,0.7-0.5,1.1-1.3,1.2c-0.1,0-0.2,0-0.3,0c-1.6,0.3-2.2,1-2.3,2.5c0,0.6,0.1,1,0.6,1.4
                        c0.3,0.3,0.7,0.5,1,0.8c0.9,0.9,0.8,1.6-0.1,2.4c-0.3,0.3-0.7,0.5-1,0.8c-0.2,0.2-0.2,0.6-0.2,0.9c0,0.3,0.2,0.6,0.4,0.9
                        c0.6,0.8,0.8,1.5,0,2.3c-0.7,0.6-0.5,1.6-0.8,2.4c-0.2,0.5-0.8,0.8-1.3,0.8c-0.2,0-0.4-0.1-0.6-0.2c-1.1-0.5-2.1-0.4-2.9,0.4
                        c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.3-1.1,0.3-1.4-0.1c-0.2-0.2-0.4-0.5-0.6-0.8c-0.3-0.4-0.8-0.8-1.3-0.9c-0.7-0.2-1.1-0.6-1.3-1.3
                        c0-0.1-0.1-0.2-0.2-0.3c-0.4-0.6-1.1-0.9-1.6-0.7c-0.5,0.2-0.8,0.6-0.9,1.2c-0.1,0.3-0.1,0.6-0.1,1c0,4.4,0,8.7,0,13.1
                        c0,0.4,0.1,0.8,0.2,1.3c0,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1c1,0,1.9,0,2.9,0c8.3,0,16.6,0,24.9,0c0.9,0,1.7,0,2.6,0
                        c0.7,0,1.3,0.1,1.8,0.6c0.4,0.3,0.9,0.6,1.3,0.9c0.5,0.3,1,0.4,1.5,0.1c0.2-0.1,0.4-0.1,0.6-0.2c1-0.7,1.9-0.8,2.9-0.2
                        c0.4,0.2,0.8,0.2,1.2,0c0.3-0.1,0.6-0.3,0.9-0.4c0.3,0,0.7,0.1,0.8,0.3c0.7,1.2,1,2.5,1.2,3.9c0.1,0.5,0,1.1-0.1,1.6
                        c0,0.6-0.1,1.3-0.1,1.9c0,1.1,0.4,2.1,1.3,2.8c0.6,0.4,1.2,0.9,1.8,1.4c1,0.7,1.5,1.6,1.6,3c0.1,1.7,1.6,2.8,3.3,2.8
                        c0.9,0,1.7-0.3,2-1.2c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.7,0.6-1.1,1.3-1.2c1-0.2,1.4-0.9,1.5-1.8c0-0.5,0-1.1,0-1.6
                        c0.1-0.9,0.6-1.5,1.6-1.7c0.4-0.1,0.9-0.1,1.2-0.2c0.4-0.2,0.7-0.4,1.1-0.7c0.5-0.4,0.7-1.2,0.4-1.7c-0.4-0.9-0.8-1.8-1.5-2.4
                        c-0.7-0.6-1.2-1.2-1.6-1.9c-0.3-0.6-1-0.8-1.5-1.1c-0.5-0.3-0.6-0.8-0.3-1.1c1.5-1.2,1.2-2.5,0.2-3.9c-0.2-0.2,0.1-0.7,0.3-0.8
                        c0.2,0,0.4-0.1,0.6-0.2c0.4,0,0.9,0,1.3,0c0.9,0,1.7-0.2,2.3-0.8c0.5-0.4,1-0.8,1.7-0.9c0.8-0.1,1.4-0.5,1.9-1
                        c0.6-0.7,1.4-0.9,2.3-0.9c2.1,0.1,3.4-0.9,4.2-2.7c0.7-1.6,2.1-2.3,3.6-3c0.8-0.4,1.6-0.4,2.4,0.2c0.6,0.5,1.4,0.6,2.1,0.3
                        c0.5-0.2,1-0.5,1.2-1c0.6-1.6,1.8-2,3.3-2c0.3,0,0.6-0.1,0.9-0.1c0.4-0.1,0.8-0.3,1-0.7c0.3-0.4,0.6-0.9,1-1.2
                        c0.4-0.4,1.4-0.4,1.8,0c0.1,0.1,0.3,0.3,0.4,0.5c0.5,1,1.3,1.2,2.4,0.9c0.7-0.2,1.2,0.1,1.6,0.7c0.1,0.1,0.1,0.2,0.2,0.3
                        c0.4,0.6,0.9,1,1.6,0.8c0.9-0.2,1.6-0.6,1.7-1.6c0-0.2,0.1-0.4,0.2-0.7c0.3-0.1,0.6-0.3,0.9-0.3c1,0,1.9-0.3,2.5-1.1
                        c0.4-0.5,0.9-0.8,1.6-0.9c0.5-0.1,1-0.3,1.5-0.4c0.9-0.2,1.4-0.9,1.6-1.8c0.1-0.5,0-1.1,0.5-1.5c0.1-0.1,0.2-0.1,0.3-0.1
                        c0.1,0,0.3,0.1,0.3,0.1c0.1,0.9,0.3,1.9,0.4,2.8c0.4,0.1,0.6,0.3,0.9,0.3c1.1,0,2.1,0,3.2,0c16.3,0,32.6,0,48.9,0
                        C840.6,503.7,841.8,503.6,843.1,503.8z"></path>
                        <path className="st4" d="M562.8,710.4c0-0.6,0.1-1.3,0.1-1.9c0-5.1,0-10.2,0-15.3c0-0.2,0-0.4,0-0.6c0.1-1-0.4-1.8-1-2.6
                        c-0.6-0.7-1.2-1.1-2.2-1.1c-0.2,0-0.4,0-0.6,0c-0.6,0-1-0.2-1.3-0.8c-0.1-0.3-0.3-0.6-0.5-0.8c-0.4-0.7-1-1-1.7-1.3
                        c-0.4-0.2-0.8-0.1-1.2,0.1c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.5-0.8,0.6c-1.3,0.6-2.7,0.4-4.1,0.3c-1-0.1-1.8-0.7-2.4-1.4
                        c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.6-0.5-1.2-0.2-1.8c0.3-0.6,0.7-1.1,0.9-1.7c0.3-0.7,0.3-1.5-0.2-2.1c-0.3-0.4-0.7-0.8-1.1-1.2
                        c-0.2-0.2-0.2-0.7,0-0.9c0.1-0.1,0.1-0.2,0.2-0.2c1.2-0.6,1.9-1.6,2-3c0-0.5,0.2-1,0.5-1.5c0.6-1.1,1.2-2.2,1.5-3.5
                        c0.2-1,1.1-1.4,2.3-1.4c0.4,0,0.8,0.4,0.9,0.8c0.1,0.7,0,1.6,0.3,2.2c0.5,0.9,1.3,1.6,2.1,2.4c0.2,0.2,0.6,0.3,0.9,0.4
                        c0.2,0.1,0.4,0.1,0.6,0c1.3-0.2,2.5-0.4,3.7,0.4c0.2,0.2,0.6,0.2,0.9,0.2c1.3,0.2,2.3-0.3,3-1.4c0.4-0.7,0.8-1.5,1.1-2.3
                        c0.3-0.7,0.8-1,1.4-1.2c0.5-0.1,1.1-0.2,1.6-0.3c1.6-0.4,2.2-1.9,1.4-3.2c-0.2-0.3-0.4-0.5-0.6-0.7c-0.5-0.6-0.6-1.4-0.4-2.1
                        c0.1-0.4,0.3-0.8,0.6-1.1c0.1-0.2,0.3-0.3,0.5-0.4c1.5-0.6,2.4-1.8,2.8-3.3c0.2-0.8,0.6-1.3,1.3-1.7c1.1-0.7,1.5-1.7,1.5-3
                        c0-0.7,0.3-1,1-1c1.8,0.1,3.1-0.7,3.9-2.2c0.5-0.9,1.2-1.2,2.1-1.1c0.1,0,0.2,0,0.3,0c0.7,0,1.2-0.4,1.4-1.1
                        c0.2-0.6,0.3-1.2,0.4-1.8c0.2-0.2,0.4-0.4,0.6-0.4c1.2-0.1,2.2-0.7,2.9-1.7c0.5-0.7,1.2-0.8,2-0.6c0.3,0.1,0.6,0.2,0.9,0.3
                        c1.3,0.5,2.5,0.4,3.6-0.5c0.2-0.1,0.4-0.2,0.5-0.3c0.7-0.4,1.8-0.2,2.3,0.5c0.4,0.5,0.7,1,1.1,1.5c0.4,0.5,0.9,0.9,1.6,0.9
                        c0.3,0,0.6,0,1,0.1c0.6,0.1,1,0.3,1.3,0.8c0.2,0.3,0.3,0.6,0.4,0.8c0.6,1,1.4,1.6,2.6,1.5c0.6,0,1.3,0,1.9,0c0.9,0,1.6,0.4,2.3,0.9
                        c0.2,0.1,0.3,0.3,0.4,0.5c1.5,1.6,3.2,1.9,5.2,0.9c0.2-0.1,0.4-0.3,0.6-0.2c0.3,0,0.7,0.1,0.9,0.3c0.4,0.5,0.7,1,1.1,1.5
                        c0.9,1.1,2.1,1.5,3.4,1c0.3-0.1,0.6-0.3,0.9-0.4c1.1-0.4,2.2-0.6,3.4-0.3c0.3,0.1,0.6,0.1,0.9,0.1c1,0.1,1.9-0.1,2.6-0.9
                        c0.6-0.7,1.4-1.3,2.3-1.7c0.8-0.4,1.3-1.1,1.4-2c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.8-0.7,1-0.5c1.2,0.9,2.6,0.8,4,0.5
                        c0.7-0.1,1.3,0.2,1.6,0.8c0.2,0.4,0.4,0.8,0.6,1.1c0.9,1.5,2.5,2,4.1,1.3c0.5-0.2,0.9-0.5,1.4-0.8c0.7,0.4,1.3,0.7,2,1.1
                        c0.7,0.4,1.4,0.4,2.2,0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.9-0.4,1.8-0.6,2.8-0.6c1.6,0,2.1-0.5,2.6-2.2c0.1-0.4,0.2-0.8,0.4-1.2
                        c0.1-0.2,0.5-0.6,0.7-0.6c2.1,0,4-0.3,5.8-1.4c2.5-0.5,4.9-1.3,7.3-2.1c0.2-0.1,0.4,0,0.6,0c0.6,0,1.3,0.9,1,1.4
                        c-0.5,0.9-0.5,1.8,0,2.7c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.4-0.2,0.9-0.6,1c-0.5,0.1-1.1,0.2-1.5,0.4c-0.5,0.2-0.9,0.5-1.3,0.8
                        c-0.3,0.2-0.5,0.9-0.3,1.2c0.2,0.3,0.3,0.5,0.5,0.8c0.4,0.4,0.6,0.9,0.6,1.4c0,0.6,0,1.3,0,1.9c0,0.5,0.3,0.8,0.7,0.9
                        c0.5,0.2,1,0.3,1.5,0.3c1.1,0,2.1,0.1,3.1,0.7c1.2,0.8,3.9-0.3,4.4-1.6c0.3-0.8,0.6-1.6,0.9-2.4c0.2-0.6,0.9-1,1.5-0.9
                        c0.4,0.1,0.8,0.2,1.2,0.3c2.2,0.5,3.2-0.1,3.6-2.3c0-0.2,0-0.4,0.1-0.6c0.2-0.6,0.5-0.9,1-1.1c0.6-0.2,1.2-0.3,1.7-0.7
                        c0.5-0.5,0.8-1.4,1.7-1.2c0.7,0.1,0.9,0.8,1.3,1.3c0.4,0.5,1,0.9,1.6,0.9c1.2,0,2.3,0.4,3.3,1c0.3,0.1,0.6,0.3,0.9,0.3
                        c1.4-0.1,2.5,0.5,3.5,1.4c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.3,1.1,0.8,1.1,1.5c0,1,0.5,1.6,1.3,2.1c0.8,0.4,1.3,1.1,1.7,1.8
                        c0.4,0.8,1.2,1.2,2.1,1.4c1,0.3,1.9,0.1,2.6-0.7c0.2-0.2,0.4-0.5,0.6-0.7c0.5-0.9,1.3-1.3,2.3-1.5c0.3-0.1,0.6-0.1,0.9-0.3
                        c0.6-0.3,1-0.8,1.1-1.5c0.1-0.7,0.1-1.5,0.2-2.2c0-0.4,0.5-0.8,0.8-0.9c0.6-0.1,1.3-0.1,1.9-0.3c0.8-0.2,1.3-0.7,1.7-1.3
                        c0.2-0.3,0.3-0.6,0.4-0.9c0.3-1.1,1-1.9,2.1-2.3c1.1-0.4,1.6-1.4,1.7-2.5c0.1-0.7,0.1-1.5,0.1-2.2c0-0.6,0.5-1,1-1
                        c0.6,0,1.2,0.2,1.3,0.7c0.2,0.5,0.4,1,0.5,1.5c0.2,0.8,0.7,1.3,1.5,1.5c0.3,0.1,0.6,0.1,0.9,0.3c1.2,0.5,2.1,1.4,2.6,2.7
                        c0.5,1.3,0.9,2.6,0.4,4c-0.3,0.9-0.1,1.7,0.6,2.3c0.6,0.5,1,1.1,1.2,1.8c0.5,1.4,1.1,2.8,1.6,4.2c0.3,0.8,0.7,1.5,1.3,2.2
                        c0.5,0.5,1,1.1,1.5,1.6c1,1.1,2,2.2,2.6,3.6c0.6,1.4,1.6,2.5,2.7,3.5c1.6,1.4,3,3,4,4.9c0.1,0.2,0.2,0.4,0.3,0.5
                        c2,2.4,3.3,5.2,4.6,8c0.5,1.1,1.1,2,2.1,2.7c0.4,0.3,0.8,0.7,1.2,1c1.1,0.9,1.9,2,2.4,3.3c0.3,0.8,0.7,1.6,1.1,2.3
                        c0.8,1.3,1.7,2.4,3.2,3c0.8,0.3,1.4,0.9,1.9,1.7c0.6,1,1.2,2,1.7,3c0.6,1.3,1.3,2.4,2.5,3.2c0.3,0.2,0.5,0.4,0.7,0.7
                        c0.6,0.6,0.9,1.4,0.9,2.3c0,0.6-0.1,1.3-0.2,1.9c0,0.6-0.1,1.3,0,1.9c0.1,0.8,0.5,1.6,1.3,2.1c0.8,0.4,1.6,1,2.5,1.5
                        c0.6,0.3,0.9,0.8,1.2,1.4c0.5,1.2,0.7,2.4,1,3.7c0.1,0.4,0.1,0.9,0.3,1.2c0.3,0.8,0.8,1.5,1.8,1.7c0.3,0.1,0.6,0.1,0.9,0.2
                        c0.7,0.1,1.1,0.5,1.5,1.1c0.5,0.8,0.9,1.7,1.6,2.4c0.2,0.2,0.4,0.6,0.4,0.8c0.1,1.1,0.8,1.7,1.6,2.2c0.5,0.3,0.6,0.8,0.5,1.4
                        c-0.1,0.3-0.1,0.6-0.1,0.9c0.1,1.2,1.5,2.4,2.7,2.1c0.3-0.1,0.6-0.2,0.9-0.3c1.3-0.5,1.9-0.1,2.2,1.5c0.1,0.5,0.1,1.1-0.1,1.6
                        c-0.3,1.2-0.1,2.3,0.9,3.2c0.7,0.7,1.1,1.5,1.1,2.5c0,1,0.5,1.9,1.1,2.6c2.7,3.2,5,6.6,8.2,9.3c1,0.9,1.9,2,2.9,3
                        c1.6,1.7,3.2,3.4,4.9,5c1.4,1.4,2.6,2.8,3.2,4.7c0.1,0.4,0.3,0.8,0.5,1.2c0.5,1,1.1,1.8,2.1,2.3c0.4,0.2,0.8,0.4,1.1,0.6
                        c0.8,0.5,1.5,1.2,1.7,2.2c0.5,2.8,0.7,2.5,2.7,4.1c1.1,0.9,2.1,1.7,3.4,2.4c0.3,0.1,0.5,0.4,0.8,0.7c-0.7,1.4-1.4,2.8-2.2,4.2
                        c-0.6,1.1-1.2,2.2-1.2,3.6c0,0.7-0.3,1.2-0.7,1.8c-0.5,0.7-0.9,1.4-1.2,2.2c-0.2,0.5-0.7,0.8-1.2,1c-0.3,0.1-0.6,0.2-0.9,0.2
                        c-1.3-0.1-2.5,0.4-3.6,1.2c-0.7,0.5-1.5,0.8-2.4,0.7c-1.5-0.2-2.9,0.1-4.2,1c-0.4,0.3-1,0.4-1.5,0.4c-1.8-0.1-3.6,0.1-5.4,0.5
                        c-0.9,0.2-1.9,0-2.8-0.2c-1-0.2-1.9,0.2-2.5,1c-0.2,0.2-0.4,0.5-0.5,0.8c-0.7,1.1-1.6,1.3-2.8,1c-0.3-0.1-0.6-0.2-0.9-0.3
                        c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0.3-2.7,0.8-3.6,1.9c-0.7,1-1.5,1.7-2.7,2.1c-0.8,0.2-1.3,0.8-1.7,1.4c-0.3,0.4-0.6,0.9-1,1.2
                        c-0.8,0.9-1.8,1.5-3.1,1.5c-1,0-1.5,0.7-2,1.4c-0.4,0.5-0.9,0.5-1.4,0.3c-0.3-0.1-0.5-0.4-0.8-0.4c-0.4-0.1-0.9-0.2-1.2,0
                        c-1,0.5-1.9,0.8-3,0.7c-0.8-0.1-1.4,0.4-1.9,1c-0.2,0.3-0.4,0.8-0.6,1.1c-0.3,0.5-0.6,0.8-1.2,1c-1.2,0.4-2.1,1.1-2.6,2.2
                        c-0.4,0.8-1.1,1.3-1.9,1.5c-1.8,0.5-3.4,1.1-5,2.1c-0.7,0.5-1.6,0.6-2.4,0.7c-1.2,0.2-2.2,0.6-3,1.7c-0.5,0.7-1.2,1-1.9,0.9
                        c-2.4,0-4.5,1.4-6.8,1.8c-1.7,1.2-3.5,2-5.5,2.5c-0.2,0.1-0.4,0.2-0.5,0.4c-0.7,1.2-1.9,1.6-3.1,2.1c-0.5,0.2-0.9,0.6-1.3,0.9
                        c-0.4,0.2-0.8,0.3-1.2,0c-0.5-0.4-1-0.8-1.5-1.2c-0.8-0.6-1.8-0.7-2.6,0c-0.7,0.6-1.4,0.9-2.3,0.8c-0.7-0.1-1.5,0-2.2,0.1
                        c-2.1,0.2-4,0.1-5.9-1c-0.5-0.3-1-0.4-1.5-0.6c-0.4-0.1-0.9-0.1-1.2,0.2c-0.8,0.7-1.7,0.8-2.7,0.7c-0.6,0-1.3,0-1.9,0
                        c-1.1,0-1.8-0.5-2.1-1.6c0-0.1-0.1-0.2-0.1-0.3c-0.2-1.4-1.9-1.9-3-1.8c-1.3,0.1-2.5,0.6-3.6,1.2c-0.6,0.4-1.2,0.8-1.9,1.2
                        c-0.9,0.6-1.8,1.1-2.8,1.4c-0.8,0.3-1.5,0.7-2.3,1.1c-0.6,0.3-1.1,0.6-1.8,0.7c-1.3,0.1-2.5,0.6-3.5,1.4c-0.5,0.4-1.1,0.6-1.8,0.5
                        c-0.9,0-1.7-0.1-2.5-0.1c-0.8,0-1.5-0.2-2.1-0.6c-0.7-0.5-1.5-0.7-2.4-0.5c-0.4,0.1-0.8-0.2-1.1-0.6c-0.4-0.5-0.7-1.1-1.1-1.6
                        c-0.6-0.7-1.5-0.8-2.3-0.6c-0.3,0.1-0.6,0.3-0.8,0.4c-0.5,0.3-1,0.4-1.5,0.1c-1.3-0.6-2.6-1-4-1c-0.3,0-0.7,0-0.9-0.2
                        c-1.3-0.8-2.8-0.8-4.2-1.4c-0.9-0.4-1.8-0.7-2.8-0.6c-0.8,0.1-1.5-0.2-2.1-0.6c-0.8-0.5-1.6-1-2.5-1.4c-0.9-0.5-1.6-1-2.2-1.8
                        c-0.6-0.8-1.3-1.4-2.4-1.3c-1.3,0-2.5,0.4-3.4,1.5c-0.3,0.3-0.5,0.7-0.9,0.9c-0.4,0.3-0.9,0.6-1.4,0.7c-1.5,0.2-3,0.3-4.4,0.4
                        c-0.7,0-1.3-0.3-1.6-0.8c-0.4-0.6-0.8-1.3-1.2-1.9c-0.4-0.5-1-0.9-1.6-0.8c-0.7,0-1.5,0-2.2,0.2c-1.2,0.3-2.3,0.2-3.5,0
                        c-1.3-0.3-2.5-0.7-3.6-1.2c-1.2-0.5-2.5-0.7-3.7-0.5c-1.2,0.2-2.3,0.3-3.5,0.5c-1.6,0.3-3.1,0.6-4.7,0.4c-2.2-0.3-4.2,0.2-6.2,1.1
                        c-1.3,0.6-2.5,1.2-3.8,1.7c-1,0.4-2,0.8-3,1.1c-1.5,0.4-3.1,0.7-4.5,1.5c-0.7,0.4-1.4,0.3-2.2,0c-0.4-0.2-0.8-0.3-1.2-0.5
                        c-0.8-0.5-1.6-0.3-2.4,0.1c-0.9,0.4-1.5,1.3-2.5,1.2c-0.9,0-1.9-0.2-2.8-0.4c-0.9-0.1-1.7,0.1-2.4,0.6c-0.7,0.6-1.5,1.3-2,2.1
                        c-1.4,2.2-3.4,3.1-6,2.9c-1.1-0.1-2.1-0.1-3.2-0.3c-2.5-0.5-5.1-0.4-7.6,0.1c-0.7,0.2-1.4,0.4-2.2,0.6c-1.5,0.3-2.9,0.7-4.4,0.7
                        c-1.3,0-2.5,0.3-3.8,0.5c-2.1,0.4-4.2,0.5-6.3-0.4c-0.8-0.3-1.6-0.5-2.5-0.4c-1.3,0.1-2.6-0.1-3.8-0.5c-1.7-0.6-3.3-0.3-4.9,0.6
                        c-0.6,0.4-1.3,0.8-1.9,1.2c-0.6,0.3-1.1,0.6-1.7,0.9c-0.5,0.3-1.2-0.1-1.2-0.6c0-0.8,0-1.7,0-2.6c0-32.5,0-64.9,0-97.4
                        c0-0.8,0-1.7,0.1-2.5c0-0.5,0.5-1,1-1c2.7,0,5.3,0,8,0c0.4,0,0.9,0.1,1.2,0.4c0.6,0.5,1.3,0.6,2.1,0.6c1.5,0,3,0,4.5,0
                        c0.7,0,1.2,0.3,1.5,1c0.2,0.6,0.6,0.8,1.2,0.8c0.5,0,1.1,0,1.6,0c0.8,0,1.2-0.3,1.2-1.1c0-0.8,0-1.7,0-2.6c0-3.4,0-6.8,0-10.2
                        c0-0.7,0-1.5,0.1-2.2c0-0.4,0.4-0.8,0.8-0.9c0.2,0,0.4-0.1,0.6-0.1c2.7,0,2.6-0.2,2.7-2.7c0-0.8,0.3-1.4,0.9-2
                        C562,711.4,562.4,710.9,562.8,710.4z"></path>
                        <path className="st4" d="M726.7,181.8c-0.4-0.1-0.8-0.2-1.2-0.3c-1.1-0.3-2.1,0-2.8,0.9c-0.2,0.3-0.4,0.6-0.6,0.7
                        c-0.2,0.2-0.5,0.3-0.8,0.3c-0.4,0.1-0.6-0.3-0.6-0.6c-0.1-0.9-0.4-1.9-0.3-2.8c0.2-1.8,0.3-3.6,0.3-5.4c0-0.8,0.4-1.4,0.7-2.1
                        c0.3-0.5,0.7-0.8,1.3-0.8c1.9-0.1,3.8-0.2,5.7-0.1c2,0.1,3.8-0.1,5.5-1.2c0.4-0.2,0.8-0.4,1.2-0.5c0.6-0.3,1.1-0.7,1.3-1.3
                        c0.4-1.4,1.3-2.1,2.6-2.6c0.5-0.2,1-0.4,1.4-0.8c1.3-1.3,3.1-1.8,4.5-3c0.1-0.1,0.5-0.1,0.6,0c0.3,0.3,0.6,0.6,0.8,1
                        c0.4,0.5,0.8,0.6,1.4,0.6c1.4-0.1,2.7-0.5,3.5-1.9c0.1-0.2,0.3-0.3,0.5-0.4c0.4-0.3,1.2-0.3,1.4,0.1c0.1,0.1,0.1,0.2,0.2,0.3
                        c0.2,0.6,0.5,1.2,1.3,1.3c0.6,0.1,1.1-0.1,1.4-0.5c0.7-0.8,1.7-0.9,2.6-0.8c0.5,0,1,0.2,1.6,0.3c0.4,0.1,0.8,0.2,1.2,0.4
                        c3.5,1.5,7.1,1.3,10.7,0.6c0.5-0.1,1-0.4,1.5-0.6c0.7-0.3,1.3-0.6,2-0.9c1-0.4,2-0.6,3.1,0c0.2,0.1,0.4,0.2,0.6,0.2
                        c0.6,0.1,1.3,0,1.8-0.4c0.6-0.5,1.1-1,1.7-1.4c0.9-0.6,1.9-0.9,3-0.4c0.2,0.1,0.4,0.2,0.6,0.2c1,0.2,1.9,0.2,2.7-0.6
                        c0.8-0.8,1.8-1,2.9-0.7c0.2,0.1,0.4,0.1,0.6,0.1c1.4,0.2,2.5,0,3.4-1.2c0.2-0.3,0.5-0.7,0.8-0.9c0.2-0.2,0.6-0.3,0.9-0.3
                        c0.4,0,0.8,0,1.3,0.1c0.8,0.1,1.5,0,2.2-0.4c0.4-0.2,0.8-0.4,1.2-0.5c0.6-0.3,1.2-0.3,1.9-0.2c0.5,0.1,1,0.3,1.6,0.4
                        c1.1,0.2,2.1-0.1,2.7-1.2c0.2-0.5,0.4-1,1-1.2c1,0.3,2,0.5,3,0.8c0.8,0.2,1.5,0,2.1-0.5c0.4-0.3,0.8-0.7,1.3-1
                        c0.7-0.5,1.5-0.4,2.1,0c0.3,0.2,0.5,0.4,0.7,0.6c1.8,1.4,3.7,2.5,5.9,2.9c1.1,0.2,2.3,0.5,3.4,0.7c0.2,0,0.4-0.1,0.6-0.3
                        c0.2-0.1,0.1-0.6-0.2-0.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.3-0.8-0.7-0.7-1.3c0.1-0.7,0.3-1.3,0.9-1.6c0.7-0.4,1.5-0.8,2.2-1.2
                        c0.9-0.7,1.4-1.6,1.2-2.8c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-1.3,0.3-2.1,1.5-2.9c0.8-0.5,1.7-0.4,2.3,0.3c0.4,0.4,0.7,0.8,1.1,1.2
                        c0.9,0.8,2.1,0.9,2.9,0c1-1.1,2.2-1.6,3.6-1.9c1.1-0.2,2.3-0.4,3.5-0.6c1-0.2,2.1-0.4,3.1-0.5c0.9-0.1,1.9-0.3,2.8-0.3
                        c1.1,0,2.1,0,3.1-0.4c0.8-0.3,1.7-0.2,2.5,0.1c0.7,0.2,1.4,0.6,2.1,0.8c0.5,0.2,1,0.2,1.5,0c0.6-0.3,1.1-0.6,1.7-0.8
                        c1.4-0.5,3.1,0.3,3.7,1.6c0.3,0.6,0.4,1.2,0.7,1.8c0.4,1,1.9,1.4,2.7,1c0.2-0.1,0.4-0.2,0.6-0.3c1.1-0.7,1.7-0.5,1.9,0.9
                        c0.2,1,0.8,1.5,1.7,1.6c0.5,0.1,1.1,0,1.6,0.2c1.2,0.6,2.5,0.4,3.8,0.3c1,0,1.9-0.2,2.7-0.8c0.4-0.3,0.9-0.6,1.4-0.8
                        c0.7-0.3,1.4-0.5,2.2-0.3c1.6,0.4,3.1,0.5,4.7,0.2c0.9-0.2,1.7,0.1,2.4,0.7c1.5,1.2,3.1,1.1,4.8,0.4c0.3-0.1,0.6-0.3,0.8-0.5
                        c0.9-0.5,1.9-0.4,2.7,0.1c0.5,0.2,0.9,0.6,1.3,0.9c0.6,0.3,1.1,0.6,1.7,0.8c1.3,0.4,2.5,0.4,3.6-0.6c0.5-0.5,1.1-0.5,1.7-0.1
                        c0.8,0.6,1.7,0.8,2.7,0.6c0.6-0.2,1.3,0,1.9,0.1c0.6,0.1,1.3,0.2,1.9,0.2c0.7,0,1.4-0.4,1.6-0.9c0.5-1.3,1.2-2.5,1.8-3.7
                        c0.2-0.5,0.3-1,0.5-1.4c0.4-0.2,0.6-0.4,0.9-0.4c1.3-0.3,2.4-0.8,2.9-2.2c0.3-0.7,0.9-0.8,1.6-0.8c0.4,0,0.9,0.1,1.1,0.5
                        c0.4,0.7,0.8,1.2,1.5,1.6c0.7,0.4,0.9,1.2,1.3,1.8c0.8,1.2,1.9,1.8,3.3,1.6c1.9-0.3,3.3,0.3,4.6,1.5c0.5,0.5,1.1,0.7,1.7,0.7
                        c0.5,0,1.1,0,1.6,0.2c1.5,0.7,2.8,0.5,4.3,0c0.8-0.3,1.7-0.3,2.5-0.4c0.6-0.1,1.3,0.1,1.8,0.5c0.7,0.4,0.8,1.4,0.3,1.9
                        c-0.8,0.9-1.8,1.4-2.8,2c-0.7,0.4-1.1,1-1.1,1.8c0,0.8-0.4,1.4-0.9,2c-0.8,1-0.7,2,0,2.9c0.7,0.9,1.3,1.9,1.9,2.9
                        c0.4,0.7,1.2,1.2,1.8,1.8c0.3,0.3,0.3,0.8,0,1.1c-0.4,0.4-0.9,0.9-1.5,1.4c-0.4-0.1-0.9-0.2-1.4-0.3c-0.6-0.1-1.1,0.1-1.4,0.5
                        c-0.7,1.1-1.8,1.9-2.5,2.9c-0.8,1.1-1.8,1.8-3.2,1.8c-0.8,0-1.3,0.6-1.4,1.5c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.5-0.5,0.9-1,1.2
                        c-1.2,0.6-2.4,0.9-3.7,0.6c-1-0.2-1.9-0.1-2.8,0.3c-0.5,0.2-1,0.3-1.5,0.4c-1.6,0.1-2.4,1.2-3,2.5c-0.2,0.4-0.2,0.8-0.3,1.2
                        c-0.1,0.5-0.2,1.1-0.5,1.5c-0.8,1.4-1.2,2.9-1.6,4.5c-0.2,0.7-0.5,1.4-0.9,2c-0.6,1-1.2,2-1.8,3c-0.4,0.6-0.4,1.2,0,1.8
                        c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.4,0,0.9-0.3,1.2c-0.5,0.4-1,0.8-1.5,1.2c-0.5,0.5-0.7,1.5-0.4,2.1c0.3,0.6,0.7,1.1,1,1.6
                        c0.6,1.1,0.6,2.3,0,3.4c-0.4,0.6-0.8,1.2-1.3,1.8c-0.7,0.8-0.8,1.7-0.7,2.7c0.1,0.6,0.4,1.2,0.5,1.9c0.2,0.8,0.1,1.5-0.4,2.1
                        c-0.5,0.6-0.9,1.2-1.4,1.7c-0.4,0.4-0.8,0.7-1.3,0.9c-0.7,0.3-1.3,0.7-1.7,1.3c-0.5,0.8-1.1,1.3-2,1.5c-0.9,0.2-1.5,0.7-1.9,1.6
                        c-0.4,0.9-1,1.3-2,1.3c-1,0-1.8,0.5-2.7,1c-0.8,0.4-1.4,1-1.5,2c-0.1,1.1-0.8,1.7-1.8,2c-0.3,0.1-0.6,0.2-0.9,0.3
                        c-0.6,0.3-1,0.8-1.1,1.5c0,1.4-0.4,2.7-1.1,4c-0.4,0.8-0.9,1.4-1.9,1.3c-0.4,0-0.8,0-1.3,0.1c-0.4,0.1-0.8,0.4-1,0.7
                        c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,1.2-0.6,2-2,1.9c-0.2,0-0.4,0-0.6,0.1c-0.8,0.3-1.5,0.7-2.1,1.4c-0.5,0.6-0.8,1.3-0.5,2.1
                        c0.1,0.2,0.1,0.4,0.2,0.6c0.3,1.2,0.2,1.5-0.8,2.1c-1.5,0.8-2.1,2.1-2.5,3.5c-0.2,1-0.4,2.1-0.7,3.1c-0.3,1-0.2,2.1,0.1,3.2
                        c0.1,0.5,0.3,1,0.4,1.5c0.1,0.5-0.3,1.1-0.8,1.2c-0.6,0.2-1.3-0.3-1.4-0.9c0-0.2,0-0.4,0-0.6c-0.1-1.3-1.4-2-2.5-1.3
                        c-0.3,0.2-0.5,0.3-0.8,0.6c-1.3,1.2-2.2,2.6-3.1,4c-0.4,0.6-0.6,1.4-0.7,2.1c-0.1,1.3-0.3,2.5-1.1,3.6c-0.5,0.6-0.1,1.5-0.3,2.2
                        c-0.1,0.4-0.3,0.8-0.7,1c-0.9,0.5-1.9,0.9-2.7,1.6c-0.2,0.2-0.6,0.2-0.9,0.1c-0.6-0.1-1.2-0.3-1.9-0.4c-0.6-0.1-1.3-0.1-1.9-0.1
                        c-0.7,0-1.2,0.3-1.7,0.8c-0.2,0.3-0.4,0.5-0.6,0.8c-0.3,0.3-1.1,0.4-1.5,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.9-1.1-2-1.8-3.5-1.6
                        c-0.3,0-0.6-0.2-0.9-0.4c-0.5-0.3-1-0.8-1.6-1.1c-0.4-0.2-0.8-0.2-1.2-0.1c-0.1,0-0.2,0.1-0.3,0.1c-1.6,1.4-3.5,1.9-5.5,2.5
                        c-0.3,0.1-0.6,0.2-0.9,0.2c-1.3,0.4-2.3,1.2-2.8,2.4c-0.9,2.3-1.8,4.6-2.1,7c-0.1,0.6-0.2,1.3-0.4,1.9c-0.2,0.6-0.7,1.1-1.3,1.3
                        c-0.2,0.1-0.4,0.1-0.6,0.1c-1.8,0.1-2.6,1.3-3.1,2.8c-0.2,0.7-0.3,1.5-0.3,2.2c0,1.6,0.1,3.2,0.2,4.8c0.2,1.9,0.3,3.8,0.3,5.7
                        c0,3.8,0.5,7.6,0.9,11.5c0.3,2.4,0.6,4.9,0.9,7.3c0.2,1.6,0.6,3.1,1.2,4.6c0.4,0.9,0.6,1.8,0.7,2.8c0.2,4.1,1.3,8,2.4,11.9
                        c0.8,2.8,1.7,5.5,2.5,8.2c0.1,0.3,0.2,0.6,0.2,0.9c0.1,0.5-0.3,1.1-0.8,1.2c-0.7,0.1-1.5,0.1-2.2,0.1c-1.1,0-2.1-0.1-3.2-0.1
                        c-0.4,0-0.9,0-1.3,0.1c-0.6,0.1-1,0.3-1.3,0.8c-0.3,0.4-0.6,0.9-0.9,1.3c-0.2,0.3-0.6,0.4-0.8,0.2c-0.4-0.3-0.9-0.6-1.3-0.9
                        c-0.5-0.3-1.4-0.1-1.8,0.3c-0.4,0.4-0.6,0.9-1,1.2c-0.6,0.6-1.4,0.9-2.3,0.8c-0.2,0-0.4-0.2-0.5-0.3c0-0.2,0-0.5,0.2-0.6
                        c0.6-0.4,1.2-0.8,1.8-1.2c0.1-0.1,0.2-0.4,0.1-0.6c-0.3-0.8-0.6-1.6-1.6-1.8c-0.9-0.1-1.4,0.4-1.9,0.9c-0.2,0.2-0.4,0.2-0.5,0
                        c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.4,0.1-0.6c-0.1-0.7-0.4-1.2-1.1-1.4c-0.6-0.2-1.3-0.2-1.8,0.3
                        c-0.3,0.4-0.6,0.8-1,1.2c-0.5-0.4-1-0.9-1.5-1.2c-0.8-0.6-2-0.4-2.6,0.4c-0.4,0.6-0.8,1.2-1.1,1.9c-0.4,0.8-1,1.4-1.7,1.8
                        c-0.5,0.2-1,0.4-1.4,0.7c-0.9,0.5-1,1.8-0.2,2.5c0.4,0.3,0.9,0.6,1.3,0.9c0.1,0.1,0.3,0.4,0.2,0.6c-0.1,0.3-0.3,0.6-0.6,0.7
                        c-0.5,0.1-1.1,0.1-1.6,0.1c-0.6,0-1.3-0.2-1.9-0.2c-0.8,0-1.4,0.3-2,0.9c-0.3,0.3-0.4,0.9-0.2,1.2c0.4,0.5,0.8,1,1.2,1.5
                        c0.6,0.6,0.6,1.3,0.5,2.1c-0.1,0.3-0.4,0.6-0.7,0.6c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.4-0.8-1-1.3-1.4c-0.5-0.4-1.4-0.1-1.6,0.5
                        c-0.2,0.4-0.3,0.8-0.4,1.2c-0.1,0.5-0.1,1.1-0.3,1.6c-0.2,0.7-1.2,1.2-1.8,0.8c-0.4-0.2-0.6-0.6-1-0.8c-0.5-0.3-1-0.3-1.5-0.2
                        c-0.7,0.2-1.2,0.5-1.3,1.2c-0.1,0.4-0.1,0.8-0.2,1.3c-0.1,0.4-0.6,0.7-1,0.5c-0.3-0.1-0.6-0.2-0.9-0.4c-0.5-0.4-1-0.8-1.5-1.1
                        c-0.5-0.4-1.6-0.1-1.9,0.6c-0.1,0.3-0.2,0.6-0.1,0.9c0.2,0.6,0.6,1.1,0.8,1.7c0.2,0.4,0,0.8-0.3,1.2c-0.3,0.4-1.1,0.5-1.4,0.1
                        c-0.3-0.3-0.5-0.7-0.8-1c-0.3-0.3-0.5-0.7-0.8-1c-0.5-0.5-1-0.8-1.7-0.8c-0.6,0-1,0.2-1.4,0.7c-0.3,0.4-0.2,0.8,0,1.2
                        c0.3,0.6,0.7,1.1,1,1.6c0.1,0.2,0.2,0.4,0.1,0.6c0,0.2-0.2,0.5-0.3,0.5c-0.3,0.1-0.6,0-0.9-0.1c-0.6-0.2-1.3-0.3-1.8,0.2
                        c-0.6,0.5-1.3,0.7-2,0.8c-2,0.3-3.2,1.6-3.5,3.6c-0.1,0.9-0.5,1.6-1.5,1.7c-0.3,0-0.6,0.2-0.9,0.3c-0.4,0.2-0.7,0.5-0.7,1
                        c-0.1,0.6-0.1,1.3-0.3,1.9c-0.1,0.7-1.1,1.2-1.8,1c-1.1-0.4-2.3-0.7-3.3-1.2c-1.3-0.8-2.5-1.1-3.9-0.7c-0.3,0.1-0.6,0-0.9-0.1
                        c-2.3-0.5-4.4-0.2-6.5,1c-0.4,0.2-0.8,0.3-1.2,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1-0.1-0.3,0-0.3c2.1-1.2,4.4-2.2,6.9-2.1
                        c0.5,0,1.1,0,1.6-0.1c0.2-0.1,0.6-0.4,0.6-0.6c0.1-1.7,0-3.4,0-5.1c0-0.3-0.5-0.8-0.8-0.9c-0.5-0.1-1.1-0.3-1.6-0.2
                        c-1,0.2-2.1,0.4-3.1,0.7c-0.7,0.2-1.3,0.7-1.8,1.3c-0.4,0.6-0.9,1.2-1.3,1.8c-1.3,1.9-3.1,2.9-5.4,2.9c-2.7,0-5.3,0-8,0
                        c-1,0-1.9-0.3-2.7-0.9c-1.1-0.8-2.1-1.7-3.2-2.7c-0.6-0.6-1.2-1.2-1.8-1.8c-0.5-0.4-1-0.8-1.7-0.8c-1.1,0-2.1-0.4-3-1
                        c-0.9-0.6-1.9-0.8-3-0.6c-3.5,0.6-6.9,1-10.4,1.6c-1.3,0.2-2.5,0.5-3.7,0.8c-2.2,0.6-4.4,0.9-6.6,0.9c-2.9,0-5.7,0.1-8.6,0.6
                        c-1.2,0.2-2.5,0.1-3.8,0.1c-0.7,0-1.3-0.1-1.8-0.5c-1.2-0.7-2.6-0.9-4-0.9c-0.5,0-1.1,0-1.6-0.1c-1.7-0.5-3.5-0.9-5.3-0.7
                        c-0.5,0-1-0.5-1.1-1c-0.1-0.5-0.2-1.1-0.2-1.6c-0.1-1.5-0.5-2.9-1.7-3.9c-0.2-0.2-0.1-0.6,0.2-0.7c0.1,0,0.2,0,0.3,0
                        c0.7,0,1.3-0.1,1.6-0.7c0.5-0.8,0.5-1.6,0.1-2.4c-0.2-0.4-0.5-0.7-0.7-1c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.2,0.3-0.7,0.5-0.7
                        c0.5,0,1.1,0.1,1.6,0.1c0.7,0,1.5-0.6,1.7-1.3c0.1-0.4,0.1-0.8,0.2-1.3c0-0.3,0.1-0.6,0.2-1c0.6-0.2,1.2-0.4,1.7-0.7
                        c0.6-0.3,1.1-0.7,1.2-1.4c0.1-0.6,0.2-1.3,0.8-1.7c1-0.8,1.2-2,1.5-3.1c0.4-1.6,0.2-3-1.1-4.1c-0.7-0.7-1.3-1.4-1.5-2.4
                        c-0.1-0.5-0.5-0.9-0.9-1.3c-0.3-0.3-0.6-0.6-0.8-1c-0.4-0.6-0.4-1.3,0-1.8c0.3-0.3,0.5-0.7,0.8-1c0.4-0.4,0.6-0.9,0.6-1.4
                        c0-0.9,0.2-1.7,0.6-2.4c0.5-1,0.1-1.9-0.5-2.6c-0.3-0.4-0.8-0.7-1.1-1.1c-0.3-0.4-0.7-0.8-0.9-1.3c-0.3-0.8-0.8-1.2-1.7-1.3
                        c-0.8-0.1-1.5,0.1-1.9,0.8c-0.1,0.1-0.1,0.2-0.2,0.2c-0.3,0.3-0.9,0.4-1.1,0.1c-0.2-0.2-0.3-0.6-0.3-0.8c0-0.2,0-0.4,0.1-0.6
                        c0.8-1.2,0.4-2.5-0.1-3.7c-0.4-0.9-1-1.6-1.6-2.4c-0.5-0.7-1.7-0.7-2.3,0c-0.5,0.7-1,1.4-1.6,2c-0.1,0.1-0.4,0.2-0.6,0.2
                        c-0.2,0-0.4-0.2-0.4-0.3c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.3-0.1-0.6-0.2-0.9c-0.2-0.7-0.9-1-1.5-0.7c-0.1,0-0.2,0.1-0.3,0.2
                        c-0.4,0.2-0.7,0.5-1.1,0.7c-1-0.1-1.9-0.2-2.9-0.3c-0.4-0.1-0.8-0.5-0.7-0.9c0.1-0.6,0.3-1.2,0.4-1.9c0.1-0.7-0.1-1.3-0.7-1.7
                        c-0.4-0.3-0.6-0.6-0.4-1.1c0.2-0.7,0-1.2-0.2-1.8c-0.3-0.8-0.2-0.9,0.6-0.9c0.4,0,0.9,0.1,1.2-0.2c0.6-0.6,0.6-1.8,0-2.3
                        c-0.7-0.5-1.4-1-2.1-1.5c-0.7-0.5-1.2-1.2-1.3-2.1c-0.1-0.8,0-1.4,1.1-1.5c0.6-0.1,1-0.3,1.3-0.7c0.5-0.8,1.1-1.6,2-1.9
                        c0.3-0.1,0.6-0.3,0.7-0.6c1-1.3,2.3-2.1,3.8-2.8c1.2-0.6,1.9-1.6,2.3-2.9c0.2-0.6,0.3-1.2,0.5-1.8c0.1-0.4,0.1-0.9,0.4-1.2
                        c0.9-1.3,1.1-2.7,0.9-4.3c-0.1-1,0.2-1.9,0.9-2.6c0.2-0.2,0.4-0.5,0.6-0.8c0.4-0.7,0.4-1.4,0-2.1c-0.1-0.3-0.3-0.6-0.6-0.8
                        c-1.2-0.6-1.2-1.6-1.2-2.7c0-0.5,0-1.1,0.1-1.6c0.1-0.4,0.2-0.9,0.5-1.1c1-0.9,1.5-2,1.8-3.3c0-0.1,0.1-0.2,0.1-0.3
                        c1.5-2.4,0.6-4.5-0.7-6.5c-0.5-0.7-1-1.3-1.2-2.2c-0.1-0.5-0.6-0.8-1.2-1c-0.8-0.2-1.7-0.2-2.5,0c-0.6,0.2-1.2,0.4-1.8,0.5
                        c-0.7,0.1-1.5,0.1-2.2-0.2c-0.8-0.3-1.2-0.8-1.2-1.7c0-0.6,0-1.3,0-1.9c-0.1-1.7-1.9-2.8-3.5-2.2c-0.4,0.1-0.9,0.3-1.1,0.6
                        c-0.9,1.1-2.2,1.2-3.5,1.4c-0.8,0.1-1.5,0-2.1-0.5c-0.4-0.3-0.9-0.6-1.4-0.7c-1.1-0.4-2.4,0-3.4-0.7c-1-0.6-2.2-0.8-3.4-0.8
                        c-0.4,0-0.9-0.2-1.1-0.5c-0.5-0.5-0.9-1.2-1.3-1.8c-0.1-0.2-0.1-0.7,0-0.9c0.4-0.7,0.9-1.4,1.4-2.1c0.3-0.4,0.5-1,1.1-1.1
                        c0.8-0.3,1.7-0.3,2.5-0.1c0.3,0.1,0.6,0.3,0.8,0.5c0.5,0.5,1,1.1,1.4,1.7c0.6,0.8,1.3,1,2.2,0.9c1,0,1.9-0.2,2.5-1.2
                        c0.6-1.1,1.6-1.7,2.7-2c0.5-0.2,1.1-0.1,1.4,0.4c0.9,1.6,2.3,1.7,3.8,1.8c0.2,0,0.4,0,0.6,0c1-0.3,1.9-0.1,2.8,0.2
                        c0.7,0.2,1.5,0,2.1-0.5c0.4-0.3,0.8-0.7,1.2-1c0.6-0.5,1.4-0.4,2,0.2c0.2,0.2,0.4,0.5,0.7,0.7c0.6,0.6,1.3,0.7,2,0.1
                        c0.8-0.6,1.7-0.7,2.7-0.3c0.4,0.2,0.8,0.2,1.2,0.3c0.5,0.1,1.1-0.1,1.5-0.5c0.4-0.4,0.8-0.7,1.1-1.1c0.5-0.5,1-0.7,1.7-0.6
                        c0.5,0.1,1,0.2,1.6,0.3c1.3,0.2,2.7-0.9,2.7-2.1c0-1.9,0.6-3.5,2-4.8c0.6-0.6,0.9-1.4,1.5-2.1c0.6-0.8,1-1.6,0.8-2.6
                        c-0.1-0.5,0.4-1.1,0.9-1.2c0.7-0.2,1.5-0.2,2.2-0.4c0.6-0.2,1.2-0.4,1.8-0.7c0.9-0.5,1.5-1.2,1.7-2.2c0.2-1.2,0.7-2.2,1.3-3.3
                        c0.7-1.3,1-2.8,1.7-4.1c0.2-0.4,0.2-1,0.2-1.6c0.2-2,0.5-2.3,2.5-2.8c0.9-0.2,1.9-0.4,2.7-1c0.7-0.5,1.6-0.7,2.4-0.7
                        c0.9,0.1,1.7-0.1,2.4-0.6c1-0.6,2-0.6,3,0.1c0.3,0.2,0.5,0.3,0.8,0.6c0.8,0.7,1.7,0.8,2.7,0.6c0.7-0.2,1.5-0.4,2.1-0.7
                        c1.5-0.8,2.4-2.1,2.4-3.9c0-1.9,0-3.8,0.7-5.6c0.1-0.3,0.1-0.6,0.1-0.9c0-0.7,0-1.5-0.3-2.2c-0.2-0.4-0.4-0.8-0.4-1.2
                        c0-1.5-0.7-2.6-1.9-3.5c-0.5-0.4-0.6-1.2-0.2-1.7c0.9-1.2,1.8-2.4,2.6-3.6c0.5-0.7,0.7-1.5,0.4-2.4c-0.1-0.4-0.4-0.8-0.8-0.9
                        c-0.4-0.2-0.9-0.2-1.2,0.1c-0.4,0.6-0.9,1.2-1.3,1.7c-0.1,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.3-0.3-0.5c0.1-0.6,0.3-1.2,0.5-1.8
                        c0.2-0.9,0.2-1.7-0.3-2.4C728.7,184.8,727.7,183.3,726.7,181.8z"></path>
                        <path className="st3" d="M842.5,502.6c-0.3,0.1-0.6,0.2-0.9,0.2c-0.8,0-1.7,0-2.6,0c-16.1,0-32.1,0-48.2,0c-1,0-1.9,0-2.9-0.1
                        c-0.4,0-0.8-0.4-0.8-0.8c-0.1-0.8-0.1-1.7-0.2-2.5c0-0.3-0.3-0.6-0.5-0.7c-0.5-0.4-1.4-0.2-1.6,0.4c-0.3,0.9-0.5,1.8-0.8,2.8
                        c-0.2,0.6-0.6,0.9-1.1,1c-0.4,0.1-0.8,0.1-1.3,0.2c-0.8,0.1-1.5,0.4-1.9,1c-0.5,0.7-1.1,0.9-1.9,1c-0.3,0-0.6,0.1-0.9,0.1
                        c-0.7,0.2-1.1,0.6-1.2,1.3c-0.1,0.3-0.2,0.6-0.4,0.8c-0.1,0.1-0.4,0.2-0.6,0.1c-0.3-0.1-0.5-0.3-0.7-0.6c-0.5-0.8-1.3-1-2.2-1
                        c-0.4,0-0.8,0-1.2,0c-0.2-0.2-0.4-0.3-0.5-0.5c-0.5-1.2-1.5-1.4-2.6-1.2c-0.5,0.1-1,0.3-1.4,0.8c-0.3,0.3-0.5,0.7-0.7,1
                        c-0.3,0.4-0.6,0.6-1.1,0.6c-0.4,0-0.8,0.1-1.3,0.1c-1.2,0-2,0.6-2.6,1.6c-0.2,0.4-0.4,0.7-0.7,1.1c-0.3,0.4-0.9,0.6-1.4,0.3
                        c-0.4-0.2-0.7-0.5-1.1-0.7c-0.4-0.1-0.8-0.2-1.2-0.1c-1.6,0.4-3,1.2-4.2,2.3c-0.4,0.3-0.6,0.8-0.9,1.3c-0.3,0.5-0.6,1.1-1,1.6
                        c-0.4,0.5-1,0.9-1.6,0.9c-0.5,0.1-1.1,0.1-1.6,0.1c-0.9,0-1.7,0.3-2.2,1c-0.4,0.5-0.9,0.9-1.6,0.9c-0.7,0-1.2,0.4-1.7,0.8
                        c-0.7,0.6-1.5,0.8-2.3,0.8c-0.5,0-1.1,0-1.6,0.1c-1,0.2-1.6,1.2-1.3,2.2c0.1,0.3,0.3,0.6,0.4,0.8c0.5,0.6,0.3,1.2-0.2,1.7
                        c-0.3,0.3-0.5,0.7-0.7,1c-0.2,0.5,0.1,1.4,0.5,1.7c0.7,0.5,1.6,0.6,2,1.5c0.3,0.6,0.8,1,1.2,1.4c0.5,0.5,1,1.1,1.2,1.8
                        c0.1,0.2,0.2,0.4,0.1,0.6c-0.1,0.3-0.2,0.7-0.5,0.8c-0.7,0.3-1.4,0.4-2.1,0.6c-0.9,0.3-1.4,1-1.6,1.9c-0.1,0.3-0.1,0.6-0.1,0.9
                        c0.2,1.4-0.4,2.2-1.8,2.5c-0.6,0.1-0.9,0.6-1,1.1c-0.1,0.3-0.1,0.7-0.2,0.9c-0.2,0.2-0.5,0.5-0.7,0.5c-1.4,0.2-2.4-0.4-2.7-1.6
                        c-0.1-0.2-0.1-0.4-0.1-0.6c0-1.4-0.8-2.4-1.8-3.2c-0.7-0.5-1.4-1-2-1.6c-0.5-0.4-0.8-1-0.7-1.7c0-0.8,0-1.7,0.2-2.5
                        c0.2-1,0-1.9-0.3-2.8c-0.2-0.7-0.4-1.4-0.7-2.1c-0.1-0.4-0.4-0.7-0.7-1.1c-0.4-0.4-0.9-0.5-1.4-0.3c-0.2,0.1-0.4,0.2-0.6,0.3
                        c-0.5,0.3-1,0.4-1.5,0.1c-0.9-0.6-1.8-0.5-2.7,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.7,0.4-1.4,0.5-2.1,0c-0.2-0.1-0.4-0.2-0.5-0.4
                        c-0.7-0.6-1.5-0.8-2.4-0.8c-0.9,0-1.7,0-2.6,0c-8.1,0-16.2,0-24.3,0c-1,0-1.9,0-2.9-0.1c-0.5,0-0.9-0.5-1-1.1c0-1-0.1-1.9,0-2.9
                        c0-3.4,0-6.8,0.1-10.2c0-0.2,0.2-0.3,0.3-0.4c0.2-0.2,0.4,0,0.5,0.2c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.2,0.5,0.6,0.7,0.6
                        c1.1,0.1,1.7,0.7,2.2,1.6c0.5,0.7,1.2,0.8,2,0.5c0.7-0.2,1.3-0.6,2-0.9c0.8,0.1,1.7,0.2,2.5,0.3c0.6,0,1.3-0.4,1.5-1
                        c0.2-0.7,0.4-1.4,0.7-2.1c0.3-0.7,1-1.2,0.8-2c-0.2-0.7-0.5-1.4-0.7-2.1c0.2-0.7,0.8-0.9,1.3-1.4c0.6-0.7,0.7-2,0.1-2.6
                        c-0.5-0.6-1-1-1.6-1.6c-0.7-0.7-0.1-1.9,0.6-2.1c0.6-0.2,1.2-0.3,1.8-0.5c0.4-0.1,0.7-0.5,0.8-0.9c0.5-1.4,0.5-2.7-0.2-4
                        c-0.1-0.2-0.3-0.3-0.3-0.5c-0.4-0.9-0.2-1.6,0.9-2c0.7-0.3,1.4-0.5,2.1-0.8c0.6-0.3,0.9-0.9,0.9-1.6c0-0.5-0.3-0.9-0.7-0.9
                        c-1,0-1.9-0.1-2.9-0.1c-5.6,0-11.3,0-16.9,0c-0.9,0-1.7,0-2.6-0.1c-0.5,0-0.8-0.5-0.6-0.9c0.9-1.2,1-2.8,2.1-3.9
                        c0.2-0.2,0.3-0.6,0.3-0.9c0.1-0.7,0.5-1.1,1.2-1.4c1.2-0.6,1.7-1.6,1.5-2.8c-0.1-0.9-0.7-1.5-1.5-1.9c-0.3-0.1-0.6-0.2-0.9-0.2
                        c-0.7,0-1.2-0.3-1.7-0.7c-0.4-0.5-1-0.8-1.4-1.3c-0.3-0.3-0.1-1.2,0.4-1.3c0.1,0,0.2-0.1,0.3-0.1c0.6-0.1,1.3-0.1,1.8-0.6
                        c0.6-0.6,0.8-1.4,1-2.3c0.2-1-0.2-1.9-0.2-2.8c0-0.5,0.4-1.1,0.9-1.1c0.1,0,0.2,0,0.3,0c1,0.2,1.6-0.4,2-1.2c0.3-0.6,0.8-1,1.4-1.3
                        c0.7-0.3,1.2-0.8,1.6-1.5c0.4-1,1.1-1.8,1.9-2.5c0.9-0.7,1.6-1.7,2.5-2.5c0.5-0.5,0.7-1.1,0.7-1.7c-0.1-3.7,0.9-7.2,1.8-10.7
                        c0.8-3.1,1-6.3,0.9-9.5c0-1-0.4-1.3-1.4-1.3c-1,0-1.9,0-2.9,0c-4.4,0-8.7,0-13.1,0c-0.6,0-1.3,0-1.9-0.1c-0.3,0-0.6-0.3-0.7-0.6
                        c-0.4-2.4-0.3-4.9-0.1-7.3c0-0.2,0.2-0.5,0.3-0.5c1.1-0.2,1.8-1.1,2.7-1.6c0.3-0.1,0.6-0.1,0.9-0.2c0.6-0.2,1.1-0.6,1.4-1.2
                        c0.4-0.6,0.4-1.2,0-1.8c-0.4-0.5-0.8-1-1.3-1.3c-1-0.6-1.5-1.5-2.1-2.3c-0.3-0.3,0.1-1.2,0.6-1.3c0.4-0.1,0.8-0.1,1.3-0.2
                        c0.8-0.1,1.6-1.1,1.4-2.2c-0.1-0.5-0.4-1-0.5-1.5c-0.2-0.7,0.1-1.1,0.9-1.4c0.6-0.2,1-0.4,1-1c0-0.6,0-1.3,0-1.9
                        c0-0.6-0.4-1-0.9-1.1c-1.1,0-2.1,0-3.2-0.1c-0.7,0-1.5-0.1-2.2-0.1c-0.3,0-0.5-0.5-0.3-0.7c0.8-0.8,1.1-1.8,1.1-2.9
                        c0-0.6,0-1.3,0.1-1.9c0-0.3,0.3-0.6,0.5-0.7c0.5-0.3,1.2-0.5,1.3-1.3c0-0.2,0.4-0.6,0.6-0.6c1.1-0.3,1.3-1,1.2-1.9
                        c0-0.6-0.1-1.3-0.1-1.9c0-0.2-0.3-0.4-0.4-0.4c-1.1-0.1-2.1-0.2-3.3-0.3c-0.1-0.4-0.4-0.8-0.4-1.2c-0.1-1-0.2-1.9-1.3-2.3
                        c-0.2-0.1,0-0.7,0.2-0.8c0.3-0.1,0.6-0.1,0.9-0.2c0.3-0.1,0.6-0.2,0.9-0.4c0.7-0.4,1.1-1.1,1-1.9c-0.1-1.2-0.3-2.3-0.6-3.5
                        c-0.1-0.5-0.4-1.1-0.9-1.2c-1.5-0.3-2.2-1.6-3.2-2.1c-0.2-1.1-0.4-2.1-0.7-3c-0.3-1-1-1.9-2.2-2.1c-0.8-0.1-1.4-0.5-1.8-1.2
                        c-0.3-0.5-0.8-0.8-1.3-0.8c-1.6-0.1-2.9-1.2-4.6-1.6c0.3-0.3,0.4-0.5,0.5-0.5c0.5,0,1.1,0.2,1.6,0.1c0.7-0.1,1.3-0.4,1.5-1.1
                        c0.2-1.1,0.7-1.9,1.5-2.7c0.3-0.4,0.4-0.9,0.6-1.4c0.3-0.7,0.5-1.4,0.8-2.1c0.2-0.4,0.6-0.7,1.1-0.6c1.7,0.4,3.1,0.4,4.3-1.1
                        c0.2-0.2,0.6-0.2,0.9-0.2c0.3,0,0.6,0,0.9,0.2c1,0.5,2,0.5,3.1,0c0.6-0.3,1.2-0.1,1.7,0.5c0.1,0.2,0.3,0.3,0.4,0.5
                        c0.5,0.7,1.2,0.8,2,0.8c0.5,0,1.1,0,1.5,0c1,0.5,1.5,1.4,2.2,2c0.1,0.4,0.2,0.7,0.2,1c0.2,1.2,0.3,2.3,0.5,3.5
                        c0.1,0.6,0.8,1.1,1.4,1.2c0.4,0,0.9,0.1,1.3,0c1.1,0,2.1,0,3.1,0.4c0.9,0.3,1.9,0.4,2.8,0.3c1.1,0,2.1,0.1,3.1,0.6
                        c1,0.6,2,0.8,3.1,0.7c1.8,0,3.6,0,5.4-0.2c2.6-0.3,5.3-0.5,8-0.5c1.6,0,3.2-0.2,4.7-0.6c3.5-1,7.1-1.4,10.7-2
                        c1.6-0.2,3.2-0.5,4.7-0.7c0.6-0.1,1.3,0,1.8,0.4c0.8,0.5,1.6,1.1,2.6,1.2c1.5,0,2.3,0.9,3.2,1.8c1.2,1.2,2.5,2.3,3.8,3.3
                        c0.8,0.7,1.8,1,3,1c3,0,6,0,8.9,0c2,0,3.6-1.1,5-2.5c0.5-0.5,0.9-1.2,1.3-1.8c0.9-1.3,2.1-2,3.7-2.2c0.5,0,1.1-0.1,1.6-0.1
                        c0.3,0,0.9,0.5,0.9,0.8c0,1.1,0,2.1,0,3.2c0,0.4-0.4,0.8-0.8,0.9c-0.2,0-0.4,0.1-0.6,0.1c-3,0-5.5,1.2-8.1,2.6c-0.4,0.2-0.4,1,0,1.4
                        c0.7,0.8,1.6,0.9,2.6,0.7c0.3-0.1,0.6-0.2,0.9-0.4c1-0.7,2.2-0.9,3.3-0.9c1.6,0,3.2,0,4.8,0.1c0.4,0,0.8,0.2,1.2,0.4
                        c1.1,0.4,2.1,0.9,3.2,1.4c0.7,0.3,1.4,0.4,2.2,0.2c0.8-0.1,1.4-0.6,1.6-1.4c0.2-0.7,0.2-1.5,0.3-2.1c0.2-0.2,0.4-0.4,0.6-0.4
                        c1.5-0.3,2.5-1.1,2.6-2.7c0-0.2,0.1-0.4,0.2-0.6c0.4-1.1,1.1-1.8,2.2-2.1c0.5-0.1,1.1-0.1,1.6-0.3c1.1-0.5,2.1-0.8,3.3-0.6
                        c1.1,0.1,1.9-1.2,1.4-2.3c-0.3-0.6-0.7-1.1-1-1.6c0-0.1,0-0.2,0-0.3c0.2-0.1,0.4-0.3,0.5-0.3c0.3,0.1,0.6,0.2,0.8,0.4
                        c0.4,0.5,0.8,1,1.1,1.6c0.4,0.6,1,0.8,1.6,1c0.4,0.1,0.8,0,1.2-0.3c0.9-0.6,1.2-1.5,0.9-2.5c-0.1-0.5-0.4-1-0.5-1.5
                        c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.3-0.3,0.5-0.1c1,0.7,1.9,1.4,3.1,1.5c0.6,0.1,1.3-0.3,1.4-1c0.1-0.5,0.1-1,0.2-1.6
                        c0.1-0.4,0.7-0.7,1-0.5c0.6,0.3,1.1,0.7,1.7,0.9c1.2,0.4,2.4-0.1,2.8-1.3c0.2-0.6,0.3-1.2,0.4-1.9c0.1-0.4,0.4-0.7,0.5-0.6
                        c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.3,0.4,0.7,0.7,1c0.1,0.1,0.4,0.2,0.6,0.3c0.7,0.2,2.1-0.8,2.2-1.5c0.2-1.2,0.2-2.3-0.9-3.2
                        c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2-0.2-0.3-0.8-0.1-0.9c0.3-0.2,0.5-0.3,0.8-0.4c0.5,0,1.1,0,1.6,0c0.7,0,1.5,0,2.2,0
                        c0.7-0.1,1.1-0.6,1.3-1.2c0.2-0.6,0.1-1.3-0.5-1.7c-0.4-0.3-0.8-0.6-1.3-0.9c-0.2-0.1-0.1-0.7,0.1-0.9c0.9-0.6,1.9-1,2.6-1.7
                        c0.7-0.7,1.1-1.8,1.7-2.7c0.3-0.5,1.3-0.6,1.7-0.2c0.2,0.2,0.5,0.4,0.7,0.7c0.3,0.3,0.5,0.7,0.8,1c0.2,0.2,1,0.2,1.2-0.1
                        c0.3-0.5,0.6-1.1,0.9-1.6c0.1-0.1,0.4-0.2,0.5-0.2c0.3,0.1,0.6,0.4,0.5,0.7c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3,0.9,0.6,1.7,1.5,1.6
                        c0.6-0.1,1-0.3,1.4-0.7c0.2-0.2,0.6-0.3,0.8-0.3c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.2,0.5,0.1,0.5c-0.4,0.4-0.8,0.6-1.2,1
                        c-0.3,0.3-0.5,0.7-0.5,1.1c-0.1,0.9,0.6,1.7,1.5,1.6c1.4-0.2,2.7-0.5,3.5-1.8c0.4-0.7,0.9-0.7,1.7-0.3c0.1,0,0.2,0.1,0.3,0.2
                        c1.4,0.7,2,0.6,2.9-0.7c0.2-0.3,0.3-0.6,0.5-0.8c0.3-0.3,0.7-0.5,1.1-0.6c0.6-0.1,1.3-0.1,1.9,0c1,0.1,1.9,0.3,2.9,0.3
                        c0.8,0,1.7-0.2,2.5-0.3c0.2,0.2,0.4,0.4,0.4,0.5c0.5,2.4,1,4.8,1.4,7.2c0.3,1.8,0.4,3.6,0.6,5.4c0.3,1.7,0.6,3.4,1,5
                        c0.5,1.9,0.8,3.7,0.9,5.7c0.2,2.8,0.4,5.5,0.7,8.3c0.1,1.2,0.4,2.3,0.7,3.4c0.5,1.7,0.5,3.3,0.2,5c-0.6,2.5-0.3,5.1-0.2,7.6
                        c0,0.8,0.3,1.7,0.7,2.4c1.1,2.5,1.7,5,2.1,7.7c0.3,2.3,0.9,4.6,1.6,6.8c0.7,2.1,1.2,4.3,1.7,6.5c0.1,0.6,0.2,1.2,0,1.9
                        c-0.1,0.5-0.3,1-0.3,1.6c0,0.5,0.1,1.1,0.6,1.4c0.2,0.1,0.3,0.3,0.5,0.4c1.3,0.6,1.6,1.6,1.3,2.9c-0.2,1.1-0.4,2.3-0.7,3.4
                        c-0.1,0.6-0.8,1.1-1.4,1c-0.5-0.1-1-0.2-1.5-0.4c-0.9-0.3-1.8-0.7-2.6-1.1c-0.6-0.3-1.2-0.2-1.9,0c-0.7,0.2-1.4,0.6-1.9,1.2
                        c-1.5,1.6-3.2,3.1-4,5.3c-0.3,0.9-1,1.3-2,1.3c-0.6,0-1.3,0-1.9,0c-0.8,0-1.5,0.1-2.2,0.4c-0.9,0.4-1.6,0.1-2.4-0.4
                        c-1.3-0.8-2.5-0.4-3.1,1c-0.5,1.1-0.8,2.2-1.1,3.3c-0.5,1.9-0.9,3.7-1.2,5.6c-0.1,0.8-0.1,1.7,0.1,2.5c0.2,1.1,0.3,2.1,0,3.2
                        c-0.2,1-0.1,1.9,0.3,2.8c0.5,1,0.6,2,0.6,3.1c0,2,0.1,4,0.2,6.1c0.3,4.8,0.8,9.5,1.8,14.2c0.6,2.7,1.5,5.3,2.3,7.9
                        c0.7,2.1,1.2,4.3,1.7,6.5c0.6,3,1.4,6,2.2,9C842.6,501.1,842.8,501.8,842.5,502.6z"></path>
                        <path className="st2" d="M684.1,246.1c-0.6-0.5-1.1-0.9-1.8-1.3c-0.8-0.5-1.6-0.4-2.4,0.1c-0.3,0.2-0.6,0.6-0.9,0.9
                        c-0.4,0.4-0.9,0.6-1.4,0.5c-1.9-0.4-3.8,0.1-5.8-0.2c-0.4-0.4-0.8-0.9-1.2-1.3c-0.6-0.7-1.4-0.9-2.3-0.7c-1.4,0.4-2.6,1-3.3,2.3
                        c-0.1,0.3-0.4,0.5-0.7,0.7c-0.6,0.5-1.8,0.4-2.4-0.2c-0.6-0.7-1.2-1.5-1.9-2.1c-0.6-0.6-1.4-0.9-2.3-0.8c-0.4,0.1-0.8,0.1-1.3,0.2
                        c-0.8,0.1-1.4,0.4-1.8,1.1c-0.4,0.6-0.7,1.3-1.2,1.9c-1,1.4-1.1,1.9-0.2,3.1c0.4,0.5,0.8,1,1.2,1.5c0.4,0.4,1,0.9,1.7,0.8
                        c1.2-0.1,2.3,0.2,3.3,0.9c0.6,0.4,1.2,0.4,1.8,0.4c1,0,1.9,0.1,2.7,0.8c0.5,0.5,1.1,0.5,1.8,0.5c1.9,0,3.6-0.2,5-1.6
                        c0.5-0.5,1.2-0.4,1.8-0.3c0.4,0.1,0.8,0.4,0.9,0.8c0.1,0.8,0.1,1.7,0.2,2.5c0.1,1.2,0.7,1.9,1.8,2.4c1.1,0.4,2.2,0.6,3.4,0.2
                        c0.7-0.3,1.4-0.4,2.2-0.5c0.6-0.1,1.4,0.3,1.6,0.8c0.4,0.8,0.8,1.5,1.4,2.1c0.7,0.8,1,1.9,1.3,2.9c0.1,0.4,0.1,0.9-0.1,1.3
                        c-0.4,1.1-0.8,2.2-1.2,3.3c-0.2,0.4-0.4,0.7-0.7,1c-0.9,0.9-1.3,1.9-1.3,3.2c0,0.4,0,0.9,0,1.3c-0.1,1.1,0.2,2.1,1.3,2.7
                        c0.2,0.1,0.3,0.3,0.4,0.5c0.3,0.4,0.5,1.1,0.1,1.5c-1.3,1.3-1.6,2.9-1.4,4.7c0.1,0.8,0,1.7-0.7,2.4c-0.4,0.4-0.6,0.9-0.6,1.5
                        c0,0.9-0.3,1.7-0.6,2.5c-0.4,1.1-1.1,1.8-2,2.4c-0.4,0.2-0.7,0.4-1.1,0.6c-0.8,0.4-1.5,0.8-2,1.5c-0.5,0.7-1.1,1.2-2,1.6
                        c-0.4,0.2-0.8,0.5-0.9,0.8c-0.5,1-1.3,1.3-2.3,1.5c-0.7,0.1-1.2,1-1.1,1.7c0.1,1.3,0.5,2.5,1.5,3.4c0.6,0.6,1.5,0.8,2,1.5
                        c0.1,0.2,0.1,0.5-0.1,0.5c-0.4,0.1-0.8,0.1-1.2,0.2c-0.8,0.2-1.2,0.9-0.9,1.7c0.1,0.2,0.2,0.4,0.3,0.6c0.3,0.5,0.2,1-0.1,1.5
                        c-0.3,0.4-0.1,0.8,0.1,1.2c0.2,0.2,0.4,0.5,0.7,0.6c0.7,0.5,0.8,1.2,0.5,2c-0.2,0.5-0.3,1-0.3,1.5c-0.1,0.4,0.5,1.1,0.9,1.2
                        c1.3,0.2,2.5,0.3,3.8,0.4c0.4,0,0.8-0.3,1.2-0.4c0.2-0.1,0.6,0.2,0.6,0.5c0.1,0.3,0.1,0.6,0.2,0.9c0.4,1,1.7,1.3,2.4,0.6
                        c0.7-0.7,1.2-1.4,1.9-2.2c0.2-0.2,0.7-0.2,0.9,0c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.5,0.7,1.1,1,1.6c0.2,0.3-0.1,0.9-0.4,1.1
                        c-0.1,0-0.2,0.1-0.3,0.1c-2.1,0.2-3.8,1.4-5.7,2.1c-0.6,0.2-1,0.8-1,1.5c0,2.2,0,4.5,0,6.7c-0.1,5.3,0.7,4.5-4.5,4.6
                        c-3.3,0-6.6,0-9.9,0c-0.5,0-1.1,0-1.6,0c-2.8-0.2-4.6,1.3-6,3.5c-0.6,1-1,2.1-1.5,3.1c-0.2,0.5-0.4,1-0.7,1.4
                        c-0.3,0.4-0.7,0.8-1.3,0.8c-1.2,0-2.3,0-3.5,0c-0.7,0-1.2,0.2-1.6,0.8c-0.4,0.6-1,1.1-1.7,1.3c-0.4,0.1-0.8,0.3-1.1,0.7
                        c-1.1,1.6-2.8,2.2-4.3,3.1c-0.6-0.5-1.2-1-1.8-1.3c-0.8-0.4-1.3-0.9-1.7-1.7c-0.4-0.7-1.1-1.2-1.7-1.9c0.3-0.6,0.6-1.1,0.9-1.7
                        c-0.2-1,0.2-1.9,0.5-2.8c0.2-0.6-0.5-1.9-1.2-2.1c-0.4-0.1-0.8-0.1-1.3-0.2c-0.9-0.1-1.5-0.7-1.7-1.7c0-0.2,0.1-0.5,0.2-0.6
                        c0.4-0.3,0.9-0.5,1.4-0.8c0.6-0.4,0.9-0.9,0.8-1.6c-0.1-0.9-0.8-1.6-1.6-1.7c-0.7-0.1-1.5-0.1-2.2,0.2c-0.5,0.2-1,0.4-1.5,0.6
                        c-0.6,0.3-1.2,0.4-1.8,0.4c-0.6,0-1,0.4-1.3,0.8c-0.3,0.3-0.6,0.6-0.9,0.8c-0.1,0.1-0.4,0-0.6-0.1c-0.3-0.2-0.5-0.4-0.7-0.7
                        c-0.9-1.1-1.5-1.2-2.7-0.4c-0.3,0.2-0.5,0.4-0.8,0.6c-0.6,0.3-1.2,0.4-1.8,0c-0.7-0.5-1.3-1-2-1.6c-0.1-0.1-0.3-0.4-0.2-0.6
                        c0-0.6,0.9-1.8,1.5-1.9c0.5-0.1,1.1-0.1,1.6-0.2c1.1-0.2,1.8-1.2,1.6-2.3c-0.1-0.6-0.3-1.2-0.5-1.8c-0.2-0.4-0.5-0.6-1-0.8
                        c-0.3-0.1-0.8,0.2-1,0.6c-0.3,0.6-0.3,1.2-0.9,1.6c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.4-0.3-0.4-0.4c0.2-0.4,0.4-0.7,0.7-1
                        c0.6-0.9,0.6-2.1,0-3c-0.3-0.4-0.6-0.6-1.1-0.6c-0.6,0-1.3,0-1.9,0c-0.2,0-0.5-0.5-0.4-0.7c0.1-0.2,0.2-0.4,0.4-0.5
                        c0.8-0.6,1-1.3,0.9-2.2c-0.1-0.8,0.1-1,0.8-1.2c0.4-0.1,0.8-0.2,1.2-0.3c0.9-0.4,1.1-1.3,0.3-2.1c-0.2-0.2-0.5-0.3-0.8-0.5
                        c-0.7-0.5-0.8-1.6-1.7-1.8c-0.7-0.2-1.5-0.1-2.2-0.2c-0.3,0-0.7-0.3-0.7-0.5c-0.3-0.9-0.8-1.7-1.3-2.5c-0.2-0.3-0.2-0.9-0.1-1.2
                        c0.5-0.9,0.6-1.8,0.1-2.7c0.1-1.7-0.4-3.4-0.4-5.1c-0.1-1-0.6-1.9-1.4-2.4c-1.1-0.6-1.8-1.5-2.6-2.3c-0.7-0.7-1.6-1.3-2-2.4
                        c-0.2-0.6-0.8-0.7-1.3-0.9c-0.5-0.2-0.9-0.6-1-1.1c-0.1-0.7-0.3-1.5-0.6-2.2c-0.1-0.4-0.5-0.7-1-0.7c-0.2,0-0.4,0-0.6,0
                        c-1.4-0.1-1.5-0.3-1.6-1.5c0-1.1,0-2.1,0-3.2c0-0.6,0.1-1,0.5-1.5c0.4-0.4,0.5-1,0.4-1.5c-0.5-2.1,0-4.2,0.1-6.3
                        c0.1-1,0.3-1.9,0.4-2.8c0.1-0.7,0.3-1.2,0.7-1.7c0.7-0.8,0.8-1.7,0.1-2.6c-0.2-0.2-0.4-0.5-0.5-0.8c-0.2-0.6-0.4-1.2-0.1-1.8
                        c0.6-1,0.6-2,0-3c-0.5-0.7-0.3-1.4,0.3-2c0.1-0.2,0.3-0.3,0.4-0.5c0.4-0.4,0.5-1,0.3-1.5c-0.1-0.5-0.3-1-0.5-1.5
                        c-0.4-0.9-0.5-1.8-0.5-2.8c0-0.9,0.3-1.6,1-2.2c0.8-0.6,0.8-1,0.4-1.9c-0.3-0.7-0.8-1.3-1.7-1.3c-0.8,0-1.4-0.2-1.9-0.9
                        c-0.2-0.3-0.7-0.6-1-0.7c-0.7-0.1-1.4-0.4-2-0.8c-0.7-0.4-1.4-0.3-2.1,0.2c-0.3,0.2-0.5,0.4-0.8,0.6c-0.8,0.5-1.6,0.5-2.5,0.3
                        c-0.7-0.2-1.4-0.5-2.1-0.8c-1.2-0.5-2.3-0.4-3.2,0.5c-0.7,0.7-1.5,1.2-2.5,1.2c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.5,0.4-0.8,0.6
                        c-0.5,0.3-1,0.4-1.5,0.3c-1.2-0.4-2.4-0.8-3.7-1c-0.5,0-0.9-0.5-1.3-0.8c-0.2-0.1-0.3-0.3-0.4-0.5c-0.6-0.8-0.9-2-1.9-2.5
                        c-0.9-0.4-1.8-0.5-2.8-0.8c-0.9-1.1-1.7-2.3-2.6-3.5c-0.7-0.9-2-1.2-3.2-0.8c-0.3,0.1-0.6,0.3-0.7,0.6c-0.2,0.3-0.4,0.8-0.5,1.2
                        c-0.5,1.4-2.6,1.5-3.5,0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.9-1.6-2.3-2.1-4.1-1.9c-0.5,0.1-1.1,0-1.5,0c-0.3-0.3-0.6-0.4-0.7-0.7
                        c-0.6-1.1-1.5-1.7-2.7-2.1c-0.3-0.1-0.6-0.3-0.8-0.4c-0.4-0.2-0.6-0.6-0.7-1c-0.1-1-0.1-1.9-0.1-2.9c0-6.7,0-13.4,0-20.1
                        c0-0.9,0-1.7,0-2.6c0-0.8-0.3-1.4-0.8-2c-1.1-1-2.1-2.1-3.2-3.2c-0.4-0.5-0.9-0.9-1.4-1.5c-0.2-3.4-0.1-6.9,0-10.3
                        c0.3-0.3,0.5-0.6,0.8-0.7c1.8-1,3.2-2.5,4.3-4.2c0.9-1.3,1.9-2.5,3.1-3.6c1-0.9,1.7-1.9,1.8-3.3c0-1.1,1-2,0.5-3.1
                        c-0.4-0.9-1.3-1.4-1.9-2.1c-0.9-0.9-0.5-2.6,0.7-3.3c0.3-0.2,0.6-0.1,0.9,0.1c0.5,0.4,0.9,0.9,1.4,1.3c0.4,0.3,1.1,0.3,1.5,0
                        c0.3-0.3,0.7-0.5,1-0.8c0.8-0.8,1.7-0.8,2.7-0.7c0.2,0,0.4,0,0.6,0.2c1,0.6,2.2,0.7,3.3,0.7c0.3,0.3,0.5,0.5,0.7,0.8
                        c1.3,2.2,3.3,3.6,5.6,4.6c1.1,0.5,2.5,0.6,3.7,0.9c1.9,0.4,3.7,0.8,5.6,1.3c0.5,0.1,1,0.5,1.3,0.8c1,1,2.2,1.3,3.5,1.2
                        c0.2,0,0.4,0,0.6-0.1c0.9-0.1,1.7,0,2.4,0.5c0.2,0.1,0.4,0.2,0.6,0.3c1.4,0.9,2.7,1.2,4.1,0c0.5-0.4,1.2-0.3,1.8,0
                        c0.1,0,0.2,0.1,0.3,0.2c1.7,1.3,3.5,1.4,5.4,0.8c1.3-0.4,2.5-0.2,3.8,0.2c1.2,0.4,2.4,0.8,3.6,1.3c2.5,1,5,0.9,7.5,0.1
                        c0.5-0.2,1-0.4,1.5-0.6c0.7-0.3,1.2-0.1,1.8,0.3c0.3,0.2,0.5,0.4,0.8,0.5c0.5,0.3,1.3,0.1,1.8-0.3c0.7-0.5,0.8-1.5,1.8-1.9
                        c0.3,0.5,0.5,1.1,0.8,1.6c0.2,0.4,0.7,0.5,1.1,0.4c0.1,0,0.2-0.1,0.3-0.1c0.6-0.3,1.2-0.4,1.7,0.2c0.4,0.4,0.8,0.8,1.3,0.9
                        c1.4,0.2,2.6,0.9,3.8,1.5c0.6,0.3,1.2,0.4,1.8,0.4c0.5,0,1.1,0,1.6-0.1c1.8-0.2,3.6-0.4,5.4-0.1c0.6,0.1,1.3-0.1,1.9-0.2
                        c0.6-0.1,1.3-0.2,1.9-0.2c1.1-0.1,2.1,0.2,3,0.7c0.9,0.5,1.8,1.1,2.8,1.6c0.7,0.3,1.5,0.2,2.1-0.2c0.7-0.5,1.3-1.1,1.5-2
                        c0.1-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.5,0.7-0.5c0.8-0.2,1.7-0.1,2.5,0.2c0.6,0.2,1.2,0.4,1.8,0.7c2.1,1.3,4.3,1,6.5,0.8
                        c0.4,0,0.8-0.3,1.1-0.6c0.4-0.3,0.8-0.6,1.2-0.9c0.9,0.1,1.6,0.4,2.3,0.3c1.3-0.3,2.5,0.1,3.7,0.5c1.1,0.4,2.3,0.5,3.4,0.6
                        c0.7,0.1,1.5-0.1,2.2-0.3c0.7-0.1,1.2-0.5,1.5-1.1c0.2-0.4,0.4-0.7,0.7-1.1c0.3-0.5,0.8-0.7,1.4-0.7c1,0,1.9,0.3,2.8,0.6
                        c1.3,0.5,2.6,1,3.9,1.4c0.6,0.2,1.3,0.1,1.8-0.4c1.3-1.1,2.8-1.4,4.5-1.1c0.8,0.2,1.5-0.1,2-0.8c0.3-0.3,0.5-0.7,0.7-1
                        c0.3-0.4,0.7-0.5,1.1-0.4c1.1,0.3,2.1,0.3,3.2,0.1c0.7-0.1,1.2,0.1,1.7,0.6c0.1,0.2,0.3,0.3,0.4,0.5c0.6,0.7,2,0.8,2.9,0.1
                        c0.3-0.2,0.6-0.4,0.7-0.6c0.6-0.9,1.5-1.5,2.6-1.6c0.6-0.1,1-0.4,1.3-0.9c0.3-0.4,0.5-0.9,0.8-1.4c0.4-0.5,0.7-1.1,1.1-1.5
                        c1.9-1.5,3.9-3,6-4.2c0.7-0.4,1.4-0.6,2.1-0.3c0.4,0.2,0.8,0.3,1.2,0.5c0.1,0,0.2,0.1,0.3,0.2c0.2,0.3,0.4,0.5,0.4,0.8
                        c0,1.1-0.1,2.1-0.1,3.2c0,1.2-0.1,2.3,0,3.5c0,0.5,0.2,1,0.4,1.5c0.4,0.9,1.7,1.3,2.4,0.6c0.5-0.4,0.9-0.9,1.3-1.4
                        c0.7-0.8,2-0.7,2.6,0c1.1,1.3,2,2.8,2.8,4.2c0.2,0.3,0.2,0.8,0.2,1.2c-0.1,0.7-0.5,1.4-0.6,2.1c-0.1,0.4,0.1,0.8,0.2,1.2
                        c0.4,0.8,1.5,1.1,2.2,0.5c0.3-0.3,0.6-0.6,0.8-0.9c0.2-0.2,0.4-0.5,0.6-0.7c0.1-0.1,0.2-0.1,0.3-0.1c0.3,0.5,0.3,1,0,1.5
                        c-0.8,1.2-1.8,2.4-2.6,3.6c-0.7,1-0.6,2.4,0.3,3.2c0.9,0.7,1.4,1.5,1.5,2.7c0,0.4,0.2,0.8,0.4,1.2c0.6,1.2,0.5,2.4,0.1,3.7
                        c-0.6,1.6-0.7,3.3-0.6,5c0,1.7-0.8,2.8-2.4,3.4c-0.5,0.2-1,0.3-1.5,0.4c-0.5,0.1-1.1,0-1.5-0.3c-0.3-0.2-0.5-0.3-0.8-0.5
                        c-1.2-0.9-2.6-0.9-3.9-0.4c-0.5,0.2-1,0.5-1.5,0.5c-1.4,0-2.7,0.4-3.9,1.1c-0.6,0.4-1.3,0.6-2.1,0.7c-0.6,0.1-1.2,0.4-1.8,0.6
                        c-0.5,0.2-0.8,0.6-1,1.2c-0.1,0.3-0.2,0.6-0.2,0.9c-0.2,2.5-1.4,4.7-2.3,6.9c-0.3,0.8-0.6,1.6-0.9,2.4c-0.5,1.6-1.7,2.4-3.2,2.7
                        c-0.4,0.1-0.8,0.1-1.3,0.1c-0.6,0-1,0.3-1.4,0.6c-0.5,0.4-1,0.9-0.9,1.6c0.2,1.1-0.3,1.8-1,2.6c-0.6,0.7-1,1.7-1.6,2.3
                        c-0.9,1-1.5,2.1-1.4,3.4c0,0.2,0,0.4,0,0.6c-0.2,1.2-1,1.9-2.2,1.7c-0.6-0.1-1.3-0.2-1.9-0.3c-0.5-0.1-1.1,0.1-1.5,0.5
                        c-1.6,1.9-2,1.8-4.2,1.3c-0.8-0.2-1.5-0.1-2.2,0.3C684.7,245.8,684.4,245.9,684.1,246.1z"></path>
                        <path className="st2" d="M423.8,833.5c-0.7,0.1-1.1,0.2-1.4,0.2c-1.7,0-3.4,0.3-4.9,1.2c-0.4,0.2-0.8,0.4-1.2,0.5
                        c-1.2,0.5-2.3,0.4-3.2-0.6c-2.2-2.3-4.9-3.2-8.1-2.9c-1.7,0.2-3.4,0.1-5.1-0.1c-1.3-0.2-2.6-0.2-3.8,0.2c-1.9,0.6-3.8,0.7-5.7,0.6
                        c-2.1-0.1-4,0.4-5.9,1.2c-0.4,0.2-0.8,0.3-1.2,0.5c-1.6,0.8-3.3,0.8-5,0.3c-0.6-0.2-1.2-0.3-1.8-0.5c-4.2-1.7-8.5-1.6-12.9-0.8
                        c-1.5,0.3-2.9,0.6-4.4,0.9c-3.1,0.6-5.9,1.7-8.6,3.3c-0.6,0.3-1.1,0.7-1.6,1c-1.7,1.1-3.5,1.5-5.5,1.2c-0.9-0.1-1.9-0.2-2.9-0.3
                        c-2.3-0.3-4.4,0.3-6.5,1.3c-2.2,1-4.2,2.5-6.2,4c-0.9,0.7-1.8,1.5-2.7,2.2c-2.4,2-5,2.2-7.7,0.8c-1.1-0.6-2.3-1.2-3.4-1.8
                        c-1.2-0.6-2.4-0.9-3.7-0.9c-1.3,0-2.5,0.1-3.7,0.9c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-3.1,1-4.8,0.2c-0.6-0.3-1.2-0.5-1.7-0.8
                        c-1.2-0.5-2.2-1.2-2.5-2.6c-0.4-1.8-1.7-3.2-3.4-4.1c-1-0.6-2-1.2-2.8-2.1c-0.3-0.4-0.6-0.9-0.6-1.6c0.8-0.6,1.6-1.2,2.4-1.9
                        c0.5-0.4,0.9-0.9,1.3-1.4c0.6-1,0.7-2.9-0.5-3.8c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.4-0.5-0.9-0.3-1.5c0.1-0.4,0.3-0.8,0.5-1.2
                        c0.2-0.5,0.2-1-0.1-1.5c-0.2-0.5-0.4-1-0.6-1.5c0-0.1,0.2-0.4,0.3-0.4c0.4-0.1,0.8-0.1,1.3,0c0.5,0,1.1,0.1,1.6,0c0.7,0,1.3-1,1-1.7
                        c-0.2-0.5-0.6-0.9-0.9-1.4c0.3-0.7,0.5-1.5,0.9-2.3c0.2-0.4,0.5-0.6,1-0.6c0.5,0,1.1,0.1,1.6,0c0.6-0.1,1.1-1.1,0.8-1.5
                        c-1.2-1.3-1.1-2.9-1-4.5c0-0.4,0.4-0.8,0.8-0.9c0.4-0.1,0.9-0.1,1.2-0.3c0.3-0.1,0.5-0.4,0.6-0.7c0.1-0.3,0.1-0.6,0.1-0.9
                        c0-3.9,0-7.9,0-11.8c0-0.3-0.1-0.6-0.1-0.9c0-0.4-0.5-0.7-1-0.6c-0.5,0.1-1,0.2-1.6,0.3c-2.4,0.6-4.7,1.2-7.1,1.9
                        c-1.2,0.4-2.3,0.3-3.4-0.2c-2.6-1-5.2-1.9-7.8-2.8c-0.7-0.2-1.4-0.5-2.1-0.5c-1.3-0.1-1.8,0.1-1.8,1.5c0,1.1,0,2.1,0,3.2
                        c0,1.7-0.1,2.1-2.3,2.1c-0.4,0-0.9,0-1.3-0.2c-0.3-0.1-0.5-0.4-0.7-0.6c-0.1-0.1-0.1-0.2-0.1-0.3c0-2.7-0.1-5.3,0.3-8
                        c0.8-5.3,1.5-10.5,2.2-15.8c0.4-3.3,0.9-6.5,1.3-9.8c0.1-0.9,0.4-1.6,1.2-2.1c0.7-0.4,1.1-1,1.4-1.7c0.6-1.3,1.6-2.2,2.8-3
                        c0.4-0.3,0.9-0.6,1.4-0.8c0.8-0.5,1.5-1.2,1.9-2.1c0.3-0.6,0.6-1.1,0.8-1.7c0.5-1.4,1.4-2.6,2.3-3.8c0.7-0.9,1.4-1,2.2-0.4
                        c0.3,0.2,0.5,0.5,0.8,0.6c0.9,0.3,1.9,0.5,2.7-0.3c0.5-0.5,1-0.8,1.7-0.7c0.3,0,0.6,0,1,0c1.1-0.1,1.6-0.9,1.5-2
                        c0-0.3-0.2-0.6-0.2-0.9c0-0.2,0.1-0.5,0.2-0.6c1.2-0.4,1.7-1.5,2.4-2.4c0.1-0.1,0.1-0.2,0.2-0.3c0.5-0.7,0.4-1.2-0.2-2
                        c-0.3-0.3-0.5-0.6-0.7-0.9c0-1.2,1.2-1.6,1.2-2.7c0-0.2,0.4-0.6,0.6-0.6c0.9-0.3,1.2-1.1,1.5-1.9c0.3-0.9,1.2-1.5,1.2-2.5
                        c0-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.6-0.2,0.9-0.4c0.9-0.5,1.1-1.3,0.5-2.1c-0.5-0.7-1-1.3-1.6-2c-2.3-2.7-4.5-5.4-6.8-8.1
                        c-0.4-0.5-0.8-1-1.1-1.5c0.1-0.6,0.6-1,1-1.3c1.5-1.2,3-2.3,4.5-3.6c1.9-1.7,3.8-3.5,5.6-5.3c0.8-0.8,1.5-1.8,2.1-2.8
                        c0.9-1.4,1.2-2.9,0.8-4.6c-0.2-0.8-0.4-1.6-0.7-2.5c-0.3-0.8-0.6-1.6-0.9-2.4c-1.6-3.9-1.6-7.9-0.6-11.9c0.9-3.6,3.3-6.1,6.5-7.9
                        c0.3-0.2,0.6-0.2,0.9-0.3c1.3-0.2,2.5-0.5,3.8-0.7c1.7-0.3,3.4-0.3,5.1,0.2c1,0.3,2.1,0.4,3.5,0.6c4.4,3.2,10,4.8,15.4,6.7
                        c1.8,0.6,3.7,1.1,5.5,1.5c4.2,1.1,8.3,2.7,12,5c2,1.3,4.2,2,6.5,2.5c1.7,0.3,3.4,0.5,5.1,0.7c1.2,0.2,2.3,0.4,3.4,0.6
                        c0.4,0.9,0.8,1.8,1.2,2.6c0.1,0.2-0.2,0.7-0.4,0.8c-0.4,0.1-0.8,0.3-1.2,0.4c-0.7,0.2-1,0.8-1.1,1.4c-0.1,0.3,0.1,0.7,0.3,0.9
                        c0.3,0.3,0.7,0.4,1.1,0.6c1.6,0.8,1.4,1.2,0.1,1.7c-0.3,0.1-0.5,0.4-0.7,0.6c-0.3,0.4-0.4,1.2-0.1,1.5c0.9,0.9,1.5,2.1,2,3.2
                        c0.2,0.4,0.1,0.9-0.3,1.2c-1.2,1.1-2.4,2.1-3.9,2.7c-0.8,0.4-1.1,0.9-1.3,1.7c-0.1,0.5-0.1,1.1-0.1,1.6c0,1.1-0.7,1.7-1.7,2
                        c-0.6,0.2-1.3,0.2-1.9,0.3c-0.4,0.1-0.8,0.2-1.2,0.5c-0.5,0.3-1,0.8-1,1.5c-0.1,1.2-0.1,2.3-0.2,3.3c0.3,0.7,0.8,1,1.2,1.2
                        c1.4,0.7,2.3,1.8,3,3.2c0.3,0.6,0.3,1.2,0,1.8c-0.2,0.4-0.5,0.7-0.6,1.1c-0.2,0.5-0.2,1-0.1,1.6c0.2,0.6,0.5,1.2,0.6,1.8
                        c0.1,0.8-0.1,1.5-0.7,2c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,0.8-1.9,1.4-3.1,1.2c-0.3,0-0.7,0-0.9,0.1c-0.2,0.1-0.6,0.4-0.6,0.6
                        c-0.1,0.8-0.1,1.7-0.1,2.5c0,10.6,0,21.3,0,31.9c0,1.8,0,3.6,0,5.4c0,1.5,0.5,1.6,1.7,1.6c1.2,0,2.3,0,3.5,0c1.1,0,2.1,0.3,3.1,0.7
                        c3,1.5,5.9,2.9,8.9,4.3c0.4,0.2,0.7,0.5,0.9,0.7c0,1.2-0.7,2.1-1,3.1c-0.2,0.7-0.5,1.4-0.7,2.1c-0.1,0.5,0.3,1.3,0.9,1.6
                        c0.5,0.2,1.1,0.3,1.4,0.6c0.9,0.8,2,1.2,3.2,1.2c0.8,0,1.7,0.2,2.5,0.2c1.3,0,2.2-1,2-2.3c-0.1-0.4-0.3-0.8-0.3-1.2
                        c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.5c0.8-0.3,1.5-0.9,1.9-1.6c0.3-0.4,0.7-0.8,1.1-1.2c0.3-0.3,0.8-0.3,1.2-0.1
                        c0.4,0.2,0.7,0.5,1.1,0.7c1.5,1,1.8,1,3.4-0.1c0.3-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6,0,0.9,0.2c0.3,0.1,0.5,0.4,0.4,0.8
                        c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.5,0,0.8,0.3,1.1c0.3,0.3,0.7,0.5,0.9,0.9c0.2,0.2,0.3,0.7,0.1,0.9c-0.7,0.8-1.2,1.7-1.5,2.7
                        c-0.1,0.3-0.6,0.6-0.9,0.8c-0.3,0.2-0.6,0.2-0.9,0.2c-0.6,0.2-0.8,0.6-0.9,1.2c0,0.2,0,0.4,0,0.6c0,3.6,0,7.2,0,10.9
                        c0,0.3,0,0.7,0.1,0.9c0.1,0.3,0.3,0.6,0.6,0.7c0.6,0.3,1.2,0.5,1.8,0.7c3.2,1.1,6.4,2.2,9.6,3.4c1.3,0.5,2.7,0.7,4.1,0.7
                        c3,0,6,0,8.9,0c0.7,0,1.5,0.1,2.2,0.1c0.3,0,0.4,0.1,0.4,0.4c0,0.2,0,0.5-0.1,0.5c-0.4,0.2-0.8,0.3-1.1,0.5c-1.3,0.7-2,2-1.8,3.4
                        c0.1,0.4,0.2,0.8,0.4,1.2c0.2,0.5,0,0.9-0.3,1.2c-0.9,0.8-1.8,1.5-2.7,2.2c-0.7,0.6-1,1.8-0.4,2.6c0.2,0.4,0.5,0.7,0.8,1
                        c0.5,0.6,0.9,1.2,1,2c0,0.3,0,0.7,0.2,0.9C423.7,829.3,423.4,831.3,423.8,833.5z"></path>
                        <path className="st4" d="M670.3,376.7c0.6,0.6,1.1,0.9,1.4,1.3c0.4,0.5,0.9,0.9,1.6,1c0.9,0.1,1.3,0.6,1.4,1.5c0.1,0.7,0.2,1.5,0.3,2.2
                        c0.1,0.9-0.2,1.4-1.1,1.6c-0.3,0.1-0.6,0.1-0.9,0.2c-0.6,0.2-1.1,0.7-1.2,1.4c-0.1,0.7,0.1,1.3,0.8,1.6c0.4,0.2,0.7,0.4,0.8,0.9
                        c0.1,0.6,0.1,1.3,0.2,1.9c0,0.2,0.3,0.4,0.5,0.4c0.9,0.1,1.9,0.2,2.9,0.2c0.4,0,0.8,0.4,0.9,0.8c0.1,0.6,0,1.3-0.5,1.4
                        c-0.7,0.2-1.4,0.5-1.5,1.4c0,0.2-0.4,0.4-0.6,0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,1.2-0.1,2.3-0.2,3.5c0,0.8-0.2,1.5-0.9,2
                        c-0.4,0.3-0.8,0.7-1,1.2c-0.2,0.5,0.2,1.2,0.7,1.2c1.5,0,3,0,4.5,0.1c0.5,0,1.1,0,1.6,0.1c0.4,0,0.7,0.4,0.7,0.9
                        c0,0.6-0.1,1-0.7,1.3c-0.3,0.1-0.6,0.3-0.8,0.4c-0.4,0.4-0.7,0.9-0.6,1.4c0.1,0.6,0.3,1.2,0.5,1.9c0.2,0.7-0.1,1.3-0.8,1.4
                        c-0.3,0.1-0.6,0.1-0.9,0.1c-1.2,0.1-2,2.1-1.1,3c0.9,0.9,1.6,2,2.8,2.6c0.3,0.1,0.4,0.5,0.6,0.7c0.2,0.3,0,1-0.2,1.2
                        c-0.3,0.2-0.5,0.4-0.8,0.4c-0.9,0.1-1.6,0.5-2.2,1.2c-0.5,0.6-1.4,0.4-2.1,0.7c-0.1,0.2-0.3,0.4-0.3,0.6c0,2.9,0,5.7,0,8.6
                        c0,0.4,0.4,0.8,0.8,0.8c1,0,1.9,0.1,2.9,0.1c4.7,0,9.4,0,14,0c0.6,0,1.3,0.1,1.9,0.2c0.2,0,0.3,0.3,0.5,0.4c0.1,0.1,0.1,0.2,0.1,0.3
                        c0.1,2.2,0.2,4.4-0.4,6.6c-0.6,2.4-1,4.8-1.6,7.2c-0.4,1.7-0.6,3.3-0.6,5.1c0,1-0.3,1.9-1,2.6c-0.5,0.5-1,1.1-1.6,1.6
                        c-1.1,0.9-2,1.8-2.6,3.2c-0.1,0.4-0.5,0.8-0.9,0.9c-1,0.3-1.6,1-2.1,1.9c-0.1,0.2-0.3,0.3-0.5,0.5c-0.4,0-0.8,0.1-1.2,0.1
                        c-1,0.2-1.6,0.8-1.6,1.7c0,0.5,0,1.1,0.1,1.6c0.2,0.9,0,1.7-0.2,2.5c-0.1,0.4-0.5,0.7-0.9,0.8c-0.4,0.1-0.8,0.1-1.2,0.2
                        c-0.7,0.2-1.1,0.7-1.1,1.4c0,0.5,0.1,1,0.1,1.4c0.4,0.8,1.1,1,1.5,1.4c0.8,0.8,1.7,1.2,2.8,1.2c0.4,0,0.8,0.3,1,0.7
                        c0.4,0.5,0.3,1.4-0.2,1.7c-0.2,0.1-0.4,0.2-0.6,0.3c-0.8,0.2-1.3,0.8-1.5,1.5c-0.3,0.9-0.5,1.9-1.4,2.4c-0.1,0.1-0.2,0.2-0.2,0.3
                        c-0.1,1.5-1.3,2.4-1.7,3.7c-1,0.5-1.9,0.3-2.9,0.3c-11.7,0-23.4,0-35.1,0c-1,0-1.9,0-2.9,0.1c-0.4,0-0.8,0.4-0.9,0.8
                        c-0.1,0.4-0.1,0.8-0.1,1.3c0,0.6-0.2,1-0.8,1.3c-0.3,0.1-0.6,0.3-0.8,0.5c-0.7,0.5-1.1,1.2-1.1,2.2c0.1,0.8,0.1,1.7,0,2.5
                        c0,0.8-0.8,1.4-1.5,1.3c-0.5-0.1-1-0.2-1.5-0.4c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.3-1-0.2-1.5,0c-1.1,0.5-2.1,1.1-2.5,2.3
                        c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.9,0.4-1.2,0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.7-0.7-1.6-1.1-2.6-1.1c-1,0-1.9-0.1-2.5-1
                        c-0.3-0.4-1-0.4-1.5-0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-0.7,0.4-1.4,0.5-2.1,0.4c-1.1-0.2-2.1-0.3-3.2,0c-1.1,0.3-2.2,0.6-3.2,1.4
                        c-0.9,0.8-2,0.7-3,0c-0.9-0.6-1.8-1-2.4-2.1c-0.3-0.7-1.1-1-1.9-0.9c-1.2,0.1-2.3,0.2-3.5,0.6c-0.5,0.2-1,0.4-1.4,0.7
                        c-0.6,0.3-0.8,1.4-1.7,1.4c-0.7,0-1.4-0.5-2.1-0.6c-1.4-0.1-2.2-0.9-2.6-2c-0.3-0.8-0.8-1.5-1.4-2.1c-0.5-0.5-0.7-1.1-0.7-1.7
                        c0-0.8-0.2-1.4-0.5-2.1c-0.4-0.8-0.6-1.6-0.9-2.4c-0.2-0.5-0.5-1.1-1-1.2c-1.2-0.2-1.7-0.8-1.5-2c0-0.2,0-0.5-0.1-0.6
                        c-0.7-1-0.5-1.7,0.4-2.5c0.3-0.3,0.4-0.8,0.2-1.2c-0.4-0.9-0.7-1.8-1.1-2.6c-0.3-0.6-0.7-1.1-1.4-1.2c-1.5-0.3-2.6-1.3-3.6-2.5
                        c-0.7-0.8-0.2-2.3,0.9-2.5c0.3-0.1,0.6,0,1-0.1c1-0.2,1.5-0.7,1.4-1.7c-0.1-1.7-0.4-2.8-1.5-4.8c0.5-0.7,1.1-1.5,1.7-2.3
                        c0.3-0.3,0.6-0.6,1-0.8c0.5-0.3,0.9-0.6,1.2-1c0.4-0.5,0.4-1.3,0-1.8c-0.2-0.2-0.5-0.4-0.7-0.6c-0.3-0.3-0.6-0.5-1-0.8
                        c0.5-0.7,0.8-0.9,1.2-0.8c1,0.4,1.8,1.1,2.9,1.4c0.8,0.2,1.5,0.2,2.1-0.3c0.5-0.3,0.6-0.8,0.6-1.4c0-0.2,0-0.5-0.1-0.6
                        c-0.7-1.2-0.6-2.5-0.6-3.7c0-0.5,0.2-1.2-0.1-1.6c-1.2-2,0-3.9,0-5.9c0-0.4,0.5-0.9,0.7-1.4c0.7-1.1,0.7-1.8-0.3-2.8
                        c-0.4-0.4-0.8-0.7-1-1c-0.1-1.2-0.3-2.3-0.4-3.3c-0.1-0.6,0.1-1.1,0.5-1.4c0.1-0.1,0.4-0.2,0.6-0.1c0.4,0.2,0.8,0.4,1,0.7
                        c0.3,0.6,0.4,1.2,0.6,1.8c0.4,1,1.3,1.4,2.3,1c0.8-0.3,1.3-0.9,1-1.7c-0.3-1-0.3-1.9,0.2-2.7c0.5-0.9,0.4-1.9-0.2-2.7
                        c-0.6-0.9-1.1-1.8-1.3-2.9c-0.2-1.2-1-2.1-1.8-2.9c-0.2-0.2-0.5-0.3-0.8-0.4c-0.4-0.1-0.8-0.2-1.2-0.4c-0.9-0.4-1.3-1.3-1.1-2.4
                        c0-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.4,0,0.6,0.1c0.3,0.2,0.5,0.4,0.7,0.7c0.7,1.3,1.9,1.2,3.1,1c0.4-0.1,0.8-0.4,0.9-0.9
                        c0.1-0.6,0.3-1.3,0.3-1.9c0-1.4,0.2-2.8,0.7-4.1c0.4-1-0.1-1.9-0.6-2.6c-0.1-0.2-0.3-0.3-0.5-0.4c-1.1-0.6-1.5-1.7-1.7-2.9
                        c-0.2-1.3-0.1-2.5-0.5-3.8c-0.1-0.5-0.1-1.1,0-1.6c0.3-1.7,0-3.4-0.5-5c-0.4-1.2-0.7-2.4-0.5-3.8c0.1-1.1-0.2-2.1-1-2.9
                        c-0.8-0.9-0.7-2-0.4-3c0.2-0.6,0.5-1.2,0.6-1.8c0.4-1.2,0.2-2.3-0.8-3.2c-0.2-0.1-0.4-0.3-0.4-0.5c-0.1-0.3-0.2-0.7-0.1-0.9
                        c0.6-0.9,1.3-1.6,1.5-2.7c0-0.2,0.4-0.6,0.7-0.6c1.6-0.1,2.5-0.9,3-2.4c0.2-0.7,0.8-0.9,1.5-0.7c0.8,0.3,1.4,0,2.1-0.2
                        c0.6-0.2,1-0.1,1.3,0.5c0.6,1.2,1.8,1.7,2.8,2.5c0.3,0.3,1.2,0.1,1.4-0.3c0.3-0.4,0.5-0.9,0.8-1.4c0.3-0.4,0.7-0.7,1-0.7
                        c0.3,0.1,0.4,0.7,0.2,1.1c-0.2,0.4-0.4,0.8-0.5,1.2c-0.2,0.8,0.6,1.7,1.5,1.7c0.2,0,0.4,0,0.6-0.1c1-0.5,1.8-0.2,2.5,0.7
                        c0.5,0.6,1.3,0.6,2.1,0.4c0.4-0.1,0.7-0.4,1.1-0.6c0.3-0.1,0.6-0.1,0.9,0.1c0.3,0.3,0.6,0.6,0.9,0.8c0.3,0.1,0.6,0.2,0.9,0.1
                        c0.3,0,0.6-0.2,0.9-0.4c0.8-0.6,1.7-0.8,2.7-0.6c0.3,0.1,0.6,0,0.9-0.1c0.6-0.1,0.9-0.5,1.1-1c0.1-0.4,0-0.8,0.1-1.2
                        c0-0.3,0-0.7,0.2-0.9c0.7-0.5,0.9-1.5,1.6-2c0.2-0.2,0.6-0.2,0.9-0.1c0.3,0.1,0.6,0.8,0.4,1.1c-0.2,0.3-0.4,0.6-0.6,0.7
                        c-0.7,0.5-0.9,1.1-1.1,1.9c-0.2,0.8,0.2,1.5,1,1.8c0.4,0.2,0.8,0.2,1.2-0.1c0.4-0.3,0.8-0.6,1.2-1c0.4-0.4,0.9-0.5,1.5-0.5
                        c0.7,0,1.5,0.1,2.2,0.1c0.4,0,0.9-0.4,0.9-0.8c0-1,0.1-1.9,0.1-2.9c0-4.3,0-8.5,0-12.8c0-0.8,0.3-1.1,1.1-1.3
                        c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.5-0.4,0.6-0.7c0.1-0.7,0.1-1.5,0.1-2.2c0-0.4,0.1-0.8,0.1-1.3
                        c0-0.3,0.5-0.8,0.9-0.8c0.6,0,1.3-0.1,1.9-0.1c1.7,0.1,3.2-0.4,4.3-1.7c0.5-0.6,1.1-1,1.8-1.3c1.1-0.5,2-1.2,2.6-2.3
                        c0.4-0.7,0.9-1.2,1.7-1.4c0.1,0,0.2-0.1,0.3-0.1c1-0.4,1.6-1.3,2.4-2.1c0.7-0.7,1.8-0.7,2.4-1.5c0.7-1,1.6-1.2,2.8-1.2
                        c0.6,0,1.3,0.1,1.9,0.1c1.2,0,2-0.5,2.5-1.6c0.5-1.1,1-2.1,1.4-3.2c0.6-1.3,1.5-2.3,2.6-3.2c0.3-0.3,0.7-0.5,1.1-0.5
                        c1.1-0.1,2.1-0.2,3.2-0.2c4.2,0,8.3,0,12.5,0c3.5,0,3.3,0.1,3.3-3.3c0-2.8,0-5.5,0-8.3c0-0.7,0.3-1.2,1-1.5c0.4-0.1,0.9-0.2,1.2-0.4
                        c1.3-0.9,2.8-1,4.2-1.3c0.1,0,0.4,0.3,0.4,0.4c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.7-0.1,1.3,0.3,1.8c0.4,0.7,1.5,1.1,2.2,0.6
                        c0.4-0.2,0.7-0.5,1-0.8c0.8-0.7,1.3-0.7,1.8,0.1c0.6,1,1.4,1.9,2.2,2.7c0.5,0.5,0.6,1.1,0.4,1.8c-0.1,0.3-0.3,0.6-0.4,0.9
                        c-0.1,1.5-0.6,2.9-1.7,4c-0.6,0.6-0.5,1.4-0.2,2.1c0.1,0.4,0.4,0.8,0.7,1.1c0.8,0.8,1.3,1.7,1.7,2.6c0.3,0.6,0.9,1.2,1.4,1.7
                        c0.6,0.7,0.9,1.4,0.8,2.3c-0.1,0.4-0.1,0.8-0.2,1.3c-0.1,0.8-0.4,1.4-1,1.9c-0.6,0.5-0.9,1.1-1,1.9c-0.1,0.7-0.5,1.1-1.2,1.4
                        c-0.1,0-0.2,0.1-0.3,0.1c-1.4,0.3-1.9,1.2-1.8,2.5c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.7,0.7-1,0.7c-0.2,0-0.4-0.1-0.6-0.2
                        c-0.7-0.2-1.3-0.2-1.7,0.4c-0.6,0.7-0.9,1.5-0.4,2.3c0.3,0.6,0.7,1.1,1,1.6c0.3,0.4,0.1,0.8-0.1,1.2c-0.2,0.3-0.5,0.5-0.8,0.4
                        c-0.9-0.3-1.6-0.2-2.4,0.3c-0.3,0.2-0.8,0.3-1.2,0.4c-0.4,0.1-0.9,0-1.2-0.3c-0.3-0.2-0.5-0.4-0.7-0.7c-0.8-0.9-1.7-1.3-2.8-0.8
                        c-0.7,0.3-1.4,0.3-2.2,0.1c-0.5-0.1-1-0.2-1.6-0.3c-0.6-0.1-1.1,0.1-1.5,0.4c-0.2,0.2-0.4,0.5-0.7,0.7c-0.5,0.5-1.1,0.6-1.8,0.3
                        c-0.8-0.4-1.4-0.2-2.1,0.1c-0.5,0.2-0.9,0.6-1.1,1.1c-0.2,0.5-0.4,1-0.5,1.5c-0.2,1-0.7,1.8-1.4,2.4c-0.3,0.3-0.4,0.7-0.6,1.1
                        c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.7,0.7-1,0.6c-0.4-0.1-0.8-0.2-1.2-0.3c-0.4-0.1-0.8,0-1.2,0.2c-0.6,0.3-0.8,1.1-0.3,1.6
                        c0.3,0.3,0.6,0.6,1,0.8c1.1,0.5,2.1,1,3.3,1.3c1,0.3,2,0.6,2.6,1.6c0.4,0.6,1.1,0.6,1.7,0.7c0.6,0.1,1,0.5,1.2,1
                        c0.3,0.7,0.5,1.4,0.7,2.1C670.3,375.6,670.2,376.1,670.3,376.7z"></path>
                        <path className="st5" d="M479.5,740.6c0.7,0,1.2,0.1,1.8,0.1c5.2,0,10.4,0,15.6,0c0.6,0,1.3,0,1.9-0.1c0.3-0.1,0.5-0.4,0.7-0.6
                        c0.1-0.1,0.1-0.2,0.1-0.3c0.2-2.3-0.2-2.3,2.7-2.3c1.2,0,2.3,0,3.5,0c0.8,0,1.1-0.4,1.1-1.2c0.1-0.8,0.1-1.7,0.2-2.5
                        c0-0.3,0.6-0.8,0.9-0.8c0.7,0,1.5-0.1,2.2-0.1c2.2,0.2,4.2-0.3,6.2-1.3c1.7-0.8,3.5-1.5,5.3-2.2c0.5-0.2,1-0.3,1.5-0.4
                        c0.9-0.2,1.2,0,1.6,1c0.3,0.7,0.7,1,1.4,1c1.3,0,2.6,0,3.8,0c0.7,0,1.5,0,2.2,0.1c0.4,0,0.8,0.4,0.8,0.8c0,0.6,0.1,1.3,0.1,1.9
                        c0,2.1,0,4.3,0,6.4c0,30.3,0,60.7,0,91c0,1.2-0.1,2.3-0.2,3.5c0,0.2-0.5,0.5-0.8,0.3c-0.4-0.2-0.8-0.4-1.1-0.6
                        c-1.4-0.8-2.9-1.2-4.6-1c-1.3,0.2-2.5,0-3.7-0.7c-0.7-0.5-1.6-0.6-2.4-0.6c-0.4,0-0.9,0-1.3,0c-1.6,0.1-3.2-0.2-4.6-1.1
                        c-0.4-0.3-0.9-0.5-1.4-0.7c-1-0.4-2-0.5-3.1-0.3c-3.6,0.5-7.2,1-10.7,1.5c-1,0.1-1.9,0.4-2.6,1.1c-1.9,1.8-2.7,1.8-4.9,0.9
                        c-1-0.4-2-0.5-3.1-0.1c-2.3,1-4.8,0.8-7.2,0.7c-0.6,0-1.2-0.3-1.8-0.6c-0.7-0.3-1.3-0.7-2-1c-1-0.4-2.1-0.4-3.1,0.1
                        c-0.4,0.2-0.8,0.4-1.1,0.6c-2.3,1.4-4.9,2-7.6,2.1c-1.8,0.1-3.6,0.2-5.4,0.2c-1.7,0-3.4,0.3-5,1c-0.3,0.1-0.6,0.2-0.9,0.3
                        c-2,0.9-4,1.2-6.2,1.1c-2.2-0.1-4.4,0.1-6.7,0.5c-1.9,0.4-3.8,0.4-5.7,0.6c-0.6,0.1-1.3,0-1.9-0.1c-0.8-0.1-1.4-0.4-1.9-1
                        c-0.5-0.6-1-1.1-1.5-1.7c-1.2-1.2-2.6-2.2-4.5-2.2c-0.9,0-1.3-0.6-1.6-1.3c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.9-0.2-1.7-0.6-2.4
                        c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.8-0.3-1.7-0.7-2.4c-0.3-0.7-0.9-1.1-1.4-1.7c-0.2-0.2-0.1-1,0.2-1.2c1-0.8,2-1.6,2.9-2.4
                        c0.6-0.5,0.8-1.2,0.5-2c-0.1-0.3-0.3-0.6-0.4-0.9c-0.3-0.9,0.3-2.3,1.2-2.7c0.5-0.2,1-0.3,1.5-0.5c0.5-0.2,0.8-0.8,0.7-1.3
                        c0-0.6-0.6-1.3-1.2-1.3c-0.6-0.1-1.3-0.1-1.9-0.1c-3,0-6-0.1-8.9,0c-1.8,0-3.6-0.3-5.3-0.9c-2.7-1-5.4-1.9-8.1-2.9
                        c-0.8-0.3-1.6-0.6-2.2-0.8c-0.5-0.6-0.5-1.1-0.5-1.7c0-3.2,0-6.4,0-9.6c0-0.8,0.2-1.5,1.1-1.8c1-0.4,1.6-1,1.8-2
                        c0.1-0.8,0.8-1.2,1.3-1.8c0.5-0.5,0.5-1.1,0.1-1.7c-0.2-0.3-0.4-0.5-0.6-0.7c-0.8-0.5-0.7-1.2-0.4-1.9c0.2-0.5,0.1-1-0.3-1.5
                        c-0.3-0.3-0.6-0.6-0.8-1c-0.4-0.8-0.2-1.6,0.4-2.3c0.2-0.2,0.5-0.4,0.7-0.6c0.5-0.7,1.2-1.2,2.1-1.4c0.6-0.2,1.2-0.4,1.8-0.7
                        c1-0.4,1.8-1,2.1-2.2c0.1-0.4,0.3-0.8,0.4-1.2c0.2-0.6,0.6-1.1,1.2-1.5c0.9-0.6,1.7-1.2,2.6-1.8c0.4-0.3,1.2,0.1,1.2,0.6
                        c0.1,0.5,0.1,1.1,0.1,1.6c0,0.5-0.2,1-0.3,1.6c-0.1,0.8,0.9,1.6,1.7,1.5c0.6-0.1,1.3-0.2,1.7-0.7c0.8-0.8,1.6-0.8,2.6-0.6
                        c1.8,0.3,3.2-0.8,3.2-2.6c0-0.3-0.1-0.6-0.1-1c0-1,0.2-1.4,1.2-1.5c0.6-0.1,1.3-0.1,1.9,0c0.5,0.1,1,0.2,1.6,0.2
                        c1,0.1,1.9-0.2,2.5-1.1c0.7-1,1.7-1.5,2.8-2.1c1.2-0.6,2.1-0.6,3.2,0.2c1.4,0.9,2.9,0.6,4-0.7c0.3-0.3,0.5-0.7,0.7-1
                        c0.9-1,1.6-1.2,2.8-0.7c0.3,0.1,0.6,0.3,0.9,0.4c0.6,0.3,1.2,0.3,1.8-0.1c0.3-0.2,0.5-0.4,0.8-0.5c0.4-0.2,0.9-0.2,1.2,0
                        c0.5,0.4,1.1,0.6,1.8,0.6c0.7,0,1.3-0.2,1.6-0.8c0.5-0.8,1-1.7,1.5-2.5c0.2-0.2,0.5-0.5,0.7-0.5c0.9-0.2,1.9-0.2,2.8,0
                        c0.4,0.1,0.5,0.4,0.6,0.7c0.1,1.1,0.1,2.1,0.1,3.2c0,0.8,0,1.7,0.1,2.5c0,0.6,0.8,1.1,1.4,1.1c0.7-0.1,1.2-0.3,1.7-0.8
                        c0.4-0.4,0.6-0.8,1-1.2c0.7-0.7,1-1.6,0.9-2.6c-0.1-0.9,0.1-1.7,0.9-2.2c0.8-0.5,1-1.3,1-2.2c0-2.8,0-5.5,0-8.3c0-1,0-1.9,0.1-2.9
                        c0-0.4,0.5-0.6,0.9-0.4c0.5,0.2,0.9,0.6,1.4,0.7c0.8,0.3,1.8-0.2,2-1c0.1-0.4,0.1-0.9,0.2-1.2c0.1-0.3,0.4-0.6,0.6-0.7
                        c1.3-0.3,2-1.1,2.3-2.4c0.1-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.3,0.5-0.3c0.4,0,0.8,0,1.3,0c1.2,0.1,1.7-0.4,1.9-1.6
                        c0.1-0.5,0.1-1.1,0.2-1.6c0-0.2,0.3-0.4,0.4-0.4c0.3,0,0.6,0,0.9,0.1c0.2,0.2,0.4,0.5,0.6,0.7C479,739.9,479.2,740.1,479.5,740.6z"></path>
                        <path className="st6" d="M491.5,618.2c0.4-0.6,0.8-1.2,1.1-1.9c0.8-1.7,1.2-3.4,0.9-5.2c-0.4-2.2-0.6-4.4-0.7-6.7
                        c-0.1-1.3-0.4-2.5-0.8-3.7c-1.6-5.3-1.9-10.7-1.2-16.1c0.2-1.7,0.8-3.3,1.2-5c0.1-0.4,0.3-0.8,0.5-1.1c0.8-1.3,1.7-2.4,2.9-3.4
                        c0.8-0.7,1.6-1.4,2.4-2.2c0.5-0.5,0.9-1.2,1-2c0.2-0.8-0.3-1.5-1.2-1.5c-2.2,0-4.5,0-6.7,0c-0.4,0-0.8-0.1-1.3-0.2
                        c-0.2,0-0.4-0.3-0.4-0.4c-0.1-0.5-0.2-1-0.2-1.6c-0.1-1,0-1.9-0.1-2.9c0-0.3-0.3-0.6-0.5-0.7c-1-0.4-1-1.3-0.9-2.1
                        c0-0.4,0.4-0.9,0.8-0.9c0.5,0,1.1-0.1,1.6-0.1c0.4,0,0.8,0,1.3-0.1c0.6-0.1,1-0.4,1.2-0.9c0.2-0.5,0.3-1,0.4-1.5
                        c0.1-0.4,0.4-0.7,0.9-0.7c0.9,0,1.7-0.1,2.4,0.5c0.7,0.6,1.5,0.7,2.3,1.1c0.9,0.4,1.8,0.6,2.8,0.6c1.3,0,2.6-0.1,3.8,0
                        c1.9,0.2,3.5-0.5,5.2-1.3c0.4-0.2,0.7-1,1.3-0.7c0.3,0.1,0.5,0.7,0.7,1c0.6,0.7,1.7,0.7,2.2-0.1c0.2-0.3,0.3-0.6,0.3-0.9
                        c0.1-0.5,0.3-0.8,0.8-0.8c0.4-0.1,0.8-0.1,1.3-0.1c0.5-0.1,0.8-0.3,1-0.7c0.3-0.7,0.6-1.4,0.9-2c0.1-0.3,0.3-0.6,0.6-0.8
                        c0.3-0.3,0.7-0.5,1.1-0.7c0.4,0.1,0.8,0.2,1.2,0.4c1.2,0.6,2.2,0.4,3-0.8c0.3-0.4,0.8-0.7,1.2-1.1c0.5-0.5,0.7-1.1,0.5-1.7
                        c-0.2-0.7-0.1-1.2,0.6-1.6c0.7-0.5,1.1-1.3,1.1-2.2c0-0.3,0-0.6,0.1-1.1c2-1.2,3-3.1,3.3-5.4c0-0.3,0.2-0.6,0.4-0.9
                        c0.3-0.5,0.7-1.1,1.1-1.6c0.5-0.7,1.2-0.8,1.9-0.2c0.3,0.3,0.6,0.6,0.9,0.8c0.9,0.7,1.7,0.8,2.6,0.2c0.5-0.3,0.9-0.6,1.3-0.9
                        c0.3-0.1,0.7-0.2,0.9-0.1c2,1.1,4.1,0.8,6.2,0.1c0.7-0.2,1-0.1,1.4,0.4c0.5,0.7,1,1.4,1.5,2c0.4,0.5,1.1,0.7,1.7,0.7
                        c0.7,0,1.2-0.3,1.5-0.9c0-0.1,0.1-0.2,0.1-0.3c0.8-1.8,0.9-1.9,2.7-1c0.4,0.2,0.8,0.4,1.2,0.5c0.6,0.2,1.3,0.2,1.8-0.3
                        c0.7-0.7,1.3-1.4,2.3-1.7c0.7-1.3,1.8-2,3.2-2.4c0.4-0.1,0.7-0.5,1-0.8c0.3-0.3,0.5-0.7,0.7-1.1c0.5-0.8,1.1-1.5,2-2
                        c1.2-0.6,1.5-2.1,0.9-3.3c-0.3-0.6-0.7-1.1-0.9-1.7c-0.2-0.4-0.1-0.8,0.1-1.2c0.2-0.4,0.8-0.5,1.1-0.3c0.4,0.2,0.7,0.5,1.1,0.6
                        c0.5,0.2,1,0.3,1.5,0.4c0.8,0.1,1.5-0.1,2.1-0.6c0.1-0.2,0.3-0.3,0.5-0.4c1.3-1,1.9-1,2.9,0.3c0.4,0.5,0.8,1,1.4,1.2
                        c0.8,0.1,1.3,0.7,1.9,1.1c0.8,0.5,2.3,0.2,2.9-0.6c0.1-0.2,0.3-0.3,0.4-0.5c0.5-1.2,1.4-2.1,2.6-2.8c0.8-0.4,0.9-1.1,0.8-1.9
                        c-0.1-0.9-0.2-1.9-0.6-2.8c-0.3-0.7,0.2-1.6,0.9-1.7c0.2,0,0.4-0.1,0.6-0.1c1.4,0.2,2.5-0.3,3.5-1.2c0.6-0.6,1.3-0.7,2-0.2
                        c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.9,0.2,1.7,0.1,2.2-0.8c0.5-0.7,1.1-0.9,1.9-0.7c0.6,0.2,1.3,0.2,1.9,0.3
                        c0.8,0,1.5-0.3,1.9-1c0.6-1.1,1.1-2.3,1.5-3.5c0.3-1,0.1-1.9-0.8-2.6c-0.9-0.6-1.6-1.3-2.1-2.4c-0.2-0.6-0.7-1.1-1.1-1.6
                        c-0.3-0.5-0.9-0.6-1.4-0.6c-0.5,0.1-1,0.2-1.5,0.4c-1,0.3-1.7,0-2.2-0.7c-0.4-0.5-0.6-1.1-1-1.6c-0.6-0.6-1.2-1.2-0.8-2.2
                        c0.5-1.2,1.1-2.3,2.5-2.7c0.6-0.2,1.3-0.1,1.9-0.3c1.3-0.4,2.2-0.1,2.9,1.2c0.8,1.3,2.9,2.2,4,2.2c0.7,0,1.3,0,1.8-0.5
                        c1.7-1.5,3.6-1.7,5.7-1.5c0.6,0.1,1.3,0,1.9,0c0.8,0,1.2-1,2-0.8c0.6,0.2,1,0.7,1.6,1c0.4,0.2,0.8,0.2,1.2,0.2c1.1,0,2,0.4,2.9,1.1
                        c0.9,0.7,2.2,0.4,2.8-0.5c0.3-0.4,0.4-1,0.8-1.4c0.6-0.7,1.7-1.4,2.8-0.5c0.8,0.6,1.7,0.9,2.7,0.9c1.5,0,2.5-0.9,2.5-2.3
                        c0-1,0-1.9,0-2.9c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.4-0.2,0.5-0.2c0.2,0,0.4,0.2,0.4,0.3c0.1,0.3,0.2,0.6,0.2,0.9c0,1,0,1.9,0,2.9
                        c0,30.5,0,61.1,0,91.6c0,0.4,0,0.8,0,1.3c0,2.6,0.1,2.4-1.9,4c-1.5,1.2-2.7,2.7-3.9,4.2c-0.2,0.2-0.3,0.5-0.4,0.8
                        c0.2,1,0.9,1.7,1.3,2.6c0.3,0.6,0.4,1.2,0.2,1.9c-0.3,0.9-0.6,1.8-0.9,2.7c-0.1,0.3-0.4,0.6-0.6,0.7c-1.3,0.2-2.3,1-3.4,1.6
                        c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0.3-1.3,0.7-1.5,1.5c-0.1,0.3-0.4,0.5-0.6,0.7c-0.1,0.1-0.5,0.2-0.6,0.1c-0.6-0.5-1.1-0.9-1.6-1.5
                        c-0.1-0.1-0.1-0.4-0.1-0.6c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.7,0.1-1.3-0.4-1.7c-0.6-0.4-1.2-0.5-1.8-0.2c-0.5,0.2-0.8,0.6-1.3,0.9
                        c-0.3,0.2-0.6,0.2-0.9,0.3c-0.4,0.1-0.9-0.3-1-0.6c0-0.3,0-0.6,0-1c-0.1-1.2-0.5-1.6-1.9-1.6c-0.3,0-0.6,0-0.9,0
                        c-0.2-0.2-0.4-0.3-0.5-0.4c-0.2-1-0.9-1.7-1.9-2c-0.2-0.1-0.4-0.1-0.6-0.3c-0.1-0.1-0.2-0.4-0.1-0.5c0.2-0.4,0.5-0.6,0.8-1
                        c0.3-0.4,0.3-1.1,0-1.5c-0.4-0.5-0.8-1-1.3-1.4c-0.9-0.8-1.4-1.8-1.7-3c-0.1-0.6-0.7-0.8-1.3-0.8c-0.6,0-1.1,0.3-1.2,0.8
                        c-0.1,0.6-0.2,1.3-0.3,1.9c0,0.3-0.5,0.5-0.7,0.4c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.5-0.5-0.7-0.6c-1.4-0.3-2.6-0.8-3.6-1.9
                        c-0.1-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.8,0-1.3,0c-0.7,0-1.2-0.2-1.5-0.9c-0.2-0.4-0.4-0.8-0.6-1.2c-0.7-0.2-1.4-0.4-2.1-0.6
                        c-0.4-0.1-0.7-0.5-0.7-0.9c0-0.3,0-0.6,0-1c0-0.3,0-0.7-0.2-0.9c-1-0.7-0.9-1.8-1.1-2.8c-0.1-0.7-0.7-1.1-1.3-1.3
                        c-0.6-0.2-0.8-0.7-1-1.2c-0.2-0.4-0.1-0.8,0.2-1.2c0.1-0.1,0.1-0.2,0.2-0.2c0.5-0.4,0.6-0.9,0.5-1.4c-0.1-0.5-0.8-1-1.5-1
                        c-0.3,0-0.6,0.1-0.9,0.1c-0.2,0-0.4,0.1-0.7,0.1c-0.7-0.4-1.3-0.9-2.1-1.3c-0.6-0.3-1.2-0.4-1.8-0.5c-0.8-0.1-1.4,0.3-1.8,1
                        c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.7-0.3,1.5-1.1,1.8c-0.6,0.3-2.8,0.1-3.4-0.2c-0.2-0.1-0.3-0.2-0.5-0.4c-1-0.7-2.1-1-3.3-0.9
                        c-1.4,0.1-2.8,0-4.2,0c-0.8,0-1.7-0.1-2.5-0.2c-2.1-0.2-2.9,1.4-2.5,3.1c0.1,0.4,0.4,0.8,0.5,1.1c0.4,0.8,0.6,1.6,0.3,2.5
                        c-0.4,1.2-0.2,2.5-0.8,3.7c-0.3,0.6-0.2,1.5-0.3,2.2c-0.1,0.5-0.5,0.9-1.1,1c-1,0.1-1.9,0.1-2.9,0.2c-0.9,0.1-1.6,0.7-1.7,1.6
                        c-0.1,1-0.4,1.8-1.4,2.4c-0.3,0.2-0.6,0.6-0.7,1c-0.3,0.8-0.5,1.6-0.9,2.4c-0.2,0.4-0.1,1-0.1,1.6c0.1,1-0.2,1.8-1.2,2.4
                        c-0.6,0.4-0.7,1-0.4,1.7c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.6-0.5,1.4-1.1,1.3c-0.4-0.1-0.8-0.2-1.2-0.3c-0.6-0.2-1.3-0.2-1.8,0.2
                        c-0.3,0.2-0.7,0.5-1,0.8c-0.5,0.4-1.1,0.6-1.8,0.6c-1.1,0-2.1,0.1-3.1,0.6c-0.4,0.2-1,0.1-1.6,0.1c-0.9,0-1.7,0.2-2.1,1.1
                        c-0.1,0.2-0.3,0.3-0.5,0.4c-0.4,0.2-0.8,0.3-1.2,0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.7-0.7-1.5-1.1-2.6-1c-0.9,0.1-1.7-0.2-2-1.1
                        c-0.2-0.5-0.7-0.8-1.1-1.1c-0.6-0.5-1-1-1.6-1.6c-0.4-0.4-1.3-0.4-1.8,0c-0.5,0.4-0.9,0.9-1.5,1.2c-0.8,0.5-1.3,1.1-1.5,2
                        c-0.2,0.7-0.6,1.1-1.3,1.3c-0.3,0.1-0.5,0.3-0.8,0.5c-0.6,0.6-0.7,1.7-0.1,2.3c0.4,0.4,0.8,0.7,1.1,1.1c0.1,0.1,0.1,0.4,0.1,0.6
                        c0,0.1-0.1,0.2-0.2,0.3c-0.9,0.6-1,1.5-1,2.5c0,0.6-0.8,1.1-1.4,0.8c-0.5-0.2-0.9-0.5-1.4-0.7c-0.7-0.3-1.6,0.1-1.9,0.8
                        c-0.2,0.4-0.3,0.8-0.4,1.2c-0.3,0.6-0.7,1.1-1.3,1.3c-0.9,0.3-1.7,0.7-2.4,1.5c-0.3,0.3-0.7,0.5-1.1,0.6c-1.1,0.2-1.6,0.9-2.1,1.8
                        c-0.4,0.8-1.1,0.9-1.9,0.8c-0.3,0-0.6-0.2-0.7-0.6c0-0.1-0.1-0.2-0.1-0.3c0-1.5-0.7-2.4-2.1-2.8c-0.2,0-0.4-0.1-0.6-0.3
                        c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.5,0-1.1,0-1.6c-0.1-0.7-0.8-1.4-1.5-1.5c-0.3,0-0.6-0.1-0.9-0.1c-0.8,0.1-1.5,0-2.2-0.4
                        c-0.3-0.2-0.6-0.2-0.9-0.3c-0.8,0-1.5,0.1-2,0.8c-0.5,0.8-0.9,1.7-1.6,2.3c-0.5,0.5-0.4,1.2-0.5,1.8c-0.2,1-1.2,1.2-2,0.5
                        C491.6,618.6,491.6,618.4,491.5,618.2z"></path>
                        <path className="st7" d="M438.1,219.6c0.7,1,0.6,2.2,0.9,3.3c0.2,0.9,0.7,1.5,1.5,2c1.4,0.8,2.5,0.7,3.8-0.3c0.8-0.6,1.5-1.1,2.3-1.7
                        c0.4-0.3,0.9-0.6,1.2-1c0.7-1.1,1.7-1.9,2.9-2.3c0.6-0.2,1-0.8,1.1-1.4c0.2-1.4,0.8-2.6,1.9-3.6c0.8-0.7,0.7-2.1-0.2-2.8
                        c-0.7-0.6-1.5-1.2-2.3-1.7c-0.8-0.6-1.3-1.4-1.3-2.4c0-0.9-0.3-1.6-0.7-2.4c-0.4-0.7-0.3-1.4,0.2-2.1c0.3-0.4,1.2-0.5,1.4-0.1
                        c0.4,0.5,0.7,1.1,1,1.6c0.8,1.1,2.3,1.3,3.4,0.5c0.3-0.2,0.6-0.6,0.9-0.9c0.6-0.5,1.2-0.4,1.7,0.2c0.1,0.2,0.2,0.4,0.3,0.6
                        c0.4,0.7,1,1.1,1.8,1c0.6,0,1.3-0.1,1.9-0.1c0.8,0,1.4,0.7,1.3,1.5c0,0.3-0.1,0.7-0.3,0.9c-0.6,0.5-1.2,0.9-1.8,1.3
                        c-0.7,0.5-0.9,1.6-0.4,2.3c0.7,1.1,1.4,2.1,2.2,3.2c0.5,0.6,1.1,1,1.9,1c1.4,0,2.8,0.1,4.1,0.1c0.8,0,1.4-0.5,1.8-1.2
                        c0.6-1.1,1.1-2.3,1.6-3.5c0.4-0.8,0.3-1.7,0.2-2.5c0-0.7,0.3-1.2,1-1.4c1.1-0.3,2.1-0.9,3-1.7c0.7-0.7,1.7-1.2,2.6-1.8
                        c0.3-0.2,1-0.1,1.2,0.2c0.3,0.3,0.5,0.7,0.8,1c0.8,1.4,0.5,2.3-1,3.1c-0.5,0.3-1,0.4-1.4,0.7c-0.7,0.5-0.7,1.8,0,2.2
                        c0.6,0.4,1.3,0.7,1.9,1.2c0.3,0.2,0.4,0.7,0.6,1.1c0.1,0.2,0,0.5-0.2,0.6c-0.5,0.3-1.1,0.6-1.7,0.9c-0.7,0.5-0.8,1.8,0,2.2
                        c2.2,1,4.3,2.3,6.7,3.1c1.4,0.4,2.7,0.6,4.1,0.3c0.8-0.2,1.6-0.4,2.5-0.7c0.3-0.1,0.6-0.3,0.8-0.5c0.8-0.9,1.7-1.5,2.7-2.2
                        c0.5-0.4,0.7-1.1,0.6-1.7c-0.1-0.8-0.4-1.4-1-1.9c-0.4-0.3-0.9-0.6-1.4-0.8c-1.1-0.5-1.4-1.5-1.3-2.6c0.1-0.6,0.2-1.3,0.2-1.9
                        c0.1-0.5,0.3-0.9,0.7-1c2.1-0.5,3.7-2,5.7-2.7c0.3-0.1,0.5-0.8,0.3-1.1c-0.4-0.5-0.8-1-1.2-1.5c2.1-1.5,4.3-2.8,5.8-4.9
                        c0.8-1,2.3-0.9,3,0.1c0.3,0.3,0.5,0.7,0.7,1.1c0.2,0.4,0.4,0.8,0.4,1.2c0.3,1.4,1.3,2.1,2.6,2.6c0.3,0.1,0.6,0.2,0.9,0.3
                        c3.3,1.1,3.3,1.1,6.8,0.2c0.6-0.2,1.3-0.3,1.9-0.4c1.2-0.4,2.3-1,3.3-1.9c0.2-0.2,0.4-0.5,0.6-0.8c0.4-0.6,0.3-1.6-0.3-2.1
                        c-0.4-0.3-0.9-0.6-1.3-0.9c-0.7-0.4-0.8-1.3-0.2-1.9c0.4-0.4,0.8-0.7,1.4-0.7c0.8,0,1.7-0.1,2.5,0.1c2,0.5,3.8,0.1,5.6-0.5
                        c1.1-0.4,2.2-0.8,3.3-1.1c1.6-0.5,1.8-0.4,1.8,1.4c0,0.7,0,1.5,0,2.2c0,1.5,0,3,0,4.5c0,0.6,0.1,1.3,0.1,2c1.7,1.7,3.4,3.5,5.1,5.2
                        c0,0.7,0.1,1.3,0.1,1.9c0,7.5,0,14.9,0,22.4c0,0.5,0,1.1-0.1,1.6c-0.1,0.3-0.3,0.5-0.7,0.6c-0.6,0.1-1.3,0.1-1.9-0.2
                        c-1.1-0.6-2.2-0.6-3.4-0.9c-0.6-0.2-1.3,0.1-1.7,0.6c-0.7,0.9-1.6,1.8-2.1,2.8c-1,1.8-2.4,3.2-3.8,4.7c-1.2,1.3-2.6,2.2-4.5,1.7
                        c-0.3-0.1-0.6,0.1-0.9,0.1c-0.4,0.1-0.7,0.4-0.9,0.8c-0.4,0.8-0.4,1.6,0,2.4c0.1,0.2,0.2,0.4,0.4,0.5c1.1,1.1,1.2,2.4,0.6,3.8
                        c-0.3,0.6-0.3,1.2-0.2,1.9c0.3,1,0.1,1.9-0.9,2.5c-0.2,0.1-0.3,0.3-0.4,0.5c-0.3,0.7-0.3,1.5-0.8,2.1c-0.4,0.6-1.2,0.8-1.8,1.3
                        c-0.4,0.3-0.6,0.9-1.1,1.2c-1.3,0.9-1.6,2.2-1.6,3.6c0,0.8,0.2,1.5,0.8,2c0.3,0.3,0.6,0.6,0.8,0.9c-0.1,0.4-0.2,0.7-0.3,0.9
                        c-0.5,0.8-0.6,1.6-0.2,2.4c0.2,0.5,0.3,1,0.3,1.5c-0.1,1.1,0,2.1,0.3,3.1c0.1,0.4-0.3,1.1-0.8,1.2c-0.1,0-0.2,0.1-0.3,0.1
                        c-0.6,0.1-1.3,0.2-1.9,0.4c-0.6,0.2-1.1,0.6-1.6,1c-0.5-0.3-1-0.5-1.5-0.7c-0.8-0.3-1.8,0.2-2,1.1c-0.2,0.7-0.2,1.5,0.1,2.2
                        c0.2,0.4,0.4,0.7,0.6,1.1c-0.4,1.1-0.8,2.2-1.3,3.3c-0.2,0.4-0.5,0.7-0.8,1c-0.4,0.4-0.9,0.6-1.2,1c-0.4,0.4-0.6,0.9-0.4,1.5
                        c0.1,0.4,0.2,0.8,0.3,1.2c0,0.9-0.8,1.3-1,2c-0.2,0.6-0.3,1.2-0.5,1.9c-0.2,0.6-0.1,1.3,0.2,1.9c0.1,0.3,0.3,0.6,0.4,0.8
                        c0.4,0.6,0.4,1.2,0.1,1.8c-0.2,0.4-0.3,0.8-0.4,1.2c-0.1,0.5,0,1,0.4,1.5c0.3,0.3,0.5,0.6,0.8,0.9c-0.4,1-0.8,1.8-1.2,2.7
                        c-0.2,0.5-1.1,0.9-1.6,0.7c-0.7-0.3-1.4-0.6-2-0.9c-0.9-0.5-1.8-0.4-2.7,0.1c-0.3,0.1-0.5,0.4-0.8,0.5c-1.1,0.7-1.7,0.6-2.6-0.1
                        c-1.9-1.6-3.4-3.5-3.7-6.1c0-0.2-0.2-0.4-0.3-0.5c-0.2-0.2-0.5-0.3-0.8-0.4c-0.4-0.1-1,0.2-1.1,0.5c-0.3,0.7-0.5,1.4-0.8,2.1
                        c-0.4,0.9-1.1,1.2-2,1.4c-0.9,0.2-1.7,0-2.5-0.3c-0.7-0.2-1.4-0.6-2.1-0.9c-1.5-0.6-3.5,0.6-3.7,2.1c0,0.2,0,0.4-0.1,0.6
                        c-0.6,1.4-0.9,2.8,0,4.2c0.2,0.3,0,0.8-0.2,1.2c-0.1,0.1-0.1,0.2-0.2,0.3c-1,0.7-1.1,2.2-2.2,2.6c-1,0.4-2.1,0.3-3.1,0.5
                        c-0.3,0-0.6,0.2-0.9,0.2c-0.2,0-0.3-0.2-0.5-0.3c0,0,0-0.2,0-0.3c0.4-0.4,0.8-0.7,1.1-1.1c0.6-0.7,0.6-1.6,0.1-2.4
                        c-0.2-0.4-0.5-0.7-1-0.7c-0.5,0-1.1,0-1.6,0.1c-0.2-0.2-0.5-0.4-0.7-0.7c-0.6-0.9-1.4-1.4-2.6-1.6c-1.6-0.2-3-1.1-4.5-1.5
                        c-0.9-0.8-1.9-0.9-3-0.8c-0.9,0-1.6-0.3-2.3-0.9c-0.3-0.3-0.7-0.6-1.1-0.7c-1.6-0.4-2.8-1.5-4-2.3c-0.4-1-0.3-2-0.2-2.9
                        c0.1-1.6-0.4-2.7-2.1-3c-0.2,0-0.4-0.6-0.3-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.4-0.5,0.6-0.9c0.7-0.1,1.4-0.2,2.1-0.3
                        c0.7-0.1,1.3-0.6,1.4-1.2c0.1-0.7-0.3-1.6-1-1.9c-0.1,0-0.2-0.1-0.3-0.1c-1.5-0.3-2.7-1.2-4-2c-0.5-0.5-0.3-1.2-0.5-1.8
                        c-0.2-0.5-0.3-1-0.5-1.5c0.6-0.8,1.3-1.5,1.8-2.3c0.5-0.7,0.8-1.5,1.2-2.2c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.6-0.5-1.6-1.1-1.7
                        c-0.5-0.1-1.1-0.1-1.6-0.1c-1.1,0-2.1-0.4-2.8-1.4c-0.5-0.6-1.1-0.9-1.9-1.1c-1.6-0.4-3.1-0.8-4.7-0.5c-0.3,0.1-0.6,0-0.9-0.1
                        c-1.1-0.1-2-0.5-2.4-1.7c-0.3-1-1.2-1.4-2.3-1.3c-0.5,0.1-1.1,0.1-1.4-0.4c-0.4-0.5-0.8-1-0.8-1.7c0-0.9,0.1-1.7,0.1-2.6
                        c0-0.6,0.1-1.3,0.4-1.8c0.2-0.5,0.4-1,0.5-1.5c0.2-0.8,0.1-1.5-0.4-2.1c-0.3-0.4-0.7-0.8-1-1.2c0.3-0.7,0.6-1.4,0.8-2.1
                        c0.2-0.6,0.4-1.2,0.6-1.8c0.2-0.5,0.6-0.9,1.2-1c0.3,0,0.6,0,0.9-0.1c0.9-0.2,1.4-0.9,1.5-1.8c0-0.3,0-0.7-0.1-0.9
                        c-0.6-1.3-0.8-2.6-0.1-4c0.1-0.3,0.1-0.6,0-0.9c-0.4-1.4-0.8-2.9-1.4-4.2c-0.4-0.8-0.7-1.5-0.8-2.4c-0.1-0.6-0.5-1.1-1-1.6
                        c-0.8-0.6-1.5-1.2-2.2-1.8c-0.3-0.2-0.1-1,0.2-1.1c0.1-0.1,0.2-0.2,0.3-0.2c2.1-0.2,2.7-1.7,3.2-3.4c0.3-1,0.1-1.9-0.8-2.5
                        c-0.2-0.1-0.3-0.3-0.5-0.4c-0.5-0.5-0.5-1.3-0.1-1.7c1.7-1.7,1.7-1.7,2.7-3.8C436.7,220.3,437.2,219.7,438.1,219.6z"></path>
                        <path className="st6" d="M628.7,595.1c0.9-1.1,1.7-2,2.5-2.9c0.8-0.9,1.6-1.7,2.7-2.3c0.4-0.3,0.9-0.6,1.4-0.6c1.2-0.1,2-0.7,2.6-1.6
                        c0.2-0.3,0.4-0.5,0.6-0.7c0.4-0.3,0.9-0.5,1.5-0.4c0.3,0.1,0.5,0.3,0.7,0.5c0.1,0.1,0,0.4-0.1,0.6c-0.2,0.3-0.6,0.6-0.8,1
                        c-0.5,0.7-0.3,1.8,0.5,2.2c1.1,0.5,2.3,1.1,3.5,1.5c0.7,0.3,1.5,0,2.2-0.2c0.8-0.2,1.1,0,1.3,0.9c0,0.2,0,0.4,0.1,0.6
                        c0,0.2,0.1,0.4,0.1,0.6c0.2,0.6,0.6,1.1,1.3,1.3c0.3,0.1,0.6,0.1,0.9,0.3c0.9,0.4,1.3,1.5,2.3,1.6c1,0.2,1.8,0.7,2.6,1.2
                        c0.8,0.6,1.7,0.8,2.7,0.8c0.4,0,0.8,0,1.3,0c0.4,0,0.8,0.2,1.1,0.5c0.7,0.6,1.2,1.2,1.6,2c0.3,0.5,0.5,0.9,0.8,1.4
                        c0.5,0.7,1.5,0.9,1.8,1.7c0.2,0.7,0.2,1.5,0.5,2.2c0.2,0.5,0.5,1,0.8,1.3c0.4,0.4,1,0.8,1.5,1.2c0.4,0.3,0.5,0.7,0.4,1.2
                        c-0.1,0.5-0.2,1-0.4,1.5c-0.6,2-0.2,3.6,1.6,4.7c0.2,0.1,0.3,0.3,0.4,0.4c0.2,0.2,0.1,0.7-0.1,0.9c-0.1,0.1-0.2,0.2-0.2,0.2
                        c-2.1,0.8-2.1,0.7-2.6,3.1c-0.1,0.5-0.6,0.7-1,0.6c-0.7-0.2-1.4-0.5-2.1-0.7c-0.8-0.2-1.5-0.2-2,0.5c-0.6,0.8-1.2,1.1-2.2,1.1
                        c-0.5,0-1,0.3-1.5,0.6c-0.9,0.6-1.2,1.4-1.1,2.5c0.1,0.3,0.1,0.7,0.2,0.9c0.7,1.2,0.7,2.4,0.4,3.7c-0.2,0.8-0.1,1.7-0.1,2.5
                        c0,0.5,0.2,1,0.6,1.4c0.7,0.8,1.4,1.6,2.2,2.3c0.9,0.7,1.2,1.4,1,2.5c-0.1,0.6,0.2,1.3,0.9,1.5c0.2,0.1,0.4,0.2,0.6,0.2
                        c0.8,0.2,1.3,0.7,1.5,1.5c0.1,0.5,0.3,1,0.4,1.6c-0.6,0.3-1.2,0.6-1.7,1c-0.9,0.6-1.9,0.9-3,0.8c-0.1,0-0.2,0-0.3,0
                        c-2.3,0-2.6,0.2-3.2,2.6c0,0.1,0,0.2-0.1,0.3c-0.2,0.8-0.8,1.3-1.6,1.4c-1.3,0-2.5,0.2-3.7,0.7c-0.6,0.3-1.3,0.1-1.8-0.2
                        c-0.4-0.3-0.9-0.6-1.4-0.8c-0.6-0.3-1.2-0.3-1.8,0.1c-0.3,0.2-0.7,0.5-1.1,0.7c-0.7,0.4-1.8,0.1-2.3-0.6c-0.2-0.3-0.3-0.6-0.5-0.8
                        c-0.3-0.5-0.5-0.9-0.8-1.4c-0.4-0.5-1-0.8-1.7-0.8c-0.5,0.1-1,0.2-1.6,0.3c-0.6,0.1-1,0-1.5-0.3c-0.4-0.2-0.7-0.4-1.1-0.6
                        c-0.5-0.2-1.4,0.1-1.6,0.6c-0.2,0.5-0.4,1-0.5,1.5c-0.1,1.1-0.6,1.8-1.6,2.2c-0.5,0.2-1,0.4-1.3,0.8c-0.8,1.1-1.8,1.4-3.1,1.2
                        c-1.7-0.2-3.4-0.1-5,0.6c-1.1,0.5-2,0.1-2.8-0.9c-0.3-0.3-0.5-0.7-0.7-1.1c-0.5-0.8-1.3-1-2.2-0.7c-0.5,0.2-1,0.5-1.5,0.6
                        c-1,0.2-1.9,0.1-2.7-0.6c-0.1-0.1-0.2-0.1-0.2-0.2c-1.2-1.5-2.7-2.1-4.5-1.9c-0.4,0-0.8,0-1.3-0.1c-0.7-0.1-1.2-0.4-1.5-1
                        c-0.2-0.3-0.3-0.6-0.4-0.8c-0.3-0.6-0.8-1-1.5-1.1c-0.7-0.2-1.4-0.3-2.2-0.5c-0.5-0.6-0.9-1.2-1.3-1.8c-0.6-0.7-1.4-1-2.3-1
                        c-0.4,0-0.9,0.1-1.2,0.2c-0.8,0.3-1.5,0.7-2.2,1.1c-0.9-0.2-1.7-0.5-2.6-0.6c-1.1-0.2-2.1-0.1-2.8,1c-0.5,0.8-1.2,1.1-2.2,1.2
                        c-1,0-1.5,0.7-1.6,1.7c0,0.3,0,0.7-0.2,0.9c-0.1,0.3-0.4,0.5-0.7,0.6c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0.1-1.6,0.5-2.1,1.3
                        c-0.1,0.2-0.2,0.4-0.3,0.5c-0.6,1-1.5,1.5-2.6,1.5c-0.3,0-0.6,0-1,0.1c-0.8,0.1-1.3,0.7-1.2,1.5c0,0.2,0,0.4,0.1,0.6
                        c0,0.6-0.2,1-0.6,1.4c-0.3,0.3-0.7,0.5-1,0.8c-0.4,0.3-0.8,0.7-0.9,1.3c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3,0.8-0.8,1.5-1.6,1.9
                        c-0.3,0.1-0.6,0.3-0.8,0.5c-1.3,0.9-1.8,2.5-1.1,3.9c0.3,0.7,0.8,1.2,1.2,1.9c0.2,0.3,0,0.9-0.4,1.1c-0.3,0.1-0.6,0.3-0.9,0.4
                        c-0.4,0.1-0.8,0.2-1.3,0.2c-1,0.2-1.7,0.7-2.1,1.7c-0.2,0.7-0.6,1.4-0.9,2c-0.5,0.9-1.5,1.3-2.4,1c-0.2-0.1-0.4-0.1-0.6-0.2
                        c-1.2-0.8-2.4-0.8-3.7-0.3c-0.3,0.1-0.7,0.1-0.9-0.1c-0.7-0.7-1.3-1.4-1.9-2.1c-0.2-0.2-0.1-0.6-0.2-0.9c-0.1-0.5-0.2-1-0.3-1.5
                        c-0.2-0.6-0.7-1-1.2-0.9c-1.5,0.1-2.7-0.4-3.8-1.3c-0.2-0.2-0.6-0.4-0.9-0.4c-1.5,0-2.5-1.2-3.8-1.6c-0.4-0.1-0.6-0.5-0.9-0.8
                        c-0.5-0.5-1.1-1-1.6-1.5c-0.6-0.7-1.4-1-2.3-0.7c-1.1,0.3-1.8-0.4-2.6-0.9c-0.7-0.4-0.8-1.7-0.2-2.3c0.4-0.4,0.8-0.6,1.2-1
                        c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.3-0.9-0.6-1c-0.6-0.3-1.2-0.4-1.9-0.1c-0.8,0.3-1.5,0.7-2.4,0.9c-0.9,0.2-1.2,1.2-1.8,1.8
                        c-0.1,0.1-0.5,0.1-0.6-0.1c-0.5-0.8-1-1.6-1.5-2.5c0.2-0.2,0.4-0.5,0.7-0.7c0.9-0.5,1.2-1.3,1-2.1c-0.3-1.3-0.2-2.5,0-3.8
                        c0.2-1.6,1.7-2.9,3.2-3.1c0.4,0,0.9,0,1.3,0c0.9,0,1.6-0.4,2.3-1c0.4-0.4,0.9-0.9,1.4-1.2c0.4-0.4,0.7-0.8,0.8-1.3
                        c0.1-0.6,0-1.3,0.4-1.8c0.9-1.2,1.1-2.5,1.3-3.9c0-0.4,0.1-0.9,0.2-1.3c0.3-1.1,0.8-2,1.9-2.4c0.4-0.2,0.8-0.3,1.1-0.6
                        c0.4-0.3,0.6-0.9,0.6-1.4c0-0.5-0.7-1.4-1.1-1.5c-0.1,0-0.2-0.1-0.3-0.1c-1.5,0-2.6-0.7-3.8-1.5c-0.2-0.1-0.3-0.6-0.3-0.8
                        c0-0.3,0.3-0.5,0.5-0.8c1.1-1.9,1-2.9-0.4-4.6c-0.3-0.3-0.6-0.6-0.7-1c-0.1-1.4-1.1-1.6-2.2-1.8c-0.8-0.1-1.2-1-0.8-1.7
                        c0.4-0.6,0.9-1.2,1.3-1.8c0.2-0.4,0.3-0.8,0.2-1.2c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-0.9-0.6-1.8-0.4-2.8c0.1-0.5,0-1.1-0.1-1.7
                        c0.4-0.5,0.7-1,1.1-1.5c0.3-0.4,0.6-0.6,1.1-0.5c1,0.1,1.9,0.1,2.8-0.4c0.3-0.2,0.8-0.3,1.2-0.3c1.5,0.1,2.7-0.3,3.8-1.2
                        c0.3-0.3,0.8-0.3,1.2-0.2c0.5,0.1,1.1,0.2,1.6,0.2c0.4,0,0.8-0.2,1.2-0.4c0.7-0.3,1-1.1,0.8-1.9c-0.1-0.3-0.2-0.6-0.3-0.9
                        c-0.1-0.3,0-0.6,0.2-0.9c0.1-0.2,0.3-0.3,0.5-0.4c0.6-0.4,0.8-0.9,0.9-1.6c0-0.1,0-0.2,0-0.3c-0.3-1.6,0.6-2.9,0.9-4.3
                        c0-0.2,0.2-0.4,0.3-0.5c1.1-0.6,1.6-1.6,1.8-2.9c0.1-0.7,0.7-1.1,1.4-1.1c0.8-0.1,1.7-0.1,2.5-0.2c0.3,0,0.6-0.1,0.9-0.1
                        c0.3,0,0.8-0.6,0.9-0.9c0-0.3,0-0.6,0-1c-0.1-0.6,0-1.3,0.2-1.9c0.5-1.1,0.6-2.3,0.8-3.4c0.2-1.4,0.2-2.7-0.5-4
                        c-0.2-0.3-0.1-0.8-0.2-1.2c0-0.3,0.5-0.9,0.8-0.9c0.1,0,0.2,0,0.3,0c2.4,0,4.9,0.1,7.3,0.1c1,0,1.9,0.3,2.6,1
                        c0.7,0.6,1.5,0.8,2.3,0.8c0.4,0,0.9,0,1.3,0c1.1,0.1,1.8-0.5,2.3-1.4c0.2-0.5,0.3-1,0.5-1.5c0.2-0.6,1-1,1.6-0.7
                        c0.6,0.2,1.3,0.4,1.7,0.8c0.9,1.1,2,1,3.2,0.8c0.3-0.1,0.5,0.4,0.3,0.6c-1,1.1-0.6,2.2-0.1,3.2c0.1,0.3,0.4,0.5,0.7,0.6
                        c0.4,0.3,0.9,0.6,1.3,0.8c0,1.9,0,1.9,1.2,3.5c0,0.1,0.1,0.2,0,0.3c-0.4,1.8-0.2,2.1,1.7,2.7c0.2,0.1,0.4,0.1,0.6,0.2
                        c0.6,0.1,1,0.4,1.1,1c0,0.2,0.2,0.4,0.3,0.6c0.6,0.7,1.2,1.2,2.2,1c0.9-0.2,1.7,0,2.3,0.8c0.4,0.6,1,0.7,1.7,0.8
                        c0.6,0,1,0.3,1.4,0.6c0.3,0.3,0.6,0.6,1,0.8c0.8,0.5,1.8,0,2-0.9c0.1-0.5,0.1-1.1,0.2-1.6c0-0.2,0.2-0.4,0.3-0.4
                        c0.2,0,0.5,0.1,0.5,0.2c0.2,1.1,0.8,1.9,1.6,2.7c0.5,0.4,0.8,1,1.3,1.5c-0.3,0.6-0.7,1.2-1,1.8c-0.3,0.5-0.1,1.2,0.4,1.4
                        c0.4,0.2,0.8,0.3,1.2,0.5c0.2,0.1,0.4,0.1,0.6,0.3c0.2,0.1,0.3,0.3,0.3,0.5c0.3,1.6,1.4,2,2.9,1.8c0.2,0,0.6,0.3,0.7,0.5
                        c0.1,0.3,0,0.6,0.1,0.9c0,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.2,0.3,0.4,0.5c0.8,0.5,1.6,0.5,2.4,0.1c0.5-0.3,1.1-0.7,1.6-1
                        c0.1,0,0.3-0.1,0.3,0c0.1,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.4-0.1,0.6c-0.6,1-0.5,1.8,0.5,2.5c0.3,0.2,0.6,0.6,0.9,0.9
                        c1,1,1.9,0.6,2.8-0.2c0.2-0.2,0.3-0.5,0.5-0.8c0.1-0.2,0.2-0.5,0.4-0.5c1.6-0.4,2.6-1.9,4.3-2.1c1-0.1,1.4-0.8,1.6-1.7
                        c0.1-0.5,0.2-1,0.4-1.5c0.6-1.5,0.5-2.9-0.4-4.2C629.1,596,629,595.6,628.7,595.1z"></path>
                        <path className="st6" d="M585.8,520.3c-0.8,0.7-1.6,1.2-2.2,1.9c-0.5,0.5-0.8,1.2-1.2,1.8c-0.3,0.5-1.3,0.7-1.7,0.4
                        c-0.7-0.5-1.3-1.1-2.2-1.2c-0.6-0.1-0.8-0.7-1.1-1c-1.1-1.2-2.5-1.3-3.9-0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.7,0.6-1.6,0.7-2.4,0.4
                        c-0.5-0.2-0.9-0.5-1.4-0.7c-0.7-0.3-1.7-0.1-2.1,0.3c-0.6,0.7-0.8,1.7-0.5,2.3c0.3,0.7,0.7,1.3,1.1,2c0.3,0.6,0.1,1.6-0.6,2
                        c-1.1,0.6-1.8,1.4-2.4,2.5c-0.5,1-1.3,1.6-2.5,1.9c-0.5,0.1-1.1,0.4-1.3,0.8c-0.6,1.4-2.1,1.7-3,2.8c-0.3,0.3-0.8,0.4-1.2,0.2
                        c-0.6-0.3-1.1-0.6-1.7-0.9c-0.7-0.4-1.4-0.3-2.1,0.1c-0.4,0.2-0.7,0.5-0.8,0.9c-0.2,0.5-0.3,1-0.6,1.5c-0.1,0.2-0.6,0.3-0.8,0.2
                        c-0.5-0.2-0.9-0.6-1.2-1c-0.5-0.6-0.8-1.2-1.3-1.8c-0.3-0.4-0.7-0.5-1.1-0.4c-0.7,0.1-1.4,0.4-2.2,0.6c-1.5,0.5-2.9,0.5-4.3-0.5
                        c-0.2-0.2-0.6-0.2-1-0.3c-0.8,0.5-1.6,1-2.4,1.6c-0.3,0.2-0.9,0.2-1.2-0.1c-0.5-0.4-0.9-0.9-1.5-1.2c-0.3-0.2-0.8-0.3-1.2-0.4
                        c-0.5-0.1-1.1,0.1-1.4,0.5c-0.6,0.8-1.1,1.5-1.6,2.3c-0.2,0.3-0.2,0.6-0.3,0.9c-0.1,0.5-0.2,1.1-0.3,1.6c-0.4,1.3-1,2.4-2.2,3
                        c-0.8,0.4-1.1,1-1,1.8c0.1,0.9-0.2,1.6-1.1,2.1c-0.8,0.4-0.9,1.1-0.5,1.9c0.2,0.5,0.1,1-0.4,1.4c-0.6,0.5-1.2,1.1-1.9,1.7
                        c-1.1-0.1-2.1-1-3.4-0.6c-0.3,0.1-0.6-0.2-0.7-0.5c-0.4-1.3-1-2.5-1.5-3.8c-0.3-0.7-0.6-1.3-1.2-1.8c-0.9-0.7-1.6-1.7-3-1.7
                        c-0.2,0-0.5-0.3-0.7-0.6c-0.3-0.4-0.5-1-0.8-1.4c-0.4-0.5-0.9-0.9-1.6-0.8c-0.5,0.1-1,0.2-1.6,0.3c-0.5,0.1-1,0-1.5-0.2
                        c-2.6-1.3-2.7-0.5-2.7-3.9c0-3.1,0-6.2,0-9.3c0-1.3,0.2-2.5,0.7-3.7c0.4-1,0.8-2,1.3-2.9c1-1.8,1.5-3.7,1.7-5.8
                        c0.2-1.7,0.4-3.4,0.7-5.1c0.3-2.1,0.5-4.2,0.6-6.3c0.2-5.5,0.5-11,0.7-16.6c0-1.2,0.4-2.3,0.7-3.4c0.1-0.3,0.2-0.6,0.5-0.8
                        c1.1-0.8,1.5-2,2.2-3.1c0.4-0.5,0.6-1.1,1-1.6c0.7-0.9,0.9-1.9,1.1-3c0.1-1.2,0.2-2.3,0.1-3.5c-0.1-1.2-0.1-2.3,0.1-3.5
                        c0.2-1.1,0.1-2.1-0.5-3.1c-0.5-0.8-0.7-1.8-0.8-2.7c-0.3-2.1-0.6-4.2-1-6.3c-0.1-0.6-0.1-1.3-0.1-1.9c0-3.7,0.1-7.4,0-11.2
                        c0-1.3-0.3-2.5-0.4-3.8c0-0.3,0.4-0.9,0.7-0.9c0.4,0,0.8-0.1,1.3-0.1c6.9,0,13.8,0,20.7,0c0.4,0,0.8,0.1,1.3,0.1
                        c0.2,0,0.5,0.6,0.4,0.8c-0.2,0.4-0.5,0.7-0.7,1.1c-0.3,0.4-0.2,0.8,0,1.2c0.6,0.8,1,1.5,2,2c0.8,0.4,1.5,1.1,2.5,1.2
                        c1.1,0.1,1.4,0.7,1.2,1.8c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.5,0,0.9,0.4,1.2c1.3,1,2.1,2.3,2.6,3.9c0.1,0.4,0.3,0.9,0.6,1.1
                        c1.1,0.6,1.8,1.5,2.3,2.5c0.2,0.3,0.6,0.6,1,0.7c1.2,0.2,2.1,0.8,2.8,1.9c0.9,1.4,2.3,1.6,3.9,0.8c0.4-0.2,0.7-0.6,1.1-0.7
                        c0.2-0.1,0.5,0.2,0.8,0.4c0.1,0,0.1,0.2,0.1,0.3c0,0.8,0.3,1.5,1,1.8c1,0.5,1.4,1.2,1.3,2.3c-0.1,0.6,0.3,1,0.8,1.2
                        c0.5,0.2,1,0.3,1.5,0.5c0.4,0.2,0.9,0.3,1.1,0.6c0.8,1.4,2.4,1.7,3.4,2.8c0.2,0.3,0.8,0.3,1.2,0.3c0.5,0,1-0.1,1.6-0.3
                        c0.9-0.2,1.6,0,2.4,0.6c1.1,0.9,2.4,1.7,3.4,2.8c0.8,0.9,1.8,1.5,3,1.8c0.6,0.2,1.1,0.6,1.2,1.3c0.1,0.4,0.2,0.8,0.4,1.2
                        c0.5,0.8,0.2,1.4-0.4,1.9c-0.4,0.4-0.5,0.9-0.3,1.4c0.2,0.6,0.8,1.1,0.7,1.7c-0.4,1.4,0.1,2.2,1.6,2.4c0.6,0.1,1,0.6,1.1,1.1
                        c0.4,1.8,1.5,3.3,1.5,5.2c0,0.4,0.3,0.8,0.6,1.1c0.6,0.6,1,1.3,1.4,2.1c0.1,0.4,0.4,0.7,0.7,1.1c0.4,0.5,0.9,0.9,1.6,1
                        c0.5,0.1,1,0.2,1.6,0.4c0.7,0.2,1.2,0.6,1.3,1.3c0.1,1,0.6,1.8,1.3,2.5c0.4,0.4,0.7,1,1,1.6c0.6,1,1.6,1.4,2.7,1.2
                        c0.4-0.1,0.8-0.2,1.2-0.3c0.8-0.2,1.1-0.1,1.6,0.6c0.2,0.3,0.4,0.5,0.5,0.8c0.5,1.2,1.3,2.2,2.4,2.9c0.6,0.4,0.7,1.1,0.5,1.7
                        c-0.4,1-0.8,2-1.3,2.9c-0.3,0.6-1.2,1-1.9,0.8c-0.3-0.1-0.6-0.2-0.9-0.3c-1.1-0.3-2.1-0.1-2.8,0.9c-0.5,0.7-1.1,0.9-1.9,0.4
                        c-0.1-0.1-0.2-0.1-0.3-0.1c-1-0.3-1.9-0.2-2.7,0.5c-0.2,0.2-0.5,0.4-0.7,0.6c-0.4,0.3-0.9,0.5-1.5,0.5c-0.5,0-1.1,0-1.6,0.2
                        c-1.2,0.3-1.9,1.5-1.5,2.7c0.2,0.7,0.7,1.3,0.7,2.1C585.8,519.5,585.8,519.9,585.8,520.3z"></path>
                        <path className="st5" d="M469.1,343.7c1.3,0.4,2.4,0.8,3.6,1.2c0.7-0.3,1.6-0.7,2.5-1.1c0.4-0.2,1.2,0.3,1.3,0.7
                        c0.1,0.7,0.2,1.5,0.3,2.2c0.1,0.4,0.4,0.8,0.8,0.9c1.2,0.3,2.5,0.5,3.7,0.8c0.5,0.1,1.1-0.1,1.5-0.4c0.3-0.2,0.8-0.5,0.9-0.8
                        c0.6-1.2,1.4-1.8,2.8-1.4c0.6,0.1,1.3-0.1,1.9-0.2c0.5-0.1,1.1-0.1,1.5,0.1c0.6,0.3,1.2,0.5,1.8,0.5c0.8,0,1.5,0,2.2-0.4
                        c1.1-0.6,2.2-1.2,3.4-1.8c1-0.5,1.9-0.3,2.6,0.5c0.6,0.7,1.1,1.3,1.9,1.6c0.9,0.4,1.6,1.1,1.9,2.1c0.1,0.3,0.3,0.6,0.5,0.8
                        c0.5,0.7,1.7,0.7,2.2,0c0.4-0.6,0.7-1.3,0.8-2c0.1-0.4,0.2-0.8,0.4-1.2c0.1-0.3,0.9-0.5,1.1-0.3c0.4,0.3,0.8,0.7,1.2,1.1
                        c0.6,0.5,1.3,0.7,2.1,0.7c0.7,0,1.2-0.3,1.6-0.9c0.3-0.5,0.6-1.1,0.9-1.6c0.8,0.2,1.5,0.5,2.2,0.6c0.6,0.1,1,0.3,1.2,0.8
                        c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.5,0.5,0.9,1.1,1.1c0.5,0.2,1,0.3,1.5,0.5c0.3,0.1,0.5,0.4,0.4,0.7c-0.1,0.3-0.1,0.6-0.1,0.9
                        c0.1,0.9,0.8,1.6,1.7,1.5c0.4,0,0.8-0.2,1.2-0.3c0.5-0.2,0.9-0.1,1.1,0.3c0.7,1.3,1.8,1.7,3.2,1.8c0.8,0.1,1.3,0.5,1.6,1.3
                        c0.1,0.3,0.3,0.5,0.5,0.8c0.3,0.4,1.1,0.5,1.5,0.2c0.3-0.2,0.6-0.5,1-0.8c0.2-0.1,0.3-0.4,0.4-0.4c0.3-0.1,0.7-0.2,0.9-0.1
                        c1,0.7,2,1.1,3.1,1.5c0.6,0.2,0.8,0.9,0.6,1.6c0,0.2-0.2,0.4-0.4,0.5c-0.5,0.1-1,0.2-1.5,0.3c-0.8,0.1-1.2,0.5-1.2,1.2
                        c0,2,0,4,0,6.1c0,1.2,0.3,2.3,0.8,3.4c0.7,1.7,0.7,3.3,0,5c-0.5,1.1-0.6,2.2-0.7,3.4c0,0.6-0.1,1.3-0.2,1.9
                        c-0.6,2.6-0.3,5.1,0.5,7.5c0.5,1.4,0.8,2.9,1.2,4.3c0.6,2.3,0.2,4.4-1,6.4c-1,1.5-2,3-3.3,4.2c-1.4,1.3-2.7,2.7-4.1,4.1
                        c-1.2,1.2-2.3,2.5-3.1,4c-1.2,2.4-2.9,4.4-4.7,6.4c-0.5,0.6-1,1.1-1.5,1.7c-1.5,1.7-2.9,3.4-3.8,5.5c-0.3,0.7-0.7,1.3-1.3,1.8
                        c-0.3,0.3-0.6,0.6-1,0.8c-2.2,1.9-2.2,1.4-1.9,4.9c0.2,1.9,0.5,3.8,0.7,5.7c0,0.3,0,0.6-0.1,0.9c-0.1,0.1-0.3,0.3-0.5,0.3
                        c-0.2,0-0.4,0-0.6-0.1c-0.7-0.5-1.4-0.9-2.1-1.4c-10.4-7.4-20.8-14.8-31.2-22.2c-1.3-0.9-2.6-1.8-3.6-3.1c-0.1-0.2-0.4-0.2-0.6-0.3
                        c-1.4,0.6-2.7,1.5-4,2.4c-1.1,0.7-2.1,0.9-3.3,0.6c-1-0.2-2.1-0.5-3.1-0.7c-0.6-1.2-1.2-2.3-1.7-3.4c-0.3-0.6-0.3-1.2-0.4-1.9
                        c-0.2-1.4-0.3-2.7-1.1-4c-0.3-0.4-0.4-1-0.5-1.5c-0.2-1-0.6-1.8-1.2-2.5c-0.4-0.5-0.7-1.1-1-1.6c-0.4-0.7-0.4-1.4-0.2-2.2
                        c0.2-0.6,0.4-1.2,0.6-1.8c0.2-0.7,0-1.3-0.4-1.8c-0.3-0.3-0.6-0.6-0.9-0.9c-0.8-0.8-1.3-1.7-1.5-2.8c-0.2-0.6-0.4-1.2-0.5-1.8
                        c-0.3-1.5-0.8-2.8-1.6-4.1c-1-1.8-1.7-3.7-1.8-5.7c-0.1-1.5-0.4-2.9-1.2-4.2c-0.5-0.7-0.7-1.5-0.9-2.4c-0.4-1.5-1.1-2.7-2.1-3.9
                        c-0.2-0.2-0.4-0.5-0.6-0.7c-1-1.3-1.8-2.7-1.9-4.3c-0.2-1.7-0.8-3.3-1.8-4.7c-0.8-1-1.1-2.3-1-3.6c0.2-3.5-1.4-6.1-3.6-8.6
                        c-0.5-0.6-1.2-0.9-1.9-1.1c-1.3-0.3-2.7-0.6-4-0.9c-0.2-0.1-0.5-0.6-0.3-0.8c0.1-0.2,0.2-0.4,0.3-0.5c0.5-0.6,1.1-1,1.9-0.9
                        c0.3,0,0.6,0.1,0.9,0.1c1.1,0.1,2.1-0.2,2.8-1.2c0.5-0.7,1-1,1.8-1.1c1.1,0,2.1-0.1,3,0.8c0.3,0.3,0.6,0.6,0.9,0.9
                        c0.5,0.4,1.3,0.3,1.8-0.1c0.3-0.3,0.6-0.6,0.8-1c0.4-0.5,1-0.8,1.7-0.7c0.8,0.1,1.7,0.1,2.5,0.2c0.5,0,0.9,0.4,1,1
                        c0.1,0.4,0,0.9,0.1,1.3c0.2,1.3,0.9,1.8,2.2,1.7c0.5-0.1,1.1-0.2,1.6-0.3c0.7-0.1,1.2,0.1,1.7,0.6c0.4,0.5,0.9,1,1.3,1.4
                        c0.2,0.2,0.5,0.4,0.7,0.6c1.1,0.8,2.2,0.6,2.9-0.8c0.2-0.5,0.4-1,0.7-1.4c0.1-0.3,0.6-0.2,0.8,0.1c0.1,0.2,0.2,0.4,0.3,0.6
                        c0.2,0.7,0.8,1,1.4,1.1c0.5,0.1,1.2,0.1,1.5,0.4c1.1,0.9,2.1,0.8,3.1-0.1C468.3,344.1,468.6,344,469.1,343.7z"></path>
                        <path className="st5" d="M559.9,712.3c-0.1,1-0.2,2-0.3,2.9c-0.3,0.1-0.6,0.3-0.9,0.3c-0.7,0.1-1.5,0.1-2.2,0.1c-0.3,0-0.6,0.3-0.6,0.6
                        c-0.1,0.7-0.1,1.5-0.1,2.2c0,3.5,0,7,0,10.5c0,0.9,0,1.7,0,2.6c0,0.9-0.5,1.3-1.5,1.1c-0.4-0.1-0.7-0.3-0.7-0.5
                        c-0.2-0.9-0.6-1.3-1.5-1.3c-1.7,0-3.4,0-5.1,0c-0.4,0-0.9-0.1-1.2-0.4c-0.6-0.6-1.2-0.8-2-0.8c-4.6,0-9.2,0-13.7,0c-1,0-1.9,0-2.9,0
                        c-1,0-1.3-0.4-1.3-1.4c0-2.2-0.1-4.5-0.1-6.7c0-3.5,0-7-0.1-10.5c0-0.6-0.1-1.3-0.1-1.9c0-0.4-0.5-0.8-0.8-0.8
                        c-0.8-0.1-1.7-0.1-2.5-0.1c-4.7,0-9.4,0-14,0c-0.6,0-1.3-0.1-1.9-0.2c-0.1,0-0.3-0.3-0.3-0.5c0.2-1.1,0.8-1.9,1.6-2.7
                        c0.6-0.6,0.6-1.3,0.3-2.1c-0.3-0.7-0.6-1.3-1-2c-0.2-0.5-0.6-0.8-1.1-1.1c-0.9-0.6-0.9-0.7-0.5-1.7c0.2-0.5,0.3-1,0.2-1.5
                        c-0.2-0.7-0.7-1.2-1.3-1.2c-0.5,0-1.1,0-1.6,0c-1-0.1-1.6-0.7-1.7-1.6c0-0.2,0-0.4,0-0.6c0.1-1-0.1-1.9-0.6-2.7
                        c-0.9-1.3-0.6-2.9-1.1-4.3c-0.1-0.2,0.1-0.6,0.3-0.9c0.2-0.6,0.2-1.2,0.1-1.9c-0.2-1.1-0.1-1.1,0.9-1.6c0.1,0,0.2-0.1,0.3-0.1
                        c1.3-0.7,1.4-1.8,0.3-2.7c-0.4-0.3-0.8-0.7-1.2-1c-0.2-0.2,0-0.7,0.2-0.8c0.6-0.2,1.2-0.4,1.8-0.5c0.5-0.8,1.3-0.9,2.2-0.9
                        c0.8,0,1.2-0.6,1.3-1.5c0.1-0.6-0.3-1.3,0.2-1.8c0.3,0,0.6,0.1,0.9,0c0.3,0,0.7,0,0.9-0.2c0.7-0.7,1.4-1.6,2.1-2.3
                        c0-1.2-0.8-2-1.2-3c-0.1-0.2-0.5-0.4-0.8-0.5c-0.6-0.2-1.1-0.5-1.3-1.2c-0.2-0.9-0.8-1.5-1.5-2c-0.8-0.7-1.6-0.9-2.6-0.5
                        c-0.8,0.3-1.6,0.5-2.4,0.7c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.4-0.1-0.6c0-0.2,0.3-0.3,0.3-0.5c0.5-1.4,0.1-2.3-1.2-2.7
                        c-0.6-0.2-1.2-0.3-1.7-0.8c-1-1-2.1-1.8-3.5-2c-0.3-0.1-0.7-0.5-0.9-0.8c-0.3-0.3-0.2-0.9,0.2-1.1c0.2-0.1,0.4-0.1,0.6-0.2
                        c0.6-0.1,0.9-0.4,1-1c0-0.4,0-0.9,0-1.3c0-2.6,0.1-2.7-2.6-2.7c-0.5,0-1.1-0.1-1.6-0.2c-0.2,0-0.4-0.3-0.4-0.4c0-0.3,0-0.7,0.1-0.9
                        c0.7-0.5,1.4-1,2.1-1.4c0.4-0.2,0.8-0.3,1.2-0.4c0.7-0.2,1.1-0.6,1.2-1.3c0.1-0.7-0.1-1.3-0.7-1.7c-0.7-0.5-1.4-0.9-2-1.6
                        c-0.5-0.6-1.4-1-2.1-1.4c-0.7-0.3-1.4-0.4-2.2-0.6c0.1-0.3,0.1-0.6,0.2-0.9c0.5-1,1-2.1,1.6-3.1c0.8-1.3,1.2-2.7,1.2-4.3
                        c0-2.4-0.1-4.9-0.1-7.3c0-0.8-0.1-1.7,0-2.5c0.1-1.4,0.4-1.6,1.8-1.2c0.2,0.1,0.4,0.2,0.6,0.2c0.9,0.2,2.1-0.4,2.2-1.4
                        c0.1-1,0.5-1.8,1.2-2.5c0.4-0.4,0.7-1.1,1-1.6c0.2-0.3,0.9-0.6,1.1-0.4c1.2,0.6,2.4,0.8,3.7,0.7c0.3,0,0.8,0.5,0.8,0.9
                        c0,0.3,0,0.6-0.1,0.9c-0.1,1,0.4,1.6,1.2,2.1c0.2,0.1,0.4,0.2,0.6,0.2c1.2,0.2,1.5,1,1.4,2.1c-0.1,0.7,0.4,1.2,1,1.5
                        c1.2,0.6,2.8,0.2,3.6-1.1c0.4-0.7,0.8-1.3,1.6-1.3c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.1,0.6-0.3c0.8-1,2-1.5,3.2-2
                        c0.6-0.3,1-0.8,1.2-1.4c0.2-0.6,0.4-1.2,1.1-1.6c0.5,0.3,1,0.6,1.6,0.9c0.4,0.1,0.8,0.1,1.2,0c0.8-0.2,1.4-0.7,1.4-1.5
                        c0-1,0.4-1.8,1.1-2.5c0.5-0.5,0.4-1.2,0-1.7c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-0.5-0.9,0.3-1.5c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.3-0.3,0.7-0.6,0.8-0.9c0.4-1.5,1.6-2.1,2.7-3c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0,0.2,0,0.2,0c0.7,0.6,1.3,1.1,1.9,1.7
                        c0.3,0.3,0.6,0.6,0.8,1c0.5,1.1,1.4,1.6,2.6,1.4c1.6-0.2,2.4,1.2,3.6,1.7c0.1,0.1,0.2,0.4,0.2,0.6c0,0.5,0.1,1.1,0.1,1.6
                        c0,0.8,0.1,1.5,0.4,2.2c0.4,0.8,0.2,1.4-0.4,2c-1,1.1-0.9,2.3-0.6,3.6c0.1,0.2,0.4,0.5,0.7,0.5c0.5,0.1,1,0.1,1.6,0.2
                        c0.2,0.3,0.4,0.5,0.5,0.8c0.3,0.7,0.4,1.4,1.1,1.9c0.2,0.1,0.4,0.5,0.5,0.8c0.3,0.6,0.4,1.2,0,1.8c-0.3,0.5-0.6,0.9-0.9,1.3
                        c-0.7,0.9-0.2,1.8,0.5,2.2c1.1,0.7,2.2,1.4,3.5,1.5c0.3,0,0.6,0.2,0.9,0.3c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.5-0.2,0.6
                        c-0.3,0.2-0.7,0.4-1.1,0.6c-1.5,0.8-2.3,2.2-2.4,3.9c-0.1,1.5-0.4,2.9-1.3,4.2c-0.2,0.3-0.3,0.6-0.3,0.9c0,1.8-1.6,2.3-2.5,3.5
                        c-0.1,0.1-0.4,0.2-0.6,0.2c-0.5,0.1-1.1,0.1-1.6,0.1c-1.9,0.1-3.1,1.2-4.1,2.7c-0.2,0.3-0.3,0.6-0.3,0.9c-0.2,1.2-0.5,2.3-0.2,3.5
                        c0.2,1.1-0.1,1.8-1,2.4c-0.6,0.4-0.6,1.2-0.4,1.7c0.4,1,1,1.9,1.5,2.8c0.4,0.6,1.6,0.6,2,0c0.3-0.3,0.4-0.7,0.7-1.1
                        c0.2-0.2,0.4-0.5,0.7-0.6c0.9-0.4,1.8-0.7,2.6-1c0.1-0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.4,0,0.6c-0.2,0.2-0.5,0.4-0.7,0.6
                        c-0.8,0.6-1,1.3-1,2.2c0,0.8,0.4,1.4,1,1.9c0.3,0.3,0.7,0.4,1.1,0.7c0.7,0.5,1.4,0.5,2.1,0.3c0.2-0.1,0.4,0,0.7-0.1
                        c0.9,0.3,1.6,1.1,2.2,1.8c1.4,1.5,3.1,2.2,4.8,2.9c0.4,0.2,0.8,0.2,1.2,0.3c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.1,0.3,0.3,0.5,0.6
                        c-0.8,1.4-1,3.1-1.9,4.6c-0.6,1-0.8,2.2-1.1,3.2c-0.7,0.6-1.3,1.2-1.9,1.8c-0.4,0.4-0.4,1.1,0,1.5c1.6,1.4,2.1,1.8,0.6,4
                        c-0.6,0.9-0.5,1.8,0.1,2.7c0.9,1.5,2.2,2.3,3.9,2.3c0.7,0,1.5,0,2.2,0c1.1,0.1,2.1-0.3,2.9-1.1c0.5-0.5,1.3-0.3,1.7,0.3
                        c0.1,0.2,0.3,0.3,0.3,0.5c0.5,1.3,1.5,2,3,1.9c1.1-0.1,2.2,1.1,2.4,2.2c0.1,0.5,0.1,1.1,0.1,1.6c0,4.8,0,9.6,0,14.4
                        c0,1.2-0.2,2.3-1.2,3.1C560.5,711.5,560.2,711.9,559.9,712.3z"></path>
                        <path className="st6" d="M450.9,524.1c-1.3-0.4-2.7,0.1-4-0.6c-0.3-0.6-0.5-1.3-0.9-1.9c-0.2-0.4-0.4-0.8-0.7-1c-1-0.6-1.4-1.5-1.8-2.5
                        c-0.3-1-1.1-1.3-2-1.3c-0.7,0-1.3,0.1-1.7,0.7c-0.5,0.6-1,1.1-1.8,1.3c-1,0.3-1.6,1-1.8,2c-0.1,0.5-0.2,1-0.4,1.7
                        c-1.3,0.6-2.6,1.4-4,1.8c-1.2,0.4-2-0.2-2-1.6c0-0.3,0-0.6,0.1-1c0.1-0.6,0.4-1,0.9-1.2c0.6-0.3,1.2-0.4,1.8-0.7
                        c0.6-0.3,1.1-0.7,1.3-1.3c0.4-1,0.9-1.9,1.2-2.9c0.6-1.7,1.5-3.3,2.6-4.7c0.7-0.9,1.4-1.9,2.1-2.8c1.1-1.5,1.8-3.1,2-5
                        c0.3-2.7,0.9-5.5,0.9-8.2c0-2.4,0.6-4.6,1.6-6.8c0.4-0.8,0.7-1.5,1.1-2.3c1-1.8,1.7-3.7,2.1-5.7c1.1-4.8,2.8-9.4,5.2-13.7
                        c0.6-1.1,1.1-2.3,1.7-3.4c0.6-1.3,1-2.6,1.2-4c0.2-1.2,0.5-2.3,0.7-3.4c0.3-1.2,0.3-2.3,0.1-3.5c-0.3-2.4-0.9-4.8-1.8-7.1
                        c-0.4-0.9-0.8-1.7-1.4-2.5c-0.5-0.7-0.9-1.4-1.3-2.2c-0.5-1.1-0.6-2.3-0.3-3.4c0.2-0.8,0.5-1.5,1.3-1.7c2.1-0.6,3.3-2.1,4.2-4
                        c0.4-0.9,1.2-1.8,1.8-2.6c0.6-0.9,1-1.9,1-3c0-1.1,0.1-2.1,0-3.2c-0.2-2.7,0.5-5.3,1.4-7.8c0.1-0.4,0.6-0.7,1-0.6
                        c1.2,0.2,2.3,0.3,3.5,0.6c0.7,0.1,1.3,0,1.8-0.3c1.1-0.7,2.2-1.3,3.3-2c0.4-0.2,0.7-0.4,1-0.5c0.2,0.1,0.4,0,0.4,0.1
                        c1.3,1.3,2.8,2.3,4.3,3.3c10.1,7.1,20.1,14.3,30.2,21.4c1.3,0.9,2.5,2,4.1,2.3c0.2,0,0.5,0.4,0.5,0.7c0.4,1.9,0.7,3.7,0.6,5.7
                        c-0.1,3.4-0.1,6.8,0.1,10.2c0.1,3.1,0.7,6.1,1.3,9.1c0.2,1,0.9,2,1,3c0.1,1-0.3,2.1-0.3,3.1c-0.1,1.6,0,3.2,0,4.8
                        c0,0.9-0.2,1.7-0.7,2.4c-0.7,1-1.3,2-1.9,3c-0.2,0.3-0.2,0.7-0.5,0.8c-1.3,0.8-1.6,2.2-2.1,3.5c-0.1,0.3-0.7,0.6-1.1,0.4
                        c-0.5-0.2-1-0.4-1.4-0.6c-0.6-0.4-1.3-0.8-2.1-0.8c-1.2,0-2.3-0.5-3.2-1.2c-0.8-0.5-1.6-0.5-2.4,0c-0.7,0.5-1.4,0.9-2.1,1.5
                        c-2.4,1.9-4.8,3.9-7.4,5.5c-0.4,0.3-0.9,0.6-1.3,1c-1.5,1.4-3.2,2.5-4.9,3.5c-0.8,0.5-1.6,1-2.2,1.8c-0.6,0.9-1.6,1.4-2.5,1.9
                        c-1,0.5-1.7,1.2-2.5,1.9c-0.4,0.5-1,0.8-1.5,1.2c-0.7,0.5-1.3,1.2-1.4,2c-0.2,1-0.9,1.7-1.2,2.6c-0.2,0.6-0.7,1-1.3,1.2
                        c-0.9,0.3-1.4,0.9-1.5,1.9c-0.1,0.8-0.5,1.4-1,2c-0.4,0.4-0.5,0.9-0.5,1.5c-0.1,0.8,0.3,1.2,1,1.3c0.4,0,0.9,0,1.3,0.1
                        c0.6,0.1,1,0.5,1,1.1c0,1.4,0,2.8,0,4.1c0,0.5-0.5,1-1.1,1c-0.9,0-1.7,0-2.6,0c-3,0-6,0-8.9,0c-0.6,0-1.3,0-1.9,0.2
                        c-0.3,0.1-0.6,0.3-0.6,0.7c-0.1,1.4-0.2,2.7,0.5,4c0.3,0.5,0.1,1-0.1,1.5c-0.1,0.2-0.5,0.5-0.8,0.4c-0.9-0.3-1.5-1.3-2.5-1.3
                        C452.2,525.1,451.5,524.6,450.9,524.1z"></path>
                        <path className="st3" d="M422.5,694.6c1,0.5,2.2,0.9,3.1,1.6c0.9,0.7,1.5,1.8,2.2,2.7c0.4,0.1,0.8,0.3,1.3,0.4c-0.1,0.3-0.2,0.7-0.4,0.8
                        c-0.7,0.6-1.5,1.2-2.2,1.8c-0.4,0.4-0.4,0.9,0,1.4c0.5,0.4,0.9,0.9,1.4,1.3c0.3,0.3,0.7,0.5,1,0.8c0.6,0.7,1.3,1.4,1.7,2.3
                        c0.4,0.9,0,1.9-0.1,2.8c-0.1,0.7,0.1,1.3,0.6,1.7c0.7,0.5,0.9,1.1,0.9,1.9c0,1.3,0,2.6,0,3.8c0,0.9-0.4,1.3-1.4,1.3
                        c-1.1,0-2.1,0-3.2,0c-0.8,0-1.4,0.2-2,0.8c-1.6,1.7-3.3,3.3-5,5c-1.6,1.6-3.1,3.2-4.9,4.6c-1.1,0.8-1.5,2-1.4,3.4
                        c0,0.4,0.1,0.8,0.6,1.1c0.6,0.4,1.2,0.8,1.6,1.5c0.4,0.6,1,0.7,1.6,0.8c0.6,0.1,1-0.1,1.5-0.4c0.5-0.4,1-0.4,1.4,0
                        c0.9,0.8,1.8,0.9,3,0.8c1.6-0.2,1.6-0.1,2.4,1.3c0.3,0.7,0.7,1.3,1.1,1.9c0.5,0.7,1.3,1.1,2.1,1.3c0.5,0.1,1.1,0.1,1.6,0.1
                        c1.5,0.1,2.4-0.1,2.8-1.9c0.1-0.6,0.4-1,1-1.2c0.8-0.3,1.6-0.7,2.3-1.1c1-0.6,1.8,0,1.9,1.2c0,0.2,0,0.4,0,0.6
                        c0,0.7-0.1,1.3-0.8,1.6c-0.8,0.5-1.2,1.1-1.2,2.1c0,0.5-0.1,1-0.4,1.5c-0.4,0.6-0.8,0.8-1.3,0.6c-0.3-0.1-0.6-0.3-0.9-0.4
                        c-0.7-0.2-1.3,0-1.8,0.4c-0.3,0.3-0.6,0.7-0.7,1c-0.1,0.5,0,1.1,0,1.6c-0.1,1-0.6,1.6-1.6,1.6c-0.2,0-0.4-0.1-0.6,0
                        c-1.5,0.2-2.4,1.2-2.1,2.6c0.2,1.1,0,1.9-0.8,2.6c-0.6,0.6-0.8,1.2-0.8,2c0,2-0.2,4-0.7,6c-0.2,0.9,0,1.7,0.8,2.3
                        c0.7,0.5,0.8,1.2,1,1.9c0.1,0.6,0.7,1,1,1.5c0.1,0.2,0,0.5-0.3,0.5c-0.9,0-1.9,0-2.8,0c-0.4,0-0.9,0.1-1.3,0.1
                        c-0.6,0.1-1.4,1-1.3,1.6c0,0.5,0.2,1.1,0.2,1.6c0.1,1.1-0.7,1.9-1.7,1.8c-0.4,0-0.8-0.1-1.3-0.1c-1-0.1-1.9,0.1-2.6,0.9
                        c-0.2,0.2-0.6,0.3-0.9,0.4c-0.2,0.1-0.7-0.2-0.7-0.4c0-0.8,0.1-1.7,0.1-2.5c0-0.4-0.1-0.8-0.2-1.3c-0.2-0.8-1.3-1.3-2-1
                        c-0.4,0.2-0.8,0.4-1.1,0.6c-0.5,0.4-0.9,0.9-1.5,1.2c-1.3,0.6-1.9,1.6-2.3,2.9c-0.1,0.3-0.1,0.6-0.3,0.9c-0.2,0.5-0.6,0.9-1.1,1.1
                        c-0.3,0.1-0.6,0.3-0.9,0.4c-1.7,0.5-3.2,1.2-4.3,2.6c-1.1,0.4-1.3,1.3-1.2,2.3c0,0.3-0.1,0.7-0.3,0.8c-0.6,0.5-1.2,0.9-1.8,1.3
                        c-0.2,0.1-0.5,0.1-0.6,0c-0.5-0.3-1-0.8-1.6-1.1c-1.1-0.7-2.2-0.6-3.2,0.4c-0.3,0.3-0.5,0.7-0.8,1c-0.4,0.6-0.8,0.9-1.5,1.2
                        c-0.7,0.3-1.4,0.6-1.8,1.3c-0.2,0.4-0.4,0.8-0.1,1.2c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.5,0.7,1.1,0.1,1.7c-0.5,0.5-1.2,0.6-1.8,0.3
                        c-0.7-0.3-1.4-0.4-2.2-0.4c-0.8,0-1.4-0.3-1.9-1c-0.3-0.5-1-0.4-1.5-0.5c-0.4-0.1-0.7-0.7-0.5-1c0.5-1.2,1-2.4,1.4-3.5
                        c0.2-0.5,0.4-1,0.5-1.5c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.5-0.4-0.8-0.5c-3.3-1.6-6.7-3.2-10-4.9c-0.8-0.4-1.6-0.6-2.5-0.6
                        c-1.4,0-2.8,0-4.1-0.1c-0.4,0-0.8-0.4-0.8-0.9c0-0.7-0.1-1.5-0.1-2.2c0-11.4,0-22.8,0-34.2c0-0.8,0-1.7,0.1-2.3
                        c0.4-0.7,0.8-0.7,1.2-0.7c1.2,0,2.3-0.4,3.1-1.3c0.1-0.2,0.3-0.3,0.5-0.4c1.4-0.9,1.6-2.2,1.3-3.6c0-0.2-0.1-0.4-0.2-0.6
                        c-0.4-0.8-0.4-1.6,0.1-2.4c0.2-0.4,0.4-0.8,0.4-1.2c0.1-1,0-1.8-0.7-2.7c-1-1.3-2.2-2.3-3.6-3.1c-0.4-1-0.2-2-0.1-2.9
                        c0-0.6,0.6-1.2,1.2-1.3c0.5-0.1,1.1-0.1,1.6-0.3c0.6-0.2,1.2-0.4,1.8-0.7c0.9-0.5,1.4-1.3,1.5-2.3c0-0.5,0-1.1,0.2-1.6
                        c0.1-0.3,0.3-0.6,0.5-0.8c1-0.7,2.1-1.4,3.2-2.1c0.4-0.3,0.9-0.6,1.2-1c0.5-0.6,0.8-1.3,0.4-2.1c-0.6-1.1-0.9-2.4-1.9-3.3
                        c-0.2-0.2-0.1-0.6,0.2-0.8c0.4-0.2,0.8-0.4,1.1-0.6c0.5-0.4,0.6-1.6,0.1-2c-0.6-0.5-1.2-0.9-1.8-1.3c-0.2-0.1-0.1-0.7,0.2-0.8
                        c0.4-0.1,0.8-0.2,1.2-0.4c1-0.3,1.4-1,1-2c-0.2-0.5-0.4-1-0.6-1.5c-0.1-0.2,0.1-0.4,0.3-0.4c1.2,0.1,2.3,0.1,3.5,0.3
                        c2.3,0.4,4.7,0.5,7,0.5c2.6,0,5.1-0.1,7.7-0.1c2.2-0.1,4.4-0.5,6.5-1.4c0.5-0.2,1-0.4,1.5-0.5c1.4-0.4,2.8-0.9,4.2-1.6
                        c1.1-0.5,1.4-0.4,2.2,0.5c1.1,1.2,2.1,2.4,3.1,3.6c0.4,0.5,1,0.7,1.7,0.8c0.4,0,0.8-0.1,1.3,0c0.4,0,0.9,0,1.2,0.2
                        c1.3,0.9,2.6,0.7,4,0.4C422.3,694.6,422.4,694.6,422.5,694.6z"></path>
                        <path className="st4" d="M507.6,313.5c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.2-0.6,1.1-1.3c-0.1-0.6-0.3-1.2-0.5-1.9c0.1-0.1,0.3-0.4,0.5-0.6
                        c0.9-0.7,1-1,0.7-1.9c-0.4-0.9-1-1.5-1.9-1.5c-0.4,0-0.8,0.2-1.2,0.2c-0.3,0-0.7-0.1-0.8-0.4c-0.1-0.3-0.1-0.8,0.1-0.9
                        c1.5-0.6,1.5-2.2,2.2-3.4c0.5-0.8,0.4-1.6-0.3-2.3c-0.2-0.2-0.4-0.5-0.5-0.7c0-0.3,0-0.5,0.1-0.7c0.5-1.2,0.6-2.4-0.2-3.6
                        c-0.7-1.1-0.5-2.4,0.2-3.3c0.9-1.2,1-2.5,0.8-3.9c0-0.1,0-0.2,0.1-0.3c0.3-0.6,0.9-0.9,1.4-1.3c0.3-0.3,0.7-0.6,0.8-1
                        c0.3-1.3,1.1-2.5,1.6-3.8c-0.3-0.6-0.7-1.1-1-1.7c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.4,0.5-0.8,0.9-0.7c0.3,0.1,0.6,0.2,0.9,0.4
                        c0.7,0.3,1.5,0.4,2.1-0.2c0.7-0.6,1.4-0.9,2.3-0.9c0.2,0,0.4-0.1,0.6-0.2c1-0.3,1.6-1.2,1.5-2.2c-0.2-1.1-0.5-2.3-0.2-3.4
                        c0-0.1,0-0.2,0-0.3c-0.2-0.7-0.4-1.4-0.4-2.2c0-0.6,0.5-1.2,0.6-1.8c0.1-0.3,0-0.6-0.2-0.9c-0.1-0.3-0.4-0.5-0.6-0.7
                        c-1.3-1.5-1.3-2.9,0.2-4.4c0.9-0.9,1.6-2,2.9-2.5c0.5-0.2,0.8-0.7,0.8-1.3c0-0.9,0.4-1.6,1.2-2.1c0.6-0.5,0.8-1.3,0.5-2
                        c-0.3-0.8-0.3-1.6,0.1-2.5c0.2-0.4,0.2-0.8,0.3-1.2c0.1-0.8,0-1.5-0.4-2.1c-0.3-0.5-0.7-1.1-0.9-1.6c-0.3-0.7,0.2-1.6,1-1.6
                        c0.1,0,0.2,0,0.3,0c2.1,0.5,3.7-0.6,5-2c1.4-1.5,2.9-2.8,3.8-4.7c0.5-0.9,1.3-1.7,1.9-2.5c0.3-0.4,0.7-0.5,1.1-0.4
                        c1,0.1,1.9,0.1,2.8,0.6c0.6,0.3,1.2,0.6,1.8,0.6c1.2,0.1,2.2,0.7,3.3,1.1c1.2,0.5,2.2,1.1,2.9,2.3c0.3,0.5,0.8,0.7,1.4,0.7
                        c0.6,0,1.3,0,1.9,0c1.2-0.1,2.1,0.4,2.7,1.4c0.9,1.8,2.4,1.9,4.1,1.7c0.7-0.1,1.2-0.5,1.5-1c0.3-0.4,0.4-1,0.7-1.4
                        c0.3-0.6,1.4-0.8,2-0.3c0.2,0.2,0.5,0.4,0.7,0.7c0.6,0.7,1.3,1.5,1.7,2.3c0.6,1,1.4,1.5,2.5,1.7c0.5,0.1,1,0.2,1.5,0.3
                        c0.6,0.9,1.1,1.7,1.6,2.4c0.5,0.7,1.2,1.2,2.1,1.3c0.9,0.2,1.9,0.4,2.8,0.7c0.4,0.1,0.8,0.4,1.1,0.6c0.4,0.7,0.4,1.5,0.3,2.2
                        c-0.2,1.6,0.3,3.2,0.6,4.7c0.3,1.4,1.1,2.5,2,3.6c0.3,0.4,0.9,0.5,1.4,0.8c0.3,0.2,0.5,0.4,0.8,0.6c0.1,0.1,0.1,0.2,0.1,0.3
                        c-0.1,0.1-0.2,0.3-0.4,0.4c-0.5,0.1-1-0.1-1.5-0.3c-0.7-0.3-1.5-0.2-2.2,0.1c-1.2,0.5-2.3,1.2-2.6,2.6c-0.2,0.7-0.6,1.4-1,2
                        c-0.4,0.6-0.4,1.2-0.1,1.8c0.2,0.5,0.5,1,0.7,1.4c0.2,0.4,0,0.7-0.4,1.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.9,0.3-1.1,1-1.1,1.7
                        c-0.1,0.7,0.3,1.3,0.9,1.5c0.3,0.1,0.6,0.1,0.9,0.2c0.6,0.2,0.7,0.6,0.4,1.3c-0.2,0.5-0.5,0.9-0.7,1.4c-0.3,0.8,0,1.8,0.7,2.3
                        c0.5,0.4,1.1,0.6,1.6,1c0.5,0.3,0.8,0.7,0.9,1.2c0.2,0.6,0.2,1.1-0.2,1.5c-1.5,1.2-2.4,2.9-4.1,4c-0.5,0.4-0.7,1-0.7,1.7
                        c0,0.6-0.1,1.3-0.2,1.9c0,0.2-0.2,0.4-0.3,0.7c-0.4,0.1-0.8,0.2-1.2,0.3c-0.7,0.2-1.1,0.6-1.2,1.3c-0.1,0.6,0,1.3,0,1.9
                        c0,0.7,0.2,1.3,0.6,1.8c0.4,0.5,0.8,1,0.9,1.6c0.1,1.3,0.8,2.1,2,2.6c0.2,0.1,0.1,0.7-0.1,0.8c-0.4,0.2-0.8,0.4-1.2,0.4
                        c-0.3,0.1-0.6,0-0.9-0.1c-0.6-0.1-1.3-0.2-1.9-0.3c-1.2-0.1-2.3,0-3.3,0.9c-0.9,0.8-1.9,0.8-3,0.6c-0.7-0.2-1.4-0.4-2.1-0.6
                        c-0.4-0.1-1.1,0.3-1.3,0.7c-0.1,0.3-0.3,0.6-0.3,0.9c-0.1,0.6-0.4,0.9-1,1.1c-0.1,0-0.2,0.1-0.3,0.1c-1.7,0.8-1.8,1.4-0.6,2.9
                        c0.2,0.2,0.4,0.5,0.6,0.7c0.4,0.4,0.5,0.9,0.6,1.5c0,0.3,0,0.6,0,1c0.1,1.2-0.3,2.3-1.1,3.2c-0.5,0.6-0.9,1.1-1,1.9
                        c-0.1,1.6-1.4,2-2.6,2.4c-0.3,0.1-0.6,0-0.9-0.1c-0.5-0.1-1-0.4-1.5-0.5c-1.3-0.3-2.2,0.3-2.6,1.6c-0.1,0.2,0,0.5-0.2,0.6
                        c-0.2,0.2-0.5,0.5-0.7,0.5c-0.6,0-1.3-0.2-1.9-0.3c-1.6-0.3-3.1-0.7-4.7-0.9c-1.8-0.3-3.6-0.3-5.4-0.2c-0.8,0-1.5,0.3-2.1,0.6
                        c-1.1,0.5-2.2,0.7-3.4,0.5c-1.5-0.2-3-0.1-4.4,0.1c-1.5,0.2-3,0.1-4.5,0.2c-1.3,0-2.6-0.3-3.6-1.2c-2-1.7-4.4-2.5-6.9-3
                        c-0.6-0.1-1.3-0.2-1.9-0.4c-0.4-0.1-0.8-0.3-1.1-0.5c-0.4-0.2-0.5-0.7-0.3-1.1c0-0.1,0.1-0.2,0.2-0.3
                        C507.3,314.1,507.4,313.8,507.6,313.5z"></path>
                        <path className="st5" d="M535.5,379.4c0-1.9,0.3-3.7,0.9-5.4c0.6-1.9,0.7-3.7-0.1-5.6c-0.6-1.6-0.9-3.3-0.7-5c0.1-0.7,0-1.5,0-2.2
                        c0-1.7,0.1-2,2-2c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.6,0.1-1.6,0.7-2.1
                        c0.7-0.6,1.5-1.1,2.3-1.6c0.5-0.3,1.4,0.1,1.5,0.7c0.1,0.8,0.1,1.7,0.1,2.5c0,0.7,1,1.3,1.7,1.1c0.7-0.2,1.4-0.5,2.1-0.8
                        c0.4-0.1,1.2,0.3,1.2,0.8c0.1,0.5,0,1,0,1.6c0,0.7,0.4,1.3,1,1.5c0.5,0.2,1,0.3,1.5,0.4c0.5,0.2,0.7,0.5,0.6,1.2
                        c-0.1,0.8,0.3,1.1,0.8,1.6c0.6,0.5,0.6,0.7,0.3,1.3c0,0.1-0.1,0.2-0.2,0.3c-0.6,1.4-0.1,2.3,1.4,2.4c0.6,0,1.3,0,2.1,0
                        c0.3,0.3,0.6,0.7,1,1.1c0.2,0.2,0.5,0.5,0.8,0.6c1.1,0.2,1.9,0.8,2.8,1.3c0.5,0.3,1.4-0.1,1.6-0.6c0.2-0.6,0.3-1.2,0.4-1.8
                        c0-0.1,0.3-0.3,0.4-0.3c0.2,0,0.4,0.1,0.5,0.3c0.6,0.6,0.3,1.4,0.6,2.1c0.3,0.7,0.7,1.1,1.4,1.1c0.2,0,0.4,0,0.6,0
                        c0.3,0,0.6,0.1,1,0.1c0.2,0.3,0.4,0.7,0.6,1.1c0.4,0.6,0.9,0.9,1.6,0.9c0.7,0,1.5-0.1,2.2-0.1c0.2,0.2,0.4,0.3,0.5,0.4
                        c1,1.5,1.4,1.7,3.1,1.2c0.5-0.2,1-0.5,1.5-0.2c1.8,0.8,3.3,0.4,4.6-1c0.2-0.2,0.7,0,0.8,0.2c0.2,0.6,0.3,1.3,0.8,1.7
                        c0.5,0.4,1,0.7,1.5,1c0,0.7-0.6,0.9-0.8,1.3c-0.4,0.8-0.5,1.6,0.2,2.4c0.1,0.2,0.3,0.3,0.5,0.4c0.6,0.5,0.7,1.1,0.6,1.7
                        c-0.1,0.5-0.3,1-0.4,1.5c-0.2,0.6-0.4,1.2-0.4,1.9c-0.1,1.1,0,2.1,0.9,3c0.5,0.5,0.7,1.1,0.6,1.8c-0.1,1.7,0.2,3.4,0.8,5
                        c0.4,1.1,0.6,2.3,0.4,3.5c-0.3,2.6,0.2,5.1,0.7,7.6c0.1,0.5,0.5,0.9,0.8,1.4c0.3,0.3,0.6,0.6,1,0.8c0.7,0.5,1,1.2,0.7,2
                        c-0.5,1.5-0.7,3.1-0.6,4.7c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.5-1.2,0.9-1.6,0.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.4-0.4-0.7-0.8-1-1.2
                        c-0.5-0.5-1.5-0.4-2,0.2c-0.3,0.4-0.5,0.9-0.5,1.5c0,0.2,0,0.4,0.1,0.6c0.2,1.1,0.7,2.1,1.9,2.3c1.9,0.4,2.9,1.8,3.3,3.5
                        c0.2,1,0.6,1.8,1.2,2.6c0.4,0.6,0.5,1.2,0.2,1.8c-0.6,1.1-0.6,2.2-0.5,3.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.3-0.5,0.3
                        c-0.2,0-0.5,0-0.6-0.1c-0.3-0.7-0.5-1.4-0.8-2c-0.4-0.9-1-1.3-1.9-1.5c-0.5-0.1-1.1,0.1-1.4,0.5c-0.4,0.5-0.6,1.1-0.6,1.8
                        c0,0.6,0.3,1.3,0.2,1.9c-0.2,1.4,0.2,2.5,1.4,3.3c0.3,0.2,0.4,0.7,0.6,1.1c-0.4,0.6-0.7,1.2-1,1.7c-0.2,0.3-0.3,0.6-0.3,0.9
                        c-0.2,1.4-0.3,2.7-0.4,4.1c0,0.3,0,0.7,0.2,0.9c0.6,1,0.6,2,0.5,3.1c-0.1,1.2,0,2.3,0.5,3.4c0.2,0.3-0.3,0.9-0.7,0.9
                        c-0.5,0.1-1.1,0.1-1.6-0.1c-0.6-0.3-1.1-0.6-1.7-0.9c-0.6-0.3-1.2-0.3-1.9-0.2c-1,0.2-1.5,1.5-0.9,2.2c0.1,0.2,0.3,0.3,0.5,0.4
                        c1.5,1.4,1.5,1.4,0,2.4c-0.3,0.2-0.6,0.3-0.8,0.6c-0.6,0.9-1.3,1.7-2,2.4c-0.4,0.4-0.4,1.2,0.1,1.7c1,1.1,1.3,2.4,1.4,3.8
                        c0,0.4-0.4,0.8-0.7,0.9c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.5,0.5-2,1.3c-0.3,0.6-0.9,0.6-1.3,0.3c-0.1-0.1-0.2-0.1-0.3-0.2
                        c-0.7-0.7-1.6-0.9-2.6-0.8c-0.5,0.1-1.1,0.2-1.6,0.2c-0.7,0-1.2-0.1-1.7-0.7c-0.8-0.9-2.2-1.3-2.8-2.5c0-0.1-0.2-0.1-0.2-0.2
                        c-0.6-0.6-1.8-0.2-2.3-0.9c-0.4-0.6-0.4-1.4-0.7-2.1c-0.1-0.3-0.4-0.6-0.6-0.7c-1-0.5-1.3-1.3-1.5-2.3c-0.2-0.9-1.2-1.1-2.1-0.8
                        c-0.5,0.2-0.9,0.6-1.3,0.8c-0.9,0.5-1.7,0.4-2.3-0.4c-0.3-0.3-0.5-0.7-0.7-1c-0.6-0.8-1.3-1.4-2.4-1.4c-0.5,0-0.9-0.3-1-0.7
                        c-0.3-1-1-1.6-1.9-2.1c-0.5-0.3-0.8-0.7-0.9-1.2c-0.3-1.4-1.2-2.4-2.1-3.5c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.3,0.1-0.6,0.1-1
                        c-0.1-0.9-0.6-1.6-1.5-1.8c-1.7-0.2-2.8-1.3-3.9-2.5c-0.1-0.1-0.2-0.4-0.3-0.5c0.3-0.6,0.6-1.1,0.8-1.7c0.3-0.7-0.3-1.6-1-1.6
                        c-0.7-0.1-1.5-0.1-2.2-0.1c-0.6,0-0.9-0.4-1-1c0-0.5,0-1.1-0.1-1.6c-0.1-1.1-0.4-2.1-1.2-2.9c-0.2-0.2-0.4-0.5-0.6-0.7
                        c-0.9-0.9-1.1-2-1-3.2c0.1-1,0.5-1.5,1.5-1.8c0.2-0.1,0.4-0.1,0.6-0.1c1.2-0.4,2-1.2,2.3-2.4c0.5-1.8,0.9-3.7,1.1-5.6
                        c0-0.4,0.1-0.8,0.1-1.3c0-1.6,0.8-2.8,1.9-3.9c0.5-0.4,1-0.8,1.5-1.2c0.5-0.5,1.1-1.1,1.2-1.8c0.2-1.2,0.7-2.2,1.3-3.2
                        c0.4-0.7,0.5-1.4,0.5-2.1c0-0.4,0-0.9-0.1-1.3c-0.5-2-0.5-4-0.5-6c0-2.3-0.1-4.7-0.1-7c0.1-2-0.6-3.5-1.9-4.9
                        c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.7-0.6-0.8-1C538.2,381.8,536.8,380.6,535.5,379.4z"></path>
                        <path className="st5" d="M429.9,767.9c-0.7-0.5-1.6-1-1.7-1.8c-0.1-1-0.7-1.5-1.3-2.1c-0.5-0.5-0.7-1.1-0.5-1.7c0.6-1.5,0.5-3.1,0.6-4.7
                        c0-1.1,0.2-2.1,1.1-2.9c0.7-0.6,0.9-1.4,0.8-2.3c0-0.4,0-0.8,0-1.3c0-0.3,0.5-0.8,0.8-0.9c0.3,0,0.6-0.1,0.9-0.1
                        c1.3,0.1,2.2-0.7,2.5-2.4c0.1-0.5,0-1.1,0.1-1.6c0-0.4,0.6-0.7,1-0.6c0.3,0.1,0.6,0.3,0.8,0.4c0.8,0.4,1.5,0.3,2-0.3
                        c0.6-0.8,1-1.6,1.1-2.6c0-0.7,0.4-1.2,1-1.5c0.9-0.6,1.2-1.4,1.1-2.5c0-0.3,0-0.6-0.1-1c-0.2-1.7-1.7-2.6-3.2-1.7
                        c-0.5,0.3-0.9,0.7-1.4,0.8c-1.5,0.3-2.2,1.1-2.6,2.5c-0.1,0.2-0.2,0.4-0.4,0.7c-0.5,0-1.2,0.1-1.8,0.1c-1.5,0.2-2.4-0.6-3.1-1.8
                        c-0.4-0.6-0.6-1.3-1-2c-0.3-0.6-0.9-0.9-1.6-0.9c-0.2,0-0.4,0-0.6,0c-1.2,0.3-2.3,0-3.2-0.8c-0.6-0.5-1.4-0.5-2.1-0.1
                        c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.1-0.9,0.1-1.2-0.2c-0.5-0.4-0.9-0.9-1.3-1.4c-0.9-0.9-1.1-1.7-0.3-2.7c0.1-0.2,0.2-0.4,0.4-0.5
                        c3.4-2.7,6.1-5.9,9.2-8.9c0.2-0.2,0.5-0.4,0.7-0.7c0.6-0.7,1.4-0.9,2.3-0.9c1.2,0,2.3,0,3.5,0c1,0,1.3-0.3,1.3-1.4
                        c0-1.7,0-3.4,0-5.1c0-0.8-0.3-1.4-0.9-1.9c-0.6-0.4-0.6-1.1-0.5-1.7c0.1-0.3,0.1-0.6,0.2-0.9c0.2-1,0-1.9-0.7-2.7
                        c-1-1.1-1.9-2.3-3.1-3.1c-0.4-0.2-0.3-0.8,0-1.1c0.6-0.5,1.4-1,1.9-1.6c0.3-0.3,0.3-0.8,0.4-1.2c0.1-0.5-0.2-0.9-0.6-1
                        c-0.4-0.1-0.8-0.2-1.2-0.3c-0.2-0.3-0.5-0.5-0.6-0.7c-0.9-1.9-2.7-2.6-4.5-3.4c-0.6-0.3-1.2-0.2-1.9-0.1c-0.6,0.1-1.3,0-1.9,0
                        c-0.9-0.9-2-1-3.2-0.6c-0.5,0.1-1.1-0.1-1.4-0.5c-1.3-1.4-2.6-2.8-3.7-4.4c-0.1-0.1,0-0.2-0.1-0.4c0.3-0.2,0.6-0.5,0.9-0.8
                        c3.2-2.3,6.5-4.4,10.1-6.2c2.4-1.2,4.8-2.3,7.2-3.4c0.4-0.2,0.8-0.1,1.2,0.1c1.3,0.6,2.6,0.7,4,0.3c0.6-0.2,1.2-0.3,1.8-0.5
                        c1.6,0.7,2.8-0.2,4-1.2c0.3-0.3,0.8-0.4,1.1-0.6c0.5-0.3,1,0,1.2,0.6c0.1,0.3,0.1,0.6,0.2,0.9c0.3,1,1.4,1.4,2.3,1
                        c0.2-0.1,0.4-0.2,0.6-0.3c0.7-0.5,1.3-1.1,2.1-1.7c0-0.5,0-1,0-1.5c0-0.4,0.4-0.9,0.7-0.9c0.4-0.1,0.8-0.2,1.3-0.1
                        c1.2,0.1,2.1-0.4,2.7-1.3c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.3,0.3,0.3,0.4c0.1,0.5,0.1,1.1,0.3,1.5
                        c0.7,1.5,2,2.1,3.7,1.8c1.2-0.2,2.3-0.3,3.5-0.4c0.6-0.1,1.2,0.5,1.2,1.2c0,0.4,0.1,0.9,0,1.3c-0.1,1.6-0.3,3.2-0.1,4.8
                        c0,0.1-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4-0.2-0.7-0.4-1-0.7c-0.8-0.8-1.8-1.2-2.9-1.3c-0.5,0-1.1,0.1-1.5,0.4
                        c-0.8,0.4-1,1.6-0.5,2.2c0.2,0.3,0.4,0.6,0.6,0.7c0.8,0.4,1.2,1.2,1.4,2c0.2,0.7,0.7,1.1,1.4,1.2c0.8,0.1,1.3,0.6,1.7,1.3
                        c0.4,0.9,1.2,1.5,2.2,1.6c1.1,0.1,1.5,0.6,1.6,1.6c0.1,0.7,0.6,1.1,1.1,1.5c0.5,0.4,0.5,0.7,0.1,1.3c-0.2,0.3-0.6,0.6-0.7,1
                        c-0.2,0.8-0.7,1.2-1.4,1.6c-0.2,0.1-0.3,0.3-0.5,0.4c-0.5,0.5-0.6,1.5-0.1,2c0.3,0.3,0.6,0.6,0.9,0.9c0.5,0.4,1,0.7,1.5,1.1
                        c-0.1,0.4,0,0.7-0.2,1c-0.3,0.7-0.2,1.2,0.2,1.8c0.2,0.2,0.4,0.5,0.6,0.7c0.3,0.4,0.5,0.9,0.3,1.5c-0.1,0.3-0.2,0.6-0.3,0.9
                        c-0.2,0.9,0,1.7,0.7,2.3c0.5,0.4,0.9,0.9,1.3,1.4c0.4,0.4,0.3,1-0.1,1.4c-0.5,0.5-1.1,1-1.7,1.5c-0.9,0.7-1.4,1.5-1.3,2.7
                        c0.1,0.8-0.1,1.5-0.4,2.2c-0.3,0.6-0.3,1.2-0.4,1.8c-0.2,1.4,0.3,2.5,1.4,3.3c2.5,2,5,4,7.5,6c0.5,0.4,1,0.8,1.5,1.2
                        c0.8,0.6,1,1.3,0.7,2.3c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.7,0.2,0.9c0.9,1.1,1,2.3,0.9,3.6c0,0.4,0,0.8-0.1,1.3
                        c0,0.6-0.4,1-1,1.1c-0.4,0-0.9,0-1.3,0c-1,0.1-1.6,0.6-1.9,1.5c-0.2,0.5-0.3,1-0.4,1.4c-0.3,0.2-0.4,0.4-0.6,0.4
                        c-1.3,0.4-2.2,1.2-2.3,2.7c0,0.4-0.6,0.6-1,0.4c-0.5-0.2-0.9-0.5-1.4-0.8c-0.2-0.1-0.4-0.1-0.6-0.1c-0.5-0.1-1.2,0.4-1.2,0.8
                        c-0.1,0.7-0.2,1.5-0.2,2.2c0,3.2,0,6.4,0,9.6c0,0.8-0.2,1.4-0.9,1.9c-0.9,0.7-1.1,1.7-1,2.9c0.1,1.4-0.8,2.3-1.8,3.1
                        c-0.2,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.7-0.3-0.7-0.5c0-1-0.1-1.9-0.1-2.9c0-0.9,0-1.7,0-2.6c0-1.2-0.5-1.3-1.4-1.3
                        c-0.7,0-1.5,0-2.2-0.1c-1.2,0-2.1,0.4-2.6,1.5c-0.2,0.5-0.5,0.9-0.8,1.4c-0.6,1.2-1.3,1.3-2.3,0.6c-0.3-0.2-0.5-0.4-0.8-0.5
                        c-0.4-0.1-0.8-0.1-1.2,0.1c-0.3,0.2-0.5,0.4-0.8,0.5c-0.5,0.4-1,0.4-1.5,0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-1.3-1-2.7-0.4-3.7,0.6
                        c-0.3,0.3-0.5,0.7-0.8,1c-1,1.2-2,1.5-3.3,0.6c-1.3-0.8-2.4-0.7-3.6-0.1c-0.7,0.3-1.3,0.7-2,1.1
                        C430.8,767.1,430.3,767.5,429.9,767.9z"></path>
                        <path className="st6" d="M492.3,549.9c-0.2-0.6-0.4-1.1-0.6-1.5c-0.2-0.3-0.8-0.5-1.1-0.3c-0.8,0.7-1.7,1.2-2.4,2
                        c-0.7,0.8-1.6,1.4-2.4,2.1c-1.7,1.5-3.7,1.4-5.7,0.9c-1.2-0.8-2.4-0.5-3.6-0.1c-1.4,0.5-2.9,0.7-4.3,1c-0.2,0-0.4,0.1-0.6,0
                        c-2-0.9-4.4-0.9-6.4-2.3c-0.3-0.4-0.5-1-1-1.5c-0.7-0.7-1.4-1.2-2.2-1.8c-0.8-0.5-1.6-0.4-2.5-0.1c-0.3,0.1-0.6,0.4-0.7,0.6
                        c-0.4,1-0.8,2-1.1,3c-0.3,1-0.4,2.1-0.6,3.1c-0.1,0.7-0.3,1.2-0.9,1.6c-0.5,0.4-0.9,1-0.9,1.6c-0.1,1-0.9,1.6-1.2,2.6
                        c-0.6,0.1-1.1,0.2-1.7,0.2c-1.8-0.1-3.6,0.2-5.4-0.2c-0.1,0-0.2-0.1-0.4-0.2c-0.1-0.7-0.1-1.4-0.2-2.2c-0.1-0.8-0.5-1.2-1.2-1.2
                        c-1,0-1.9,0-2.9,0c-1.2,0-2.3,0-3.5,0c-0.8,0-1.1-0.2-1-0.5c0.2-0.5,0.6-0.6,1-0.7c1-0.2,2.1-0.4,3.1-0.5c0.8-0.1,1.7-0.2,2.5-0.3
                        c0.7-0.1,1.2-0.5,1.5-1.1c0.9-1.8,1.7-3.7,2-5.7c0.1-0.4,0-0.8,0-1.3c-0.1-1.7-1-2.9-2.9-3.3c-0.9-0.2-1.9-0.1-2.9-0.1
                        c-0.8,0-1.7,0-2.5,0c-0.6,0-1.2-0.7-1.3-1.2c-0.1-0.7-0.2-1.5-0.2-2.2c0-1.6-0.6-2.9-1.7-4c-1.8-1.9-1.6-2.2-0.5-4.5
                        c0.5-1,1.2-2,1.8-3c0.6-0.9,1-1.8,1.3-2.9c0.2-1,0-1.9-0.7-2.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.7-0.3-0.9-0.8-0.9-1.5
                        c0.1-0.8,0.4-1.5,1.2-1.7c1.1-0.3,1.8-1.1,2.5-1.8c0.4-0.4,1.5-0.2,1.7,0.3c0.2,0.5,0.4,1,0.6,1.5c0.1,0.3,0.2,0.7,0.5,0.8
                        c1.5,0.8,1.7,2.3,2.5,3.6c0.1,0.2,0.4,0.4,0.7,0.6c0.3,0.1,0.6,0.1,0.9,0.2c0.5,0.1,1.1,0.1,1.6,0.1c0.8,0,1.4,0.3,2,0.8
                        c1,1,2.5,1.2,3.6,2c0.3,0.2,0.8,0.1,1.2,0c0.3-0.1,0.6-0.3,0.7-0.5c0.4-0.9,0.7-1.8,0.2-2.7c-0.5-0.9-0.7-1.8-0.5-2.8
                        c0-0.3,0.5-0.8,0.8-0.8c0.7,0,1.5-0.1,2.2-0.1c3.1,0,6.2,0,9.3,0c0.5,0,1.1,0,1.6,0c0.6,0,1-0.4,1-1c0-1.9,0-3.8,0-5.7
                        c0-0.1,0-0.2,0-0.3c0-0.4-0.4-0.7-0.8-0.8c-0.4-0.1-0.8-0.1-1.3-0.1c-0.9-0.1-1.1-0.6-0.7-1.3c0.4-0.6,0.9-1.2,1-2
                        c0.2-0.9,0.6-1.5,1.6-1.7c0.5-0.1,1-0.5,1.1-1.1c0.4-1.3,1.5-2.3,1.7-3.8c0-0.3,0.4-0.7,0.8-1c0.8-0.5,1.5-1.2,2.2-1.9
                        c0.4-0.4,0.8-0.7,1.2-1c1.4-0.8,2.7-1.6,3.7-2.9c0.4-0.5,1-0.8,1.5-1.1c1.8-1,3.4-2.1,5-3.5c0.4-0.4,0.8-0.7,1.3-1
                        c2.7-1.7,5.2-3.7,7.7-5.7c0.4-0.3,0.9-0.6,1.3-0.9c0.8-0.5,1.6-0.7,2.4-0.1c1.1,0.7,2.3,1.1,3.6,1.1c0.4,0,0.8,0.2,1.1,0.5
                        c0.7,0.6,1.5,0.8,2.3,0.9c0.4,0.1,0.5,0.4,0.6,0.7c0.1,0.2,0.1,0.4,0.1,0.6c-0.2,5.1-0.5,10.2-0.7,15.3c-0.1,1.1-0.1,2.1-0.2,3.2
                        c-0.1,1.5-0.2,3-0.5,4.4c-0.4,1.9-0.6,3.8-0.8,5.7c-0.1,1.3-0.5,2.5-1.1,3.6c-0.6,1.3-1.2,2.7-1.8,4.1c-0.4,0.9-0.6,1.8-0.6,2.8
                        c0,3.9,0,7.9,0,11.8c0,0.3,0.1,0.6,0.1,0.9c0.1,0.7,0.5,1.2,1.1,1.5c0.7,0.3,1.4,0.5,2.1,0.8c0.6,0.3,1.2,0.3,1.9,0.2
                        c0.5-0.1,1.1-0.2,1.6-0.2c0.3,0,0.6,0.2,0.8,0.4c0.3,0.3,0.4,0.7,0.6,1.1c0.3,0.6,0.7,1,1.5,1.1c1.5,0.2,2.3,1.4,3.3,2.4
                        c0.1,1.4,1.2,2.4,1.5,3.8c0.3,1.6,0.2,2.9-0.6,4.3c-0.3,0.5-0.7,0.8-1.3,0.7c-0.5,0-1-0.2-1.5,0.2c-0.5,0.4-0.4,0.9-0.6,1.4
                        c0,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2-0.3-0.3-0.5-0.6-0.8c-0.3-0.3-0.9-0.3-1.2-0.1
                        c-0.3,0.2-0.5,0.4-0.7,0.6c-1.3,1.3-2.8,1.9-4.6,1.8c-1.2-0.1-2.3-0.1-3.5,0c-1.7,0.1-3.2-0.3-4.6-1.1c-1.1-0.7-2.3-1.1-3.6-1.1
                        c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.3-0.4-0.4c-0.1-0.6-0.1-1.3-0.1-1.9c0-0.1,0.2-0.4,0.4-0.4c0.4-0.1,0.8-0.2,1.2-0.3
                        c0.5-0.1,0.7-0.4,0.8-0.9c0-0.4,0-0.8,0-1.3c0-0.3-0.2-0.6-0.6-0.7C493.7,550.1,493,550,492.3,549.9z"></path>
                        <path className="st5" d="M524.2,727.6c-1.4,0.1-2.8,0.5-4.2,1.2c-1.4,0.7-3,1.1-4.4,1.8c-1.6,0.8-3.2,1.1-4.9,1c-0.4,0-0.9,0-1.3,0
                        c-3.4,0-3.4-0.3-3.4,3.2c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-1.2,0-2.3,0-3.5,0
                        c-2.1,0-2.2,0.2-2.4,2.1c0,0.4-0.2,0.8-0.3,1.1c-2.4,0.3-12.9,0.4-18,0.1c-0.6-0.2-0.7-0.8-1-1.2c-0.3-0.4-0.6-0.7-1-0.8
                        c-1.1-0.2-1.8-0.6-1.8-1.8c-0.7-0.9-0.8-1.9-0.6-3c0.1-0.8-0.2-1.4-0.8-1.9c-2.6-2-5.2-4.1-7.7-6.2c-0.7-0.5-1.3-1.1-2-1.6
                        c-0.7-0.6-0.9-1.4-0.7-2.3c0-0.2,0-0.4,0.1-0.6c0.8-1.4,0.9-3,1-4.6c0.9-0.8,1.8-1.6,2.7-2.4c0.6-0.6,0.7-1.6,0.2-2.3
                        c-0.4-0.5-0.8-0.9-1.3-1.4c-0.7-0.6-1.1-1.3-0.5-2.2c0.6-1.1,0.3-2.1-0.5-2.9c-0.5-0.5-0.6-1.1-0.3-1.7c0.3-0.8,0-1.4-0.7-1.9
                        c-0.6-0.5-1.2-0.9-1.8-1.4c-0.3-0.3-0.3-0.9,0.1-1.1c0.9-0.6,1.7-1.2,2.1-2.3c0.1-0.4,0.6-0.6,0.8-1c0.5-0.7,0.5-1.4-0.2-2
                        c-0.8-0.6-1.3-1.4-1.4-2.4c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.5-0.6-0.8-1.2-0.9c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2-0.4-1.6-0.9
                        c-0.1-0.1-0.1-0.2-0.1-0.3c-0.4-1-1.1-1.5-2.1-1.7c-0.4-0.1-0.8-0.4-0.9-0.8c-0.1-1.1-0.7-1.7-1.6-2.2c-0.1,0-0.2-0.1-0.2-0.2
                        c-0.1-0.3-0.4-0.5-0.1-0.9c0.2-0.3,0.4-0.5,0.7-0.5c0.3,0,0.6,0,0.9,0.1c0.8,0.3,1.5,0.7,2.1,1.4c0.4,0.5,1.1,0.7,1.7,0.7
                        c0.8,0.1,1.5-0.6,1.5-1.3c-0.2-1.8-0.1-3.6,0-5.4c0-0.4,0-0.9,0-1.3c-0.1-1.2-1-2-2.1-1.9c-0.8,0.1-1.7,0.2-2.5,0.3
                        c-0.4,0.1-0.8,0.2-1.2,0.3c-1.4,0.1-1.9-0.1-2.6-1.3c-0.4-0.7-0.3-1.9,0.8-1.9c1.9-0.1,3.8-0.7,5.7-0.6c0.4,0,0.8-0.2,1.2-0.4
                        c0.5-0.2,1-0.6,1.5-0.6c1.4-0.2,2.1-0.7,2-2.3c-0.1-0.8,0.4-1.4,0.9-2c0.3-0.4,0.7-0.8,0.9-1.3c0.3-0.4,0.9-0.7,1.4-0.4
                        c0.8,0.4,1.5,0.8,2.3,1.1c0.6-0.3,1.3-0.4,1.8-0.8c0.7-0.4,1.3-0.6,2.1-0.5c0.5,0,1.1,0.1,1.6-0.1c1.7-0.7,3.1,0.2,4.6,0.8
                        c0.5,0.2,0.9,0.6,1.4,0.8c0.9,0.4,1.9,0.4,2.7-0.3c0.5-0.5,1.1-0.5,1.8-0.3c0.3,0.1,0.6,0.2,0.9,0.2c0.8,0.1,2-0.2,2-1.5
                        c0-0.9,0.3-1.6,0.9-2.3c0.7-0.7,1.3-1.4,1.9-2.2c1.3-1.6,1.4-1.6,2.8-0.2c0.5,0.5,1,1,1.8,1.3c1.6,0.5,1.5,0.4,0.7,1.8
                        c-0.3,0.5-0.4,1-0.1,1.5c0.3,0.5,0.7,0.7,1.3,0.7c0.3,0,0.6,0,0.9-0.1c0.5-0.1,1-0.3,1.5-0.5c1.5-0.5,2.8-0.4,3.6,2
                        c0.1,0.3,0.2,0.6,0.3,0.9c0.4,0.2,0.8,0.4,1.1,0.5c1,0.4,1.2,1.4,1.6,2.2c0.1,0.1-0.1,0.4-0.2,0.6c-0.4,0.5-0.9,0.9-1.3,1.3
                        c-0.6,0-1.1-0.1-1.6-0.1c-0.5,0-1,0.6-1,1.1c0,0.5,0.2,1,0.3,1.6c0.1,0.5-0.4,1-1,1c-0.4,0-0.8-0.1-1.2-0.1
                        c-0.8,0.9-1.8,1.4-2.9,1.7c-0.6,0.2-0.8,1.8-0.4,2.3c0.1,0.2,0.3,0.3,0.5,0.4c0.4,0.4,1.3,0.5,1.2,1.3c-0.1,0.6-0.8,0.5-1.3,0.7
                        c-0.9,0.5-1.1,1-0.9,2.1c0.1,0.5,0.4,1,0.1,1.5c-0.7,1.9,0,3.8,0.5,5.6c0.8,1.1,0.9,2.3,0.7,3.6c-0.1,0.7,0.3,1.2,0.8,1.6
                        c0.8,0.6,1.7,0.9,2.7,0.8c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.5c-0.1,0.4-0.3,0.8-0.4,1.2c-0.4,1.1-0.3,1.7,0.9,2.4
                        c0.7,0.4,1.1,0.9,1.3,1.7c0.1,0.6,0.6,1.1,0.9,1.8c-0.9,1-1.8,2.1-2.3,3.4c-0.1,0.4-0.2,0.8-0.1,1.2c0,0.3,0.2,0.6,0.6,0.6
                        c0.7,0.1,1.5,0.1,2.2,0.1c4.6,0,9.1,0,13.7,0c0.8,0,1.7,0,2.5,0.1c0.4,0,0.7,0.4,0.8,0.9c0,0.2,0.1,0.4,0.1,0.6
                        c0,5.1,0.1,10.2,0.1,15.3C524.7,726.6,524.7,727.1,524.2,727.6z"></path>
                        <path className="st2" d="M573.3,288c1.6-1.6,3.1-3.1,4.5-4.6c0.3-0.3,0.4-0.8,0.4-1.2c0-1.1-0.5-2.1-1.4-2.7c-0.5-0.3-1.1-0.6-1.6-1
                        c-0.3-0.2-0.5-0.9-0.3-1.2c0.2-0.4,0.4-0.7,0.6-1.1c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.6-0.3-1.3-0.9-1.5c-0.5-0.2-1.1-0.2-1.4-0.6
                        c-0.2-0.2-0.2-0.4-0.1-0.6c0.2-0.5,0.8-0.6,1.2-1c0.5-0.5,0.6-1.1,0.5-1.7c-0.2-0.8-1-1.5-0.7-2.4c0.2-0.8,0.6-1.6,1-2.3
                        c0.1-0.2,0.2-0.4,0.3-0.6c0.6-1.2,1.6-1.7,2.9-1.7c0.6,0,1.3,0.2,1.9,0.3c0.4,0,0.8,0,1.3-0.1c0.5-0.1,0.7-0.5,0.9-0.8
                        c0.2-0.3,0-0.9-0.3-1.1c-0.3-0.3-0.7-0.5-1-0.7c-0.5-0.3-0.9-0.5-1.3-0.7c-0.2-0.4-0.4-0.6-0.6-0.9c-1.2-1.5-1.5-3.3-1.6-5.1
                        c0-0.6,0-1.3,0-1.9c0-2.6,0-2.1,1.9-3.7c0.4-0.4,0.9-0.5,1.5-0.5c1.3,0.1,2.3-0.4,3.1-1.3c0.7-0.8,1.7-0.8,2.7-0.5
                        c0.6,0.2,1.2,0.5,1.8,0.7c1.1,0.5,2.1,0.4,3.1-0.1c0.5-0.2,0.9-0.6,1.4-0.8c0.3-0.1,0.7-0.2,0.9,0c0.6,0.4,1.2,0.8,2,0.8
                        c0.3,0,0.7,0.2,0.8,0.4c0.6,0.8,1.3,1.1,2.2,1.1c0.2,0,0.4,0.1,0.6,0.2c0.5,0.2,0.8,0.9,0.5,1.3c-0.1,0.2-0.3,0.3-0.4,0.5
                        c-0.4,0.4-0.7,0.8-0.7,1.3c-0.1,1.7-0.1,3.4,0.7,5c0.3,0.6,0.3,1.2-0.3,1.8c-0.2,0.2-0.4,0.5-0.6,0.8c-0.4,0.6-0.4,1.2-0.2,1.8
                        c0.1,0.3,0.3,0.6,0.4,0.9c0.3,0.5,0.4,1,0,1.5c-0.7,0.9-0.6,1.8-0.2,2.7c0.2,0.5,0.5,0.9,0.7,1.4c0.2,0.4,0.4,0.8,0.1,1.2
                        c-0.6,0.7-0.8,1.5-0.9,2.3c-0.3,2.5-0.7,5.1-1,7.6c0,0.4,0,0.9,0.2,1.2c0.3,0.7,0.1,1.2-0.3,1.8c-0.2,0.3-0.5,0.8-0.5,1.1
                        c0.2,1.5,0.1,3,0,4.5c0,0.7,0.6,1.5,1.3,1.7c0.4,0.1,0.8,0.1,1.3,0.2c0.8,0.1,1,0.3,1.2,1.2c0.1,0.3,0.1,0.6,0.1,0.9
                        c0.1,0.7,0.5,1.3,1.1,1.5c0.9,0.3,1.4,0.8,1.7,1.7c0.1,0.2,0.2,0.5,0.3,0.5c1.6,0.8,2.3,2.5,3.9,3.2c0.8,0.4,1.2,1.1,1.2,2.1
                        c0,0.9,0.2,1.7,0.3,2.5c0.2,1.2,0.2,2.5,0.3,3.8c0,0.1,0,0.2-0.1,0.3c-0.8,1.3-0.4,2.4,0.5,3.5c0.3,0.3,0.4,0.8,0.5,1.2
                        c0.3,1.1,1,1.6,2.1,1.5c0.3,0,0.6-0.1,1-0.1c0.4,0,0.9,0.2,1,0.6c0.3,0.7,0.8,1.2,1.4,1.7c0.1,0.1,0.1,0.2,0.1,0.3
                        c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0-0.2,0-0.3,0c-1.5,0.2-1.8,0.5-1.7,2c0.1,0.7-0.1,1.2-0.6,1.7c-0.2,0.1-0.4,0.2-0.5,0.4
                        c-0.4,0.4-0.6,0.9-0.4,1.5c0.2,0.7,0.8,1.1,1.4,1.1c0.5,0.1,1-0.1,1.6-0.2c0.7-0.1,1.2,1.1,1,1.7c-0.2,0.5-0.7,0.8-1.1,1.2
                        c-0.5,0.6-0.5,1.2-0.1,1.7c0.6,0.7,1.3,0.9,2,0.6c0.6-0.2,1-0.7,1.4-1.3c0-0.1,0.2-0.1,0.3-0.1c0.1,0.1,0.3,0.2,0.4,0.4
                        c0.1,0.3,0.1,0.6,0.1,0.9c0,0.4-0.3,0.6-0.6,0.7c-0.4,0.1-0.8,0.3-1.2,0.3c-0.9,0-1.6,0.4-2.1,1.2c-0.4,0.6-0.9,0.8-1.6,0.9
                        c-0.6,0.1-1.2,0.5-1.7,0.7c-0.4,0.2-0.8,0.5-1.2,0.5c-1.4-0.1-2.4,0.8-3.4,1.5c-3,2.2-3.1,2.2-6.7,2.5c-2.9,0.3-5.7,0-8.6-0.2
                        c-0.6,0-1.3-0.3-1.8-0.6c-1.2-0.6-2.4-0.9-3.7-0.8c-0.8,0.1-1.5-0.2-2.1-0.7c-0.7-0.5-1.3-1.1-2-1.6c-0.7-0.6-1.5-0.8-2.4-0.8
                        c-1.6,0-3.2,0-4.8,0c-1.2,0-2.2,0.6-3.3,1c-1.3,0.5-2.5,0.3-3.5-0.7c-1.1-1.1-2.3-2.2-3.4-3.4c-1.2-1.1-2.4-2-4.1-2.3
                        c-0.7-0.1-1.4-0.5-2.1-0.8c-1.7-0.9-3.5-1.6-5.4-1.7c-1.2-0.1-2.3-0.4-3.5-0.6c-0.9-0.2-1.4-0.9-1.1-1.9c0.1-0.2,0.2-0.4,0.3-0.6
                        c0.2-0.3,0.8-0.6,1.1-0.5c0.6,0.2,1.2,0.4,1.8,0.6c0.6,0.2,1.3,0.2,1.9-0.1c0.5-0.2,0.9-0.5,1.4-0.7c0.6-0.3,1-0.8,1.1-1.5
                        c0.1-1,0.6-1.8,1.2-2.5c0.9-1.1,1.3-2.4,1.2-3.9c-0.1-1.1-0.3-2.1-1.1-2.9c-0.2-0.2-0.4-0.5-0.6-0.7c-0.3-0.4-0.2-0.9,0.2-1.1
                        c0.2-0.1,0.4-0.1,0.6-0.2c0.8-0.2,1.3-0.7,1.4-1.5c0-0.2,0.1-0.4,0.2-0.6c0.2-0.3,0.4-0.5,0.8-0.4c0.7,0.2,1.4,0.4,2.2,0.5
                        c1,0.2,1.9,0.1,2.7-0.5c0.3-0.3,0.7-0.5,1.1-0.7c0.7-0.4,1.4-0.5,2.1-0.4c0.7,0.1,1.5,0.3,2.2,0.5c0.7,0.1,1.5,0,2.2-0.3
                        c1.1-0.5,1.3-2.3,0.2-2.8c-0.8-0.4-1.3-1-1.4-1.9c-0.1-0.8-0.6-1.4-1.1-1.9c-0.6-0.6-0.6-1.3-0.6-2.1c0-0.9,0.2-1.2,1.3-1.5
                        c0.2-0.1,0.4-0.1,0.6-0.1c0.5-0.1,0.7-0.4,0.8-0.9C572.9,290.3,573.1,289.2,573.3,288z"></path>
                        <path className="st7" d="M434.5,228.3c-0.4,1.1-0.6,1.9-0.9,2.6c-0.2,0.2-0.4,0.4-0.6,0.5c-0.6,0.2-1.2,0.4-1.8,0.7
                        c-0.3,0.2-0.6,0.6-0.9,0.9c-0.4,0.6-0.4,1.3,0.1,1.7c0.8,0.7,1.7,1.3,2.5,2c0.3,0.3,0.7,0.7,0.7,1c0,1.1,0.6,2,1.1,2.9
                        c0.4,0.8,0.6,1.8,0.9,2.7c0.3,0.8,0.3,1.7,0,2.5c-0.4,1.1-0.2,2.1,0.4,3.1c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.4-0.2,0.9-0.5,1.1
                        c-0.1,0-0.2,0.1-0.3,0.1c-1.9,0.1-2.8,1.2-3.2,3c-0.1,0.4-0.1,0.9-0.4,1.2c-0.9,1.4-0.8,2.6,0.4,3.7c0.4,0.4,0.4,1,0.3,1.5
                        c-0.1,0.3-0.1,0.6-0.3,0.9c-0.4,0.8-0.5,1.6-0.5,2.5c0,0.5,0,1.1-0.1,1.6c-0.1,0.5-0.3,1.1-0.7,1.4c-0.9,0.7-1.7,1.6-3,1.6
                        c-0.8,0-1.4,0.5-1.8,1.2c-0.7,0.9-1.3,1.9-1.4,3.2c0,0.7-0.3,1.5-0.5,2.2c-0.2,0.7-0.6,1.2-1.2,1.4c-1.7,0.5-2.8,1.9-3.9,3.1
                        c-1,1.1-1.6,2.5-2.9,3.3c-0.5,1.5-1.7,2.4-2.5,3.7c-0.7,1.2-1.3,2.4-1.2,3.9c0,1.7-0.4,3.4,0,5.1c0.1,0.3-0.2,0.6-0.5,0.7
                        c-0.4,0.1-0.6,0-0.8-0.3c-0.5-0.7-0.9-1.4-0.9-2.3c0-0.4,0-0.9-0.1-1.3c-0.1-0.9-0.8-1.6-1.7-1.4c-1.1,0.2-2.1,0.3-3.2-0.2
                        c-0.3-0.4-0.6-0.9-0.8-1.3c-0.4-0.6-1-0.7-1.7-0.7c-0.5,0-1.1,0.5-1.2,0.9c-0.1,0.5-0.1,1-0.2,1.6c-0.1,0.5-0.1,1-0.3,1.6
                        c0,0.1-0.3,0.3-0.5,0.3c-0.2,0-0.4,0-0.6-0.2c-0.2-0.2-0.4-0.5-0.5-0.8c-0.2-0.9-0.8-1.5-1.7-1.8c-0.8-0.3-1.3-0.8-1.6-1.5
                        c-0.3-0.6-0.5-1.2-0.7-1.8c-0.5-1.3-0.9-2.6-1.4-3.9c-0.1-0.2,0.2-0.7,0.5-0.7c0.5-0.1,1.1-0.1,1.6-0.2c0.4-0.1,0.8-0.4,0.9-0.8
                        c0.1-0.5,0.2-1,0.2-1.6c0.1-0.5,0.3-0.8,0.8-0.9c0.3-0.1,0.6-0.2,0.9-0.2c0.8-0.1,1-0.7,1.2-1.3c0-0.1,0.1-0.2,0.2-0.2
                        c0.7,0,0.8,0.6,1.1,1c0.4,0.5,0.9,0.5,1.4,0.4c0.4-0.1,0.9-0.5,0.9-0.8c0-1.1-0.1-2.1,0-3.2c0-0.9-0.1-1.7-0.7-2.3
                        c-0.3-0.3-0.4-0.7-0.5-1.1c-0.2-0.9-0.2-1.9-0.3-3c0.2-0.2,0.4-0.6,0.8-0.9c0.9-0.7,1.5-1.5,1.4-2.7c0-0.7,0.4-1.1,0.9-1.6
                        c0.8-0.9,0.8-1.5,0-2.5c-0.3-0.4-0.6-0.9-0.8-1.4c-0.5-1.3-2.2-1.9-3.5-1.2c-0.6,0.3-1.1,0.7-1.6,1.1c-0.5,0.4-1.1,0.6-1.8,0.5
                        c-0.7-0.1-1.5-0.3-2.2-0.1c-0.6,0.1-1,0.5-1.2,1c-0.2,0.5-0.2,1.1-0.4,1.5c-0.4,1-1.1,1.8-2.3,1.9c-0.2,0-0.4,0-0.6,0.1
                        c-1,0.3-2.2-0.2-3.1,0.5c-0.8,0.5-1.4,1.3-2.2,1.9c-0.8,0-1.7,0-2.5,0c-0.4,0-0.8-0.4-0.8-0.8c0-1-0.1-1.9-0.1-2.9
                        c0-1,0.1-1.2,1.3-1.4c0.7-0.1,1.2-0.4,1.5-1c0.7-1.3,1.4-2.6,2.3-3.8c0.4-0.5,0.7-1.1,0.9-1.7c0.6-1.9,1.7-3.6,3-5.2
                        c0.3-0.4,0.6-0.9,0.8-1.4c0.3-0.6,0.3-1.3,0-1.9c-0.1-0.3-0.4-0.6-0.7-0.6c-1.1-0.1-2.1-0.1-3.2-0.2c-0.4,0-1,0-1.2-0.3
                        c-0.9-1.1-2.1-0.9-3.2-0.9c-0.9,0-1.7,0-2.6,0c-1.5-0.1-2.9,0-4.3,0.7c-0.5,0.3-1.2,0.2-1.9,0.2c-0.6,0-1.3-0.2-1.9-0.2
                        c-1.2-0.1-2.1-0.4-2.4-1.7c0-0.2-0.2-0.4-0.3-0.6c-0.3-0.4-0.6-0.6-1-0.6c-0.4,0-0.8,0-1.3,0c-1.2,0.1-2.1-0.3-2.6-1.4
                        c-0.4-0.8-1-1.4-1.8-1.7c-0.8-0.3-1.1-1-1.7-1.4c-0.8-0.7-1.7-0.8-2.6-0.3c-1.1,0.6-2.2,1.3-3.4,2.1c-0.8,0-1.7-0.1-2.7,0
                        c-0.9,0.1-1.8,0.5-2.8,0.3c-0.5-0.1-1,0.2-1.5,0.4c-0.4,0.1-0.8,0.4-1.1,0.5c-0.4,0.2-1.2-0.2-1.3-0.6c-0.1-0.4-0.1-0.8-0.2-1.3
                        c-0.1-1-0.6-1.5-1.6-1.7c-0.2,0-0.4-0.1-0.6-0.1c-0.3-0.1-0.5-0.6-0.4-0.8c0.7-1.2,1.1-2.5,1.1-3.9c0-0.5,0.6-0.9,1.2-0.7
                        c0.8,0.2,1.6,0.4,2.4,0.7c1,0.3,1.9,0.2,2.8-0.3c0.7-0.4,1.4-0.9,2.2-1.3c0.5-0.2,1.1-0.3,1.5,0c0.7,0.5,1.5,0.8,2.3,0.9
                        c0.5,0.1,1.1,0.2,1.5,0.5c1,0.8,2.1,0.8,3.3,0.5c1.6-0.3,3-1.2,4.5-1.5c0.4-0.1,0.7-0.5,1.1-0.7c0.9-0.6,1.7-1.2,2.6-1.9
                        c0.8-0.6,1.6-1,2.6-1.3c1.5-0.4,3-0.3,4.1,1c0.1,0.2,0.3,0.3,0.4,0.5c0.7,0.6,1.5,0.8,2.4,0.4c0.3-0.1,0.5-0.3,0.8-0.5
                        c1.2-0.5,2.4-0.7,3.6,0.1c0.3,0.2,0.8,0.4,1.2,0.4c0.8,0.2,1.3,0.7,1.6,1.4c0.2,0.5,0.5,0.9,0.8,1.3c0.2,0.3,0.8,0.4,1.2,0.2
                        c0.6-0.2,1.1-0.6,1.5-1.2c0.8-1.6,2.5-2.2,3.7-3.4c0.3-0.3,0.8-0.3,1.2-0.3c1-0.1,1.8-0.5,2.5-1.2c0.9-1,2-1.1,3.2-0.8
                        c1.6,0.4,3.1,0.8,4.3,2c0.6,0.7,1.5,0.7,2.4,0.6c1.1-0.1,2.1-0.5,2.8-1.4c0.5-0.5,1.1-0.7,1.7-0.7c0.6,0,1.3,0.1,1.9,0.1
                        c1,0,1.8-0.8,1.9-1.8c0-0.3,0-0.6,0-1c-0.1-0.9,0.3-1.6,0.9-2.3c0.1-0.1,0.2-0.2,0.2-0.2c0.5-0.8,1.6-1.1,1.9-2.1
                        c0.2-0.9,0.3-1.9,0.6-2.8c0.1-0.4,0.2-0.8,0.5-1.1c0.2-0.2,0.5-0.3,0.8-0.3c0.2-0.1,0.7,0.4,0.7,0.6c0,0.7-0.1,1.5-0.1,2.2
                        c0,0.7,0.3,1.2,1,1.5c0.3,0.1,0.6,0.3,0.9,0.4c0.9,0.3,1.4,1,1.6,1.9c0.1,0.4,0.2,0.8,0.4,1.2c0.7,1.7,2.5,2.5,4.2,1.8
                        c1.5-0.6,2.8-1.5,4.1-2.5c0.9-0.7,1.1-1.8,1.6-2.7c0.1-0.3,0.7-0.5,1.1-0.4c0.3,0,0.5,0.5,0.4,0.7c-0.1,0.1-0.1,0.2-0.1,0.3
                        c-0.8,0.9-1.4,1.8-1.6,3.1c-0.2,0.8-0.9,1.1-1.5,1.6c-1.2,0.8-1.3,2.6-0.3,3.6C433.5,227.5,433.9,227.8,434.5,228.3z"></path>
                        <path className="st8" d="M507.9,330.7c0.1-0.3,0.3-0.7,0.3-1.1c0.2-1.6,0.4-3.1,1.1-4.6c0.5-1.1,0.6-2.3,0.3-3.4c-0.2-1.1,0-2.1,0.2-3.1
                        c0.1-0.4,0.6-0.7,1-0.6c1.8,0.4,3.5,0.9,4.9,2.1c0.1,0.1,0.2,0.1,0.3,0.2c1.8,1.5,4,1.9,6.3,1.7c2.8-0.2,5.5-0.4,8.3-0.3
                        c0.9,0,1.7,0,2.5-0.4c1.8-0.9,3.7-0.9,5.6-0.7c1.7,0.2,3.4,0.6,5,0.9c1.5,0.3,2.9,0.6,4.3,1.2c0.8,0.3,1.6,0.5,2.4,0.7
                        c0.4,0.1,0.8,0.8,0.7,1.3c0,0.1,0,0.2,0,0.3c-0.7,1.4-0.4,2.7,0.4,3.9c0.3,0.4,0.2,0.8,0.1,1.2c-0.2,0.5-0.5,0.9-0.7,1.4
                        c-0.4,1.1-0.5,2.1,0.3,3c1.5,1.8,2.7,3.8,4.7,5.2c1,0.7,1.7,1.6,2.1,2.8c0.1,0.4,0.3,0.8,0.5,1.2c0.5,1,1.2,1.7,2.3,2.1
                        c0.6,0.2,1.3,0.3,1.8,0.6c1.2,0.9,2.5,1.2,3.9,1c1.3-0.1,2.5,0.4,3.6,1c0.4,0.2,0.8,0.4,1.1,0.7c1.2,1.1,2.6,1.3,4.2,1.1
                        c1.4-0.2,2.8-0.3,4.1-0.7c1.4-0.4,2.7-0.6,4.1-0.5c1.8,0.2,3.6,0,5.3-0.7c1-0.4,2.1-0.4,3.1-0.2c1.5,0.2,3,0.4,4.6,0.6
                        c0.4,0.5,1,1,1.4,1.6c0.4,0.5,0.5,1.2,0.1,1.8c-0.5,1-1.1,2.1-1.7,3.1c-0.4,0.7-0.7,1.5-0.9,2.4c-0.4,2.7-1.1,5.4-2.2,8
                        c-0.2,0.5-0.3,1-0.3,1.5c0,1.3,0,2.6,0,3.8c0,0.5-0.1,1.1-0.1,1.6c0,0.5-0.6,1-1.1,1c-0.5,0-1.1-0.1-1.6-0.1c-0.8,0-1.4,0.4-1.8,1.2
                        c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.5-0.6,0.9-1.2,1c-0.9,0.1-1.9,0.4-2.8,0.7c-0.4,0.1-0.9-0.1-1.1-0.5c-0.3-0.6-0.4-1.2-0.7-1.8
                        c-0.2-0.4-1-0.6-1.4-0.3c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.6,0.7-1.4,0.9-2.3,0.7c-0.8-0.2-1.7-0.5-2.5-0.2
                        c-1.4,0.6-2.2,0.3-2.8-1.1c-0.2-0.5-0.8-0.7-1.4-0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.9,0.2-1.7,0.1-2.1-1c-0.3-0.9-1-1.2-2-1.2
                        c-1.2,0-1.2,0-1.4-1.2c-0.1-0.4-0.1-0.8-0.3-1.2c-0.4-0.9-1.5-1.3-2.4-1c-0.3,0.1-0.6,0.4-0.7,0.6c-0.2,0.5-0.2,1-0.3,1.5
                        c-0.1,0.3-0.5,0.5-0.7,0.4c-0.3-0.2-0.5-0.4-0.8-0.5c-1.3-0.3-2.4-0.8-3.1-2c-0.3-0.4-0.9-0.5-1.4-0.4c-0.4,0.1-0.8,0.1-1.3,0.1
                        c-0.4,0-0.8-0.5-0.7-0.9c0-0.1,0.1-0.2,0.2-0.3c0.7-0.7,0.8-1.6,0.1-2.3c-0.6-0.6-0.7-1.2-0.7-2c0-0.5-0.3-0.8-0.7-1
                        c-0.7-0.3-1.4-0.5-2.1-0.9c-0.4-0.2-0.6-0.5-0.6-1c0-0.3,0-0.6,0-1c-0.1-0.9-0.8-1.5-1.5-1.9c-0.6-0.3-1.2-0.1-1.8,0.2
                        c-0.4,0.2-0.7,0.4-1.1,0.5c-0.2,0.1-0.5,0-0.5-0.3c-0.1-0.7-0.1-1.5-0.2-2.2c-0.1-1.1-0.8-1.7-1.8-1.8c-0.9-0.1-1.7,0-2.3,0.8
                        c-0.5,0.5-1.1,0.7-1.7,0.4c-1-0.5-2.2-0.7-3.2-1.5c-0.3-0.3-0.8-0.3-1.2-0.4c-0.7-0.1-1.3,0-1.7,0.6c-0.2,0.2-0.4,0.5-0.6,0.7
                        c-0.1,0.1-0.5,0.1-0.6,0c-0.2-0.2-0.4-0.5-0.5-0.8c-0.5-0.9-1.2-1.4-2.3-1.5c-0.1,0-0.2,0-0.3,0c-0.8,0-1.5-0.3-1.8-1
                        c-0.6-1-1.4-1.2-2.4-0.9c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.7-0.3-0.7-0.5c0-0.2,0-0.4,0-0.6c-0.1-1.4-0.3-1.6-1.6-2
                        c-0.1,0-0.2-0.1-0.3-0.1c-0.9-0.1-1.3-0.6-1.5-1.4c-0.2-0.8-0.8-1.3-1.6-1.3c-0.2,0-0.4,0-0.6-0.1c-0.8-0.3-1.5-0.8-2.4-0.4
                        c-0.8,0.4-0.8,1.2-1.2,1.8c-0.4,0.7-1.3,0.9-1.9,0.4c-0.5-0.4-0.9-0.9-1.4-1.3c-0.4-0.4-1-0.4-1.5-0.4c-0.7,0.1-1.2,0.4-1.4,1.1
                        c-0.3,0.8-0.4,1.6-0.7,2.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0-0.5,0-0.6-0.1c-0.3-0.3-0.4-0.8-0.6-1.1c-0.3-0.4-0.6-0.9-1.1-1.2
                        c-1-0.7-2.1-1.4-3-2.4c-0.7-0.8-1.9-0.9-3-0.5c-0.6,0.2-1.1,0.5-1.7,0.7c-0.2,0.1-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.2-0.2
                        c-0.8-1.8-1.4-3.6-1.4-5.5c0-1.4,0-2.8,0-4.1c0-1,0.3-1.3,1.3-1.3c0.6,0,1.3,0,1.9-0.1c2.7-0.6,5.5-0.7,8.3-0.7
                        C505.9,331.5,506.9,331.4,507.9,330.7z"></path>
                        <path className="st3" d="M470.5,618.4c-1.7-0.1-3.5-0.1-5.3,0.1c-1.4,0.1-2.5-0.4-3.5-1.2c-0.3-0.2-0.4-0.9-0.2-1.2
                        c0.1-0.3,0.3-0.6,0.5-0.8c0.4-0.5,0.8-1,1.3-1.4c0.8-1,0.2-2-0.6-2.4c-0.6-0.3-1.2-0.5-1.7-0.8c-0.4-0.2-0.7-0.4-1-0.7
                        c-0.4-0.3-0.5-1.1-0.2-1.5c0.3-0.3,0.5-0.7,0.9-0.9c1.6-0.9,2.8-2.3,4.1-3.5c0.7-0.6,0.9-1.4,0.9-2.3c0-0.3-0.1-0.6-0.1-0.9
                        c-0.1-1.5-0.4-3-0.4-4.4c0-2.1-0.6-4-2-5.6c-0.4-0.5-0.8-1-1.2-1.5c-0.7-0.8-0.8-1.7-0.6-2.7c0.1-0.3,0.1-0.6,0.3-0.9
                        c0.7-1.5,0.5-2.9,0-4.3c-0.6-1.6-1-3.2-1.6-4.9c-0.7-2.2-2.1-3.9-4.4-4.8c-2.7-1.1-5.1-2.6-7.7-3.8c-1.9-0.9-3.4-2.2-4.4-4
                        c-0.2-0.4-0.5-0.7-0.7-1.1c-1-1.5-2.2-2.6-3.9-3.1c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.2-0.6-0.6-0.4-0.7c0.2-0.1,0.3-0.3,0.5-0.3
                        c1.8,0,3.6,0,5.4,0c0.4,0,0.9,0,1.3,0.1c0.2,0,0.4,0.2,0.4,0.4c0.1,0.7,0.1,1.5,0.2,2.2c0.1,0.6,0.5,1,1,1c2.3,0,4.7,0,7,0
                        c0.6,0,1-0.3,1.3-0.8c0.3-0.5,0.7-1.1,0.9-1.7c0.3-1.2,0.8-2.1,1.7-3c0.3-0.3,0.4-0.7,0.5-1.1c0.2-1.1,0.4-2.3,0.6-3.4
                        c0.1-0.9,0.5-1.6,0.9-2.4c0.3-0.6,1.2-0.8,1.7-0.5c0.7,0.5,1.4,1,2,1.5c0.3,0.3,0.5,0.6,0.7,1c0.2,0.5,0.6,0.9,1.1,1.1
                        c1.3,0.5,2.5,1.2,3.9,1.3c1.1,0,1.9,1.1,3,0.8c1.8-0.4,3.6-0.4,5.3-1.2c0.7-0.4,1.4-0.3,2.1,0.1c1.7,0.9,3.5,0.7,5.3,0.2
                        c0.4-0.1,0.8-0.3,1.1-0.6c1.1-0.9,2.3-1.5,3.1-2.7c0.3-0.4,0.8-0.7,1.2-1c0.2-0.2,0.7-0.1,0.8,0.2c0.3,0.7,0.7,1,1.5,1
                        c0.4,0,0.8,0.1,1.2,0.2c0.1,0,0.3,0.3,0.2,0.5c0,0.2-0.2,0.4-0.4,0.4c-0.4,0.1-0.8,0.1-1.3,0.2c-0.6,0.1-1,0.6-0.8,1.1
                        c0.4,1.7,0.5,3.4,0.3,5.1c-0.1,0.7-0.6,1.2-1.3,1.2c-0.5,0-1.1,0.1-1.6,0.1c-2.3,0-2.4,0.1-2.4,2.6c0,0.4,0,0.9,0.1,1.3
                        c0.1,0.3,0.3,0.6,0.6,0.7c0.7,0.2,1,0.8,1,1.4c0,1.3,0.1,2.5,0.1,3.8c0,0.4,0.4,0.8,0.8,0.8c1.2,0,2.3,0.1,3.5,0.1
                        c1.5,0,3,0.1,4.5,0.1c0.4,0,0.7,0.6,0.5,0.9c-0.2,0.4-0.4,0.7-0.7,1c-0.6,0.6-1.2,1.2-1.9,1.7c-1.1,0.9-1.9,2-2.8,3.1
                        c-0.6,0.9-1.1,1.8-1.3,2.9c-0.8,3.5-1.5,7.1-1.4,10.7c0,2.4,0.6,4.6,0.9,6.9c0.2,1.3,0.6,2.5,0.9,3.7c0.2,0.9,0.5,1.9,0.6,2.8
                        c0.2,2.2,0.3,4.5,0.7,6.7c0.3,1.5,0.2,3-0.5,4.4c-0.2,0.5-0.5,0.9-0.8,1.4c-0.3,0.4-0.6,0.9-1,1.3c-1.3,1.6-1.7,3.5-1.3,5.4
                        c0.4,2,0.3,4,0.3,6c0,2.3-0.4,4.4-1.7,6.4c-0.5,0.7-0.8,1.5-1.3,2.2c-0.3,0.4-0.7,0.6-1.1,0.4c-0.8-0.6-1.7-0.8-2.7-0.8
                        c-0.5,0-1.1,0-1.6-0.1c-0.6,0-1-0.3-1.3-0.8c-0.6-0.8-1.1-1.6-1.1-2.6c0-1.3-0.9-2.1-1.1-3.3c-0.2-1.2-0.2-2.3-0.8-3.4
                        c-0.3-0.5-0.5-1-0.6-1.5c-0.2-0.7-0.5-1.2-0.9-1.6c-0.5-0.6-1-1.1-1.1-1.9c-0.2-0.9-0.6-1.6-1.5-1.9
                        C471.5,620.3,471.4,619.1,470.5,618.4z"></path>
                        <path className="st2" d="M624.7,376.7c-0.4,0.1-0.7,0.3-1,0.2c-1.6-0.4-2.9-0.1-4,1.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.5,0.1-0.6,0
                        c-0.2-0.2-0.3-0.6-0.3-0.8c0.1-0.7,0.7-1,1.2-1.5c0.4-0.4,0.6-0.9,0.6-1.4c0-1.2-1.4-2.2-2.5-1.8c-0.3,0.1-0.6,0.3-0.8,0.5
                        c-0.5,0.7-0.8,1.5-1.6,2c-0.4,0.2-0.4,0.7-0.4,1.1c0,0.4,0.1,0.9,0,1.3c-0.1,0.2-0.4,0.4-0.7,0.6c-0.1,0.1-0.2,0-0.3,0
                        c-1.1-0.3-2.1-0.1-3,0.6c-0.6,0.4-1.2,0.3-1.7-0.3c-0.1-0.2-0.3-0.3-0.5-0.4c-0.6-0.4-1.1-0.5-1.8,0c-0.3,0.2-0.5,0.4-0.7,0.6
                        c-0.4,0.3-0.9,0.3-1.2,0c-0.2-0.1-0.3-0.3-0.5-0.4c-0.8-0.9-1.6-1.3-2.8-0.7c-0.2,0.1-0.6,0-0.9-0.1c-0.3-0.2-0.2-0.5-0.1-0.8
                        c0.2-0.4,0.4-0.7,0.6-1.1c0.2-0.7-0.3-1.6-1.1-1.7c-0.3,0-0.6,0-1,0c-0.4,0.1-0.8,0.2-1,0.6c-0.1,0.3-0.3,0.6-0.4,0.9
                        c-0.2,0.3-0.3,0.5-0.5,0.8c-1-0.2-1.6-1-2.3-1.7c-0.4-0.4-0.6-0.8-0.9-1.3c-0.2-0.4-0.5-0.8-0.5-1.1c-0.1-1.7-0.1-3.4-0.1-5.1
                        c0-0.5,0.2-1.1,0.3-1.5c0.8-2.2,1.6-4.4,1.9-6.7c0.2-1.5,0.6-2.9,1.5-4.1c0.4-0.6,0.7-1.3,1.1-2c0.6-0.9,0.5-1.9,0-2.7
                        c-0.4-0.6-0.9-1.2-1.4-1.7c-0.5-0.5-1.2-0.4-1.8-0.7c-0.4-0.2-0.7-0.5-0.7-0.9c-0.1-1.3-0.1-2.5-0.1-3.8c0-0.4-0.4-0.8-0.8-0.8
                        c-1.3-0.1-2.5-0.1-3.8-0.1c-0.6,0-1.3-0.1-1.9-0.2c-0.4,0-0.6-0.4-0.6-0.6c-0.1-1-0.1-1.9,0.5-2.8c0.3-0.4,0.5-0.9,0.8-1.4
                        c0.7-1.2,0.8-1.3,2.3-1c4.1,0.6,8.3,0.7,12.4-0.1c0.6-0.1,1.2-0.4,1.8-0.7c0.6-0.4,1.3-0.7,1.9-1.2c1.2-1,2.5-1.6,4-1.9
                        c0.5-0.1,1-0.4,1.4-0.6c0.6-0.3,1.2-0.3,1.8,0.2c0.9,0.8,1.8,1.5,2.7,2.2c0.6,0.5,1.4,0.5,2.1,0.2c0.6-0.2,1.1-0.6,1.7-0.9
                        c0.4-0.2,0.8-0.2,1.1,0.2c0.2,0.2,0.3,0.6,0.5,0.8c0.6,0.5,1.8,0.6,2.4,0.2c0.2-0.2,0.4-0.5,0.7-0.7c0.2-0.2,0.4-0.5,0.7-0.6
                        c1.5-0.5,3-1,4.5-1.4c0.3-0.1,0.7,0,0.9,0.2c0.3,0.1,0.5,0.4,0.6,0.7c0.1,0.3-0.1,0.6-0.3,0.8c-0.4,0.3-1,0.5-1.4,0.8
                        c-0.5,0.3-0.7,1.1-0.5,1.7c0.2,0.5,0.5,0.9,0.8,1.4c0.3,0.5,0.8,0.7,1.3,0.8c0.3,0.1,0.6,0,0.9,0.1c0.9,0.2,1.2,0.6,0.9,1.6
                        c-0.1,0.4-0.4,0.8-0.4,1.2c0,0.8-0.3,1.4-0.7,2.1c-0.4,0.6-0.2,1.2,0.2,1.7c0.2,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.6,0.4,0.7,0.6
                        c0.3,1.5,1.4,2,2.6,2.5c0.4,0.2,0.7,0.8,0.9,1.2c0.2,0.3,0.1,0.6-0.2,0.9c-0.3,0.3-0.7,0.5-1,0.7c-0.9,0.4-1.7,0.9-2.3,1.6
                        c-0.9,1-2.1,1.5-3.4,1.4c-0.5,0-1.1,0-1.6,0c-2.3,0-2.3,0-2.3,2.3c0,0.6,0,1.3-0.1,1.9c0,0.4-0.3,0.8-0.8,0.9
                        c-0.4,0.1-0.8,0.1-1.3,0.2c-0.2,0.1-0.4,0.3-0.4,0.4c-0.1,0.6-0.2,1.3-0.2,1.9c0,4,0,8.1,0,12.1
                        C624.8,374.7,624.7,375.7,624.7,376.7z"></path>
                        <path className="st4" d="M509,317.5c-0.3,1.7-0.4,3.3-0.2,4.9c0.1,0.8,0,1.7-0.4,2.5c-0.6,1.5-1,3-1.1,4.6c0,0.4-0.5,0.8-0.9,0.8
                        c-0.7,0.1-1.5,0.2-2.2,0.2c-1.6,0-3.2,0.1-4.8,0.4c-1.9,0.3-3.8,0.4-5.7,0.6c-0.8,0.1-1.3,0.4-1.3,1.1c0,2.1,0,4.2,0,6.4
                        c0,1.3,0.4,2.5,0.9,3.7c0.2,0.5,0.3,1,0.5,1.5c0.1,0.3-0.3,0.9-0.7,0.9c-0.5,0-1.1,0.1-1.6,0.1c-0.4,0-0.8-0.3-1.2-0.4
                        c-0.3-0.1-0.6-0.3-0.9-0.2c-0.8,0.1-1.7,0.3-2.5,0.4c-0.7,0-1.4-0.5-2.2-0.2c-0.4,0.1-0.8,0.4-1,0.7c-0.4,0.5-0.6,1.1-1.1,1.6
                        c-0.3,0.4-0.9,0.6-1.4,0.5c-1-0.2-2-0.5-3.1-0.7c-0.6-0.6-0.3-1.4-0.7-2.1c-0.3-0.6-0.5-1.2-0.7-1.8c-0.1-0.4-0.1-0.8-0.1-1.3
                        c0.1-1.9,0.2-3.8,0.2-5.7c0.1-2.7,0.2-5.3,0.3-8c0-0.5-0.2-1-0.8-1.2c-0.6-0.2-0.9-0.6-0.9-1.2c0-1.5,0-3-0.1-4.5
                        c0-0.8,0-1.7-0.1-2.5c-0.1-0.7-0.5-1.2-1.1-1.4c-0.4-0.1-0.8-0.2-1.2-0.3c-0.3-0.1-0.6-0.6-0.4-0.7c0.7-0.8,0.7-2,1.2-2.9
                        c0.3-0.7,0.8-1.3,1.6-1.3c0.6,0,1.3-0.1,1.9-0.2c0.9-0.2,1.6-0.5,2-1.4c0.4-0.9,1.2-1.5,1.7-2.3c0.4-0.5,0.6-1.1,0.3-1.8
                        c-0.2-0.6-0.5-1.2-0.7-1.8c0.3-1,0.6-2.1,0.9-3.1c0.2-0.6,0.8-1.1,1.5-1c0.5,0,1.1,0.1,1.6,0.2c0.6,0.2,1.2,0.5,1.8,0.7
                        c1.3,0.5,2.5,0.4,3.7-0.2c0.5-0.2,0.9-0.5,1.2-1c0.4-0.6,0.7-1.3,1-2c0.2,0.1,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.4,0.3,0.6
                        c0.6,2.4,2.2,4.1,4,5.6c0.7,0.6,1.5,0.7,2.4,0.5c0.4-0.1,0.8-0.3,1.1-0.5c1.7-1.2,1.8-0.8,3.1-0.1c0.5,0.2,0.9,0.5,1.4,0.8
                        c0.1,0.3,0.2,0.6,0.3,0.9c0.4,1.2,0.8,1.4,1.8,1.4c0.6,0,1.3-0.3,1.9,0c0.2,0.1,0.3,0.3,0.4,0.4c0.2,0.3,0,0.6-0.4,0.7
                        c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.6,0.8-0.4,1.1c0.4,0.5,0.8,1,1.2,1.5c0.3,0.4,0.1,0.8-0.4,0.9c-0.3,0-0.6,0-0.9,0.1
                        c-0.3,0.1-0.6,0.2-0.8,0.5c-0.3,0.4-0.6,0.9-0.8,1.4c-0.3,0.5-0.2,1.3,0.3,1.7c0.3,0.3,0.7,0.5,1,0.7c0.3,0.2,0.6,0.2,0.9,0.3
                        C508.3,317.2,508.5,317.3,509,317.5z"></path>
                        <path className="st7" d="M404.4,299.1c0-0.9,0-1.8,0-2.6c0-1-0.2-1.9-1.2-2.4c-0.6-0.3-0.9-0.9-0.8-1.6c0.1-1-0.1-2.1,0.3-3.1
                        c0.1-0.1,0.4-0.2,0.5-0.2c0.3,0.2,0.5,0.4,0.6,0.7c0.4,1.2,1.3,1.6,2.5,1.6c0.7,0,1.5,0,2.2,0.1c0.4,0,0.8,0.4,0.8,0.8
                        c0,1.2,0.3,2.3,0.8,3.4c0.3,0.6,0.8,0.9,1.5,0.9c0.3,0,0.6-0.1,0.9-0.1c0.4,0,0.6-0.3,0.6-0.6c0.1-0.4,0.1-0.8,0.1-1.3
                        c0-1.4,0-2.8-0.1-4.1c-0.1-1.6,0.5-2.9,1.3-4.2c0.7-1.2,1.9-2,2.4-3.4c0.1-0.3,0.6-0.5,0.9-0.9c0.4-0.4,0.8-0.7,1-1.2
                        c0.5-1.2,1.7-1.9,2.4-3c0.3-0.5,1-0.8,1.6-1c1.6-0.6,2.3-1.8,2.5-3.4c0.1-0.4,0.1-0.8,0.1-1.3c0.1-1.1,0.7-2,1.4-2.8
                        c0.2-0.2,0.5-0.5,0.8-0.5c1.4,0,2.4-0.8,3.4-1.5c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0.3,0.6,0.5,0.8,0.9c0.3,0.5,0.7,0.8,1.3,0.8
                        c0.2,0,0.4,0,0.6,0c1.2-0.2,1.8,0.4,2.1,1.4c0.2,0.6,0.7,1,1.4,1.2c1.2,0.4,2.5,0.6,3.8,0.4c1.4-0.2,2.7,0.5,4,0.9
                        c0.3,0.1,0.5,0.4,0.7,0.6c0.8,1,1.8,1.4,3.1,1.4c0.4,0,0.8,0,1.3,0.1c0.5,0.1,0.9,0.7,0.6,1.2c-0.7,1.3-1.2,2.7-2.4,3.7
                        c-0.6,0.4-0.6,1.1-0.4,1.8c0.1,0.6,0.4,1.2,0.5,1.8c0.2,0.8-0.1,1.3-1,1.6c-0.8,0.2-1.3,0.6-1.6,1.4c-0.3,0.9-0.6,1.8-1.6,2.2
                        c-0.6,0.3-0.9,0.9-1,1.6c0,0.8-0.4,1.4-1,1.9c-0.2,0.2-0.5,0.5-0.5,0.7c0,1-0.4,1.8-1.1,2.6c-0.2,0.2-0.3,0.6-0.3,0.9
                        c0,0.8-0.2,1.4-0.9,1.9c-0.2,0.1-0.4,0.2-0.5,0.4c-1,1.6-2.3,2-4.1,1.6c-0.8-0.2-1.4,0.2-1.7,1.1c-0.1,0.3-0.3,0.5-0.5,0.7
                        c-0.1,0.1-0.4,0.2-0.6,0.1c-0.3-0.2-0.6-0.5-1-0.8c-0.5-0.4-1-0.8-1.7-0.6c-0.6,0.2-1.1,0.9-1.1,1.8c0,0.4,0,0.9,0.1,1.3
                        c0.2,0.9,0.1,1.7-0.4,2.4c-0.6,0.7-0.5,1.6-0.2,2.4c0.3,0.7,0.6,1.3,0.9,2c0.4,0.9-0.4,1.7-1.4,1.5c-0.3-0.1-0.6-0.1-0.9-0.2
                        c-0.7-0.1-1.2-0.5-1.3-1.2c-0.1-0.4-0.2-0.8-0.4-1.2c-0.3-0.6-0.8-1-1.5-1c-0.5,0-1.1,0.1-1.4,0.6c-0.3,0.4-0.6,0.9-0.9,1.3
                        c-0.2,0.2-0.6,0.4-0.9,0.4c-0.9,0-1.9-0.1-2.6-0.9c-0.8-1-1.9-1.7-3.1-2.2c-2.3-1-4-2.7-5.6-4.6c-0.6-0.7-1.3-1.4-2.1-1.9
                        C406.2,300.6,405.3,299.8,404.4,299.1z"></path>
                        <path className="st7" d="M396.1,280.5c-0.9,0.3-1.7,0.6-2.5,0.9c-0.4,0.2-0.7,1-0.5,1.4c1.2,2.1,1.6,4.6,2.8,6.7
                        c0.4,0.7,0.3,1.4,0.1,2.2c-0.1,0.4-0.4,0.8-0.7,1c-0.3,0.3-0.7,0.4-1.1,0.6c-0.6,0.3-0.9,0.9-1,1.5c-0.1,0.7-0.1,1.5-0.2,2.2
                        c-0.1,0.7-0.9,1-1.5,0.5c-0.4-0.3-0.8-0.7-1.2-1c-1.3-1.2-2.8-2.2-4.7-2.8c-0.7-1-0.8-2.4-1.9-3.2c-0.2-0.1-0.3-0.6-0.3-0.9
                        c-0.1-0.9,0.1-1.7,0.4-2.5c0.4-1.2,0.4-2.3-0.4-3.3c-0.5-0.7-0.6-1.5-0.6-2.4c0.1-1.6,0.1-1.6-0.9-3.3c0.2-0.4,0.4-0.9,0.6-1.4
                        c0.5-1.2,0.3-2.3-0.6-3.2c-0.3-0.3-0.7-0.5-0.9-0.7c-0.1-1.3-0.2-2.4-0.4-3.4c-0.1-1.1-0.5-2.1-1.4-2.8c-0.9-0.8-1.6-1.7-1.9-2.8
                        c-0.2-0.6-0.5-1.2-0.9-1.6c-0.7-0.8-1.1-1.5-1-2.6c0.1-0.9-0.2-1.7-0.5-2.5c-0.7-1.9-1-3.9-1-5.9c0-1.4,0-2.8,0-4.1
                        c0-1.1,0.1-1.3,1.3-1.2c0.8,0.1,1.5-0.1,2.1-0.4c1.2-0.6,2.4-0.7,3.7-0.7c1.3,0,2.6,0,3.8,0.1c0.3,0,0.7,0.1,0.9,0.3
                        c0.5,0.9,1.4,0.9,2.2,0.9c1,0,1.9,0,2.9,0.1c0.4,0,0.7,0.6,0.6,1c-0.2,0.4-0.3,0.8-0.6,1.1c-1.4,1.6-2.4,3.5-3.2,5.5
                        c-0.3,0.8-0.8,1.5-1.2,2.2c-0.4,0.7-1,1.4-1.3,2.2c-0.5,1.1-1.2,1.7-2.4,1.7c-0.7,0-1,0.4-1,1c0,1.7,0,3.4,0,5.1
                        c0,0.4,0.4,0.8,0.8,0.8c1.3,0.2,2.5,0.1,3.6,0c0.8-0.2,1.1-0.7,1.4-1.1c0.6-0.7,1.2-1.1,2.2-1.1c0.8,0,1.7-0.2,2.5-0.4
                        c1.7-0.3,2.6-1.4,2.9-3c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.8-0.6,1.1-0.6c0.4,0.1,0.8,0.2,1.2,0.3c0.7,0.2,1.3,0.1,1.8-0.3
                        c0.5-0.4,1-0.8,1.5-1.1c0.5-0.3,1-0.4,1.5-0.4c0.6,0,1,0.2,1.2,0.8c0.1,0.3,0.2,0.6,0.3,0.9c0.2,0.6,1.2,0.7,1,1.5
                        c-0.1,0.5-0.7,0.8-0.9,1.2c-0.2,0.3-0.4,0.8-0.4,1.2c0.1,1.2-0.4,2-1.4,2.7c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.4-0.6,0.9-0.5,1.4
                        c0.1,1.1,0.3,2.1,0.5,3.2c0,0.2,0.1,0.4,0.2,0.6c0.3,0.5,0.8,1,0.9,1.6c0.1,0.6-0.1,1.2-0.1,1.9c0,0.4,0.2,0.8,0.2,1.2
                        c0,0.2-0.2,0.4-0.3,0.5c0,0-0.2,0-0.3-0.1c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.4-0.5-0.7-0.7c-0.7-0.3-1.7-0.2-1.9,0.6
                        c-0.2,0.9-0.7,1.2-1.5,1.3c-0.6,0.1-0.9,0.5-1.1,1C396.4,279.2,396.2,279.9,396.1,280.5z"></path>
                        <path className="st3" d="M512.7,437.5c-0.5-0.7-0.6-1.6-0.7-2.4c-0.3-1.8-0.5-3.6-0.8-5.4c-0.1-1,0-1.9,0.9-2.5c0.2-0.1,0.3-0.3,0.5-0.4
                        c1.5-1.2,2.8-2.6,3.5-4.4c0.4-1,1.1-1.8,1.8-2.6c0.7-0.8,1.4-1.6,2.1-2.4c2.2-2.4,4.2-4.8,5.7-7.7c0.6-1.2,1.4-2.1,2.3-3
                        c1.7-1.7,3.4-3.5,5.2-5.2c1.5-1.5,2.6-3.4,3.5-5.3c0.3-0.7,0.5-1.4,0.5-2.1c0-1.6,0-3.2-0.5-4.7c-0.5-1.4-0.9-2.8-1.2-4.3
                        c-0.3-1.1-0.5-2.3-0.1-3.4c0-0.1,0.4-0.3,0.5-0.2c0.5,0.4,1.1,0.8,1.3,1.4c0.6,1.3,1.3,2.4,2.5,3.3c0.2,0.2,0.4,0.5,0.6,0.7
                        c0.8,0.7,1,1.6,1.1,2.6c0.1,2.9,0.2,5.7,0.2,8.6c0,2,0,4,0.5,6c0.2,0.9,0.1,1.7-0.4,2.5c-0.6,1-1.2,2-1.3,3.2
                        c-0.1,0.7-0.6,1.1-1,1.6c-0.5,0.4-1,0.8-1.5,1.2c-1.2,1-1.9,2.3-2.1,3.8c-0.1,0.8-0.2,1.7-0.2,2.5c-0.1,1.6-0.5,3.2-0.9,4.7
                        c-0.3,1-0.9,1.7-1.9,2c-2.4,0.6-2.6,1.4-2.5,3.6c0,0.2,0,0.4,0,0.6c0,0.7,0.2,1.2,0.7,1.8c0.2,0.2,0.4,0.5,0.6,0.7
                        c1.6,1.4,1.7,3.1,1.3,5c0,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-6.2,0-12.3,0-18.5,0C513.1,437.7,512.9,437.5,512.7,437.5z
                        "></path>
                        <path className="st4" d="M470,619.3c0.4,0.5,0.6,0.9,0.8,1.2c0.5,0.9,1.9,0.9,2.1,2.3c0.1,1,1,1.6,1.4,2.4c0.6,1.1,1.1,2.3,1.5,3.5
                        c0,0.1-0.1,0.5-0.2,0.5c-0.6,0.1-1.3,0.2-1.9,0.3c-0.4,0.1-0.8,0.3-0.9,0.7c-0.1,0.3-0.2,0.7-0.4,0.8c-0.9,0.4-1.1,1.2-1.1,2.1
                        c0,0.8-0.4,1.4-1.1,1.8c-0.6,0.4-0.8,0.9-0.9,1.6c0,0.8,0.2,1.1,1,1.4c0.1,0,0.2,0,0.3,0.1c0.8,0.3,1.1,0.9,0.7,1.7
                        c-0.1,0.3-0.3,0.5-0.5,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.7,0.2,1.6,0.9,1.7c0.5,0.1,1.1,0.1,1.6,0.2c0.2,0,0.3,0.3,0.4,0.5
                        c0.3,1.2,0,2.3-0.6,3.3c-0.1,0.2-0.2,0.4-0.4,0.5c-1.6,1.1-2.1,2.6-2,4.4c0,0.2,0,0.4-0.1,0.6c0,0.3-0.5,0.8-0.9,0.8
                        c-0.9,0-1.7,0-2.6,0.1c-0.4,0-0.8,0-1.3,0.1c-0.4,0-0.9,0.8-0.8,1.2c0.1,0.3,0.1,0.6,0.3,0.9c0.8,1.3,1.2,2.7,1.3,4.2
                        c0,0.7,0.3,1.4,0.5,2.2c0.1,0.6,0.1,1.3-0.3,1.8c-0.2,0.4-0.3,0.8-0.6,1.1c-0.8,0.9-1.1,2-1.3,3.2c0,0.2-0.2,0.4-0.3,0.6
                        c-2.4,1.4-5.2,1.6-7.9,1.9c-0.4,0-0.9,0-1.3,0c-3.2,0-2.5-0.1-4.3,2c-0.1,0.1-0.2,0.1-0.4,0.3c-0.7,0.1-1.6,0.1-2.4,0.2
                        c-0.9,0.1-1.7,0.7-1.6,1.6c0.2,1.7-1,2.2-2.1,2.8c-0.2,0.1-0.7-0.1-0.8-0.3c-0.1-0.4-0.3-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.2-0.6
                        c-0.3-0.5-1.1-0.9-1.6-0.6c-0.6,0.3-1.1,0.6-1.7,0.9c-0.6,0.3-3.1,1-3.7,1.2c-0.8,0.2-1.6,0.5-2.5,0.6c-0.5,0.1-1-0.1-1.6-0.1
                        c-0.1,0-0.2-0.1-0.2-0.2c0-0.2-0.1-0.5,0-0.6c1.9-1.2,3.7-2.4,5.6-3.6c1.8-1.1,3.6-2.2,5.4-3.4c3.6-2.4,6.6-5.3,9.1-8.9
                        c0.8-1.1,1.7-2.2,2.5-3.3c0.4-0.6,0.9-1.2,1.3-1.8c0.8-1.3,1.1-2.7,0.7-4.3c-0.2-0.7-0.3-1.5-0.5-2.2c-0.4-1.3-0.1-2.5,0.7-3.6
                        c0.5-0.8,1.2-1.5,1.9-2.2c1.3-1.2,2.7-2.4,4.1-3.6c1.3-1.2,2.7-2.4,4-3.7c0.9-0.9,1.6-2,2.2-3.1c0.7-1.4,0.9-2.8,0.8-4.3
                        c-0.2-1.7-1-3-2.6-3.7c-1.1-0.5-2.3-1-3.6-1.1c-0.7,0-1.2-0.2-1.7-0.7c-0.3-0.3-0.3-1,0-1.1c0.7-0.3,1.5-0.5,2-1
                        c0.9-1.1,2.1-1.4,3.4-1.4C467.9,619.3,468.9,619.3,470,619.3z"></path>
                        <path className="st7" d="M444.9,336.7c-0.1-0.3-0.3-0.6-0.3-0.9c0-4.9,0-9.8,0-14.7c0-0.7,0.3-1.2,1-1.4c0.7-0.2,1-0.7,1-1.4
                        c0-0.4-0.1-0.8-0.1-1.3c0.1-1.1-0.2-2.1-1-2.9c-0.3-0.3-0.1-0.9,0.3-1.1c0.5-0.1,1-0.2,1.5-0.4c0.8-0.2,1.2-0.9,1.1-1.7
                        c0-0.4-0.1-0.9-0.4-1.2c-0.4-0.6-0.4-1.1-0.3-1.8c0.2-1.3,0.2-2.5,0.2-3.8c0-0.8-0.4-1.4-1-1.9c-1-0.8-2.2-1.3-3.3-1.8
                        c-0.6-0.3-1.2-0.3-1.8,0c-0.3,0.1-0.6,0.3-0.9,0.3c-0.4,0.1-0.8-0.1-1.1-0.4c-0.3-0.3-0.2-0.8,0.2-1.1c0.4-0.4,0.9-0.6,1.2-1
                        c0.3-0.3,0.5-0.7,0.6-1.1c0-0.8,0.3-1.4,0.7-2.1c0.3-0.5,0.4-1.2,0.7-1.7c0.3-0.5,0.8-1,1.1-1.5c0.3-0.7,0.3-1.5,0.7-2.1
                        c0.3-0.5,1-0.7,1.5-1.2c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.5,0.2-1.1,0.8-1.5c0.3-0.1,0.9-0.2,1.3-0.4c0.9-0.3,1.6-0.3,2.4,0.4
                        c0.5,0.4,1.1,0.7,1.7,0.7c0.2,0,0.5,0.1,0.6,0.2c0.2,0.2,0.5,0.5,0.5,0.7c0,0.2-0.3,0.6-0.5,0.7c-0.4,0.2-0.8,0.2-1.2,0.2
                        c-1,0-1.6,0.4-2,1.3c-0.9,1.8-0.8,2.2,1,3.2c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.1,0.2,0.3,0.4c0,1,0.1,2.1,0.1,3.3
                        c0.1,0.9,0.5,1.6,1.3,2c1.3,0.5,2.2,1.7,3.6,1.9c0.2,0,0.4,0.2,0.5,0.3c1,1.2,2.3,1.5,3.7,1.4c0.5,0,1,0.1,1.5,0.4
                        c1.5,1,3.3,1.3,5,1.8c0.4,0.1,0.8,0.2,1.2,0.4c0.2,0.1,0.4,0.4,0.6,0.7c0.2,0.3,0,0.5-0.4,0.7c-0.4,0.2-0.8,0.3-1.2,0.4
                        c-0.8,0.2-1,0.8-0.9,1.5c0,0.2,0.1,0.4,0,0.6c0,0.3,0,0.7-0.2,0.9c-1.1,1.2-1,2.5-0.4,3.9c0,0.1,0,0.2,0,0.4
                        c-0.4,0.5-0.8,1.1-1.3,1.7c-0.2,0.2-0.5,0.4-0.8,0.4c-0.6,0.1-1.3,0.1-1.9,0.1c-1.7,0-3.4,0-5.1,0c-0.7,0-1.5,0.1-2.2,0.2
                        c-0.4,0.1-0.7,0.4-1,0.8c-0.3,0.5-0.3,1.1,0,1.5c0.3,0.3,0.6,0.6,1,0.8c0.5,0.4,0.5,0.9,0.5,1.4c-0.1,0.5-0.3,1-0.5,1.5
                        c-0.2,0.5-0.2,1,0,1.5c0.3,0.6,0.4,1.1,0.1,1.8c-0.3,0.6-0.4,1.2-0.4,1.8c0,1.9-0.6,3.8-0.5,5.7c0.1,0.7-0.4,1.2-1.1,1.4
                        c-0.9,0.2-1.9,0.3-2.7,0.9c-1.4,0.9-2.9,1.1-4.6,0.8C446,336.8,445.5,336.8,444.9,336.7z"></path>
                        <path className="st7" d="M552.5,323.6c1.7-0.3,3.2,0.2,4.6,0.8c1.5,0.7,3,1.5,4.7,1.9c0.7,0.2,1.4,0.6,1.9,1.1c1.3,1.1,2.5,2.3,3.7,3.5
                        c0.7,0.8,1.5,1.4,2.6,1.6c0.3,0.1,0.6,0.2,1,0.3c0.3-0.1,0.6-0.1,0.9-0.2c2.7-1.7,5.7-1.4,8.7-1.1c0.5,0.1,1,0.3,1.5,0.6
                        c0.4,0.2,0.7,0.5,1,0.8c1.2,1.1,2.5,1.9,4.3,1.6c0.3-0.1,0.6,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.5c-0.2,1.1-0.8,2-1.3,2.9
                        c-0.3,0.5-0.5,0.9-0.5,1.5c-0.2,3-0.6,3,2.7,3c1.3,0,2.5,0,3.8,0.1c0.4,0,0.8,0.4,0.8,0.8c0,0.3,0.1,0.6,0.1,1
                        c0,2.5,0.1,2.8-2.6,2.6c-1.2-0.1-2.3,0-3.4,0.4c-1.3,0.5-2.7,0.7-4.1,0.4c-1.5-0.3-3,0-4.4,0.5c-1.3,0.4-2.7,0.6-4.1,0.7
                        c-1.5,0.1-3-0.1-4.1-1.2c-0.3-0.3-0.7-0.4-1.1-0.6c-1-0.6-2.1-1-3.3-0.9c-0.4,0-0.8,0-1.3,0c-0.5,0-1.1-0.1-1.5-0.4
                        c-1-0.7-2.1-1.1-3.2-1.4c-0.7-0.2-1.1-0.6-1.4-1.2c-0.3-0.6-0.5-1.2-0.7-1.8c-0.4-1-1-1.9-1.9-2.5c-2-1.3-3.2-3.4-4.7-5.1
                        c-0.6-0.7-0.6-1.6-0.2-2.4c0.2-0.3,0.3-0.5,0.5-0.8c0.4-0.8,0.5-1.8,0-2.5c-0.9-1.2-0.6-2.4-0.3-3.7
                        C552.2,324.3,552.4,324,552.5,323.6z"></path>
                        <path className="st3" d="M468.5,308.4c1.7-0.4,2.8-0.9,4.1-0.9c0.3,0,0.6,0,0.9,0.1c0.2,0,0.3,0.3,0.4,0.4c0.1,0.2,0,0.4,0,0.6
                        c-0.2,0.5-0.8,0.7-1.2,1c-0.5,0.4-0.8,1.1-0.4,1.7c0.8,1.3,0.4,2.4-0.1,3.6c-0.2,0.4-0.5,0.7-0.6,1.1c-0.3,0.7,0.1,1.5,0.9,1.7
                        c0.1,0,0.2,0,0.3,0c1.6,0.4,1.8,0.6,1.9,2c0,2,0,4,0,6.1c0,0.4,0,0.8,0.1,1.3c0,0.2,0.2,0.4,0.4,0.5c1.1,0.3,1.1,1.1,1.1,2
                        c-0.1,3.5-0.2,7-0.3,10.5c0,0.7-0.1,1.5-0.2,2.2c-1,0.5-1.9,1-2.7,1.5c-0.8,0.2-1.4-0.3-2-0.4c-0.5-0.1-1-0.3-1.5-0.5
                        c-0.7-0.3-1.2-0.1-1.7,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.5,0.3-1,0.5-1.5,0.2c-0.6-0.5-1.4-0.6-2.1-0.6c-0.5,0-0.8-0.3-1-0.7
                        c-0.1-0.3-0.2-0.6-0.4-0.8c-0.6-0.8-1.9-0.8-2.4,0.2c-0.3,0.5-0.5,1.2-0.8,1.7c-0.1,0.3-0.8,0.5-1.1,0.3c-0.3-0.2-0.5-0.3-0.8-0.6
                        c-0.7-0.7-1.3-1.4-2-2c-0.5-0.5-1.1-0.6-1.8-0.4c-0.3,0.1-0.6,0.2-0.9,0.3c-1.3,0.4-1.9-0.2-2-1.3c-0.1-0.7,0-1.5,0-2.1
                        c1.1-1,2.2-1.4,3.5-1.6c1-0.1,1.4-0.8,1.6-1.7c0-0.2,0.1-0.4,0.1-0.6c-0.2-1.8,0.4-3.6,0.3-5.4c0-0.5,0.2-1,0.4-1.5
                        c0.1-0.4,0.3-0.8,0.3-1.2c0-0.7-0.4-1.5-0.3-2.2c0.1-0.7,0.6-1.3,0.5-2.1c0-1-0.4-1.8-1.3-2.4c-0.1-0.1-0.3-0.4-0.2-0.5
                        c0.2-0.2,0.4-0.5,0.7-0.6c0.3-0.1,0.6-0.1,0.9-0.1c1.1,0,2.1,0,3.2,0c1.5,0,3,0,4.5,0c0.7,0,1.2-0.3,1.6-0.8c0.3-0.3,0.4-0.8,0.7-1
                        c0.9-0.8,1-1.5,0.4-2.5c-0.3-0.6-0.3-1.3,0.1-1.8C468.6,310.5,468.9,309.6,468.5,308.4z"></path>
                        <path className="st2" d="M488.4,649.5c0.3,0.1,0.6,0.3,0.9,0.3c0.8,0.1,1.7,0.1,2.5,0.1c1.1,0,1.4,0.4,1.4,1.6c0,0.2,0,0.4,0,0.6
                        c0,0.5-0.2,0.7-0.8,0.8c-0.3,0.1-0.7,0-0.9,0.2c-0.2,0.1-0.5,0.4-0.5,0.7c-0.1,0.6-0.1,1.3,0.3,1.8c0.3,0.4,0.7,0.8,1,1.2
                        c0.2,0.3,0.2,0.8,0,1.2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.3,0.3-0.6,0.6-0.9,0.9c-0.9,1-1.8,2.1-1.8,3.6c0,0.4-0.6,0.7-1,0.6
                        c-0.3-0.1-0.6-0.1-0.9-0.3c-1-0.5-1.8-0.3-2.5,0.6c-0.4,0.5-0.9,0.5-1.5,0.3c-0.4-0.2-0.7-0.4-1.1-0.6c-1.6-0.8-3.3-1.5-5.2-1.1
                        c-0.5,0.1-1.1,0.1-1.6,0.1c-1-0.1-1.9,0.1-2.7,0.7c-0.8,0.6-1.5,0.6-2.4,0.1c-0.7-0.5-1.4-0.9-2.1-1.3c-0.7-1.4-0.6-2.8-0.9-4.1
                        c-0.3-1.2-0.7-2.4-1.1-3.6c0.5-0.4,1-0.3,1.5-0.4c1,0,1.9,0,2.9-0.1c0.4,0,0.8-0.4,0.8-0.8c0-0.5,0.1-1.1,0.1-1.6
                        c0-0.5,0-1.1,0.1-1.6c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.3,0.4-0.5,0.7-0.7c1.3-1.1,1.8-2.6,1.9-4.2c0.1-1.3-0.3-1.9-1.7-2.1
                        c-0.3-0.1-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.3-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.6,0.5-1.2,0.5-1.8c0-0.8,0-1.4-1.2-1.5
                        c-0.3,0-0.6-0.1-0.9-0.3c-0.1-0.1-0.1-0.4-0.1-0.6c0-0.1,0.1-0.2,0.2-0.3c1.1-0.7,1.8-1.5,1.6-2.8c0-0.3,0.2-0.7,0.5-0.8
                        c0.6-0.3,0.9-0.8,1.2-1.4c0.3-0.6,1.4-0.8,2-0.4c0.2,0.1,0.4,0.3,0.4,0.4c0.4,1.3,1.3,2.4,1.3,3.9c0,0.9,0.6,1.6,1,2.3
                        c0.5,0.8,1.1,1.2,2,1.3c0.5,0,1.1,0.2,1.6,0.1c1-0.1,1.9,0.2,2.6,0.9c0.6,0.6,1.4,1,2.3,1c1.1,0,2,0.5,2.9,1.2c1,0.8,2,1.6,2.9,2.4
                        c0.1,0.1,0,0.5-0.1,0.5c-1.3,0.4-2.3,1.5-3.6,2c-1,0.4-1.2,1.1-1.1,2C488.3,648.8,488.3,649.2,488.4,649.5z"></path>
                        <path className="st7" d="M430.2,324c0-0.8,0-1.5-0.1-2.2c-0.1-1.1,0.3-1.8,1.3-2.3c0.5-0.3,0.7-0.8,0.8-1.3c0-0.5,0-1.1-0.1-1.6
                        c-0.1-0.7,0-1.3,0.8-1.5c0.2-0.1,0.4-0.1,0.6-0.3c0.8-0.9,1.5-1.7,2.3-2.6c0.4-0.5-0.1-1.4-0.7-1.5c-0.6,0-1.3-0.1-1.9-0.1
                        c-0.8,0-1.7,0-2.4-0.1c-0.8-0.6-1.1-1.4-1.4-2.2c-0.2-0.5-0.3-1,0.1-1.5c0.7-0.9,0.8-1.9,0.7-3c-0.1-0.5,0-1,0.1-1.6
                        c0-0.3,0.6-0.5,0.8-0.3c0.2,0.2,0.4,0.4,0.7,0.6c1.2,1.1,2.2,0.9,3.1-0.5c0.1-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.6-0.4,0.8-0.4
                        c0.8,0.1,1.7,0.3,2.5,0c0.3-0.1,0.7-0.1,0.9,0.1c1.3,0.8,2.6,0.6,3.9,0.1c1.6,0.6,3.1,1.1,4.2,2.5c0.2,1.2,0.1,2.4,0,3.7
                        c-0.9,1.2-0.5,2.1,0.4,3.1c0.4,0.4,0,1.2-0.5,1.3c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0.1-1.4-0.2-1.7,0.5c-0.3,0.8-0.4,1.8,0.2,2.4
                        c0.6,0.7,0.8,1.4,0.8,2.3c0,0.3,0.1,0.6,0.1,0.9c0,0.3-0.2,0.6-0.6,0.7c-0.4,0.1-0.8,0.1-1.2,0.2c-0.1,0-0.2,0.1-0.3,0.2
                        c0,0.9-0.1,1.8-0.1,2.8c0,4.6,0,9.2,0,13.7c0,0.7-0.1,1.5-0.1,2.2c-0.1,0.5-0.8,0.8-1.2,0.5c-0.3-0.2-0.5-0.4-0.7-0.6
                        c-1.1-0.8-2.3-1.1-3.6-1c-0.9,0.1-1.7,0.4-2.2,1.1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.7,0.5-1.1,0.5c-0.9,0.1-1.9-0.2-2.9,0
                        c-1,0.3-1.4,1.2-2.2,1.8c-0.2,0.1-0.8,0.1-0.9-0.1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.4-0.5-0.7-0.6-1.1c-0.5-1.4-0.6-2,0.7-3.1
                        c1.9-1.6,3.5-3.4,4.8-5.5c0.2-0.4,0.5-0.7,0.7-1.1c0.8-1.5,0.7-3-0.8-4.3C431.6,325,430.9,324.6,430.2,324z"></path>
                        <path className="st7" d="M396.8,303.8c-1-1.8-2.2-3.4-3.7-4.7c0.4-0.7,0.7-1.3,0.9-1.8c0.2-0.6,0.1-1.3,0.2-1.9c0-0.7,0.3-1.2,1-1.4
                        c1-0.3,1.6-1.1,1.8-2.1c0-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.9-0.5,1.1-0.1c0.2,0.4,0.4,0.7,0.6,1.1c0.3,0.6,0.6,1.1,1.3,1.2
                        c1.3,0.3,2.2,1.2,3.1,2.1c0,1.3,0,2.5,0,3.9c1.1,1,2.3,1.9,3.5,2.7c0.5,0.4,1.1,0.7,1.4,1.2c1,1.4,2.4,2.4,3.6,3.6
                        c0.5,0.5,1.2,0.9,1.9,1.2c1.4,0.7,2.7,1.5,3.9,2.8c0.9,1,2.2,1.1,3.6,0.9c0.4-0.1,0.8-0.4,1-0.7c0.4-0.4,0.8-1,1.3-1.4
                        c0.1-0.1,0.4,0,0.6,0.1c0.5,0.3,0.6,0.9,0.7,1.4c0.2,1.1,1,1.6,2,1.9c0.4,0.1,0.8,0.1,1.3,0.2c0.9,0.1,1.7-0.1,2.2-0.9
                        c0.3-0.5,0.8-0.8,1.3-0.8c0.7,0,1.5,0,2.2,0.1c0.3,0,0.4,0.4,0.3,0.8c-0.5,0.9-1,1.8-2.1,2.2c-0.9,0.3-1,0.9-0.8,2.1
                        c0.1,0.3,0.2,0.6,0.2,0.9c0,0.4-0.1,0.8-0.5,1.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.6,0.3-0.9,0.8-1,1.5c-0.1,0.7-0.1,1.5-0.1,2.2
                        c-0.1,0.9-0.4,1.2-1.5,1.2c-1.2,0-2.3-0.1-3.5-0.4c-1.2-0.4-2.1-1.1-2.6-2.2c-0.3-0.6-0.5-1.2-0.9-1.7c-0.2-0.4-0.6-0.6-1-0.7
                        c-0.7-0.1-1.5-0.1-2.2-0.1c-0.7,0-1.2-0.2-1.8-0.6c-1.5-0.9-3-1.6-4.5-2.4c-1.1-0.6-2.3-1-3.5-1.6c-1.8-1-3.6-1.9-5.6-2.1
                        c-1.1-0.1-1.9-0.8-2.6-1.7c-0.6-0.9-1.2-1.8-2-2.5c-0.8-0.7-1-1.6-1-2.6C396.6,304.8,396.7,304.3,396.8,303.8z"></path>
                        <path className="st7" d="M278.1,263.2c0.6-0.8,1.7-0.9,2.2-1.8c0.1-0.2,0.5-0.4,0.8-0.4c0.7-0.1,1.2-0.4,1.8-0.6c0.2-0.1,0.6,0,0.9,0.2
                        c0.3,0.2,0.4,0.5,0.6,0.7c0.7,0.6,1.3,0.6,1.9,0c0.4-0.5,0.8-1,1.4-1.3c0.3-0.1,0.5-0.5,0.6-0.7c0.3-1.2,1-1.5,2.1-1.2
                        c0.3,0.1,0.7,0.1,0.9,0c0.9-0.3,1.6,0,2.2,0.7c0.6,0.7,1.6,0.4,2.1-0.4c0.2-0.3,0.2-0.6,0.4-0.9c0.3-0.5,0.7-0.8,1.3-0.9
                        c0.9-0.1,1.9-0.2,2.8-0.2c1-0.8,1.9-1.5,2.8-2.3c0.8-0.6,1.2-1.5,1.6-2.3c0.5-1,0.9-1.9,2-2.4c1-1.2,2.4-2.1,2.9-3.7
                        c0.2-0.5,0.6-0.6,1-0.4c0.4,0.2,0.7,0.4,1.1,0.6c0.4,0.2,0.9,0.1,1.1-0.3c0.3-0.5,0.4-1,0.7-1.4c0.2-0.1,0.3-0.3,0.5-0.3
                        c2-0.8,2.5-0.7,3.7-3.2c0-0.1,0.1-0.2,0.2-0.3c0.3-0.4,1-0.6,1.4-0.4c0.4,0.2,0.7,0.5,1.1,0.6c0.9,0.4,1.9,0.3,2.8-0.2
                        c0.3-0.2,0.7-0.5,0.7-1c0-0.5-0.2-0.8-0.6-1c-0.4-0.2-0.8-0.4-1.1-0.6c-0.1,0-0.2-0.2-0.2-0.2c0.1-0.2,0.2-0.4,0.3-0.5
                        c0.1-0.1,0.2-0.2,0.3-0.2c1.5-0.2,2.6-0.9,3.1-2.4c0.2-0.6,0.7-1.1,1.2-1.5c1.1-1,2.4-1.8,3.5-2.7c0.6-0.5,1.4-0.6,2.1-0.5
                        c0.3,0,0.6,0.1,0.9,0.2c0.9,0.4,1.8,0.5,2.8,0.3c1.1-0.2,2.1,0,3.1,0.5c0.3,0.1,0.6,0.3,0.8,0.5c0.7,0.6,1.6,0.6,2.4,0.4
                        c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.4,0.2,0.4,0.4c0.2,0.8,0.2,1.7-0.2,2.5c-0.1,0.2-0.3,0.3-0.4,0.5c-0.8,1.5-0.6,2.3,1.1,2.9
                        c0.8,0.3,1.3,0.6,1.3,1.5c0,0.2,0,0.4,0.1,0.6c0.2,1.1,1,1.6,2.1,1.5c0.1,0,0.2,0,0.3,0c1-0.1,1.7-1,2.7-0.8
                        c0.9,0.1,1.7-0.2,2.5-0.4c0.7-0.2,1.5,0,2.2,0c0.6,0,1.2-0.1,1.8-0.5c0.6-0.4,1.2-0.8,1.9-1.2c1.6-0.9,1.6-0.9,3,0.4
                        c0.3,0.3,0.6,0.7,1,0.8c1,0.3,1.5,1.1,2,2c0.3,0.6,0.8,1,1.5,1.1c0.4,0.1,0.8,0.3,1.2,0.3c1.4-0.2,2.2,0.3,2.6,1.7
                        c0.2,0.5,0.6,0.9,1.2,1c0.9,0.1,1.3,0.7,1.3,1.5c0,1.5,0,3,0,4.5c0,2.2,0.2,4.5,1.1,6.6c0.2,0.6,0.3,1.2,0.4,1.9
                        c0.1,0.5,0,1.1,0,1.6c1,1.2,2,2.3,2.3,3.8c0.2,0.9,0.9,1.4,1.6,2c0.9,0.7,1.3,1.7,1.3,2.8c0,0.5,0.2,1.1,0.2,1.6
                        c0,1.2,0.4,2,1.3,2.8c0.6,0.5,0.7,1.3,0.4,2c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.2-0.2,0.4-0.2,0.6c-0.2,0.5-0.1,1,0.3,1.5
                        c0.3,0.3,0.5,0.7,0.7,0.9c0,0.4,0,0.7,0,1c-0.2,1.1-0.1,2.1,0.5,3c0.6,0.8,0.6,1.8,0.3,2.8c-0.2,0.8-0.6,1.6-0.5,2.5
                        c0,0.7,0.1,1.3,0.6,1.7c0.3,0.3,0.6,0.6,0.9,0.9c0.5,0.8,0.4,1.5-0.5,1.8c-1.2,0.4-2.3,1.1-3.4,1.8c-0.8,0.5-1.7,0.9-2.6,1.2
                        c-0.7,0.3-1.5,0.3-2.2,0c-0.6-0.2-1.2-0.5-1.5-1.2c-0.2-0.5-0.4-1-0.6-1.5c-0.8-1.9-2.8-2.8-4.7-2.2c-0.4,0.1-0.8,0.3-1.2,0.5
                        c-1.5,0.5-3,0.6-4.4-0.1c-4.2-2-8.6-3-12.8-4.8c-0.5-0.2-1-0.4-1.5-0.5c-1.1-0.2-2.1,0.1-2.8,1c-0.4,0.5-0.7,1.1-1.1,1.5
                        c-0.7,0.8-2.3,0.6-2.7-0.4c-0.2-0.4-0.4-0.8-0.4-1.2c-0.2-1.2-0.6-2.2-1.6-3c-0.9-0.7-1.3-1.7-1.7-2.7c-0.2-0.5-0.5-0.9-1.1-1.1
                        c-0.7,0.2-0.9,0.8-1.3,1.3c-0.6,0.7-1.3,1.1-2.2,1.1c-0.8-0.1-1.4,0.4-1.9,1c-0.4,0.5-0.9,1-1.1,1.5c-0.4,0.9-1,1.6-1.9,2
                        c-0.8,1.1-1.8,1.3-3.1,1c-1-0.2-2.1-0.3-3-0.9c-0.4-0.3-0.8-0.2-1.2,0.1c-0.6,0.4-1.2,0.9-1.8,1.4c-0.2,0.2-0.4,0.5-0.4,0.8
                        c-0.1,0.6-0.1,1.3,0,1.9c0.1,0.8,0.5,1.4,1.3,1.6c0.5,0.2,1.2,0,1.4-0.4c0.3-0.4,0.5-1,0.8-1.4c0.1-0.1,0.4-0.2,0.5-0.2
                        c1,0.7,2.3,1.1,2.7,2.5c0.1,0.5,0.6,0.6,1,0.7c0.6,0.1,1.3,0.1,1.8,0.5c0.3,0.2,0.4,1.3,0,1.4c-1.4,0.6-2.8,1.5-4.5,1.4
                        c-0.4,0-0.9,0-1.3,0c-1.1,0.1-2.1,0.4-2.7,1.5c-0.3,0.4-0.6,1-1.1,1.1c-1.4,0.5-2.3,1.5-3.3,2.4c-0.5,0.4-1.3,0.4-1.8,0
                        c-0.7-0.5-1.4-1-2-1.6c-0.7-0.6-1.5-1.2-2.5-1.3c-0.3,0-0.5-0.4-0.7-0.6c0.3-0.5,0.5-1,0.8-1.4c-0.4-0.5-0.7-0.9-1-1.2
                        c0.1-0.2,0.1-0.4,0.1-0.6c0.8-1.4,2-2.4,2.6-4c0.2-0.5,0.7-0.7,1.3-0.6c0.3,0.1,0.6,0.1,0.9,0.1c0.6-0.1,1-0.4,1.1-1
                        c0.1-0.4,0-0.8-0.3-1.2c-0.5-0.4-0.9-0.9-1.4-1.3c-0.6-0.5-1.5-0.5-2,0c-0.2,0.1-0.3,0.3-0.4,0.5c-0.5,0.8-1.3,1-2.2,0.8
                        c-0.5-0.1-1.1-0.1-1.6-0.3c-1.6-0.4-3.1-0.5-4.7-0.1c-1.6,0.3-3.2,0.4-4.8,0.1c-1.1-0.2-1.9,0.2-2.3,1.2c-0.1,0.2-0.2,0.4-0.4,0.5
                        c-0.2,0.2-0.9,0.3-1.2,0.1c-0.4-0.3-0.9-0.6-1.2-1c-1-1.2-2.2-1.6-3.7-1.5c-0.4,0-0.8,0.1-1.3,0.1c-1,0-1.9,0-2.9,0
                        c-0.9,0-1.3-0.3-1.2-0.7c0.1-0.5,0.5-0.7,0.9-0.8c0.3-0.1,0.6-0.2,0.9-0.3c0.9-0.4,1.2-1.1,1.2-2.1c0-1.1-0.6-2-1.6-2.1
                        c-1.7-0.2-2.7-1.5-3.9-2.5c-0.1-0.1-0.1-0.4,0-0.6c0.2-0.4,0.4-0.7,0.7-1c0.6-0.6,1.1-1.3,1.5-2.1c0.2-0.5,0.6-0.8,0.9-1.3
                        c0.3-0.4,0.7-0.8,0.9-1.3c0.3-0.7,0.6-1.3,1.3-1.7c0.8-0.5,1-1.3,1-2.2c0-0.7-0.1-1.5-0.4-2.2c-0.3-0.6-0.9-1-1.5-1
                        c-0.7,0.1-1.3,0.5-1.3,1.2c-0.1,1.3-0.7,2.2-1.5,3.1c-0.2,0.2-0.5,0.4-0.8,0.5c-0.8,0.3-1.8-0.5-1.7-1.4c0-0.1,0-0.2,0-0.3
                        c0.6-2.1,0-2.5-1.7-3c-0.2,0-0.4-0.2-0.5-0.3c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.2,0.1-0.4,0.2-0.6C278.5,265.3,278.4,264.2,278.1,263.2z
                        M257.9,273.4c-0.2,0.9-0.7,1.5-1.6,1.9c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.5-0.4,1-0.5,1.5c0,0.3,0.2,0.6,0.4,0.8
                        c0.6,0.5,0.6,1.1,0.2,1.7c-0.5,0.7-0.2,1.2,0.4,1.6c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.3,0.3,1,0,1.2c-0.9,0.5-1.6,1.4-2.7,1.7
                        c-0.7,0.2-1.2,0.6-1.4,1.2c-0.4,0.8-1,1.4-1.7,1.9c-0.6,0.4-1.2,0.9-1.6,1.5c-0.1,0.3-0.1,0.7,0.1,0.9c0.2,0.1,0.4,0.2,0.6,0.2
                        c0.6,0,1-0.3,1.4-0.6c0.5-0.5,1-1.1,1.5-1.6c0.6-0.6,1.6-0.3,1.8,0.4c0.2,0.5,0.2,1,0.4,1.5c0.1,0.3,0.4,0.5,0.7,0.5
                        c0.3,0,0.6-0.2,0.8-0.4c0.2-0.2,0.2-0.6,0.4-0.9c0.4-0.7,1-0.7,1.5,0c0.1,0.1,0.1,0.2,0.1,0.3c0.3,1,1,1.3,1.9,1.4
                        c0.5,0,1.1,0,1.6,0c0.5,0,1,0.1,1.5,0.5c1.2,0.9,2.4,0.6,3.6,0.2c0.1,0,0.3-0.3,0.2-0.5c0-0.4-0.3-0.8-0.7-1
                        c-0.5-0.2-1.1-0.1-1.5-0.4c-1.1-0.6-2.2-1.3-2.9-2.4c-0.2-0.2-0.3-0.6-0.2-0.9c0.2-0.9,0.5-1.6,1.3-2.1c0.3-0.2,0.9-0.2,1.2,0.1
                        c0.2,0.2,0.4,0.5,0.6,0.7c0.3,0.4,0.7,0.8,1,1.2c0.2,0.3,1,0.1,1.1-0.2c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.5,0.7-0.6,1.4-0.5
                        c0.4,0.1,0.8,0.5,0.8,0.9c0,0.5-0.1,1.1,0,1.6c0,0.2,0.4,0.3,0.7,0.5c0.3-0.7,0.6-1.2,0.9-1.8c0.2-0.4,0.6-0.5,1.1-0.3
                        c0.4,0.1,0.8,0.3,1.2,0.4c0.3,0.1,0.6-0.3,0.5-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.4-1-0.8-2-1.3-2.9c-0.6-1-0.5-2-0.7-3.1
                        c-0.1-0.3-0.3-0.6-0.5-0.8c-0.3-0.4-0.7-0.8-1-1.2c-0.2-0.3-0.3-0.8-0.4-1.2c-0.1-0.6,0.2-1.3,0.7-1.6c0.5-0.3,1.3-0.2,1.7,0.4
                        c0.3,0.4,0.6,0.9,0.8,1.4c0.2,0.5,0.5,0.9,1.1,1c0.4,0,0.7-0.6,0.8-1.5c0-0.2,0-0.4,0-0.6c0.1-0.8-0.2-1.4-0.9-1.9
                        c-0.9-0.6-1.1-1.5-1-2.5c0.1-0.5,0-1.1,0-1.6c0-0.6-0.8-1.1-1.4-1.1c-0.5,0-1,0.4-1.2,0.9c-0.2,0.5-0.2,1-0.4,1.5
                        c-0.4,1-1.8,1.5-2.7,1c-0.6-0.4-1.2-0.8-1.9-1.2c-0.6-0.3-1.3-0.2-1.7,0.3c-0.5,0.7-1,1.4-1.5,2c-0.6,0.7-1.3,1-2.2,1
                        c-0.7,0-1.5-0.1-2.1-0.2C258.2,273,258,273.2,257.9,273.4z M199.9,294.1c-0.7,1.1-1.6,2-2.9,2.4c-0.4,0.1-0.7,0.5-0.9,0.8
                        c-0.3,0.4-0.5,1-0.6,1.5c-0.1,0.4,0,0.8,0.4,1.1c0.4,0.3,0.9,0.4,1.2,0.1c1-1,2.2-1.2,3.5-1c0.5,0.1,1-0.4,1.2-0.9
                        c0.2-0.8,0.3-1.7,0.5-2.5c0.1-0.5,1.1-0.9,1.6-0.7c0.4,0.2,0.7,0.4,1.1,0.7c0.6,0.5,1.3,0.7,2.1,0.5c1.1-0.3,2.1,0,3,0.7
                        c0.2,0.2,0.5,0.4,0.8,0.6c1.4,0.8,3,1.1,4.6,0.8c0.3-0.1,0.6-0.3,0.7-0.6c0.1-0.2-0.1-0.7-0.2-0.9c-1.5-0.9-2.7-2.1-3.8-3.3
                        c-0.8-0.9-1.9-1.3-3.1-1.4c-0.6,0-1.3,0-1.9,0c-0.3,0-0.6,0-1,0c-0.7-0.1-1.2-0.4-1.6-1c-0.4-0.5-0.3-1,0.1-1.5
                        c0.3-0.5,0.4-0.9,0.3-1.5c-0.1-0.4-0.1-0.8-0.2-1.3c0-0.3,0.5-0.8,0.8-0.8c0.3,0,0.6,0.1,0.9,0.1c0.3,0,0.6-0.1,0.6-0.5
                        c0.1-0.5,0-1.1,0.5-1.4c0.6-0.4,1.2-0.1,1.8,0.2c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6-0.2,0.7-0.5c0.1-0.3,0-0.6,0.1-0.9
                        c0.2-0.7,0.7-1,1.4-1c0.6,0,1.1,0.2,1.3,0.7c0.4,0.8,0.8,1.5,1.6,2c0.5,0.3,0.6,0.8,0.6,1.4c0,0.3,0.1,0.6,0.2,1
                        c0.4,0.1,0.8,0.2,1.2,0.3c0.6,0.1,1.3,0.3,1.7,0.8c0.8,0.8,1.7,1.2,2.8,1.3c0.7,0.1,1.4,0.4,2.1,0.8c0.4,0.2,0.8,0.4,1.1,0.5
                        c0.3,0.1,0.8-0.2,1-0.6c0.2-0.4,0.2-1-0.2-1.2c-1.6-1.1-2.5-2.5-2.5-4.5c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.7-0.6-1.2-1.2-1.3
                        c-1.7-0.4-3.2-1.2-4.4-2.4c-0.4-0.4-0.9-0.6-1.3-0.9c-0.3-0.2-0.7-0.5-0.9-0.9c-0.6-0.9-1.5-1.5-2.4-2.1c-0.8-0.5-1.6-1-2.1-1.9
                        c-0.1-0.2-0.3-0.3-0.4-0.5c-0.5-0.4-1.1-0.7-1.7-0.8c-0.6-0.1-1,0.3-1.4,0.7c-0.7,0.6-1.5,0.8-2.4,0.7c-0.5-0.1-1-0.2-1.6-0.2
                        c-0.3,0-0.6,0.3-0.7,0.5c-0.2,0.5-0.3,1-0.2,1.5c0.1,0.7,0.4,1.4,0.6,2.1c0.1,0.3,0.2,0.7,0.1,0.9c-0.6,1.5-0.5,3.4-2.4,4.2
                        c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2,0.3-0.3,0.9,0,1.2c0.4,0.3,0.8,0.7,1.3,0.9c2.6,1.4,2.6,1.5,0.4,3.4c-0.1,0.7-0.1,1.6-0.2,2.5
                        C200.8,293,200.3,293.5,199.9,294.1z M236.6,275.2c1.4,0.8,1.4,1.9,0.2,3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.5-0.6,0.7-0.2,1.4
                        c0.5,0.8,0.6,1.6,0.3,2.4c-0.3,0.7-0.6,1.4-0.8,2.1c-0.2,0.4-0.3,0.8-0.5,1.2c-0.1,0.4,0.1,0.9,0.5,1c0.6,0.2,1.3,0.1,1.4-0.3
                        c0.4-1.3,1.1-2.5,1.3-3.9c0.1-0.5,0.6-0.7,1-0.5c0.7,0.3,1.3,0.8,2,0.8c1,0.1,0.9-1.3,1.7-1.6c0.6-0.3,0.5-0.8-0.1-1.2
                        c-0.7-0.5-1-1-0.8-1.9c0.1-0.4,0.3-0.8,0.3-1.2c0.1-0.6-0.2-1.1-0.6-1.4c-0.4-0.3-0.9-0.5-1.4-0.7c-1.6-1-3.3-1.9-4.4-3.6
                        c-0.3-0.5-0.9-0.9-1.5-1.1c-1-0.3-1.9-0.9-2.7-1.6c-2.2-2.1-2.2-1.3-4.6-0.8c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0.4-1.2,0.9-1.5,1.6
                        c-0.1,0.3,0.1,1,0.4,1.1c0.5,0.1,1,0.3,1.5,0.3c0.8,0,1.7-0.2,2.5-0.2c0.9,0,1.7,0.3,2.2,1c0.3,0.4,0.6,0.8,0.9,1.3
                        c0.3,0.5,0.5,0.9,0.7,1.4C234.9,274.3,235.8,274.7,236.6,275.2z M300.2,300.9c-0.4-0.3-0.6-0.8-1-1.2c-0.1-0.1-0.5-0.1-0.6-0.1
                        c-1.1,1-2.3,1.5-3.7,1.5c-0.3,0-0.6,0.2-0.9,0.4c-0.8,0.3-1.5,0.2-2-0.5c-0.7-1.1-1.6-1.6-2.9-1.2c-0.6,0.2-1.3,0.1-1.8,0.1
                        c-0.1,0.2-0.2,0.2-0.3,0.3c-0.4,1.1-1,2-2.4,2.2c-1.2,0.2-1.6,1.2-1.8,2.3c0,0.1,0.1,0.4,0.3,0.5c0.4,0.1,0.8,0.2,1.2,0.2
                        c0.6-0.1,1.2-0.3,1.8-0.5c0.9-0.2,1.7-0.2,2.3,0.5c0.8,0.8,1.8,1,2.9,1.1c0.2,0,0.4-0.2,0.5-0.3c0.1-0.3,0.2-0.6,0.3-0.9
                        c0.1-0.3,0.5-0.5,0.7-0.4c0.2,0.2,0.5,0.4,0.6,0.7c0.1,0.4,0,0.8,0,1.2c1.3,0.4,1.3,0.4,2.8,1.9c0.1,0.1,0.5,0,0.5,0
                        c0.3-0.9,1.2-1.1,1.6-1.9c-0.2-0.4-0.4-0.7-0.6-1.1c-0.3-0.8,0.1-1.6,1-1.5c0.4,0,0.8,0.1,1.3,0.1c0.5-0.8,0.9-1.6,1.3-2.4
                        C301.2,301.4,300.6,301.3,300.2,300.9z"></path>
            
                    </svg>


                    <svg version="1.1" className="map" id="sml_state" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1000 1000" >
                        <path className="st14" d="M645.9,106.7c-0.6,17-1.5,34,6.5,49.9c2.9,6,2.5,12.4-2.6,17.7c-2.1,2.3-4.1,4.9-5.8,7.6
                            c-3.8,5.7-4.2,11.9-2,18.2c1,3,2.6,5.9,4.3,8.6c3.1,4.9,3.9,10.2,0.5,14.9c-6.6,8.7-6.2,17.7-1.8,27c2.2,4.7,4.8,9.2,7.3,13.9
                            c2,3.9,3.7,8,0.6,11.8c-5.8,6.9-8.1,14.9-9.2,23.4c-3.3,25.3-6.7,50.6-9.7,75.9c-0.5,4.1,0.2,8.7,1.9,12.4c1,2.1,1.5,4.2,1.6,6
                            l-16.7,0.6l-10.7,2.9l-40.3,10.7l-3.7-9.1l-52.4,7.6l6.1-11.3l5.2-28.7l-3.1-21.8c0,0-35.3,6.1-38.1,6.7
                            c-2.9,0.6-13.9,14.8-13.9,14.8L450.5,377l-14.2-29.2l3.7-10.1l0.5-11l7.8-1.7l-1.2-20.6l-0.4-6.2l44-10.1c0,0-9-26.7-4.5-27.5
                            c4.7-0.9,41.1-8.7,41.1-8.7s-5.9-15.8-5.4-17.7c0.5-2,11.3-24.6,11.5-28.6c0.3-4,9.5-17,9.5-17l-4.5-7.3l9.4-6.8
                            c0,0,3.2-2.8,5.1-5.5c2-2.8-10.1-14.6-10.1-14.6s-9.6-7.5-8.2-9.4c1.5-1.9,0.8-5.7,0.8-5.7s-8.3-8-7.2-8.7c1.2-0.7-5.1-5.3-5.1-5.3
                            l-16.9,0.2l-13.4-3.8l-7.3,4.8l-7.3-19.5l2.8-37.7c6.7-1.9,12.6-5.7,17.9-11.4c7.4-8.3,17-7.9,26.6-5.2c6,1.8,11.8,4.6,17.6,7.4
                            c10.5,5.2,20.6,3.8,30.6-1.2c4.7-2.3,8.9-5.9,13.8-7.9c2.6-1.1,6.7-1.7,8.8-0.4c6.4,3.8,12.5,7.9,20.4,8.1c2.9,0.1,6.7,1.7,8.3,3.9
                            c5.6,7.6,12.9,10.6,21.9,11.3c2,0.1,4.1,1.1,6,1.8c4.9,1.7,7.7,9.1,4.7,12.9c-1.3,1.7-2.9,3.3-4.5,4.6
                            C648.9,96.8,646.1,101,645.9,106.7z"></path>
                        <path className="st13" d="M525,366.7l-5.2,28.7l-6.1,11.3l-2.1,3.8l-51.7,58.2l4.7,6.5l-2.4,8.1l-0.8,46.7l-1.4,78.5l-22.6,0.5l0.4,62.1
                            l-57.1,0.8l-5.7,25c0,0-0.4,2-0.9,5.2c-1.6,9.5-4.9,28.8-5.7,30.7c-0.9,2.3-12.7,22.6-12.7,22.6l0.5,18.9c0,0-0.5,24.1-1.4,25.5
                            c-0.9,1.4-6.2,27-6.2,27s-3.7,35.7-2.8,37.2c1,1.4,12.7,15.1,14,18.1c1.2,3.2,7.7,12.5,7.7,12.5s-5.2,10.4-7.8,19.6
                            c-5.2-1.4-10.1-4.3-14.4-8.5c-11.3-11.3-22.6-22.4-33.9-33.6c-11.6-11.3-24.3-20.3-41-23c-7.2-1.1-14.2-4.6-20.6-8.1
                            c-17-9.5-34.7-15.8-54.3-17c-11.6-0.7-23.1-3.7-34.6-5.8c-9.5-1.8-13.8-9-11.3-19.2c0.5-2,1.6-3.9,2.6-5.7c1.9-3.2,8.2-6.3,11-5.4
                            c6,2,12,4.1,18,6.3c6.4,2.3,12.6,1.8,18.6-0.9c4.8-2.1,9.5-4.7,14.1-7.3c5.9-3.2,10.3-7.9,10.9-14.8c0.7-10,5.5-17.7,11.9-25.3
                            c9.1-10.9,12.9-24.1,12.2-38.6c-0.6-10.9-2.8-21-10.5-29.3c-2.1-2.3-4.1-4.8-6-7.3c-2.8-3.7-1.7-9,2.1-10.6c2-0.8,4-1.5,6-1.9
                            c8.5-1.7,12.6-7.2,14.3-15.2c0.4-2.1,0.7-4.5,1.8-6.1c1.7-2.6,4.3-4.7,7.8-3.8c7.3,1.8,14.3,4.1,21.6,5.4c9.7,1.7,19.2,1.2,27.3-5.2
                            c3.3-2.6,7-4.9,10.6-7.2c6.6-4.2,13.9-5.3,21.5-4.1c7.3,1.2,14.5,3.3,21.9,4.6c7.4,1.2,14.6-0.1,21.6-3.4
                            c11.3-5.4,12.9-23.2,1.7-28.4c-8.5-3.9-13.4-9.5-14.1-19.3c-0.6-7.6-5.6-13.7-10.7-19.2c-5.6-6-6-13.3-6-20.8
                            c0.1-9.2,2.5-12.1,12.8-15.1c2-0.7,4.2-0.8,6.2-1.3c4.7-1.1,7.5-4.5,8.4-8.7c2-10.3,3.4-20.8,5.4-33.1c15.7-15.9,30.9-30.7,45.4-46
                            c2.6-2.7,3.7-7.8,3.8-11.7c0.1-11.3-4.7-20.6-14.2-27c-5.3-3.5-11-6.4-16.4-9.9c-2.5-1.6-4.6-8.9-3.3-11.6c1.7-3.9,3.9-7.5,5.5-11.4
                            c1.6-3.8,3.1-7.9,3.8-12c1.1-6.4-2.6-12.6-9.1-15c3.4-0.9,19.1-6,19.1-6l20.4-22.2l1.2,20.6l-7.8,1.7l-0.5,11l-3.7,10.1l14.2,29.2
                            l19.4-10.6c0,0,11-14.2,13.9-14.8c2.8-0.6,38.1-6.7,38.1-6.7L525,366.7z"></path>
                        <path className="st9" d="M851.4,864.9c-0.2,3.5-2.5,6-5.6,7.1c-3.9,1.5-8.2,2.7-12.3,2.9c-9.3,0.5-16.2,4.2-21.2,12
                            c-3.6,5.7-8.8,8.3-15.8,9.4c-6,0.9-11.6,4.7-17.4,7.3c-3.9,1.8-7.8,4.8-11.6,4.7c-14.1-0.7-23.9,7.4-34.3,15
                            c-29.9,21.6-30.8,21.6-67.1,25.4c-11,1.2-22,1.6-32.9,1.3c-0.1-29.7-0.5-206.3-0.5-207.6c0-1.4,0.2-15.5,0.2-15.5s-4.8-9-4.8-10
                            c0-0.8-0.4-50.7-0.6-68.8l10-1.9l-10.8-21.8l3.2-7.7c0,0,35.9-1.1,35.1-0.7c-0.9,0-19.3-68.8-19.3-68.8L625,505.1
                            c0,0-10.9-84.5-15-107.6l10.7-2.9l16.7-0.6c0.1,4.1-1.7,7.7-4.5,11.5c-2.3,3.3-5.1,7.8-4.7,11.4c1.7,14.9,0.9,29.7,0.3,44.6
                            c-0.3,6.8,5.8,14.6,12.7,16.6c4.1,1.1,8.4,1.2,12.6,2c8.5,1.5,10,3.2,11.5,12.6c0.6,3.1,0.6,6.3,1,9.5c1.1,6.8,4.9,12.7,11.1,14.5
                            c9.4,2.7,14.4,8.2,16.9,17c0.6,2,1.7,4.6,3.3,5.3c15.7,7.5,22.6,25.3,38.8,32.4c8.3,3.7,12.3,11.4,12.4,20.8
                            c0.1,8.5,2,16.7,3.4,25.1c2.2,12.4,2.1,25.1,2.9,37.8c0.1,1,0,2.2-0.6,3.1c-7.8,13.1-3.7,24.2,5.2,35c2.6,3.2,3.7,7.6,4.8,11.7
                            c3.2,10.8,10.3,15.5,21.3,15c3.2-0.1,6.4-0.7,9.6-0.5c4.3,0.3,8.8,1.7,10.4,6c2.8,7.4,8.2,12.2,14.2,16.7c0.7,0.5,1.3,2.3,1,2.7
                            c-1,1.4-2.5,2.5-4,3.3c-0.8,0.5-2,0.3-3.2,0.5c-15.3,2.3-18,4.7-16.8,20.2c0.5,7.2-0.6,12.4-6.2,16.6c-1.8,1.3-3.6,2.5-4.9,4.1
                            c-3.7,4.2-5.6,9-3.5,14.6c2.4,6.7,7.6,10.5,14.1,11.3c5.1,0.6,10.5-0.8,15.7-1.6c7.2-0.9,12.4,11,10.1,16.6
                            c-2.2,5.4-7.1,7.7-10.6,11.6c-5,5.7-5.1,12-0.6,17.2c5.9,6.8,12.8,8.7,19.7,6.1c6.4-2.3,10.4-7.1,13.6-12.7c0.5-0.7,2.1-1.3,2.6-1
                            c1.4,1,3.3,2.3,3.6,3.9C851.6,858.5,851.5,861.7,851.4,864.9z"></path>
                        <path className="st10" d="M633.2,949.9c-17.8-0.2-35.4-1.8-53.1-3c-6.1-0.4-12.6-2.5-18-5.6c-11.6-6.5-23.8-9-36.8-7.6
                            c-7.9,0.7-14.5-2.2-20.6-7.1c-6.6-5.3-13.3-10.6-19.9-16c-6.9-5.6-14.8-8.2-23.6-8.3c-15.9,0-31.9-0.1-47.9-0.1
                            c-12.1-0.1-22.4,5.8-33.1,10.3c-7.1,3-14.1,3.5-20.7,1.7c2.6-9.2,7.8-19.6,7.8-19.6s-6.5-9.3-7.7-12.5c-1.3-3.1-13-16.7-14-18.1
                            c-0.9-1.5,2.8-37.2,2.8-37.2s5.3-25.6,6.2-27c0.9-1.4,1.4-25.5,1.4-25.5l-0.5-18.9c0,0,11.8-20.3,12.7-22.6
                            c0.7-1.9,4.1-21.2,5.7-30.7c17.6,0,70.3,0.1,72.8,0.3c3.2,0.4,42.2,13.4,45.1,15.3c2.8,2,27.2,1.5,27.2,1.5l-1.7-27.2
                            c0,0,88.6,1.2,94.5,1.7c6,0.6-2.1-38.6-2.1-38.6l9.8,2.2l-1.9-7.6l10-1.9c0.2,18.1,0.6,68,0.6,68.8c0,1,4.8,10,4.8,10
                            s-0.2,14.1-0.2,15.5C632.8,743.6,633.1,920.2,633.2,949.9z"></path>
                        <path className="st15" d="M553,169.1c-2,2.7-5.1,5.5-5.1,5.5l-9.4,6.8l4.5,7.3c0,0-9.2,13-9.5,17c-0.3,4-11.1,26.6-11.5,28.6
                            c-0.5,1.9,5.4,17.7,5.4,17.7s-36.5,7.7-41.1,8.7c-4.6,0.8,4.5,27.5,4.5,27.5l-44,10.1l0.4,6.2l-20.4,22.2c0,0-15.6,5.1-19.1,6
                            c-4.8-1.9-10.5-1.7-14.1-6.2c-1.7-2-1.7-3.6-0.7-6c2.3-5.5,8.3-6.2,12.1-9.8c5.3-4.7,6.4-10.8,5-17.4c-1.7-8.2-10.1-14.7-7.4-23.8
                            c2.3-8.1,6.3-15.6,9.6-23.4c0.7-2,1.5-4,2.5-5.9c6-11.5,16-16.8,28.6-16.9c6.3,0,12.6,2.2,18.9,2.8c4.2,0.4,8.5-0.3,12.6-1
                            c4.7-0.8,7.1-4.6,9-8.4c1.5-3.1-0.6-9.2-3.5-11.4c-3.3-2.5-6.7-5.2-10.3-7.3c-4.6-2.6-9.5-4.7-13.4-6.7c-2.4-3.5-4-6.3-6-8.8
                            c-11.7-14.9-15.1-32.6-16.4-50.8c-0.4-6.4,0-12.8,0-19.2c-0.3-25.6-0.1-20.7,18.9-36.7c4.3-3.5,9.4-5.1,14.8-4.8
                            c4.8,0.2,9.3-0.3,13.5-1.5l-2.8,37.7l7.3,19.5l7.3-4.8l13.4,3.8l16.9-0.2c0,0,6.3,4.6,5.1,5.3c-1.1,0.7,7.2,8.7,7.2,8.7
                            s0.7,3.8-0.8,5.7c-1.4,1.9,8.2,9.4,8.2,9.4S554.9,166.3,553,169.1z"></path>
                        <path className="st12" d="M665,615.9c0.8-0.5-35.1,0.7-35.1,0.7l-3.2,7.7l-23.8-48l-13.6,10l-6,6l-6.7-1L575,584l-55.4-0.1v-56
                            c0,0-40.9,1.4-58.4,2.1l0.8-46.7l2.4-8.1l-4.7-6.5l51.7-58.2l2.1-3.8l52.4-7.6l3.7,9.1l40.3-10.7c4.1,23.1,15,107.6,15,107.6
                            l20.6,41.9C645.6,547.1,664,615.9,665,615.9z"></path>
                        <path className="st11" d="M627.6,647.9l-10,1.9l1.9,7.6l-9.8-2.2c0,0,8.1,39.2,2.1,38.6c-5.9-0.5-94.5-1.7-94.5-1.7l1.7,27.2
                            c0,0-24.4,0.5-27.2-1.5c-2.9-1.9-41.9-14.9-45.1-15.3c-2.5-0.2-55.2-0.3-72.8-0.3c0.6-3.2,0.9-5.2,0.9-5.2l5.7-25l57.1-0.8
                            l-0.4-62.1l22.6-0.5l1.4-78.5c17.5-0.7,58.4-2.1,58.4-2.1v56L575,584l1.6,7.3l6.7,1l6-6l13.6-10l23.8,48l10.8,21.8L627.6,647.9z"></path>
                    
                    </svg>
            </div>

            {/* <div className="description_survey" style={{position:'fixed'}}>
                    <h1>enlkn</h1>
            </div> */}

            <svg version="1.1" className="map" id="map_lrg" style={{display: 'block'}} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" >
            <path 
                className="st2 state_level" 
                id="TT-PTF"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-PTF') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-PTF') ? this.callMapData('TT-PTF') : null}
                d="M50,847.2c0.4,0,0.9-0.2,1-0.6c1.2-3.8,3.7-6.7,6.6-9.2c1.4-1.2,2.8-2.3,4.2-3.4c1.8-1.4,3.4-2.9,5-4.5
                c1.3-1.3,2.2-2.9,2.8-4.6c0.2-0.7,0.5-1.4,0.7-2.1c0.5-1.4,1.5-2.3,2.7-3c1.1-0.6,2.3-1,3.6-1.3c2.5-0.6,5-1,7.5-1.3
                c3.7-0.4,7.4-1,10.9-2.5c1-0.4,2.1-0.5,3.1-0.5c3.1-0.1,6.2-0.3,9.2-0.8c6.1-1,12.1-2.5,17.7-5.1c2.1-1,4.2-2.1,6.1-3.5
                c1.5-1,2.9-2.2,4.1-3.5c2.9-3.2,5.4-6.6,7.5-10.3c0.8-1.4,1.5-2.8,1.8-4.4c0.3-1.4,0.4-2.7,0-4.1c-0.1-0.5-0.2-1.1-0.4-1.5
                c-0.7-1.5,0.1-2,1.1-2.5c0.7-0.4,1.5-0.8,2.4-0.8c1.3-0.1,2.5-0.3,3.8-0.4c0.8-0.1,1.7-0.1,2.6-0.1c2.5,0,4.8-0.7,7-1.9
                c1.8-1,3.6-1.8,5.5-2.6c4.8-2.1,9.8-3.5,14.9-4.5c5.7-1,11.4-1.8,17.1-2.5c2.4-0.3,4.9-0.5,7.3-0.7c3.4-0.3,6.8-0.5,10.2-0.8
                c7.5-0.7,15-2,22-5c1-0.4,2-0.8,3-1.1c6.7-2.6,12.1-7,16.5-12.7c0.8-1,1.4-2.2,1.9-3.3c0.7-1.4,1.4-2.9,2.1-4.3
                c0.7-1.2,1.1-2.6,1.4-3.9c0.5-2.1,1.4-4,2.6-5.8c0.2-0.1,0.4-0.2,0.6-0.2c2.8,0,5.5-0.8,8.1-1.6c1.6-0.5,3.2-1.1,4.8-1.7
                c1.6-0.6,3.2-1.2,4.9-1.4c1.4-0.2,2.7-0.7,3.9-1.4c1.7-0.9,3.3-1.9,5-2.8c0.9-0.5,1.8-1.1,2.7-1.7c0.3,0.2,0.6,0.3,0.8,0.5
                c3,3.5,5.9,7,8.9,10.5c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.2,0.9-0.2,1.1c-1,0.3-1.6,0.9-1.7,2.1c0,0.8-1,1.1-1.1,1.9
                c-0.1,0.8-0.5,1.3-1.3,1.6c-0.3,0.1-0.6,0.4-0.6,0.7c-0.3,0.8-0.7,1.5-1.2,2.2c-0.5,0.8-0.4,1.6,0.3,2.4c0.1,0.2,0.3,0.3,0.4,0.5
                c0.1,0.2,0.2,0.5,0.1,0.6c-0.7,0.9-1.2,2.1-2.4,2.4c-0.5,0.2-0.6,0.6-0.6,1c0,0.3,0.1,0.6,0.1,0.9c0.1,1.3-0.1,1.6-1.3,1.5
                c-0.9-0.1-1.7,0.1-2.3,0.8c-0.1,0.2-0.3,0.3-0.5,0.4c-0.3,0.1-0.6,0.1-0.9,0c-0.3-0.1-0.6-0.3-0.8-0.5c-0.6-0.5-1.4-0.6-2.1-0.5
                c-0.4,0.1-0.8,0.3-1.1,0.6c-1.1,1.3-2.2,2.6-2.7,4.3c-0.2,0.5-0.5,1-0.7,1.4c-0.4,1-1.1,1.8-2.1,2.4c-0.7,0.4-1.4,0.9-2.1,1.4
                c-0.7,0.5-1.3,1.1-1.7,1.9c-0.5,1-1.1,1.8-2,2.4c-0.6,0.4-0.9,1.1-1,1.9c-0.6,4.9-1.3,9.7-1.9,14.5c-0.5,3.7-1.1,7.4-1.6,11
                c-0.2,1.5-0.3,3-0.3,4.5c0,1.7,0,3.4,0.1,5.1c0,0.4,0.4,0.8,0.8,0.8c1.4,0,2.8,0,4.1,0c0.5,0,0.9-0.5,1-1c0-1.2,0-2.3,0.1-3.5
                c0-0.5,0.1-1.1,0.1-1.6c0-0.4,0.6-0.8,0.9-0.7c0.8,0.3,1.6,0.5,2.4,0.8c2.5,0.9,5,1.8,7.5,2.8c1,0.4,2,0.4,3.1,0.1
                c1.9-0.6,3.9-1.1,5.8-1.6c0.6-0.2,1.2-0.3,1.9-0.4c0.7-0.1,1.1,0.1,1.1,0.7c0,0.1,0,0.2,0,0.3c0,3.5,0,7,0,10.5
                c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.2-0.4,0.4-0.5,0.4c-1.9,0.3-1.9,0.3-2,2.2c0,1,0,1.9,0,2.9c0,0.4,0.2,0.8,0.3,1.2
                c0.2,0.7,0.2,0.8-0.4,0.8c-1.3-0.1-2,0.5-2.4,1.6c-0.2,0.4-0.3,0.8-0.5,1.2c-0.5,0.8-0.4,1.6,0.2,2.4c0.1,0.2,0.2,0.4,0.2,0.6
                c0,0.2-0.2,0.5-0.3,0.5c-0.7-0.1-1.5-0.3-2.2-0.3c-1-0.1-1.8,1.1-1.5,2c0.1,0.3,0.3,0.6,0.4,0.9c0.4,0.6,0.4,1.1,0.2,1.8
                c-0.2,0.5-0.4,1-0.4,1.5c-0.1,0.7,0.1,1.3,0.6,1.7c0.2,0.1,0.3,0.3,0.5,0.4c1.2,1,0.9,2.4,0,3.4c-0.4,0.4-0.8,0.7-1.2,1
                c-0.6,0.4-1.2,0.9-1.9,1.1c-0.6-0.2-0.8-0.7-1.1-1.1c-1.3-1.8-2.9-3.1-4.8-4.1c-1.7-0.9-3.4-1.8-5.2-2.4c-1.9-0.7-3.9-1.1-5.9-1.1
                c-1.4,0-2.8,0-4.1,0.1c-1.8,0.1-3.4-0.3-4.7-1.5c-0.3-0.3-0.7-0.5-1-0.8c-1.4-1-3-1.6-4.6-2.1c-0.2-0.1-0.4-0.1-0.6-0.1
                c-2.4-0.2-4.9-0.1-7.3,0.1c-1.1,0.6-2.3,0.4-3.5,0.2c-2.4-0.3-4.9-0.4-7.3-0.4c-4.5-0.1-8.9,0.5-13.3,1.4c-0.9,0.2-1.9,0.4-2.8,0.6
                c-3.5,0.9-7,0.8-10.5,0.2c-0.5-0.1-1-0.2-1.6-0.3c-3.2-0.4-6.4-0.2-9.4,1.1c-2.4,1-4.7,2-6.9,3.2c-1.7,0.9-3.3,1.9-5,2.8
                c-0.7,0.4-1.5,0.8-2.3,1.1c-1.6,0.7-3.1,0.6-4.6-0.1c-0.6-0.3-1.2-0.6-1.5-1.1c-1.6-2.1-3.6-3.6-5.7-5.1c-1.2-0.9-2.3-1.8-3.4-2.9
                c-1.9-1.9-4.3-2.8-6.9-3c-5.7-0.4-11.2,0.4-16.6,2.3c-3,1.1-5.9,2.3-8.8,3.8c-2.6,1.4-5.2,2.8-7.8,4.3c-4.3,2.5-8.6,5-13,7.5
                c-0.5,0.3-0.9,0.5-1.4,0.8c-1.3,0.9-2.7,1.2-4.2,1.1c-0.6,0-1.3,0-1.9,0c-1.4-0.1-2.8,0.3-4,0.9c-1.7,0.8-3.4,1.8-4.9,3
                c-2,1.8-4.2,3.3-6.4,4.8c-1.8,1.2-3.5,2.4-5.2,3.7c-3,2.5-6.4,4.2-10,5.4c-2.4,0.8-4.9,1.3-7.4,1.9c-0.6,0.2-1.2,0.4-1.8,0.6
                c-2.3,0-4.7,0-7,0c-0.3-0.2-0.5-0.4-0.8-0.5c-1-0.3-2.1-0.5-3.1-0.8c-2.1-0.6-4.2-1.4-6.2-2.6c-1.8-1.1-3.2-2.4-4-4.4
                c-0.1-0.2-0.1-0.4-0.3-0.6c-0.3-0.3-0.7-0.5-1-0.7C50,848.9,50,848,50,847.2z"/>
            <path 
                className="st2 state_level" 
                id="TT-RCM"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-RCM') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-RCM') ? this.callMapData('TT-RCM') : null}
                d="M843.1,503.8c0.2,0.4,0.6,0.9,0.7,1.4c0.8,3,1.5,6,1.4,9.1c0,0.3,0,0.6,0,1c0,1.3,0.3,2.5,1.2,3.6
                c0.5,0.6,0.8,1.2,1,2c0.4,1.6,1.2,3,1.9,4.4c0.5,1.2,1,2.3,1.4,3.5c0.3,0.9,0.3,1.9-0.3,2.8c-0.1,0.2-0.2,0.4-0.3,0.5
                c-0.5,0.7-0.6,1.4-0.3,2.1c0.5,1.8,1,3.7,1.7,5.5c0.7,1.9,1.6,3.7,2.4,5.6c0.5,1.2,1.1,2.3,2.1,3.2c1.4,1.3,2.1,2.9,2.3,4.8
                c0.1,0.5,0.1,1.1,0.2,1.6c0.1,0.5,0.3,1,0.6,1.5c1.8,2.4,2.9,5.2,4,8c1.6,3.8,3.1,7.7,4.8,11.5c0.9,1.9,1.9,3.8,2.7,5.8
                c0.4,1,1.1,1.8,1.9,2.5c1.5,1.1,2.6,2.5,3.6,4.1c0.6,1,1.4,1.8,2.1,2.8c0.3,0.4,0.5,0.9,0.8,1.4c0.1,0.2-0.2,0.7-0.4,0.7
                c-1.1-0.1-2.1-0.1-3.2-0.2c-0.7,0-1.5-0.1-2.2,0c-0.5,0.1-1,0.4-1.5,0.6c-0.2,0.1-0.3,0.6-0.1,0.8c0.5,0.7,1.2,1.2,2.1,1.4
                c0.4,0.1,0.9,0.1,1.2,0c0.7-0.2,1.4-0.6,2.1-0.9c0.7,0.3,1.4,0.5,2.1,0.8c0.6,0.2,1.3,0.3,1.9,0c1.6-0.8,3.3-0.7,5-0.1
                c0.7,0.3,1.4,0.4,2.2,0.4c0.9,0,1.7-0.4,2-1.3c0.3-0.7,0.5-1.4,0.7-2.1c0.2-0.5,1-1,1.6-0.9c0.5,0.1,1.1,0.2,1.5,0.5
                c1,0.6,2,0.4,3.1,0c1.4-0.5,2.7-1.2,4-1.9c0.4-0.2,0.9-0.1,1.2,0.2c0.4,0.4,0.5,1.1,0.3,1.5c-0.2,0.3-0.4,0.5-0.6,0.8
                c-0.5,0.6-0.9,1.2-1.1,1.9c-0.2,0.7-0.5,1.4-0.8,2.1c-0.6,1.2-0.9,2.4-0.5,3.7c0.2,0.7-0.2,1.2-0.8,1.6c-0.8,0.5-1.5,1.2-2.4,1.4
                c-1.1,0.3-2-0.6-3-0.9c-0.8-0.2-1.5-0.8-2.3-1.2c-0.7-0.3-1.3-0.6-2-0.9c-0.6-0.2-1.4,0.1-1.6,0.7c-1.3,3.4-3.4,6.4-4.9,9.7
                c-0.9,2-2.2,3.9-3.5,5.7c-1.3,1.9-2.1,3.9-2.5,6.1c-0.4,2.1-0.8,4.2-1.3,6.2c-0.3,1.2-0.4,2.3-0.2,3.5c0.3,1.2,0.2,2.3,0,3.5
                c-0.6,3.3-0.8,6.5-0.3,9.8c0.4,2.5,0.2,5.1-0.3,7.6c-0.4,2-0.7,4-0.4,6c0.1,1.2-0.2,2.3-0.6,3.4c-0.3,0.8-0.4,1.7,0,2.5
                c0.8,1.8,0.8,3.7,0.6,5.6c-0.2,1.7-0.4,3.4-0.4,5.1c0,2.7-0.2,5.3-0.8,7.9c-0.6,2.8-0.8,5.7-1.1,8.5c-0.3,2.6-0.6,5.3-1.2,7.9
                c-0.2,0.9-0.3,1.9-0.3,2.9c0,1.6,0.1,3.2,0.2,4.8c0.3,2.1,0.4,4.2,0.4,6.4c0,1.7,0.2,3.4,0.6,5.1c0.4,1.6,0.8,3.3,0.8,5
                c0.1,1.5,0.5,2.9,0.8,4.4c0.7,3.8,1.2,7.6,1.8,11.3c0.6,4.1,0.9,8.3,1.8,12.3c0.4,1.6,0.8,3.1,1.3,4.6c0.5,1.7,1.1,3.4,1.7,5.1
                c0.5,1.4,0.9,2.8,0.8,4.4c-0.1,1.2,0.6,2,1.5,2.6c0.9,0.7,2,0.7,2.9,1.3c0.2,0.1,0.3,0.7,0.1,0.9c-0.1,0.1-0.1,0.2-0.2,0.2
                c-1.4,1-2.9,1.8-4.4,2.5c-0.5,0.2-1.1,0.1-1.5-0.3c-0.8-0.8-1.7-1.2-2.9-1.1c-0.7,0-1-0.3-1.1-0.9c-0.1-0.7-0.3-1.5-0.4-2.2
                c-0.1-0.5-0.2-1.1-0.1-1.6c0.1-0.8,0.1-1.5-0.3-2.1c-0.6-0.9-0.7-1.8-0.6-2.7c0-0.5,0-1.1,0-1.6c-0.2-1.5-1-2.6-2.4-3.1
                c-0.4-0.1-0.8-0.3-1.2-0.3c-3.3-0.3-6.6-0.6-9.9-0.2c-1.6,0.2-3.1,0.7-4.4,1.7c-0.2,0.1-0.4,0.2-0.5,0.4c-2,1.8-4.2,3.2-6.4,4.8
                c-1.1,0.8-2.3,1.6-3.6,2.1c-1.2,0.5-2.3,1.2-3,2.3c-0.9,1.4-2.3,2.2-3.7,2.9c-0.8,0.4-1.6,0.7-2.1,1.4c-0.8,1-1.9,1.6-3.2,2
                c-0.8,0.3-1.5,0.8-2,1.5c-0.8,1.5-2.3,2.1-3.6,2.8c-2-0.6-3.5-1.8-5-3c-1.1-0.8-1.8-1.7-1.9-3.1c-0.1-1.2-0.9-2.1-1.9-2.8
                c-0.3-0.2-0.7-0.5-1.1-0.7c-1.1-0.5-1.9-1.4-2.3-2.5c-0.2-0.5-0.4-1-0.6-1.5c-0.7-1.5-1.6-2.7-2.8-3.9c-2-2-3.9-4-5.8-5.9
                c-1-1-1.9-2-2.9-2.9c-1.3-1.1-2.4-2.4-3.5-3.7c-1.1-1.4-2.3-2.8-3.4-4.2c-0.8-1-1.5-2.1-1.6-3.4c-0.1-1-0.5-1.8-1.2-2.5
                c-0.2-0.1-0.3-0.3-0.4-0.5c-0.4-0.6-0.6-1.2-0.3-1.8c0.8-1.5,0-2.7-0.7-3.9c-0.4-0.7-1.2-1-1.9-0.7c-0.5,0.2-1,0.4-1.5,0.5
                c-0.7,0.1-1.5-0.6-1.5-1.3c0-0.1,0-0.2,0-0.3c0.5-1.3,0-2.3-1.2-2.9c-0.4-0.2-0.8-0.5-0.8-0.9c-0.2-1.1-0.8-1.9-1.5-2.8
                c-0.4-0.5-0.7-1.1-1-1.6c-0.4-0.6-1-1.1-1.8-1.1c-0.3,0-0.7,0-0.9-0.2c-0.4-0.2-0.7-0.5-0.9-0.8c-0.2-0.3-0.3-0.8-0.4-1.2
                c-0.2-0.9-0.4-1.9-0.6-2.8c-0.4-1.6-1.2-2.9-2.8-3.6c-0.8-0.3-1.4-0.9-2.3-1.4c-0.1-0.7-0.2-1.4-0.2-2.1c0-1,0.1-1.9,0.1-2.9
                c0-0.9-0.3-1.7-0.9-2.3c-0.1-0.2-0.3-0.3-0.4-0.5c-1.5-1.1-2.5-2.6-3.2-4.3c-0.4-1-1.1-1.8-1.7-2.7c-0.4-0.6-1-1.2-1.7-1.4
                c-2-0.7-3-2.3-3.9-4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.6-1.9-1.9-3.3-3.4-4.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.7-0.5-1.2-1.2-1.5-2
                c-0.5-1.1-1-2.1-1.5-3.2c-0.8-1.6-1.6-3.2-2.8-4.6c-0.2-0.2-0.4-0.5-0.5-0.8c-1.1-2.3-2.9-4.2-4.8-5.9c-0.7-0.6-1.3-1.4-1.8-2.3
                c-1-2.1-2.4-3.8-4-5.3c-1-0.9-1.6-2-2-3.2c-0.3-0.9-0.8-1.8-1-2.7c-0.4-1.4-1.1-2.5-2.2-3.4c0.1-0.7,0.1-1.3,0.2-2
                c0.1-1.4-0.2-2.7-0.8-4c-0.6-1.2-1.4-2.1-2.7-2.6c-0.4-0.2-0.8-0.3-1.2-0.4c-0.4-0.2-0.7-0.5-0.8-0.9c-0.2-0.6-0.4-1.2-0.6-1.8
                c-0.3-0.7-1.4-1.2-2.1-1.1c-0.5,0.1-1,0.3-1.5,0.5c-0.2,0.1-0.5,0.5-0.5,0.7c-0.1,0.7-0.1,1.5,0,2.2c0,1.2-0.3,2.1-1.5,2.5
                c-0.1,0-0.2,0.1-0.3,0.1c-0.8,0.4-1.4,1-1.7,1.8c-0.2,0.4-0.3,0.8-0.5,1.2c-0.3,0.7-0.9,1.2-1.7,1.3c-0.5,0.1-1.1,0.1-1.6,0.1
                c-0.7,0.1-1.2,0.6-1.3,1.2c-0.2,0.8-0.2,1.7-0.3,2.5c-0.1,0.5-0.4,0.8-0.8,1c-1,0.4-3,1.8-3.8,2.6c-0.6,0.6-2.3,0.6-2.9-0.1
                c-0.2-0.2-0.4-0.5-0.6-0.8c-0.4-0.6-0.8-1.2-1.5-1.6c-0.6-0.3-0.9-0.8-1-1.5c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.6-0.6-1.1-1.1-1.5
                c-0.6-0.4-1.3-0.7-1.9-1.2c-0.7-0.5-1.5-0.8-2.4-0.7c-0.7,0.1-1.2-0.2-1.8-0.5c-0.6-0.3-1.2-0.7-1.8-0.7c-1.4,0-2.2-0.5-2.8-1.7
                c-0.1-0.2-0.3-0.3-0.5-0.4c-0.7-0.5-1.5-0.5-2.1,0.1c-0.7,0.8-1.5,1.4-2.6,1.8c-1.1,0.4-1.8,1.2-1.8,2.4c0,0.2,0,0.4-0.1,0.6
                c-0.1,0.5-0.7,1-1.1,1c-0.4,0-0.8,0-1.3-0.1c-0.6-0.1-1.3-0.2-1.9-0.2c-1,0-1.6,0.5-1.9,1.3c-0.2,0.7-0.5,1.4-0.7,2.1
                c-0.4,1-1.3,1.3-2.2,1.6c-0.2,0.1-0.5,0.1-0.6,0c-1.4-1.1-3-0.9-4.6-1.1c-0.3,0-0.6-0.2-0.7-0.6c0-0.4,0-0.8,0-1.3
                c0.1-0.8-0.1-1.5-0.5-2.1c-0.2-0.3-0.2-0.6-0.3-0.9c0.3-0.2,0.5-0.5,0.8-0.6c0.4-0.2,0.8-0.2,1.2-0.3c1.5-0.3,2-1,1.6-2.5
                c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.7-0.3-1.4,0-2.1c0.5-1.3-0.4-2.5-1.8-2.7c-0.6-0.1-1.3,0.1-1.9,0.3c-1.3,0.4-2.6,0.8-4,1.1
                c-0.2,0.1-0.5-0.2-0.8-0.4c-0.1,0-0.1-0.2-0.1-0.3c-0.1-0.3-0.1-0.6-0.2-0.9c-0.4-1.2-1-2.1-2.3-2.4c-0.5-0.1-0.7-0.5-0.7-0.9
                c0-0.4,0-0.9-0.1-1.3c-0.1-0.4-0.2-0.9-0.5-1.1c-1.3-0.8-1.9-2.3-3.1-3.1c-0.2-1.3-0.3-2.6,0.2-3.8c0.3-0.7,0.1-1.5-0.2-2.2
                c-0.2-0.5-0.5-1-0.7-1.4c-0.3-0.8,0.1-1.8,0.9-2.1c0.4-0.1,0.8-0.3,1.2-0.3c0.9,0,1.6-0.4,2.1-1.2c0.3-0.5,0.9-0.5,1.4-0.4
                c0.4,0.1,0.8,0.3,1.2,0.5c0.7,0.2,1.5,0.3,2.2,0c0.3-0.1,0.6-0.4,0.7-0.6c0.2-0.7,0.3-1.4,0.4-2.1c0.8-0.4,1.6-0.8,2.3-1.3
                c0.8-0.5,0.8-1.6,0.1-2.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-1.7-0.8-1.8-2.2-1.4-3.8c0-0.2,0.1-0.4,0.2-0.6
                c0.7-1.8,0.3-2.7-1.4-3.7c-0.9-0.5-1.4-1.3-1.4-2.3c0-0.1,0-0.2,0-0.3c0-0.9-0.4-1.7-1.2-2.1c-0.8-0.4-1.3-1.1-1.7-1.8
                c-0.4-0.7-1-1.4-1.5-2c-0.7-0.9-1.7-1.3-2.8-1.1c-1.3,0.2-2.3-0.2-3.3-0.9c-0.4-0.3-0.9-0.6-1.4-0.7c-0.8-0.2-1.6-0.6-2.2-1.2
                c-0.1-0.1-0.2-0.1-0.2-0.2c-0.8-0.6-2.3-2.5-2.7-3.4c-0.2-0.6-0.9-1-1.5-0.9c-0.6,0.1-3.9-0.3-4.5-0.6c-0.4-0.1-0.8-0.3-1.1-0.5
                c-0.3-0.1-0.3-0.6-0.2-0.8c0.3-0.3,0.6-0.6,0.9-0.9c0.4-0.5,0.4-1.2,0-1.8c-0.6-0.8-1.4-1.2-2.2-0.9c-0.9,0.3-1.8,0.7-2.3,1.6
                c-0.3,0.5-0.6,0.8-1.2,1.1c-0.3,0.1-0.7-0.1-0.8-0.3c0-0.3-0.1-0.6-0.1-0.9c0-0.9,0-1.7,0-2.6c0-31.5,0-63,0-94.5
                c0-1.2,0-2.3,0.1-3.5c0-0.4,0.4-0.8,0.9-0.8c0.7,0,1.5-0.1,2.2-0.1c19.7,0,39.4,0,59.1,0c0.6,0,1.3,0.1,1.9,0.1
                c0.3,0,0.4,0.2,0.3,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.5,0.2-1,0.4-1.5,0.6c-0.3,0.1-0.6,0.2-0.9,0.4c-0.9,0.5-1.4,1.9-0.7,2.8
                c0.8,1,1.1,2,0.9,3.3c-0.1,0.7-0.5,1.1-1.3,1.2c-0.1,0-0.2,0-0.3,0c-1.6,0.3-2.2,1-2.3,2.5c0,0.6,0.1,1,0.6,1.4
                c0.3,0.3,0.7,0.5,1,0.8c0.9,0.9,0.8,1.6-0.1,2.4c-0.3,0.3-0.7,0.5-1,0.8c-0.2,0.2-0.2,0.6-0.2,0.9c0,0.3,0.2,0.6,0.4,0.9
                c0.6,0.8,0.8,1.5,0,2.3c-0.7,0.6-0.5,1.6-0.8,2.4c-0.2,0.5-0.8,0.8-1.3,0.8c-0.2,0-0.4-0.1-0.6-0.2c-1.1-0.5-2.1-0.4-2.9,0.4
                c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.3-1.1,0.3-1.4-0.1c-0.2-0.2-0.4-0.5-0.6-0.8c-0.3-0.4-0.8-0.8-1.3-0.9c-0.7-0.2-1.1-0.6-1.3-1.3
                c0-0.1-0.1-0.2-0.2-0.3c-0.4-0.6-1.1-0.9-1.6-0.7c-0.5,0.2-0.8,0.6-0.9,1.2c-0.1,0.3-0.1,0.6-0.1,1c0,4.4,0,8.7,0,13.1
                c0,0.4,0.1,0.8,0.2,1.3c0,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.4,0.1,0.6,0.1c1,0,1.9,0,2.9,0c8.3,0,16.6,0,24.9,0c0.9,0,1.7,0,2.6,0
                c0.7,0,1.3,0.1,1.8,0.6c0.4,0.3,0.9,0.6,1.3,0.9c0.5,0.3,1,0.4,1.5,0.1c0.2-0.1,0.4-0.1,0.6-0.2c1-0.7,1.9-0.8,2.9-0.2
                c0.4,0.2,0.8,0.2,1.2,0c0.3-0.1,0.6-0.3,0.9-0.4c0.3,0,0.7,0.1,0.8,0.3c0.7,1.2,1,2.5,1.2,3.9c0.1,0.5,0,1.1-0.1,1.6
                c0,0.6-0.1,1.3-0.1,1.9c0,1.1,0.4,2.1,1.3,2.8c0.6,0.4,1.2,0.9,1.8,1.4c1,0.7,1.5,1.6,1.6,3c0.1,1.7,1.6,2.8,3.3,2.8
                c0.9,0,1.7-0.3,2-1.2c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.7,0.6-1.1,1.3-1.2c1-0.2,1.4-0.9,1.5-1.8c0-0.5,0-1.1,0-1.6
                c0.1-0.9,0.6-1.5,1.6-1.7c0.4-0.1,0.9-0.1,1.2-0.2c0.4-0.2,0.7-0.4,1.1-0.7c0.5-0.4,0.7-1.2,0.4-1.7c-0.4-0.9-0.8-1.8-1.5-2.4
                c-0.7-0.6-1.2-1.2-1.6-1.9c-0.3-0.6-1-0.8-1.5-1.1c-0.5-0.3-0.6-0.8-0.3-1.1c1.5-1.2,1.2-2.5,0.2-3.9c-0.2-0.2,0.1-0.7,0.3-0.8
                c0.2,0,0.4-0.1,0.6-0.2c0.4,0,0.9,0,1.3,0c0.9,0,1.7-0.2,2.3-0.8c0.5-0.4,1-0.8,1.7-0.9c0.8-0.1,1.4-0.5,1.9-1
                c0.6-0.7,1.4-0.9,2.3-0.9c2.1,0.1,3.4-0.9,4.2-2.7c0.7-1.6,2.1-2.3,3.6-3c0.8-0.4,1.6-0.4,2.4,0.2c0.6,0.5,1.4,0.6,2.1,0.3
                c0.5-0.2,1-0.5,1.2-1c0.6-1.6,1.8-2,3.3-2c0.3,0,0.6-0.1,0.9-0.1c0.4-0.1,0.8-0.3,1-0.7c0.3-0.4,0.6-0.9,1-1.2
                c0.4-0.4,1.4-0.4,1.8,0c0.1,0.1,0.3,0.3,0.4,0.5c0.5,1,1.3,1.2,2.4,0.9c0.7-0.2,1.2,0.1,1.6,0.7c0.1,0.1,0.1,0.2,0.2,0.3
                c0.4,0.6,0.9,1,1.6,0.8c0.9-0.2,1.6-0.6,1.7-1.6c0-0.2,0.1-0.4,0.2-0.7c0.3-0.1,0.6-0.3,0.9-0.3c1,0,1.9-0.3,2.5-1.1
                c0.4-0.5,0.9-0.8,1.6-0.9c0.5-0.1,1-0.3,1.5-0.4c0.9-0.2,1.4-0.9,1.6-1.8c0.1-0.5,0-1.1,0.5-1.5c0.1-0.1,0.2-0.1,0.3-0.1
                c0.1,0,0.3,0.1,0.3,0.1c0.1,0.9,0.3,1.9,0.4,2.8c0.4,0.1,0.6,0.3,0.9,0.3c1.1,0,2.1,0,3.2,0c16.3,0,32.6,0,48.9,0
                C840.6,503.7,841.8,503.6,843.1,503.8z"/>
            <path 
                className="st4 state_level" 
                id="TT-PRT"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-PRT') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-PRT') ? this.callMapData('TT-PRT') : null}
                d="M562.8,710.4c0-0.6,0.1-1.3,0.1-1.9c0-5.1,0-10.2,0-15.3c0-0.2,0-0.4,0-0.6c0.1-1-0.4-1.8-1-2.6
                c-0.6-0.7-1.2-1.1-2.2-1.1c-0.2,0-0.4,0-0.6,0c-0.6,0-1-0.2-1.3-0.8c-0.1-0.3-0.3-0.6-0.5-0.8c-0.4-0.7-1-1-1.7-1.3
                c-0.4-0.2-0.8-0.1-1.2,0.1c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.5,0.5-0.8,0.6c-1.3,0.6-2.7,0.4-4.1,0.3c-1-0.1-1.8-0.7-2.4-1.4
                c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.6-0.5-1.2-0.2-1.8c0.3-0.6,0.7-1.1,0.9-1.7c0.3-0.7,0.3-1.5-0.2-2.1c-0.3-0.4-0.7-0.8-1.1-1.2
                c-0.2-0.2-0.2-0.7,0-0.9c0.1-0.1,0.1-0.2,0.2-0.2c1.2-0.6,1.9-1.6,2-3c0-0.5,0.2-1,0.5-1.5c0.6-1.1,1.2-2.2,1.5-3.5
                c0.2-1,1.1-1.4,2.3-1.4c0.4,0,0.8,0.4,0.9,0.8c0.1,0.7,0,1.6,0.3,2.2c0.5,0.9,1.3,1.6,2.1,2.4c0.2,0.2,0.6,0.3,0.9,0.4
                c0.2,0.1,0.4,0.1,0.6,0c1.3-0.2,2.5-0.4,3.7,0.4c0.2,0.2,0.6,0.2,0.9,0.2c1.3,0.2,2.3-0.3,3-1.4c0.4-0.7,0.8-1.5,1.1-2.3
                c0.3-0.7,0.8-1,1.4-1.2c0.5-0.1,1.1-0.2,1.6-0.3c1.6-0.4,2.2-1.9,1.4-3.2c-0.2-0.3-0.4-0.5-0.6-0.7c-0.5-0.6-0.6-1.4-0.4-2.1
                c0.1-0.4,0.3-0.8,0.6-1.1c0.1-0.2,0.3-0.3,0.5-0.4c1.5-0.6,2.4-1.8,2.8-3.3c0.2-0.8,0.6-1.3,1.3-1.7c1.1-0.7,1.5-1.7,1.5-3
                c0-0.7,0.3-1,1-1c1.8,0.1,3.1-0.7,3.9-2.2c0.5-0.9,1.2-1.2,2.1-1.1c0.1,0,0.2,0,0.3,0c0.7,0,1.2-0.4,1.4-1.1
                c0.2-0.6,0.3-1.2,0.4-1.8c0.2-0.2,0.4-0.4,0.6-0.4c1.2-0.1,2.2-0.7,2.9-1.7c0.5-0.7,1.2-0.8,2-0.6c0.3,0.1,0.6,0.2,0.9,0.3
                c1.3,0.5,2.5,0.4,3.6-0.5c0.2-0.1,0.4-0.2,0.5-0.3c0.7-0.4,1.8-0.2,2.3,0.5c0.4,0.5,0.7,1,1.1,1.5c0.4,0.5,0.9,0.9,1.6,0.9
                c0.3,0,0.6,0,1,0.1c0.6,0.1,1,0.3,1.3,0.8c0.2,0.3,0.3,0.6,0.4,0.8c0.6,1,1.4,1.6,2.6,1.5c0.6,0,1.3,0,1.9,0c0.9,0,1.6,0.4,2.3,0.9
                c0.2,0.1,0.3,0.3,0.4,0.5c1.5,1.6,3.2,1.9,5.2,0.9c0.2-0.1,0.4-0.3,0.6-0.2c0.3,0,0.7,0.1,0.9,0.3c0.4,0.5,0.7,1,1.1,1.5
                c0.9,1.1,2.1,1.5,3.4,1c0.3-0.1,0.6-0.3,0.9-0.4c1.1-0.4,2.2-0.6,3.4-0.3c0.3,0.1,0.6,0.1,0.9,0.1c1,0.1,1.9-0.1,2.6-0.9
                c0.6-0.7,1.4-1.3,2.3-1.7c0.8-0.4,1.3-1.1,1.4-2c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.8-0.7,1-0.5c1.2,0.9,2.6,0.8,4,0.5
                c0.7-0.1,1.3,0.2,1.6,0.8c0.2,0.4,0.4,0.8,0.6,1.1c0.9,1.5,2.5,2,4.1,1.3c0.5-0.2,0.9-0.5,1.4-0.8c0.7,0.4,1.3,0.7,2,1.1
                c0.7,0.4,1.4,0.4,2.2,0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.9-0.4,1.8-0.6,2.8-0.6c1.6,0,2.1-0.5,2.6-2.2c0.1-0.4,0.2-0.8,0.4-1.2
                c0.1-0.2,0.5-0.6,0.7-0.6c2.1,0,4-0.3,5.8-1.4c2.5-0.5,4.9-1.3,7.3-2.1c0.2-0.1,0.4,0,0.6,0c0.6,0,1.3,0.9,1,1.4
                c-0.5,0.9-0.5,1.8,0,2.7c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.4-0.2,0.9-0.6,1c-0.5,0.1-1.1,0.2-1.5,0.4c-0.5,0.2-0.9,0.5-1.3,0.8
                c-0.3,0.2-0.5,0.9-0.3,1.2c0.2,0.3,0.3,0.5,0.5,0.8c0.4,0.4,0.6,0.9,0.6,1.4c0,0.6,0,1.3,0,1.9c0,0.5,0.3,0.8,0.7,0.9
                c0.5,0.2,1,0.3,1.5,0.3c1.1,0,2.1,0.1,3.1,0.7c1.2,0.8,3.9-0.3,4.4-1.6c0.3-0.8,0.6-1.6,0.9-2.4c0.2-0.6,0.9-1,1.5-0.9
                c0.4,0.1,0.8,0.2,1.2,0.3c2.2,0.5,3.2-0.1,3.6-2.3c0-0.2,0-0.4,0.1-0.6c0.2-0.6,0.5-0.9,1-1.1c0.6-0.2,1.2-0.3,1.7-0.7
                c0.5-0.5,0.8-1.4,1.7-1.2c0.7,0.1,0.9,0.8,1.3,1.3c0.4,0.5,1,0.9,1.6,0.9c1.2,0,2.3,0.4,3.3,1c0.3,0.1,0.6,0.3,0.9,0.3
                c1.4-0.1,2.5,0.5,3.5,1.4c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.3,1.1,0.8,1.1,1.5c0,1,0.5,1.6,1.3,2.1c0.8,0.4,1.3,1.1,1.7,1.8
                c0.4,0.8,1.2,1.2,2.1,1.4c1,0.3,1.9,0.1,2.6-0.7c0.2-0.2,0.4-0.5,0.6-0.7c0.5-0.9,1.3-1.3,2.3-1.5c0.3-0.1,0.6-0.1,0.9-0.3
                c0.6-0.3,1-0.8,1.1-1.5c0.1-0.7,0.1-1.5,0.2-2.2c0-0.4,0.5-0.8,0.8-0.9c0.6-0.1,1.3-0.1,1.9-0.3c0.8-0.2,1.3-0.7,1.7-1.3
                c0.2-0.3,0.3-0.6,0.4-0.9c0.3-1.1,1-1.9,2.1-2.3c1.1-0.4,1.6-1.4,1.7-2.5c0.1-0.7,0.1-1.5,0.1-2.2c0-0.6,0.5-1,1-1
                c0.6,0,1.2,0.2,1.3,0.7c0.2,0.5,0.4,1,0.5,1.5c0.2,0.8,0.7,1.3,1.5,1.5c0.3,0.1,0.6,0.1,0.9,0.3c1.2,0.5,2.1,1.4,2.6,2.7
                c0.5,1.3,0.9,2.6,0.4,4c-0.3,0.9-0.1,1.7,0.6,2.3c0.6,0.5,1,1.1,1.2,1.8c0.5,1.4,1.1,2.8,1.6,4.2c0.3,0.8,0.7,1.5,1.3,2.2
                c0.5,0.5,1,1.1,1.5,1.6c1,1.1,2,2.2,2.6,3.6c0.6,1.4,1.6,2.5,2.7,3.5c1.6,1.4,3,3,4,4.9c0.1,0.2,0.2,0.4,0.3,0.5
                c2,2.4,3.3,5.2,4.6,8c0.5,1.1,1.1,2,2.1,2.7c0.4,0.3,0.8,0.7,1.2,1c1.1,0.9,1.9,2,2.4,3.3c0.3,0.8,0.7,1.6,1.1,2.3
                c0.8,1.3,1.7,2.4,3.2,3c0.8,0.3,1.4,0.9,1.9,1.7c0.6,1,1.2,2,1.7,3c0.6,1.3,1.3,2.4,2.5,3.2c0.3,0.2,0.5,0.4,0.7,0.7
                c0.6,0.6,0.9,1.4,0.9,2.3c0,0.6-0.1,1.3-0.2,1.9c0,0.6-0.1,1.3,0,1.9c0.1,0.8,0.5,1.6,1.3,2.1c0.8,0.4,1.6,1,2.5,1.5
                c0.6,0.3,0.9,0.8,1.2,1.4c0.5,1.2,0.7,2.4,1,3.7c0.1,0.4,0.1,0.9,0.3,1.2c0.3,0.8,0.8,1.5,1.8,1.7c0.3,0.1,0.6,0.1,0.9,0.2
                c0.7,0.1,1.1,0.5,1.5,1.1c0.5,0.8,0.9,1.7,1.6,2.4c0.2,0.2,0.4,0.6,0.4,0.8c0.1,1.1,0.8,1.7,1.6,2.2c0.5,0.3,0.6,0.8,0.5,1.4
                c-0.1,0.3-0.1,0.6-0.1,0.9c0.1,1.2,1.5,2.4,2.7,2.1c0.3-0.1,0.6-0.2,0.9-0.3c1.3-0.5,1.9-0.1,2.2,1.5c0.1,0.5,0.1,1.1-0.1,1.6
                c-0.3,1.2-0.1,2.3,0.9,3.2c0.7,0.7,1.1,1.5,1.1,2.5c0,1,0.5,1.9,1.1,2.6c2.7,3.2,5,6.6,8.2,9.3c1,0.9,1.9,2,2.9,3
                c1.6,1.7,3.2,3.4,4.9,5c1.4,1.4,2.6,2.8,3.2,4.7c0.1,0.4,0.3,0.8,0.5,1.2c0.5,1,1.1,1.8,2.1,2.3c0.4,0.2,0.8,0.4,1.1,0.6
                c0.8,0.5,1.5,1.2,1.7,2.2c0.5,2.8,0.7,2.5,2.7,4.1c1.1,0.9,2.1,1.7,3.4,2.4c0.3,0.1,0.5,0.4,0.8,0.7c-0.7,1.4-1.4,2.8-2.2,4.2
                c-0.6,1.1-1.2,2.2-1.2,3.6c0,0.7-0.3,1.2-0.7,1.8c-0.5,0.7-0.9,1.4-1.2,2.2c-0.2,0.5-0.7,0.8-1.2,1c-0.3,0.1-0.6,0.2-0.9,0.2
                c-1.3-0.1-2.5,0.4-3.6,1.2c-0.7,0.5-1.5,0.8-2.4,0.7c-1.5-0.2-2.9,0.1-4.2,1c-0.4,0.3-1,0.4-1.5,0.4c-1.8-0.1-3.6,0.1-5.4,0.5
                c-0.9,0.2-1.9,0-2.8-0.2c-1-0.2-1.9,0.2-2.5,1c-0.2,0.2-0.4,0.5-0.5,0.8c-0.7,1.1-1.6,1.3-2.8,1c-0.3-0.1-0.6-0.2-0.9-0.3
                c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0.3-2.7,0.8-3.6,1.9c-0.7,1-1.5,1.7-2.7,2.1c-0.8,0.2-1.3,0.8-1.7,1.4c-0.3,0.4-0.6,0.9-1,1.2
                c-0.8,0.9-1.8,1.5-3.1,1.5c-1,0-1.5,0.7-2,1.4c-0.4,0.5-0.9,0.5-1.4,0.3c-0.3-0.1-0.5-0.4-0.8-0.4c-0.4-0.1-0.9-0.2-1.2,0
                c-1,0.5-1.9,0.8-3,0.7c-0.8-0.1-1.4,0.4-1.9,1c-0.2,0.3-0.4,0.8-0.6,1.1c-0.3,0.5-0.6,0.8-1.2,1c-1.2,0.4-2.1,1.1-2.6,2.2
                c-0.4,0.8-1.1,1.3-1.9,1.5c-1.8,0.5-3.4,1.1-5,2.1c-0.7,0.5-1.6,0.6-2.4,0.7c-1.2,0.2-2.2,0.6-3,1.7c-0.5,0.7-1.2,1-1.9,0.9
                c-2.4,0-4.5,1.4-6.8,1.8c-1.7,1.2-3.5,2-5.5,2.5c-0.2,0.1-0.4,0.2-0.5,0.4c-0.7,1.2-1.9,1.6-3.1,2.1c-0.5,0.2-0.9,0.6-1.3,0.9
                c-0.4,0.2-0.8,0.3-1.2,0c-0.5-0.4-1-0.8-1.5-1.2c-0.8-0.6-1.8-0.7-2.6,0c-0.7,0.6-1.4,0.9-2.3,0.8c-0.7-0.1-1.5,0-2.2,0.1
                c-2.1,0.2-4,0.1-5.9-1c-0.5-0.3-1-0.4-1.5-0.6c-0.4-0.1-0.9-0.1-1.2,0.2c-0.8,0.7-1.7,0.8-2.7,0.7c-0.6,0-1.3,0-1.9,0
                c-1.1,0-1.8-0.5-2.1-1.6c0-0.1-0.1-0.2-0.1-0.3c-0.2-1.4-1.9-1.9-3-1.8c-1.3,0.1-2.5,0.6-3.6,1.2c-0.6,0.4-1.2,0.8-1.9,1.2
                c-0.9,0.6-1.8,1.1-2.8,1.4c-0.8,0.3-1.5,0.7-2.3,1.1c-0.6,0.3-1.1,0.6-1.8,0.7c-1.3,0.1-2.5,0.6-3.5,1.4c-0.5,0.4-1.1,0.6-1.8,0.5
                c-0.9,0-1.7-0.1-2.5-0.1c-0.8,0-1.5-0.2-2.1-0.6c-0.7-0.5-1.5-0.7-2.4-0.5c-0.4,0.1-0.8-0.2-1.1-0.6c-0.4-0.5-0.7-1.1-1.1-1.6
                c-0.6-0.7-1.5-0.8-2.3-0.6c-0.3,0.1-0.6,0.3-0.8,0.4c-0.5,0.3-1,0.4-1.5,0.1c-1.3-0.6-2.6-1-4-1c-0.3,0-0.7,0-0.9-0.2
                c-1.3-0.8-2.8-0.8-4.2-1.4c-0.9-0.4-1.8-0.7-2.8-0.6c-0.8,0.1-1.5-0.2-2.1-0.6c-0.8-0.5-1.6-1-2.5-1.4c-0.9-0.5-1.6-1-2.2-1.8
                c-0.6-0.8-1.3-1.4-2.4-1.3c-1.3,0-2.5,0.4-3.4,1.5c-0.3,0.3-0.5,0.7-0.9,0.9c-0.4,0.3-0.9,0.6-1.4,0.7c-1.5,0.2-3,0.3-4.4,0.4
                c-0.7,0-1.3-0.3-1.6-0.8c-0.4-0.6-0.8-1.3-1.2-1.9c-0.4-0.5-1-0.9-1.6-0.8c-0.7,0-1.5,0-2.2,0.2c-1.2,0.3-2.3,0.2-3.5,0
                c-1.3-0.3-2.5-0.7-3.6-1.2c-1.2-0.5-2.5-0.7-3.7-0.5c-1.2,0.2-2.3,0.3-3.5,0.5c-1.6,0.3-3.1,0.6-4.7,0.4c-2.2-0.3-4.2,0.2-6.2,1.1
                c-1.3,0.6-2.5,1.2-3.8,1.7c-1,0.4-2,0.8-3,1.1c-1.5,0.4-3.1,0.7-4.5,1.5c-0.7,0.4-1.4,0.3-2.2,0c-0.4-0.2-0.8-0.3-1.2-0.5
                c-0.8-0.5-1.6-0.3-2.4,0.1c-0.9,0.4-1.5,1.3-2.5,1.2c-0.9,0-1.9-0.2-2.8-0.4c-0.9-0.1-1.7,0.1-2.4,0.6c-0.7,0.6-1.5,1.3-2,2.1
                c-1.4,2.2-3.4,3.1-6,2.9c-1.1-0.1-2.1-0.1-3.2-0.3c-2.5-0.5-5.1-0.4-7.6,0.1c-0.7,0.2-1.4,0.4-2.2,0.6c-1.5,0.3-2.9,0.7-4.4,0.7
                c-1.3,0-2.5,0.3-3.8,0.5c-2.1,0.4-4.2,0.5-6.3-0.4c-0.8-0.3-1.6-0.5-2.5-0.4c-1.3,0.1-2.6-0.1-3.8-0.5c-1.7-0.6-3.3-0.3-4.9,0.6
                c-0.6,0.4-1.3,0.8-1.9,1.2c-0.6,0.3-1.1,0.6-1.7,0.9c-0.5,0.3-1.2-0.1-1.2-0.6c0-0.8,0-1.7,0-2.6c0-32.5,0-64.9,0-97.4
                c0-0.8,0-1.7,0.1-2.5c0-0.5,0.5-1,1-1c2.7,0,5.3,0,8,0c0.4,0,0.9,0.1,1.2,0.4c0.6,0.5,1.3,0.6,2.1,0.6c1.5,0,3,0,4.5,0
                c0.7,0,1.2,0.3,1.5,1c0.2,0.6,0.6,0.8,1.2,0.8c0.5,0,1.1,0,1.6,0c0.8,0,1.2-0.3,1.2-1.1c0-0.8,0-1.7,0-2.6c0-3.4,0-6.8,0-10.2
                c0-0.7,0-1.5,0.1-2.2c0-0.4,0.4-0.8,0.8-0.9c0.2,0,0.4-0.1,0.6-0.1c2.7,0,2.6-0.2,2.7-2.7c0-0.8,0.3-1.4,0.9-2
                C562,711.4,562.4,710.9,562.8,710.4z"/>
            <path 
                className="st4 state_level" 
                id="TT-SGE" 
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-SGE') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-SGE') ? this.callMapData('TT-SGE') : null}
                d="M726.7,181.8c-0.4-0.1-0.8-0.2-1.2-0.3c-1.1-0.3-2.1,0-2.8,0.9c-0.2,0.3-0.4,0.6-0.6,0.7
                c-0.2,0.2-0.5,0.3-0.8,0.3c-0.4,0.1-0.6-0.3-0.6-0.6c-0.1-0.9-0.4-1.9-0.3-2.8c0.2-1.8,0.3-3.6,0.3-5.4c0-0.8,0.4-1.4,0.7-2.1
                c0.3-0.5,0.7-0.8,1.3-0.8c1.9-0.1,3.8-0.2,5.7-0.1c2,0.1,3.8-0.1,5.5-1.2c0.4-0.2,0.8-0.4,1.2-0.5c0.6-0.3,1.1-0.7,1.3-1.3
                c0.4-1.4,1.3-2.1,2.6-2.6c0.5-0.2,1-0.4,1.4-0.8c1.3-1.3,3.1-1.8,4.5-3c0.1-0.1,0.5-0.1,0.6,0c0.3,0.3,0.6,0.6,0.8,1
                c0.4,0.5,0.8,0.6,1.4,0.6c1.4-0.1,2.7-0.5,3.5-1.9c0.1-0.2,0.3-0.3,0.5-0.4c0.4-0.3,1.2-0.3,1.4,0.1c0.1,0.1,0.1,0.2,0.2,0.3
                c0.2,0.6,0.5,1.2,1.3,1.3c0.6,0.1,1.1-0.1,1.4-0.5c0.7-0.8,1.7-0.9,2.6-0.8c0.5,0,1,0.2,1.6,0.3c0.4,0.1,0.8,0.2,1.2,0.4
                c3.5,1.5,7.1,1.3,10.7,0.6c0.5-0.1,1-0.4,1.5-0.6c0.7-0.3,1.3-0.6,2-0.9c1-0.4,2-0.6,3.1,0c0.2,0.1,0.4,0.2,0.6,0.2
                c0.6,0.1,1.3,0,1.8-0.4c0.6-0.5,1.1-1,1.7-1.4c0.9-0.6,1.9-0.9,3-0.4c0.2,0.1,0.4,0.2,0.6,0.2c1,0.2,1.9,0.2,2.7-0.6
                c0.8-0.8,1.8-1,2.9-0.7c0.2,0.1,0.4,0.1,0.6,0.1c1.4,0.2,2.5,0,3.4-1.2c0.2-0.3,0.5-0.7,0.8-0.9c0.2-0.2,0.6-0.3,0.9-0.3
                c0.4,0,0.8,0,1.3,0.1c0.8,0.1,1.5,0,2.2-0.4c0.4-0.2,0.8-0.4,1.2-0.5c0.6-0.3,1.2-0.3,1.9-0.2c0.5,0.1,1,0.3,1.6,0.4
                c1.1,0.2,2.1-0.1,2.7-1.2c0.2-0.5,0.4-1,1-1.2c1,0.3,2,0.5,3,0.8c0.8,0.2,1.5,0,2.1-0.5c0.4-0.3,0.8-0.7,1.3-1
                c0.7-0.5,1.5-0.4,2.1,0c0.3,0.2,0.5,0.4,0.7,0.6c1.8,1.4,3.7,2.5,5.9,2.9c1.1,0.2,2.3,0.5,3.4,0.7c0.2,0,0.4-0.1,0.6-0.3
                c0.2-0.1,0.1-0.6-0.2-0.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.3-0.8-0.7-0.7-1.3c0.1-0.7,0.3-1.3,0.9-1.6c0.7-0.4,1.5-0.8,2.2-1.2
                c0.9-0.7,1.4-1.6,1.2-2.8c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-1.3,0.3-2.1,1.5-2.9c0.8-0.5,1.7-0.4,2.3,0.3c0.4,0.4,0.7,0.8,1.1,1.2
                c0.9,0.8,2.1,0.9,2.9,0c1-1.1,2.2-1.6,3.6-1.9c1.1-0.2,2.3-0.4,3.5-0.6c1-0.2,2.1-0.4,3.1-0.5c0.9-0.1,1.9-0.3,2.8-0.3
                c1.1,0,2.1,0,3.1-0.4c0.8-0.3,1.7-0.2,2.5,0.1c0.7,0.2,1.4,0.6,2.1,0.8c0.5,0.2,1,0.2,1.5,0c0.6-0.3,1.1-0.6,1.7-0.8
                c1.4-0.5,3.1,0.3,3.7,1.6c0.3,0.6,0.4,1.2,0.7,1.8c0.4,1,1.9,1.4,2.7,1c0.2-0.1,0.4-0.2,0.6-0.3c1.1-0.7,1.7-0.5,1.9,0.9
                c0.2,1,0.8,1.5,1.7,1.6c0.5,0.1,1.1,0,1.6,0.2c1.2,0.6,2.5,0.4,3.8,0.3c1,0,1.9-0.2,2.7-0.8c0.4-0.3,0.9-0.6,1.4-0.8
                c0.7-0.3,1.4-0.5,2.2-0.3c1.6,0.4,3.1,0.5,4.7,0.2c0.9-0.2,1.7,0.1,2.4,0.7c1.5,1.2,3.1,1.1,4.8,0.4c0.3-0.1,0.6-0.3,0.8-0.5
                c0.9-0.5,1.9-0.4,2.7,0.1c0.5,0.2,0.9,0.6,1.3,0.9c0.6,0.3,1.1,0.6,1.7,0.8c1.3,0.4,2.5,0.4,3.6-0.6c0.5-0.5,1.1-0.5,1.7-0.1
                c0.8,0.6,1.7,0.8,2.7,0.6c0.6-0.2,1.3,0,1.9,0.1c0.6,0.1,1.3,0.2,1.9,0.2c0.7,0,1.4-0.4,1.6-0.9c0.5-1.3,1.2-2.5,1.8-3.7
                c0.2-0.5,0.3-1,0.5-1.4c0.4-0.2,0.6-0.4,0.9-0.4c1.3-0.3,2.4-0.8,2.9-2.2c0.3-0.7,0.9-0.8,1.6-0.8c0.4,0,0.9,0.1,1.1,0.5
                c0.4,0.7,0.8,1.2,1.5,1.6c0.7,0.4,0.9,1.2,1.3,1.8c0.8,1.2,1.9,1.8,3.3,1.6c1.9-0.3,3.3,0.3,4.6,1.5c0.5,0.5,1.1,0.7,1.7,0.7
                c0.5,0,1.1,0,1.6,0.2c1.5,0.7,2.8,0.5,4.3,0c0.8-0.3,1.7-0.3,2.5-0.4c0.6-0.1,1.3,0.1,1.8,0.5c0.7,0.4,0.8,1.4,0.3,1.9
                c-0.8,0.9-1.8,1.4-2.8,2c-0.7,0.4-1.1,1-1.1,1.8c0,0.8-0.4,1.4-0.9,2c-0.8,1-0.7,2,0,2.9c0.7,0.9,1.3,1.9,1.9,2.9
                c0.4,0.7,1.2,1.2,1.8,1.8c0.3,0.3,0.3,0.8,0,1.1c-0.4,0.4-0.9,0.9-1.5,1.4c-0.4-0.1-0.9-0.2-1.4-0.3c-0.6-0.1-1.1,0.1-1.4,0.5
                c-0.7,1.1-1.8,1.9-2.5,2.9c-0.8,1.1-1.8,1.8-3.2,1.8c-0.8,0-1.3,0.6-1.4,1.5c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.5-0.5,0.9-1,1.2
                c-1.2,0.6-2.4,0.9-3.7,0.6c-1-0.2-1.9-0.1-2.8,0.3c-0.5,0.2-1,0.3-1.5,0.4c-1.6,0.1-2.4,1.2-3,2.5c-0.2,0.4-0.2,0.8-0.3,1.2
                c-0.1,0.5-0.2,1.1-0.5,1.5c-0.8,1.4-1.2,2.9-1.6,4.5c-0.2,0.7-0.5,1.4-0.9,2c-0.6,1-1.2,2-1.8,3c-0.4,0.6-0.4,1.2,0,1.8
                c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.4,0,0.9-0.3,1.2c-0.5,0.4-1,0.8-1.5,1.2c-0.5,0.5-0.7,1.5-0.4,2.1c0.3,0.6,0.7,1.1,1,1.6
                c0.6,1.1,0.6,2.3,0,3.4c-0.4,0.6-0.8,1.2-1.3,1.8c-0.7,0.8-0.8,1.7-0.7,2.7c0.1,0.6,0.4,1.2,0.5,1.9c0.2,0.8,0.1,1.5-0.4,2.1
                c-0.5,0.6-0.9,1.2-1.4,1.7c-0.4,0.4-0.8,0.7-1.3,0.9c-0.7,0.3-1.3,0.7-1.7,1.3c-0.5,0.8-1.1,1.3-2,1.5c-0.9,0.2-1.5,0.7-1.9,1.6
                c-0.4,0.9-1,1.3-2,1.3c-1,0-1.8,0.5-2.7,1c-0.8,0.4-1.4,1-1.5,2c-0.1,1.1-0.8,1.7-1.8,2c-0.3,0.1-0.6,0.2-0.9,0.3
                c-0.6,0.3-1,0.8-1.1,1.5c0,1.4-0.4,2.7-1.1,4c-0.4,0.8-0.9,1.4-1.9,1.3c-0.4,0-0.8,0-1.3,0.1c-0.4,0.1-0.8,0.4-1,0.7
                c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,1.2-0.6,2-2,1.9c-0.2,0-0.4,0-0.6,0.1c-0.8,0.3-1.5,0.7-2.1,1.4c-0.5,0.6-0.8,1.3-0.5,2.1
                c0.1,0.2,0.1,0.4,0.2,0.6c0.3,1.2,0.2,1.5-0.8,2.1c-1.5,0.8-2.1,2.1-2.5,3.5c-0.2,1-0.4,2.1-0.7,3.1c-0.3,1-0.2,2.1,0.1,3.2
                c0.1,0.5,0.3,1,0.4,1.5c0.1,0.5-0.3,1.1-0.8,1.2c-0.6,0.2-1.3-0.3-1.4-0.9c0-0.2,0-0.4,0-0.6c-0.1-1.3-1.4-2-2.5-1.3
                c-0.3,0.2-0.5,0.3-0.8,0.6c-1.3,1.2-2.2,2.6-3.1,4c-0.4,0.6-0.6,1.4-0.7,2.1c-0.1,1.3-0.3,2.5-1.1,3.6c-0.5,0.6-0.1,1.5-0.3,2.2
                c-0.1,0.4-0.3,0.8-0.7,1c-0.9,0.5-1.9,0.9-2.7,1.6c-0.2,0.2-0.6,0.2-0.9,0.1c-0.6-0.1-1.2-0.3-1.9-0.4c-0.6-0.1-1.3-0.1-1.9-0.1
                c-0.7,0-1.2,0.3-1.7,0.8c-0.2,0.3-0.4,0.5-0.6,0.8c-0.3,0.3-1.1,0.4-1.5,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.9-1.1-2-1.8-3.5-1.6
                c-0.3,0-0.6-0.2-0.9-0.4c-0.5-0.3-1-0.8-1.6-1.1c-0.4-0.2-0.8-0.2-1.2-0.1c-0.1,0-0.2,0.1-0.3,0.1c-1.6,1.4-3.5,1.9-5.5,2.5
                c-0.3,0.1-0.6,0.2-0.9,0.2c-1.3,0.4-2.3,1.2-2.8,2.4c-0.9,2.3-1.8,4.6-2.1,7c-0.1,0.6-0.2,1.3-0.4,1.9c-0.2,0.6-0.7,1.1-1.3,1.3
                c-0.2,0.1-0.4,0.1-0.6,0.1c-1.8,0.1-2.6,1.3-3.1,2.8c-0.2,0.7-0.3,1.5-0.3,2.2c0,1.6,0.1,3.2,0.2,4.8c0.2,1.9,0.3,3.8,0.3,5.7
                c0,3.8,0.5,7.6,0.9,11.5c0.3,2.4,0.6,4.9,0.9,7.3c0.2,1.6,0.6,3.1,1.2,4.6c0.4,0.9,0.6,1.8,0.7,2.8c0.2,4.1,1.3,8,2.4,11.9
                c0.8,2.8,1.7,5.5,2.5,8.2c0.1,0.3,0.2,0.6,0.2,0.9c0.1,0.5-0.3,1.1-0.8,1.2c-0.7,0.1-1.5,0.1-2.2,0.1c-1.1,0-2.1-0.1-3.2-0.1
                c-0.4,0-0.9,0-1.3,0.1c-0.6,0.1-1,0.3-1.3,0.8c-0.3,0.4-0.6,0.9-0.9,1.3c-0.2,0.3-0.6,0.4-0.8,0.2c-0.4-0.3-0.9-0.6-1.3-0.9
                c-0.5-0.3-1.4-0.1-1.8,0.3c-0.4,0.4-0.6,0.9-1,1.2c-0.6,0.6-1.4,0.9-2.3,0.8c-0.2,0-0.4-0.2-0.5-0.3c0-0.2,0-0.5,0.2-0.6
                c0.6-0.4,1.2-0.8,1.8-1.2c0.1-0.1,0.2-0.4,0.1-0.6c-0.3-0.8-0.6-1.6-1.6-1.8c-0.9-0.1-1.4,0.4-1.9,0.9c-0.2,0.2-0.4,0.2-0.5,0
                c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.9c0-0.2,0.1-0.4,0.1-0.6c-0.1-0.7-0.4-1.2-1.1-1.4c-0.6-0.2-1.3-0.2-1.8,0.3
                c-0.3,0.4-0.6,0.8-1,1.2c-0.5-0.4-1-0.9-1.5-1.2c-0.8-0.6-2-0.4-2.6,0.4c-0.4,0.6-0.8,1.2-1.1,1.9c-0.4,0.8-1,1.4-1.7,1.8
                c-0.5,0.2-1,0.4-1.4,0.7c-0.9,0.5-1,1.8-0.2,2.5c0.4,0.3,0.9,0.6,1.3,0.9c0.1,0.1,0.3,0.4,0.2,0.6c-0.1,0.3-0.3,0.6-0.6,0.7
                c-0.5,0.1-1.1,0.1-1.6,0.1c-0.6,0-1.3-0.2-1.9-0.2c-0.8,0-1.4,0.3-2,0.9c-0.3,0.3-0.4,0.9-0.2,1.2c0.4,0.5,0.8,1,1.2,1.5
                c0.6,0.6,0.6,1.3,0.5,2.1c-0.1,0.3-0.4,0.6-0.7,0.6c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.4-0.8-1-1.3-1.4c-0.5-0.4-1.4-0.1-1.6,0.5
                c-0.2,0.4-0.3,0.8-0.4,1.2c-0.1,0.5-0.1,1.1-0.3,1.6c-0.2,0.7-1.2,1.2-1.8,0.8c-0.4-0.2-0.6-0.6-1-0.8c-0.5-0.3-1-0.3-1.5-0.2
                c-0.7,0.2-1.2,0.5-1.3,1.2c-0.1,0.4-0.1,0.8-0.2,1.3c-0.1,0.4-0.6,0.7-1,0.5c-0.3-0.1-0.6-0.2-0.9-0.4c-0.5-0.4-1-0.8-1.5-1.1
                c-0.5-0.4-1.6-0.1-1.9,0.6c-0.1,0.3-0.2,0.6-0.1,0.9c0.2,0.6,0.6,1.1,0.8,1.7c0.2,0.4,0,0.8-0.3,1.2c-0.3,0.4-1.1,0.5-1.4,0.1
                c-0.3-0.3-0.5-0.7-0.8-1c-0.3-0.3-0.5-0.7-0.8-1c-0.5-0.5-1-0.8-1.7-0.8c-0.6,0-1,0.2-1.4,0.7c-0.3,0.4-0.2,0.8,0,1.2
                c0.3,0.6,0.7,1.1,1,1.6c0.1,0.2,0.2,0.4,0.1,0.6c0,0.2-0.2,0.5-0.3,0.5c-0.3,0.1-0.6,0-0.9-0.1c-0.6-0.2-1.3-0.3-1.8,0.2
                c-0.6,0.5-1.3,0.7-2,0.8c-2,0.3-3.2,1.6-3.5,3.6c-0.1,0.9-0.5,1.6-1.5,1.7c-0.3,0-0.6,0.2-0.9,0.3c-0.4,0.2-0.7,0.5-0.7,1
                c-0.1,0.6-0.1,1.3-0.3,1.9c-0.1,0.7-1.1,1.2-1.8,1c-1.1-0.4-2.3-0.7-3.3-1.2c-1.3-0.8-2.5-1.1-3.9-0.7c-0.3,0.1-0.6,0-0.9-0.1
                c-2.3-0.5-4.4-0.2-6.5,1c-0.4,0.2-0.8,0.3-1.2,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1-0.1-0.3,0-0.3c2.1-1.2,4.4-2.2,6.9-2.1
                c0.5,0,1.1,0,1.6-0.1c0.2-0.1,0.6-0.4,0.6-0.6c0.1-1.7,0-3.4,0-5.1c0-0.3-0.5-0.8-0.8-0.9c-0.5-0.1-1.1-0.3-1.6-0.2
                c-1,0.2-2.1,0.4-3.1,0.7c-0.7,0.2-1.3,0.7-1.8,1.3c-0.4,0.6-0.9,1.2-1.3,1.8c-1.3,1.9-3.1,2.9-5.4,2.9c-2.7,0-5.3,0-8,0
                c-1,0-1.9-0.3-2.7-0.9c-1.1-0.8-2.1-1.7-3.2-2.7c-0.6-0.6-1.2-1.2-1.8-1.8c-0.5-0.4-1-0.8-1.7-0.8c-1.1,0-2.1-0.4-3-1
                c-0.9-0.6-1.9-0.8-3-0.6c-3.5,0.6-6.9,1-10.4,1.6c-1.3,0.2-2.5,0.5-3.7,0.8c-2.2,0.6-4.4,0.9-6.6,0.9c-2.9,0-5.7,0.1-8.6,0.6
                c-1.2,0.2-2.5,0.1-3.8,0.1c-0.7,0-1.3-0.1-1.8-0.5c-1.2-0.7-2.6-0.9-4-0.9c-0.5,0-1.1,0-1.6-0.1c-1.7-0.5-3.5-0.9-5.3-0.7
                c-0.5,0-1-0.5-1.1-1c-0.1-0.5-0.2-1.1-0.2-1.6c-0.1-1.5-0.5-2.9-1.7-3.9c-0.2-0.2-0.1-0.6,0.2-0.7c0.1,0,0.2,0,0.3,0
                c0.7,0,1.3-0.1,1.6-0.7c0.5-0.8,0.5-1.6,0.1-2.4c-0.2-0.4-0.5-0.7-0.7-1c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.2,0.3-0.7,0.5-0.7
                c0.5,0,1.1,0.1,1.6,0.1c0.7,0,1.5-0.6,1.7-1.3c0.1-0.4,0.1-0.8,0.2-1.3c0-0.3,0.1-0.6,0.2-1c0.6-0.2,1.2-0.4,1.7-0.7
                c0.6-0.3,1.1-0.7,1.2-1.4c0.1-0.6,0.2-1.3,0.8-1.7c1-0.8,1.2-2,1.5-3.1c0.4-1.6,0.2-3-1.1-4.1c-0.7-0.7-1.3-1.4-1.5-2.4
                c-0.1-0.5-0.5-0.9-0.9-1.3c-0.3-0.3-0.6-0.6-0.8-1c-0.4-0.6-0.4-1.3,0-1.8c0.3-0.3,0.5-0.7,0.8-1c0.4-0.4,0.6-0.9,0.6-1.4
                c0-0.9,0.2-1.7,0.6-2.4c0.5-1,0.1-1.9-0.5-2.6c-0.3-0.4-0.8-0.7-1.1-1.1c-0.3-0.4-0.7-0.8-0.9-1.3c-0.3-0.8-0.8-1.2-1.7-1.3
                c-0.8-0.1-1.5,0.1-1.9,0.8c-0.1,0.1-0.1,0.2-0.2,0.2c-0.3,0.3-0.9,0.4-1.1,0.1c-0.2-0.2-0.3-0.6-0.3-0.8c0-0.2,0-0.4,0.1-0.6
                c0.8-1.2,0.4-2.5-0.1-3.7c-0.4-0.9-1-1.6-1.6-2.4c-0.5-0.7-1.7-0.7-2.3,0c-0.5,0.7-1,1.4-1.6,2c-0.1,0.1-0.4,0.2-0.6,0.2
                c-0.2,0-0.4-0.2-0.4-0.3c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.3-0.1-0.6-0.2-0.9c-0.2-0.7-0.9-1-1.5-0.7c-0.1,0-0.2,0.1-0.3,0.2
                c-0.4,0.2-0.7,0.5-1.1,0.7c-1-0.1-1.9-0.2-2.9-0.3c-0.4-0.1-0.8-0.5-0.7-0.9c0.1-0.6,0.3-1.2,0.4-1.9c0.1-0.7-0.1-1.3-0.7-1.7
                c-0.4-0.3-0.6-0.6-0.4-1.1c0.2-0.7,0-1.2-0.2-1.8c-0.3-0.8-0.2-0.9,0.6-0.9c0.4,0,0.9,0.1,1.2-0.2c0.6-0.6,0.6-1.8,0-2.3
                c-0.7-0.5-1.4-1-2.1-1.5c-0.7-0.5-1.2-1.2-1.3-2.1c-0.1-0.8,0-1.4,1.1-1.5c0.6-0.1,1-0.3,1.3-0.7c0.5-0.8,1.1-1.6,2-1.9
                c0.3-0.1,0.6-0.3,0.7-0.6c1-1.3,2.3-2.1,3.8-2.8c1.2-0.6,1.9-1.6,2.3-2.9c0.2-0.6,0.3-1.2,0.5-1.8c0.1-0.4,0.1-0.9,0.4-1.2
                c0.9-1.3,1.1-2.7,0.9-4.3c-0.1-1,0.2-1.9,0.9-2.6c0.2-0.2,0.4-0.5,0.6-0.8c0.4-0.7,0.4-1.4,0-2.1c-0.1-0.3-0.3-0.6-0.6-0.8
                c-1.2-0.6-1.2-1.6-1.2-2.7c0-0.5,0-1.1,0.1-1.6c0.1-0.4,0.2-0.9,0.5-1.1c1-0.9,1.5-2,1.8-3.3c0-0.1,0.1-0.2,0.1-0.3
                c1.5-2.4,0.6-4.5-0.7-6.5c-0.5-0.7-1-1.3-1.2-2.2c-0.1-0.5-0.6-0.8-1.2-1c-0.8-0.2-1.7-0.2-2.5,0c-0.6,0.2-1.2,0.4-1.8,0.5
                c-0.7,0.1-1.5,0.1-2.2-0.2c-0.8-0.3-1.2-0.8-1.2-1.7c0-0.6,0-1.3,0-1.9c-0.1-1.7-1.9-2.8-3.5-2.2c-0.4,0.1-0.9,0.3-1.1,0.6
                c-0.9,1.1-2.2,1.2-3.5,1.4c-0.8,0.1-1.5,0-2.1-0.5c-0.4-0.3-0.9-0.6-1.4-0.7c-1.1-0.4-2.4,0-3.4-0.7c-1-0.6-2.2-0.8-3.4-0.8
                c-0.4,0-0.9-0.2-1.1-0.5c-0.5-0.5-0.9-1.2-1.3-1.8c-0.1-0.2-0.1-0.7,0-0.9c0.4-0.7,0.9-1.4,1.4-2.1c0.3-0.4,0.5-1,1.1-1.1
                c0.8-0.3,1.7-0.3,2.5-0.1c0.3,0.1,0.6,0.3,0.8,0.5c0.5,0.5,1,1.1,1.4,1.7c0.6,0.8,1.3,1,2.2,0.9c1,0,1.9-0.2,2.5-1.2
                c0.6-1.1,1.6-1.7,2.7-2c0.5-0.2,1.1-0.1,1.4,0.4c0.9,1.6,2.3,1.7,3.8,1.8c0.2,0,0.4,0,0.6,0c1-0.3,1.9-0.1,2.8,0.2
                c0.7,0.2,1.5,0,2.1-0.5c0.4-0.3,0.8-0.7,1.2-1c0.6-0.5,1.4-0.4,2,0.2c0.2,0.2,0.4,0.5,0.7,0.7c0.6,0.6,1.3,0.7,2,0.1
                c0.8-0.6,1.7-0.7,2.7-0.3c0.4,0.2,0.8,0.2,1.2,0.3c0.5,0.1,1.1-0.1,1.5-0.5c0.4-0.4,0.8-0.7,1.1-1.1c0.5-0.5,1-0.7,1.7-0.6
                c0.5,0.1,1,0.2,1.6,0.3c1.3,0.2,2.7-0.9,2.7-2.1c0-1.9,0.6-3.5,2-4.8c0.6-0.6,0.9-1.4,1.5-2.1c0.6-0.8,1-1.6,0.8-2.6
                c-0.1-0.5,0.4-1.1,0.9-1.2c0.7-0.2,1.5-0.2,2.2-0.4c0.6-0.2,1.2-0.4,1.8-0.7c0.9-0.5,1.5-1.2,1.7-2.2c0.2-1.2,0.7-2.2,1.3-3.3
                c0.7-1.3,1-2.8,1.7-4.1c0.2-0.4,0.2-1,0.2-1.6c0.2-2,0.5-2.3,2.5-2.8c0.9-0.2,1.9-0.4,2.7-1c0.7-0.5,1.6-0.7,2.4-0.7
                c0.9,0.1,1.7-0.1,2.4-0.6c1-0.6,2-0.6,3,0.1c0.3,0.2,0.5,0.3,0.8,0.6c0.8,0.7,1.7,0.8,2.7,0.6c0.7-0.2,1.5-0.4,2.1-0.7
                c1.5-0.8,2.4-2.1,2.4-3.9c0-1.9,0-3.8,0.7-5.6c0.1-0.3,0.1-0.6,0.1-0.9c0-0.7,0-1.5-0.3-2.2c-0.2-0.4-0.4-0.8-0.4-1.2
                c0-1.5-0.7-2.6-1.9-3.5c-0.5-0.4-0.6-1.2-0.2-1.7c0.9-1.2,1.8-2.4,2.6-3.6c0.5-0.7,0.7-1.5,0.4-2.4c-0.1-0.4-0.4-0.8-0.8-0.9
                c-0.4-0.2-0.9-0.2-1.2,0.1c-0.4,0.6-0.9,1.2-1.3,1.7c-0.1,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.3-0.3-0.5c0.1-0.6,0.3-1.2,0.5-1.8
                c0.2-0.9,0.2-1.7-0.3-2.4C728.7,184.8,727.7,183.3,726.7,181.8z"/>
            <path className="st3 state_level" id="Cumuto/Manzanilla" d="M842.5,502.6c-0.3,0.1-0.6,0.2-0.9,0.2c-0.8,0-1.7,0-2.6,0c-16.1,0-32.1,0-48.2,0c-1,0-1.9,0-2.9-0.1
                c-0.4,0-0.8-0.4-0.8-0.8c-0.1-0.8-0.1-1.7-0.2-2.5c0-0.3-0.3-0.6-0.5-0.7c-0.5-0.4-1.4-0.2-1.6,0.4c-0.3,0.9-0.5,1.8-0.8,2.8
                c-0.2,0.6-0.6,0.9-1.1,1c-0.4,0.1-0.8,0.1-1.3,0.2c-0.8,0.1-1.5,0.4-1.9,1c-0.5,0.7-1.1,0.9-1.9,1c-0.3,0-0.6,0.1-0.9,0.1
                c-0.7,0.2-1.1,0.6-1.2,1.3c-0.1,0.3-0.2,0.6-0.4,0.8c-0.1,0.1-0.4,0.2-0.6,0.1c-0.3-0.1-0.5-0.3-0.7-0.6c-0.5-0.8-1.3-1-2.2-1
                c-0.4,0-0.8,0-1.2,0c-0.2-0.2-0.4-0.3-0.5-0.5c-0.5-1.2-1.5-1.4-2.6-1.2c-0.5,0.1-1,0.3-1.4,0.8c-0.3,0.3-0.5,0.7-0.7,1
                c-0.3,0.4-0.6,0.6-1.1,0.6c-0.4,0-0.8,0.1-1.3,0.1c-1.2,0-2,0.6-2.6,1.6c-0.2,0.4-0.4,0.7-0.7,1.1c-0.3,0.4-0.9,0.6-1.4,0.3
                c-0.4-0.2-0.7-0.5-1.1-0.7c-0.4-0.1-0.8-0.2-1.2-0.1c-1.6,0.4-3,1.2-4.2,2.3c-0.4,0.3-0.6,0.8-0.9,1.3c-0.3,0.5-0.6,1.1-1,1.6
                c-0.4,0.5-1,0.9-1.6,0.9c-0.5,0.1-1.1,0.1-1.6,0.1c-0.9,0-1.7,0.3-2.2,1c-0.4,0.5-0.9,0.9-1.6,0.9c-0.7,0-1.2,0.4-1.7,0.8
                c-0.7,0.6-1.5,0.8-2.3,0.8c-0.5,0-1.1,0-1.6,0.1c-1,0.2-1.6,1.2-1.3,2.2c0.1,0.3,0.3,0.6,0.4,0.8c0.5,0.6,0.3,1.2-0.2,1.7
                c-0.3,0.3-0.5,0.7-0.7,1c-0.2,0.5,0.1,1.4,0.5,1.7c0.7,0.5,1.6,0.6,2,1.5c0.3,0.6,0.8,1,1.2,1.4c0.5,0.5,1,1.1,1.2,1.8
                c0.1,0.2,0.2,0.4,0.1,0.6c-0.1,0.3-0.2,0.7-0.5,0.8c-0.7,0.3-1.4,0.4-2.1,0.6c-0.9,0.3-1.4,1-1.6,1.9c-0.1,0.3-0.1,0.6-0.1,0.9
                c0.2,1.4-0.4,2.2-1.8,2.5c-0.6,0.1-0.9,0.6-1,1.1c-0.1,0.3-0.1,0.7-0.2,0.9c-0.2,0.2-0.5,0.5-0.7,0.5c-1.4,0.2-2.4-0.4-2.7-1.6
                c-0.1-0.2-0.1-0.4-0.1-0.6c0-1.4-0.8-2.4-1.8-3.2c-0.7-0.5-1.4-1-2-1.6c-0.5-0.4-0.8-1-0.7-1.7c0-0.8,0-1.7,0.2-2.5
                c0.2-1,0-1.9-0.3-2.8c-0.2-0.7-0.4-1.4-0.7-2.1c-0.1-0.4-0.4-0.7-0.7-1.1c-0.4-0.4-0.9-0.5-1.4-0.3c-0.2,0.1-0.4,0.2-0.6,0.3
                c-0.5,0.3-1,0.4-1.5,0.1c-0.9-0.6-1.8-0.5-2.7,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.7,0.4-1.4,0.5-2.1,0c-0.2-0.1-0.4-0.2-0.5-0.4
                c-0.7-0.6-1.5-0.8-2.4-0.8c-0.9,0-1.7,0-2.6,0c-8.1,0-16.2,0-24.3,0c-1,0-1.9,0-2.9-0.1c-0.5,0-0.9-0.5-1-1.1c0-1-0.1-1.9,0-2.9
                c0-3.4,0-6.8,0.1-10.2c0-0.2,0.2-0.3,0.3-0.4c0.2-0.2,0.4,0,0.5,0.2c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.2,0.5,0.6,0.7,0.6
                c1.1,0.1,1.7,0.7,2.2,1.6c0.5,0.7,1.2,0.8,2,0.5c0.7-0.2,1.3-0.6,2-0.9c0.8,0.1,1.7,0.2,2.5,0.3c0.6,0,1.3-0.4,1.5-1
                c0.2-0.7,0.4-1.4,0.7-2.1c0.3-0.7,1-1.2,0.8-2c-0.2-0.7-0.5-1.4-0.7-2.1c0.2-0.7,0.8-0.9,1.3-1.4c0.6-0.7,0.7-2,0.1-2.6
                c-0.5-0.6-1-1-1.6-1.6c-0.7-0.7-0.1-1.9,0.6-2.1c0.6-0.2,1.2-0.3,1.8-0.5c0.4-0.1,0.7-0.5,0.8-0.9c0.5-1.4,0.5-2.7-0.2-4
                c-0.1-0.2-0.3-0.3-0.3-0.5c-0.4-0.9-0.2-1.6,0.9-2c0.7-0.3,1.4-0.5,2.1-0.8c0.6-0.3,0.9-0.9,0.9-1.6c0-0.5-0.3-0.9-0.7-0.9
                c-1,0-1.9-0.1-2.9-0.1c-5.6,0-11.3,0-16.9,0c-0.9,0-1.7,0-2.6-0.1c-0.5,0-0.8-0.5-0.6-0.9c0.9-1.2,1-2.8,2.1-3.9
                c0.2-0.2,0.3-0.6,0.3-0.9c0.1-0.7,0.5-1.1,1.2-1.4c1.2-0.6,1.7-1.6,1.5-2.8c-0.1-0.9-0.7-1.5-1.5-1.9c-0.3-0.1-0.6-0.2-0.9-0.2
                c-0.7,0-1.2-0.3-1.7-0.7c-0.4-0.5-1-0.8-1.4-1.3c-0.3-0.3-0.1-1.2,0.4-1.3c0.1,0,0.2-0.1,0.3-0.1c0.6-0.1,1.3-0.1,1.8-0.6
                c0.6-0.6,0.8-1.4,1-2.3c0.2-1-0.2-1.9-0.2-2.8c0-0.5,0.4-1.1,0.9-1.1c0.1,0,0.2,0,0.3,0c1,0.2,1.6-0.4,2-1.2c0.3-0.6,0.8-1,1.4-1.3
                c0.7-0.3,1.2-0.8,1.6-1.5c0.4-1,1.1-1.8,1.9-2.5c0.9-0.7,1.6-1.7,2.5-2.5c0.5-0.5,0.7-1.1,0.7-1.7c-0.1-3.7,0.9-7.2,1.8-10.7
                c0.8-3.1,1-6.3,0.9-9.5c0-1-0.4-1.3-1.4-1.3c-1,0-1.9,0-2.9,0c-4.4,0-8.7,0-13.1,0c-0.6,0-1.3,0-1.9-0.1c-0.3,0-0.6-0.3-0.7-0.6
                c-0.4-2.4-0.3-4.9-0.1-7.3c0-0.2,0.2-0.5,0.3-0.5c1.1-0.2,1.8-1.1,2.7-1.6c0.3-0.1,0.6-0.1,0.9-0.2c0.6-0.2,1.1-0.6,1.4-1.2
                c0.4-0.6,0.4-1.2,0-1.8c-0.4-0.5-0.8-1-1.3-1.3c-1-0.6-1.5-1.5-2.1-2.3c-0.3-0.3,0.1-1.2,0.6-1.3c0.4-0.1,0.8-0.1,1.3-0.2
                c0.8-0.1,1.6-1.1,1.4-2.2c-0.1-0.5-0.4-1-0.5-1.5c-0.2-0.7,0.1-1.1,0.9-1.4c0.6-0.2,1-0.4,1-1c0-0.6,0-1.3,0-1.9
                c0-0.6-0.4-1-0.9-1.1c-1.1,0-2.1,0-3.2-0.1c-0.7,0-1.5-0.1-2.2-0.1c-0.3,0-0.5-0.5-0.3-0.7c0.8-0.8,1.1-1.8,1.1-2.9
                c0-0.6,0-1.3,0.1-1.9c0-0.3,0.3-0.6,0.5-0.7c0.5-0.3,1.2-0.5,1.3-1.3c0-0.2,0.4-0.6,0.6-0.6c1.1-0.3,1.3-1,1.2-1.9
                c0-0.6-0.1-1.3-0.1-1.9c0-0.2-0.3-0.4-0.4-0.4c-1.1-0.1-2.1-0.2-3.3-0.3c-0.1-0.4-0.4-0.8-0.4-1.2c-0.1-1-0.2-1.9-1.3-2.3
                c-0.2-0.1,0-0.7,0.2-0.8c0.3-0.1,0.6-0.1,0.9-0.2c0.3-0.1,0.6-0.2,0.9-0.4c0.7-0.4,1.1-1.1,1-1.9c-0.1-1.2-0.3-2.3-0.6-3.5
                c-0.1-0.5-0.4-1.1-0.9-1.2c-1.5-0.3-2.2-1.6-3.2-2.1c-0.2-1.1-0.4-2.1-0.7-3c-0.3-1-1-1.9-2.2-2.1c-0.8-0.1-1.4-0.5-1.8-1.2
                c-0.3-0.5-0.8-0.8-1.3-0.8c-1.6-0.1-2.9-1.2-4.6-1.6c0.3-0.3,0.4-0.5,0.5-0.5c0.5,0,1.1,0.2,1.6,0.1c0.7-0.1,1.3-0.4,1.5-1.1
                c0.2-1.1,0.7-1.9,1.5-2.7c0.3-0.4,0.4-0.9,0.6-1.4c0.3-0.7,0.5-1.4,0.8-2.1c0.2-0.4,0.6-0.7,1.1-0.6c1.7,0.4,3.1,0.4,4.3-1.1
                c0.2-0.2,0.6-0.2,0.9-0.2c0.3,0,0.6,0,0.9,0.2c1,0.5,2,0.5,3.1,0c0.6-0.3,1.2-0.1,1.7,0.5c0.1,0.2,0.3,0.3,0.4,0.5
                c0.5,0.7,1.2,0.8,2,0.8c0.5,0,1.1,0,1.5,0c1,0.5,1.5,1.4,2.2,2c0.1,0.4,0.2,0.7,0.2,1c0.2,1.2,0.3,2.3,0.5,3.5
                c0.1,0.6,0.8,1.1,1.4,1.2c0.4,0,0.9,0.1,1.3,0c1.1,0,2.1,0,3.1,0.4c0.9,0.3,1.9,0.4,2.8,0.3c1.1,0,2.1,0.1,3.1,0.6
                c1,0.6,2,0.8,3.1,0.7c1.8,0,3.6,0,5.4-0.2c2.6-0.3,5.3-0.5,8-0.5c1.6,0,3.2-0.2,4.7-0.6c3.5-1,7.1-1.4,10.7-2
                c1.6-0.2,3.2-0.5,4.7-0.7c0.6-0.1,1.3,0,1.8,0.4c0.8,0.5,1.6,1.1,2.6,1.2c1.5,0,2.3,0.9,3.2,1.8c1.2,1.2,2.5,2.3,3.8,3.3
                c0.8,0.7,1.8,1,3,1c3,0,6,0,8.9,0c2,0,3.6-1.1,5-2.5c0.5-0.5,0.9-1.2,1.3-1.8c0.9-1.3,2.1-2,3.7-2.2c0.5,0,1.1-0.1,1.6-0.1
                c0.3,0,0.9,0.5,0.9,0.8c0,1.1,0,2.1,0,3.2c0,0.4-0.4,0.8-0.8,0.9c-0.2,0-0.4,0.1-0.6,0.1c-3,0-5.5,1.2-8.1,2.6c-0.4,0.2-0.4,1,0,1.4
                c0.7,0.8,1.6,0.9,2.6,0.7c0.3-0.1,0.6-0.2,0.9-0.4c1-0.7,2.2-0.9,3.3-0.9c1.6,0,3.2,0,4.8,0.1c0.4,0,0.8,0.2,1.2,0.4
                c1.1,0.4,2.1,0.9,3.2,1.4c0.7,0.3,1.4,0.4,2.2,0.2c0.8-0.1,1.4-0.6,1.6-1.4c0.2-0.7,0.2-1.5,0.3-2.1c0.2-0.2,0.4-0.4,0.6-0.4
                c1.5-0.3,2.5-1.1,2.6-2.7c0-0.2,0.1-0.4,0.2-0.6c0.4-1.1,1.1-1.8,2.2-2.1c0.5-0.1,1.1-0.1,1.6-0.3c1.1-0.5,2.1-0.8,3.3-0.6
                c1.1,0.1,1.9-1.2,1.4-2.3c-0.3-0.6-0.7-1.1-1-1.6c0-0.1,0-0.2,0-0.3c0.2-0.1,0.4-0.3,0.5-0.3c0.3,0.1,0.6,0.2,0.8,0.4
                c0.4,0.5,0.8,1,1.1,1.6c0.4,0.6,1,0.8,1.6,1c0.4,0.1,0.8,0,1.2-0.3c0.9-0.6,1.2-1.5,0.9-2.5c-0.1-0.5-0.4-1-0.5-1.5
                c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.3-0.3,0.5-0.1c1,0.7,1.9,1.4,3.1,1.5c0.6,0.1,1.3-0.3,1.4-1c0.1-0.5,0.1-1,0.2-1.6
                c0.1-0.4,0.7-0.7,1-0.5c0.6,0.3,1.1,0.7,1.7,0.9c1.2,0.4,2.4-0.1,2.8-1.3c0.2-0.6,0.3-1.2,0.4-1.9c0.1-0.4,0.4-0.7,0.5-0.6
                c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.3,0.4,0.7,0.7,1c0.1,0.1,0.4,0.2,0.6,0.3c0.7,0.2,2.1-0.8,2.2-1.5c0.2-1.2,0.2-2.3-0.9-3.2
                c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2-0.2-0.3-0.8-0.1-0.9c0.3-0.2,0.5-0.3,0.8-0.4c0.5,0,1.1,0,1.6,0c0.7,0,1.5,0,2.2,0
                c0.7-0.1,1.1-0.6,1.3-1.2c0.2-0.6,0.1-1.3-0.5-1.7c-0.4-0.3-0.8-0.6-1.3-0.9c-0.2-0.1-0.1-0.7,0.1-0.9c0.9-0.6,1.9-1,2.6-1.7
                c0.7-0.7,1.1-1.8,1.7-2.7c0.3-0.5,1.3-0.6,1.7-0.2c0.2,0.2,0.5,0.4,0.7,0.7c0.3,0.3,0.5,0.7,0.8,1c0.2,0.2,1,0.2,1.2-0.1
                c0.3-0.5,0.6-1.1,0.9-1.6c0.1-0.1,0.4-0.2,0.5-0.2c0.3,0.1,0.6,0.4,0.5,0.7c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3,0.9,0.6,1.7,1.5,1.6
                c0.6-0.1,1-0.3,1.4-0.7c0.2-0.2,0.6-0.3,0.8-0.3c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.2,0.5,0.1,0.5c-0.4,0.4-0.8,0.6-1.2,1
                c-0.3,0.3-0.5,0.7-0.5,1.1c-0.1,0.9,0.6,1.7,1.5,1.6c1.4-0.2,2.7-0.5,3.5-1.8c0.4-0.7,0.9-0.7,1.7-0.3c0.1,0,0.2,0.1,0.3,0.2
                c1.4,0.7,2,0.6,2.9-0.7c0.2-0.3,0.3-0.6,0.5-0.8c0.3-0.3,0.7-0.5,1.1-0.6c0.6-0.1,1.3-0.1,1.9,0c1,0.1,1.9,0.3,2.9,0.3
                c0.8,0,1.7-0.2,2.5-0.3c0.2,0.2,0.4,0.4,0.4,0.5c0.5,2.4,1,4.8,1.4,7.2c0.3,1.8,0.4,3.6,0.6,5.4c0.3,1.7,0.6,3.4,1,5
                c0.5,1.9,0.8,3.7,0.9,5.7c0.2,2.8,0.4,5.5,0.7,8.3c0.1,1.2,0.4,2.3,0.7,3.4c0.5,1.7,0.5,3.3,0.2,5c-0.6,2.5-0.3,5.1-0.2,7.6
                c0,0.8,0.3,1.7,0.7,2.4c1.1,2.5,1.7,5,2.1,7.7c0.3,2.3,0.9,4.6,1.6,6.8c0.7,2.1,1.2,4.3,1.7,6.5c0.1,0.6,0.2,1.2,0,1.9
                c-0.1,0.5-0.3,1-0.3,1.6c0,0.5,0.1,1.1,0.6,1.4c0.2,0.1,0.3,0.3,0.5,0.4c1.3,0.6,1.6,1.6,1.3,2.9c-0.2,1.1-0.4,2.3-0.7,3.4
                c-0.1,0.6-0.8,1.1-1.4,1c-0.5-0.1-1-0.2-1.5-0.4c-0.9-0.3-1.8-0.7-2.6-1.1c-0.6-0.3-1.2-0.2-1.9,0c-0.7,0.2-1.4,0.6-1.9,1.2
                c-1.5,1.6-3.2,3.1-4,5.3c-0.3,0.9-1,1.3-2,1.3c-0.6,0-1.3,0-1.9,0c-0.8,0-1.5,0.1-2.2,0.4c-0.9,0.4-1.6,0.1-2.4-0.4
                c-1.3-0.8-2.5-0.4-3.1,1c-0.5,1.1-0.8,2.2-1.1,3.3c-0.5,1.9-0.9,3.7-1.2,5.6c-0.1,0.8-0.1,1.7,0.1,2.5c0.2,1.1,0.3,2.1,0,3.2
                c-0.2,1-0.1,1.9,0.3,2.8c0.5,1,0.6,2,0.6,3.1c0,2,0.1,4,0.2,6.1c0.3,4.8,0.8,9.5,1.8,14.2c0.6,2.7,1.5,5.3,2.3,7.9
                c0.7,2.1,1.2,4.3,1.7,6.5c0.6,3,1.4,6,2.2,9C842.6,501.1,842.8,501.8,842.5,502.6z"/>
            <path 
                className="st2 state_level" 
                id="TT-ARI" 
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-ARI') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-ARI') ? this.callMapData('TT-ARI') : null}
                d="M684.1,246.1c-0.6-0.5-1.1-0.9-1.8-1.3c-0.8-0.5-1.6-0.4-2.4,0.1c-0.3,0.2-0.6,0.6-0.9,0.9
                c-0.4,0.4-0.9,0.6-1.4,0.5c-1.9-0.4-3.8,0.1-5.8-0.2c-0.4-0.4-0.8-0.9-1.2-1.3c-0.6-0.7-1.4-0.9-2.3-0.7c-1.4,0.4-2.6,1-3.3,2.3
                c-0.1,0.3-0.4,0.5-0.7,0.7c-0.6,0.5-1.8,0.4-2.4-0.2c-0.6-0.7-1.2-1.5-1.9-2.1c-0.6-0.6-1.4-0.9-2.3-0.8c-0.4,0.1-0.8,0.1-1.3,0.2
                c-0.8,0.1-1.4,0.4-1.8,1.1c-0.4,0.6-0.7,1.3-1.2,1.9c-1,1.4-1.1,1.9-0.2,3.1c0.4,0.5,0.8,1,1.2,1.5c0.4,0.4,1,0.9,1.7,0.8
                c1.2-0.1,2.3,0.2,3.3,0.9c0.6,0.4,1.2,0.4,1.8,0.4c1,0,1.9,0.1,2.7,0.8c0.5,0.5,1.1,0.5,1.8,0.5c1.9,0,3.6-0.2,5-1.6
                c0.5-0.5,1.2-0.4,1.8-0.3c0.4,0.1,0.8,0.4,0.9,0.8c0.1,0.8,0.1,1.7,0.2,2.5c0.1,1.2,0.7,1.9,1.8,2.4c1.1,0.4,2.2,0.6,3.4,0.2
                c0.7-0.3,1.4-0.4,2.2-0.5c0.6-0.1,1.4,0.3,1.6,0.8c0.4,0.8,0.8,1.5,1.4,2.1c0.7,0.8,1,1.9,1.3,2.9c0.1,0.4,0.1,0.9-0.1,1.3
                c-0.4,1.1-0.8,2.2-1.2,3.3c-0.2,0.4-0.4,0.7-0.7,1c-0.9,0.9-1.3,1.9-1.3,3.2c0,0.4,0,0.9,0,1.3c-0.1,1.1,0.2,2.1,1.3,2.7
                c0.2,0.1,0.3,0.3,0.4,0.5c0.3,0.4,0.5,1.1,0.1,1.5c-1.3,1.3-1.6,2.9-1.4,4.7c0.1,0.8,0,1.7-0.7,2.4c-0.4,0.4-0.6,0.9-0.6,1.5
                c0,0.9-0.3,1.7-0.6,2.5c-0.4,1.1-1.1,1.8-2,2.4c-0.4,0.2-0.7,0.4-1.1,0.6c-0.8,0.4-1.5,0.8-2,1.5c-0.5,0.7-1.1,1.2-2,1.6
                c-0.4,0.2-0.8,0.5-0.9,0.8c-0.5,1-1.3,1.3-2.3,1.5c-0.7,0.1-1.2,1-1.1,1.7c0.1,1.3,0.5,2.5,1.5,3.4c0.6,0.6,1.5,0.8,2,1.5
                c0.1,0.2,0.1,0.5-0.1,0.5c-0.4,0.1-0.8,0.1-1.2,0.2c-0.8,0.2-1.2,0.9-0.9,1.7c0.1,0.2,0.2,0.4,0.3,0.6c0.3,0.5,0.2,1-0.1,1.5
                c-0.3,0.4-0.1,0.8,0.1,1.2c0.2,0.2,0.4,0.5,0.7,0.6c0.7,0.5,0.8,1.2,0.5,2c-0.2,0.5-0.3,1-0.3,1.5c-0.1,0.4,0.5,1.1,0.9,1.2
                c1.3,0.2,2.5,0.3,3.8,0.4c0.4,0,0.8-0.3,1.2-0.4c0.2-0.1,0.6,0.2,0.6,0.5c0.1,0.3,0.1,0.6,0.2,0.9c0.4,1,1.7,1.3,2.4,0.6
                c0.7-0.7,1.2-1.4,1.9-2.2c0.2-0.2,0.7-0.2,0.9,0c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.5,0.7,1.1,1,1.6c0.2,0.3-0.1,0.9-0.4,1.1
                c-0.1,0-0.2,0.1-0.3,0.1c-2.1,0.2-3.8,1.4-5.7,2.1c-0.6,0.2-1,0.8-1,1.5c0,2.2,0,4.5,0,6.7c-0.1,5.3,0.7,4.5-4.5,4.6
                c-3.3,0-6.6,0-9.9,0c-0.5,0-1.1,0-1.6,0c-2.8-0.2-4.6,1.3-6,3.5c-0.6,1-1,2.1-1.5,3.1c-0.2,0.5-0.4,1-0.7,1.4
                c-0.3,0.4-0.7,0.8-1.3,0.8c-1.2,0-2.3,0-3.5,0c-0.7,0-1.2,0.2-1.6,0.8c-0.4,0.6-1,1.1-1.7,1.3c-0.4,0.1-0.8,0.3-1.1,0.7
                c-1.1,1.6-2.8,2.2-4.3,3.1c-0.6-0.5-1.2-1-1.8-1.3c-0.8-0.4-1.3-0.9-1.7-1.7c-0.4-0.7-1.1-1.2-1.7-1.9c0.3-0.6,0.6-1.1,0.9-1.7
                c-0.2-1,0.2-1.9,0.5-2.8c0.2-0.6-0.5-1.9-1.2-2.1c-0.4-0.1-0.8-0.1-1.3-0.2c-0.9-0.1-1.5-0.7-1.7-1.7c0-0.2,0.1-0.5,0.2-0.6
                c0.4-0.3,0.9-0.5,1.4-0.8c0.6-0.4,0.9-0.9,0.8-1.6c-0.1-0.9-0.8-1.6-1.6-1.7c-0.7-0.1-1.5-0.1-2.2,0.2c-0.5,0.2-1,0.4-1.5,0.6
                c-0.6,0.3-1.2,0.4-1.8,0.4c-0.6,0-1,0.4-1.3,0.8c-0.3,0.3-0.6,0.6-0.9,0.8c-0.1,0.1-0.4,0-0.6-0.1c-0.3-0.2-0.5-0.4-0.7-0.7
                c-0.9-1.1-1.5-1.2-2.7-0.4c-0.3,0.2-0.5,0.4-0.8,0.6c-0.6,0.3-1.2,0.4-1.8,0c-0.7-0.5-1.3-1-2-1.6c-0.1-0.1-0.3-0.4-0.2-0.6
                c0-0.6,0.9-1.8,1.5-1.9c0.5-0.1,1.1-0.1,1.6-0.2c1.1-0.2,1.8-1.2,1.6-2.3c-0.1-0.6-0.3-1.2-0.5-1.8c-0.2-0.4-0.5-0.6-1-0.8
                c-0.3-0.1-0.8,0.2-1,0.6c-0.3,0.6-0.3,1.2-0.9,1.6c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.4-0.3-0.4-0.4c0.2-0.4,0.4-0.7,0.7-1
                c0.6-0.9,0.6-2.1,0-3c-0.3-0.4-0.6-0.6-1.1-0.6c-0.6,0-1.3,0-1.9,0c-0.2,0-0.5-0.5-0.4-0.7c0.1-0.2,0.2-0.4,0.4-0.5
                c0.8-0.6,1-1.3,0.9-2.2c-0.1-0.8,0.1-1,0.8-1.2c0.4-0.1,0.8-0.2,1.2-0.3c0.9-0.4,1.1-1.3,0.3-2.1c-0.2-0.2-0.5-0.3-0.8-0.5
                c-0.7-0.5-0.8-1.6-1.7-1.8c-0.7-0.2-1.5-0.1-2.2-0.2c-0.3,0-0.7-0.3-0.7-0.5c-0.3-0.9-0.8-1.7-1.3-2.5c-0.2-0.3-0.2-0.9-0.1-1.2
                c0.5-0.9,0.6-1.8,0.1-2.7c0.1-1.7-0.4-3.4-0.4-5.1c-0.1-1-0.6-1.9-1.4-2.4c-1.1-0.6-1.8-1.5-2.6-2.3c-0.7-0.7-1.6-1.3-2-2.4
                c-0.2-0.6-0.8-0.7-1.3-0.9c-0.5-0.2-0.9-0.6-1-1.1c-0.1-0.7-0.3-1.5-0.6-2.2c-0.1-0.4-0.5-0.7-1-0.7c-0.2,0-0.4,0-0.6,0
                c-1.4-0.1-1.5-0.3-1.6-1.5c0-1.1,0-2.1,0-3.2c0-0.6,0.1-1,0.5-1.5c0.4-0.4,0.5-1,0.4-1.5c-0.5-2.1,0-4.2,0.1-6.3
                c0.1-1,0.3-1.9,0.4-2.8c0.1-0.7,0.3-1.2,0.7-1.7c0.7-0.8,0.8-1.7,0.1-2.6c-0.2-0.2-0.4-0.5-0.5-0.8c-0.2-0.6-0.4-1.2-0.1-1.8
                c0.6-1,0.6-2,0-3c-0.5-0.7-0.3-1.4,0.3-2c0.1-0.2,0.3-0.3,0.4-0.5c0.4-0.4,0.5-1,0.3-1.5c-0.1-0.5-0.3-1-0.5-1.5
                c-0.4-0.9-0.5-1.8-0.5-2.8c0-0.9,0.3-1.6,1-2.2c0.8-0.6,0.8-1,0.4-1.9c-0.3-0.7-0.8-1.3-1.7-1.3c-0.8,0-1.4-0.2-1.9-0.9
                c-0.2-0.3-0.7-0.6-1-0.7c-0.7-0.1-1.4-0.4-2-0.8c-0.7-0.4-1.4-0.3-2.1,0.2c-0.3,0.2-0.5,0.4-0.8,0.6c-0.8,0.5-1.6,0.5-2.5,0.3
                c-0.7-0.2-1.4-0.5-2.1-0.8c-1.2-0.5-2.3-0.4-3.2,0.5c-0.7,0.7-1.5,1.2-2.5,1.2c-1,0-1.9,0.4-2.6,1c-0.2,0.2-0.5,0.4-0.8,0.6
                c-0.5,0.3-1,0.4-1.5,0.3c-1.2-0.4-2.4-0.8-3.7-1c-0.5,0-0.9-0.5-1.3-0.8c-0.2-0.1-0.3-0.3-0.4-0.5c-0.6-0.8-0.9-2-1.9-2.5
                c-0.9-0.4-1.8-0.5-2.8-0.8c-0.9-1.1-1.7-2.3-2.6-3.5c-0.7-0.9-2-1.2-3.2-0.8c-0.3,0.1-0.6,0.3-0.7,0.6c-0.2,0.3-0.4,0.8-0.5,1.2
                c-0.5,1.4-2.6,1.5-3.5,0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.9-1.6-2.3-2.1-4.1-1.9c-0.5,0.1-1.1,0-1.5,0c-0.3-0.3-0.6-0.4-0.7-0.7
                c-0.6-1.1-1.5-1.7-2.7-2.1c-0.3-0.1-0.6-0.3-0.8-0.4c-0.4-0.2-0.6-0.6-0.7-1c-0.1-1-0.1-1.9-0.1-2.9c0-6.7,0-13.4,0-20.1
                c0-0.9,0-1.7,0-2.6c0-0.8-0.3-1.4-0.8-2c-1.1-1-2.1-2.1-3.2-3.2c-0.4-0.5-0.9-0.9-1.4-1.5c-0.2-3.4-0.1-6.9,0-10.3
                c0.3-0.3,0.5-0.6,0.8-0.7c1.8-1,3.2-2.5,4.3-4.2c0.9-1.3,1.9-2.5,3.1-3.6c1-0.9,1.7-1.9,1.8-3.3c0-1.1,1-2,0.5-3.1
                c-0.4-0.9-1.3-1.4-1.9-2.1c-0.9-0.9-0.5-2.6,0.7-3.3c0.3-0.2,0.6-0.1,0.9,0.1c0.5,0.4,0.9,0.9,1.4,1.3c0.4,0.3,1.1,0.3,1.5,0
                c0.3-0.3,0.7-0.5,1-0.8c0.8-0.8,1.7-0.8,2.7-0.7c0.2,0,0.4,0,0.6,0.2c1,0.6,2.2,0.7,3.3,0.7c0.3,0.3,0.5,0.5,0.7,0.8
                c1.3,2.2,3.3,3.6,5.6,4.6c1.1,0.5,2.5,0.6,3.7,0.9c1.9,0.4,3.7,0.8,5.6,1.3c0.5,0.1,1,0.5,1.3,0.8c1,1,2.2,1.3,3.5,1.2
                c0.2,0,0.4,0,0.6-0.1c0.9-0.1,1.7,0,2.4,0.5c0.2,0.1,0.4,0.2,0.6,0.3c1.4,0.9,2.7,1.2,4.1,0c0.5-0.4,1.2-0.3,1.8,0
                c0.1,0,0.2,0.1,0.3,0.2c1.7,1.3,3.5,1.4,5.4,0.8c1.3-0.4,2.5-0.2,3.8,0.2c1.2,0.4,2.4,0.8,3.6,1.3c2.5,1,5,0.9,7.5,0.1
                c0.5-0.2,1-0.4,1.5-0.6c0.7-0.3,1.2-0.1,1.8,0.3c0.3,0.2,0.5,0.4,0.8,0.5c0.5,0.3,1.3,0.1,1.8-0.3c0.7-0.5,0.8-1.5,1.8-1.9
                c0.3,0.5,0.5,1.1,0.8,1.6c0.2,0.4,0.7,0.5,1.1,0.4c0.1,0,0.2-0.1,0.3-0.1c0.6-0.3,1.2-0.4,1.7,0.2c0.4,0.4,0.8,0.8,1.3,0.9
                c1.4,0.2,2.6,0.9,3.8,1.5c0.6,0.3,1.2,0.4,1.8,0.4c0.5,0,1.1,0,1.6-0.1c1.8-0.2,3.6-0.4,5.4-0.1c0.6,0.1,1.3-0.1,1.9-0.2
                c0.6-0.1,1.3-0.2,1.9-0.2c1.1-0.1,2.1,0.2,3,0.7c0.9,0.5,1.8,1.1,2.8,1.6c0.7,0.3,1.5,0.2,2.1-0.2c0.7-0.5,1.3-1.1,1.5-2
                c0.1-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.5,0.7-0.5c0.8-0.2,1.7-0.1,2.5,0.2c0.6,0.2,1.2,0.4,1.8,0.7c2.1,1.3,4.3,1,6.5,0.8
                c0.4,0,0.8-0.3,1.1-0.6c0.4-0.3,0.8-0.6,1.2-0.9c0.9,0.1,1.6,0.4,2.3,0.3c1.3-0.3,2.5,0.1,3.7,0.5c1.1,0.4,2.3,0.5,3.4,0.6
                c0.7,0.1,1.5-0.1,2.2-0.3c0.7-0.1,1.2-0.5,1.5-1.1c0.2-0.4,0.4-0.7,0.7-1.1c0.3-0.5,0.8-0.7,1.4-0.7c1,0,1.9,0.3,2.8,0.6
                c1.3,0.5,2.6,1,3.9,1.4c0.6,0.2,1.3,0.1,1.8-0.4c1.3-1.1,2.8-1.4,4.5-1.1c0.8,0.2,1.5-0.1,2-0.8c0.3-0.3,0.5-0.7,0.7-1
                c0.3-0.4,0.7-0.5,1.1-0.4c1.1,0.3,2.1,0.3,3.2,0.1c0.7-0.1,1.2,0.1,1.7,0.6c0.1,0.2,0.3,0.3,0.4,0.5c0.6,0.7,2,0.8,2.9,0.1
                c0.3-0.2,0.6-0.4,0.7-0.6c0.6-0.9,1.5-1.5,2.6-1.6c0.6-0.1,1-0.4,1.3-0.9c0.3-0.4,0.5-0.9,0.8-1.4c0.4-0.5,0.7-1.1,1.1-1.5
                c1.9-1.5,3.9-3,6-4.2c0.7-0.4,1.4-0.6,2.1-0.3c0.4,0.2,0.8,0.3,1.2,0.5c0.1,0,0.2,0.1,0.3,0.2c0.2,0.3,0.4,0.5,0.4,0.8
                c0,1.1-0.1,2.1-0.1,3.2c0,1.2-0.1,2.3,0,3.5c0,0.5,0.2,1,0.4,1.5c0.4,0.9,1.7,1.3,2.4,0.6c0.5-0.4,0.9-0.9,1.3-1.4
                c0.7-0.8,2-0.7,2.6,0c1.1,1.3,2,2.8,2.8,4.2c0.2,0.3,0.2,0.8,0.2,1.2c-0.1,0.7-0.5,1.4-0.6,2.1c-0.1,0.4,0.1,0.8,0.2,1.2
                c0.4,0.8,1.5,1.1,2.2,0.5c0.3-0.3,0.6-0.6,0.8-0.9c0.2-0.2,0.4-0.5,0.6-0.7c0.1-0.1,0.2-0.1,0.3-0.1c0.3,0.5,0.3,1,0,1.5
                c-0.8,1.2-1.8,2.4-2.6,3.6c-0.7,1-0.6,2.4,0.3,3.2c0.9,0.7,1.4,1.5,1.5,2.7c0,0.4,0.2,0.8,0.4,1.2c0.6,1.2,0.5,2.4,0.1,3.7
                c-0.6,1.6-0.7,3.3-0.6,5c0,1.7-0.8,2.8-2.4,3.4c-0.5,0.2-1,0.3-1.5,0.4c-0.5,0.1-1.1,0-1.5-0.3c-0.3-0.2-0.5-0.3-0.8-0.5
                c-1.2-0.9-2.6-0.9-3.9-0.4c-0.5,0.2-1,0.5-1.5,0.5c-1.4,0-2.7,0.4-3.9,1.1c-0.6,0.4-1.3,0.6-2.1,0.7c-0.6,0.1-1.2,0.4-1.8,0.6
                c-0.5,0.2-0.8,0.6-1,1.2c-0.1,0.3-0.2,0.6-0.2,0.9c-0.2,2.5-1.4,4.7-2.3,6.9c-0.3,0.8-0.6,1.6-0.9,2.4c-0.5,1.6-1.7,2.4-3.2,2.7
                c-0.4,0.1-0.8,0.1-1.3,0.1c-0.6,0-1,0.3-1.4,0.6c-0.5,0.4-1,0.9-0.9,1.6c0.2,1.1-0.3,1.8-1,2.6c-0.6,0.7-1,1.7-1.6,2.3
                c-0.9,1-1.5,2.1-1.4,3.4c0,0.2,0,0.4,0,0.6c-0.2,1.2-1,1.9-2.2,1.7c-0.6-0.1-1.3-0.2-1.9-0.3c-0.5-0.1-1.1,0.1-1.5,0.5
                c-1.6,1.9-2,1.8-4.2,1.3c-0.8-0.2-1.5-0.1-2.2,0.3C684.7,245.8,684.4,245.9,684.1,246.1z"/>
            <path className="st2 state_level" id="La Brea" d="M423.8,833.5c-0.7,0.1-1.1,0.2-1.4,0.2c-1.7,0-3.4,0.3-4.9,1.2c-0.4,0.2-0.8,0.4-1.2,0.5
                c-1.2,0.5-2.3,0.4-3.2-0.6c-2.2-2.3-4.9-3.2-8.1-2.9c-1.7,0.2-3.4,0.1-5.1-0.1c-1.3-0.2-2.6-0.2-3.8,0.2c-1.9,0.6-3.8,0.7-5.7,0.6
                c-2.1-0.1-4,0.4-5.9,1.2c-0.4,0.2-0.8,0.3-1.2,0.5c-1.6,0.8-3.3,0.8-5,0.3c-0.6-0.2-1.2-0.3-1.8-0.5c-4.2-1.7-8.5-1.6-12.9-0.8
                c-1.5,0.3-2.9,0.6-4.4,0.9c-3.1,0.6-5.9,1.7-8.6,3.3c-0.6,0.3-1.1,0.7-1.6,1c-1.7,1.1-3.5,1.5-5.5,1.2c-0.9-0.1-1.9-0.2-2.9-0.3
                c-2.3-0.3-4.4,0.3-6.5,1.3c-2.2,1-4.2,2.5-6.2,4c-0.9,0.7-1.8,1.5-2.7,2.2c-2.4,2-5,2.2-7.7,0.8c-1.1-0.6-2.3-1.2-3.4-1.8
                c-1.2-0.6-2.4-0.9-3.7-0.9c-1.3,0-2.5,0.1-3.7,0.9c-0.2,0.1-0.4,0.2-0.5,0.3c-1.5,1.2-3.1,1-4.8,0.2c-0.6-0.3-1.2-0.5-1.7-0.8
                c-1.2-0.5-2.2-1.2-2.5-2.6c-0.4-1.8-1.7-3.2-3.4-4.1c-1-0.6-2-1.2-2.8-2.1c-0.3-0.4-0.6-0.9-0.6-1.6c0.8-0.6,1.6-1.2,2.4-1.9
                c0.5-0.4,0.9-0.9,1.3-1.4c0.6-1,0.7-2.9-0.5-3.8c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.4-0.5-0.9-0.3-1.5c0.1-0.4,0.3-0.8,0.5-1.2
                c0.2-0.5,0.2-1-0.1-1.5c-0.2-0.5-0.4-1-0.6-1.5c0-0.1,0.2-0.4,0.3-0.4c0.4-0.1,0.8-0.1,1.3,0c0.5,0,1.1,0.1,1.6,0c0.7,0,1.3-1,1-1.7
                c-0.2-0.5-0.6-0.9-0.9-1.4c0.3-0.7,0.5-1.5,0.9-2.3c0.2-0.4,0.5-0.6,1-0.6c0.5,0,1.1,0.1,1.6,0c0.6-0.1,1.1-1.1,0.8-1.5
                c-1.2-1.3-1.1-2.9-1-4.5c0-0.4,0.4-0.8,0.8-0.9c0.4-0.1,0.9-0.1,1.2-0.3c0.3-0.1,0.5-0.4,0.6-0.7c0.1-0.3,0.1-0.6,0.1-0.9
                c0-3.9,0-7.9,0-11.8c0-0.3-0.1-0.6-0.1-0.9c0-0.4-0.5-0.7-1-0.6c-0.5,0.1-1,0.2-1.6,0.3c-2.4,0.6-4.7,1.2-7.1,1.9
                c-1.2,0.4-2.3,0.3-3.4-0.2c-2.6-1-5.2-1.9-7.8-2.8c-0.7-0.2-1.4-0.5-2.1-0.5c-1.3-0.1-1.8,0.1-1.8,1.5c0,1.1,0,2.1,0,3.2
                c0,1.7-0.1,2.1-2.3,2.1c-0.4,0-0.9,0-1.3-0.2c-0.3-0.1-0.5-0.4-0.7-0.6c-0.1-0.1-0.1-0.2-0.1-0.3c0-2.7-0.1-5.3,0.3-8
                c0.8-5.3,1.5-10.5,2.2-15.8c0.4-3.3,0.9-6.5,1.3-9.8c0.1-0.9,0.4-1.6,1.2-2.1c0.7-0.4,1.1-1,1.4-1.7c0.6-1.3,1.6-2.2,2.8-3
                c0.4-0.3,0.9-0.6,1.4-0.8c0.8-0.5,1.5-1.2,1.9-2.1c0.3-0.6,0.6-1.1,0.8-1.7c0.5-1.4,1.4-2.6,2.3-3.8c0.7-0.9,1.4-1,2.2-0.4
                c0.3,0.2,0.5,0.5,0.8,0.6c0.9,0.3,1.9,0.5,2.7-0.3c0.5-0.5,1-0.8,1.7-0.7c0.3,0,0.6,0,1,0c1.1-0.1,1.6-0.9,1.5-2
                c0-0.3-0.2-0.6-0.2-0.9c0-0.2,0.1-0.5,0.2-0.6c1.2-0.4,1.7-1.5,2.4-2.4c0.1-0.1,0.1-0.2,0.2-0.3c0.5-0.7,0.4-1.2-0.2-2
                c-0.3-0.3-0.5-0.6-0.7-0.9c0-1.2,1.2-1.6,1.2-2.7c0-0.2,0.4-0.6,0.6-0.6c0.9-0.3,1.2-1.1,1.5-1.9c0.3-0.9,1.2-1.5,1.2-2.5
                c0-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.6-0.2,0.9-0.4c0.9-0.5,1.1-1.3,0.5-2.1c-0.5-0.7-1-1.3-1.6-2c-2.3-2.7-4.5-5.4-6.8-8.1
                c-0.4-0.5-0.8-1-1.1-1.5c0.1-0.6,0.6-1,1-1.3c1.5-1.2,3-2.3,4.5-3.6c1.9-1.7,3.8-3.5,5.6-5.3c0.8-0.8,1.5-1.8,2.1-2.8
                c0.9-1.4,1.2-2.9,0.8-4.6c-0.2-0.8-0.4-1.6-0.7-2.5c-0.3-0.8-0.6-1.6-0.9-2.4c-1.6-3.9-1.6-7.9-0.6-11.9c0.9-3.6,3.3-6.1,6.5-7.9
                c0.3-0.2,0.6-0.2,0.9-0.3c1.3-0.2,2.5-0.5,3.8-0.7c1.7-0.3,3.4-0.3,5.1,0.2c1,0.3,2.1,0.4,3.5,0.6c4.4,3.2,10,4.8,15.4,6.7
                c1.8,0.6,3.7,1.1,5.5,1.5c4.2,1.1,8.3,2.7,12,5c2,1.3,4.2,2,6.5,2.5c1.7,0.3,3.4,0.5,5.1,0.7c1.2,0.2,2.3,0.4,3.4,0.6
                c0.4,0.9,0.8,1.8,1.2,2.6c0.1,0.2-0.2,0.7-0.4,0.8c-0.4,0.1-0.8,0.3-1.2,0.4c-0.7,0.2-1,0.8-1.1,1.4c-0.1,0.3,0.1,0.7,0.3,0.9
                c0.3,0.3,0.7,0.4,1.1,0.6c1.6,0.8,1.4,1.2,0.1,1.7c-0.3,0.1-0.5,0.4-0.7,0.6c-0.3,0.4-0.4,1.2-0.1,1.5c0.9,0.9,1.5,2.1,2,3.2
                c0.2,0.4,0.1,0.9-0.3,1.2c-1.2,1.1-2.4,2.1-3.9,2.7c-0.8,0.4-1.1,0.9-1.3,1.7c-0.1,0.5-0.1,1.1-0.1,1.6c0,1.1-0.7,1.7-1.7,2
                c-0.6,0.2-1.3,0.2-1.9,0.3c-0.4,0.1-0.8,0.2-1.2,0.5c-0.5,0.3-1,0.8-1,1.5c-0.1,1.2-0.1,2.3-0.2,3.3c0.3,0.7,0.8,1,1.2,1.2
                c1.4,0.7,2.3,1.8,3,3.2c0.3,0.6,0.3,1.2,0,1.8c-0.2,0.4-0.5,0.7-0.6,1.1c-0.2,0.5-0.2,1-0.1,1.6c0.2,0.6,0.5,1.2,0.6,1.8
                c0.1,0.8-0.1,1.5-0.7,2c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,0.8-1.9,1.4-3.1,1.2c-0.3,0-0.7,0-0.9,0.1c-0.2,0.1-0.6,0.4-0.6,0.6
                c-0.1,0.8-0.1,1.7-0.1,2.5c0,10.6,0,21.3,0,31.9c0,1.8,0,3.6,0,5.4c0,1.5,0.5,1.6,1.7,1.6c1.2,0,2.3,0,3.5,0c1.1,0,2.1,0.3,3.1,0.7
                c3,1.5,5.9,2.9,8.9,4.3c0.4,0.2,0.7,0.5,0.9,0.7c0,1.2-0.7,2.1-1,3.1c-0.2,0.7-0.5,1.4-0.7,2.1c-0.1,0.5,0.3,1.3,0.9,1.6
                c0.5,0.2,1.1,0.3,1.4,0.6c0.9,0.8,2,1.2,3.2,1.2c0.8,0,1.7,0.2,2.5,0.2c1.3,0,2.2-1,2-2.3c-0.1-0.4-0.3-0.8-0.3-1.2
                c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.5c0.8-0.3,1.5-0.9,1.9-1.6c0.3-0.4,0.7-0.8,1.1-1.2c0.3-0.3,0.8-0.3,1.2-0.1
                c0.4,0.2,0.7,0.5,1.1,0.7c1.5,1,1.8,1,3.4-0.1c0.3-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6,0,0.9,0.2c0.3,0.1,0.5,0.4,0.4,0.8
                c-0.1,0.4-0.3,0.8-0.4,1.2c-0.1,0.5,0,0.8,0.3,1.1c0.3,0.3,0.7,0.5,0.9,0.9c0.2,0.2,0.3,0.7,0.1,0.9c-0.7,0.8-1.2,1.7-1.5,2.7
                c-0.1,0.3-0.6,0.6-0.9,0.8c-0.3,0.2-0.6,0.2-0.9,0.2c-0.6,0.2-0.8,0.6-0.9,1.2c0,0.2,0,0.4,0,0.6c0,3.6,0,7.2,0,10.9
                c0,0.3,0,0.7,0.1,0.9c0.1,0.3,0.3,0.6,0.6,0.7c0.6,0.3,1.2,0.5,1.8,0.7c3.2,1.1,6.4,2.2,9.6,3.4c1.3,0.5,2.7,0.7,4.1,0.7
                c3,0,6,0,8.9,0c0.7,0,1.5,0.1,2.2,0.1c0.3,0,0.4,0.1,0.4,0.4c0,0.2,0,0.5-0.1,0.5c-0.4,0.2-0.8,0.3-1.1,0.5c-1.3,0.7-2,2-1.8,3.4
                c0.1,0.4,0.2,0.8,0.4,1.2c0.2,0.5,0,0.9-0.3,1.2c-0.9,0.8-1.8,1.5-2.7,2.2c-0.7,0.6-1,1.8-0.4,2.6c0.2,0.4,0.5,0.7,0.8,1
                c0.5,0.6,0.9,1.2,1,2c0,0.3,0,0.7,0.2,0.9C423.7,829.3,423.4,831.3,423.8,833.5z"/>
            <path className="st4 state_level" id="La Horquetta/Talparo" d="M670.3,376.7c0.6,0.6,1.1,0.9,1.4,1.3c0.4,0.5,0.9,0.9,1.6,1c0.9,0.1,1.3,0.6,1.4,1.5c0.1,0.7,0.2,1.5,0.3,2.2
                c0.1,0.9-0.2,1.4-1.1,1.6c-0.3,0.1-0.6,0.1-0.9,0.2c-0.6,0.2-1.1,0.7-1.2,1.4c-0.1,0.7,0.1,1.3,0.8,1.6c0.4,0.2,0.7,0.4,0.8,0.9
                c0.1,0.6,0.1,1.3,0.2,1.9c0,0.2,0.3,0.4,0.5,0.4c0.9,0.1,1.9,0.2,2.9,0.2c0.4,0,0.8,0.4,0.9,0.8c0.1,0.6,0,1.3-0.5,1.4
                c-0.7,0.2-1.4,0.5-1.5,1.4c0,0.2-0.4,0.4-0.6,0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,1.2-0.1,2.3-0.2,3.5c0,0.8-0.2,1.5-0.9,2
                c-0.4,0.3-0.8,0.7-1,1.2c-0.2,0.5,0.2,1.2,0.7,1.2c1.5,0,3,0,4.5,0.1c0.5,0,1.1,0,1.6,0.1c0.4,0,0.7,0.4,0.7,0.9
                c0,0.6-0.1,1-0.7,1.3c-0.3,0.1-0.6,0.3-0.8,0.4c-0.4,0.4-0.7,0.9-0.6,1.4c0.1,0.6,0.3,1.2,0.5,1.9c0.2,0.7-0.1,1.3-0.8,1.4
                c-0.3,0.1-0.6,0.1-0.9,0.1c-1.2,0.1-2,2.1-1.1,3c0.9,0.9,1.6,2,2.8,2.6c0.3,0.1,0.4,0.5,0.6,0.7c0.2,0.3,0,1-0.2,1.2
                c-0.3,0.2-0.5,0.4-0.8,0.4c-0.9,0.1-1.6,0.5-2.2,1.2c-0.5,0.6-1.4,0.4-2.1,0.7c-0.1,0.2-0.3,0.4-0.3,0.6c0,2.9,0,5.7,0,8.6
                c0,0.4,0.4,0.8,0.8,0.8c1,0,1.9,0.1,2.9,0.1c4.7,0,9.4,0,14,0c0.6,0,1.3,0.1,1.9,0.2c0.2,0,0.3,0.3,0.5,0.4c0.1,0.1,0.1,0.2,0.1,0.3
                c0.1,2.2,0.2,4.4-0.4,6.6c-0.6,2.4-1,4.8-1.6,7.2c-0.4,1.7-0.6,3.3-0.6,5.1c0,1-0.3,1.9-1,2.6c-0.5,0.5-1,1.1-1.6,1.6
                c-1.1,0.9-2,1.8-2.6,3.2c-0.1,0.4-0.5,0.8-0.9,0.9c-1,0.3-1.6,1-2.1,1.9c-0.1,0.2-0.3,0.3-0.5,0.5c-0.4,0-0.8,0.1-1.2,0.1
                c-1,0.2-1.6,0.8-1.6,1.7c0,0.5,0,1.1,0.1,1.6c0.2,0.9,0,1.7-0.2,2.5c-0.1,0.4-0.5,0.7-0.9,0.8c-0.4,0.1-0.8,0.1-1.2,0.2
                c-0.7,0.2-1.1,0.7-1.1,1.4c0,0.5,0.1,1,0.1,1.4c0.4,0.8,1.1,1,1.5,1.4c0.8,0.8,1.7,1.2,2.8,1.2c0.4,0,0.8,0.3,1,0.7
                c0.4,0.5,0.3,1.4-0.2,1.7c-0.2,0.1-0.4,0.2-0.6,0.3c-0.8,0.2-1.3,0.8-1.5,1.5c-0.3,0.9-0.5,1.9-1.4,2.4c-0.1,0.1-0.2,0.2-0.2,0.3
                c-0.1,1.5-1.3,2.4-1.7,3.7c-1,0.5-1.9,0.3-2.9,0.3c-11.7,0-23.4,0-35.1,0c-1,0-1.9,0-2.9,0.1c-0.4,0-0.8,0.4-0.9,0.8
                c-0.1,0.4-0.1,0.8-0.1,1.3c0,0.6-0.2,1-0.8,1.3c-0.3,0.1-0.6,0.3-0.8,0.5c-0.7,0.5-1.1,1.2-1.1,2.2c0.1,0.8,0.1,1.7,0,2.5
                c0,0.8-0.8,1.4-1.5,1.3c-0.5-0.1-1-0.2-1.5-0.4c-0.5-0.2-0.9-0.5-1.4-0.8c-0.5-0.3-1-0.2-1.5,0c-1.1,0.5-2.1,1.1-2.5,2.3
                c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.9,0.4-1.2,0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.7-0.7-1.6-1.1-2.6-1.1c-1,0-1.9-0.1-2.5-1
                c-0.3-0.4-1-0.4-1.5-0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-0.7,0.4-1.4,0.5-2.1,0.4c-1.1-0.2-2.1-0.3-3.2,0c-1.1,0.3-2.2,0.6-3.2,1.4
                c-0.9,0.8-2,0.7-3,0c-0.9-0.6-1.8-1-2.4-2.1c-0.3-0.7-1.1-1-1.9-0.9c-1.2,0.1-2.3,0.2-3.5,0.6c-0.5,0.2-1,0.4-1.4,0.7
                c-0.6,0.3-0.8,1.4-1.7,1.4c-0.7,0-1.4-0.5-2.1-0.6c-1.4-0.1-2.2-0.9-2.6-2c-0.3-0.8-0.8-1.5-1.4-2.1c-0.5-0.5-0.7-1.1-0.7-1.7
                c0-0.8-0.2-1.4-0.5-2.1c-0.4-0.8-0.6-1.6-0.9-2.4c-0.2-0.5-0.5-1.1-1-1.2c-1.2-0.2-1.7-0.8-1.5-2c0-0.2,0-0.5-0.1-0.6
                c-0.7-1-0.5-1.7,0.4-2.5c0.3-0.3,0.4-0.8,0.2-1.2c-0.4-0.9-0.7-1.8-1.1-2.6c-0.3-0.6-0.7-1.1-1.4-1.2c-1.5-0.3-2.6-1.3-3.6-2.5
                c-0.7-0.8-0.2-2.3,0.9-2.5c0.3-0.1,0.6,0,1-0.1c1-0.2,1.5-0.7,1.4-1.7c-0.1-1.7-0.4-2.8-1.5-4.8c0.5-0.7,1.1-1.5,1.7-2.3
                c0.3-0.3,0.6-0.6,1-0.8c0.5-0.3,0.9-0.6,1.2-1c0.4-0.5,0.4-1.3,0-1.8c-0.2-0.2-0.5-0.4-0.7-0.6c-0.3-0.3-0.6-0.5-1-0.8
                c0.5-0.7,0.8-0.9,1.2-0.8c1,0.4,1.8,1.1,2.9,1.4c0.8,0.2,1.5,0.2,2.1-0.3c0.5-0.3,0.6-0.8,0.6-1.4c0-0.2,0-0.5-0.1-0.6
                c-0.7-1.2-0.6-2.5-0.6-3.7c0-0.5,0.2-1.2-0.1-1.6c-1.2-2,0-3.9,0-5.9c0-0.4,0.5-0.9,0.7-1.4c0.7-1.1,0.7-1.8-0.3-2.8
                c-0.4-0.4-0.8-0.7-1-1c-0.1-1.2-0.3-2.3-0.4-3.3c-0.1-0.6,0.1-1.1,0.5-1.4c0.1-0.1,0.4-0.2,0.6-0.1c0.4,0.2,0.8,0.4,1,0.7
                c0.3,0.6,0.4,1.2,0.6,1.8c0.4,1,1.3,1.4,2.3,1c0.8-0.3,1.3-0.9,1-1.7c-0.3-1-0.3-1.9,0.2-2.7c0.5-0.9,0.4-1.9-0.2-2.7
                c-0.6-0.9-1.1-1.8-1.3-2.9c-0.2-1.2-1-2.1-1.8-2.9c-0.2-0.2-0.5-0.3-0.8-0.4c-0.4-0.1-0.8-0.2-1.2-0.4c-0.9-0.4-1.3-1.3-1.1-2.4
                c0-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.4,0,0.6,0.1c0.3,0.2,0.5,0.4,0.7,0.7c0.7,1.3,1.9,1.2,3.1,1c0.4-0.1,0.8-0.4,0.9-0.9
                c0.1-0.6,0.3-1.3,0.3-1.9c0-1.4,0.2-2.8,0.7-4.1c0.4-1-0.1-1.9-0.6-2.6c-0.1-0.2-0.3-0.3-0.5-0.4c-1.1-0.6-1.5-1.7-1.7-2.9
                c-0.2-1.3-0.1-2.5-0.5-3.8c-0.1-0.5-0.1-1.1,0-1.6c0.3-1.7,0-3.4-0.5-5c-0.4-1.2-0.7-2.4-0.5-3.8c0.1-1.1-0.2-2.1-1-2.9
                c-0.8-0.9-0.7-2-0.4-3c0.2-0.6,0.5-1.2,0.6-1.8c0.4-1.2,0.2-2.3-0.8-3.2c-0.2-0.1-0.4-0.3-0.4-0.5c-0.1-0.3-0.2-0.7-0.1-0.9
                c0.6-0.9,1.3-1.6,1.5-2.7c0-0.2,0.4-0.6,0.7-0.6c1.6-0.1,2.5-0.9,3-2.4c0.2-0.7,0.8-0.9,1.5-0.7c0.8,0.3,1.4,0,2.1-0.2
                c0.6-0.2,1-0.1,1.3,0.5c0.6,1.2,1.8,1.7,2.8,2.5c0.3,0.3,1.2,0.1,1.4-0.3c0.3-0.4,0.5-0.9,0.8-1.4c0.3-0.4,0.7-0.7,1-0.7
                c0.3,0.1,0.4,0.7,0.2,1.1c-0.2,0.4-0.4,0.8-0.5,1.2c-0.2,0.8,0.6,1.7,1.5,1.7c0.2,0,0.4,0,0.6-0.1c1-0.5,1.8-0.2,2.5,0.7
                c0.5,0.6,1.3,0.6,2.1,0.4c0.4-0.1,0.7-0.4,1.1-0.6c0.3-0.1,0.6-0.1,0.9,0.1c0.3,0.3,0.6,0.6,0.9,0.8c0.3,0.1,0.6,0.2,0.9,0.1
                c0.3,0,0.6-0.2,0.9-0.4c0.8-0.6,1.7-0.8,2.7-0.6c0.3,0.1,0.6,0,0.9-0.1c0.6-0.1,0.9-0.5,1.1-1c0.1-0.4,0-0.8,0.1-1.2
                c0-0.3,0-0.7,0.2-0.9c0.7-0.5,0.9-1.5,1.6-2c0.2-0.2,0.6-0.2,0.9-0.1c0.3,0.1,0.6,0.8,0.4,1.1c-0.2,0.3-0.4,0.6-0.6,0.7
                c-0.7,0.5-0.9,1.1-1.1,1.9c-0.2,0.8,0.2,1.5,1,1.8c0.4,0.2,0.8,0.2,1.2-0.1c0.4-0.3,0.8-0.6,1.2-1c0.4-0.4,0.9-0.5,1.5-0.5
                c0.7,0,1.5,0.1,2.2,0.1c0.4,0,0.9-0.4,0.9-0.8c0-1,0.1-1.9,0.1-2.9c0-4.3,0-8.5,0-12.8c0-0.8,0.3-1.1,1.1-1.3
                c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.5-0.4,0.6-0.7c0.1-0.7,0.1-1.5,0.1-2.2c0-0.4,0.1-0.8,0.1-1.3
                c0-0.3,0.5-0.8,0.9-0.8c0.6,0,1.3-0.1,1.9-0.1c1.7,0.1,3.2-0.4,4.3-1.7c0.5-0.6,1.1-1,1.8-1.3c1.1-0.5,2-1.2,2.6-2.3
                c0.4-0.7,0.9-1.2,1.7-1.4c0.1,0,0.2-0.1,0.3-0.1c1-0.4,1.6-1.3,2.4-2.1c0.7-0.7,1.8-0.7,2.4-1.5c0.7-1,1.6-1.2,2.8-1.2
                c0.6,0,1.3,0.1,1.9,0.1c1.2,0,2-0.5,2.5-1.6c0.5-1.1,1-2.1,1.4-3.2c0.6-1.3,1.5-2.3,2.6-3.2c0.3-0.3,0.7-0.5,1.1-0.5
                c1.1-0.1,2.1-0.2,3.2-0.2c4.2,0,8.3,0,12.5,0c3.5,0,3.3,0.1,3.3-3.3c0-2.8,0-5.5,0-8.3c0-0.7,0.3-1.2,1-1.5c0.4-0.1,0.9-0.2,1.2-0.4
                c1.3-0.9,2.8-1,4.2-1.3c0.1,0,0.4,0.3,0.4,0.4c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.7-0.1,1.3,0.3,1.8c0.4,0.7,1.5,1.1,2.2,0.6
                c0.4-0.2,0.7-0.5,1-0.8c0.8-0.7,1.3-0.7,1.8,0.1c0.6,1,1.4,1.9,2.2,2.7c0.5,0.5,0.6,1.1,0.4,1.8c-0.1,0.3-0.3,0.6-0.4,0.9
                c-0.1,1.5-0.6,2.9-1.7,4c-0.6,0.6-0.5,1.4-0.2,2.1c0.1,0.4,0.4,0.8,0.7,1.1c0.8,0.8,1.3,1.7,1.7,2.6c0.3,0.6,0.9,1.2,1.4,1.7
                c0.6,0.7,0.9,1.4,0.8,2.3c-0.1,0.4-0.1,0.8-0.2,1.3c-0.1,0.8-0.4,1.4-1,1.9c-0.6,0.5-0.9,1.1-1,1.9c-0.1,0.7-0.5,1.1-1.2,1.4
                c-0.1,0-0.2,0.1-0.3,0.1c-1.4,0.3-1.9,1.2-1.8,2.5c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.7,0.7-1,0.7c-0.2,0-0.4-0.1-0.6-0.2
                c-0.7-0.2-1.3-0.2-1.7,0.4c-0.6,0.7-0.9,1.5-0.4,2.3c0.3,0.6,0.7,1.1,1,1.6c0.3,0.4,0.1,0.8-0.1,1.2c-0.2,0.3-0.5,0.5-0.8,0.4
                c-0.9-0.3-1.6-0.2-2.4,0.3c-0.3,0.2-0.8,0.3-1.2,0.4c-0.4,0.1-0.9,0-1.2-0.3c-0.3-0.2-0.5-0.4-0.7-0.7c-0.8-0.9-1.7-1.3-2.8-0.8
                c-0.7,0.3-1.4,0.3-2.2,0.1c-0.5-0.1-1-0.2-1.6-0.3c-0.6-0.1-1.1,0.1-1.5,0.4c-0.2,0.2-0.4,0.5-0.7,0.7c-0.5,0.5-1.1,0.6-1.8,0.3
                c-0.8-0.4-1.4-0.2-2.1,0.1c-0.5,0.2-0.9,0.6-1.1,1.1c-0.2,0.5-0.4,1-0.5,1.5c-0.2,1-0.7,1.8-1.4,2.4c-0.3,0.3-0.4,0.7-0.6,1.1
                c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.7,0.7-1,0.6c-0.4-0.1-0.8-0.2-1.2-0.3c-0.4-0.1-0.8,0-1.2,0.2c-0.6,0.3-0.8,1.1-0.3,1.6
                c0.3,0.3,0.6,0.6,1,0.8c1.1,0.5,2.1,1,3.3,1.3c1,0.3,2,0.6,2.6,1.6c0.4,0.6,1.1,0.6,1.7,0.7c0.6,0.1,1,0.5,1.2,1
                c0.3,0.7,0.5,1.4,0.7,2.1C670.3,375.6,670.2,376.1,670.3,376.7z"/>
            <path 
                className="st5 state_level" 
                id="TT-SIP"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-SIP') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-SIP') ? this.callMapData('TT-SIP') : null}
                d="M479.5,740.6c0.7,0,1.2,0.1,1.8,0.1c5.2,0,10.4,0,15.6,0c0.6,0,1.3,0,1.9-0.1c0.3-0.1,0.5-0.4,0.7-0.6
                c0.1-0.1,0.1-0.2,0.1-0.3c0.2-2.3-0.2-2.3,2.7-2.3c1.2,0,2.3,0,3.5,0c0.8,0,1.1-0.4,1.1-1.2c0.1-0.8,0.1-1.7,0.2-2.5
                c0-0.3,0.6-0.8,0.9-0.8c0.7,0,1.5-0.1,2.2-0.1c2.2,0.2,4.2-0.3,6.2-1.3c1.7-0.8,3.5-1.5,5.3-2.2c0.5-0.2,1-0.3,1.5-0.4
                c0.9-0.2,1.2,0,1.6,1c0.3,0.7,0.7,1,1.4,1c1.3,0,2.6,0,3.8,0c0.7,0,1.5,0,2.2,0.1c0.4,0,0.8,0.4,0.8,0.8c0,0.6,0.1,1.3,0.1,1.9
                c0,2.1,0,4.3,0,6.4c0,30.3,0,60.7,0,91c0,1.2-0.1,2.3-0.2,3.5c0,0.2-0.5,0.5-0.8,0.3c-0.4-0.2-0.8-0.4-1.1-0.6
                c-1.4-0.8-2.9-1.2-4.6-1c-1.3,0.2-2.5,0-3.7-0.7c-0.7-0.5-1.6-0.6-2.4-0.6c-0.4,0-0.9,0-1.3,0c-1.6,0.1-3.2-0.2-4.6-1.1
                c-0.4-0.3-0.9-0.5-1.4-0.7c-1-0.4-2-0.5-3.1-0.3c-3.6,0.5-7.2,1-10.7,1.5c-1,0.1-1.9,0.4-2.6,1.1c-1.9,1.8-2.7,1.8-4.9,0.9
                c-1-0.4-2-0.5-3.1-0.1c-2.3,1-4.8,0.8-7.2,0.7c-0.6,0-1.2-0.3-1.8-0.6c-0.7-0.3-1.3-0.7-2-1c-1-0.4-2.1-0.4-3.1,0.1
                c-0.4,0.2-0.8,0.4-1.1,0.6c-2.3,1.4-4.9,2-7.6,2.1c-1.8,0.1-3.6,0.2-5.4,0.2c-1.7,0-3.4,0.3-5,1c-0.3,0.1-0.6,0.2-0.9,0.3
                c-2,0.9-4,1.2-6.2,1.1c-2.2-0.1-4.4,0.1-6.7,0.5c-1.9,0.4-3.8,0.4-5.7,0.6c-0.6,0.1-1.3,0-1.9-0.1c-0.8-0.1-1.4-0.4-1.9-1
                c-0.5-0.6-1-1.1-1.5-1.7c-1.2-1.2-2.6-2.2-4.5-2.2c-0.9,0-1.3-0.6-1.6-1.3c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.9-0.2-1.7-0.6-2.4
                c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.8-0.3-1.7-0.7-2.4c-0.3-0.7-0.9-1.1-1.4-1.7c-0.2-0.2-0.1-1,0.2-1.2c1-0.8,2-1.6,2.9-2.4
                c0.6-0.5,0.8-1.2,0.5-2c-0.1-0.3-0.3-0.6-0.4-0.9c-0.3-0.9,0.3-2.3,1.2-2.7c0.5-0.2,1-0.3,1.5-0.5c0.5-0.2,0.8-0.8,0.7-1.3
                c0-0.6-0.6-1.3-1.2-1.3c-0.6-0.1-1.3-0.1-1.9-0.1c-3,0-6-0.1-8.9,0c-1.8,0-3.6-0.3-5.3-0.9c-2.7-1-5.4-1.9-8.1-2.9
                c-0.8-0.3-1.6-0.6-2.2-0.8c-0.5-0.6-0.5-1.1-0.5-1.7c0-3.2,0-6.4,0-9.6c0-0.8,0.2-1.5,1.1-1.8c1-0.4,1.6-1,1.8-2
                c0.1-0.8,0.8-1.2,1.3-1.8c0.5-0.5,0.5-1.1,0.1-1.7c-0.2-0.3-0.4-0.5-0.6-0.7c-0.8-0.5-0.7-1.2-0.4-1.9c0.2-0.5,0.1-1-0.3-1.5
                c-0.3-0.3-0.6-0.6-0.8-1c-0.4-0.8-0.2-1.6,0.4-2.3c0.2-0.2,0.5-0.4,0.7-0.6c0.5-0.7,1.2-1.2,2.1-1.4c0.6-0.2,1.2-0.4,1.8-0.7
                c1-0.4,1.8-1,2.1-2.2c0.1-0.4,0.3-0.8,0.4-1.2c0.2-0.6,0.6-1.1,1.2-1.5c0.9-0.6,1.7-1.2,2.6-1.8c0.4-0.3,1.2,0.1,1.2,0.6
                c0.1,0.5,0.1,1.1,0.1,1.6c0,0.5-0.2,1-0.3,1.6c-0.1,0.8,0.9,1.6,1.7,1.5c0.6-0.1,1.3-0.2,1.7-0.7c0.8-0.8,1.6-0.8,2.6-0.6
                c1.8,0.3,3.2-0.8,3.2-2.6c0-0.3-0.1-0.6-0.1-1c0-1,0.2-1.4,1.2-1.5c0.6-0.1,1.3-0.1,1.9,0c0.5,0.1,1,0.2,1.6,0.2
                c1,0.1,1.9-0.2,2.5-1.1c0.7-1,1.7-1.5,2.8-2.1c1.2-0.6,2.1-0.6,3.2,0.2c1.4,0.9,2.9,0.6,4-0.7c0.3-0.3,0.5-0.7,0.7-1
                c0.9-1,1.6-1.2,2.8-0.7c0.3,0.1,0.6,0.3,0.9,0.4c0.6,0.3,1.2,0.3,1.8-0.1c0.3-0.2,0.5-0.4,0.8-0.5c0.4-0.2,0.9-0.2,1.2,0
                c0.5,0.4,1.1,0.6,1.8,0.6c0.7,0,1.3-0.2,1.6-0.8c0.5-0.8,1-1.7,1.5-2.5c0.2-0.2,0.5-0.5,0.7-0.5c0.9-0.2,1.9-0.2,2.8,0
                c0.4,0.1,0.5,0.4,0.6,0.7c0.1,1.1,0.1,2.1,0.1,3.2c0,0.8,0,1.7,0.1,2.5c0,0.6,0.8,1.1,1.4,1.1c0.7-0.1,1.2-0.3,1.7-0.8
                c0.4-0.4,0.6-0.8,1-1.2c0.7-0.7,1-1.6,0.9-2.6c-0.1-0.9,0.1-1.7,0.9-2.2c0.8-0.5,1-1.3,1-2.2c0-2.8,0-5.5,0-8.3c0-1,0-1.9,0.1-2.9
                c0-0.4,0.5-0.6,0.9-0.4c0.5,0.2,0.9,0.6,1.4,0.7c0.8,0.3,1.8-0.2,2-1c0.1-0.4,0.1-0.9,0.2-1.2c0.1-0.3,0.4-0.6,0.6-0.7
                c1.3-0.3,2-1.1,2.3-2.4c0.1-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.3,0.5-0.3c0.4,0,0.8,0,1.3,0c1.2,0.1,1.7-0.4,1.9-1.6
                c0.1-0.5,0.1-1.1,0.2-1.6c0-0.2,0.3-0.4,0.4-0.4c0.3,0,0.6,0,0.9,0.1c0.2,0.2,0.4,0.5,0.6,0.7C479,739.9,479.2,740.1,479.5,740.6z"
                />
            <path 
                className="st6 state_level" 
                id="TT-CTT"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-CTT') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-CTT') ? this.callMapData('TT-CTT') : null}
                d="M491.5,618.2c0.4-0.6,0.8-1.2,1.1-1.9c0.8-1.7,1.2-3.4,0.9-5.2c-0.4-2.2-0.6-4.4-0.7-6.7
                c-0.1-1.3-0.4-2.5-0.8-3.7c-1.6-5.3-1.9-10.7-1.2-16.1c0.2-1.7,0.8-3.3,1.2-5c0.1-0.4,0.3-0.8,0.5-1.1c0.8-1.3,1.7-2.4,2.9-3.4
                c0.8-0.7,1.6-1.4,2.4-2.2c0.5-0.5,0.9-1.2,1-2c0.2-0.8-0.3-1.5-1.2-1.5c-2.2,0-4.5,0-6.7,0c-0.4,0-0.8-0.1-1.3-0.2
                c-0.2,0-0.4-0.3-0.4-0.4c-0.1-0.5-0.2-1-0.2-1.6c-0.1-1,0-1.9-0.1-2.9c0-0.3-0.3-0.6-0.5-0.7c-1-0.4-1-1.3-0.9-2.1
                c0-0.4,0.4-0.9,0.8-0.9c0.5,0,1.1-0.1,1.6-0.1c0.4,0,0.8,0,1.3-0.1c0.6-0.1,1-0.4,1.2-0.9c0.2-0.5,0.3-1,0.4-1.5
                c0.1-0.4,0.4-0.7,0.9-0.7c0.9,0,1.7-0.1,2.4,0.5c0.7,0.6,1.5,0.7,2.3,1.1c0.9,0.4,1.8,0.6,2.8,0.6c1.3,0,2.6-0.1,3.8,0
                c1.9,0.2,3.5-0.5,5.2-1.3c0.4-0.2,0.7-1,1.3-0.7c0.3,0.1,0.5,0.7,0.7,1c0.6,0.7,1.7,0.7,2.2-0.1c0.2-0.3,0.3-0.6,0.3-0.9
                c0.1-0.5,0.3-0.8,0.8-0.8c0.4-0.1,0.8-0.1,1.3-0.1c0.5-0.1,0.8-0.3,1-0.7c0.3-0.7,0.6-1.4,0.9-2c0.1-0.3,0.3-0.6,0.6-0.8
                c0.3-0.3,0.7-0.5,1.1-0.7c0.4,0.1,0.8,0.2,1.2,0.4c1.2,0.6,2.2,0.4,3-0.8c0.3-0.4,0.8-0.7,1.2-1.1c0.5-0.5,0.7-1.1,0.5-1.7
                c-0.2-0.7-0.1-1.2,0.6-1.6c0.7-0.5,1.1-1.3,1.1-2.2c0-0.3,0-0.6,0.1-1.1c2-1.2,3-3.1,3.3-5.4c0-0.3,0.2-0.6,0.4-0.9
                c0.3-0.5,0.7-1.1,1.1-1.6c0.5-0.7,1.2-0.8,1.9-0.2c0.3,0.3,0.6,0.6,0.9,0.8c0.9,0.7,1.7,0.8,2.6,0.2c0.5-0.3,0.9-0.6,1.3-0.9
                c0.3-0.1,0.7-0.2,0.9-0.1c2,1.1,4.1,0.8,6.2,0.1c0.7-0.2,1-0.1,1.4,0.4c0.5,0.7,1,1.4,1.5,2c0.4,0.5,1.1,0.7,1.7,0.7
                c0.7,0,1.2-0.3,1.5-0.9c0-0.1,0.1-0.2,0.1-0.3c0.8-1.8,0.9-1.9,2.7-1c0.4,0.2,0.8,0.4,1.2,0.5c0.6,0.2,1.3,0.2,1.8-0.3
                c0.7-0.7,1.3-1.4,2.3-1.7c0.7-1.3,1.8-2,3.2-2.4c0.4-0.1,0.7-0.5,1-0.8c0.3-0.3,0.5-0.7,0.7-1.1c0.5-0.8,1.1-1.5,2-2
                c1.2-0.6,1.5-2.1,0.9-3.3c-0.3-0.6-0.7-1.1-0.9-1.7c-0.2-0.4-0.1-0.8,0.1-1.2c0.2-0.4,0.8-0.5,1.1-0.3c0.4,0.2,0.7,0.5,1.1,0.6
                c0.5,0.2,1,0.3,1.5,0.4c0.8,0.1,1.5-0.1,2.1-0.6c0.1-0.2,0.3-0.3,0.5-0.4c1.3-1,1.9-1,2.9,0.3c0.4,0.5,0.8,1,1.4,1.2
                c0.8,0.1,1.3,0.7,1.9,1.1c0.8,0.5,2.3,0.2,2.9-0.6c0.1-0.2,0.3-0.3,0.4-0.5c0.5-1.2,1.4-2.1,2.6-2.8c0.8-0.4,0.9-1.1,0.8-1.9
                c-0.1-0.9-0.2-1.9-0.6-2.8c-0.3-0.7,0.2-1.6,0.9-1.7c0.2,0,0.4-0.1,0.6-0.1c1.4,0.2,2.5-0.3,3.5-1.2c0.6-0.6,1.3-0.7,2-0.2
                c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.9,0.2,1.7,0.1,2.2-0.8c0.5-0.7,1.1-0.9,1.9-0.7c0.6,0.2,1.3,0.2,1.9,0.3
                c0.8,0,1.5-0.3,1.9-1c0.6-1.1,1.1-2.3,1.5-3.5c0.3-1,0.1-1.9-0.8-2.6c-0.9-0.6-1.6-1.3-2.1-2.4c-0.2-0.6-0.7-1.1-1.1-1.6
                c-0.3-0.5-0.9-0.6-1.4-0.6c-0.5,0.1-1,0.2-1.5,0.4c-1,0.3-1.7,0-2.2-0.7c-0.4-0.5-0.6-1.1-1-1.6c-0.6-0.6-1.2-1.2-0.8-2.2
                c0.5-1.2,1.1-2.3,2.5-2.7c0.6-0.2,1.3-0.1,1.9-0.3c1.3-0.4,2.2-0.1,2.9,1.2c0.8,1.3,2.9,2.2,4,2.2c0.7,0,1.3,0,1.8-0.5
                c1.7-1.5,3.6-1.7,5.7-1.5c0.6,0.1,1.3,0,1.9,0c0.8,0,1.2-1,2-0.8c0.6,0.2,1,0.7,1.6,1c0.4,0.2,0.8,0.2,1.2,0.2c1.1,0,2,0.4,2.9,1.1
                c0.9,0.7,2.2,0.4,2.8-0.5c0.3-0.4,0.4-1,0.8-1.4c0.6-0.7,1.7-1.4,2.8-0.5c0.8,0.6,1.7,0.9,2.7,0.9c1.5,0,2.5-0.9,2.5-2.3
                c0-1,0-1.9,0-2.9c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.4-0.2,0.5-0.2c0.2,0,0.4,0.2,0.4,0.3c0.1,0.3,0.2,0.6,0.2,0.9c0,1,0,1.9,0,2.9
                c0,30.5,0,61.1,0,91.6c0,0.4,0,0.8,0,1.3c0,2.6,0.1,2.4-1.9,4c-1.5,1.2-2.7,2.7-3.9,4.2c-0.2,0.2-0.3,0.5-0.4,0.8
                c0.2,1,0.9,1.7,1.3,2.6c0.3,0.6,0.4,1.2,0.2,1.9c-0.3,0.9-0.6,1.8-0.9,2.7c-0.1,0.3-0.4,0.6-0.6,0.7c-1.3,0.2-2.3,1-3.4,1.6
                c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0.3-1.3,0.7-1.5,1.5c-0.1,0.3-0.4,0.5-0.6,0.7c-0.1,0.1-0.5,0.2-0.6,0.1c-0.6-0.5-1.1-0.9-1.6-1.5
                c-0.1-0.1-0.1-0.4-0.1-0.6c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.7,0.1-1.3-0.4-1.7c-0.6-0.4-1.2-0.5-1.8-0.2c-0.5,0.2-0.8,0.6-1.3,0.9
                c-0.3,0.2-0.6,0.2-0.9,0.3c-0.4,0.1-0.9-0.3-1-0.6c0-0.3,0-0.6,0-1c-0.1-1.2-0.5-1.6-1.9-1.6c-0.3,0-0.6,0-0.9,0
                c-0.2-0.2-0.4-0.3-0.5-0.4c-0.2-1-0.9-1.7-1.9-2c-0.2-0.1-0.4-0.1-0.6-0.3c-0.1-0.1-0.2-0.4-0.1-0.5c0.2-0.4,0.5-0.6,0.8-1
                c0.3-0.4,0.3-1.1,0-1.5c-0.4-0.5-0.8-1-1.3-1.4c-0.9-0.8-1.4-1.8-1.7-3c-0.1-0.6-0.7-0.8-1.3-0.8c-0.6,0-1.1,0.3-1.2,0.8
                c-0.1,0.6-0.2,1.3-0.3,1.9c0,0.3-0.5,0.5-0.7,0.4c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.5-0.5-0.7-0.6c-1.4-0.3-2.6-0.8-3.6-1.9
                c-0.1-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.8,0-1.3,0c-0.7,0-1.2-0.2-1.5-0.9c-0.2-0.4-0.4-0.8-0.6-1.2c-0.7-0.2-1.4-0.4-2.1-0.6
                c-0.4-0.1-0.7-0.5-0.7-0.9c0-0.3,0-0.6,0-1c0-0.3,0-0.7-0.2-0.9c-1-0.7-0.9-1.8-1.1-2.8c-0.1-0.7-0.7-1.1-1.3-1.3
                c-0.6-0.2-0.8-0.7-1-1.2c-0.2-0.4-0.1-0.8,0.2-1.2c0.1-0.1,0.1-0.2,0.2-0.2c0.5-0.4,0.6-0.9,0.5-1.4c-0.1-0.5-0.8-1-1.5-1
                c-0.3,0-0.6,0.1-0.9,0.1c-0.2,0-0.4,0.1-0.7,0.1c-0.7-0.4-1.3-0.9-2.1-1.3c-0.6-0.3-1.2-0.4-1.8-0.5c-0.8-0.1-1.4,0.3-1.8,1
                c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.7-0.3,1.5-1.1,1.8c-0.6,0.3-2.8,0.1-3.4-0.2c-0.2-0.1-0.3-0.2-0.5-0.4c-1-0.7-2.1-1-3.3-0.9
                c-1.4,0.1-2.8,0-4.2,0c-0.8,0-1.7-0.1-2.5-0.2c-2.1-0.2-2.9,1.4-2.5,3.1c0.1,0.4,0.4,0.8,0.5,1.1c0.4,0.8,0.6,1.6,0.3,2.5
                c-0.4,1.2-0.2,2.5-0.8,3.7c-0.3,0.6-0.2,1.5-0.3,2.2c-0.1,0.5-0.5,0.9-1.1,1c-1,0.1-1.9,0.1-2.9,0.2c-0.9,0.1-1.6,0.7-1.7,1.6
                c-0.1,1-0.4,1.8-1.4,2.4c-0.3,0.2-0.6,0.6-0.7,1c-0.3,0.8-0.5,1.6-0.9,2.4c-0.2,0.4-0.1,1-0.1,1.6c0.1,1-0.2,1.8-1.2,2.4
                c-0.6,0.4-0.7,1-0.4,1.7c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.6-0.5,1.4-1.1,1.3c-0.4-0.1-0.8-0.2-1.2-0.3c-0.6-0.2-1.3-0.2-1.8,0.2
                c-0.3,0.2-0.7,0.5-1,0.8c-0.5,0.4-1.1,0.6-1.8,0.6c-1.1,0-2.1,0.1-3.1,0.6c-0.4,0.2-1,0.1-1.6,0.1c-0.9,0-1.7,0.2-2.1,1.1
                c-0.1,0.2-0.3,0.3-0.5,0.4c-0.4,0.2-0.8,0.3-1.2,0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.7-0.7-1.5-1.1-2.6-1c-0.9,0.1-1.7-0.2-2-1.1
                c-0.2-0.5-0.7-0.8-1.1-1.1c-0.6-0.5-1-1-1.6-1.6c-0.4-0.4-1.3-0.4-1.8,0c-0.5,0.4-0.9,0.9-1.5,1.2c-0.8,0.5-1.3,1.1-1.5,2
                c-0.2,0.7-0.6,1.1-1.3,1.3c-0.3,0.1-0.5,0.3-0.8,0.5c-0.6,0.6-0.7,1.7-0.1,2.3c0.4,0.4,0.8,0.7,1.1,1.1c0.1,0.1,0.1,0.4,0.1,0.6
                c0,0.1-0.1,0.2-0.2,0.3c-0.9,0.6-1,1.5-1,2.5c0,0.6-0.8,1.1-1.4,0.8c-0.5-0.2-0.9-0.5-1.4-0.7c-0.7-0.3-1.6,0.1-1.9,0.8
                c-0.2,0.4-0.3,0.8-0.4,1.2c-0.3,0.6-0.7,1.1-1.3,1.3c-0.9,0.3-1.7,0.7-2.4,1.5c-0.3,0.3-0.7,0.5-1.1,0.6c-1.1,0.2-1.6,0.9-2.1,1.8
                c-0.4,0.8-1.1,0.9-1.9,0.8c-0.3,0-0.6-0.2-0.7-0.6c0-0.1-0.1-0.2-0.1-0.3c0-1.5-0.7-2.4-2.1-2.8c-0.2,0-0.4-0.1-0.6-0.3
                c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.5,0-1.1,0-1.6c-0.1-0.7-0.8-1.4-1.5-1.5c-0.3,0-0.6-0.1-0.9-0.1c-0.8,0.1-1.5,0-2.2-0.4
                c-0.3-0.2-0.6-0.2-0.9-0.3c-0.8,0-1.5,0.1-2,0.8c-0.5,0.8-0.9,1.7-1.6,2.3c-0.5,0.5-0.4,1.2-0.5,1.8c-0.2,1-1.2,1.2-2,0.5
                C491.6,618.6,491.6,618.4,491.5,618.2z"/>
            <path className="st7 state_level" id="Ann’s East" d="M438.1,219.6c0.7,1,0.6,2.2,0.9,3.3c0.2,0.9,0.7,1.5,1.5,2c1.4,0.8,2.5,0.7,3.8-0.3c0.8-0.6,1.5-1.1,2.3-1.7
                c0.4-0.3,0.9-0.6,1.2-1c0.7-1.1,1.7-1.9,2.9-2.3c0.6-0.2,1-0.8,1.1-1.4c0.2-1.4,0.8-2.6,1.9-3.6c0.8-0.7,0.7-2.1-0.2-2.8
                c-0.7-0.6-1.5-1.2-2.3-1.7c-0.8-0.6-1.3-1.4-1.3-2.4c0-0.9-0.3-1.6-0.7-2.4c-0.4-0.7-0.3-1.4,0.2-2.1c0.3-0.4,1.2-0.5,1.4-0.1
                c0.4,0.5,0.7,1.1,1,1.6c0.8,1.1,2.3,1.3,3.4,0.5c0.3-0.2,0.6-0.6,0.9-0.9c0.6-0.5,1.2-0.4,1.7,0.2c0.1,0.2,0.2,0.4,0.3,0.6
                c0.4,0.7,1,1.1,1.8,1c0.6,0,1.3-0.1,1.9-0.1c0.8,0,1.4,0.7,1.3,1.5c0,0.3-0.1,0.7-0.3,0.9c-0.6,0.5-1.2,0.9-1.8,1.3
                c-0.7,0.5-0.9,1.6-0.4,2.3c0.7,1.1,1.4,2.1,2.2,3.2c0.5,0.6,1.1,1,1.9,1c1.4,0,2.8,0.1,4.1,0.1c0.8,0,1.4-0.5,1.8-1.2
                c0.6-1.1,1.1-2.3,1.6-3.5c0.4-0.8,0.3-1.7,0.2-2.5c0-0.7,0.3-1.2,1-1.4c1.1-0.3,2.1-0.9,3-1.7c0.7-0.7,1.7-1.2,2.6-1.8
                c0.3-0.2,1-0.1,1.2,0.2c0.3,0.3,0.5,0.7,0.8,1c0.8,1.4,0.5,2.3-1,3.1c-0.5,0.3-1,0.4-1.4,0.7c-0.7,0.5-0.7,1.8,0,2.2
                c0.6,0.4,1.3,0.7,1.9,1.2c0.3,0.2,0.4,0.7,0.6,1.1c0.1,0.2,0,0.5-0.2,0.6c-0.5,0.3-1.1,0.6-1.7,0.9c-0.7,0.5-0.8,1.8,0,2.2
                c2.2,1,4.3,2.3,6.7,3.1c1.4,0.4,2.7,0.6,4.1,0.3c0.8-0.2,1.6-0.4,2.5-0.7c0.3-0.1,0.6-0.3,0.8-0.5c0.8-0.9,1.7-1.5,2.7-2.2
                c0.5-0.4,0.7-1.1,0.6-1.7c-0.1-0.8-0.4-1.4-1-1.9c-0.4-0.3-0.9-0.6-1.4-0.8c-1.1-0.5-1.4-1.5-1.3-2.6c0.1-0.6,0.2-1.3,0.2-1.9
                c0.1-0.5,0.3-0.9,0.7-1c2.1-0.5,3.7-2,5.7-2.7c0.3-0.1,0.5-0.8,0.3-1.1c-0.4-0.5-0.8-1-1.2-1.5c2.1-1.5,4.3-2.8,5.8-4.9
                c0.8-1,2.3-0.9,3,0.1c0.3,0.3,0.5,0.7,0.7,1.1c0.2,0.4,0.4,0.8,0.4,1.2c0.3,1.4,1.3,2.1,2.6,2.6c0.3,0.1,0.6,0.2,0.9,0.3
                c3.3,1.1,3.3,1.1,6.8,0.2c0.6-0.2,1.3-0.3,1.9-0.4c1.2-0.4,2.3-1,3.3-1.9c0.2-0.2,0.4-0.5,0.6-0.8c0.4-0.6,0.3-1.6-0.3-2.1
                c-0.4-0.3-0.9-0.6-1.3-0.9c-0.7-0.4-0.8-1.3-0.2-1.9c0.4-0.4,0.8-0.7,1.4-0.7c0.8,0,1.7-0.1,2.5,0.1c2,0.5,3.8,0.1,5.6-0.5
                c1.1-0.4,2.2-0.8,3.3-1.1c1.6-0.5,1.8-0.4,1.8,1.4c0,0.7,0,1.5,0,2.2c0,1.5,0,3,0,4.5c0,0.6,0.1,1.3,0.1,2c1.7,1.7,3.4,3.5,5.1,5.2
                c0,0.7,0.1,1.3,0.1,1.9c0,7.5,0,14.9,0,22.4c0,0.5,0,1.1-0.1,1.6c-0.1,0.3-0.3,0.5-0.7,0.6c-0.6,0.1-1.3,0.1-1.9-0.2
                c-1.1-0.6-2.2-0.6-3.4-0.9c-0.6-0.2-1.3,0.1-1.7,0.6c-0.7,0.9-1.6,1.8-2.1,2.8c-1,1.8-2.4,3.2-3.8,4.7c-1.2,1.3-2.6,2.2-4.5,1.7
                c-0.3-0.1-0.6,0.1-0.9,0.1c-0.4,0.1-0.7,0.4-0.9,0.8c-0.4,0.8-0.4,1.6,0,2.4c0.1,0.2,0.2,0.4,0.4,0.5c1.1,1.1,1.2,2.4,0.6,3.8
                c-0.3,0.6-0.3,1.2-0.2,1.9c0.3,1,0.1,1.9-0.9,2.5c-0.2,0.1-0.3,0.3-0.4,0.5c-0.3,0.7-0.3,1.5-0.8,2.1c-0.4,0.6-1.2,0.8-1.8,1.3
                c-0.4,0.3-0.6,0.9-1.1,1.2c-1.3,0.9-1.6,2.2-1.6,3.6c0,0.8,0.2,1.5,0.8,2c0.3,0.3,0.6,0.6,0.8,0.9c-0.1,0.4-0.2,0.7-0.3,0.9
                c-0.5,0.8-0.6,1.6-0.2,2.4c0.2,0.5,0.3,1,0.3,1.5c-0.1,1.1,0,2.1,0.3,3.1c0.1,0.4-0.3,1.1-0.8,1.2c-0.1,0-0.2,0.1-0.3,0.1
                c-0.6,0.1-1.3,0.2-1.9,0.4c-0.6,0.2-1.1,0.6-1.6,1c-0.5-0.3-1-0.5-1.5-0.7c-0.8-0.3-1.8,0.2-2,1.1c-0.2,0.7-0.2,1.5,0.1,2.2
                c0.2,0.4,0.4,0.7,0.6,1.1c-0.4,1.1-0.8,2.2-1.3,3.3c-0.2,0.4-0.5,0.7-0.8,1c-0.4,0.4-0.9,0.6-1.2,1c-0.4,0.4-0.6,0.9-0.4,1.5
                c0.1,0.4,0.2,0.8,0.3,1.2c0,0.9-0.8,1.3-1,2c-0.2,0.6-0.3,1.2-0.5,1.9c-0.2,0.6-0.1,1.3,0.2,1.9c0.1,0.3,0.3,0.6,0.4,0.8
                c0.4,0.6,0.4,1.2,0.1,1.8c-0.2,0.4-0.3,0.8-0.4,1.2c-0.1,0.5,0,1,0.4,1.5c0.3,0.3,0.5,0.6,0.8,0.9c-0.4,1-0.8,1.8-1.2,2.7
                c-0.2,0.5-1.1,0.9-1.6,0.7c-0.7-0.3-1.4-0.6-2-0.9c-0.9-0.5-1.8-0.4-2.7,0.1c-0.3,0.1-0.5,0.4-0.8,0.5c-1.1,0.7-1.7,0.6-2.6-0.1
                c-1.9-1.6-3.4-3.5-3.7-6.1c0-0.2-0.2-0.4-0.3-0.5c-0.2-0.2-0.5-0.3-0.8-0.4c-0.4-0.1-1,0.2-1.1,0.5c-0.3,0.7-0.5,1.4-0.8,2.1
                c-0.4,0.9-1.1,1.2-2,1.4c-0.9,0.2-1.7,0-2.5-0.3c-0.7-0.2-1.4-0.6-2.1-0.9c-1.5-0.6-3.5,0.6-3.7,2.1c0,0.2,0,0.4-0.1,0.6
                c-0.6,1.4-0.9,2.8,0,4.2c0.2,0.3,0,0.8-0.2,1.2c-0.1,0.1-0.1,0.2-0.2,0.3c-1,0.7-1.1,2.2-2.2,2.6c-1,0.4-2.1,0.3-3.1,0.5
                c-0.3,0-0.6,0.2-0.9,0.2c-0.2,0-0.3-0.2-0.5-0.3c0,0,0-0.2,0-0.3c0.4-0.4,0.8-0.7,1.1-1.1c0.6-0.7,0.6-1.6,0.1-2.4
                c-0.2-0.4-0.5-0.7-1-0.7c-0.5,0-1.1,0-1.6,0.1c-0.2-0.2-0.5-0.4-0.7-0.7c-0.6-0.9-1.4-1.4-2.6-1.6c-1.6-0.2-3-1.1-4.5-1.5
                c-0.9-0.8-1.9-0.9-3-0.8c-0.9,0-1.6-0.3-2.3-0.9c-0.3-0.3-0.7-0.6-1.1-0.7c-1.6-0.4-2.8-1.5-4-2.3c-0.4-1-0.3-2-0.2-2.9
                c0.1-1.6-0.4-2.7-2.1-3c-0.2,0-0.4-0.6-0.3-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.4-0.5,0.6-0.9c0.7-0.1,1.4-0.2,2.1-0.3
                c0.7-0.1,1.3-0.6,1.4-1.2c0.1-0.7-0.3-1.6-1-1.9c-0.1,0-0.2-0.1-0.3-0.1c-1.5-0.3-2.7-1.2-4-2c-0.5-0.5-0.3-1.2-0.5-1.8
                c-0.2-0.5-0.3-1-0.5-1.5c0.6-0.8,1.3-1.5,1.8-2.3c0.5-0.7,0.8-1.5,1.2-2.2c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.6-0.5-1.6-1.1-1.7
                c-0.5-0.1-1.1-0.1-1.6-0.1c-1.1,0-2.1-0.4-2.8-1.4c-0.5-0.6-1.1-0.9-1.9-1.1c-1.6-0.4-3.1-0.8-4.7-0.5c-0.3,0.1-0.6,0-0.9-0.1
                c-1.1-0.1-2-0.5-2.4-1.7c-0.3-1-1.2-1.4-2.3-1.3c-0.5,0.1-1.1,0.1-1.4-0.4c-0.4-0.5-0.8-1-0.8-1.7c0-0.9,0.1-1.7,0.1-2.6
                c0-0.6,0.1-1.3,0.4-1.8c0.2-0.5,0.4-1,0.5-1.5c0.2-0.8,0.1-1.5-0.4-2.1c-0.3-0.4-0.7-0.8-1-1.2c0.3-0.7,0.6-1.4,0.8-2.1
                c0.2-0.6,0.4-1.2,0.6-1.8c0.2-0.5,0.6-0.9,1.2-1c0.3,0,0.6,0,0.9-0.1c0.9-0.2,1.4-0.9,1.5-1.8c0-0.3,0-0.7-0.1-0.9
                c-0.6-1.3-0.8-2.6-0.1-4c0.1-0.3,0.1-0.6,0-0.9c-0.4-1.4-0.8-2.9-1.4-4.2c-0.4-0.8-0.7-1.5-0.8-2.4c-0.1-0.6-0.5-1.1-1-1.6
                c-0.8-0.6-1.5-1.2-2.2-1.8c-0.3-0.2-0.1-1,0.2-1.1c0.1-0.1,0.2-0.2,0.3-0.2c2.1-0.2,2.7-1.7,3.2-3.4c0.3-1,0.1-1.9-0.8-2.5
                c-0.2-0.1-0.3-0.3-0.5-0.4c-0.5-0.5-0.5-1.3-0.1-1.7c1.7-1.7,1.7-1.7,2.7-3.8C436.7,220.3,437.2,219.7,438.1,219.6z"/>
            <path className="st6 state_level" id="Princes Town" d="M628.7,595.1c0.9-1.1,1.7-2,2.5-2.9c0.8-0.9,1.6-1.7,2.7-2.3c0.4-0.3,0.9-0.6,1.4-0.6c1.2-0.1,2-0.7,2.6-1.6
                c0.2-0.3,0.4-0.5,0.6-0.7c0.4-0.3,0.9-0.5,1.5-0.4c0.3,0.1,0.5,0.3,0.7,0.5c0.1,0.1,0,0.4-0.1,0.6c-0.2,0.3-0.6,0.6-0.8,1
                c-0.5,0.7-0.3,1.8,0.5,2.2c1.1,0.5,2.3,1.1,3.5,1.5c0.7,0.3,1.5,0,2.2-0.2c0.8-0.2,1.1,0,1.3,0.9c0,0.2,0,0.4,0.1,0.6
                c0,0.2,0.1,0.4,0.1,0.6c0.2,0.6,0.6,1.1,1.3,1.3c0.3,0.1,0.6,0.1,0.9,0.3c0.9,0.4,1.3,1.5,2.3,1.6c1,0.2,1.8,0.7,2.6,1.2
                c0.8,0.6,1.7,0.8,2.7,0.8c0.4,0,0.8,0,1.3,0c0.4,0,0.8,0.2,1.1,0.5c0.7,0.6,1.2,1.2,1.6,2c0.3,0.5,0.5,0.9,0.8,1.4
                c0.5,0.7,1.5,0.9,1.8,1.7c0.2,0.7,0.2,1.5,0.5,2.2c0.2,0.5,0.5,1,0.8,1.3c0.4,0.4,1,0.8,1.5,1.2c0.4,0.3,0.5,0.7,0.4,1.2
                c-0.1,0.5-0.2,1-0.4,1.5c-0.6,2-0.2,3.6,1.6,4.7c0.2,0.1,0.3,0.3,0.4,0.4c0.2,0.2,0.1,0.7-0.1,0.9c-0.1,0.1-0.2,0.2-0.2,0.2
                c-2.1,0.8-2.1,0.7-2.6,3.1c-0.1,0.5-0.6,0.7-1,0.6c-0.7-0.2-1.4-0.5-2.1-0.7c-0.8-0.2-1.5-0.2-2,0.5c-0.6,0.8-1.2,1.1-2.2,1.1
                c-0.5,0-1,0.3-1.5,0.6c-0.9,0.6-1.2,1.4-1.1,2.5c0.1,0.3,0.1,0.7,0.2,0.9c0.7,1.2,0.7,2.4,0.4,3.7c-0.2,0.8-0.1,1.7-0.1,2.5
                c0,0.5,0.2,1,0.6,1.4c0.7,0.8,1.4,1.6,2.2,2.3c0.9,0.7,1.2,1.4,1,2.5c-0.1,0.6,0.2,1.3,0.9,1.5c0.2,0.1,0.4,0.2,0.6,0.2
                c0.8,0.2,1.3,0.7,1.5,1.5c0.1,0.5,0.3,1,0.4,1.6c-0.6,0.3-1.2,0.6-1.7,1c-0.9,0.6-1.9,0.9-3,0.8c-0.1,0-0.2,0-0.3,0
                c-2.3,0-2.6,0.2-3.2,2.6c0,0.1,0,0.2-0.1,0.3c-0.2,0.8-0.8,1.3-1.6,1.4c-1.3,0-2.5,0.2-3.7,0.7c-0.6,0.3-1.3,0.1-1.8-0.2
                c-0.4-0.3-0.9-0.6-1.4-0.8c-0.6-0.3-1.2-0.3-1.8,0.1c-0.3,0.2-0.7,0.5-1.1,0.7c-0.7,0.4-1.8,0.1-2.3-0.6c-0.2-0.3-0.3-0.6-0.5-0.8
                c-0.3-0.5-0.5-0.9-0.8-1.4c-0.4-0.5-1-0.8-1.7-0.8c-0.5,0.1-1,0.2-1.6,0.3c-0.6,0.1-1,0-1.5-0.3c-0.4-0.2-0.7-0.4-1.1-0.6
                c-0.5-0.2-1.4,0.1-1.6,0.6c-0.2,0.5-0.4,1-0.5,1.5c-0.1,1.1-0.6,1.8-1.6,2.2c-0.5,0.2-1,0.4-1.3,0.8c-0.8,1.1-1.8,1.4-3.1,1.2
                c-1.7-0.2-3.4-0.1-5,0.6c-1.1,0.5-2,0.1-2.8-0.9c-0.3-0.3-0.5-0.7-0.7-1.1c-0.5-0.8-1.3-1-2.2-0.7c-0.5,0.2-1,0.5-1.5,0.6
                c-1,0.2-1.9,0.1-2.7-0.6c-0.1-0.1-0.2-0.1-0.2-0.2c-1.2-1.5-2.7-2.1-4.5-1.9c-0.4,0-0.8,0-1.3-0.1c-0.7-0.1-1.2-0.4-1.5-1
                c-0.2-0.3-0.3-0.6-0.4-0.8c-0.3-0.6-0.8-1-1.5-1.1c-0.7-0.2-1.4-0.3-2.2-0.5c-0.5-0.6-0.9-1.2-1.3-1.8c-0.6-0.7-1.4-1-2.3-1
                c-0.4,0-0.9,0.1-1.2,0.2c-0.8,0.3-1.5,0.7-2.2,1.1c-0.9-0.2-1.7-0.5-2.6-0.6c-1.1-0.2-2.1-0.1-2.8,1c-0.5,0.8-1.2,1.1-2.2,1.2
                c-1,0-1.5,0.7-1.6,1.7c0,0.3,0,0.7-0.2,0.9c-0.1,0.3-0.4,0.5-0.7,0.6c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0.1-1.6,0.5-2.1,1.3
                c-0.1,0.2-0.2,0.4-0.3,0.5c-0.6,1-1.5,1.5-2.6,1.5c-0.3,0-0.6,0-1,0.1c-0.8,0.1-1.3,0.7-1.2,1.5c0,0.2,0,0.4,0.1,0.6
                c0,0.6-0.2,1-0.6,1.4c-0.3,0.3-0.7,0.5-1,0.8c-0.4,0.3-0.8,0.7-0.9,1.3c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3,0.8-0.8,1.5-1.6,1.9
                c-0.3,0.1-0.6,0.3-0.8,0.5c-1.3,0.9-1.8,2.5-1.1,3.9c0.3,0.7,0.8,1.2,1.2,1.9c0.2,0.3,0,0.9-0.4,1.1c-0.3,0.1-0.6,0.3-0.9,0.4
                c-0.4,0.1-0.8,0.2-1.3,0.2c-1,0.2-1.7,0.7-2.1,1.7c-0.2,0.7-0.6,1.4-0.9,2c-0.5,0.9-1.5,1.3-2.4,1c-0.2-0.1-0.4-0.1-0.6-0.2
                c-1.2-0.8-2.4-0.8-3.7-0.3c-0.3,0.1-0.7,0.1-0.9-0.1c-0.7-0.7-1.3-1.4-1.9-2.1c-0.2-0.2-0.1-0.6-0.2-0.9c-0.1-0.5-0.2-1-0.3-1.5
                c-0.2-0.6-0.7-1-1.2-0.9c-1.5,0.1-2.7-0.4-3.8-1.3c-0.2-0.2-0.6-0.4-0.9-0.4c-1.5,0-2.5-1.2-3.8-1.6c-0.4-0.1-0.6-0.5-0.9-0.8
                c-0.5-0.5-1.1-1-1.6-1.5c-0.6-0.7-1.4-1-2.3-0.7c-1.1,0.3-1.8-0.4-2.6-0.9c-0.7-0.4-0.8-1.7-0.2-2.3c0.4-0.4,0.8-0.6,1.2-1
                c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.3-0.9-0.6-1c-0.6-0.3-1.2-0.4-1.9-0.1c-0.8,0.3-1.5,0.7-2.4,0.9c-0.9,0.2-1.2,1.2-1.8,1.8
                c-0.1,0.1-0.5,0.1-0.6-0.1c-0.5-0.8-1-1.6-1.5-2.5c0.2-0.2,0.4-0.5,0.7-0.7c0.9-0.5,1.2-1.3,1-2.1c-0.3-1.3-0.2-2.5,0-3.8
                c0.2-1.6,1.7-2.9,3.2-3.1c0.4,0,0.9,0,1.3,0c0.9,0,1.6-0.4,2.3-1c0.4-0.4,0.9-0.9,1.4-1.2c0.4-0.4,0.7-0.8,0.8-1.3
                c0.1-0.6,0-1.3,0.4-1.8c0.9-1.2,1.1-2.5,1.3-3.9c0-0.4,0.1-0.9,0.2-1.3c0.3-1.1,0.8-2,1.9-2.4c0.4-0.2,0.8-0.3,1.1-0.6
                c0.4-0.3,0.6-0.9,0.6-1.4c0-0.5-0.7-1.4-1.1-1.5c-0.1,0-0.2-0.1-0.3-0.1c-1.5,0-2.6-0.7-3.8-1.5c-0.2-0.1-0.3-0.6-0.3-0.8
                c0-0.3,0.3-0.5,0.5-0.8c1.1-1.9,1-2.9-0.4-4.6c-0.3-0.3-0.6-0.6-0.7-1c-0.1-1.4-1.1-1.6-2.2-1.8c-0.8-0.1-1.2-1-0.8-1.7
                c0.4-0.6,0.9-1.2,1.3-1.8c0.2-0.4,0.3-0.8,0.2-1.2c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-0.9-0.6-1.8-0.4-2.8c0.1-0.5,0-1.1-0.1-1.7
                c0.4-0.5,0.7-1,1.1-1.5c0.3-0.4,0.6-0.6,1.1-0.5c1,0.1,1.9,0.1,2.8-0.4c0.3-0.2,0.8-0.3,1.2-0.3c1.5,0.1,2.7-0.3,3.8-1.2
                c0.3-0.3,0.8-0.3,1.2-0.2c0.5,0.1,1.1,0.2,1.6,0.2c0.4,0,0.8-0.2,1.2-0.4c0.7-0.3,1-1.1,0.8-1.9c-0.1-0.3-0.2-0.6-0.3-0.9
                c-0.1-0.3,0-0.6,0.2-0.9c0.1-0.2,0.3-0.3,0.5-0.4c0.6-0.4,0.8-0.9,0.9-1.6c0-0.1,0-0.2,0-0.3c-0.3-1.6,0.6-2.9,0.9-4.3
                c0-0.2,0.2-0.4,0.3-0.5c1.1-0.6,1.6-1.6,1.8-2.9c0.1-0.7,0.7-1.1,1.4-1.1c0.8-0.1,1.7-0.1,2.5-0.2c0.3,0,0.6-0.1,0.9-0.1
                c0.3,0,0.8-0.6,0.9-0.9c0-0.3,0-0.6,0-1c-0.1-0.6,0-1.3,0.2-1.9c0.5-1.1,0.6-2.3,0.8-3.4c0.2-1.4,0.2-2.7-0.5-4
                c-0.2-0.3-0.1-0.8-0.2-1.2c0-0.3,0.5-0.9,0.8-0.9c0.1,0,0.2,0,0.3,0c2.4,0,4.9,0.1,7.3,0.1c1,0,1.9,0.3,2.6,1
                c0.7,0.6,1.5,0.8,2.3,0.8c0.4,0,0.9,0,1.3,0c1.1,0.1,1.8-0.5,2.3-1.4c0.2-0.5,0.3-1,0.5-1.5c0.2-0.6,1-1,1.6-0.7
                c0.6,0.2,1.3,0.4,1.7,0.8c0.9,1.1,2,1,3.2,0.8c0.3-0.1,0.5,0.4,0.3,0.6c-1,1.1-0.6,2.2-0.1,3.2c0.1,0.3,0.4,0.5,0.7,0.6
                c0.4,0.3,0.9,0.6,1.3,0.8c0,1.9,0,1.9,1.2,3.5c0,0.1,0.1,0.2,0,0.3c-0.4,1.8-0.2,2.1,1.7,2.7c0.2,0.1,0.4,0.1,0.6,0.2
                c0.6,0.1,1,0.4,1.1,1c0,0.2,0.2,0.4,0.3,0.6c0.6,0.7,1.2,1.2,2.2,1c0.9-0.2,1.7,0,2.3,0.8c0.4,0.6,1,0.7,1.7,0.8
                c0.6,0,1,0.3,1.4,0.6c0.3,0.3,0.6,0.6,1,0.8c0.8,0.5,1.8,0,2-0.9c0.1-0.5,0.1-1.1,0.2-1.6c0-0.2,0.2-0.4,0.3-0.4
                c0.2,0,0.5,0.1,0.5,0.2c0.2,1.1,0.8,1.9,1.6,2.7c0.5,0.4,0.8,1,1.3,1.5c-0.3,0.6-0.7,1.2-1,1.8c-0.3,0.5-0.1,1.2,0.4,1.4
                c0.4,0.2,0.8,0.3,1.2,0.5c0.2,0.1,0.4,0.1,0.6,0.3c0.2,0.1,0.3,0.3,0.3,0.5c0.3,1.6,1.4,2,2.9,1.8c0.2,0,0.6,0.3,0.7,0.5
                c0.1,0.3,0,0.6,0.1,0.9c0,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.2,0.3,0.4,0.5c0.8,0.5,1.6,0.5,2.4,0.1c0.5-0.3,1.1-0.7,1.6-1
                c0.1,0,0.3-0.1,0.3,0c0.1,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.4-0.1,0.6c-0.6,1-0.5,1.8,0.5,2.5c0.3,0.2,0.6,0.6,0.9,0.9
                c1,1,1.9,0.6,2.8-0.2c0.2-0.2,0.3-0.5,0.5-0.8c0.1-0.2,0.2-0.5,0.4-0.5c1.6-0.4,2.6-1.9,4.3-2.1c1-0.1,1.4-0.8,1.6-1.7
                c0.1-0.5,0.2-1,0.4-1.5c0.6-1.5,0.5-2.9-0.4-4.2C629.1,596,629,595.6,628.7,595.1z"/>
            <path className="st6 state_level" id="Caroni Central" d="M585.8,520.3c-0.8,0.7-1.6,1.2-2.2,1.9c-0.5,0.5-0.8,1.2-1.2,1.8c-0.3,0.5-1.3,0.7-1.7,0.4
                c-0.7-0.5-1.3-1.1-2.2-1.2c-0.6-0.1-0.8-0.7-1.1-1c-1.1-1.2-2.5-1.3-3.9-0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.7,0.6-1.6,0.7-2.4,0.4
                c-0.5-0.2-0.9-0.5-1.4-0.7c-0.7-0.3-1.7-0.1-2.1,0.3c-0.6,0.7-0.8,1.7-0.5,2.3c0.3,0.7,0.7,1.3,1.1,2c0.3,0.6,0.1,1.6-0.6,2
                c-1.1,0.6-1.8,1.4-2.4,2.5c-0.5,1-1.3,1.6-2.5,1.9c-0.5,0.1-1.1,0.4-1.3,0.8c-0.6,1.4-2.1,1.7-3,2.8c-0.3,0.3-0.8,0.4-1.2,0.2
                c-0.6-0.3-1.1-0.6-1.7-0.9c-0.7-0.4-1.4-0.3-2.1,0.1c-0.4,0.2-0.7,0.5-0.8,0.9c-0.2,0.5-0.3,1-0.6,1.5c-0.1,0.2-0.6,0.3-0.8,0.2
                c-0.5-0.2-0.9-0.6-1.2-1c-0.5-0.6-0.8-1.2-1.3-1.8c-0.3-0.4-0.7-0.5-1.1-0.4c-0.7,0.1-1.4,0.4-2.2,0.6c-1.5,0.5-2.9,0.5-4.3-0.5
                c-0.2-0.2-0.6-0.2-1-0.3c-0.8,0.5-1.6,1-2.4,1.6c-0.3,0.2-0.9,0.2-1.2-0.1c-0.5-0.4-0.9-0.9-1.5-1.2c-0.3-0.2-0.8-0.3-1.2-0.4
                c-0.5-0.1-1.1,0.1-1.4,0.5c-0.6,0.8-1.1,1.5-1.6,2.3c-0.2,0.3-0.2,0.6-0.3,0.9c-0.1,0.5-0.2,1.1-0.3,1.6c-0.4,1.3-1,2.4-2.2,3
                c-0.8,0.4-1.1,1-1,1.8c0.1,0.9-0.2,1.6-1.1,2.1c-0.8,0.4-0.9,1.1-0.5,1.9c0.2,0.5,0.1,1-0.4,1.4c-0.6,0.5-1.2,1.1-1.9,1.7
                c-1.1-0.1-2.1-1-3.4-0.6c-0.3,0.1-0.6-0.2-0.7-0.5c-0.4-1.3-1-2.5-1.5-3.8c-0.3-0.7-0.6-1.3-1.2-1.8c-0.9-0.7-1.6-1.7-3-1.7
                c-0.2,0-0.5-0.3-0.7-0.6c-0.3-0.4-0.5-1-0.8-1.4c-0.4-0.5-0.9-0.9-1.6-0.8c-0.5,0.1-1,0.2-1.6,0.3c-0.5,0.1-1,0-1.5-0.2
                c-2.6-1.3-2.7-0.5-2.7-3.9c0-3.1,0-6.2,0-9.3c0-1.3,0.2-2.5,0.7-3.7c0.4-1,0.8-2,1.3-2.9c1-1.8,1.5-3.7,1.7-5.8
                c0.2-1.7,0.4-3.4,0.7-5.1c0.3-2.1,0.5-4.2,0.6-6.3c0.2-5.5,0.5-11,0.7-16.6c0-1.2,0.4-2.3,0.7-3.4c0.1-0.3,0.2-0.6,0.5-0.8
                c1.1-0.8,1.5-2,2.2-3.1c0.4-0.5,0.6-1.1,1-1.6c0.7-0.9,0.9-1.9,1.1-3c0.1-1.2,0.2-2.3,0.1-3.5c-0.1-1.2-0.1-2.3,0.1-3.5
                c0.2-1.1,0.1-2.1-0.5-3.1c-0.5-0.8-0.7-1.8-0.8-2.7c-0.3-2.1-0.6-4.2-1-6.3c-0.1-0.6-0.1-1.3-0.1-1.9c0-3.7,0.1-7.4,0-11.2
                c0-1.3-0.3-2.5-0.4-3.8c0-0.3,0.4-0.9,0.7-0.9c0.4,0,0.8-0.1,1.3-0.1c6.9,0,13.8,0,20.7,0c0.4,0,0.8,0.1,1.3,0.1
                c0.2,0,0.5,0.6,0.4,0.8c-0.2,0.4-0.5,0.7-0.7,1.1c-0.3,0.4-0.2,0.8,0,1.2c0.6,0.8,1,1.5,2,2c0.8,0.4,1.5,1.1,2.5,1.2
                c1.1,0.1,1.4,0.7,1.2,1.8c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.5,0,0.9,0.4,1.2c1.3,1,2.1,2.3,2.6,3.9c0.1,0.4,0.3,0.9,0.6,1.1
                c1.1,0.6,1.8,1.5,2.3,2.5c0.2,0.3,0.6,0.6,1,0.7c1.2,0.2,2.1,0.8,2.8,1.9c0.9,1.4,2.3,1.6,3.9,0.8c0.4-0.2,0.7-0.6,1.1-0.7
                c0.2-0.1,0.5,0.2,0.8,0.4c0.1,0,0.1,0.2,0.1,0.3c0,0.8,0.3,1.5,1,1.8c1,0.5,1.4,1.2,1.3,2.3c-0.1,0.6,0.3,1,0.8,1.2
                c0.5,0.2,1,0.3,1.5,0.5c0.4,0.2,0.9,0.3,1.1,0.6c0.8,1.4,2.4,1.7,3.4,2.8c0.2,0.3,0.8,0.3,1.2,0.3c0.5,0,1-0.1,1.6-0.3
                c0.9-0.2,1.6,0,2.4,0.6c1.1,0.9,2.4,1.7,3.4,2.8c0.8,0.9,1.8,1.5,3,1.8c0.6,0.2,1.1,0.6,1.2,1.3c0.1,0.4,0.2,0.8,0.4,1.2
                c0.5,0.8,0.2,1.4-0.4,1.9c-0.4,0.4-0.5,0.9-0.3,1.4c0.2,0.6,0.8,1.1,0.7,1.7c-0.4,1.4,0.1,2.2,1.6,2.4c0.6,0.1,1,0.6,1.1,1.1
                c0.4,1.8,1.5,3.3,1.5,5.2c0,0.4,0.3,0.8,0.6,1.1c0.6,0.6,1,1.3,1.4,2.1c0.1,0.4,0.4,0.7,0.7,1.1c0.4,0.5,0.9,0.9,1.6,1
                c0.5,0.1,1,0.2,1.6,0.4c0.7,0.2,1.2,0.6,1.3,1.3c0.1,1,0.6,1.8,1.3,2.5c0.4,0.4,0.7,1,1,1.6c0.6,1,1.6,1.4,2.7,1.2
                c0.4-0.1,0.8-0.2,1.2-0.3c0.8-0.2,1.1-0.1,1.6,0.6c0.2,0.3,0.4,0.5,0.5,0.8c0.5,1.2,1.3,2.2,2.4,2.9c0.6,0.4,0.7,1.1,0.5,1.7
                c-0.4,1-0.8,2-1.3,2.9c-0.3,0.6-1.2,1-1.9,0.8c-0.3-0.1-0.6-0.2-0.9-0.3c-1.1-0.3-2.1-0.1-2.8,0.9c-0.5,0.7-1.1,0.9-1.9,0.4
                c-0.1-0.1-0.2-0.1-0.3-0.1c-1-0.3-1.9-0.2-2.7,0.5c-0.2,0.2-0.5,0.4-0.7,0.6c-0.4,0.3-0.9,0.5-1.5,0.5c-0.5,0-1.1,0-1.6,0.2
                c-1.2,0.3-1.9,1.5-1.5,2.7c0.2,0.7,0.7,1.3,0.7,2.1C585.8,519.5,585.8,519.9,585.8,520.3z"/>
            <path className="st5 state_level" id="Chaguanas West" d="M469.1,343.7c1.3,0.4,2.4,0.8,3.6,1.2c0.7-0.3,1.6-0.7,2.5-1.1c0.4-0.2,1.2,0.3,1.3,0.7
                c0.1,0.7,0.2,1.5,0.3,2.2c0.1,0.4,0.4,0.8,0.8,0.9c1.2,0.3,2.5,0.5,3.7,0.8c0.5,0.1,1.1-0.1,1.5-0.4c0.3-0.2,0.8-0.5,0.9-0.8
                c0.6-1.2,1.4-1.8,2.8-1.4c0.6,0.1,1.3-0.1,1.9-0.2c0.5-0.1,1.1-0.1,1.5,0.1c0.6,0.3,1.2,0.5,1.8,0.5c0.8,0,1.5,0,2.2-0.4
                c1.1-0.6,2.2-1.2,3.4-1.8c1-0.5,1.9-0.3,2.6,0.5c0.6,0.7,1.1,1.3,1.9,1.6c0.9,0.4,1.6,1.1,1.9,2.1c0.1,0.3,0.3,0.6,0.5,0.8
                c0.5,0.7,1.7,0.7,2.2,0c0.4-0.6,0.7-1.3,0.8-2c0.1-0.4,0.2-0.8,0.4-1.2c0.1-0.3,0.9-0.5,1.1-0.3c0.4,0.3,0.8,0.7,1.2,1.1
                c0.6,0.5,1.3,0.7,2.1,0.7c0.7,0,1.2-0.3,1.6-0.9c0.3-0.5,0.6-1.1,0.9-1.6c0.8,0.2,1.5,0.5,2.2,0.6c0.6,0.1,1,0.3,1.2,0.8
                c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.5,0.5,0.9,1.1,1.1c0.5,0.2,1,0.3,1.5,0.5c0.3,0.1,0.5,0.4,0.4,0.7c-0.1,0.3-0.1,0.6-0.1,0.9
                c0.1,0.9,0.8,1.6,1.7,1.5c0.4,0,0.8-0.2,1.2-0.3c0.5-0.2,0.9-0.1,1.1,0.3c0.7,1.3,1.8,1.7,3.2,1.8c0.8,0.1,1.3,0.5,1.6,1.3
                c0.1,0.3,0.3,0.5,0.5,0.8c0.3,0.4,1.1,0.5,1.5,0.2c0.3-0.2,0.6-0.5,1-0.8c0.2-0.1,0.3-0.4,0.4-0.4c0.3-0.1,0.7-0.2,0.9-0.1
                c1,0.7,2,1.1,3.1,1.5c0.6,0.2,0.8,0.9,0.6,1.6c0,0.2-0.2,0.4-0.4,0.5c-0.5,0.1-1,0.2-1.5,0.3c-0.8,0.1-1.2,0.5-1.2,1.2
                c0,2,0,4,0,6.1c0,1.2,0.3,2.3,0.8,3.4c0.7,1.7,0.7,3.3,0,5c-0.5,1.1-0.6,2.2-0.7,3.4c0,0.6-0.1,1.3-0.2,1.9
                c-0.6,2.6-0.3,5.1,0.5,7.5c0.5,1.4,0.8,2.9,1.2,4.3c0.6,2.3,0.2,4.4-1,6.4c-1,1.5-2,3-3.3,4.2c-1.4,1.3-2.7,2.7-4.1,4.1
                c-1.2,1.2-2.3,2.5-3.1,4c-1.2,2.4-2.9,4.4-4.7,6.4c-0.5,0.6-1,1.1-1.5,1.7c-1.5,1.7-2.9,3.4-3.8,5.5c-0.3,0.7-0.7,1.3-1.3,1.8
                c-0.3,0.3-0.6,0.6-1,0.8c-2.2,1.9-2.2,1.4-1.9,4.9c0.2,1.9,0.5,3.8,0.7,5.7c0,0.3,0,0.6-0.1,0.9c-0.1,0.1-0.3,0.3-0.5,0.3
                c-0.2,0-0.4,0-0.6-0.1c-0.7-0.5-1.4-0.9-2.1-1.4c-10.4-7.4-20.8-14.8-31.2-22.2c-1.3-0.9-2.6-1.8-3.6-3.1c-0.1-0.2-0.4-0.2-0.6-0.3
                c-1.4,0.6-2.7,1.5-4,2.4c-1.1,0.7-2.1,0.9-3.3,0.6c-1-0.2-2.1-0.5-3.1-0.7c-0.6-1.2-1.2-2.3-1.7-3.4c-0.3-0.6-0.3-1.2-0.4-1.9
                c-0.2-1.4-0.3-2.7-1.1-4c-0.3-0.4-0.4-1-0.5-1.5c-0.2-1-0.6-1.8-1.2-2.5c-0.4-0.5-0.7-1.1-1-1.6c-0.4-0.7-0.4-1.4-0.2-2.2
                c0.2-0.6,0.4-1.2,0.6-1.8c0.2-0.7,0-1.3-0.4-1.8c-0.3-0.3-0.6-0.6-0.9-0.9c-0.8-0.8-1.3-1.7-1.5-2.8c-0.2-0.6-0.4-1.2-0.5-1.8
                c-0.3-1.5-0.8-2.8-1.6-4.1c-1-1.8-1.7-3.7-1.8-5.7c-0.1-1.5-0.4-2.9-1.2-4.2c-0.5-0.7-0.7-1.5-0.9-2.4c-0.4-1.5-1.1-2.7-2.1-3.9
                c-0.2-0.2-0.4-0.5-0.6-0.7c-1-1.3-1.8-2.7-1.9-4.3c-0.2-1.7-0.8-3.3-1.8-4.7c-0.8-1-1.1-2.3-1-3.6c0.2-3.5-1.4-6.1-3.6-8.6
                c-0.5-0.6-1.2-0.9-1.9-1.1c-1.3-0.3-2.7-0.6-4-0.9c-0.2-0.1-0.5-0.6-0.3-0.8c0.1-0.2,0.2-0.4,0.3-0.5c0.5-0.6,1.1-1,1.9-0.9
                c0.3,0,0.6,0.1,0.9,0.1c1.1,0.1,2.1-0.2,2.8-1.2c0.5-0.7,1-1,1.8-1.1c1.1,0,2.1-0.1,3,0.8c0.3,0.3,0.6,0.6,0.9,0.9
                c0.5,0.4,1.3,0.3,1.8-0.1c0.3-0.3,0.6-0.6,0.8-1c0.4-0.5,1-0.8,1.7-0.7c0.8,0.1,1.7,0.1,2.5,0.2c0.5,0,0.9,0.4,1,1
                c0.1,0.4,0,0.9,0.1,1.3c0.2,1.3,0.9,1.8,2.2,1.7c0.5-0.1,1.1-0.2,1.6-0.3c0.7-0.1,1.2,0.1,1.7,0.6c0.4,0.5,0.9,1,1.3,1.4
                c0.2,0.2,0.5,0.4,0.7,0.6c1.1,0.8,2.2,0.6,2.9-0.8c0.2-0.5,0.4-1,0.7-1.4c0.1-0.3,0.6-0.2,0.8,0.1c0.1,0.2,0.2,0.4,0.3,0.6
                c0.2,0.7,0.8,1,1.4,1.1c0.5,0.1,1.2,0.1,1.5,0.4c1.1,0.9,2.1,0.8,3.1-0.1C468.3,344.1,468.6,344,469.1,343.7z"/>
            <path className="st5 state_level" id="Naparima" d="M559.9,712.3c-0.1,1-0.2,2-0.3,2.9c-0.3,0.1-0.6,0.3-0.9,0.3c-0.7,0.1-1.5,0.1-2.2,0.1c-0.3,0-0.6,0.3-0.6,0.6
                c-0.1,0.7-0.1,1.5-0.1,2.2c0,3.5,0,7,0,10.5c0,0.9,0,1.7,0,2.6c0,0.9-0.5,1.3-1.5,1.1c-0.4-0.1-0.7-0.3-0.7-0.5
                c-0.2-0.9-0.6-1.3-1.5-1.3c-1.7,0-3.4,0-5.1,0c-0.4,0-0.9-0.1-1.2-0.4c-0.6-0.6-1.2-0.8-2-0.8c-4.6,0-9.2,0-13.7,0c-1,0-1.9,0-2.9,0
                c-1,0-1.3-0.4-1.3-1.4c0-2.2-0.1-4.5-0.1-6.7c0-3.5,0-7-0.1-10.5c0-0.6-0.1-1.3-0.1-1.9c0-0.4-0.5-0.8-0.8-0.8
                c-0.8-0.1-1.7-0.1-2.5-0.1c-4.7,0-9.4,0-14,0c-0.6,0-1.3-0.1-1.9-0.2c-0.1,0-0.3-0.3-0.3-0.5c0.2-1.1,0.8-1.9,1.6-2.7
                c0.6-0.6,0.6-1.3,0.3-2.1c-0.3-0.7-0.6-1.3-1-2c-0.2-0.5-0.6-0.8-1.1-1.1c-0.9-0.6-0.9-0.7-0.5-1.7c0.2-0.5,0.3-1,0.2-1.5
                c-0.2-0.7-0.7-1.2-1.3-1.2c-0.5,0-1.1,0-1.6,0c-1-0.1-1.6-0.7-1.7-1.6c0-0.2,0-0.4,0-0.6c0.1-1-0.1-1.9-0.6-2.7
                c-0.9-1.3-0.6-2.9-1.1-4.3c-0.1-0.2,0.1-0.6,0.3-0.9c0.2-0.6,0.2-1.2,0.1-1.9c-0.2-1.1-0.1-1.1,0.9-1.6c0.1,0,0.2-0.1,0.3-0.1
                c1.3-0.7,1.4-1.8,0.3-2.7c-0.4-0.3-0.8-0.7-1.2-1c-0.2-0.2,0-0.7,0.2-0.8c0.6-0.2,1.2-0.4,1.8-0.5c0.5-0.8,1.3-0.9,2.2-0.9
                c0.8,0,1.2-0.6,1.3-1.5c0.1-0.6-0.3-1.3,0.2-1.8c0.3,0,0.6,0.1,0.9,0c0.3,0,0.7,0,0.9-0.2c0.7-0.7,1.4-1.6,2.1-2.3
                c0-1.2-0.8-2-1.2-3c-0.1-0.2-0.5-0.4-0.8-0.5c-0.6-0.2-1.1-0.5-1.3-1.2c-0.2-0.9-0.8-1.5-1.5-2c-0.8-0.7-1.6-0.9-2.6-0.5
                c-0.8,0.3-1.6,0.5-2.4,0.7c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.4-0.1-0.6c0-0.2,0.3-0.3,0.3-0.5c0.5-1.4,0.1-2.3-1.2-2.7
                c-0.6-0.2-1.2-0.3-1.7-0.8c-1-1-2.1-1.8-3.5-2c-0.3-0.1-0.7-0.5-0.9-0.8c-0.3-0.3-0.2-0.9,0.2-1.1c0.2-0.1,0.4-0.1,0.6-0.2
                c0.6-0.1,0.9-0.4,1-1c0-0.4,0-0.9,0-1.3c0-2.6,0.1-2.7-2.6-2.7c-0.5,0-1.1-0.1-1.6-0.2c-0.2,0-0.4-0.3-0.4-0.4c0-0.3,0-0.7,0.1-0.9
                c0.7-0.5,1.4-1,2.1-1.4c0.4-0.2,0.8-0.3,1.2-0.4c0.7-0.2,1.1-0.6,1.2-1.3c0.1-0.7-0.1-1.3-0.7-1.7c-0.7-0.5-1.4-0.9-2-1.6
                c-0.5-0.6-1.4-1-2.1-1.4c-0.7-0.3-1.4-0.4-2.2-0.6c0.1-0.3,0.1-0.6,0.2-0.9c0.5-1,1-2.1,1.6-3.1c0.8-1.3,1.2-2.7,1.2-4.3
                c0-2.4-0.1-4.9-0.1-7.3c0-0.8-0.1-1.7,0-2.5c0.1-1.4,0.4-1.6,1.8-1.2c0.2,0.1,0.4,0.2,0.6,0.2c0.9,0.2,2.1-0.4,2.2-1.4
                c0.1-1,0.5-1.8,1.2-2.5c0.4-0.4,0.7-1.1,1-1.6c0.2-0.3,0.9-0.6,1.1-0.4c1.2,0.6,2.4,0.8,3.7,0.7c0.3,0,0.8,0.5,0.8,0.9
                c0,0.3,0,0.6-0.1,0.9c-0.1,1,0.4,1.6,1.2,2.1c0.2,0.1,0.4,0.2,0.6,0.2c1.2,0.2,1.5,1,1.4,2.1c-0.1,0.7,0.4,1.2,1,1.5
                c1.2,0.6,2.8,0.2,3.6-1.1c0.4-0.7,0.8-1.3,1.6-1.3c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.1,0.6-0.3c0.8-1,2-1.5,3.2-2
                c0.6-0.3,1-0.8,1.2-1.4c0.2-0.6,0.4-1.2,1.1-1.6c0.5,0.3,1,0.6,1.6,0.9c0.4,0.1,0.8,0.1,1.2,0c0.8-0.2,1.4-0.7,1.4-1.5
                c0-1,0.4-1.8,1.1-2.5c0.5-0.5,0.4-1.2,0-1.7c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-0.5-0.9,0.3-1.5c0.2-0.1,0.4-0.2,0.5-0.3
                c0.3-0.3,0.7-0.6,0.8-0.9c0.4-1.5,1.6-2.1,2.7-3c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0,0.2,0,0.2,0c0.7,0.6,1.3,1.1,1.9,1.7
                c0.3,0.3,0.6,0.6,0.8,1c0.5,1.1,1.4,1.6,2.6,1.4c1.6-0.2,2.4,1.2,3.6,1.7c0.1,0.1,0.2,0.4,0.2,0.6c0,0.5,0.1,1.1,0.1,1.6
                c0,0.8,0.1,1.5,0.4,2.2c0.4,0.8,0.2,1.4-0.4,2c-1,1.1-0.9,2.3-0.6,3.6c0.1,0.2,0.4,0.5,0.7,0.5c0.5,0.1,1,0.1,1.6,0.2
                c0.2,0.3,0.4,0.5,0.5,0.8c0.3,0.7,0.4,1.4,1.1,1.9c0.2,0.1,0.4,0.5,0.5,0.8c0.3,0.6,0.4,1.2,0,1.8c-0.3,0.5-0.6,0.9-0.9,1.3
                c-0.7,0.9-0.2,1.8,0.5,2.2c1.1,0.7,2.2,1.4,3.5,1.5c0.3,0,0.6,0.2,0.9,0.3c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2,0,0.5-0.2,0.6
                c-0.3,0.2-0.7,0.4-1.1,0.6c-1.5,0.8-2.3,2.2-2.4,3.9c-0.1,1.5-0.4,2.9-1.3,4.2c-0.2,0.3-0.3,0.6-0.3,0.9c0,1.8-1.6,2.3-2.5,3.5
                c-0.1,0.1-0.4,0.2-0.6,0.2c-0.5,0.1-1.1,0.1-1.6,0.1c-1.9,0.1-3.1,1.2-4.1,2.7c-0.2,0.3-0.3,0.6-0.3,0.9c-0.2,1.2-0.5,2.3-0.2,3.5
                c0.2,1.1-0.1,1.8-1,2.4c-0.6,0.4-0.6,1.2-0.4,1.7c0.4,1,1,1.9,1.5,2.8c0.4,0.6,1.6,0.6,2,0c0.3-0.3,0.4-0.7,0.7-1.1
                c0.2-0.2,0.4-0.5,0.7-0.6c0.9-0.4,1.8-0.7,2.6-1c0.1-0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.4,0,0.6c-0.2,0.2-0.5,0.4-0.7,0.6
                c-0.8,0.6-1,1.3-1,2.2c0,0.8,0.4,1.4,1,1.9c0.3,0.3,0.7,0.4,1.1,0.7c0.7,0.5,1.4,0.5,2.1,0.3c0.2-0.1,0.4,0,0.7-0.1
                c0.9,0.3,1.6,1.1,2.2,1.8c1.4,1.5,3.1,2.2,4.8,2.9c0.4,0.2,0.8,0.2,1.2,0.3c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.1,0.3,0.3,0.5,0.6
                c-0.8,1.4-1,3.1-1.9,4.6c-0.6,1-0.8,2.2-1.1,3.2c-0.7,0.6-1.3,1.2-1.9,1.8c-0.4,0.4-0.4,1.1,0,1.5c1.6,1.4,2.1,1.8,0.6,4
                c-0.6,0.9-0.5,1.8,0.1,2.7c0.9,1.5,2.2,2.3,3.9,2.3c0.7,0,1.5,0,2.2,0c1.1,0.1,2.1-0.3,2.9-1.1c0.5-0.5,1.3-0.3,1.7,0.3
                c0.1,0.2,0.3,0.3,0.3,0.5c0.5,1.3,1.5,2,3,1.9c1.1-0.1,2.2,1.1,2.4,2.2c0.1,0.5,0.1,1.1,0.1,1.6c0,4.8,0,9.6,0,14.4
                c0,1.2-0.2,2.3-1.2,3.1C560.5,711.5,560.2,711.9,559.9,712.3z"/>
            <path className="st6 state_level" id="Couva North" d="M450.9,524.1c-1.3-0.4-2.7,0.1-4-0.6c-0.3-0.6-0.5-1.3-0.9-1.9c-0.2-0.4-0.4-0.8-0.7-1c-1-0.6-1.4-1.5-1.8-2.5
                c-0.3-1-1.1-1.3-2-1.3c-0.7,0-1.3,0.1-1.7,0.7c-0.5,0.6-1,1.1-1.8,1.3c-1,0.3-1.6,1-1.8,2c-0.1,0.5-0.2,1-0.4,1.7
                c-1.3,0.6-2.6,1.4-4,1.8c-1.2,0.4-2-0.2-2-1.6c0-0.3,0-0.6,0.1-1c0.1-0.6,0.4-1,0.9-1.2c0.6-0.3,1.2-0.4,1.8-0.7
                c0.6-0.3,1.1-0.7,1.3-1.3c0.4-1,0.9-1.9,1.2-2.9c0.6-1.7,1.5-3.3,2.6-4.7c0.7-0.9,1.4-1.9,2.1-2.8c1.1-1.5,1.8-3.1,2-5
                c0.3-2.7,0.9-5.5,0.9-8.2c0-2.4,0.6-4.6,1.6-6.8c0.4-0.8,0.7-1.5,1.1-2.3c1-1.8,1.7-3.7,2.1-5.7c1.1-4.8,2.8-9.4,5.2-13.7
                c0.6-1.1,1.1-2.3,1.7-3.4c0.6-1.3,1-2.6,1.2-4c0.2-1.2,0.5-2.3,0.7-3.4c0.3-1.2,0.3-2.3,0.1-3.5c-0.3-2.4-0.9-4.8-1.8-7.1
                c-0.4-0.9-0.8-1.7-1.4-2.5c-0.5-0.7-0.9-1.4-1.3-2.2c-0.5-1.1-0.6-2.3-0.3-3.4c0.2-0.8,0.5-1.5,1.3-1.7c2.1-0.6,3.3-2.1,4.2-4
                c0.4-0.9,1.2-1.8,1.8-2.6c0.6-0.9,1-1.9,1-3c0-1.1,0.1-2.1,0-3.2c-0.2-2.7,0.5-5.3,1.4-7.8c0.1-0.4,0.6-0.7,1-0.6
                c1.2,0.2,2.3,0.3,3.5,0.6c0.7,0.1,1.3,0,1.8-0.3c1.1-0.7,2.2-1.3,3.3-2c0.4-0.2,0.7-0.4,1-0.5c0.2,0.1,0.4,0,0.4,0.1
                c1.3,1.3,2.8,2.3,4.3,3.3c10.1,7.1,20.1,14.3,30.2,21.4c1.3,0.9,2.5,2,4.1,2.3c0.2,0,0.5,0.4,0.5,0.7c0.4,1.9,0.7,3.7,0.6,5.7
                c-0.1,3.4-0.1,6.8,0.1,10.2c0.1,3.1,0.7,6.1,1.3,9.1c0.2,1,0.9,2,1,3c0.1,1-0.3,2.1-0.3,3.1c-0.1,1.6,0,3.2,0,4.8
                c0,0.9-0.2,1.7-0.7,2.4c-0.7,1-1.3,2-1.9,3c-0.2,0.3-0.2,0.7-0.5,0.8c-1.3,0.8-1.6,2.2-2.1,3.5c-0.1,0.3-0.7,0.6-1.1,0.4
                c-0.5-0.2-1-0.4-1.4-0.6c-0.6-0.4-1.3-0.8-2.1-0.8c-1.2,0-2.3-0.5-3.2-1.2c-0.8-0.5-1.6-0.5-2.4,0c-0.7,0.5-1.4,0.9-2.1,1.5
                c-2.4,1.9-4.8,3.9-7.4,5.5c-0.4,0.3-0.9,0.6-1.3,1c-1.5,1.4-3.2,2.5-4.9,3.5c-0.8,0.5-1.6,1-2.2,1.8c-0.6,0.9-1.6,1.4-2.5,1.9
                c-1,0.5-1.7,1.2-2.5,1.9c-0.4,0.5-1,0.8-1.5,1.2c-0.7,0.5-1.3,1.2-1.4,2c-0.2,1-0.9,1.7-1.2,2.6c-0.2,0.6-0.7,1-1.3,1.2
                c-0.9,0.3-1.4,0.9-1.5,1.9c-0.1,0.8-0.5,1.4-1,2c-0.4,0.4-0.5,0.9-0.5,1.5c-0.1,0.8,0.3,1.2,1,1.3c0.4,0,0.9,0,1.3,0.1
                c0.6,0.1,1,0.5,1,1.1c0,1.4,0,2.8,0,4.1c0,0.5-0.5,1-1.1,1c-0.9,0-1.7,0-2.6,0c-3,0-6,0-8.9,0c-0.6,0-1.3,0-1.9,0.2
                c-0.3,0.1-0.6,0.3-0.6,0.7c-0.1,1.4-0.2,2.7,0.5,4c0.3,0.5,0.1,1-0.1,1.5c-0.1,0.2-0.5,0.5-0.8,0.4c-0.9-0.3-1.5-1.3-2.5-1.3
                C452.2,525.1,451.5,524.6,450.9,524.1z"/>
            <path className="st3 state_level" id="Fyzabad" d="M422.5,694.6c1,0.5,2.2,0.9,3.1,1.6c0.9,0.7,1.5,1.8,2.2,2.7c0.4,0.1,0.8,0.3,1.3,0.4c-0.1,0.3-0.2,0.7-0.4,0.8
                c-0.7,0.6-1.5,1.2-2.2,1.8c-0.4,0.4-0.4,0.9,0,1.4c0.5,0.4,0.9,0.9,1.4,1.3c0.3,0.3,0.7,0.5,1,0.8c0.6,0.7,1.3,1.4,1.7,2.3
                c0.4,0.9,0,1.9-0.1,2.8c-0.1,0.7,0.1,1.3,0.6,1.7c0.7,0.5,0.9,1.1,0.9,1.9c0,1.3,0,2.6,0,3.8c0,0.9-0.4,1.3-1.4,1.3
                c-1.1,0-2.1,0-3.2,0c-0.8,0-1.4,0.2-2,0.8c-1.6,1.7-3.3,3.3-5,5c-1.6,1.6-3.1,3.2-4.9,4.6c-1.1,0.8-1.5,2-1.4,3.4
                c0,0.4,0.1,0.8,0.6,1.1c0.6,0.4,1.2,0.8,1.6,1.5c0.4,0.6,1,0.7,1.6,0.8c0.6,0.1,1-0.1,1.5-0.4c0.5-0.4,1-0.4,1.4,0
                c0.9,0.8,1.8,0.9,3,0.8c1.6-0.2,1.6-0.1,2.4,1.3c0.3,0.7,0.7,1.3,1.1,1.9c0.5,0.7,1.3,1.1,2.1,1.3c0.5,0.1,1.1,0.1,1.6,0.1
                c1.5,0.1,2.4-0.1,2.8-1.9c0.1-0.6,0.4-1,1-1.2c0.8-0.3,1.6-0.7,2.3-1.1c1-0.6,1.8,0,1.9,1.2c0,0.2,0,0.4,0,0.6
                c0,0.7-0.1,1.3-0.8,1.6c-0.8,0.5-1.2,1.1-1.2,2.1c0,0.5-0.1,1-0.4,1.5c-0.4,0.6-0.8,0.8-1.3,0.6c-0.3-0.1-0.6-0.3-0.9-0.4
                c-0.7-0.2-1.3,0-1.8,0.4c-0.3,0.3-0.6,0.7-0.7,1c-0.1,0.5,0,1.1,0,1.6c-0.1,1-0.6,1.6-1.6,1.6c-0.2,0-0.4-0.1-0.6,0
                c-1.5,0.2-2.4,1.2-2.1,2.6c0.2,1.1,0,1.9-0.8,2.6c-0.6,0.6-0.8,1.2-0.8,2c0,2-0.2,4-0.7,6c-0.2,0.9,0,1.7,0.8,2.3
                c0.7,0.5,0.8,1.2,1,1.9c0.1,0.6,0.7,1,1,1.5c0.1,0.2,0,0.5-0.3,0.5c-0.9,0-1.9,0-2.8,0c-0.4,0-0.9,0.1-1.3,0.1
                c-0.6,0.1-1.4,1-1.3,1.6c0,0.5,0.2,1.1,0.2,1.6c0.1,1.1-0.7,1.9-1.7,1.8c-0.4,0-0.8-0.1-1.3-0.1c-1-0.1-1.9,0.1-2.6,0.9
                c-0.2,0.2-0.6,0.3-0.9,0.4c-0.2,0.1-0.7-0.2-0.7-0.4c0-0.8,0.1-1.7,0.1-2.5c0-0.4-0.1-0.8-0.2-1.3c-0.2-0.8-1.3-1.3-2-1
                c-0.4,0.2-0.8,0.4-1.1,0.6c-0.5,0.4-0.9,0.9-1.5,1.2c-1.3,0.6-1.9,1.6-2.3,2.9c-0.1,0.3-0.1,0.6-0.3,0.9c-0.2,0.5-0.6,0.9-1.1,1.1
                c-0.3,0.1-0.6,0.3-0.9,0.4c-1.7,0.5-3.2,1.2-4.3,2.6c-1.1,0.4-1.3,1.3-1.2,2.3c0,0.3-0.1,0.7-0.3,0.8c-0.6,0.5-1.2,0.9-1.8,1.3
                c-0.2,0.1-0.5,0.1-0.6,0c-0.5-0.3-1-0.8-1.6-1.1c-1.1-0.7-2.2-0.6-3.2,0.4c-0.3,0.3-0.5,0.7-0.8,1c-0.4,0.6-0.8,0.9-1.5,1.2
                c-0.7,0.3-1.4,0.6-1.8,1.3c-0.2,0.4-0.4,0.8-0.1,1.2c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.5,0.7,1.1,0.1,1.7c-0.5,0.5-1.2,0.6-1.8,0.3
                c-0.7-0.3-1.4-0.4-2.2-0.4c-0.8,0-1.4-0.3-1.9-1c-0.3-0.5-1-0.4-1.5-0.5c-0.4-0.1-0.7-0.7-0.5-1c0.5-1.2,1-2.4,1.4-3.5
                c0.2-0.5,0.4-1,0.5-1.5c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.5-0.4-0.8-0.5c-3.3-1.6-6.7-3.2-10-4.9c-0.8-0.4-1.6-0.6-2.5-0.6
                c-1.4,0-2.8,0-4.1-0.1c-0.4,0-0.8-0.4-0.8-0.9c0-0.7-0.1-1.5-0.1-2.2c0-11.4,0-22.8,0-34.2c0-0.8,0-1.7,0.1-2.3
                c0.4-0.7,0.8-0.7,1.2-0.7c1.2,0,2.3-0.4,3.1-1.3c0.1-0.2,0.3-0.3,0.5-0.4c1.4-0.9,1.6-2.2,1.3-3.6c0-0.2-0.1-0.4-0.2-0.6
                c-0.4-0.8-0.4-1.6,0.1-2.4c0.2-0.4,0.4-0.8,0.4-1.2c0.1-1,0-1.8-0.7-2.7c-1-1.3-2.2-2.3-3.6-3.1c-0.4-1-0.2-2-0.1-2.9
                c0-0.6,0.6-1.2,1.2-1.3c0.5-0.1,1.1-0.1,1.6-0.3c0.6-0.2,1.2-0.4,1.8-0.7c0.9-0.5,1.4-1.3,1.5-2.3c0-0.5,0-1.1,0.2-1.6
                c0.1-0.3,0.3-0.6,0.5-0.8c1-0.7,2.1-1.4,3.2-2.1c0.4-0.3,0.9-0.6,1.2-1c0.5-0.6,0.8-1.3,0.4-2.1c-0.6-1.1-0.9-2.4-1.9-3.3
                c-0.2-0.2-0.1-0.6,0.2-0.8c0.4-0.2,0.8-0.4,1.1-0.6c0.5-0.4,0.6-1.6,0.1-2c-0.6-0.5-1.2-0.9-1.8-1.3c-0.2-0.1-0.1-0.7,0.2-0.8
                c0.4-0.1,0.8-0.2,1.2-0.4c1-0.3,1.4-1,1-2c-0.2-0.5-0.4-1-0.6-1.5c-0.1-0.2,0.1-0.4,0.3-0.4c1.2,0.1,2.3,0.1,3.5,0.3
                c2.3,0.4,4.7,0.5,7,0.5c2.6,0,5.1-0.1,7.7-0.1c2.2-0.1,4.4-0.5,6.5-1.4c0.5-0.2,1-0.4,1.5-0.5c1.4-0.4,2.8-0.9,4.2-1.6
                c1.1-0.5,1.4-0.4,2.2,0.5c1.1,1.2,2.1,2.4,3.1,3.6c0.4,0.5,1,0.7,1.7,0.8c0.4,0,0.8-0.1,1.3,0c0.4,0,0.9,0,1.2,0.2
                c1.3,0.9,2.6,0.7,4,0.4C422.3,694.6,422.4,694.6,422.5,694.6z"/>
            <path 
                className="st4 state_level" 
                id="TT-TUP" 
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-TUP') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-TUP') ? this.callMapData('TT-TUP') : null}
                d="M507.6,313.5c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.2-0.6,1.1-1.3c-0.1-0.6-0.3-1.2-0.5-1.9c0.1-0.1,0.3-0.4,0.5-0.6
                c0.9-0.7,1-1,0.7-1.9c-0.4-0.9-1-1.5-1.9-1.5c-0.4,0-0.8,0.2-1.2,0.2c-0.3,0-0.7-0.1-0.8-0.4c-0.1-0.3-0.1-0.8,0.1-0.9
                c1.5-0.6,1.5-2.2,2.2-3.4c0.5-0.8,0.4-1.6-0.3-2.3c-0.2-0.2-0.4-0.5-0.5-0.7c0-0.3,0-0.5,0.1-0.7c0.5-1.2,0.6-2.4-0.2-3.6
                c-0.7-1.1-0.5-2.4,0.2-3.3c0.9-1.2,1-2.5,0.8-3.9c0-0.1,0-0.2,0.1-0.3c0.3-0.6,0.9-0.9,1.4-1.3c0.3-0.3,0.7-0.6,0.8-1
                c0.3-1.3,1.1-2.5,1.6-3.8c-0.3-0.6-0.7-1.1-1-1.7c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.4,0.5-0.8,0.9-0.7c0.3,0.1,0.6,0.2,0.9,0.4
                c0.7,0.3,1.5,0.4,2.1-0.2c0.7-0.6,1.4-0.9,2.3-0.9c0.2,0,0.4-0.1,0.6-0.2c1-0.3,1.6-1.2,1.5-2.2c-0.2-1.1-0.5-2.3-0.2-3.4
                c0-0.1,0-0.2,0-0.3c-0.2-0.7-0.4-1.4-0.4-2.2c0-0.6,0.5-1.2,0.6-1.8c0.1-0.3,0-0.6-0.2-0.9c-0.1-0.3-0.4-0.5-0.6-0.7
                c-1.3-1.5-1.3-2.9,0.2-4.4c0.9-0.9,1.6-2,2.9-2.5c0.5-0.2,0.8-0.7,0.8-1.3c0-0.9,0.4-1.6,1.2-2.1c0.6-0.5,0.8-1.3,0.5-2
                c-0.3-0.8-0.3-1.6,0.1-2.5c0.2-0.4,0.2-0.8,0.3-1.2c0.1-0.8,0-1.5-0.4-2.1c-0.3-0.5-0.7-1.1-0.9-1.6c-0.3-0.7,0.2-1.6,1-1.6
                c0.1,0,0.2,0,0.3,0c2.1,0.5,3.7-0.6,5-2c1.4-1.5,2.9-2.8,3.8-4.7c0.5-0.9,1.3-1.7,1.9-2.5c0.3-0.4,0.7-0.5,1.1-0.4
                c1,0.1,1.9,0.1,2.8,0.6c0.6,0.3,1.2,0.6,1.8,0.6c1.2,0.1,2.2,0.7,3.3,1.1c1.2,0.5,2.2,1.1,2.9,2.3c0.3,0.5,0.8,0.7,1.4,0.7
                c0.6,0,1.3,0,1.9,0c1.2-0.1,2.1,0.4,2.7,1.4c0.9,1.8,2.4,1.9,4.1,1.7c0.7-0.1,1.2-0.5,1.5-1c0.3-0.4,0.4-1,0.7-1.4
                c0.3-0.6,1.4-0.8,2-0.3c0.2,0.2,0.5,0.4,0.7,0.7c0.6,0.7,1.3,1.5,1.7,2.3c0.6,1,1.4,1.5,2.5,1.7c0.5,0.1,1,0.2,1.5,0.3
                c0.6,0.9,1.1,1.7,1.6,2.4c0.5,0.7,1.2,1.2,2.1,1.3c0.9,0.2,1.9,0.4,2.8,0.7c0.4,0.1,0.8,0.4,1.1,0.6c0.4,0.7,0.4,1.5,0.3,2.2
                c-0.2,1.6,0.3,3.2,0.6,4.7c0.3,1.4,1.1,2.5,2,3.6c0.3,0.4,0.9,0.5,1.4,0.8c0.3,0.2,0.5,0.4,0.8,0.6c0.1,0.1,0.1,0.2,0.1,0.3
                c-0.1,0.1-0.2,0.3-0.4,0.4c-0.5,0.1-1-0.1-1.5-0.3c-0.7-0.3-1.5-0.2-2.2,0.1c-1.2,0.5-2.3,1.2-2.6,2.6c-0.2,0.7-0.6,1.4-1,2
                c-0.4,0.6-0.4,1.2-0.1,1.8c0.2,0.5,0.5,1,0.7,1.4c0.2,0.4,0,0.7-0.4,1.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.9,0.3-1.1,1-1.1,1.7
                c-0.1,0.7,0.3,1.3,0.9,1.5c0.3,0.1,0.6,0.1,0.9,0.2c0.6,0.2,0.7,0.6,0.4,1.3c-0.2,0.5-0.5,0.9-0.7,1.4c-0.3,0.8,0,1.8,0.7,2.3
                c0.5,0.4,1.1,0.6,1.6,1c0.5,0.3,0.8,0.7,0.9,1.2c0.2,0.6,0.2,1.1-0.2,1.5c-1.5,1.2-2.4,2.9-4.1,4c-0.5,0.4-0.7,1-0.7,1.7
                c0,0.6-0.1,1.3-0.2,1.9c0,0.2-0.2,0.4-0.3,0.7c-0.4,0.1-0.8,0.2-1.2,0.3c-0.7,0.2-1.1,0.6-1.2,1.3c-0.1,0.6,0,1.3,0,1.9
                c0,0.7,0.2,1.3,0.6,1.8c0.4,0.5,0.8,1,0.9,1.6c0.1,1.3,0.8,2.1,2,2.6c0.2,0.1,0.1,0.7-0.1,0.8c-0.4,0.2-0.8,0.4-1.2,0.4
                c-0.3,0.1-0.6,0-0.9-0.1c-0.6-0.1-1.3-0.2-1.9-0.3c-1.2-0.1-2.3,0-3.3,0.9c-0.9,0.8-1.9,0.8-3,0.6c-0.7-0.2-1.4-0.4-2.1-0.6
                c-0.4-0.1-1.1,0.3-1.3,0.7c-0.1,0.3-0.3,0.6-0.3,0.9c-0.1,0.6-0.4,0.9-1,1.1c-0.1,0-0.2,0.1-0.3,0.1c-1.7,0.8-1.8,1.4-0.6,2.9
                c0.2,0.2,0.4,0.5,0.6,0.7c0.4,0.4,0.5,0.9,0.6,1.5c0,0.3,0,0.6,0,1c0.1,1.2-0.3,2.3-1.1,3.2c-0.5,0.6-0.9,1.1-1,1.9
                c-0.1,1.6-1.4,2-2.6,2.4c-0.3,0.1-0.6,0-0.9-0.1c-0.5-0.1-1-0.4-1.5-0.5c-1.3-0.3-2.2,0.3-2.6,1.6c-0.1,0.2,0,0.5-0.2,0.6
                c-0.2,0.2-0.5,0.5-0.7,0.5c-0.6,0-1.3-0.2-1.9-0.3c-1.6-0.3-3.1-0.7-4.7-0.9c-1.8-0.3-3.6-0.3-5.4-0.2c-0.8,0-1.5,0.3-2.1,0.6
                c-1.1,0.5-2.2,0.7-3.4,0.5c-1.5-0.2-3-0.1-4.4,0.1c-1.5,0.2-3,0.1-4.5,0.2c-1.3,0-2.6-0.3-3.6-1.2c-2-1.7-4.4-2.5-6.9-3
                c-0.6-0.1-1.3-0.2-1.9-0.4c-0.4-0.1-0.8-0.3-1.1-0.5c-0.4-0.2-0.5-0.7-0.3-1.1c0-0.1,0.1-0.2,0.2-0.3
                C507.3,314.1,507.4,313.8,507.6,313.5z"/>
            <path className="st5 state_level" id="Caroni East" d="M535.5,379.4c0-1.9,0.3-3.7,0.9-5.4c0.6-1.9,0.7-3.7-0.1-5.6c-0.6-1.6-0.9-3.3-0.7-5c0.1-0.7,0-1.5,0-2.2
                c0-1.7,0.1-2,2-2c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.6,0.1-1.6,0.7-2.1
                c0.7-0.6,1.5-1.1,2.3-1.6c0.5-0.3,1.4,0.1,1.5,0.7c0.1,0.8,0.1,1.7,0.1,2.5c0,0.7,1,1.3,1.7,1.1c0.7-0.2,1.4-0.5,2.1-0.8
                c0.4-0.1,1.2,0.3,1.2,0.8c0.1,0.5,0,1,0,1.6c0,0.7,0.4,1.3,1,1.5c0.5,0.2,1,0.3,1.5,0.4c0.5,0.2,0.7,0.5,0.6,1.2
                c-0.1,0.8,0.3,1.1,0.8,1.6c0.6,0.5,0.6,0.7,0.3,1.3c0,0.1-0.1,0.2-0.2,0.3c-0.6,1.4-0.1,2.3,1.4,2.4c0.6,0,1.3,0,2.1,0
                c0.3,0.3,0.6,0.7,1,1.1c0.2,0.2,0.5,0.5,0.8,0.6c1.1,0.2,1.9,0.8,2.8,1.3c0.5,0.3,1.4-0.1,1.6-0.6c0.2-0.6,0.3-1.2,0.4-1.8
                c0-0.1,0.3-0.3,0.4-0.3c0.2,0,0.4,0.1,0.5,0.3c0.6,0.6,0.3,1.4,0.6,2.1c0.3,0.7,0.7,1.1,1.4,1.1c0.2,0,0.4,0,0.6,0
                c0.3,0,0.6,0.1,1,0.1c0.2,0.3,0.4,0.7,0.6,1.1c0.4,0.6,0.9,0.9,1.6,0.9c0.7,0,1.5-0.1,2.2-0.1c0.2,0.2,0.4,0.3,0.5,0.4
                c1,1.5,1.4,1.7,3.1,1.2c0.5-0.2,1-0.5,1.5-0.2c1.8,0.8,3.3,0.4,4.6-1c0.2-0.2,0.7,0,0.8,0.2c0.2,0.6,0.3,1.3,0.8,1.7
                c0.5,0.4,1,0.7,1.5,1c0,0.7-0.6,0.9-0.8,1.3c-0.4,0.8-0.5,1.6,0.2,2.4c0.1,0.2,0.3,0.3,0.5,0.4c0.6,0.5,0.7,1.1,0.6,1.7
                c-0.1,0.5-0.3,1-0.4,1.5c-0.2,0.6-0.4,1.2-0.4,1.9c-0.1,1.1,0,2.1,0.9,3c0.5,0.5,0.7,1.1,0.6,1.8c-0.1,1.7,0.2,3.4,0.8,5
                c0.4,1.1,0.6,2.3,0.4,3.5c-0.3,2.6,0.2,5.1,0.7,7.6c0.1,0.5,0.5,0.9,0.8,1.4c0.3,0.3,0.6,0.6,1,0.8c0.7,0.5,1,1.2,0.7,2
                c-0.5,1.5-0.7,3.1-0.6,4.7c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.5-1.2,0.9-1.6,0.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.4-0.4-0.7-0.8-1-1.2
                c-0.5-0.5-1.5-0.4-2,0.2c-0.3,0.4-0.5,0.9-0.5,1.5c0,0.2,0,0.4,0.1,0.6c0.2,1.1,0.7,2.1,1.9,2.3c1.9,0.4,2.9,1.8,3.3,3.5
                c0.2,1,0.6,1.8,1.2,2.6c0.4,0.6,0.5,1.2,0.2,1.8c-0.6,1.1-0.6,2.2-0.5,3.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.3-0.5,0.3
                c-0.2,0-0.5,0-0.6-0.1c-0.3-0.7-0.5-1.4-0.8-2c-0.4-0.9-1-1.3-1.9-1.5c-0.5-0.1-1.1,0.1-1.4,0.5c-0.4,0.5-0.6,1.1-0.6,1.8
                c0,0.6,0.3,1.3,0.2,1.9c-0.2,1.4,0.2,2.5,1.4,3.3c0.3,0.2,0.4,0.7,0.6,1.1c-0.4,0.6-0.7,1.2-1,1.7c-0.2,0.3-0.3,0.6-0.3,0.9
                c-0.2,1.4-0.3,2.7-0.4,4.1c0,0.3,0,0.7,0.2,0.9c0.6,1,0.6,2,0.5,3.1c-0.1,1.2,0,2.3,0.5,3.4c0.2,0.3-0.3,0.9-0.7,0.9
                c-0.5,0.1-1.1,0.1-1.6-0.1c-0.6-0.3-1.1-0.6-1.7-0.9c-0.6-0.3-1.2-0.3-1.9-0.2c-1,0.2-1.5,1.5-0.9,2.2c0.1,0.2,0.3,0.3,0.5,0.4
                c1.5,1.4,1.5,1.4,0,2.4c-0.3,0.2-0.6,0.3-0.8,0.6c-0.6,0.9-1.3,1.7-2,2.4c-0.4,0.4-0.4,1.2,0.1,1.7c1,1.1,1.3,2.4,1.4,3.8
                c0,0.4-0.4,0.8-0.7,0.9c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.5,0.5-2,1.3c-0.3,0.6-0.9,0.6-1.3,0.3c-0.1-0.1-0.2-0.1-0.3-0.2
                c-0.7-0.7-1.6-0.9-2.6-0.8c-0.5,0.1-1.1,0.2-1.6,0.2c-0.7,0-1.2-0.1-1.7-0.7c-0.8-0.9-2.2-1.3-2.8-2.5c0-0.1-0.2-0.1-0.2-0.2
                c-0.6-0.6-1.8-0.2-2.3-0.9c-0.4-0.6-0.4-1.4-0.7-2.1c-0.1-0.3-0.4-0.6-0.6-0.7c-1-0.5-1.3-1.3-1.5-2.3c-0.2-0.9-1.2-1.1-2.1-0.8
                c-0.5,0.2-0.9,0.6-1.3,0.8c-0.9,0.5-1.7,0.4-2.3-0.4c-0.3-0.3-0.5-0.7-0.7-1c-0.6-0.8-1.3-1.4-2.4-1.4c-0.5,0-0.9-0.3-1-0.7
                c-0.3-1-1-1.6-1.9-2.1c-0.5-0.3-0.8-0.7-0.9-1.2c-0.3-1.4-1.2-2.4-2.1-3.5c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.3,0.1-0.6,0.1-1
                c-0.1-0.9-0.6-1.6-1.5-1.8c-1.7-0.2-2.8-1.3-3.9-2.5c-0.1-0.1-0.2-0.4-0.3-0.5c0.3-0.6,0.6-1.1,0.8-1.7c0.3-0.7-0.3-1.6-1-1.6
                c-0.7-0.1-1.5-0.1-2.2-0.1c-0.6,0-0.9-0.4-1-1c0-0.5,0-1.1-0.1-1.6c-0.1-1.1-0.4-2.1-1.2-2.9c-0.2-0.2-0.4-0.5-0.6-0.7
                c-0.9-0.9-1.1-2-1-3.2c0.1-1,0.5-1.5,1.5-1.8c0.2-0.1,0.4-0.1,0.6-0.1c1.2-0.4,2-1.2,2.3-2.4c0.5-1.8,0.9-3.7,1.1-5.6
                c0-0.4,0.1-0.8,0.1-1.3c0-1.6,0.8-2.8,1.9-3.9c0.5-0.4,1-0.8,1.5-1.2c0.5-0.5,1.1-1.1,1.2-1.8c0.2-1.2,0.7-2.2,1.3-3.2
                c0.4-0.7,0.5-1.4,0.5-2.1c0-0.4,0-0.9-0.1-1.3c-0.5-2-0.5-4-0.5-6c0-2.3-0.1-4.7-0.1-7c0.1-2-0.6-3.5-1.9-4.9
                c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.7-0.6-0.8-1C538.2,381.8,536.8,380.6,535.5,379.4z"/>
            <path 
                className="st5 state_level" 
                id="TT-PED"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-PED') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-PED') ? this.callMapData('TT-PED') : null}
                d="M429.9,767.9c-0.7-0.5-1.6-1-1.7-1.8c-0.1-1-0.7-1.5-1.3-2.1c-0.5-0.5-0.7-1.1-0.5-1.7c0.6-1.5,0.5-3.1,0.6-4.7
                c0-1.1,0.2-2.1,1.1-2.9c0.7-0.6,0.9-1.4,0.8-2.3c0-0.4,0-0.8,0-1.3c0-0.3,0.5-0.8,0.8-0.9c0.3,0,0.6-0.1,0.9-0.1
                c1.3,0.1,2.2-0.7,2.5-2.4c0.1-0.5,0-1.1,0.1-1.6c0-0.4,0.6-0.7,1-0.6c0.3,0.1,0.6,0.3,0.8,0.4c0.8,0.4,1.5,0.3,2-0.3
                c0.6-0.8,1-1.6,1.1-2.6c0-0.7,0.4-1.2,1-1.5c0.9-0.6,1.2-1.4,1.1-2.5c0-0.3,0-0.6-0.1-1c-0.2-1.7-1.7-2.6-3.2-1.7
                c-0.5,0.3-0.9,0.7-1.4,0.8c-1.5,0.3-2.2,1.1-2.6,2.5c-0.1,0.2-0.2,0.4-0.4,0.7c-0.5,0-1.2,0.1-1.8,0.1c-1.5,0.2-2.4-0.6-3.1-1.8
                c-0.4-0.6-0.6-1.3-1-2c-0.3-0.6-0.9-0.9-1.6-0.9c-0.2,0-0.4,0-0.6,0c-1.2,0.3-2.3,0-3.2-0.8c-0.6-0.5-1.4-0.5-2.1-0.1
                c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.1-0.9,0.1-1.2-0.2c-0.5-0.4-0.9-0.9-1.3-1.4c-0.9-0.9-1.1-1.7-0.3-2.7c0.1-0.2,0.2-0.4,0.4-0.5
                c3.4-2.7,6.1-5.9,9.2-8.9c0.2-0.2,0.5-0.4,0.7-0.7c0.6-0.7,1.4-0.9,2.3-0.9c1.2,0,2.3,0,3.5,0c1,0,1.3-0.3,1.3-1.4
                c0-1.7,0-3.4,0-5.1c0-0.8-0.3-1.4-0.9-1.9c-0.6-0.4-0.6-1.1-0.5-1.7c0.1-0.3,0.1-0.6,0.2-0.9c0.2-1,0-1.9-0.7-2.7
                c-1-1.1-1.9-2.3-3.1-3.1c-0.4-0.2-0.3-0.8,0-1.1c0.6-0.5,1.4-1,1.9-1.6c0.3-0.3,0.3-0.8,0.4-1.2c0.1-0.5-0.2-0.9-0.6-1
                c-0.4-0.1-0.8-0.2-1.2-0.3c-0.2-0.3-0.5-0.5-0.6-0.7c-0.9-1.9-2.7-2.6-4.5-3.4c-0.6-0.3-1.2-0.2-1.9-0.1c-0.6,0.1-1.3,0-1.9,0
                c-0.9-0.9-2-1-3.2-0.6c-0.5,0.1-1.1-0.1-1.4-0.5c-1.3-1.4-2.6-2.8-3.7-4.4c-0.1-0.1,0-0.2-0.1-0.4c0.3-0.2,0.6-0.5,0.9-0.8
                c3.2-2.3,6.5-4.4,10.1-6.2c2.4-1.2,4.8-2.3,7.2-3.4c0.4-0.2,0.8-0.1,1.2,0.1c1.3,0.6,2.6,0.7,4,0.3c0.6-0.2,1.2-0.3,1.8-0.5
                c1.6,0.7,2.8-0.2,4-1.2c0.3-0.3,0.8-0.4,1.1-0.6c0.5-0.3,1,0,1.2,0.6c0.1,0.3,0.1,0.6,0.2,0.9c0.3,1,1.4,1.4,2.3,1
                c0.2-0.1,0.4-0.2,0.6-0.3c0.7-0.5,1.3-1.1,2.1-1.7c0-0.5,0-1,0-1.5c0-0.4,0.4-0.9,0.7-0.9c0.4-0.1,0.8-0.2,1.3-0.1
                c1.2,0.1,2.1-0.4,2.7-1.3c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.3,0.3,0.3,0.4c0.1,0.5,0.1,1.1,0.3,1.5
                c0.7,1.5,2,2.1,3.7,1.8c1.2-0.2,2.3-0.3,3.5-0.4c0.6-0.1,1.2,0.5,1.2,1.2c0,0.4,0.1,0.9,0,1.3c-0.1,1.6-0.3,3.2-0.1,4.8
                c0,0.1-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4-0.2-0.7-0.4-1-0.7c-0.8-0.8-1.8-1.2-2.9-1.3c-0.5,0-1.1,0.1-1.5,0.4
                c-0.8,0.4-1,1.6-0.5,2.2c0.2,0.3,0.4,0.6,0.6,0.7c0.8,0.4,1.2,1.2,1.4,2c0.2,0.7,0.7,1.1,1.4,1.2c0.8,0.1,1.3,0.6,1.7,1.3
                c0.4,0.9,1.2,1.5,2.2,1.6c1.1,0.1,1.5,0.6,1.6,1.6c0.1,0.7,0.6,1.1,1.1,1.5c0.5,0.4,0.5,0.7,0.1,1.3c-0.2,0.3-0.6,0.6-0.7,1
                c-0.2,0.8-0.7,1.2-1.4,1.6c-0.2,0.1-0.3,0.3-0.5,0.4c-0.5,0.5-0.6,1.5-0.1,2c0.3,0.3,0.6,0.6,0.9,0.9c0.5,0.4,1,0.7,1.5,1.1
                c-0.1,0.4,0,0.7-0.2,1c-0.3,0.7-0.2,1.2,0.2,1.8c0.2,0.2,0.4,0.5,0.6,0.7c0.3,0.4,0.5,0.9,0.3,1.5c-0.1,0.3-0.2,0.6-0.3,0.9
                c-0.2,0.9,0,1.7,0.7,2.3c0.5,0.4,0.9,0.9,1.3,1.4c0.4,0.4,0.3,1-0.1,1.4c-0.5,0.5-1.1,1-1.7,1.5c-0.9,0.7-1.4,1.5-1.3,2.7
                c0.1,0.8-0.1,1.5-0.4,2.2c-0.3,0.6-0.3,1.2-0.4,1.8c-0.2,1.4,0.3,2.5,1.4,3.3c2.5,2,5,4,7.5,6c0.5,0.4,1,0.8,1.5,1.2
                c0.8,0.6,1,1.3,0.7,2.3c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0.1,0.7,0.2,0.9c0.9,1.1,1,2.3,0.9,3.6c0,0.4,0,0.8-0.1,1.3
                c0,0.6-0.4,1-1,1.1c-0.4,0-0.9,0-1.3,0c-1,0.1-1.6,0.6-1.9,1.5c-0.2,0.5-0.3,1-0.4,1.4c-0.3,0.2-0.4,0.4-0.6,0.4
                c-1.3,0.4-2.2,1.2-2.3,2.7c0,0.4-0.6,0.6-1,0.4c-0.5-0.2-0.9-0.5-1.4-0.8c-0.2-0.1-0.4-0.1-0.6-0.1c-0.5-0.1-1.2,0.4-1.2,0.8
                c-0.1,0.7-0.2,1.5-0.2,2.2c0,3.2,0,6.4,0,9.6c0,0.8-0.2,1.4-0.9,1.9c-0.9,0.7-1.1,1.7-1,2.9c0.1,1.4-0.8,2.3-1.8,3.1
                c-0.2,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.7-0.3-0.7-0.5c0-1-0.1-1.9-0.1-2.9c0-0.9,0-1.7,0-2.6c0-1.2-0.5-1.3-1.4-1.3
                c-0.7,0-1.5,0-2.2-0.1c-1.2,0-2.1,0.4-2.6,1.5c-0.2,0.5-0.5,0.9-0.8,1.4c-0.6,1.2-1.3,1.3-2.3,0.6c-0.3-0.2-0.5-0.4-0.8-0.5
                c-0.4-0.1-0.8-0.1-1.2,0.1c-0.3,0.2-0.5,0.4-0.8,0.5c-0.5,0.4-1,0.4-1.5,0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-1.3-1-2.7-0.4-3.7,0.6
                c-0.3,0.3-0.5,0.7-0.8,1c-1,1.2-2,1.5-3.3,0.6c-1.3-0.8-2.4-0.7-3.6-0.1c-0.7,0.3-1.3,0.7-2,1.1
                C430.8,767.1,430.3,767.5,429.9,767.9z"/>
            <path className="st6 state_level" id="Couva South" d="M492.3,549.9c-0.2-0.6-0.4-1.1-0.6-1.5c-0.2-0.3-0.8-0.5-1.1-0.3c-0.8,0.7-1.7,1.2-2.4,2
                c-0.7,0.8-1.6,1.4-2.4,2.1c-1.7,1.5-3.7,1.4-5.7,0.9c-1.2-0.8-2.4-0.5-3.6-0.1c-1.4,0.5-2.9,0.7-4.3,1c-0.2,0-0.4,0.1-0.6,0
                c-2-0.9-4.4-0.9-6.4-2.3c-0.3-0.4-0.5-1-1-1.5c-0.7-0.7-1.4-1.2-2.2-1.8c-0.8-0.5-1.6-0.4-2.5-0.1c-0.3,0.1-0.6,0.4-0.7,0.6
                c-0.4,1-0.8,2-1.1,3c-0.3,1-0.4,2.1-0.6,3.1c-0.1,0.7-0.3,1.2-0.9,1.6c-0.5,0.4-0.9,1-0.9,1.6c-0.1,1-0.9,1.6-1.2,2.6
                c-0.6,0.1-1.1,0.2-1.7,0.2c-1.8-0.1-3.6,0.2-5.4-0.2c-0.1,0-0.2-0.1-0.4-0.2c-0.1-0.7-0.1-1.4-0.2-2.2c-0.1-0.8-0.5-1.2-1.2-1.2
                c-1,0-1.9,0-2.9,0c-1.2,0-2.3,0-3.5,0c-0.8,0-1.1-0.2-1-0.5c0.2-0.5,0.6-0.6,1-0.7c1-0.2,2.1-0.4,3.1-0.5c0.8-0.1,1.7-0.2,2.5-0.3
                c0.7-0.1,1.2-0.5,1.5-1.1c0.9-1.8,1.7-3.7,2-5.7c0.1-0.4,0-0.8,0-1.3c-0.1-1.7-1-2.9-2.9-3.3c-0.9-0.2-1.9-0.1-2.9-0.1
                c-0.8,0-1.7,0-2.5,0c-0.6,0-1.2-0.7-1.3-1.2c-0.1-0.7-0.2-1.5-0.2-2.2c0-1.6-0.6-2.9-1.7-4c-1.8-1.9-1.6-2.2-0.5-4.5
                c0.5-1,1.2-2,1.8-3c0.6-0.9,1-1.8,1.3-2.9c0.2-1,0-1.9-0.7-2.6c-0.2-0.1-0.3-0.3-0.5-0.4c-0.7-0.3-0.9-0.8-0.9-1.5
                c0.1-0.8,0.4-1.5,1.2-1.7c1.1-0.3,1.8-1.1,2.5-1.8c0.4-0.4,1.5-0.2,1.7,0.3c0.2,0.5,0.4,1,0.6,1.5c0.1,0.3,0.2,0.7,0.5,0.8
                c1.5,0.8,1.7,2.3,2.5,3.6c0.1,0.2,0.4,0.4,0.7,0.6c0.3,0.1,0.6,0.1,0.9,0.2c0.5,0.1,1.1,0.1,1.6,0.1c0.8,0,1.4,0.3,2,0.8
                c1,1,2.5,1.2,3.6,2c0.3,0.2,0.8,0.1,1.2,0c0.3-0.1,0.6-0.3,0.7-0.5c0.4-0.9,0.7-1.8,0.2-2.7c-0.5-0.9-0.7-1.8-0.5-2.8
                c0-0.3,0.5-0.8,0.8-0.8c0.7,0,1.5-0.1,2.2-0.1c3.1,0,6.2,0,9.3,0c0.5,0,1.1,0,1.6,0c0.6,0,1-0.4,1-1c0-1.9,0-3.8,0-5.7
                c0-0.1,0-0.2,0-0.3c0-0.4-0.4-0.7-0.8-0.8c-0.4-0.1-0.8-0.1-1.3-0.1c-0.9-0.1-1.1-0.6-0.7-1.3c0.4-0.6,0.9-1.2,1-2
                c0.2-0.9,0.6-1.5,1.6-1.7c0.5-0.1,1-0.5,1.1-1.1c0.4-1.3,1.5-2.3,1.7-3.8c0-0.3,0.4-0.7,0.8-1c0.8-0.5,1.5-1.2,2.2-1.9
                c0.4-0.4,0.8-0.7,1.2-1c1.4-0.8,2.7-1.6,3.7-2.9c0.4-0.5,1-0.8,1.5-1.1c1.8-1,3.4-2.1,5-3.5c0.4-0.4,0.8-0.7,1.3-1
                c2.7-1.7,5.2-3.7,7.7-5.7c0.4-0.3,0.9-0.6,1.3-0.9c0.8-0.5,1.6-0.7,2.4-0.1c1.1,0.7,2.3,1.1,3.6,1.1c0.4,0,0.8,0.2,1.1,0.5
                c0.7,0.6,1.5,0.8,2.3,0.9c0.4,0.1,0.5,0.4,0.6,0.7c0.1,0.2,0.1,0.4,0.1,0.6c-0.2,5.1-0.5,10.2-0.7,15.3c-0.1,1.1-0.1,2.1-0.2,3.2
                c-0.1,1.5-0.2,3-0.5,4.4c-0.4,1.9-0.6,3.8-0.8,5.7c-0.1,1.3-0.5,2.5-1.1,3.6c-0.6,1.3-1.2,2.7-1.8,4.1c-0.4,0.9-0.6,1.8-0.6,2.8
                c0,3.9,0,7.9,0,11.8c0,0.3,0.1,0.6,0.1,0.9c0.1,0.7,0.5,1.2,1.1,1.5c0.7,0.3,1.4,0.5,2.1,0.8c0.6,0.3,1.2,0.3,1.9,0.2
                c0.5-0.1,1.1-0.2,1.6-0.2c0.3,0,0.6,0.2,0.8,0.4c0.3,0.3,0.4,0.7,0.6,1.1c0.3,0.6,0.7,1,1.5,1.1c1.5,0.2,2.3,1.4,3.3,2.4
                c0.1,1.4,1.2,2.4,1.5,3.8c0.3,1.6,0.2,2.9-0.6,4.3c-0.3,0.5-0.7,0.8-1.3,0.7c-0.5,0-1-0.2-1.5,0.2c-0.5,0.4-0.4,0.9-0.6,1.4
                c0,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2-0.3-0.3-0.5-0.6-0.8c-0.3-0.3-0.9-0.3-1.2-0.1
                c-0.3,0.2-0.5,0.4-0.7,0.6c-1.3,1.3-2.8,1.9-4.6,1.8c-1.2-0.1-2.3-0.1-3.5,0c-1.7,0.1-3.2-0.3-4.6-1.1c-1.1-0.7-2.3-1.1-3.6-1.1
                c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.3-0.4-0.4c-0.1-0.6-0.1-1.3-0.1-1.9c0-0.1,0.2-0.4,0.4-0.4c0.4-0.1,0.8-0.2,1.2-0.3
                c0.5-0.1,0.7-0.4,0.8-0.9c0-0.4,0-0.8,0-1.3c0-0.3-0.2-0.6-0.6-0.7C493.7,550.1,493,550,492.3,549.9z"/>
            <path className="st5 state_level" id="TT-PED" d="M524.2,727.6c-1.4,0.1-2.8,0.5-4.2,1.2c-1.4,0.7-3,1.1-4.4,1.8c-1.6,0.8-3.2,1.1-4.9,1c-0.4,0-0.9,0-1.3,0
                c-3.4,0-3.4-0.3-3.4,3.2c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-1.2,0-2.3,0-3.5,0
                c-2.1,0-2.2,0.2-2.4,2.1c0,0.4-0.2,0.8-0.3,1.1c-2.4,0.3-12.9,0.4-18,0.1c-0.6-0.2-0.7-0.8-1-1.2c-0.3-0.4-0.6-0.7-1-0.8
                c-1.1-0.2-1.8-0.6-1.8-1.8c-0.7-0.9-0.8-1.9-0.6-3c0.1-0.8-0.2-1.4-0.8-1.9c-2.6-2-5.2-4.1-7.7-6.2c-0.7-0.5-1.3-1.1-2-1.6
                c-0.7-0.6-0.9-1.4-0.7-2.3c0-0.2,0-0.4,0.1-0.6c0.8-1.4,0.9-3,1-4.6c0.9-0.8,1.8-1.6,2.7-2.4c0.6-0.6,0.7-1.6,0.2-2.3
                c-0.4-0.5-0.8-0.9-1.3-1.4c-0.7-0.6-1.1-1.3-0.5-2.2c0.6-1.1,0.3-2.1-0.5-2.9c-0.5-0.5-0.6-1.1-0.3-1.7c0.3-0.8,0-1.4-0.7-1.9
                c-0.6-0.5-1.2-0.9-1.8-1.4c-0.3-0.3-0.3-0.9,0.1-1.1c0.9-0.6,1.7-1.2,2.1-2.3c0.1-0.4,0.6-0.6,0.8-1c0.5-0.7,0.5-1.4-0.2-2
                c-0.8-0.6-1.3-1.4-1.4-2.4c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.5-0.6-0.8-1.2-0.9c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2-0.4-1.6-0.9
                c-0.1-0.1-0.1-0.2-0.1-0.3c-0.4-1-1.1-1.5-2.1-1.7c-0.4-0.1-0.8-0.4-0.9-0.8c-0.1-1.1-0.7-1.7-1.6-2.2c-0.1,0-0.2-0.1-0.2-0.2
                c-0.1-0.3-0.4-0.5-0.1-0.9c0.2-0.3,0.4-0.5,0.7-0.5c0.3,0,0.6,0,0.9,0.1c0.8,0.3,1.5,0.7,2.1,1.4c0.4,0.5,1.1,0.7,1.7,0.7
                c0.8,0.1,1.5-0.6,1.5-1.3c-0.2-1.8-0.1-3.6,0-5.4c0-0.4,0-0.9,0-1.3c-0.1-1.2-1-2-2.1-1.9c-0.8,0.1-1.7,0.2-2.5,0.3
                c-0.4,0.1-0.8,0.2-1.2,0.3c-1.4,0.1-1.9-0.1-2.6-1.3c-0.4-0.7-0.3-1.9,0.8-1.9c1.9-0.1,3.8-0.7,5.7-0.6c0.4,0,0.8-0.2,1.2-0.4
                c0.5-0.2,1-0.6,1.5-0.6c1.4-0.2,2.1-0.7,2-2.3c-0.1-0.8,0.4-1.4,0.9-2c0.3-0.4,0.7-0.8,0.9-1.3c0.3-0.4,0.9-0.7,1.4-0.4
                c0.8,0.4,1.5,0.8,2.3,1.1c0.6-0.3,1.3-0.4,1.8-0.8c0.7-0.4,1.3-0.6,2.1-0.5c0.5,0,1.1,0.1,1.6-0.1c1.7-0.7,3.1,0.2,4.6,0.8
                c0.5,0.2,0.9,0.6,1.4,0.8c0.9,0.4,1.9,0.4,2.7-0.3c0.5-0.5,1.1-0.5,1.8-0.3c0.3,0.1,0.6,0.2,0.9,0.2c0.8,0.1,2-0.2,2-1.5
                c0-0.9,0.3-1.6,0.9-2.3c0.7-0.7,1.3-1.4,1.9-2.2c1.3-1.6,1.4-1.6,2.8-0.2c0.5,0.5,1,1,1.8,1.3c1.6,0.5,1.5,0.4,0.7,1.8
                c-0.3,0.5-0.4,1-0.1,1.5c0.3,0.5,0.7,0.7,1.3,0.7c0.3,0,0.6,0,0.9-0.1c0.5-0.1,1-0.3,1.5-0.5c1.5-0.5,2.8-0.4,3.6,2
                c0.1,0.3,0.2,0.6,0.3,0.9c0.4,0.2,0.8,0.4,1.1,0.5c1,0.4,1.2,1.4,1.6,2.2c0.1,0.1-0.1,0.4-0.2,0.6c-0.4,0.5-0.9,0.9-1.3,1.3
                c-0.6,0-1.1-0.1-1.6-0.1c-0.5,0-1,0.6-1,1.1c0,0.5,0.2,1,0.3,1.6c0.1,0.5-0.4,1-1,1c-0.4,0-0.8-0.1-1.2-0.1
                c-0.8,0.9-1.8,1.4-2.9,1.7c-0.6,0.2-0.8,1.8-0.4,2.3c0.1,0.2,0.3,0.3,0.5,0.4c0.4,0.4,1.3,0.5,1.2,1.3c-0.1,0.6-0.8,0.5-1.3,0.7
                c-0.9,0.5-1.1,1-0.9,2.1c0.1,0.5,0.4,1,0.1,1.5c-0.7,1.9,0,3.8,0.5,5.6c0.8,1.1,0.9,2.3,0.7,3.6c-0.1,0.7,0.3,1.2,0.8,1.6
                c0.8,0.6,1.7,0.9,2.7,0.8c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.5c-0.1,0.4-0.3,0.8-0.4,1.2c-0.4,1.1-0.3,1.7,0.9,2.4
                c0.7,0.4,1.1,0.9,1.3,1.7c0.1,0.6,0.6,1.1,0.9,1.8c-0.9,1-1.8,2.1-2.3,3.4c-0.1,0.4-0.2,0.8-0.1,1.2c0,0.3,0.2,0.6,0.6,0.6
                c0.7,0.1,1.5,0.1,2.2,0.1c4.6,0,9.1,0,13.7,0c0.8,0,1.7,0,2.5,0.1c0.4,0,0.7,0.4,0.8,0.9c0,0.2,0.1,0.4,0.1,0.6
                c0,5.1,0.1,10.2,0.1,15.3C524.7,726.6,524.7,727.1,524.2,727.6z"/>
            <path className="cont17 state_level" id="Lopinot/Bon Air West" fill="#7f7f7f" d="M573.3,288c1.6-1.6,3.1-3.1,4.5-4.6c0.3-0.3,0.4-0.8,0.4-1.2c0-1.1-0.5-2.1-1.4-2.7c-0.5-0.3-1.1-0.6-1.6-1
                c-0.3-0.2-0.5-0.9-0.3-1.2c0.2-0.4,0.4-0.7,0.6-1.1c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.6-0.3-1.3-0.9-1.5c-0.5-0.2-1.1-0.2-1.4-0.6
                c-0.2-0.2-0.2-0.4-0.1-0.6c0.2-0.5,0.8-0.6,1.2-1c0.5-0.5,0.6-1.1,0.5-1.7c-0.2-0.8-1-1.5-0.7-2.4c0.2-0.8,0.6-1.6,1-2.3
                c0.1-0.2,0.2-0.4,0.3-0.6c0.6-1.2,1.6-1.7,2.9-1.7c0.6,0,1.3,0.2,1.9,0.3c0.4,0,0.8,0,1.3-0.1c0.5-0.1,0.7-0.5,0.9-0.8
                c0.2-0.3,0-0.9-0.3-1.1c-0.3-0.3-0.7-0.5-1-0.7c-0.5-0.3-0.9-0.5-1.3-0.7c-0.2-0.4-0.4-0.6-0.6-0.9c-1.2-1.5-1.5-3.3-1.6-5.1
                c0-0.6,0-1.3,0-1.9c0-2.6,0-2.1,1.9-3.7c0.4-0.4,0.9-0.5,1.5-0.5c1.3,0.1,2.3-0.4,3.1-1.3c0.7-0.8,1.7-0.8,2.7-0.5
                c0.6,0.2,1.2,0.5,1.8,0.7c1.1,0.5,2.1,0.4,3.1-0.1c0.5-0.2,0.9-0.6,1.4-0.8c0.3-0.1,0.7-0.2,0.9,0c0.6,0.4,1.2,0.8,2,0.8
                c0.3,0,0.7,0.2,0.8,0.4c0.6,0.8,1.3,1.1,2.2,1.1c0.2,0,0.4,0.1,0.6,0.2c0.5,0.2,0.8,0.9,0.5,1.3c-0.1,0.2-0.3,0.3-0.4,0.5
                c-0.4,0.4-0.7,0.8-0.7,1.3c-0.1,1.7-0.1,3.4,0.7,5c0.3,0.6,0.3,1.2-0.3,1.8c-0.2,0.2-0.4,0.5-0.6,0.8c-0.4,0.6-0.4,1.2-0.2,1.8
                c0.1,0.3,0.3,0.6,0.4,0.9c0.3,0.5,0.4,1,0,1.5c-0.7,0.9-0.6,1.8-0.2,2.7c0.2,0.5,0.5,0.9,0.7,1.4c0.2,0.4,0.4,0.8,0.1,1.2
                c-0.6,0.7-0.8,1.5-0.9,2.3c-0.3,2.5-0.7,5.1-1,7.6c0,0.4,0,0.9,0.2,1.2c0.3,0.7,0.1,1.2-0.3,1.8c-0.2,0.3-0.5,0.8-0.5,1.1
                c0.2,1.5,0.1,3,0,4.5c0,0.7,0.6,1.5,1.3,1.7c0.4,0.1,0.8,0.1,1.3,0.2c0.8,0.1,1,0.3,1.2,1.2c0.1,0.3,0.1,0.6,0.1,0.9
                c0.1,0.7,0.5,1.3,1.1,1.5c0.9,0.3,1.4,0.8,1.7,1.7c0.1,0.2,0.2,0.5,0.3,0.5c1.6,0.8,2.3,2.5,3.9,3.2c0.8,0.4,1.2,1.1,1.2,2.1
                c0,0.9,0.2,1.7,0.3,2.5c0.2,1.2,0.2,2.5,0.3,3.8c0,0.1,0,0.2-0.1,0.3c-0.8,1.3-0.4,2.4,0.5,3.5c0.3,0.3,0.4,0.8,0.5,1.2
                c0.3,1.1,1,1.6,2.1,1.5c0.3,0,0.6-0.1,1-0.1c0.4,0,0.9,0.2,1,0.6c0.3,0.7,0.8,1.2,1.4,1.7c0.1,0.1,0.1,0.2,0.1,0.3
                c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0-0.2,0-0.3,0c-1.5,0.2-1.8,0.5-1.7,2c0.1,0.7-0.1,1.2-0.6,1.7c-0.2,0.1-0.4,0.2-0.5,0.4
                c-0.4,0.4-0.6,0.9-0.4,1.5c0.2,0.7,0.8,1.1,1.4,1.1c0.5,0.1,1-0.1,1.6-0.2c0.7-0.1,1.2,1.1,1,1.7c-0.2,0.5-0.7,0.8-1.1,1.2
                c-0.5,0.6-0.5,1.2-0.1,1.7c0.6,0.7,1.3,0.9,2,0.6c0.6-0.2,1-0.7,1.4-1.3c0-0.1,0.2-0.1,0.3-0.1c0.1,0.1,0.3,0.2,0.4,0.4
                c0.1,0.3,0.1,0.6,0.1,0.9c0,0.4-0.3,0.6-0.6,0.7c-0.4,0.1-0.8,0.3-1.2,0.3c-0.9,0-1.6,0.4-2.1,1.2c-0.4,0.6-0.9,0.8-1.6,0.9
                c-0.6,0.1-1.2,0.5-1.7,0.7c-0.4,0.2-0.8,0.5-1.2,0.5c-1.4-0.1-2.4,0.8-3.4,1.5c-3,2.2-3.1,2.2-6.7,2.5c-2.9,0.3-5.7,0-8.6-0.2
                c-0.6,0-1.3-0.3-1.8-0.6c-1.2-0.6-2.4-0.9-3.7-0.8c-0.8,0.1-1.5-0.2-2.1-0.7c-0.7-0.5-1.3-1.1-2-1.6c-0.7-0.6-1.5-0.8-2.4-0.8
                c-1.6,0-3.2,0-4.8,0c-1.2,0-2.2,0.6-3.3,1c-1.3,0.5-2.5,0.3-3.5-0.7c-1.1-1.1-2.3-2.2-3.4-3.4c-1.2-1.1-2.4-2-4.1-2.3
                c-0.7-0.1-1.4-0.5-2.1-0.8c-1.7-0.9-3.5-1.6-5.4-1.7c-1.2-0.1-2.3-0.4-3.5-0.6c-0.9-0.2-1.4-0.9-1.1-1.9c0.1-0.2,0.2-0.4,0.3-0.6
                c0.2-0.3,0.8-0.6,1.1-0.5c0.6,0.2,1.2,0.4,1.8,0.6c0.6,0.2,1.3,0.2,1.9-0.1c0.5-0.2,0.9-0.5,1.4-0.7c0.6-0.3,1-0.8,1.1-1.5
                c0.1-1,0.6-1.8,1.2-2.5c0.9-1.1,1.3-2.4,1.2-3.9c-0.1-1.1-0.3-2.1-1.1-2.9c-0.2-0.2-0.4-0.5-0.6-0.7c-0.3-0.4-0.2-0.9,0.2-1.1
                c0.2-0.1,0.4-0.1,0.6-0.2c0.8-0.2,1.3-0.7,1.4-1.5c0-0.2,0.1-0.4,0.2-0.6c0.2-0.3,0.4-0.5,0.8-0.4c0.7,0.2,1.4,0.4,2.2,0.5
                c1,0.2,1.9,0.1,2.7-0.5c0.3-0.3,0.7-0.5,1.1-0.7c0.7-0.4,1.4-0.5,2.1-0.4c0.7,0.1,1.5,0.3,2.2,0.5c0.7,0.1,1.5,0,2.2-0.3
                c1.1-0.5,1.3-2.3,0.2-2.8c-0.8-0.4-1.3-1-1.4-1.9c-0.1-0.8-0.6-1.4-1.1-1.9c-0.6-0.6-0.6-1.3-0.6-2.1c0-0.9,0.2-1.2,1.3-1.5
                c0.2-0.1,0.4-0.1,0.6-0.1c0.5-0.1,0.7-0.4,0.8-0.9C572.9,290.3,573.1,289.2,573.3,288z"/>
            <path className="st7 state_level" id="TT-DMN" d="M434.5,228.3c-0.4,1.1-0.6,1.9-0.9,2.6c-0.2,0.2-0.4,0.4-0.6,0.5c-0.6,0.2-1.2,0.4-1.8,0.7
                c-0.3,0.2-0.6,0.6-0.9,0.9c-0.4,0.6-0.4,1.3,0.1,1.7c0.8,0.7,1.7,1.3,2.5,2c0.3,0.3,0.7,0.7,0.7,1c0,1.1,0.6,2,1.1,2.9
                c0.4,0.8,0.6,1.8,0.9,2.7c0.3,0.8,0.3,1.7,0,2.5c-0.4,1.1-0.2,2.1,0.4,3.1c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.4-0.2,0.9-0.5,1.1
                c-0.1,0-0.2,0.1-0.3,0.1c-1.9,0.1-2.8,1.2-3.2,3c-0.1,0.4-0.1,0.9-0.4,1.2c-0.9,1.4-0.8,2.6,0.4,3.7c0.4,0.4,0.4,1,0.3,1.5
                c-0.1,0.3-0.1,0.6-0.3,0.9c-0.4,0.8-0.5,1.6-0.5,2.5c0,0.5,0,1.1-0.1,1.6c-0.1,0.5-0.3,1.1-0.7,1.4c-0.9,0.7-1.7,1.6-3,1.6
                c-0.8,0-1.4,0.5-1.8,1.2c-0.7,0.9-1.3,1.9-1.4,3.2c0,0.7-0.3,1.5-0.5,2.2c-0.2,0.7-0.6,1.2-1.2,1.4c-1.7,0.5-2.8,1.9-3.9,3.1
                c-1,1.1-1.6,2.5-2.9,3.3c-0.5,1.5-1.7,2.4-2.5,3.7c-0.7,1.2-1.3,2.4-1.2,3.9c0,1.7-0.4,3.4,0,5.1c0.1,0.3-0.2,0.6-0.5,0.7
                c-0.4,0.1-0.6,0-0.8-0.3c-0.5-0.7-0.9-1.4-0.9-2.3c0-0.4,0-0.9-0.1-1.3c-0.1-0.9-0.8-1.6-1.7-1.4c-1.1,0.2-2.1,0.3-3.2-0.2
                c-0.3-0.4-0.6-0.9-0.8-1.3c-0.4-0.6-1-0.7-1.7-0.7c-0.5,0-1.1,0.5-1.2,0.9c-0.1,0.5-0.1,1-0.2,1.6c-0.1,0.5-0.1,1-0.3,1.6
                c0,0.1-0.3,0.3-0.5,0.3c-0.2,0-0.4,0-0.6-0.2c-0.2-0.2-0.4-0.5-0.5-0.8c-0.2-0.9-0.8-1.5-1.7-1.8c-0.8-0.3-1.3-0.8-1.6-1.5
                c-0.3-0.6-0.5-1.2-0.7-1.8c-0.5-1.3-0.9-2.6-1.4-3.9c-0.1-0.2,0.2-0.7,0.5-0.7c0.5-0.1,1.1-0.1,1.6-0.2c0.4-0.1,0.8-0.4,0.9-0.8
                c0.1-0.5,0.2-1,0.2-1.6c0.1-0.5,0.3-0.8,0.8-0.9c0.3-0.1,0.6-0.2,0.9-0.2c0.8-0.1,1-0.7,1.2-1.3c0-0.1,0.1-0.2,0.2-0.2
                c0.7,0,0.8,0.6,1.1,1c0.4,0.5,0.9,0.5,1.4,0.4c0.4-0.1,0.9-0.5,0.9-0.8c0-1.1-0.1-2.1,0-3.2c0-0.9-0.1-1.7-0.7-2.3
                c-0.3-0.3-0.4-0.7-0.5-1.1c-0.2-0.9-0.2-1.9-0.3-3c0.2-0.2,0.4-0.6,0.8-0.9c0.9-0.7,1.5-1.5,1.4-2.7c0-0.7,0.4-1.1,0.9-1.6
                c0.8-0.9,0.8-1.5,0-2.5c-0.3-0.4-0.6-0.9-0.8-1.4c-0.5-1.3-2.2-1.9-3.5-1.2c-0.6,0.3-1.1,0.7-1.6,1.1c-0.5,0.4-1.1,0.6-1.8,0.5
                c-0.7-0.1-1.5-0.3-2.2-0.1c-0.6,0.1-1,0.5-1.2,1c-0.2,0.5-0.2,1.1-0.4,1.5c-0.4,1-1.1,1.8-2.3,1.9c-0.2,0-0.4,0-0.6,0.1
                c-1,0.3-2.2-0.2-3.1,0.5c-0.8,0.5-1.4,1.3-2.2,1.9c-0.8,0-1.7,0-2.5,0c-0.4,0-0.8-0.4-0.8-0.8c0-1-0.1-1.9-0.1-2.9
                c0-1,0.1-1.2,1.3-1.4c0.7-0.1,1.2-0.4,1.5-1c0.7-1.3,1.4-2.6,2.3-3.8c0.4-0.5,0.7-1.1,0.9-1.7c0.6-1.9,1.7-3.6,3-5.2
                c0.3-0.4,0.6-0.9,0.8-1.4c0.3-0.6,0.3-1.3,0-1.9c-0.1-0.3-0.4-0.6-0.7-0.6c-1.1-0.1-2.1-0.1-3.2-0.2c-0.4,0-1,0-1.2-0.3
                c-0.9-1.1-2.1-0.9-3.2-0.9c-0.9,0-1.7,0-2.6,0c-1.5-0.1-2.9,0-4.3,0.7c-0.5,0.3-1.2,0.2-1.9,0.2c-0.6,0-1.3-0.2-1.9-0.2
                c-1.2-0.1-2.1-0.4-2.4-1.7c0-0.2-0.2-0.4-0.3-0.6c-0.3-0.4-0.6-0.6-1-0.6c-0.4,0-0.8,0-1.3,0c-1.2,0.1-2.1-0.3-2.6-1.4
                c-0.4-0.8-1-1.4-1.8-1.7c-0.8-0.3-1.1-1-1.7-1.4c-0.8-0.7-1.7-0.8-2.6-0.3c-1.1,0.6-2.2,1.3-3.4,2.1c-0.8,0-1.7-0.1-2.7,0
                c-0.9,0.1-1.8,0.5-2.8,0.3c-0.5-0.1-1,0.2-1.5,0.4c-0.4,0.1-0.8,0.4-1.1,0.5c-0.4,0.2-1.2-0.2-1.3-0.6c-0.1-0.4-0.1-0.8-0.2-1.3
                c-0.1-1-0.6-1.5-1.6-1.7c-0.2,0-0.4-0.1-0.6-0.1c-0.3-0.1-0.5-0.6-0.4-0.8c0.7-1.2,1.1-2.5,1.1-3.9c0-0.5,0.6-0.9,1.2-0.7
                c0.8,0.2,1.6,0.4,2.4,0.7c1,0.3,1.9,0.2,2.8-0.3c0.7-0.4,1.4-0.9,2.2-1.3c0.5-0.2,1.1-0.3,1.5,0c0.7,0.5,1.5,0.8,2.3,0.9
                c0.5,0.1,1.1,0.2,1.5,0.5c1,0.8,2.1,0.8,3.3,0.5c1.6-0.3,3-1.2,4.5-1.5c0.4-0.1,0.7-0.5,1.1-0.7c0.9-0.6,1.7-1.2,2.6-1.9
                c0.8-0.6,1.6-1,2.6-1.3c1.5-0.4,3-0.3,4.1,1c0.1,0.2,0.3,0.3,0.4,0.5c0.7,0.6,1.5,0.8,2.4,0.4c0.3-0.1,0.5-0.3,0.8-0.5
                c1.2-0.5,2.4-0.7,3.6,0.1c0.3,0.2,0.8,0.4,1.2,0.4c0.8,0.2,1.3,0.7,1.6,1.4c0.2,0.5,0.5,0.9,0.8,1.3c0.2,0.3,0.8,0.4,1.2,0.2
                c0.6-0.2,1.1-0.6,1.5-1.2c0.8-1.6,2.5-2.2,3.7-3.4c0.3-0.3,0.8-0.3,1.2-0.3c1-0.1,1.8-0.5,2.5-1.2c0.9-1,2-1.1,3.2-0.8
                c1.6,0.4,3.1,0.8,4.3,2c0.6,0.7,1.5,0.7,2.4,0.6c1.1-0.1,2.1-0.5,2.8-1.4c0.5-0.5,1.1-0.7,1.7-0.7c0.6,0,1.3,0.1,1.9,0.1
                c1,0,1.8-0.8,1.9-1.8c0-0.3,0-0.6,0-1c-0.1-0.9,0.3-1.6,0.9-2.3c0.1-0.1,0.2-0.2,0.2-0.2c0.5-0.8,1.6-1.1,1.9-2.1
                c0.2-0.9,0.3-1.9,0.6-2.8c0.1-0.4,0.2-0.8,0.5-1.1c0.2-0.2,0.5-0.3,0.8-0.3c0.2-0.1,0.7,0.4,0.7,0.6c0,0.7-0.1,1.5-0.1,2.2
                c0,0.7,0.3,1.2,1,1.5c0.3,0.1,0.6,0.3,0.9,0.4c0.9,0.3,1.4,1,1.6,1.9c0.1,0.4,0.2,0.8,0.4,1.2c0.7,1.7,2.5,2.5,4.2,1.8
                c1.5-0.6,2.8-1.5,4.1-2.5c0.9-0.7,1.1-1.8,1.6-2.7c0.1-0.3,0.7-0.5,1.1-0.4c0.3,0,0.5,0.5,0.4,0.7c-0.1,0.1-0.1,0.2-0.1,0.3
                c-0.8,0.9-1.4,1.8-1.6,3.1c-0.2,0.8-0.9,1.1-1.5,1.6c-1.2,0.8-1.3,2.6-0.3,3.6C433.5,227.5,433.9,227.8,434.5,228.3z"/>
            <path className="st8 state_level" id="Augustine" d="M507.9,330.7c0.1-0.3,0.3-0.7,0.3-1.1c0.2-1.6,0.4-3.1,1.1-4.6c0.5-1.1,0.6-2.3,0.3-3.4c-0.2-1.1,0-2.1,0.2-3.1
                c0.1-0.4,0.6-0.7,1-0.6c1.8,0.4,3.5,0.9,4.9,2.1c0.1,0.1,0.2,0.1,0.3,0.2c1.8,1.5,4,1.9,6.3,1.7c2.8-0.2,5.5-0.4,8.3-0.3
                c0.9,0,1.7,0,2.5-0.4c1.8-0.9,3.7-0.9,5.6-0.7c1.7,0.2,3.4,0.6,5,0.9c1.5,0.3,2.9,0.6,4.3,1.2c0.8,0.3,1.6,0.5,2.4,0.7
                c0.4,0.1,0.8,0.8,0.7,1.3c0,0.1,0,0.2,0,0.3c-0.7,1.4-0.4,2.7,0.4,3.9c0.3,0.4,0.2,0.8,0.1,1.2c-0.2,0.5-0.5,0.9-0.7,1.4
                c-0.4,1.1-0.5,2.1,0.3,3c1.5,1.8,2.7,3.8,4.7,5.2c1,0.7,1.7,1.6,2.1,2.8c0.1,0.4,0.3,0.8,0.5,1.2c0.5,1,1.2,1.7,2.3,2.1
                c0.6,0.2,1.3,0.3,1.8,0.6c1.2,0.9,2.5,1.2,3.9,1c1.3-0.1,2.5,0.4,3.6,1c0.4,0.2,0.8,0.4,1.1,0.7c1.2,1.1,2.6,1.3,4.2,1.1
                c1.4-0.2,2.8-0.3,4.1-0.7c1.4-0.4,2.7-0.6,4.1-0.5c1.8,0.2,3.6,0,5.3-0.7c1-0.4,2.1-0.4,3.1-0.2c1.5,0.2,3,0.4,4.6,0.6
                c0.4,0.5,1,1,1.4,1.6c0.4,0.5,0.5,1.2,0.1,1.8c-0.5,1-1.1,2.1-1.7,3.1c-0.4,0.7-0.7,1.5-0.9,2.4c-0.4,2.7-1.1,5.4-2.2,8
                c-0.2,0.5-0.3,1-0.3,1.5c0,1.3,0,2.6,0,3.8c0,0.5-0.1,1.1-0.1,1.6c0,0.5-0.6,1-1.1,1c-0.5,0-1.1-0.1-1.6-0.1c-0.8,0-1.4,0.4-1.8,1.2
                c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.5-0.6,0.9-1.2,1c-0.9,0.1-1.9,0.4-2.8,0.7c-0.4,0.1-0.9-0.1-1.1-0.5c-0.3-0.6-0.4-1.2-0.7-1.8
                c-0.2-0.4-1-0.6-1.4-0.3c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.6,0.7-1.4,0.9-2.3,0.7c-0.8-0.2-1.7-0.5-2.5-0.2
                c-1.4,0.6-2.2,0.3-2.8-1.1c-0.2-0.5-0.8-0.7-1.4-0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.9,0.2-1.7,0.1-2.1-1c-0.3-0.9-1-1.2-2-1.2
                c-1.2,0-1.2,0-1.4-1.2c-0.1-0.4-0.1-0.8-0.3-1.2c-0.4-0.9-1.5-1.3-2.4-1c-0.3,0.1-0.6,0.4-0.7,0.6c-0.2,0.5-0.2,1-0.3,1.5
                c-0.1,0.3-0.5,0.5-0.7,0.4c-0.3-0.2-0.5-0.4-0.8-0.5c-1.3-0.3-2.4-0.8-3.1-2c-0.3-0.4-0.9-0.5-1.4-0.4c-0.4,0.1-0.8,0.1-1.3,0.1
                c-0.4,0-0.8-0.5-0.7-0.9c0-0.1,0.1-0.2,0.2-0.3c0.7-0.7,0.8-1.6,0.1-2.3c-0.6-0.6-0.7-1.2-0.7-2c0-0.5-0.3-0.8-0.7-1
                c-0.7-0.3-1.4-0.5-2.1-0.9c-0.4-0.2-0.6-0.5-0.6-1c0-0.3,0-0.6,0-1c-0.1-0.9-0.8-1.5-1.5-1.9c-0.6-0.3-1.2-0.1-1.8,0.2
                c-0.4,0.2-0.7,0.4-1.1,0.5c-0.2,0.1-0.5,0-0.5-0.3c-0.1-0.7-0.1-1.5-0.2-2.2c-0.1-1.1-0.8-1.7-1.8-1.8c-0.9-0.1-1.7,0-2.3,0.8
                c-0.5,0.5-1.1,0.7-1.7,0.4c-1-0.5-2.2-0.7-3.2-1.5c-0.3-0.3-0.8-0.3-1.2-0.4c-0.7-0.1-1.3,0-1.7,0.6c-0.2,0.2-0.4,0.5-0.6,0.7
                c-0.1,0.1-0.5,0.1-0.6,0c-0.2-0.2-0.4-0.5-0.5-0.8c-0.5-0.9-1.2-1.4-2.3-1.5c-0.1,0-0.2,0-0.3,0c-0.8,0-1.5-0.3-1.8-1
                c-0.6-1-1.4-1.2-2.4-0.9c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.7-0.3-0.7-0.5c0-0.2,0-0.4,0-0.6c-0.1-1.4-0.3-1.6-1.6-2
                c-0.1,0-0.2-0.1-0.3-0.1c-0.9-0.1-1.3-0.6-1.5-1.4c-0.2-0.8-0.8-1.3-1.6-1.3c-0.2,0-0.4,0-0.6-0.1c-0.8-0.3-1.5-0.8-2.4-0.4
                c-0.8,0.4-0.8,1.2-1.2,1.8c-0.4,0.7-1.3,0.9-1.9,0.4c-0.5-0.4-0.9-0.9-1.4-1.3c-0.4-0.4-1-0.4-1.5-0.4c-0.7,0.1-1.2,0.4-1.4,1.1
                c-0.3,0.8-0.4,1.6-0.7,2.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0-0.5,0-0.6-0.1c-0.3-0.3-0.4-0.8-0.6-1.1c-0.3-0.4-0.6-0.9-1.1-1.2
                c-1-0.7-2.1-1.4-3-2.4c-0.7-0.8-1.9-0.9-3-0.5c-0.6,0.2-1.1,0.5-1.7,0.7c-0.2,0.1-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.2-0.2
                c-0.8-1.8-1.4-3.6-1.4-5.5c0-1.4,0-2.8,0-4.1c0-1,0.3-1.3,1.3-1.3c0.6,0,1.3,0,1.9-0.1c2.7-0.6,5.5-0.7,8.3-0.7
                C505.9,331.5,506.9,331.4,507.9,330.7z"/>
            <path className="st3 state_level" id="Pointe-A-Pierre" d="M470.5,618.4c-1.7-0.1-3.5-0.1-5.3,0.1c-1.4,0.1-2.5-0.4-3.5-1.2c-0.3-0.2-0.4-0.9-0.2-1.2
                c0.1-0.3,0.3-0.6,0.5-0.8c0.4-0.5,0.8-1,1.3-1.4c0.8-1,0.2-2-0.6-2.4c-0.6-0.3-1.2-0.5-1.7-0.8c-0.4-0.2-0.7-0.4-1-0.7
                c-0.4-0.3-0.5-1.1-0.2-1.5c0.3-0.3,0.5-0.7,0.9-0.9c1.6-0.9,2.8-2.3,4.1-3.5c0.7-0.6,0.9-1.4,0.9-2.3c0-0.3-0.1-0.6-0.1-0.9
                c-0.1-1.5-0.4-3-0.4-4.4c0-2.1-0.6-4-2-5.6c-0.4-0.5-0.8-1-1.2-1.5c-0.7-0.8-0.8-1.7-0.6-2.7c0.1-0.3,0.1-0.6,0.3-0.9
                c0.7-1.5,0.5-2.9,0-4.3c-0.6-1.6-1-3.2-1.6-4.9c-0.7-2.2-2.1-3.9-4.4-4.8c-2.7-1.1-5.1-2.6-7.7-3.8c-1.9-0.9-3.4-2.2-4.4-4
                c-0.2-0.4-0.5-0.7-0.7-1.1c-1-1.5-2.2-2.6-3.9-3.1c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.2-0.6-0.6-0.4-0.7c0.2-0.1,0.3-0.3,0.5-0.3
                c1.8,0,3.6,0,5.4,0c0.4,0,0.9,0,1.3,0.1c0.2,0,0.4,0.2,0.4,0.4c0.1,0.7,0.1,1.5,0.2,2.2c0.1,0.6,0.5,1,1,1c2.3,0,4.7,0,7,0
                c0.6,0,1-0.3,1.3-0.8c0.3-0.5,0.7-1.1,0.9-1.7c0.3-1.2,0.8-2.1,1.7-3c0.3-0.3,0.4-0.7,0.5-1.1c0.2-1.1,0.4-2.3,0.6-3.4
                c0.1-0.9,0.5-1.6,0.9-2.4c0.3-0.6,1.2-0.8,1.7-0.5c0.7,0.5,1.4,1,2,1.5c0.3,0.3,0.5,0.6,0.7,1c0.2,0.5,0.6,0.9,1.1,1.1
                c1.3,0.5,2.5,1.2,3.9,1.3c1.1,0,1.9,1.1,3,0.8c1.8-0.4,3.6-0.4,5.3-1.2c0.7-0.4,1.4-0.3,2.1,0.1c1.7,0.9,3.5,0.7,5.3,0.2
                c0.4-0.1,0.8-0.3,1.1-0.6c1.1-0.9,2.3-1.5,3.1-2.7c0.3-0.4,0.8-0.7,1.2-1c0.2-0.2,0.7-0.1,0.8,0.2c0.3,0.7,0.7,1,1.5,1
                c0.4,0,0.8,0.1,1.2,0.2c0.1,0,0.3,0.3,0.2,0.5c0,0.2-0.2,0.4-0.4,0.4c-0.4,0.1-0.8,0.1-1.3,0.2c-0.6,0.1-1,0.6-0.8,1.1
                c0.4,1.7,0.5,3.4,0.3,5.1c-0.1,0.7-0.6,1.2-1.3,1.2c-0.5,0-1.1,0.1-1.6,0.1c-2.3,0-2.4,0.1-2.4,2.6c0,0.4,0,0.9,0.1,1.3
                c0.1,0.3,0.3,0.6,0.6,0.7c0.7,0.2,1,0.8,1,1.4c0,1.3,0.1,2.5,0.1,3.8c0,0.4,0.4,0.8,0.8,0.8c1.2,0,2.3,0.1,3.5,0.1
                c1.5,0,3,0.1,4.5,0.1c0.4,0,0.7,0.6,0.5,0.9c-0.2,0.4-0.4,0.7-0.7,1c-0.6,0.6-1.2,1.2-1.9,1.7c-1.1,0.9-1.9,2-2.8,3.1
                c-0.6,0.9-1.1,1.8-1.3,2.9c-0.8,3.5-1.5,7.1-1.4,10.7c0,2.4,0.6,4.6,0.9,6.9c0.2,1.3,0.6,2.5,0.9,3.7c0.2,0.9,0.5,1.9,0.6,2.8
                c0.2,2.2,0.3,4.5,0.7,6.7c0.3,1.5,0.2,3-0.5,4.4c-0.2,0.5-0.5,0.9-0.8,1.4c-0.3,0.4-0.6,0.9-1,1.3c-1.3,1.6-1.7,3.5-1.3,5.4
                c0.4,2,0.3,4,0.3,6c0,2.3-0.4,4.4-1.7,6.4c-0.5,0.7-0.8,1.5-1.3,2.2c-0.3,0.4-0.7,0.6-1.1,0.4c-0.8-0.6-1.7-0.8-2.7-0.8
                c-0.5,0-1.1,0-1.6-0.1c-0.6,0-1-0.3-1.3-0.8c-0.6-0.8-1.1-1.6-1.1-2.6c0-1.3-0.9-2.1-1.1-3.3c-0.2-1.2-0.2-2.3-0.8-3.4
                c-0.3-0.5-0.5-1-0.6-1.5c-0.2-0.7-0.5-1.2-0.9-1.6c-0.5-0.6-1-1.1-1.1-1.9c-0.2-0.9-0.6-1.6-1.5-1.9
                C471.5,620.3,471.4,619.1,470.5,618.4z"/>
            <path className="st2 state_level" id="D’abadie/O’meara" d="M624.7,376.7c-0.4,0.1-0.7,0.3-1,0.2c-1.6-0.4-2.9-0.1-4,1.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.5,0.1-0.6,0
                c-0.2-0.2-0.3-0.6-0.3-0.8c0.1-0.7,0.7-1,1.2-1.5c0.4-0.4,0.6-0.9,0.6-1.4c0-1.2-1.4-2.2-2.5-1.8c-0.3,0.1-0.6,0.3-0.8,0.5
                c-0.5,0.7-0.8,1.5-1.6,2c-0.4,0.2-0.4,0.7-0.4,1.1c0,0.4,0.1,0.9,0,1.3c-0.1,0.2-0.4,0.4-0.7,0.6c-0.1,0.1-0.2,0-0.3,0
                c-1.1-0.3-2.1-0.1-3,0.6c-0.6,0.4-1.2,0.3-1.7-0.3c-0.1-0.2-0.3-0.3-0.5-0.4c-0.6-0.4-1.1-0.5-1.8,0c-0.3,0.2-0.5,0.4-0.7,0.6
                c-0.4,0.3-0.9,0.3-1.2,0c-0.2-0.1-0.3-0.3-0.5-0.4c-0.8-0.9-1.6-1.3-2.8-0.7c-0.2,0.1-0.6,0-0.9-0.1c-0.3-0.2-0.2-0.5-0.1-0.8
                c0.2-0.4,0.4-0.7,0.6-1.1c0.2-0.7-0.3-1.6-1.1-1.7c-0.3,0-0.6,0-1,0c-0.4,0.1-0.8,0.2-1,0.6c-0.1,0.3-0.3,0.6-0.4,0.9
                c-0.2,0.3-0.3,0.5-0.5,0.8c-1-0.2-1.6-1-2.3-1.7c-0.4-0.4-0.6-0.8-0.9-1.3c-0.2-0.4-0.5-0.8-0.5-1.1c-0.1-1.7-0.1-3.4-0.1-5.1
                c0-0.5,0.2-1.1,0.3-1.5c0.8-2.2,1.6-4.4,1.9-6.7c0.2-1.5,0.6-2.9,1.5-4.1c0.4-0.6,0.7-1.3,1.1-2c0.6-0.9,0.5-1.9,0-2.7
                c-0.4-0.6-0.9-1.2-1.4-1.7c-0.5-0.5-1.2-0.4-1.8-0.7c-0.4-0.2-0.7-0.5-0.7-0.9c-0.1-1.3-0.1-2.5-0.1-3.8c0-0.4-0.4-0.8-0.8-0.8
                c-1.3-0.1-2.5-0.1-3.8-0.1c-0.6,0-1.3-0.1-1.9-0.2c-0.4,0-0.6-0.4-0.6-0.6c-0.1-1-0.1-1.9,0.5-2.8c0.3-0.4,0.5-0.9,0.8-1.4
                c0.7-1.2,0.8-1.3,2.3-1c4.1,0.6,8.3,0.7,12.4-0.1c0.6-0.1,1.2-0.4,1.8-0.7c0.6-0.4,1.3-0.7,1.9-1.2c1.2-1,2.5-1.6,4-1.9
                c0.5-0.1,1-0.4,1.4-0.6c0.6-0.3,1.2-0.3,1.8,0.2c0.9,0.8,1.8,1.5,2.7,2.2c0.6,0.5,1.4,0.5,2.1,0.2c0.6-0.2,1.1-0.6,1.7-0.9
                c0.4-0.2,0.8-0.2,1.1,0.2c0.2,0.2,0.3,0.6,0.5,0.8c0.6,0.5,1.8,0.6,2.4,0.2c0.2-0.2,0.4-0.5,0.7-0.7c0.2-0.2,0.4-0.5,0.7-0.6
                c1.5-0.5,3-1,4.5-1.4c0.3-0.1,0.7,0,0.9,0.2c0.3,0.1,0.5,0.4,0.6,0.7c0.1,0.3-0.1,0.6-0.3,0.8c-0.4,0.3-1,0.5-1.4,0.8
                c-0.5,0.3-0.7,1.1-0.5,1.7c0.2,0.5,0.5,0.9,0.8,1.4c0.3,0.5,0.8,0.7,1.3,0.8c0.3,0.1,0.6,0,0.9,0.1c0.9,0.2,1.2,0.6,0.9,1.6
                c-0.1,0.4-0.4,0.8-0.4,1.2c0,0.8-0.3,1.4-0.7,2.1c-0.4,0.6-0.2,1.2,0.2,1.7c0.2,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.6,0.4,0.7,0.6
                c0.3,1.5,1.4,2,2.6,2.5c0.4,0.2,0.7,0.8,0.9,1.2c0.2,0.3,0.1,0.6-0.2,0.9c-0.3,0.3-0.7,0.5-1,0.7c-0.9,0.4-1.7,0.9-2.3,1.6
                c-0.9,1-2.1,1.5-3.4,1.4c-0.5,0-1.1,0-1.6,0c-2.3,0-2.3,0-2.3,2.3c0,0.6,0,1.3-0.1,1.9c0,0.4-0.3,0.8-0.8,0.9
                c-0.4,0.1-0.8,0.1-1.3,0.2c-0.2,0.1-0.4,0.3-0.4,0.4c-0.1,0.6-0.2,1.3-0.2,1.9c0,4,0,8.1,0,12.1
                C624.8,374.7,624.7,375.7,624.7,376.7z"/>
            <path className="st4 state_level" id="Joseph" d="M509,317.5c-0.3,1.7-0.4,3.3-0.2,4.9c0.1,0.8,0,1.7-0.4,2.5c-0.6,1.5-1,3-1.1,4.6c0,0.4-0.5,0.8-0.9,0.8
                c-0.7,0.1-1.5,0.2-2.2,0.2c-1.6,0-3.2,0.1-4.8,0.4c-1.9,0.3-3.8,0.4-5.7,0.6c-0.8,0.1-1.3,0.4-1.3,1.1c0,2.1,0,4.2,0,6.4
                c0,1.3,0.4,2.5,0.9,3.7c0.2,0.5,0.3,1,0.5,1.5c0.1,0.3-0.3,0.9-0.7,0.9c-0.5,0-1.1,0.1-1.6,0.1c-0.4,0-0.8-0.3-1.2-0.4
                c-0.3-0.1-0.6-0.3-0.9-0.2c-0.8,0.1-1.7,0.3-2.5,0.4c-0.7,0-1.4-0.5-2.2-0.2c-0.4,0.1-0.8,0.4-1,0.7c-0.4,0.5-0.6,1.1-1.1,1.6
                c-0.3,0.4-0.9,0.6-1.4,0.5c-1-0.2-2-0.5-3.1-0.7c-0.6-0.6-0.3-1.4-0.7-2.1c-0.3-0.6-0.5-1.2-0.7-1.8c-0.1-0.4-0.1-0.8-0.1-1.3
                c0.1-1.9,0.2-3.8,0.2-5.7c0.1-2.7,0.2-5.3,0.3-8c0-0.5-0.2-1-0.8-1.2c-0.6-0.2-0.9-0.6-0.9-1.2c0-1.5,0-3-0.1-4.5
                c0-0.8,0-1.7-0.1-2.5c-0.1-0.7-0.5-1.2-1.1-1.4c-0.4-0.1-0.8-0.2-1.2-0.3c-0.3-0.1-0.6-0.6-0.4-0.7c0.7-0.8,0.7-2,1.2-2.9
                c0.3-0.7,0.8-1.3,1.6-1.3c0.6,0,1.3-0.1,1.9-0.2c0.9-0.2,1.6-0.5,2-1.4c0.4-0.9,1.2-1.5,1.7-2.3c0.4-0.5,0.6-1.1,0.3-1.8
                c-0.2-0.6-0.5-1.2-0.7-1.8c0.3-1,0.6-2.1,0.9-3.1c0.2-0.6,0.8-1.1,1.5-1c0.5,0,1.1,0.1,1.6,0.2c0.6,0.2,1.2,0.5,1.8,0.7
                c1.3,0.5,2.5,0.4,3.7-0.2c0.5-0.2,0.9-0.5,1.2-1c0.4-0.6,0.7-1.3,1-2c0.2,0.1,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.4,0.3,0.6
                c0.6,2.4,2.2,4.1,4,5.6c0.7,0.6,1.5,0.7,2.4,0.5c0.4-0.1,0.8-0.3,1.1-0.5c1.7-1.2,1.8-0.8,3.1-0.1c0.5,0.2,0.9,0.5,1.4,0.8
                c0.1,0.3,0.2,0.6,0.3,0.9c0.4,1.2,0.8,1.4,1.8,1.4c0.6,0,1.3-0.3,1.9,0c0.2,0.1,0.3,0.3,0.4,0.4c0.2,0.3,0,0.6-0.4,0.7
                c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.6,0.8-0.4,1.1c0.4,0.5,0.8,1,1.2,1.5c0.3,0.4,0.1,0.8-0.4,0.9c-0.3,0-0.6,0-0.9,0.1
                c-0.3,0.1-0.6,0.2-0.8,0.5c-0.3,0.4-0.6,0.9-0.8,1.4c-0.3,0.5-0.2,1.3,0.3,1.7c0.3,0.3,0.7,0.5,1,0.7c0.3,0.2,0.6,0.2,0.9,0.3
                C508.3,317.2,508.5,317.3,509,317.5z"/>
            <path 
                className="st7 state_level" 
                id="TT-POS"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-POS') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-POS') ? this.callMapData('TT-POS') : null}
                d="M404.4,299.1c0-0.9,0-1.8,0-2.6c0-1-0.2-1.9-1.2-2.4c-0.6-0.3-0.9-0.9-0.8-1.6c0.1-1-0.1-2.1,0.3-3.1
                c0.1-0.1,0.4-0.2,0.5-0.2c0.3,0.2,0.5,0.4,0.6,0.7c0.4,1.2,1.3,1.6,2.5,1.6c0.7,0,1.5,0,2.2,0.1c0.4,0,0.8,0.4,0.8,0.8
                c0,1.2,0.3,2.3,0.8,3.4c0.3,0.6,0.8,0.9,1.5,0.9c0.3,0,0.6-0.1,0.9-0.1c0.4,0,0.6-0.3,0.6-0.6c0.1-0.4,0.1-0.8,0.1-1.3
                c0-1.4,0-2.8-0.1-4.1c-0.1-1.6,0.5-2.9,1.3-4.2c0.7-1.2,1.9-2,2.4-3.4c0.1-0.3,0.6-0.5,0.9-0.9c0.4-0.4,0.8-0.7,1-1.2
                c0.5-1.2,1.7-1.9,2.4-3c0.3-0.5,1-0.8,1.6-1c1.6-0.6,2.3-1.8,2.5-3.4c0.1-0.4,0.1-0.8,0.1-1.3c0.1-1.1,0.7-2,1.4-2.8
                c0.2-0.2,0.5-0.5,0.8-0.5c1.4,0,2.4-0.8,3.4-1.5c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0.3,0.6,0.5,0.8,0.9c0.3,0.5,0.7,0.8,1.3,0.8
                c0.2,0,0.4,0,0.6,0c1.2-0.2,1.8,0.4,2.1,1.4c0.2,0.6,0.7,1,1.4,1.2c1.2,0.4,2.5,0.6,3.8,0.4c1.4-0.2,2.7,0.5,4,0.9
                c0.3,0.1,0.5,0.4,0.7,0.6c0.8,1,1.8,1.4,3.1,1.4c0.4,0,0.8,0,1.3,0.1c0.5,0.1,0.9,0.7,0.6,1.2c-0.7,1.3-1.2,2.7-2.4,3.7
                c-0.6,0.4-0.6,1.1-0.4,1.8c0.1,0.6,0.4,1.2,0.5,1.8c0.2,0.8-0.1,1.3-1,1.6c-0.8,0.2-1.3,0.6-1.6,1.4c-0.3,0.9-0.6,1.8-1.6,2.2
                c-0.6,0.3-0.9,0.9-1,1.6c0,0.8-0.4,1.4-1,1.9c-0.2,0.2-0.5,0.5-0.5,0.7c0,1-0.4,1.8-1.1,2.6c-0.2,0.2-0.3,0.6-0.3,0.9
                c0,0.8-0.2,1.4-0.9,1.9c-0.2,0.1-0.4,0.2-0.5,0.4c-1,1.6-2.3,2-4.1,1.6c-0.8-0.2-1.4,0.2-1.7,1.1c-0.1,0.3-0.3,0.5-0.5,0.7
                c-0.1,0.1-0.4,0.2-0.6,0.1c-0.3-0.2-0.6-0.5-1-0.8c-0.5-0.4-1-0.8-1.7-0.6c-0.6,0.2-1.1,0.9-1.1,1.8c0,0.4,0,0.9,0.1,1.3
                c0.2,0.9,0.1,1.7-0.4,2.4c-0.6,0.7-0.5,1.6-0.2,2.4c0.3,0.7,0.6,1.3,0.9,2c0.4,0.9-0.4,1.7-1.4,1.5c-0.3-0.1-0.6-0.1-0.9-0.2
                c-0.7-0.1-1.2-0.5-1.3-1.2c-0.1-0.4-0.2-0.8-0.4-1.2c-0.3-0.6-0.8-1-1.5-1c-0.5,0-1.1,0.1-1.4,0.6c-0.3,0.4-0.6,0.9-0.9,1.3
                c-0.2,0.2-0.6,0.4-0.9,0.4c-0.9,0-1.9-0.1-2.6-0.9c-0.8-1-1.9-1.7-3.1-2.2c-2.3-1-4-2.7-5.6-4.6c-0.6-0.7-1.3-1.4-2.1-1.9
                C406.2,300.6,405.3,299.8,404.4,299.1z"/>
            <path className="st7 state_level" id="TT-DMN" d="M396.1,280.5c-0.9,0.3-1.7,0.6-2.5,0.9c-0.4,0.2-0.7,1-0.5,1.4c1.2,2.1,1.6,4.6,2.8,6.7
                c0.4,0.7,0.3,1.4,0.1,2.2c-0.1,0.4-0.4,0.8-0.7,1c-0.3,0.3-0.7,0.4-1.1,0.6c-0.6,0.3-0.9,0.9-1,1.5c-0.1,0.7-0.1,1.5-0.2,2.2
                c-0.1,0.7-0.9,1-1.5,0.5c-0.4-0.3-0.8-0.7-1.2-1c-1.3-1.2-2.8-2.2-4.7-2.8c-0.7-1-0.8-2.4-1.9-3.2c-0.2-0.1-0.3-0.6-0.3-0.9
                c-0.1-0.9,0.1-1.7,0.4-2.5c0.4-1.2,0.4-2.3-0.4-3.3c-0.5-0.7-0.6-1.5-0.6-2.4c0.1-1.6,0.1-1.6-0.9-3.3c0.2-0.4,0.4-0.9,0.6-1.4
                c0.5-1.2,0.3-2.3-0.6-3.2c-0.3-0.3-0.7-0.5-0.9-0.7c-0.1-1.3-0.2-2.4-0.4-3.4c-0.1-1.1-0.5-2.1-1.4-2.8c-0.9-0.8-1.6-1.7-1.9-2.8
                c-0.2-0.6-0.5-1.2-0.9-1.6c-0.7-0.8-1.1-1.5-1-2.6c0.1-0.9-0.2-1.7-0.5-2.5c-0.7-1.9-1-3.9-1-5.9c0-1.4,0-2.8,0-4.1
                c0-1.1,0.1-1.3,1.3-1.2c0.8,0.1,1.5-0.1,2.1-0.4c1.2-0.6,2.4-0.7,3.7-0.7c1.3,0,2.6,0,3.8,0.1c0.3,0,0.7,0.1,0.9,0.3
                c0.5,0.9,1.4,0.9,2.2,0.9c1,0,1.9,0,2.9,0.1c0.4,0,0.7,0.6,0.6,1c-0.2,0.4-0.3,0.8-0.6,1.1c-1.4,1.6-2.4,3.5-3.2,5.5
                c-0.3,0.8-0.8,1.5-1.2,2.2c-0.4,0.7-1,1.4-1.3,2.2c-0.5,1.1-1.2,1.7-2.4,1.7c-0.7,0-1,0.4-1,1c0,1.7,0,3.4,0,5.1
                c0,0.4,0.4,0.8,0.8,0.8c1.3,0.2,2.5,0.1,3.6,0c0.8-0.2,1.1-0.7,1.4-1.1c0.6-0.7,1.2-1.1,2.2-1.1c0.8,0,1.7-0.2,2.5-0.4
                c1.7-0.3,2.6-1.4,2.9-3c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.8-0.6,1.1-0.6c0.4,0.1,0.8,0.2,1.2,0.3c0.7,0.2,1.3,0.1,1.8-0.3
                c0.5-0.4,1-0.8,1.5-1.1c0.5-0.3,1-0.4,1.5-0.4c0.6,0,1,0.2,1.2,0.8c0.1,0.3,0.2,0.6,0.3,0.9c0.2,0.6,1.2,0.7,1,1.5
                c-0.1,0.5-0.7,0.8-0.9,1.2c-0.2,0.3-0.4,0.8-0.4,1.2c0.1,1.2-0.4,2-1.4,2.7c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.4-0.6,0.9-0.5,1.4
                c0.1,1.1,0.3,2.1,0.5,3.2c0,0.2,0.1,0.4,0.2,0.6c0.3,0.5,0.8,1,0.9,1.6c0.1,0.6-0.1,1.2-0.1,1.9c0,0.4,0.2,0.8,0.2,1.2
                c0,0.2-0.2,0.4-0.3,0.5c0,0-0.2,0-0.3-0.1c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.4-0.5-0.7-0.7c-0.7-0.3-1.7-0.2-1.9,0.6
                c-0.2,0.9-0.7,1.2-1.5,1.3c-0.6,0.1-0.9,0.5-1.1,1C396.4,279.2,396.2,279.9,396.1,280.5z"/>
            <path 
                className="st3 state_level" 
                id="TT-CHA"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-CHA') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-CHA') ? this.callMapData('TT-CHA') : null}
                d="M512.7,437.5c-0.5-0.7-0.6-1.6-0.7-2.4c-0.3-1.8-0.5-3.6-0.8-5.4c-0.1-1,0-1.9,0.9-2.5c0.2-0.1,0.3-0.3,0.5-0.4
                c1.5-1.2,2.8-2.6,3.5-4.4c0.4-1,1.1-1.8,1.8-2.6c0.7-0.8,1.4-1.6,2.1-2.4c2.2-2.4,4.2-4.8,5.7-7.7c0.6-1.2,1.4-2.1,2.3-3
                c1.7-1.7,3.4-3.5,5.2-5.2c1.5-1.5,2.6-3.4,3.5-5.3c0.3-0.7,0.5-1.4,0.5-2.1c0-1.6,0-3.2-0.5-4.7c-0.5-1.4-0.9-2.8-1.2-4.3
                c-0.3-1.1-0.5-2.3-0.1-3.4c0-0.1,0.4-0.3,0.5-0.2c0.5,0.4,1.1,0.8,1.3,1.4c0.6,1.3,1.3,2.4,2.5,3.3c0.2,0.2,0.4,0.5,0.6,0.7
                c0.8,0.7,1,1.6,1.1,2.6c0.1,2.9,0.2,5.7,0.2,8.6c0,2,0,4,0.5,6c0.2,0.9,0.1,1.7-0.4,2.5c-0.6,1-1.2,2-1.3,3.2
                c-0.1,0.7-0.6,1.1-1,1.6c-0.5,0.4-1,0.8-1.5,1.2c-1.2,1-1.9,2.3-2.1,3.8c-0.1,0.8-0.2,1.7-0.2,2.5c-0.1,1.6-0.5,3.2-0.9,4.7
                c-0.3,1-0.9,1.7-1.9,2c-2.4,0.6-2.6,1.4-2.5,3.6c0,0.2,0,0.4,0,0.6c0,0.7,0.2,1.2,0.7,1.8c0.2,0.2,0.4,0.5,0.6,0.7
                c1.6,1.4,1.7,3.1,1.3,5c0,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-6.2,0-12.3,0-18.5,0C513.1,437.7,512.9,437.5,512.7,437.5z
                "/>
            <path 
                className="st4 state_level" 
                id="TT-SFO"
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-SFO') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-SFO') ? this.callMapData('TT-SFO') : null}
                d="M470,619.3c0.4,0.5,0.6,0.9,0.8,1.2c0.5,0.9,1.9,0.9,2.1,2.3c0.1,1,1,1.6,1.4,2.4c0.6,1.1,1.1,2.3,1.5,3.5
                c0,0.1-0.1,0.5-0.2,0.5c-0.6,0.1-1.3,0.2-1.9,0.3c-0.4,0.1-0.8,0.3-0.9,0.7c-0.1,0.3-0.2,0.7-0.4,0.8c-0.9,0.4-1.1,1.2-1.1,2.1
                c0,0.8-0.4,1.4-1.1,1.8c-0.6,0.4-0.8,0.9-0.9,1.6c0,0.8,0.2,1.1,1,1.4c0.1,0,0.2,0,0.3,0.1c0.8,0.3,1.1,0.9,0.7,1.7
                c-0.1,0.3-0.3,0.5-0.5,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.7,0.2,1.6,0.9,1.7c0.5,0.1,1.1,0.1,1.6,0.2c0.2,0,0.3,0.3,0.4,0.5
                c0.3,1.2,0,2.3-0.6,3.3c-0.1,0.2-0.2,0.4-0.4,0.5c-1.6,1.1-2.1,2.6-2,4.4c0,0.2,0,0.4-0.1,0.6c0,0.3-0.5,0.8-0.9,0.8
                c-0.9,0-1.7,0-2.6,0.1c-0.4,0-0.8,0-1.3,0.1c-0.4,0-0.9,0.8-0.8,1.2c0.1,0.3,0.1,0.6,0.3,0.9c0.8,1.3,1.2,2.7,1.3,4.2
                c0,0.7,0.3,1.4,0.5,2.2c0.1,0.6,0.1,1.3-0.3,1.8c-0.2,0.4-0.3,0.8-0.6,1.1c-0.8,0.9-1.1,2-1.3,3.2c0,0.2-0.2,0.4-0.3,0.6
                c-2.4,1.4-5.2,1.6-7.9,1.9c-0.4,0-0.9,0-1.3,0c-3.2,0-2.5-0.1-4.3,2c-0.1,0.1-0.2,0.1-0.4,0.3c-0.7,0.1-1.6,0.1-2.4,0.2
                c-0.9,0.1-1.7,0.7-1.6,1.6c0.2,1.7-1,2.2-2.1,2.8c-0.2,0.1-0.7-0.1-0.8-0.3c-0.1-0.4-0.3-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.2-0.6
                c-0.3-0.5-1.1-0.9-1.6-0.6c-0.6,0.3-1.1,0.6-1.7,0.9c-0.6,0.3-3.1,1-3.7,1.2c-0.8,0.2-1.6,0.5-2.5,0.6c-0.5,0.1-1-0.1-1.6-0.1
                c-0.1,0-0.2-0.1-0.2-0.2c0-0.2-0.1-0.5,0-0.6c1.9-1.2,3.7-2.4,5.6-3.6c1.8-1.1,3.6-2.2,5.4-3.4c3.6-2.4,6.6-5.3,9.1-8.9
                c0.8-1.1,1.7-2.2,2.5-3.3c0.4-0.6,0.9-1.2,1.3-1.8c0.8-1.3,1.1-2.7,0.7-4.3c-0.2-0.7-0.3-1.5-0.5-2.2c-0.4-1.3-0.1-2.5,0.7-3.6
                c0.5-0.8,1.2-1.5,1.9-2.2c1.3-1.2,2.7-2.4,4.1-3.6c1.3-1.2,2.7-2.4,4-3.7c0.9-0.9,1.6-2,2.2-3.1c0.7-1.4,0.9-2.8,0.8-4.3
                c-0.2-1.7-1-3-2.6-3.7c-1.1-0.5-2.3-1-3.6-1.1c-0.7,0-1.2-0.2-1.7-0.7c-0.3-0.3-0.3-1,0-1.1c0.7-0.3,1.5-0.5,2-1
                c0.9-1.1,2.1-1.4,3.4-1.4C467.9,619.3,468.9,619.3,470,619.3z"/>
            <path 
                className="st7 state_level" 
                id="TT-SJL" 
                style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-SJL') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-SJL') ? this.callMapData('TT-SJL') : null}
                d="M444.9,336.7c-0.1-0.3-0.3-0.6-0.3-0.9c0-4.9,0-9.8,0-14.7c0-0.7,0.3-1.2,1-1.4c0.7-0.2,1-0.7,1-1.4
                c0-0.4-0.1-0.8-0.1-1.3c0.1-1.1-0.2-2.1-1-2.9c-0.3-0.3-0.1-0.9,0.3-1.1c0.5-0.1,1-0.2,1.5-0.4c0.8-0.2,1.2-0.9,1.1-1.7
                c0-0.4-0.1-0.9-0.4-1.2c-0.4-0.6-0.4-1.1-0.3-1.8c0.2-1.3,0.2-2.5,0.2-3.8c0-0.8-0.4-1.4-1-1.9c-1-0.8-2.2-1.3-3.3-1.8
                c-0.6-0.3-1.2-0.3-1.8,0c-0.3,0.1-0.6,0.3-0.9,0.3c-0.4,0.1-0.8-0.1-1.1-0.4c-0.3-0.3-0.2-0.8,0.2-1.1c0.4-0.4,0.9-0.6,1.2-1
                c0.3-0.3,0.5-0.7,0.6-1.1c0-0.8,0.3-1.4,0.7-2.1c0.3-0.5,0.4-1.2,0.7-1.7c0.3-0.5,0.8-1,1.1-1.5c0.3-0.7,0.3-1.5,0.7-2.1
                c0.3-0.5,1-0.7,1.5-1.2c0.2-0.2,0.3-0.5,0.5-0.8c0.2-0.5,0.2-1.1,0.8-1.5c0.3-0.1,0.9-0.2,1.3-0.4c0.9-0.3,1.6-0.3,2.4,0.4
                c0.5,0.4,1.1,0.7,1.7,0.7c0.2,0,0.5,0.1,0.6,0.2c0.2,0.2,0.5,0.5,0.5,0.7c0,0.2-0.3,0.6-0.5,0.7c-0.4,0.2-0.8,0.2-1.2,0.2
                c-1,0-1.6,0.4-2,1.3c-0.9,1.8-0.8,2.2,1,3.2c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.1,0.2,0.3,0.4c0,1,0.1,2.1,0.1,3.3
                c0.1,0.9,0.5,1.6,1.3,2c1.3,0.5,2.2,1.7,3.6,1.9c0.2,0,0.4,0.2,0.5,0.3c1,1.2,2.3,1.5,3.7,1.4c0.5,0,1,0.1,1.5,0.4
                c1.5,1,3.3,1.3,5,1.8c0.4,0.1,0.8,0.2,1.2,0.4c0.2,0.1,0.4,0.4,0.6,0.7c0.2,0.3,0,0.5-0.4,0.7c-0.4,0.2-0.8,0.3-1.2,0.4
                c-0.8,0.2-1,0.8-0.9,1.5c0,0.2,0.1,0.4,0,0.6c0,0.3,0,0.7-0.2,0.9c-1.1,1.2-1,2.5-0.4,3.9c0,0.1,0,0.2,0,0.4
                c-0.4,0.5-0.8,1.1-1.3,1.7c-0.2,0.2-0.5,0.4-0.8,0.4c-0.6,0.1-1.3,0.1-1.9,0.1c-1.7,0-3.4,0-5.1,0c-0.7,0-1.5,0.1-2.2,0.2
                c-0.4,0.1-0.7,0.4-1,0.8c-0.3,0.5-0.3,1.1,0,1.5c0.3,0.3,0.6,0.6,1,0.8c0.5,0.4,0.5,0.9,0.5,1.4c-0.1,0.5-0.3,1-0.5,1.5
                c-0.2,0.5-0.2,1,0,1.5c0.3,0.6,0.4,1.1,0.1,1.8c-0.3,0.6-0.4,1.2-0.4,1.8c0,1.9-0.6,3.8-0.5,5.7c0.1,0.7-0.4,1.2-1.1,1.4
                c-0.9,0.2-1.9,0.3-2.7,0.9c-1.4,0.9-2.9,1.1-4.6,0.8C446,336.8,445.5,336.8,444.9,336.7z"/>
            <path className="st7 state_level" id="Arouca/Maloney" d="M552.5,323.6c1.7-0.3,3.2,0.2,4.6,0.8c1.5,0.7,3,1.5,4.7,1.9c0.7,0.2,1.4,0.6,1.9,1.1c1.3,1.1,2.5,2.3,3.7,3.5
                c0.7,0.8,1.5,1.4,2.6,1.6c0.3,0.1,0.6,0.2,1,0.3c0.3-0.1,0.6-0.1,0.9-0.2c2.7-1.7,5.7-1.4,8.7-1.1c0.5,0.1,1,0.3,1.5,0.6
                c0.4,0.2,0.7,0.5,1,0.8c1.2,1.1,2.5,1.9,4.3,1.6c0.3-0.1,0.6,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.5c-0.2,1.1-0.8,2-1.3,2.9
                c-0.3,0.5-0.5,0.9-0.5,1.5c-0.2,3-0.6,3,2.7,3c1.3,0,2.5,0,3.8,0.1c0.4,0,0.8,0.4,0.8,0.8c0,0.3,0.1,0.6,0.1,1
                c0,2.5,0.1,2.8-2.6,2.6c-1.2-0.1-2.3,0-3.4,0.4c-1.3,0.5-2.7,0.7-4.1,0.4c-1.5-0.3-3,0-4.4,0.5c-1.3,0.4-2.7,0.6-4.1,0.7
                c-1.5,0.1-3-0.1-4.1-1.2c-0.3-0.3-0.7-0.4-1.1-0.6c-1-0.6-2.1-1-3.3-0.9c-0.4,0-0.8,0-1.3,0c-0.5,0-1.1-0.1-1.5-0.4
                c-1-0.7-2.1-1.1-3.2-1.4c-0.7-0.2-1.1-0.6-1.4-1.2c-0.3-0.6-0.5-1.2-0.7-1.8c-0.4-1-1-1.9-1.9-2.5c-2-1.3-3.2-3.4-4.7-5.1
                c-0.6-0.7-0.6-1.6-0.2-2.4c0.2-0.3,0.3-0.5,0.5-0.8c0.4-0.8,0.5-1.8,0-2.5c-0.9-1.2-0.6-2.4-0.3-3.7
                C552.2,324.3,552.4,324,552.5,323.6z"/>
            <path className="st3 state_level" id="TT-SJL" d="M468.5,308.4c1.7-0.4,2.8-0.9,4.1-0.9c0.3,0,0.6,0,0.9,0.1c0.2,0,0.3,0.3,0.4,0.4c0.1,0.2,0,0.4,0,0.6
                c-0.2,0.5-0.8,0.7-1.2,1c-0.5,0.4-0.8,1.1-0.4,1.7c0.8,1.3,0.4,2.4-0.1,3.6c-0.2,0.4-0.5,0.7-0.6,1.1c-0.3,0.7,0.1,1.5,0.9,1.7
                c0.1,0,0.2,0,0.3,0c1.6,0.4,1.8,0.6,1.9,2c0,2,0,4,0,6.1c0,0.4,0,0.8,0.1,1.3c0,0.2,0.2,0.4,0.4,0.5c1.1,0.3,1.1,1.1,1.1,2
                c-0.1,3.5-0.2,7-0.3,10.5c0,0.7-0.1,1.5-0.2,2.2c-1,0.5-1.9,1-2.7,1.5c-0.8,0.2-1.4-0.3-2-0.4c-0.5-0.1-1-0.3-1.5-0.5
                c-0.7-0.3-1.2-0.1-1.7,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.5,0.3-1,0.5-1.5,0.2c-0.6-0.5-1.4-0.6-2.1-0.6c-0.5,0-0.8-0.3-1-0.7
                c-0.1-0.3-0.2-0.6-0.4-0.8c-0.6-0.8-1.9-0.8-2.4,0.2c-0.3,0.5-0.5,1.2-0.8,1.7c-0.1,0.3-0.8,0.5-1.1,0.3c-0.3-0.2-0.5-0.3-0.8-0.6
                c-0.7-0.7-1.3-1.4-2-2c-0.5-0.5-1.1-0.6-1.8-0.4c-0.3,0.1-0.6,0.2-0.9,0.3c-1.3,0.4-1.9-0.2-2-1.3c-0.1-0.7,0-1.5,0-2.1
                c1.1-1,2.2-1.4,3.5-1.6c1-0.1,1.4-0.8,1.6-1.7c0-0.2,0.1-0.4,0.1-0.6c-0.2-1.8,0.4-3.6,0.3-5.4c0-0.5,0.2-1,0.4-1.5
                c0.1-0.4,0.3-0.8,0.3-1.2c0-0.7-0.4-1.5-0.3-2.2c0.1-0.7,0.6-1.3,0.5-2.1c0-1-0.4-1.8-1.3-2.4c-0.1-0.1-0.3-0.4-0.2-0.5
                c0.2-0.2,0.4-0.5,0.7-0.6c0.3-0.1,0.6-0.1,0.9-0.1c1.1,0,2.1,0,3.2,0c1.5,0,3,0,4.5,0c0.7,0,1.2-0.3,1.6-0.8c0.3-0.3,0.4-0.8,0.7-1
                c0.9-0.8,1-1.5,0.4-2.5c-0.3-0.6-0.3-1.3,0.1-1.8C468.6,310.5,468.9,309.6,468.5,308.4z"/>
            <path className="st2 state_level" id="TT-SFO" d="M488.4,649.5c0.3,0.1,0.6,0.3,0.9,0.3c0.8,0.1,1.7,0.1,2.5,0.1c1.1,0,1.4,0.4,1.4,1.6c0,0.2,0,0.4,0,0.6
                c0,0.5-0.2,0.7-0.8,0.8c-0.3,0.1-0.7,0-0.9,0.2c-0.2,0.1-0.5,0.4-0.5,0.7c-0.1,0.6-0.1,1.3,0.3,1.8c0.3,0.4,0.7,0.8,1,1.2
                c0.2,0.3,0.2,0.8,0,1.2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.3,0.3-0.6,0.6-0.9,0.9c-0.9,1-1.8,2.1-1.8,3.6c0,0.4-0.6,0.7-1,0.6
                c-0.3-0.1-0.6-0.1-0.9-0.3c-1-0.5-1.8-0.3-2.5,0.6c-0.4,0.5-0.9,0.5-1.5,0.3c-0.4-0.2-0.7-0.4-1.1-0.6c-1.6-0.8-3.3-1.5-5.2-1.1
                c-0.5,0.1-1.1,0.1-1.6,0.1c-1-0.1-1.9,0.1-2.7,0.7c-0.8,0.6-1.5,0.6-2.4,0.1c-0.7-0.5-1.4-0.9-2.1-1.3c-0.7-1.4-0.6-2.8-0.9-4.1
                c-0.3-1.2-0.7-2.4-1.1-3.6c0.5-0.4,1-0.3,1.5-0.4c1,0,1.9,0,2.9-0.1c0.4,0,0.8-0.4,0.8-0.8c0-0.5,0.1-1.1,0.1-1.6
                c0-0.5,0-1.1,0.1-1.6c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.3,0.4-0.5,0.7-0.7c1.3-1.1,1.8-2.6,1.9-4.2c0.1-1.3-0.3-1.9-1.7-2.1
                c-0.3-0.1-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.3-0.5c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.6,0.5-1.2,0.5-1.8c0-0.8,0-1.4-1.2-1.5
                c-0.3,0-0.6-0.1-0.9-0.3c-0.1-0.1-0.1-0.4-0.1-0.6c0-0.1,0.1-0.2,0.2-0.3c1.1-0.7,1.8-1.5,1.6-2.8c0-0.3,0.2-0.7,0.5-0.8
                c0.6-0.3,0.9-0.8,1.2-1.4c0.3-0.6,1.4-0.8,2-0.4c0.2,0.1,0.4,0.3,0.4,0.4c0.4,1.3,1.3,2.4,1.3,3.9c0,0.9,0.6,1.6,1,2.3
                c0.5,0.8,1.1,1.2,2,1.3c0.5,0,1.1,0.2,1.6,0.1c1-0.1,1.9,0.2,2.6,0.9c0.6,0.6,1.4,1,2.3,1c1.1,0,2,0.5,2.9,1.2c1,0.8,2,1.6,2.9,2.4
                c0.1,0.1,0,0.5-0.1,0.5c-1.3,0.4-2.3,1.5-3.6,2c-1,0.4-1.2,1.1-1.1,2C488.3,648.8,488.3,649.2,488.4,649.5z"/>
            <path className="st7 state_level" id="Laventille West" d="M430.2,324c0-0.8,0-1.5-0.1-2.2c-0.1-1.1,0.3-1.8,1.3-2.3c0.5-0.3,0.7-0.8,0.8-1.3c0-0.5,0-1.1-0.1-1.6
                c-0.1-0.7,0-1.3,0.8-1.5c0.2-0.1,0.4-0.1,0.6-0.3c0.8-0.9,1.5-1.7,2.3-2.6c0.4-0.5-0.1-1.4-0.7-1.5c-0.6,0-1.3-0.1-1.9-0.1
                c-0.8,0-1.7,0-2.4-0.1c-0.8-0.6-1.1-1.4-1.4-2.2c-0.2-0.5-0.3-1,0.1-1.5c0.7-0.9,0.8-1.9,0.7-3c-0.1-0.5,0-1,0.1-1.6
                c0-0.3,0.6-0.5,0.8-0.3c0.2,0.2,0.4,0.4,0.7,0.6c1.2,1.1,2.2,0.9,3.1-0.5c0.1-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.6-0.4,0.8-0.4
                c0.8,0.1,1.7,0.3,2.5,0c0.3-0.1,0.7-0.1,0.9,0.1c1.3,0.8,2.6,0.6,3.9,0.1c1.6,0.6,3.1,1.1,4.2,2.5c0.2,1.2,0.1,2.4,0,3.7
                c-0.9,1.2-0.5,2.1,0.4,3.1c0.4,0.4,0,1.2-0.5,1.3c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0.1-1.4-0.2-1.7,0.5c-0.3,0.8-0.4,1.8,0.2,2.4
                c0.6,0.7,0.8,1.4,0.8,2.3c0,0.3,0.1,0.6,0.1,0.9c0,0.3-0.2,0.6-0.6,0.7c-0.4,0.1-0.8,0.1-1.2,0.2c-0.1,0-0.2,0.1-0.3,0.2
                c0,0.9-0.1,1.8-0.1,2.8c0,4.6,0,9.2,0,13.7c0,0.7-0.1,1.5-0.1,2.2c-0.1,0.5-0.8,0.8-1.2,0.5c-0.3-0.2-0.5-0.4-0.7-0.6
                c-1.1-0.8-2.3-1.1-3.6-1c-0.9,0.1-1.7,0.4-2.2,1.1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.7,0.5-1.1,0.5c-0.9,0.1-1.9-0.2-2.9,0
                c-1,0.3-1.4,1.2-2.2,1.8c-0.2,0.1-0.8,0.1-0.9-0.1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.4-0.5-0.7-0.6-1.1c-0.5-1.4-0.6-2,0.7-3.1
                c1.9-1.6,3.5-3.4,4.8-5.5c0.2-0.4,0.5-0.7,0.7-1.1c0.8-1.5,0.7-3-0.8-4.3C431.6,325,430.9,324.6,430.2,324z"/>
            <path className="st7 state_level" id="Port-Of-Spain South" d="M396.8,303.8c-1-1.8-2.2-3.4-3.7-4.7c0.4-0.7,0.7-1.3,0.9-1.8c0.2-0.6,0.1-1.3,0.2-1.9c0-0.7,0.3-1.2,1-1.4
                c1-0.3,1.6-1.1,1.8-2.1c0-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.9-0.5,1.1-0.1c0.2,0.4,0.4,0.7,0.6,1.1c0.3,0.6,0.6,1.1,1.3,1.2
                c1.3,0.3,2.2,1.2,3.1,2.1c0,1.3,0,2.5,0,3.9c1.1,1,2.3,1.9,3.5,2.7c0.5,0.4,1.1,0.7,1.4,1.2c1,1.4,2.4,2.4,3.6,3.6
                c0.5,0.5,1.2,0.9,1.9,1.2c1.4,0.7,2.7,1.5,3.9,2.8c0.9,1,2.2,1.1,3.6,0.9c0.4-0.1,0.8-0.4,1-0.7c0.4-0.4,0.8-1,1.3-1.4
                c0.1-0.1,0.4,0,0.6,0.1c0.5,0.3,0.6,0.9,0.7,1.4c0.2,1.1,1,1.6,2,1.9c0.4,0.1,0.8,0.1,1.3,0.2c0.9,0.1,1.7-0.1,2.2-0.9
                c0.3-0.5,0.8-0.8,1.3-0.8c0.7,0,1.5,0,2.2,0.1c0.3,0,0.4,0.4,0.3,0.8c-0.5,0.9-1,1.8-2.1,2.2c-0.9,0.3-1,0.9-0.8,2.1
                c0.1,0.3,0.2,0.6,0.2,0.9c0,0.4-0.1,0.8-0.5,1.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.6,0.3-0.9,0.8-1,1.5c-0.1,0.7-0.1,1.5-0.1,2.2
                c-0.1,0.9-0.4,1.2-1.5,1.2c-1.2,0-2.3-0.1-3.5-0.4c-1.2-0.4-2.1-1.1-2.6-2.2c-0.3-0.6-0.5-1.2-0.9-1.7c-0.2-0.4-0.6-0.6-1-0.7
                c-0.7-0.1-1.5-0.1-2.2-0.1c-0.7,0-1.2-0.2-1.8-0.6c-1.5-0.9-3-1.6-4.5-2.4c-1.1-0.6-2.3-1-3.5-1.6c-1.8-1-3.6-1.9-5.6-2.1
                c-1.1-0.1-1.9-0.8-2.6-1.7c-0.6-0.9-1.2-1.8-2-2.5c-0.8-0.7-1-1.6-1-2.6C396.6,304.8,396.7,304.3,396.8,303.8z"/>
            <path className="st7 state_level" id="TT-DMN" d="M278.1,263.2c0.6-0.8,1.7-0.9,2.2-1.8c0.1-0.2,0.5-0.4,0.8-0.4c0.7-0.1,1.2-0.4,1.8-0.6c0.2-0.1,0.6,0,0.9,0.2
                c0.3,0.2,0.4,0.5,0.6,0.7c0.7,0.6,1.3,0.6,1.9,0c0.4-0.5,0.8-1,1.4-1.3c0.3-0.1,0.5-0.5,0.6-0.7c0.3-1.2,1-1.5,2.1-1.2
                c0.3,0.1,0.7,0.1,0.9,0c0.9-0.3,1.6,0,2.2,0.7c0.6,0.7,1.6,0.4,2.1-0.4c0.2-0.3,0.2-0.6,0.4-0.9c0.3-0.5,0.7-0.8,1.3-0.9
                c0.9-0.1,1.9-0.2,2.8-0.2c1-0.8,1.9-1.5,2.8-2.3c0.8-0.6,1.2-1.5,1.6-2.3c0.5-1,0.9-1.9,2-2.4c1-1.2,2.4-2.1,2.9-3.7
                c0.2-0.5,0.6-0.6,1-0.4c0.4,0.2,0.7,0.4,1.1,0.6c0.4,0.2,0.9,0.1,1.1-0.3c0.3-0.5,0.4-1,0.7-1.4c0.2-0.1,0.3-0.3,0.5-0.3
                c2-0.8,2.5-0.7,3.7-3.2c0-0.1,0.1-0.2,0.2-0.3c0.3-0.4,1-0.6,1.4-0.4c0.4,0.2,0.7,0.5,1.1,0.6c0.9,0.4,1.9,0.3,2.8-0.2
                c0.3-0.2,0.7-0.5,0.7-1c0-0.5-0.2-0.8-0.6-1c-0.4-0.2-0.8-0.4-1.1-0.6c-0.1,0-0.2-0.2-0.2-0.2c0.1-0.2,0.2-0.4,0.3-0.5
                c0.1-0.1,0.2-0.2,0.3-0.2c1.5-0.2,2.6-0.9,3.1-2.4c0.2-0.6,0.7-1.1,1.2-1.5c1.1-1,2.4-1.8,3.5-2.7c0.6-0.5,1.4-0.6,2.1-0.5
                c0.3,0,0.6,0.1,0.9,0.2c0.9,0.4,1.8,0.5,2.8,0.3c1.1-0.2,2.1,0,3.1,0.5c0.3,0.1,0.6,0.3,0.8,0.5c0.7,0.6,1.6,0.6,2.4,0.4
                c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.4,0.2,0.4,0.4c0.2,0.8,0.2,1.7-0.2,2.5c-0.1,0.2-0.3,0.3-0.4,0.5c-0.8,1.5-0.6,2.3,1.1,2.9
                c0.8,0.3,1.3,0.6,1.3,1.5c0,0.2,0,0.4,0.1,0.6c0.2,1.1,1,1.6,2.1,1.5c0.1,0,0.2,0,0.3,0c1-0.1,1.7-1,2.7-0.8
                c0.9,0.1,1.7-0.2,2.5-0.4c0.7-0.2,1.5,0,2.2,0c0.6,0,1.2-0.1,1.8-0.5c0.6-0.4,1.2-0.8,1.9-1.2c1.6-0.9,1.6-0.9,3,0.4
                c0.3,0.3,0.6,0.7,1,0.8c1,0.3,1.5,1.1,2,2c0.3,0.6,0.8,1,1.5,1.1c0.4,0.1,0.8,0.3,1.2,0.3c1.4-0.2,2.2,0.3,2.6,1.7
                c0.2,0.5,0.6,0.9,1.2,1c0.9,0.1,1.3,0.7,1.3,1.5c0,1.5,0,3,0,4.5c0,2.2,0.2,4.5,1.1,6.6c0.2,0.6,0.3,1.2,0.4,1.9
                c0.1,0.5,0,1.1,0,1.6c1,1.2,2,2.3,2.3,3.8c0.2,0.9,0.9,1.4,1.6,2c0.9,0.7,1.3,1.7,1.3,2.8c0,0.5,0.2,1.1,0.2,1.6
                c0,1.2,0.4,2,1.3,2.8c0.6,0.5,0.7,1.3,0.4,2c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.2-0.2,0.4-0.2,0.6c-0.2,0.5-0.1,1,0.3,1.5
                c0.3,0.3,0.5,0.7,0.7,0.9c0,0.4,0,0.7,0,1c-0.2,1.1-0.1,2.1,0.5,3c0.6,0.8,0.6,1.8,0.3,2.8c-0.2,0.8-0.6,1.6-0.5,2.5
                c0,0.7,0.1,1.3,0.6,1.7c0.3,0.3,0.6,0.6,0.9,0.9c0.5,0.8,0.4,1.5-0.5,1.8c-1.2,0.4-2.3,1.1-3.4,1.8c-0.8,0.5-1.7,0.9-2.6,1.2
                c-0.7,0.3-1.5,0.3-2.2,0c-0.6-0.2-1.2-0.5-1.5-1.2c-0.2-0.5-0.4-1-0.6-1.5c-0.8-1.9-2.8-2.8-4.7-2.2c-0.4,0.1-0.8,0.3-1.2,0.5
                c-1.5,0.5-3,0.6-4.4-0.1c-4.2-2-8.6-3-12.8-4.8c-0.5-0.2-1-0.4-1.5-0.5c-1.1-0.2-2.1,0.1-2.8,1c-0.4,0.5-0.7,1.1-1.1,1.5
                c-0.7,0.8-2.3,0.6-2.7-0.4c-0.2-0.4-0.4-0.8-0.4-1.2c-0.2-1.2-0.6-2.2-1.6-3c-0.9-0.7-1.3-1.7-1.7-2.7c-0.2-0.5-0.5-0.9-1.1-1.1
                c-0.7,0.2-0.9,0.8-1.3,1.3c-0.6,0.7-1.3,1.1-2.2,1.1c-0.8-0.1-1.4,0.4-1.9,1c-0.4,0.5-0.9,1-1.1,1.5c-0.4,0.9-1,1.6-1.9,2
                c-0.8,1.1-1.8,1.3-3.1,1c-1-0.2-2.1-0.3-3-0.9c-0.4-0.3-0.8-0.2-1.2,0.1c-0.6,0.4-1.2,0.9-1.8,1.4c-0.2,0.2-0.4,0.5-0.4,0.8
                c-0.1,0.6-0.1,1.3,0,1.9c0.1,0.8,0.5,1.4,1.3,1.6c0.5,0.2,1.2,0,1.4-0.4c0.3-0.4,0.5-1,0.8-1.4c0.1-0.1,0.4-0.2,0.5-0.2
                c1,0.7,2.3,1.1,2.7,2.5c0.1,0.5,0.6,0.6,1,0.7c0.6,0.1,1.3,0.1,1.8,0.5c0.3,0.2,0.4,1.3,0,1.4c-1.4,0.6-2.8,1.5-4.5,1.4
                c-0.4,0-0.9,0-1.3,0c-1.1,0.1-2.1,0.4-2.7,1.5c-0.3,0.4-0.6,1-1.1,1.1c-1.4,0.5-2.3,1.5-3.3,2.4c-0.5,0.4-1.3,0.4-1.8,0
                c-0.7-0.5-1.4-1-2-1.6c-0.7-0.6-1.5-1.2-2.5-1.3c-0.3,0-0.5-0.4-0.7-0.6c0.3-0.5,0.5-1,0.8-1.4c-0.4-0.5-0.7-0.9-1-1.2
                c0.1-0.2,0.1-0.4,0.1-0.6c0.8-1.4,2-2.4,2.6-4c0.2-0.5,0.7-0.7,1.3-0.6c0.3,0.1,0.6,0.1,0.9,0.1c0.6-0.1,1-0.4,1.1-1
                c0.1-0.4,0-0.8-0.3-1.2c-0.5-0.4-0.9-0.9-1.4-1.3c-0.6-0.5-1.5-0.5-2,0c-0.2,0.1-0.3,0.3-0.4,0.5c-0.5,0.8-1.3,1-2.2,0.8
                c-0.5-0.1-1.1-0.1-1.6-0.3c-1.6-0.4-3.1-0.5-4.7-0.1c-1.6,0.3-3.2,0.4-4.8,0.1c-1.1-0.2-1.9,0.2-2.3,1.2c-0.1,0.2-0.2,0.4-0.4,0.5
                c-0.2,0.2-0.9,0.3-1.2,0.1c-0.4-0.3-0.9-0.6-1.2-1c-1-1.2-2.2-1.6-3.7-1.5c-0.4,0-0.8,0.1-1.3,0.1c-1,0-1.9,0-2.9,0
                c-0.9,0-1.3-0.3-1.2-0.7c0.1-0.5,0.5-0.7,0.9-0.8c0.3-0.1,0.6-0.2,0.9-0.3c0.9-0.4,1.2-1.1,1.2-2.1c0-1.1-0.6-2-1.6-2.1
                c-1.7-0.2-2.7-1.5-3.9-2.5c-0.1-0.1-0.1-0.4,0-0.6c0.2-0.4,0.4-0.7,0.7-1c0.6-0.6,1.1-1.3,1.5-2.1c0.2-0.5,0.6-0.8,0.9-1.3
                c0.3-0.4,0.7-0.8,0.9-1.3c0.3-0.7,0.6-1.3,1.3-1.7c0.8-0.5,1-1.3,1-2.2c0-0.7-0.1-1.5-0.4-2.2c-0.3-0.6-0.9-1-1.5-1
                c-0.7,0.1-1.3,0.5-1.3,1.2c-0.1,1.3-0.7,2.2-1.5,3.1c-0.2,0.2-0.5,0.4-0.8,0.5c-0.8,0.3-1.8-0.5-1.7-1.4c0-0.1,0-0.2,0-0.3
                c0.6-2.1,0-2.5-1.7-3c-0.2,0-0.4-0.2-0.5-0.3c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.2,0.1-0.4,0.2-0.6C278.5,265.3,278.4,264.2,278.1,263.2z
                M257.9,273.4c-0.2,0.9-0.7,1.5-1.6,1.9c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.5-0.4,1-0.5,1.5c0,0.3,0.2,0.6,0.4,0.8
                c0.6,0.5,0.6,1.1,0.2,1.7c-0.5,0.7-0.2,1.2,0.4,1.6c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.3,0.3,1,0,1.2c-0.9,0.5-1.6,1.4-2.7,1.7
                c-0.7,0.2-1.2,0.6-1.4,1.2c-0.4,0.8-1,1.4-1.7,1.9c-0.6,0.4-1.2,0.9-1.6,1.5c-0.1,0.3-0.1,0.7,0.1,0.9c0.2,0.1,0.4,0.2,0.6,0.2
                c0.6,0,1-0.3,1.4-0.6c0.5-0.5,1-1.1,1.5-1.6c0.6-0.6,1.6-0.3,1.8,0.4c0.2,0.5,0.2,1,0.4,1.5c0.1,0.3,0.4,0.5,0.7,0.5
                c0.3,0,0.6-0.2,0.8-0.4c0.2-0.2,0.2-0.6,0.4-0.9c0.4-0.7,1-0.7,1.5,0c0.1,0.1,0.1,0.2,0.1,0.3c0.3,1,1,1.3,1.9,1.4
                c0.5,0,1.1,0,1.6,0c0.5,0,1,0.1,1.5,0.5c1.2,0.9,2.4,0.6,3.6,0.2c0.1,0,0.3-0.3,0.2-0.5c0-0.4-0.3-0.8-0.7-1
                c-0.5-0.2-1.1-0.1-1.5-0.4c-1.1-0.6-2.2-1.3-2.9-2.4c-0.2-0.2-0.3-0.6-0.2-0.9c0.2-0.9,0.5-1.6,1.3-2.1c0.3-0.2,0.9-0.2,1.2,0.1
                c0.2,0.2,0.4,0.5,0.6,0.7c0.3,0.4,0.7,0.8,1,1.2c0.2,0.3,1,0.1,1.1-0.2c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.5,0.7-0.6,1.4-0.5
                c0.4,0.1,0.8,0.5,0.8,0.9c0,0.5-0.1,1.1,0,1.6c0,0.2,0.4,0.3,0.7,0.5c0.3-0.7,0.6-1.2,0.9-1.8c0.2-0.4,0.6-0.5,1.1-0.3
                c0.4,0.1,0.8,0.3,1.2,0.4c0.3,0.1,0.6-0.3,0.5-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.4-1-0.8-2-1.3-2.9c-0.6-1-0.5-2-0.7-3.1
                c-0.1-0.3-0.3-0.6-0.5-0.8c-0.3-0.4-0.7-0.8-1-1.2c-0.2-0.3-0.3-0.8-0.4-1.2c-0.1-0.6,0.2-1.3,0.7-1.6c0.5-0.3,1.3-0.2,1.7,0.4
                c0.3,0.4,0.6,0.9,0.8,1.4c0.2,0.5,0.5,0.9,1.1,1c0.4,0,0.7-0.6,0.8-1.5c0-0.2,0-0.4,0-0.6c0.1-0.8-0.2-1.4-0.9-1.9
                c-0.9-0.6-1.1-1.5-1-2.5c0.1-0.5,0-1.1,0-1.6c0-0.6-0.8-1.1-1.4-1.1c-0.5,0-1,0.4-1.2,0.9c-0.2,0.5-0.2,1-0.4,1.5
                c-0.4,1-1.8,1.5-2.7,1c-0.6-0.4-1.2-0.8-1.9-1.2c-0.6-0.3-1.3-0.2-1.7,0.3c-0.5,0.7-1,1.4-1.5,2c-0.6,0.7-1.3,1-2.2,1
                c-0.7,0-1.5-0.1-2.1-0.2C258.2,273,258,273.2,257.9,273.4z M199.9,294.1c-0.7,1.1-1.6,2-2.9,2.4c-0.4,0.1-0.7,0.5-0.9,0.8
                c-0.3,0.4-0.5,1-0.6,1.5c-0.1,0.4,0,0.8,0.4,1.1c0.4,0.3,0.9,0.4,1.2,0.1c1-1,2.2-1.2,3.5-1c0.5,0.1,1-0.4,1.2-0.9
                c0.2-0.8,0.3-1.7,0.5-2.5c0.1-0.5,1.1-0.9,1.6-0.7c0.4,0.2,0.7,0.4,1.1,0.7c0.6,0.5,1.3,0.7,2.1,0.5c1.1-0.3,2.1,0,3,0.7
                c0.2,0.2,0.5,0.4,0.8,0.6c1.4,0.8,3,1.1,4.6,0.8c0.3-0.1,0.6-0.3,0.7-0.6c0.1-0.2-0.1-0.7-0.2-0.9c-1.5-0.9-2.7-2.1-3.8-3.3
                c-0.8-0.9-1.9-1.3-3.1-1.4c-0.6,0-1.3,0-1.9,0c-0.3,0-0.6,0-1,0c-0.7-0.1-1.2-0.4-1.6-1c-0.4-0.5-0.3-1,0.1-1.5
                c0.3-0.5,0.4-0.9,0.3-1.5c-0.1-0.4-0.1-0.8-0.2-1.3c0-0.3,0.5-0.8,0.8-0.8c0.3,0,0.6,0.1,0.9,0.1c0.3,0,0.6-0.1,0.6-0.5
                c0.1-0.5,0-1.1,0.5-1.4c0.6-0.4,1.2-0.1,1.8,0.2c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6-0.2,0.7-0.5c0.1-0.3,0-0.6,0.1-0.9
                c0.2-0.7,0.7-1,1.4-1c0.6,0,1.1,0.2,1.3,0.7c0.4,0.8,0.8,1.5,1.6,2c0.5,0.3,0.6,0.8,0.6,1.4c0,0.3,0.1,0.6,0.2,1
                c0.4,0.1,0.8,0.2,1.2,0.3c0.6,0.1,1.3,0.3,1.7,0.8c0.8,0.8,1.7,1.2,2.8,1.3c0.7,0.1,1.4,0.4,2.1,0.8c0.4,0.2,0.8,0.4,1.1,0.5
                c0.3,0.1,0.8-0.2,1-0.6c0.2-0.4,0.2-1-0.2-1.2c-1.6-1.1-2.5-2.5-2.5-4.5c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.7-0.6-1.2-1.2-1.3
                c-1.7-0.4-3.2-1.2-4.4-2.4c-0.4-0.4-0.9-0.6-1.3-0.9c-0.3-0.2-0.7-0.5-0.9-0.9c-0.6-0.9-1.5-1.5-2.4-2.1c-0.8-0.5-1.6-1-2.1-1.9
                c-0.1-0.2-0.3-0.3-0.4-0.5c-0.5-0.4-1.1-0.7-1.7-0.8c-0.6-0.1-1,0.3-1.4,0.7c-0.7,0.6-1.5,0.8-2.4,0.7c-0.5-0.1-1-0.2-1.6-0.2
                c-0.3,0-0.6,0.3-0.7,0.5c-0.2,0.5-0.3,1-0.2,1.5c0.1,0.7,0.4,1.4,0.6,2.1c0.1,0.3,0.2,0.7,0.1,0.9c-0.6,1.5-0.5,3.4-2.4,4.2
                c-0.2,0.1-0.3,0.3-0.4,0.5c-0.2,0.3-0.3,0.9,0,1.2c0.4,0.3,0.8,0.7,1.3,0.9c2.6,1.4,2.6,1.5,0.4,3.4c-0.1,0.7-0.1,1.6-0.2,2.5
                C200.8,293,200.3,293.5,199.9,294.1z M236.6,275.2c1.4,0.8,1.4,1.9,0.2,3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.5-0.6,0.7-0.2,1.4
                c0.5,0.8,0.6,1.6,0.3,2.4c-0.3,0.7-0.6,1.4-0.8,2.1c-0.2,0.4-0.3,0.8-0.5,1.2c-0.1,0.4,0.1,0.9,0.5,1c0.6,0.2,1.3,0.1,1.4-0.3
                c0.4-1.3,1.1-2.5,1.3-3.9c0.1-0.5,0.6-0.7,1-0.5c0.7,0.3,1.3,0.8,2,0.8c1,0.1,0.9-1.3,1.7-1.6c0.6-0.3,0.5-0.8-0.1-1.2
                c-0.7-0.5-1-1-0.8-1.9c0.1-0.4,0.3-0.8,0.3-1.2c0.1-0.6-0.2-1.1-0.6-1.4c-0.4-0.3-0.9-0.5-1.4-0.7c-1.6-1-3.3-1.9-4.4-3.6
                c-0.3-0.5-0.9-0.9-1.5-1.1c-1-0.3-1.9-0.9-2.7-1.6c-2.2-2.1-2.2-1.3-4.6-0.8c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0.4-1.2,0.9-1.5,1.6
                c-0.1,0.3,0.1,1,0.4,1.1c0.5,0.1,1,0.3,1.5,0.3c0.8,0,1.7-0.2,2.5-0.2c0.9,0,1.7,0.3,2.2,1c0.3,0.4,0.6,0.8,0.9,1.3
                c0.3,0.5,0.5,0.9,0.7,1.4C234.9,274.3,235.8,274.7,236.6,275.2z M300.2,300.9c-0.4-0.3-0.6-0.8-1-1.2c-0.1-0.1-0.5-0.1-0.6-0.1
                c-1.1,1-2.3,1.5-3.7,1.5c-0.3,0-0.6,0.2-0.9,0.4c-0.8,0.3-1.5,0.2-2-0.5c-0.7-1.1-1.6-1.6-2.9-1.2c-0.6,0.2-1.3,0.1-1.8,0.1
                c-0.1,0.2-0.2,0.2-0.3,0.3c-0.4,1.1-1,2-2.4,2.2c-1.2,0.2-1.6,1.2-1.8,2.3c0,0.1,0.1,0.4,0.3,0.5c0.4,0.1,0.8,0.2,1.2,0.2
                c0.6-0.1,1.2-0.3,1.8-0.5c0.9-0.2,1.7-0.2,2.3,0.5c0.8,0.8,1.8,1,2.9,1.1c0.2,0,0.4-0.2,0.5-0.3c0.1-0.3,0.2-0.6,0.3-0.9
                c0.1-0.3,0.5-0.5,0.7-0.4c0.2,0.2,0.5,0.4,0.6,0.7c0.1,0.4,0,0.8,0,1.2c1.3,0.4,1.3,0.4,2.8,1.9c0.1,0.1,0.5,0,0.5,0
                c0.3-0.9,1.2-1.1,1.6-1.9c-0.2-0.4-0.4-0.7-0.6-1.1c-0.3-0.8,0.1-1.6,1-1.5c0.4,0,0.8,0.1,1.3,0.1c0.5-0.8,0.9-1.6,1.3-2.4
                C301.2,301.4,300.6,301.3,300.2,300.9z"/>

                <path 
                    className="st7 state_level" 
                    id="TT-WTO"
                    style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-WTO') ? this.state.marginColor : '#7f7f7f' }}
                    onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-WTO') ? this.callMapData('TT-WTO') : null}
                    d="M37.856,681.139c-0.771,0.273-1.429,0.511-2.089,0.736c-0.422,0.145-0.763,0.048-0.718-0.196
                c0.053-0.279,0.13-0.607,0.315-0.798c0.271-0.275,0.624-0.517,0.988-0.647c1.245-0.453,2.554-0.676,3.834-0.3
                c2.056,0.604,3.972,0.211,5.834-0.631c1.8-0.811,3.177-2.062,3.831-4.007c0.211-0.625,0.112-1.182-0.176-1.728
                c-0.086-0.163-0.262-0.386-0.406-0.394c-0.917-0.05-1.442-0.561-1.818-1.343c-0.167-0.347-0.457-0.635-0.675-0.929
                c0.195-0.426,0.438-0.737,0.885-0.744c0.399-0.005,0.797,0.059,1.198,0.077c0.809,0.038,1.612-0.031,2.34-0.414
                c0.464-0.245,0.918-0.379,1.43-0.208c0.525,0.174,0.995-0.015,1.43-0.262c0.867-0.493,1.732-0.932,2.729-1.18
                c1.128-0.281,1.887-1.105,2.19-2.272c0.05-0.191,0.157-0.442,0.082-0.579c-0.556-1.004-0.033-1.91,0.184-2.854
                c0.063-0.277,0.024-0.615-0.073-0.886c-0.36-1.017-0.523-2.15-1.588-2.771c-0.14-0.081,0.158-0.715,0.345-0.732
                c0.593-0.056,1.198-0.046,1.778-0.167c0.475-0.102,1.085-0.225,1.342-0.561c0.677-0.884,1.603-1.118,2.58-1.365
                c1.692-0.427,3.139-1.31,4.344-2.56c0.263-0.272,0.464-0.674,0.539-1.047c0.082-0.405-0.241-0.699-0.524-1.004
                c-0.427-0.462-0.451-0.86-0.159-1.011c2.271-1.18,3.957-3.03,5.653-4.871c0.204-0.22,0.411-0.436,0.691-0.729
                c0.275-0.993,0.246-2.101,0.337-3.19c0.024-0.29-0.06-0.594-0.125-0.887c-0.152-0.679-0.859-1.139-1.58-1.025
                c-0.388,0.06-0.771,0.155-1.106,0.223c-0.4-0.332-0.542-0.708-0.436-1.115c0.073-0.274,0.215-0.601,0.431-0.754
                c0.65-0.462,1.335-0.882,2.041-1.251c0.228-0.116,0.566-0.027,0.853-0.027c0.099,0,0.2,0.032,0.297,0.021
                c0.84-0.103,1.497-0.354,1.732-1.339c0.169-0.704,0.624-0.799,1.014-0.265c0.44,0.602,1,0.72,1.572,0.485
                c1.103-0.448,2.277-0.815,3.102-1.757c0.397-0.453,0.815-0.383,1.337-0.28c0.567,0.112,0.843-0.272,0.801-0.941
                c-0.018-0.287-0.073-0.572-0.108-0.836c0.618-0.568,1.375-0.873,1.874-1.544c0.489-0.653,1.205-1.039,2.045-1.148
                c0.517-0.066,1.042-0.284,1.255-0.734c0.337-0.713,0.769-1.071,1.584-1.074c0.897-0.003,1.458-0.576,1.813-1.361
                c0.537-1.188,1.069-2.375,1.599-3.564c0.364-0.82,0.654-1.66,1.614-2.061c0.264-0.109,0.352-0.662,0.502-1.02
                c0.168-0.396,0.428-0.716,0.877-0.682c1.113,0.085,1.667-0.453,1.851-1.503c0.03-0.174,0.198-0.324,0.34-0.545
                c0.817-0.215,1.683-0.444,2.63-0.695c0.136-0.347,0.253-0.723,0.427-1.068c0.205-0.403,0.611-0.571,0.949-0.344
                c0.562,0.381,1.061,0.511,1.6-0.017c0.287-0.277,0.625-0.5,0.915-0.774c0.901-0.852,1.899-1.432,3.205-1.284
                c0.286,0.03,0.662,0.051,0.864-0.101c0.679-0.511,1.447-0.571,2.242-0.695c0.682-0.106,1.314-0.195,2.021-0.133
                c0.676,0.061,1.347-0.337,1.66-1.072c0.078-0.185,0.144-0.403,0.285-0.521c0.126-0.104,0.367-0.158,0.526-0.115
                c0.172,0.045,0.384,0.199,0.442,0.356c0.137,0.369,0.191,0.769,0.28,1.155c0.31,1.362,0.434,2.764,0.972,4.083
                c0.493,1.207,0.248,2.377-0.574,3.39c-0.375,0.463-0.79,0.904-1.243,1.289c-0.457,0.387-0.973,0.707-1.483,1.024
                c-0.634,0.393-1.05,0.97-1.211,1.656c-0.232,0.984-0.541,1.921-1.056,2.793c-0.319,0.538-0.315,1.148-0.326,1.752
                c-0.011,0.649-0.292,1.134-0.846,1.489c-0.636,0.41-0.947,1.003-0.888,1.78c0.068,0.906-0.331,1.512-1.175,1.876
                c-0.375,0.161-0.58,0.536-0.688,0.938c-0.104,0.387-0.176,0.784-0.326,1.152c-0.15,0.371-0.373,0.743-0.804,0.829
                c-1.267,0.254-1.759,1.169-2.12,2.28c-0.186,0.566-0.032,1.023,0.284,1.386c0.388,0.44,0.924,0.75,1.37,1.146
                c0.219,0.192,0.452,0.428,0.542,0.689c0.085,0.249,0.038,0.574-0.041,0.839c-0.077,0.256-0.51,0.337-0.787,0.172
                c-0.257-0.149-0.478-0.363-0.739-0.508c-0.463-0.254-0.945-0.315-1.408,0.012c-0.324,0.229-0.609,0.604-0.963,0.687
                c-1.57,0.362-2.488,1.628-3.649,2.545c-0.717,0.566-0.589,1.878,0.161,2.36c0.249,0.159,0.541,0.257,0.804,0.397
                c0.263,0.143,0.582,0.249,0.759,0.467c0.433,0.533,0.7,1.255,1.224,1.648c0.431,0.324,1.165,0.212,1.71,0.43
                c0.805,0.319,1.212,1.058,1.369,1.858c0.152,0.778,0.122,1.593,0.231,2.382c0.059,0.429,0.419,0.687,0.806,0.817
                c0.585,0.196,1.168,0.105,1.764-0.062c0.878-0.248,0.916-0.172,0.665,0.804c-0.099,0.383-0.288,0.808-0.202,1.156
                c0.261,1.077,0.416,2.139,0.327,3.256c-0.039,0.472,0.157,0.991,0.351,1.445c0.272,0.644,0.614,1.261,0.975,1.86
                c0.73,1.215,1.037,1.673-0.205,2.868c-0.464,0.448-0.501,1.065-0.229,1.675c0.163,0.36,0.348,0.717,0.468,1.092
                c0.131,0.414-0.195,1.11-0.57,1.288c-0.27,0.128-0.549,0.229-0.829,0.326c-0.378,0.128-0.837,0.141-1.127,0.37
                c-1.431,1.138-3.297,1.44-4.745,2.497c-1.379,1.01-2.542,2.273-3.552,3.654c-0.887,1.213-1.764,2.4-2.956,3.363
                c-0.849,0.687-1.553,1.562-2.258,2.409c-0.377,0.451-0.624,1.015-0.915,1.533c-0.746,1.329-1.79,2.143-3.396,1.977
                c-0.797-0.082-1.654-0.029-2.372-0.316c-1.895-0.764-3.108,0.347-4.16,1.514c-0.792,0.876-1.342,1.981-2.238,2.804
                c-0.146,0.134-0.266,0.305-0.361,0.479c-1.336,2.467-2.69,4.925-3.984,7.413c-0.318,0.611-0.479,1.318-0.641,1.999
                c-0.315,1.337-0.277,2.602,0.971,3.521c0.079,0.058,0.138,0.146,0.2,0.222c0.516,0.633,0.482,1.151-0.111,1.868
                c-0.395,0.476-0.839,0.907-1.511,0.807c-0.897-0.135-1.79-0.133-2.691-0.078c-0.367,0.022-0.707-0.315-0.776-0.771
                c-0.058-0.39-0.103-0.79-0.076-1.181c0.033-0.492,0.173-0.979,0.209-1.473c0.013-0.175-0.113-0.495-0.221-0.515
                c-0.273-0.054-0.589-0.021-0.859,0.063c-0.167,0.054-0.292,0.261-0.422,0.409c-0.066,0.073-0.104,0.171-0.154,0.256
                c-0.1,0.171-0.202,0.344-0.305,0.517c-0.583,0.013-0.774-0.532-1.154-0.809c-0.25-0.181-0.576-0.328-0.812-0.1
                c-0.191,0.186-0.37,0.549-0.319,0.782c0.167,0.767,0.555,1.498,0.642,2.266c0.077,0.667-0.158,1.363-0.214,2.051
                c-0.015,0.184,0.162,0.373,0.181,0.566c0.038,0.388,0.131,0.817,0.014,1.165c-0.256,0.754-0.562,1.511-1.127,2.103
                c-0.199,0.207-0.467,0.443-0.73,0.482c-2.859,0.426-5.725,1.083-8.584,0.011c-0.412-0.154-0.76-0.026-1.152,0.059
                c-0.475,0.104-0.982,0.136-1.466,0.097c-0.559-0.043-1.162-0.713-1.164-1.206c-0.006-1.195-0.05-2.393,0.029-3.581
                c0.048-0.729-0.225-1.374-0.305-2.064c-0.049-0.423-0.385-0.686-0.777-0.834c-0.281-0.104-0.568-0.21-0.861-0.261
                c-1.785-0.3-3.511-0.79-5.179-1.5c-0.357-0.154-0.776-0.242-1.162-0.228c-1.26,0.045-2.367-0.357-3.37-1.07
                c-0.603-0.428-1.254-0.625-1.976-0.57c-2.612,0.193-5.212-0.037-7.799-0.319c-1.626-0.178-3.177,0.318-4.776,0.307
                c-0.273-0.003-0.583,0.173-0.813,0.35c-0.548,0.419-1.003,0.899-1.73,1.122c-1.812,0.554-3.556,1.345-5.467,1.557
                c-0.621,0.067-1.2-0.018-1.613-0.564c-0.06-0.078-0.138-0.146-0.22-0.203c-0.573-0.392-1.273-0.678-1.694-1.192
                c-0.588-0.72-0.375-1.729-0.536-2.605c-0.087-0.481-0.035-0.998,0.003-1.496c0.039-0.517,0.251-0.967,0.701-1.266
                c0.749-0.498,1.442-1.165,2.265-1.438c0.973-0.32,1.445-0.961,1.915-1.754c0.977-1.652,1.061-1.629,0.26-3.593
                c-0.6-1.473-0.979-3.041-1.399-4.58c-0.157-0.574-0.195-1.188-0.205-1.787c-0.007-0.391,0.044-0.834,0.228-1.164
                c0.529-0.956,1.114-1.885,1.744-2.778c0.102-0.145,0.617-0.166,0.807-0.043c0.516,0.339,0.812,0.895,0.922,1.495
                c0.127,0.688,0.217,1.369,0.568,2.007c0.513,0.93,1.107,1.662,2.298,1.63c0.396-0.013,0.82,0.015,1.188,0.145
                c1.573,0.554,3.141,0.407,4.743,0.146c1.082-0.177,2.199-0.271,3.291-0.21c1.317,0.072,2.59-0.162,3.879-0.3
                c0.822-0.088,1.301-1.131,0.849-1.823c-0.382-0.586-0.805-1.162-1.554-1.337C39.388,681.492,38.707,681.335,37.856,681.139z"></path>

                <path 
                    className="st6 state_level" 
                    id="TT-ETO"
                    style={{ fill: this.state.trendsResults.filter(data => data.geoCode === 'TT-ETO') ? this.state.marginColor : '#7f7f7f' }}
                onMouseEnter={()=>this.state.trendsResults.filter(data => data.geoCode === 'TT-ETO') ? this.callMapData('TT-ETO') : null}
                    d="M311.334,575.644c-0.585,0.076-0.712,0.644-1.055,0.979c-0.643,0.628-1.793,0.851-2.439,0.298
                c-0.854-0.729-2.104-0.706-2.824-1.617c-0.258,0.11-0.632,0.151-0.749,0.35c-0.189,0.32-0.213,0.74-0.306,1.12l-3.993-1.353
                c-0.058-0.08-0.168-0.16-0.167-0.237c0.022-0.688-0.189-1.423,0.336-2.017c0.264-0.298,0.581-0.549,0.853-0.841
                c0.304-0.325,0.283-0.717,0.187-1.121c-0.119-0.498-0.366-0.908-0.695-1.307c-0.441-0.532-0.799-1.133-1.172-1.671
                c0.346-0.821,0.645-1.552,0.959-2.274c0.158-0.364,0.688-0.447,1.032-0.18c0.158,0.122,0.345,0.218,0.475,0.363
                c0.264,0.3,0.456,0.671,0.75,0.933c0.736,0.65,0.947,1.47,0.955,2.408c0.004,0.474,0.447,0.709,0.842,0.443
                c0.761-0.514,1.61-0.762,2.551-1.017c0.55,0.345,1.142,0.71,1.726,1.087c0.179,0.113,0.221,0.671,0.058,0.82
                c-0.218,0.202-0.488,0.354-0.69,0.571c-0.416,0.444-0.644,0.979-0.675,1.597c0.497,0.238,1.109,0.333,1.354,0.687
                c0.725,1.039,1.692,0.874,2.688,0.773C311.334,574.841,311.334,575.242,311.334,575.644z M284.565,520.04
                c-0.14-0.392,0.161-0.646,0.297-0.951c-0.202,0-0.402,0-0.602,0c0.117,0.298,0.234,0.595,0.352,0.894L284.565,520.04z
                    M210.284,555.769c-0.083,0.485-0.167,0.973-0.249,1.444c-0.095,0.069-0.175,0.175-0.258,0.177
                c-1.399,0.039-2.647,0.893-4.087,0.743c-0.274-0.027-0.629,0.071-0.842,0.242c-0.512,0.407-1.132,0.357-1.698,0.531
                c-0.734,0.226-0.823,1.155-1.457,1.447c-0.922,0.428-1.41,1.401-2.39,1.756c-0.349,0.126-0.753,0.444-0.877,0.772
                c-0.326,0.861-1.087,1.124-1.756,1.546c-0.377,0.235-0.977,0.173-1.086,0.716c0.654,0.819,1.299,1.581,1.888,2.384
                c0.38,0.519,0.07,1.499-0.57,1.79c-0.349,0.158-0.841,0.356-1.13,0.228c-1.01-0.449-1.934-0.229-2.892,0.094
                c-0.27,0.092-0.578,0.07-0.925,0.105c-0.277-0.281-0.559-0.56-0.832-0.843c-0.569-0.593-1.188-1.082-2.068-1.071
                c-1.508-0.65-3.119-0.584-4.706-0.674c-0.803-0.045-1.425,0.35-1.578,1.1c-0.196,0.974-0.828,1.286-1.651,1.492
                c-0.171,0.043-0.309,0.223-0.475,0.351c0.044,0.39,0.108,0.783,0.131,1.183c0.01,0.191-0.051,0.393-0.101,0.583
                c-0.122,0.463-0.737,0.836-1.175,0.646c-0.648-0.28-1.283-0.689-1.898,0.004c-0.782,0.881-1.78,0.992-2.737,0.565
                c-1.077-0.481-2.093-0.604-3.193-0.22c-0.394,0.138-0.762-0.017-1.156-0.111c-1.196-0.287-2.282,0.064-3.118,1.068
                c-0.566,0.685-1.217,1.013-2.078,0.948c-0.594-0.042-1.184-0.126-1.776-0.166c-0.412-0.027-0.966,0.785-0.715,1.089
                c0.679,0.816,0.862,1.778,0.952,2.781c0.109,1.219-0.78,1.974-2.047,1.887c-0.954-0.063-1.756-0.304-2.349-1.084
                c-0.057-0.076-0.163-0.113-0.245-0.173c-0.329-0.229-0.821-0.206-1.097,0.076c-0.49,0.501-0.958,1.023-1.457,1.518
                c-0.66,0.65-1.261,0.505-1.55-0.363c-0.095-0.28-0.176-0.562-0.261-0.833c-0.889-0.378-1.798-0.28-2.693-0.292
                c-0.546-0.006-1.029,0.248-1.181,0.759c-0.219,0.734-0.619,1.195-1.334,1.465c-0.453,0.171-0.854,0.469-1.261,0.699
                c-0.714-0.23-1.372-0.479-2.049-0.645c-0.265-0.065-0.678-0.051-0.846,0.112c-0.552,0.533-1.195,0.684-1.916,0.688
                c-0.842,0.007-1.603,0.332-2.186,0.879c-0.463,0.435-1.111,0.933-0.571,1.789c0.131,0.206,0.018,0.58-0.017,0.873
                c-0.054,0.455,0.176,0.877,0.503,1.005c0.982,0.386,0.971,1.117,0.903,1.961c-0.086,1.044-0.626,1.846-1.119,2.727
                c-0.42,0.756-0.781,1.385-1.667,1.509c-0.729,0.101-1.376,0.377-1.844,0.921c-0.612,0.714-1.577,0.849-2.227,1.478
                c-1.038-0.055-1.59,0.668-2.231,1.319c-1.35,1.378-2.895,2.461-4.858,2.8c-0.394,0.068-0.803,0.116-1.169,0.262
                c-0.768,0.309-1.397,0.764-1.54,1.679c-0.109,0.688-0.331,1.358-0.416,2.049c-0.112,0.893-0.685,1.424-1.346,1.891
                c-0.493,0.353-0.938-0.006-1.399-0.174c-0.71-0.258-1.367,0.18-2.069,0.201c-0.305,0.387-0.56,0.844-0.931,1.16
                c-0.867,0.736-1.112,1.738-0.938,2.721c0.33,1.864,0.275,3.816,1.1,5.574c0.785,1.672,0.369,3.126-0.708,4.464
                c-0.435,0.538-0.95,1.024-1.484,1.467c-0.613,0.51-1.288,0.942-1.961,1.427c-0.279,0.867-0.45,1.775-0.855,2.563
                c-0.472,0.917-0.7,1.853-0.8,2.858c-0.073,0.72-0.418,1.313-1.042,1.748c-0.438,0.303-0.586,0.776-0.582,1.313
                c0.002,0.296-0.052,0.602-0.139,0.886c-0.149,0.479-0.34,0.959-0.85,1.172c-0.846,0.354-1.113,1.067-1.302,1.885
                c-0.192,0.834-0.715,1.417-1.564,1.667c-0.189,0.058-0.39,0.103-0.556,0.202c-0.787,0.483-0.941,1.771-0.274,2.309
                c0.388,0.312,0.845,0.547,1.205,0.887c0.644,0.607,0.756,1.387,0.523,2.215c-0.215,0.769-1.173,1.256-1.931,0.938
                c-0.449-0.189-0.841-0.515-1.259-0.774c-0.529,0.27-0.895,0.754-1.533,0.906c-1.418,0.336-2.36,1.382-3.254,2.454
                c-0.149,0.181-0.107,0.383,0.082,0.534c0.23,0.186,0.443,0.443,0.708,0.52c0.727,0.204,1.266,0.597,1.549,1.297
                c0.298,0.737,0.848,1.062,1.619,1.107c0.891,0.057,1.425,0.619,1.88,1.33c0.458,0.714,0.506,1.488,0.543,2.3
                c0.07,1.556,0.443,1.795,1.798,1.262c0.279-0.11,0.55-0.289,0.835-0.315c0.278-0.027,0.596,0.046,0.85,0.169
                c0.465,0.229,0.745,0.863,0.502,1.271c-0.596,0.997-0.789,1.998-0.46,3.141c0.132,0.458-0.005,0.993-0.017,1.494
                c-0.014,0.612,0.106,1.18,0.417,1.727c0.593,1.044,1.153,2.107,1.697,3.179c0.192,0.377,0.141,0.786-0.113,1.133
                c-0.404,0.56-0.828,1.104-1.188,1.582c0.244,0.594,0.498,1.038,0.611,1.519c0.279,1.178-0.319,2.188-1.473,2.589
                c-0.471,0.164-0.955,0.291-1.417,0.47c-0.511,0.199-0.704,0.835-0.356,1.227c1.12,1.265,2.2,2.558,3.216,3.906
                c0.411,0.546,1.01,0.965,1.569,1.379c0.56,0.415,1.176,0.754,1.767,1.126c0.545,1.152,1.853,1.003,2.725,1.594
                c0.741-0.068,1.319,0.25,1.912,0.666c0.28,0.193,0.834,0.261,1.132,0.113c1.236-0.604,2.094-0.252,2.779,0.854
                c0.151,0.244,0.398,0.448,0.643,0.605c0.145,0.096,0.417,0.147,0.553,0.073c0.882-0.48,1.763-0.987,1.884-2.141
                c0.121-1.144,0.753-2.056,1.358-2.969c0.211-0.318,0.542-0.636,0.89-0.776c1.109-0.451,2.236-0.876,3.387-1.202
                c0.872-0.248,1.491,0.155,1.89,1.041c0.122,0.269,0.156,0.607,0.342,0.812c0.708,0.786,1.389,1.07,2.338,0.349
                c0.36-0.273,0.537-0.647,0.509-1.03c-0.082-1.084-0.245-2.161-0.375-3.238c0.169-0.111,0.317-0.275,0.495-0.32
                c0.598-0.149,1.177-0.267,1.236-1.058c0.05-0.655,0.683-0.958,0.979-1.473c0.404-0.704,0.819-1.393,0.646-2.251
                c-0.204-1.009,0.36-1.729,0.925-2.438c0.616-0.772,1.447-1.328,2.045-2.15c0.455-0.627,1.222-1.011,2.032-1.201
                c1.066-0.25,2.125-0.532,3.219-0.81c0.992,0.44,2.067,0.774,2.981,1.36c1.454,0.93,2.931,1.139,4.552,0.654
                c0.768-0.229,1.56-0.385,2.314-0.65c1.918-0.674,3.927-0.76,5.919-0.91c0.984-0.075,1.994,0.115,2.989,0.225
                c0.416,0.045,0.755,0.306,0.914,0.693c0.306,0.755,0.639,1.441,1.43,1.846c0.851,0.433,0.955,1.494,1.611,2.107
                c-0.029,0.391-0.091,0.781-0.071,1.168c0.007,0.132,0.211,0.271,0.353,0.363c0.059,0.039,0.234,0.003,0.281-0.059
                c1.122-1.435,2.771-2.251,4.071-3.482c0.61-0.578,1.605-0.574,2.192,0.011c0.828,0.826,1.76,0.882,2.762,0.641
                c0.978-0.232,1.863-0.677,2.678-1.297c0.698-0.529,1.385-1.048,2.396-1.051c0.92-0.002,1.702-0.511,2.111-1.447
                c0.204-0.466,0.6-0.771,1.055-1.024c1.696-0.946,3.017-2.299,4.051-3.927c0.332-0.523,0.733-0.946,1.242-1.28
                c0.335-0.22,0.662-0.452,1.009-0.656c0.929-0.54,1.491-1.283,1.549-2.405c0.019-0.371,0.197-0.774,0.416-1.084
                c0.341-0.491,0.815-0.889,1.163-1.374c0.464-0.65,0.84-1.364,1.294-2.022c0.474-0.688,1.061-1.196,1.997-1.071
                c0.29,0.038,0.621,0.026,0.888-0.077c0.844-0.326,1.744-0.689,1.866-1.732c0.105-0.9,0.507-1.471,1.324-1.816
                c0.164-0.066,0.308-0.276,0.377-0.452c0.303-0.782,0.846-1.021,1.649-0.851c0.447,0.097,0.803-0.167,0.941-0.565
                c0.299-0.858,0.962-1.354,1.758-1.472c1.459-0.214,2.603-0.931,3.664-1.869c0.224-0.198,0.482-0.364,0.745-0.509
                c0.458-0.248,0.928-0.312,1.431-0.074c0.353,0.168,0.825,0.468,1.095,0.354c0.95-0.398,2.201-0.396,2.547-1.751
                c0.094-0.366,0.587-0.495,0.992-0.578c0.617-0.131,1.193,0.047,1.772,0.203c0.32,0.085,0.558,0.482,0.569,0.938
                c0.008,0.296,0,0.593,0.026,0.888c0.018,0.22,0.222,0.391,0.424,0.282c0.86-0.467,1.7-0.974,2.538-1.479
                c0.233-0.143,0.253-0.64,0.038-0.774c-0.423-0.263-0.863-0.5-1.291-0.752c-0.372-0.217-0.346-0.635,0.06-0.946
                c0.079-0.059,0.184-0.082,0.266-0.14c0.155-0.104,0.302-0.223,0.452-0.335c-0.109-0.127-0.193-0.289-0.327-0.374
                c-0.488-0.312-0.284-0.934-0.619-1.314c-0.396-0.45-0.778-0.914-1.145-1.39c-0.271-0.353-0.243-1.017,0.036-1.413
                c0.111-0.16,0.208-0.385,0.367-0.45c0.939-0.398,1.348-1.19,1.51-2.102c0.455-2.555,2.292-3.87,4.36-4.999
                c1.439-0.037,2.241-0.92,3.093-1.987c0.924-1.158,2.138-2.116,3.339-3.011c0.953-0.711,2.04-1.277,3.138-1.747
                c1.004-0.429,2.081-0.728,3.211-0.688c1.155,0.042,2.158,0.39,2.905,1.323c0.312,0.389,0.623,0.779,0.957,1.149
                c0.292,0.322,0.702,0.405,1.102,0.34c0.59-0.1,1.162-0.31,1.753-0.395c0.902-0.132,1.794-0.068,2.657,0.287
                c0.84,0.346,1.735,0.6,1.768,1.765c0.004,0.173,0.188,0.346,0.297,0.511c0.512,0.761,1.252,1.085,2.063,0.843
                c1.244-0.371,2.464-0.838,3.719-1.161c1.065-0.273,0.829-1.499,1.587-1.961c-0.068-0.688-0.038-1.405-0.241-2.052
                c-0.132-0.423-0.603-0.736-0.91-1.087c0.205-0.989,1.271-1.257,1.62-2.011c-0.103-0.746-0.03-1.52-0.333-2.097
                c-0.378-0.722-0.73-1.385-0.758-2.225c-0.014-0.388-0.333-0.708-0.704-0.915c-0.254-0.143-0.493-0.31-0.807-0.508
                c-0.03-0.58-0.033-1.177-0.097-1.766c-0.143-1.356,0.481-2.292,1.581-2.977c0.422-0.263,0.905-0.45,1.27-0.775
                c0.922-0.819,2.035-0.861,3.115-0.695c0.876,0.133,1.757,0.482,1.903,1.615c0.05,0.389,0.215,0.767,0.358,1.137
                c0.253,0.652,0.623,1.275,0.773,1.949c0.15,0.66,0.09,1.37,0.122,2.044c1.501,0.674,2.821,1.562,4.33,2.082
                c0.548,0.19,1.009,0.632,1.513,0.954c0.288,0.183,0.901,0.027,1.025-0.307c0.204-0.552,0.283-1.139,0.791-1.562
                c0.162-0.136,0.134-0.544,0.125-0.825c-0.015-0.498-0.084-0.993-0.129-1.472c0.08-0.096,0.129-0.198,0.211-0.246
                c0.343-0.198,0.696-0.379,1.047-0.566c0.878-0.47,1.206-1.018,0.969-1.922c-0.132-0.504-0.256-0.933,0.035-1.416
                c0.717-1.189,1.401-2.4,2.114-3.596c0.122-0.203,0.347-0.214,0.542-0.07c0.311,0.231,0.604,0.491,0.921,0.717
                c0.219,0.155,0.732-0.035,0.723-0.261c-0.057-1.215,0.124-2.387,0.54-3.53c0.101-0.276,0.094-0.592,0.143-0.89
                c0.17-1.059,0.103-1.985-1.046-2.536c-0.425-0.202-0.765-0.604-1.094-0.964c-0.084-0.091-0.038-0.409,0.062-0.533
                c0.182-0.224,0.419-0.467,0.681-0.548c0.754-0.232,0.837-0.776,0.751-1.414c-0.054-0.396-0.181-0.779-0.246-1.174
                c-0.099-0.583,0.243-1.244,0.859-1.357c0.781-0.146,1.146-0.603,1.562-1.221c0.601-0.892,0.592-1.896,0.832-2.854
                c0.042-0.171-0.045-0.383-0.1-0.567c-0.196-0.652-0.403-1.301-0.663-2.137c-0.954,0.075-1.883-0.234-2.759-0.735
                c-0.536-0.308-1.117-0.308-1.729-0.157c-1.121,0.277-1.925-0.255-2.583-1.114c-0.373-0.489-0.65-1.016-0.717-1.628
                c-0.122-1.096-0.231-2.192-0.335-3.289c-0.08-0.847,0.125-1.586,0.818-2.146c0.233-0.188,0.497-0.374,0.648-0.623
                c0.487-0.795,1.189-0.984,2.061-0.895c0.331,0.033,0.514-0.238,0.452-0.571c-0.035-0.195-0.153-0.376-0.177-0.571
                c-0.114-0.894,0.564-1.552,1.441-1.392c0.191,0.035,0.357,0.181,0.545,0.249c0.626,0.225,1.019-0.058,0.932-0.621
                c-0.105-0.699-0.293-1.347-0.718-1.939c-0.283-0.393-0.471-0.872-0.62-1.339c-0.124-0.387-0.052-0.79,0.282-1.095
                c0.376-0.346,1.087-0.516,1.368-0.196c0.733,0.834,1.668,1.009,2.688,1.033c0.494,0.013,0.841-0.33,0.602-0.662
                c-0.571-0.788-0.681-1.654-0.563-2.573c0.102-0.788,0.242-1.572,0.376-2.356c0.102-0.601,0.379-0.853,1.011-0.893
                c0.39-0.024,0.783-0.007,1.168-0.007c0.466-0.703,0.563-1.51,0.758-2.275c0.032-0.126-0.139-0.401-0.274-0.456
                c-0.461-0.182-0.954-0.284-1.426-0.439c-0.313-0.104-0.474-0.37-0.476-0.683c-0.001-0.296,0.05-0.599,0.124-0.89
                c0.317-1.261,0.753-2.479,1.239-3.686c0.043-0.107-0.145-0.429-0.279-0.468c-0.568-0.163-1.159-0.257-1.74-0.378
                c-0.406-0.084-0.688-0.505-0.575-0.879c0.231-0.766,0.434-1.542,0.729-2.282c0.251-0.635,0.612-1.227,0.991-1.963
                c-1.065-0.182-2.018-0.346-2.971-0.502c-0.191-0.031-0.414-0.101-0.574-0.036c-0.933,0.379-1.757-0.062-2.607-0.304
                c-0.34-0.098-0.642-0.555-0.619-0.936c0.017-0.3,0.082-0.595,0.125-0.89c0.08-0.528,0.019-0.944-0.575-1.195
                c-0.826-0.349-1.212-1.012-1.025-1.923c0.085-0.414,0.023-0.794-0.135-1.168c-0.147-0.344,0.047-0.574,0.263-0.783
                c0.213-0.209,0.455-0.388,0.686-0.581c-0.145-0.092-0.29-0.259-0.438-0.263c-0.539-0.019-0.881,0.497-1.329,0.606
                c-1.102,0.271-1.773,0.724-1.761,2.006c0.007,0.549-0.392,1.11-0.639,1.652c-0.212,0.467-0.542,0.855-1.042,1.023
                c-0.472,0.16-0.958,0.328-1.45,0.371c-0.399,0.034-0.799-0.195-0.977-0.573c-0.165-0.352-0.204-0.759-0.316-1.135
                c-0.071-0.239-0.524-0.52-0.699-0.43c-0.171,0.088-0.369,0.192-0.459,0.347c-0.201,0.339-0.34,0.714-0.519,1.104
                c-1.032,0.276-2.169,0.251-3.13,0.873c-0.134,0.088-0.417,0.057-0.577-0.025c-1.009-0.51-1.708-0.174-2.244,0.743
                c-0.091,0.156-0.277,0.307-0.451,0.361c-1.328,0.438-2.661,0.856-3.998,1.266c-0.413,0.125-0.8-0.002-1.113-0.279
                c-0.374-0.332-0.703-0.719-1.09-1.033c-0.111-0.089-0.399-0.08-0.536,0c-0.51,0.305-0.845,1.135-0.623,1.55
                c0.474,0.879,0.923,1.783,1.504,2.59c0.268,0.369,0.803,0.57,1.251,0.774c0.26,0.118,0.592,0.135,0.886,0.116
                c1.139-0.068,1.888,0.547,2.499,1.393c0.296,0.408,0.486,0.859,0.321,1.402c-0.084,0.274-0.136,0.594-0.082,0.869
                c0.173,0.877,0.364,1.754,0.624,2.607c0.198,0.654,0.033,1.199-0.348,1.684c-0.871,1.113-1.873,2.084-3.322,2.63
                c-0.585-0.272-1.23-0.533-1.839-0.861c-0.654-0.354-0.856-0.38-1.21,0.067c-0.497,0.628-0.952,1.287-1.464,1.901
                c-0.452,0.544-0.99,0.856-1.756,0.993c-1.158,0.204-2.273,0.646-3.008,1.724c-0.317,0.467-0.83,0.523-1.304,0.108
                c-0.586-0.512-1.21-0.631-1.936-0.604c-0.578,0.024-1.185,0.436-1.731-0.027c-0.623-0.525-0.016-1.107-0.008-1.663
                c0.009-0.446-0.214-0.706-0.712-0.703c-0.396,0-0.806,0.082-1.183,0c-0.261-0.058-0.616-0.287-0.687-0.515
                c-0.588-1.908-1.779-3.535-2.441-5.394c-1.092-0.528-2.044-1.276-3.302-1.409c-0.293-0.028-0.537-0.542-0.807-0.836
                c-0.755-0.059-1.56,0.471-2.284-0.168c-0.065-0.058-0.213-0.092-0.283-0.058c-1.647,0.818-3.514,0.756-5.222,1.319
                c-0.282,0.095-0.586,0.171-0.828,0.334c-1.165,0.78-2.436,1.461-3.43,2.422c-1.059,1.022-2.186,1.696-3.62,1.94
                c-0.381,0.064-0.735,0.297-1.163,0.479c-0.735-0.266-1.479-0.557-2.238-0.798c-0.369-0.117-0.797-0.267-1.148-0.187
                c-1.165,0.27-2.18-0.003-3.14-0.641c-0.083-0.058-0.171-0.105-0.262-0.147c-0.461-0.21-1.053-0.134-1.384,0.233
                c-1.005,1.111-1.992,2.242-2.984,3.366L210.284,555.769z M284.201,523.467c-0.262-0.118-0.586-0.181-0.869-0.146
                c-0.275,0.029-0.625,0.146-0.774,0.351c-0.144,0.195-0.187,0.612-0.073,0.824c0.278,0.513,0.654,0.979,1.031,1.432
                c0.105,0.124,0.413,0.238,0.523,0.176c0.824-0.466,1.712-0.363,2.598-0.44c0.965-0.082,1.974-0.269,2.482-1.345
                c0.18-0.383,0.647-0.37,1.047-0.411c0.281-0.028,0.557-0.156,0.822-0.27c0.231-0.098,0.392-0.589,0.234-0.758
                c-0.191-0.207-0.513-0.555-0.655-0.501c-0.892,0.329-1.933,0.186-2.686,1.039c-0.418,0.478-1.179,0.65-1.849,0.994
                C285.428,524.094,284.827,523.753,284.201,523.467z M210.223,555.826c0.005-0.076,0.03-0.16,0.005-0.227
                c-0.015-0.033-0.164-0.058-0.168-0.046c-0.082,0.162,0,0.236,0.225,0.214L210.223,555.826z M284.565,520.04
                c-0.022-0.053-0.043-0.104-0.066-0.158c-0.123,0.127-0.085,0.157,0.113,0.097C284.612,519.981,284.565,520.04,284.565,520.04z"></path>

            </svg>





                <svg version="1.1" className="map" id="cont17State" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 1000 1000" >
                
                <path className="st14" id="dist1" d="M645.9,106.7c-0.6,17-1.5,34,6.5,49.9c2.9,6,2.5,12.4-2.6,17.7c-2.1,2.3-4.1,4.9-5.8,7.6
                    c-3.8,5.7-4.2,11.9-2,18.2c1,3,2.6,5.9,4.3,8.6c3.1,4.9,3.9,10.2,0.5,14.9c-6.6,8.7-6.2,17.7-1.8,27c2.2,4.7,4.8,9.2,7.3,13.9
                    c2,3.9,3.7,8,0.6,11.8c-5.8,6.9-8.1,14.9-9.2,23.4c-3.3,25.3-6.7,50.6-9.7,75.9c-0.5,4.1,0.2,8.7,1.9,12.4c1,2.1,1.5,4.2,1.6,6
                    l-16.7,0.6l-10.7,2.9l-40.3,10.7l-3.7-9.1l-52.4,7.6l6.1-11.3l5.2-28.7l-3.1-21.8c0,0-35.3,6.1-38.1,6.7
                    c-2.9,0.6-13.9,14.8-13.9,14.8L450.5,377l-14.2-29.2l3.7-10.1l0.5-11l7.8-1.7l-1.2-20.6l-0.4-6.2l44-10.1c0,0-9-26.7-4.5-27.5
                    c4.7-0.9,41.1-8.7,41.1-8.7s-5.9-15.8-5.4-17.7c0.5-2,11.3-24.6,11.5-28.6c0.3-4,9.5-17,9.5-17l-4.5-7.3l9.4-6.8
                    c0,0,3.2-2.8,5.1-5.5c2-2.8-10.1-14.6-10.1-14.6s-9.6-7.5-8.2-9.4c1.5-1.9,0.8-5.7,0.8-5.7s-8.3-8-7.2-8.7c1.2-0.7-5.1-5.3-5.1-5.3
                    l-16.9,0.2l-13.4-3.8l-7.3,4.8l-7.3-19.5l2.8-37.7c6.7-1.9,12.6-5.7,17.9-11.4c7.4-8.3,17-7.9,26.6-5.2c6,1.8,11.8,4.6,17.6,7.4
                    c10.5,5.2,20.6,3.8,30.6-1.2c4.7-2.3,8.9-5.9,13.8-7.9c2.6-1.1,6.7-1.7,8.8-0.4c6.4,3.8,12.5,7.9,20.4,8.1c2.9,0.1,6.7,1.7,8.3,3.9
                    c5.6,7.6,12.9,10.6,21.9,11.3c2,0.1,4.1,1.1,6,1.8c4.9,1.7,7.7,9.1,4.7,12.9c-1.3,1.7-2.9,3.3-4.5,4.6
                    C648.9,96.8,646.1,101,645.9,106.7z"/>
                <path className="st13" id="dist2" d="M525,366.7l-5.2,28.7l-6.1,11.3l-2.1,3.8l-51.7,58.2l4.7,6.5l-2.4,8.1l-0.8,46.7l-1.4,78.5l-22.6,0.5l0.4,62.1
                    l-57.1,0.8l-5.7,25c0,0-0.4,2-0.9,5.2c-1.6,9.5-4.9,28.8-5.7,30.7c-0.9,2.3-12.7,22.6-12.7,22.6l0.5,18.9c0,0-0.5,24.1-1.4,25.5
                    c-0.9,1.4-6.2,27-6.2,27s-3.7,35.7-2.8,37.2c1,1.4,12.7,15.1,14,18.1c1.2,3.2,7.7,12.5,7.7,12.5s-5.2,10.4-7.8,19.6
                    c-5.2-1.4-10.1-4.3-14.4-8.5c-11.3-11.3-22.6-22.4-33.9-33.6c-11.6-11.3-24.3-20.3-41-23c-7.2-1.1-14.2-4.6-20.6-8.1
                    c-17-9.5-34.7-15.8-54.3-17c-11.6-0.7-23.1-3.7-34.6-5.8c-9.5-1.8-13.8-9-11.3-19.2c0.5-2,1.6-3.9,2.6-5.7c1.9-3.2,8.2-6.3,11-5.4
                    c6,2,12,4.1,18,6.3c6.4,2.3,12.6,1.8,18.6-0.9c4.8-2.1,9.5-4.7,14.1-7.3c5.9-3.2,10.3-7.9,10.9-14.8c0.7-10,5.5-17.7,11.9-25.3
                    c9.1-10.9,12.9-24.1,12.2-38.6c-0.6-10.9-2.8-21-10.5-29.3c-2.1-2.3-4.1-4.8-6-7.3c-2.8-3.7-1.7-9,2.1-10.6c2-0.8,4-1.5,6-1.9
                    c8.5-1.7,12.6-7.2,14.3-15.2c0.4-2.1,0.7-4.5,1.8-6.1c1.7-2.6,4.3-4.7,7.8-3.8c7.3,1.8,14.3,4.1,21.6,5.4c9.7,1.7,19.2,1.2,27.3-5.2
                    c3.3-2.6,7-4.9,10.6-7.2c6.6-4.2,13.9-5.3,21.5-4.1c7.3,1.2,14.5,3.3,21.9,4.6c7.4,1.2,14.6-0.1,21.6-3.4
                    c11.3-5.4,12.9-23.2,1.7-28.4c-8.5-3.9-13.4-9.5-14.1-19.3c-0.6-7.6-5.6-13.7-10.7-19.2c-5.6-6-6-13.3-6-20.8
                    c0.1-9.2,2.5-12.1,12.8-15.1c2-0.7,4.2-0.8,6.2-1.3c4.7-1.1,7.5-4.5,8.4-8.7c2-10.3,3.4-20.8,5.4-33.1c15.7-15.9,30.9-30.7,45.4-46
                    c2.6-2.7,3.7-7.8,3.8-11.7c0.1-11.3-4.7-20.6-14.2-27c-5.3-3.5-11-6.4-16.4-9.9c-2.5-1.6-4.6-8.9-3.3-11.6c1.7-3.9,3.9-7.5,5.5-11.4
                    c1.6-3.8,3.1-7.9,3.8-12c1.1-6.4-2.6-12.6-9.1-15c3.4-0.9,19.1-6,19.1-6l20.4-22.2l1.2,20.6l-7.8,1.7l-0.5,11l-3.7,10.1l14.2,29.2
                    l19.4-10.6c0,0,11-14.2,13.9-14.8c2.8-0.6,38.1-6.7,38.1-6.7L525,366.7z"/>
                <path className="st9" id="dist3" d="M851.4,864.9c-0.2,3.5-2.5,6-5.6,7.1c-3.9,1.5-8.2,2.7-12.3,2.9c-9.3,0.5-16.2,4.2-21.2,12
                    c-3.6,5.7-8.8,8.3-15.8,9.4c-6,0.9-11.6,4.7-17.4,7.3c-3.9,1.8-7.8,4.8-11.6,4.7c-14.1-0.7-23.9,7.4-34.3,15
                    c-29.9,21.6-30.8,21.6-67.1,25.4c-11,1.2-22,1.6-32.9,1.3c-0.1-29.7-0.5-206.3-0.5-207.6c0-1.4,0.2-15.5,0.2-15.5s-4.8-9-4.8-10
                    c0-0.8-0.4-50.7-0.6-68.8l10-1.9l-10.8-21.8l3.2-7.7c0,0,35.9-1.1,35.1-0.7c-0.9,0-19.3-68.8-19.3-68.8L625,505.1
                    c0,0-10.9-84.5-15-107.6l10.7-2.9l16.7-0.6c0.1,4.1-1.7,7.7-4.5,11.5c-2.3,3.3-5.1,7.8-4.7,11.4c1.7,14.9,0.9,29.7,0.3,44.6
                    c-0.3,6.8,5.8,14.6,12.7,16.6c4.1,1.1,8.4,1.2,12.6,2c8.5,1.5,10,3.2,11.5,12.6c0.6,3.1,0.6,6.3,1,9.5c1.1,6.8,4.9,12.7,11.1,14.5
                    c9.4,2.7,14.4,8.2,16.9,17c0.6,2,1.7,4.6,3.3,5.3c15.7,7.5,22.6,25.3,38.8,32.4c8.3,3.7,12.3,11.4,12.4,20.8
                    c0.1,8.5,2,16.7,3.4,25.1c2.2,12.4,2.1,25.1,2.9,37.8c0.1,1,0,2.2-0.6,3.1c-7.8,13.1-3.7,24.2,5.2,35c2.6,3.2,3.7,7.6,4.8,11.7
                    c3.2,10.8,10.3,15.5,21.3,15c3.2-0.1,6.4-0.7,9.6-0.5c4.3,0.3,8.8,1.7,10.4,6c2.8,7.4,8.2,12.2,14.2,16.7c0.7,0.5,1.3,2.3,1,2.7
                    c-1,1.4-2.5,2.5-4,3.3c-0.8,0.5-2,0.3-3.2,0.5c-15.3,2.3-18,4.7-16.8,20.2c0.5,7.2-0.6,12.4-6.2,16.6c-1.8,1.3-3.6,2.5-4.9,4.1
                    c-3.7,4.2-5.6,9-3.5,14.6c2.4,6.7,7.6,10.5,14.1,11.3c5.1,0.6,10.5-0.8,15.7-1.6c7.2-0.9,12.4,11,10.1,16.6
                    c-2.2,5.4-7.1,7.7-10.6,11.6c-5,5.7-5.1,12-0.6,17.2c5.9,6.8,12.8,8.7,19.7,6.1c6.4-2.3,10.4-7.1,13.6-12.7c0.5-0.7,2.1-1.3,2.6-1
                    c1.4,1,3.3,2.3,3.6,3.9C851.6,858.5,851.5,861.7,851.4,864.9z"/>
                <path className="st10" id="dist4" d="M633.2,949.9c-17.8-0.2-35.4-1.8-53.1-3c-6.1-0.4-12.6-2.5-18-5.6c-11.6-6.5-23.8-9-36.8-7.6
                    c-7.9,0.7-14.5-2.2-20.6-7.1c-6.6-5.3-13.3-10.6-19.9-16c-6.9-5.6-14.8-8.2-23.6-8.3c-15.9,0-31.9-0.1-47.9-0.1
                    c-12.1-0.1-22.4,5.8-33.1,10.3c-7.1,3-14.1,3.5-20.7,1.7c2.6-9.2,7.8-19.6,7.8-19.6s-6.5-9.3-7.7-12.5c-1.3-3.1-13-16.7-14-18.1
                    c-0.9-1.5,2.8-37.2,2.8-37.2s5.3-25.6,6.2-27c0.9-1.4,1.4-25.5,1.4-25.5l-0.5-18.9c0,0,11.8-20.3,12.7-22.6
                    c0.7-1.9,4.1-21.2,5.7-30.7c17.6,0,70.3,0.1,72.8,0.3c3.2,0.4,42.2,13.4,45.1,15.3c2.8,2,27.2,1.5,27.2,1.5l-1.7-27.2
                    c0,0,88.6,1.2,94.5,1.7c6,0.6-2.1-38.6-2.1-38.6l9.8,2.2l-1.9-7.6l10-1.9c0.2,18.1,0.6,68,0.6,68.8c0,1,4.8,10,4.8,10
                    s-0.2,14.1-0.2,15.5C632.8,743.6,633.1,920.2,633.2,949.9z"/>
                <path className="st15"  id="dist5" d="M553,169.1c-2,2.7-5.1,5.5-5.1,5.5l-9.4,6.8l4.5,7.3c0,0-9.2,13-9.5,17c-0.3,4-11.1,26.6-11.5,28.6
                    c-0.5,1.9,5.4,17.7,5.4,17.7s-36.5,7.7-41.1,8.7c-4.6,0.8,4.5,27.5,4.5,27.5l-44,10.1l0.4,6.2l-20.4,22.2c0,0-15.6,5.1-19.1,6
                    c-4.8-1.9-10.5-1.7-14.1-6.2c-1.7-2-1.7-3.6-0.7-6c2.3-5.5,8.3-6.2,12.1-9.8c5.3-4.7,6.4-10.8,5-17.4c-1.7-8.2-10.1-14.7-7.4-23.8
                    c2.3-8.1,6.3-15.6,9.6-23.4c0.7-2,1.5-4,2.5-5.9c6-11.5,16-16.8,28.6-16.9c6.3,0,12.6,2.2,18.9,2.8c4.2,0.4,8.5-0.3,12.6-1
                    c4.7-0.8,7.1-4.6,9-8.4c1.5-3.1-0.6-9.2-3.5-11.4c-3.3-2.5-6.7-5.2-10.3-7.3c-4.6-2.6-9.5-4.7-13.4-6.7c-2.4-3.5-4-6.3-6-8.8
                    c-11.7-14.9-15.1-32.6-16.4-50.8c-0.4-6.4,0-12.8,0-19.2c-0.3-25.6-0.1-20.7,18.9-36.7c4.3-3.5,9.4-5.1,14.8-4.8
                    c4.8,0.2,9.3-0.3,13.5-1.5l-2.8,37.7l7.3,19.5l7.3-4.8l13.4,3.8l16.9-0.2c0,0,6.3,4.6,5.1,5.3c-1.1,0.7,7.2,8.7,7.2,8.7
                    s0.7,3.8-0.8,5.7c-1.4,1.9,8.2,9.4,8.2,9.4S554.9,166.3,553,169.1z"/>
                <path className="st12" id="dist6" d="M665,615.9c0.8-0.5-35.1,0.7-35.1,0.7l-3.2,7.7l-23.8-48l-13.6,10l-6,6l-6.7-1L575,584l-55.4-0.1v-56
                    c0,0-40.9,1.4-58.4,2.1l0.8-46.7l2.4-8.1l-4.7-6.5l51.7-58.2l2.1-3.8l52.4-7.6l3.7,9.1l40.3-10.7c4.1,23.1,15,107.6,15,107.6
                    l20.6,41.9C645.6,547.1,664,615.9,665,615.9z"/>
                <path className="st11" id="dist7" d="M627.6,647.9l-10,1.9l1.9,7.6l-9.8-2.2c0,0,8.1,39.2,2.1,38.6c-5.9-0.5-94.5-1.7-94.5-1.7l1.7,27.2
                    c0,0-24.4,0.5-27.2-1.5c-2.9-1.9-41.9-14.9-45.1-15.3c-2.5-0.2-55.2-0.3-72.8-0.3c0.6-3.2,0.9-5.2,0.9-5.2l5.7-25l57.1-0.8
                    l-0.4-62.1l22.6-0.5l1.4-78.5c17.5-0.7,58.4-2.1,58.4-2.1v56L575,584l1.6,7.3l6.7,1l6-6l13.6-10l23.8,48l10.8,21.8L627.6,647.9z"/>
                
                </svg>




                
                <svg version="1.1" className="map" id="cont17Dist1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 1000 1000" >

                <path className="st14" d="M794.6,192.6c-1.4,42.8-3.7,85.3,16.4,125.5c7.2,15,6.3,31.1-6.5,44.4c-5.4,5.8-10.3,12.4-14.5,19.2
                    c-9.6,14.3-10.5,29.9-4.9,45.8c2.6,7.5,6.5,14.7,10.8,21.5c7.7,12.4,9.8,25.7,1.2,37.4c-16.6,22-15.7,44.4-4.4,67.8
                    c5.6,11.9,12.2,23.1,18.2,34.8c5.1,9.8,9.3,20.1,1.4,29.7c-14.5,17.3-20.3,37.4-23.1,58.9c-8.2,63.6-16.8,127.1-24.3,190.7
                    c-1.2,10.3,0.5,22,4.7,31.1c2.6,5.4,3.7,10.5,4,15.2l-42.1,1.4l-26.9,7.2L603.2,950l-9.3-22.9l-131.6,19.2l15.4-28.3l13.1-72.2
                    l-7.7-54.7c0,0-88.8,15.4-95.8,16.8c-7.2,1.4-34.8,37.2-34.8,37.2l-48.8,26.6l-35.8-73.4l9.3-25.5l1.2-27.6l19.6-4.2l-3-51.6
                    l-0.9-15.7l110.5-25.5c0,0-22.7-67.1-11.2-69.2c11.7-2.3,103.3-21.7,103.3-21.7s-14.7-39.7-13.6-44.4c1.2-4.9,28.3-61.7,29-71.7
                    c0.7-10,23.8-42.8,23.8-42.8L524.7,380l23.6-17.1c0,0,7.9-7,12.9-13.8c4.9-7-25.5-36.7-25.5-36.7s-24.1-18.9-20.6-23.6
                    c3.7-4.7,2.1-14.3,2.1-14.3s-20.8-20.1-18-21.7c3-1.9-12.9-13.3-12.9-13.3l-42.5,0.5l-33.7-9.6l-18.2,12.2l-18.2-49.1l7-94.6
                    c16.8-4.7,31.8-14.3,44.9-28.7c18.7-20.8,42.8-19.9,66.8-13.1c15.2,4.4,29.7,11.5,44.2,18.7c26.4,13.1,51.9,9.6,76.9-3
                    c11.9-5.8,22.4-14.7,34.6-19.9c6.5-2.8,16.8-4.2,22.2-0.9c16.1,9.6,31.3,19.9,51.2,20.3c7.2,0.2,16.8,4.2,20.8,9.8
                    c14,19.2,32.5,26.6,54.9,28.3c5.1,0.2,10.3,2.8,15.2,4.4c12.4,4.2,19.4,22.9,11.7,32.5c-3.3,4.2-7.2,8.2-11.2,11.5
                    C802.1,167.6,795.1,178.1,794.6,192.6z"/>
                <g className="district_show">
                    <path d="M689.9,252.2c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C689.9,247.1,689.9,249.7,689.9,252.2z"/>
                    <path d="M689.9,201c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C689.9,187.6,689.9,194.3,689.9,201z"/>
                    <path d="M607,266.2c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C660.6,266.2,633.8,266.2,607,266.2z"/>
                    <path d="M663.7,234.2c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C663.7,215.3,663.7,224.7,663.7,234.2z"/>
                    <path d="M639,203.5c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C640.6,203.5,639.9,203.5,639,203.5z"/>
                </g>
                <g className="district_show">
                    <path d="M683.6,532.9c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C683.6,527.8,683.6,530.4,683.6,532.9z"/>
                    <path d="M683.6,481.7c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C683.6,468.3,683.6,475,683.6,481.7z"/>
                    <path d="M600.6,546.9c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C654.2,546.9,627.5,546.9,600.6,546.9z"/>
                    <path d="M657.4,514.9c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C657.4,496,657.4,505.4,657.4,514.9z"/>
                    <path d="M632.6,484.2c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C634.2,484.2,633.6,484.2,632.6,484.2z"/>
                </g>
                <g className="district_show">
                    <path d="M655.4,803.6c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C655.4,798.5,655.4,801,655.4,803.6z"/>
                    <path d="M655.4,752.3c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C655.4,738.9,655.4,745.6,655.4,752.3z"/>
                    <path d="M572.4,817.6c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C626,817.6,599.3,817.6,572.4,817.6z"/>
                    <path d="M629.2,785.6c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C629.2,766.7,629.2,776,629.2,785.6z"/>
                    <path d="M604.5,754.8c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C606.1,754.8,605.4,754.8,604.5,754.8z"/>
                </g>
                </svg>
                



                <svg version="1.1" className="map" id="cont17Dist2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
                <path className="st13" d="M777.8,141.8l-7.7,42.4l-9.1,16.6l-3.2,5.6l-76.3,85.9l6.9,9.6l-3.6,11.9l-1.2,68.9l-2.1,115.9l-33.4,0.7
                    l0.5,91.7l-84.3,1.2l-8.4,36.9c0,0-0.5,3-1.4,7.7c-2.3,14-7.3,42.6-8.4,45.3c-1.4,3.4-18.8,33.4-18.8,33.4l0.7,27.9
                    c0,0-0.7,35.6-2.1,37.6c-1.4,2.1-9.2,39.8-9.2,39.8s-5.5,52.7-4.1,54.9c1.5,2.1,18.8,22.2,20.7,26.8c1.8,4.7,11.4,18.4,11.4,18.4
                    s-7.7,15.4-11.5,29c-7.7-2.1-15-6.3-21.3-12.5c-16.6-16.6-33.4-33.1-50.1-49.6c-17.2-16.7-35.8-29.9-60.5-33.9
                    c-10.6-1.6-21-6.7-30.5-11.9c-25.1-14-51.2-23.3-80.2-25.1c-17.2-1.1-34-5.5-51.1-8.5c-14-2.6-20.3-13.3-16.6-28.3
                    c0.7-2.9,2.3-5.8,3.8-8.4c2.7-4.7,12.1-9.3,16.2-8c8.9,2.9,17.7,6,26.5,9.3c9.5,3.4,18.5,2.6,27.5-1.4c7.1-3.2,14-7,20.9-10.8
                    c8.6-4.7,15.2-11.7,16.1-21.8c1.1-14.8,8.1-26.1,17.6-37.3c13.5-16.1,19.1-35.6,18-57c-0.8-16.1-4.1-31-15.5-43.2
                    c-3.2-3.4-6-7.1-8.9-10.8c-4.1-5.5-2.5-13.3,3.2-15.6c2.9-1.2,5.9-2.2,8.9-2.7c12.5-2.5,18.7-10.6,21.1-22.4c0.5-3.2,1-6.6,2.6-9.1
                    c2.5-3.8,6.3-6.9,11.5-5.6c10.7,2.6,21.1,6,31.8,8c14.3,2.5,28.3,1.8,40.4-7.7c4.9-3.8,10.3-7.3,15.6-10.6c9.7-6.2,20.5-7.8,31.7-6
                    c10.8,1.8,21.4,4.9,32.3,6.7c11,1.8,21.6-0.1,31.8-5.1c16.7-8,19.1-34.2,2.5-41.9c-12.5-5.8-19.8-14-20.9-28.6
                    c-0.8-11.3-8.2-20.2-15.8-28.3c-8.2-8.8-8.9-19.6-8.9-30.8c0.1-13.6,3.7-17.8,18.9-22.2c3-1,6.2-1.2,9.2-1.9
                    c6.9-1.6,11.1-6.6,12.4-12.9c3-15.2,5.1-30.8,8-48.9c23.2-23.5,45.6-45.3,67-68c3.8-4,5.5-11.5,5.6-17.3c0.1-16.7-6.9-30.5-21-39.8
                    c-7.8-5.2-16.2-9.5-24.2-14.6c-3.7-2.3-6.7-13.2-4.8-17.2c2.5-5.8,5.8-11.1,8.1-16.9c2.3-5.6,4.5-11.7,5.6-17.7
                    c1.6-9.5-3.8-18.5-13.5-22.1c5.1-1.4,28.1-8.9,28.1-8.9L662.8,50l1.8,30.3L653,82.8L652.4,99l-5.5,15l21,43.1l28.7-15.6
                    c0,0,16.2-21,20.5-21.8c4.1-0.8,56.3-9.9,56.3-9.9L777.8,141.8z"/>
                    <g className="district_show">
                    <path d="M467.9,777c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C467.9,771.9,467.9,774.4,467.9,777z"/>
                    <path d="M467.9,725.7c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C467.9,712.3,467.9,719,467.9,725.7z"/>
                    <path d="M385,791c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C438.5,791,411.8,791,385,791z"/>
                    <path d="M441.7,759c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C441.7,740.1,441.7,749.4,441.7,759z"/>
                    <path d="M417,728.2c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C418.6,728.2,417.9,728.2,417,728.2z"/>
                </g>
                <g className="district_show">
                    <path d="M663.4,417.8c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C663.4,412.7,663.4,415.3,663.4,417.8z"/>
                    <path d="M663.4,366.6c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C663.4,353.2,663.4,359.9,663.4,366.6z"/>
                    <path d="M580.5,431.8c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C634.1,431.8,607.3,431.8,580.5,431.8z"/>
                    <path d="M637.2,399.8c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C637.2,380.9,637.2,390.3,637.2,399.8z"/>
                    <path d="M612.5,369.1c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C614.1,369.1,613.4,369.1,612.5,369.1z"/>
                </g>
                </svg>




                <svg version="1.1" className="map" id="cont17Dist3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  viewBox="0 0 1000 1000">
                <path className="st9" d="M695.4,812.2c-0.3,5.7-4.1,9.8-9,11.4c-6.3,2.4-13.3,4.4-19.9,4.7c-15.1,0.8-26.2,6.8-34.3,19.4
                    c-5.9,9.2-14.3,13.4-25.6,15.2c-9.8,1.5-18.8,7.7-28.2,11.7c-6.3,2.9-12.6,7.8-18.8,7.7c-22.9-1.1-38.7,12-55.6,24.2
                    c-48.3,34.9-49.8,34.9-108.6,41.1c-17.8,2-35.5,2.6-53.3,2.1c-0.1-48-0.8-334-0.8-336.1c0-2.3,0.3-25.1,0.3-25.1s-7.8-14.6-7.8-16.3
                    c0-1.4-0.6-82.1-0.9-111.4l16.1-3l-17.5-35.2l5.1-12.5c0,0,58.1-1.8,56.8-1.1c-1.5,0-31.3-111.4-31.3-111.4l-33.4-67.9
                    c0,0-17.6-136.9-24.2-174.2l17.3-4.7l27.1-0.9c0.2,6.6-2.7,12.5-7.2,18.7c-3.8,5.4-8.3,12.6-7.5,18.5c2.7,24.1,1.5,48,0.5,72.1
                    c-0.5,11,9.3,23.6,20.6,27c6.6,1.8,13.6,2,20.3,3.2c13.7,2.4,16.1,5.1,18.7,20.3c0.9,5,0.9,10.2,1.7,15.4c1.8,11,8,20.6,17.9,23.5
                    c15.2,4.4,23.3,13.3,27.4,27.6c0.9,3.2,2.7,7.4,5.3,8.6c25.4,12.2,36.6,41,62.8,52.4c13.4,6,19.9,18.5,20,33.7
                    c0.1,13.7,3.2,27.1,5.6,40.7c3.6,20,3.5,40.7,4.7,61.1c0.1,1.7,0,3.6-0.9,5c-12.6,21.2-6,39.2,8.4,56.6c4.2,5.1,6,12.3,7.8,19
                    c5.1,17.5,16.7,25.1,34.5,24.2c5.1-0.1,10.4-1.2,15.5-0.8c6.9,0.5,14.3,2.7,16.9,9.8c4.5,12,13.3,19.7,23,27.1
                    c1.1,0.8,2.1,3.8,1.7,4.4c-1.7,2.3-4.1,4.1-6.5,5.4c-1.4,0.8-3.3,0.5-5.1,0.8c-24.8,3.8-29.1,7.7-27.3,32.7
                    c0.8,11.6-0.9,20-10.1,26.8c-2.9,2.1-5.9,4.1-8,6.6c-6,6.8-9,14.6-5.7,23.6c3.9,10.8,12.3,17,22.9,18.2c8.3,0.9,17-1.4,25.4-2.6
                    c11.6-1.5,20,17.8,16.4,26.8c-3.6,8.7-11.4,12.5-17.2,18.8c-8.1,9.2-8.3,19.4-0.9,27.9c9.5,11,20.8,14.2,31.9,9.9
                    c10.4-3.8,16.9-11.4,22-20.6c0.8-1.1,3.5-2.1,4.2-1.7c2.3,1.7,5.4,3.8,5.9,6.3C695.7,801.8,695.6,807.1,695.4,812.2z"/>
                    <g className="district_show">
                    <path d="M496.7,473.1c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C496.7,468,496.7,470.5,496.7,473.1z"/>
                    <path d="M496.7,421.8c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C496.7,408.4,496.7,415.1,496.7,421.8z"/>
                    <path d="M413.7,487.1c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C467.3,487.1,440.6,487.1,413.7,487.1z"/>
                    <path d="M470.5,455.1c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C470.5,436.2,470.5,445.5,470.5,455.1z"/>
                    <path d="M445.7,424.3c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C447.4,424.3,446.7,424.3,445.7,424.3z"/>
                </g>
                <g className="district_show">
                    <path d="M480.7,822.3c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C480.7,817.1,480.7,819.7,480.7,822.3z"/>
                    <path d="M480.7,771c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C480.7,757.6,480.7,764.3,480.7,771z"/>
                    <path d="M397.8,836.2c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C451.4,836.2,424.6,836.2,397.8,836.2z"/>
                    <path d="M454.5,804.2c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C454.5,785.3,454.5,794.7,454.5,804.2z"/>
                    <path d="M429.8,773.5c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C431.4,773.5,430.7,773.5,429.8,773.5z"/>
                </g>
                </svg>



                <svg version="1.1" className="map" id="cont17Dist4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
                <path className="st10" d="M929,950c-52.9-0.6-105.6-5.3-158.3-8.9c-18.3-1.1-37.7-7.5-53.8-16.6c-34.6-19.4-71-26.9-109.8-22.7
                    c-23.6,2.2-43.2-6.7-61.3-21.1c-19.7-15.8-39.6-31.6-59.3-47.7c-20.5-16.6-44.1-24.4-70.4-24.7c-47.4,0-95.1-0.3-142.7-0.3
                    c-36-0.3-66.8,17.2-98.7,30.8c-21.1,8.9-42.1,10.5-61.8,5c7.8-27.4,23.3-58.5,23.3-58.5s-19.4-27.7-23-37.1
                    c-3.9-9.1-38.8-49.9-41.9-54.1c-2.8-4.4,8.3-110.9,8.3-110.9s15.8-76.2,18.6-80.4c2.8-4.2,4.2-75.9,4.2-75.9l-1.4-56.3
                    c0,0,35.2-60.4,38-67.4c2.2-5.5,12.2-63.2,16.9-91.5c52.4,0,209.5,0.3,217,0.8c9.4,1.1,125.8,39.9,134.4,45.5
                    c8.3,5.8,80.9,4.4,80.9,4.4l-5-80.9c0,0,264.2,3.6,281.6,5c17.7,1.7-6.4-115-6.4-115l29.1,6.7l-5.5-22.7l29.9-5.5
                    c0.6,54.1,1.7,202.6,1.7,205.1c0,3,14.4,29.9,14.4,29.9s-0.6,42.1-0.6,46.3C927.6,335.2,928.7,861.6,929,950z"/>
                    <g className="district_show">
                    <path d="M322.1,392.3c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C322.1,387.2,322.1,389.8,322.1,392.3z"/>
                    <path d="M322.1,341.1c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C322.1,327.7,322.1,334.4,322.1,341.1z"/>
                    <path d="M239.1,406.3c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C292.7,406.3,266,406.3,239.1,406.3z"/>
                    <path d="M295.9,374.3c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C295.9,355.4,295.9,364.8,295.9,374.3z"/>
                    <path d="M271.1,343.6c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C272.8,343.6,272.1,343.6,271.1,343.6z"/>
                </g>
                <g className="district_show">
                    <path d="M775.8,781.6c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C775.8,776.5,775.8,779.1,775.8,781.6z"/>
                    <path d="M775.8,730.4c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C775.8,717,775.8,723.7,775.8,730.4z"/>
                    <path d="M692.8,795.6c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C746.4,795.6,719.7,795.6,692.8,795.6z"/>
                    <path d="M749.6,763.6c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C749.6,744.7,749.6,754.1,749.6,763.6z"/>
                    <path d="M724.8,732.9c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C726.5,732.9,725.8,732.9,724.8,732.9z"/>
                </g>
                <g className="district_show">
                    <path d="M799.7,410.5c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C799.7,405.4,799.7,408,799.7,410.5z"/>
                    <path d="M799.7,359.2c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C799.7,345.9,799.7,352.6,799.7,359.2z"/>
                    <path d="M716.8,424.5c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C770.4,424.5,743.6,424.5,716.8,424.5z"/>
                    <path d="M773.5,392.5c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C773.5,373.6,773.5,382.9,773.5,392.5z"/>
                    <path d="M748.8,361.7c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C750.4,361.7,749.7,361.7,748.8,361.7z"/>
                </g>
                </svg>



                <svg version="1.1" className="map" id="cont17Dist5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
                <path className="st15" d="M774.6,390.5c-6.7,9.2-17.5,18.8-17.5,18.8L725,432.4l15.3,25.1c0,0-31.5,44.5-32.4,58.2
                    c-1,13.7-37.8,90.9-39.4,97.6c-1.6,6.4,18.4,60.4,18.4,60.4s-124.6,26.4-140.5,29.6c-15.6,2.9,15.3,94.1,15.3,94.1l-150.4,34.7
                    l1.3,21.3l-69.6,76c0,0-53.4,17.5-65.2,20.7c-16.5-6.4-35.9-5.7-48.3-21.3c-5.7-6.7-5.7-12.4-2.2-20.3c7.9-18.8,28.3-21.3,41.3-33.4
                    c18.1-16.2,21.9-36.9,17.2-59.4c-5.7-28-34.7-50.2-25.4-81.4c7.9-27.7,21.6-53.4,32.7-80.1c2.5-6.7,5.1-13.7,8.6-20
                    c20.3-39.4,54.7-57.5,97.6-57.9c21.6,0,42.9,7.6,64.5,9.5c14.3,1.3,28.9-1,42.9-3.5c15.9-2.9,24.2-15.6,30.8-28.6
                    c5.1-10.5-1.9-31.5-12.1-39.1c-11.4-8.6-22.9-17.8-35.3-25.1c-15.6-8.9-32.4-16.2-45.8-22.9c-8.3-12.1-13.7-21.6-20.3-30.2
                    c-40.1-50.9-51.5-111.3-56-173.6c-1.3-21.9,0-43.9,0-65.5c-1-87.4-0.3-70.9,64.5-125.6c14.6-12.1,32.1-17.5,50.5-16.5
                    c16.5,0.6,31.8-1,46.1-5.1l-9.5,128.8l24.8,66.8l24.8-16.5l45.8,13l57.9-0.6c0,0,21.6,15.6,17.5,18.1c-3.8,2.2,24.5,29.6,24.5,29.6
                    s2.2,13-2.9,19.4c-4.8,6.4,28,32.1,28,32.1S781.2,380.9,774.6,390.5z"/>
                    <g className="district_show">
                    <path d="M625,407.2c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C625,402.1,625,404.7,625,407.2z"/>
                    <path d="M625,356c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C625,342.6,625,349.3,625,356z"/>
                    <path d="M542.1,421.2c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C595.7,421.2,568.9,421.2,542.1,421.2z"/>
                    <path d="M598.8,389.2c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C598.8,370.3,598.8,379.7,598.8,389.2z"/>
                    <path d="M574.1,358.5c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C575.7,358.5,575,358.5,574.1,358.5z"/>
                </g>
                <g className="district_show">
                    <path d="M459.4,754.4c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C459.4,749.3,459.4,751.8,459.4,754.4z"/>
                    <path d="M459.4,703.1c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C459.4,689.7,459.4,696.4,459.4,703.1z"/>
                    <path d="M376.5,768.4c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C430.1,768.4,403.3,768.4,376.5,768.4z"/>
                    <path d="M433.2,736.4c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C433.2,717.5,433.2,726.8,433.2,736.4z"/>
                    <path d="M408.5,705.6c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C410.1,705.6,409.4,705.6,408.5,705.6z"/>
                </g>
                </svg>



                <svg version="1.1" className="map" id="cont17Dist6" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
                <path className="st12" d="M907.1,916.8c3.3-1.8-139.2,2.6-139.2,2.6L755.4,950l-94.5-190.5L607,799.4L583,823l-26.6-4.1l-6.3-28.8
                    l-220-0.4V567.6c0,0-162.4,5.5-231.8,8.5l3.3-185.3l9.6-32.1l-18.5-25.8L298,101.7l8.5-15.1l207.8-30.3l14.8,36.2L689,50
                    c16.2,91.5,59.4,427.1,59.4,427.1l82,166.5C830.4,643.6,903.5,916.8,907.1,916.8z"/>
                    <g className="district_show">
                    <path d="M349,299.5c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C349,294.4,349,296.9,349,299.5z"/>
                    <path d="M349,248.2c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C349,234.8,349,241.5,349,248.2z"/>
                    <path d="M266.1,313.5c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C319.7,313.5,292.9,313.5,266.1,313.5z"/>
                    <path d="M322.8,281.5c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C322.8,262.6,322.8,271.9,322.8,281.5z"/>
                    <path d="M298.1,250.7c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C299.7,250.7,299,250.7,298.1,250.7z"/>
                </g>
                <g className="district_show">
                    <path d="M743.9,697.8c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C743.9,692.7,743.9,695.3,743.9,697.8z"/>
                    <path d="M743.9,646.6c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C743.9,633.2,743.9,639.9,743.9,646.6z"/>
                    <path d="M660.9,711.8c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C714.5,711.8,687.8,711.8,660.9,711.8z"/>
                    <path d="M717.7,679.8c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C717.7,660.9,717.7,670.3,717.7,679.8z"/>
                    <path d="M692.9,649.1c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C694.5,649.1,693.8,649.1,692.9,649.1z"/>
                </g>
                </svg>




                <svg version="1.1" className="map" id="cont17Dist7" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
                <path className="st11" d="M916,582.9l-34.3,6.3l6.3,26l-33.3-7.6c0,0,27.6,133.6,7.3,131.7c-20-1.6-322.5-5.7-322.5-5.7l5.7,92.7
                    c0,0-83.2,1.6-92.7-5.1c-9.8-6.3-143.2-50.8-154-52.1c-8.6-0.6-188.6-1-248.6-1c1.9-10.8,3.2-17.8,3.2-17.8l19.4-85.4l194.9-2.9
                    l-1.3-212.1l77.1-1.6l4.8-267.9c59.7-2.5,199.4-7.3,199.4-7.3v191.1l189.2,0.3l5.4,24.8l22.9,3.5l20.6-20.3l46.3-34.3l81.3,163.8
                    l36.8,74.3L916,582.9z"/>
                    <g className="district_show">
                    <path d="M500.5,343.6c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C500.5,338.4,500.5,341,500.5,343.6z"/>
                    <path d="M500.5,292.3c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C500.5,278.9,500.5,285.6,500.5,292.3z"/>
                    <path d="M417.5,357.5c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C471.1,357.5,444.4,357.5,417.5,357.5z"/>
                    <path d="M474.3,325.5c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C474.3,306.6,474.3,316,474.3,325.5z"/>
                    <path d="M449.6,294.8c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C451.2,294.8,450.5,294.8,449.6,294.8z"/>
                </g>
                <g className="district_show">
                    <path d="M408.6,707.8c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C408.6,702.7,408.6,705.2,408.6,707.8z"/>
                    <path d="M408.6,656.5c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C408.6,643.1,408.6,649.8,408.6,656.5z"/>
                    <path d="M325.7,721.8c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C379.3,721.8,352.6,721.8,325.7,721.8z"/>
                    <path d="M382.5,689.8c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C382.5,670.9,382.5,680.2,382.5,689.8z"/>
                    <path d="M357.7,659c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C359.3,659,358.6,659,357.7,659z"/>
                </g>
                <g className="district_show">
                    <path d="M793.7,568.1c-0.9,0-1.8-0.1-2.8-0.1c-26.6,0-53.3,0-79.9,0c-0.8,0-1.7,0-2.7,0c0.1-3.5-0.5-6.7,1.1-9.9
                        c2.7-5.6,5.1-11.3,7.6-16.9c0.5-1.2,1.1-1.7,2.5-1.7c4.1,0.1,8.1,0,12.4,0c0,2.3,0,4.5,0,6.9c-1.3,0.2-3.3-0.1-3.9,0.7
                        c-1,1.4-1.2,3.4-1.9,5.3c16.7,0,33.1,0,49.5,0c-0.3-4.7-1.1-5.5-5.4-5.9c0-2.2,0-4.4,0-7c2.9,0,5.9,0.3,8.8-0.1
                        c4.1-0.5,6.1,1,7.5,4.9c2,5.5,4.8,10.8,7.2,16.1C793.7,563,793.7,565.6,793.7,568.1z"/>
                    <path d="M793.7,516.9c-5,0-9.9,0-14.9,0.1c-2,0-2.9-1-2.9-2.8c0-4.8-0.1-9.7,0.1-14.5c0-1,1-1.9,1.5-2.8c5.4,0,10.8,0,16.2,0
                        C793.7,503.5,793.7,510.2,793.7,516.9z"/>
                    <path d="M710.8,582.1c0-3.9,0-7.7,0-11.7c26.9,0,53.6,0,80.4,0c0,3.9,0,7.7,0,11.7C764.4,582.1,737.6,582.1,710.8,582.1z"/>
                    <path d="M767.5,550.1c-11.1,0-22,0-33,0c0-9.4,0-18.8,0-28.3c4.4,0,8.8,0,13.2,0c-0.7,1.4-1.5,2.6-2,3.9c-0.8,2.5-0.2,4.9,1.4,6
                        c2,1.3,4,0.7,5.8-0.6c4-2.8,8-5.7,12-8.5c0.7-0.5,1.5-0.8,2.6-1.3C767.5,531.2,767.5,540.6,767.5,550.1z"/>
                    <path d="M742.8,519.4c1.7-5.8,2.7-11.7,9-14.3c1.3-0.5,2.4-1.4,3.7-2.2c6.5-3.9,10-4.6,18.1-3.5c0,3.3,0,6.7,0,10.1
                        c0,0.2-0.2,0.5-0.4,0.7c-1.9,1.8-3.7,3.7-4.3,6.4c-0.1,0.4-0.6,0.7-1,1c-5.2,3.7-10.4,7.5-15.6,11.1c-1.1,0.8-2.3,2.1-3.8,0.7
                        c-0.9-0.8-0.7-3.5,0.4-4.7c2.5-2.9,5.2-5.7,7.7-8.6c0.5-0.6,0.7-1.5,1.1-2.3c-0.8-0.3-1.7-0.8-2.5-0.8c-1.9-0.1-3.7,0.3-4.4,2.5
                        c-1.4,3.9-1.4,3.9-5.5,3.8C744.4,519.4,743.7,519.4,742.8,519.4z"/>
                </g>
                </svg>


      </div>
      );
     }
   }

const mapStateToProps = ({ campTeam, authUser }) => {
    const { constituency_list } = campTeam;
    const { updatedYearValue, election_result, marginalColorValue, trendsResults } = authUser

    return { constituency_list, updatedYearValue, election_result, marginalColorValue, trendsResults };
};

export default connect(mapStateToProps, actions, null, {withRef: true})(Map);