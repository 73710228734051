import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { NotificationContainer, NotificationManager } from 'react-notifications';
// redux action
import * as actions from '../actions';
import { connect } from 'react-redux';
import moment from 'moment'
import Datatable from 'react-bs-datatable'; 
import PieChart from '../Components/PieChart2';


class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
      selectedContituency: "",
      selectedPollingDiv: "",
      selectedStreet: "",
    };
  }

  render() {
    const genderChartData = [{
      "data": "Male",
      "value":25,
      "color": '#4ca9f8',
  }, {
      "data": "Female",
      "value": 36,
      "color": '#fb5858',
  }, {
      "data": "Others",
      "value": 50,
      "color": '#ffff00',
  }]



  const header = [
    { prop: 'name', title: 'Name' },
    { prop: 'score', title: 'Score' }
  ];

  const body = [
    { name: 'Jack', score: 100 },
    { name: 'Sam', score: 55 },
    { name: 'Sam', score: 55 },
    { name: 'Sam', score: 55 },
    { name: 'Sam', score: 55 },
    { name: 'Sam', score: 55 },
    { name: 'Sam', score: 55 },
    { name: 'Sam', score: 55 },
  ];


  const onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };
   
  const customLabels = {
    first: '<<',
    last: '>>',
    prev: '<',
    next: '>',
    show: 'Display',
    entries: 'rows',
    noResults: 'There is no data to be displayed',
  };

    return (
      <div>

        <div className="container-fluid">
          <div className="row my-3 px-4">
            <div className="col-sm-5">
              <h1 className="text-primary ">Reports</h1>
            </div>

          </div>




          <div className="row mt-5 px-4">
              <div className="col-sm-12">
                  <ul className="reportTabs">
                    {/* <li><a href="#/campaign-detail">Campaign Details</a></li> */}
                    <li><a href="#/top-registered-voters-pd-wise">Top 10 Registered Voters PD wise</a></li>
                    {/* <li><a href="#/campaign-worker-detail">Campaign Worker Details</a></li> */}
                    <li><a href="#/ten-new-voters-pd-wise">Top 10 New Voters PD wise</a></li>
                    <li><a href="#/winners-and-victory-margin">Winners and Victory Margin Constituency and PD Wise</a></li>
                    <li><a href="#/top-pd-partywise">Top 10 PDs Party Wise</a></li>
                    <li><a href="#/hourly-slot-voters">Hourly Slot Wise Voters</a></li>
                    <li><a href="#/voter-database">Comparison Of Newly Added Voters</a></li>
                  </ul>
              </div>
          </div>


        </div>



      </div>
    )
  }
}

const mapStateToProps = ({ campTeam, payment, survey }) => {
  const { constituency_list, polling_div } = campTeam;
  const { major_concern } = payment;
  const { street_list } = survey;
  return { constituency_list, polling_div, street_list, major_concern }
}
export default connect(mapStateToProps, actions)(Reports);
