import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';

import { Tabs, Tab } from 'react-bootstrap-tabs';

import Serial from '../Components/Serial2';


class AnalyticsConclusion extends Component {


    componentDidMount() {
        this.props.getConclusionFromGoogleTrendsData('unc', '2019')
    }

    render() {


        return (
            <div>
                <div className="container-fluid">
                    <div className="row my-3 px-4">
                        <div className="col-sm-12">
                            <h1 className="text-primary pull-left">Analytics Conclusion</h1>
                            <button class="btn_cust btn-primary pull-right"  onClick={() => window.print()}>Download and Print Report</button>
                        </div>
                    </div>


                    <div className="row px-4">
                        <div className="col-sm-12">
                            <div className="container-fluid page page_live_count">

                                <div className="trend_cont">
                                    <h1 className="live_trend_head">The Next PM: <span>Kamla Persad</span> or <span>Keith Rowley</span>?</h1>

                                </div>

                                <div className="row">

                                    <div className="col-sm-12 sm-m-0 sm_100 text-center">
                                        <div className="live_trend_chart">
                                            <Serial />
                                        </div>

                                        <div className="trend_candi">
                                            <div className="candi_box">
                                                <img className="img-fluid" src="../img/Kamla_Persad_Bissesar.jpg" />
                                                <div className="candi_name">Kamla Persad Bissesar <p>UNC</p></div>
                                            </div>
                                            <div className="candi_box">
                                                <img className="img-fluid" src="../img/KeithRowley.jpg" />
                                                <div className="candi_name">Keith Christopher Rowley <p>PNM</p></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>
        )
    }
}

const mapStateToProps = ({ campTeam, authUser }) => {
    const { constituency_list } = campTeam;
    const { updatedYearValue, election_result, marginalColorValue, trendsResults, trendsResults_1 } = authUser

    return { constituency_list, updatedYearValue, election_result, marginalColorValue, trendsResults, trendsResults_1 };
};

export default connect(mapStateToProps, actions)(AnalyticsConclusion);
