import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { NotificationContainer, NotificationManager } from 'react-notifications';
// redux action
import * as actions from '../actions';
import { connect } from 'react-redux';
import PieChart from '../Components/PieChart2';


class TalkingPoints extends Component {
  csvLink = React.createRef()
  constructor(props) {
    super(props);

    this.state = {
      tofuIsReady: false,
      selectedContituency: "",
      selectedPollingDiv: "",
      selectedStreet: "",
      excel_concer_data: [],
      downloadData: [],
      headers: [],
      allCocerns: [],
      review: [],
      reviewData: [],
      is_loaded: 0,
      selected_tb_row: null
    };
  }

  componentDidMount() {
    this.props.listConsituency();
    this.props.getMajorConcern();
    this.props.getConcernExcelData();
  }


  //Get Excel Data
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.excel_concer_data !== prevState.excel_concer_data) {
      return { excel_concer_data: nextProps.excel_concer_data };
    }
    if (nextProps.allCocerns !== prevState.allCocerns) {
      return { allCocerns: nextProps.allCocerns };
    }

    else return null;
  }

  //Get Review Data In state
  componentDidUpdate(nextProps, prevState) {

    if (this.props.review !== nextProps.review && this.state.is_loaded != 1) {
      debugger
      this.setState({ review: this.props.review, reviewData: this.props.review, is_loaded: 1 })
    }

  }

  //On constituency Changed
  ConstituencyChanged = e => {
    var qryString = "";
    console.log('Constituency');
    console.log(e.target.value);
    if (e.target.value == '') {
      this.setState({ selectedContituency: e.target.value, selectedStreet: '', is_loaded: 0 })
      this.props.getPollingDiv(e.target.value);
      this.props.getSteet();
      this.props.getMajorConcern(qryString);
      this.props.getConcernExcelData(qryString);

    } else {

      this.setState({ selectedContituency: e.target.value, is_loaded: 0 })
      this.props.getPollingDiv(e.target.value);
      qryString = '?constituencyID=' + e.target.value;
      this.props.getMajorConcern(qryString);
      this.props.getConcernExcelData(qryString);
    }
  };
  PollingDivChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedPollingDiv: e.target.value, selectedStreet: '', is_loaded: 0 })
      this.props.getSteet();
      qryString = '?constituencyID=' + this.state.selectedContituency;
      this.props.getMajorConcern(qryString);
      this.props.getConcernExcelData(qryString);
    } else {

      this.setState({ selectedPollingDiv: e.target.value, is_loaded: 0 })
      this.props.getSteet(e.target.value);
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + e.target.value;
      this.props.getMajorConcern(qryString);
      this.props.getConcernExcelData(qryString);
    }
  };
  //On street Changed
  streetChanged = e => {
    var qryString = "";
    if (e.target.value == '') {
      this.setState({ selectedStreet: e.target.value, is_loaded: 0 })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv;

      this.props.getMajorConcern(qryString);
      this.props.getConcernExcelData(qryString);
    } else {

      this.setState({ selectedStreet: e.target.value, is_loaded: 0 })
      qryString = '?constituencyID=' + this.state.selectedContituency + '&pollingDivisionID=' + this.state.selectedPollingDiv + '&streetID=' + e.target.value;

      this.props.getMajorConcern(qryString);
      this.props.getConcernExcelData(qryString);
    }
  }


  download = (event) => {

    console.log('****Excel Data Inside Downloads****')
    console.log(this.state.excel_concer_data)
    console.log(this.state.excel_concer_data.length)

    var excel_downloaded_data = [];
    var headers = [
      { label: "Constituency", key: "constituency" },
      { label: "Polling Division", key: "polling_division" },
      { label: "Street", key: "street" },
      { label: "Voter Name", key: "voter_name" },
      { label: "Concern", key: "concern" },
      { label: "Description of the concern", key: "description" }
    ];


    if (this.state.excel_concer_data.length > 0) {
      this.state.excel_concer_data.map((data) => {
        excel_downloaded_data.push({
          constituency: data.constituencyID.constituencyName,
          polling_division: data.pollingDivisionID.pollingDivisionName,
          street: data.voterID.streetName,
          voter_name: data.voterID.name,
          concern: data.concern,
          description: data.descritption

        })
      })
    }
    console.log('--> Downloads')
    console.log(excel_downloaded_data)
    console.log(headers)
    this.setState({ downloadData: excel_downloaded_data, headers: headers }, () => {
      this.csvLink.current.link.click()
    })

  }
  //Handle Filter for Concern
  handleClick = (data, index) => {
    var concerFilter = this.state.reviewData.filter(function (concernData) {
      return concernData.concern == data;
    });
    this.setState({ review: concerFilter, selected_tb_row: index });
  }

  render() {
    console.log('All Consern');
    console.log(this.props.allCocerns);

    var genderChartData = [];
    if (this.props.major_concern.allCocerns) {
      this.props.major_concern.allCocerns.map((data) => {
        genderChartData.push({
          "country": data._id,
          "litres": data.total,
        })
      })
    }

    return (
      <div>

        <div className="container-fluid">
          <div className="row my-3 px-4 printRow">
            <div className="col-sm-5 printfleft">
              <h1 className="text-primary ">Major Concerns</h1>
            </div>

            <div className="col-sm-7 printfright">
              <div className="title_right_sec">
                <div className="form-inline mr-3 mb-3">
                  <label className="mr-2">Filter By</label>
                  <select
                    className="form-control pull-right"
                    id="const_sele"
                    name="consti"
                    onChange={this.ConstituencyChanged}
                    value={this.state.selectedContituency}
                  >
                    <option value="">Select a Constituency</option>
                    {this.props.constituency_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.constituencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group mr-3">
                  <select className="form-control pull-right"
                    onChange={this.PollingDivChanged}
                    value={this.state.selectedPollingDiv}
                  >
                    <option value="">Select Polling div</option>
                    {this.props.polling_div.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.pollingDivisionName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <select className="form-control pull-right"
                    onChange={this.streetChanged}
                    value={this.state.selectedStreet}
                  >
                    <option value="">Select Street</option>
                    {this.props.street_list.map((data, index) => {
                      return (
                        <option value={data._id}>
                          {data.street_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <button class="btn_cust btn-primary pull-right mt-2 ml-2 printHide" onClick={() => window.print()}>Download and Print Report</button>
              </div>
            </div>

          </div>

          {
            (!(this.props.loading)) ?
              <div>
                <div className="row px-4">
                  <div className="col-sm-7">
                    <PieChart dataValue={genderChartData} />
                    <h5 className="text-center mt-1">Total Concerns :{(this.props.major_concern) ? this.props.major_concern.total : 0}</h5>
                  </div>
                  <div className="col-sm-5">
                    <div class="card">
                      <div class="card-header font-weight-bold">Top 5 Major Concerns</div>
                      <ul class="list-group list-group-flush toplistgroup">
                        {(this.props.major_concern.topConcerns) ?
                          this.props.major_concern.topConcerns.map((data, index) => {
                            return <li class={(this.state.selected_tb_row == 'tf_' + index) ? 'list-group-item active' : 'list-group-item'} onClick={() => this.handleClick(data._id, 'tf_' + index)}>{data._id} : {data.total}</li>
                          })
                          : null
                        }
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row mt-3 px-4 printRow">
                  <div className="col-sm-7 printw100">
                    <div className="shadow_box tableScroll tableClickable">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.allCocerns) ?
                            this.state.allCocerns.map((data, index) => {
                              return (<tr class={(this.state.selected_tb_row == 'tb_' + index) ? 'active' : ''} onClick={() => this.handleClick(data._id, 'tb_' + index)}>
                                <td>{data._id}</td>
                                <td>{data.total}</td>
                              </tr>)
                            })
                            : null
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>

                  <div className="col-sm-5 printHide">
                    <div class="card remarkCard mb-4">
                      <div class="card-header font-weight-bold">
                        <div className="mt-2 pull-left">Remarks</div>
                        <button class="btn_cust btn-primary pull-right font-weight-bold" onClick={this.download}>Download CSV</button>
                        <CSVLink
                          data={this.state.downloadData}
                          headers={this.state.headers}
                          filename="ConcernData.csv"
                          className="hidden"
                          ref={this.csvLink}
                          target="_blank" />
                      </div>
                      <ul class="list-group list-group-flush">
                        {(this.state.review.length > 0) ?
                          this.state.review.map((data) => {
                            return (<li class="list-group-item">{data.voterID.name} : {data.constituencyID.constituencyName} {data.pollingDivisionID.pollingDivisionName}  : {data.voterID.streetName}
                              <p>{data.descritption}</p>
                            </li>)
                          })
                          : null
                        }

                      </ul>
                    </div>
                  </div>

                </div>
              </div>

              :
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>

          }

        </div>

        <div className="printTable">
          <table border="1" cellPadding="10">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Constituency Name</th>
                <th>Polling Division</th>
                <th>Street</th>
                <th>Voter Name</th>
                <th>Concern Name</th>
                <th>Description of the concern/issue</th>
              </tr>
            </thead>
            <thead>

              {(this.state.review.length > 0) ?
                this.state.review.map((data, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{data.constituencyID.constituencyName}</td>
                      <td>{data.pollingDivisionID.pollingDivisionName}</td>
                      <td>{data.voterID.streetName} </td>
                      <td>{data.voterID.name}</td>
                      <td>{data.concern}</td>
                      <td><p>{data.descritption}</p></td>
                    </tr>
                  )
                })
                : null
              }
            </thead>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ campTeam, payment, survey }) => {
  const { constituency_list, polling_div } = campTeam;
  const { major_concern, loading, review, allCocerns } = payment;
  const { street_list, excel_concer_data } = survey;
  return { constituency_list, polling_div, street_list, excel_concer_data, major_concern, loading, review, allCocerns }
}
export default connect(mapStateToProps, actions)(TalkingPoints);
