import React, { Component } from 'react';
import AmCharts from "@amcharts/amcharts3-react";

class PieChart extends Component{

  render(){
    const config = {
      "type": "pie",
      "theme":"none",
      "dataProvider":this.props.dataValue,
      "valueField": "litres",
      "titleField": "country",
       "balloon":{
        "fixedPosition":true
        },
      "autoMargins":false,
      "marginBottom":"0",
      "marginTop":"50",
      "radius": 90,
      "fontSize": 13,
     
      
    }; 

    return (
      <div>
        <AmCharts.React className="amchart_pie2" options={config} />
      </div>
  )

  }
}

export default PieChart;
