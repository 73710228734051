import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import DonutChart from '../../Components/DonutChart';
import Serial from '../../Components/Serial';


class Dashboard extends Component{
 
    render(){

    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-4">
                      <h1 className="text-primary ">Dashboard / Results</h1>
                  </div>
                  <div className="col-sm-8">
                        <div className="form-group mt-3">
                            <select className="form-control pull-right" id="const_sele">
                                <option>Select a Street</option>
                                <option>Street 1</option>
                                <option>Street 2</option>
                                <option>Street 3</option>
                                <option>Street 4</option>
                            </select>
                        </div>

                        <div className="form-group mt-3">
                            <select className="form-control pull-right mr-2" id="const_sele">
                                <option>Select a Polling Division</option>
                                <option>Division 1</option>
                                <option>Division 2</option>
                                <option>Division 3</option>
                                <option>Division 4</option>
                            </select>
                        </div>
                        
                        <div className="form-group mt-3">
                            <select className="form-control pull-right  mr-2" id="const_sele">
                                <option>Select a Constituency</option>
                                <option>Constituency 1</option>
                                <option>Constituency 2</option>
                                <option>Constituency 3</option>
                                <option>Constituency 4</option>
                            </select>
                        </div>
                  </div>
            </div>   


            <div className="row px-4 mt-5">
                <div className="col-sm-12">
                    <h3>Survey Analysis</h3>
                    <div className="shadow_box">
                        <div className="row">
                            <div className="col-sm-2 text-center allyt_box">
                                <p>Attempts</p>
                                <p className="analyvalue">1</p>
                            </div>
                            <div className="col-sm-2 text-center allyt_box">
                                <p>Voter</p>
                                <p className="analyvalue">1</p>
                            </div>
                            <div className="col-sm-2 text-center allyt_box">
                                <p>Houses</p>
                                <p className="analyvalue">1</p>
                            </div>
                            <div className="col-sm-2 text-center allyt_box">
                                <p>Talking Points</p>
                                <p className="analyvalue">1</p>
                            </div>
                            <div className="col-sm-2 text-center allyt_box">
                                <p>Hit Rate</p>
                                <p className="analyvalue">100%</p>
                            </div>
                            <div className="col-sm-2 text-center allyt_box">
                                <p>Avg. Reception</p>
                                <p className="analyvalue">5.0 <i className="fa fa-star"></i></p>
                            </div>
                        </div>
                    </div>

                    <h3 className="mt-5">General Analysis</h3>
                    <div className="shadow_box">
                        <div className="row">
                            <div className="col-sm-3 text-center allyt_box">
                                <p>Survey Workers</p>
                                <p className="analyvalue">1</p>
                            </div>
                            <div className="col-sm-3 text-center allyt_box">
                                <p>Poll Workers</p>
                                <p className="analyvalue">51</p>
                            </div>
                            <div className="col-sm-3 text-center allyt_box">
                                <p>Vehicle Provider</p>
                                <p className="analyvalue">100</p>
                            </div>
                            <div className="col-sm-3 text-center allyt_box">
                                <p>Streets</p>
                                <p className="analyvalue">25</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-5 px-4">
                <div className="col-sm-4">
                    <div className="shadow_box">
                        <h4>Hit Rate</h4>
                        <DonutChart />
                    </div>
                </div>

                <div className="col-sm-8">
                    <div className="shadow_box">
                        <h4>Reception</h4>
                        <Serial />
                    </div>
                </div>
            </div>   

            <div className="row mt-5 px-4" style={{display:'none'}}>
                <div className="col-sm-12">
                    <div className="shadow_box">
                        <h4>Lists</h4>
                        
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th>Name</th>
                                    <th>Attempts</th>
                                    <th>Reception</th>
                                    <th>Hit Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>                                
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row mt-5 px-4">
                <div className="col-sm-12">
                    <div className="shadow_box">
                        <h4>Streets</h4>
                        
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th>Name</th>
                                    <th>Attempts</th>
                                    <th>Reception</th>
                                    <th>Hit Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Potential Volunteers</td>
                                    <td>80</td>
                                    <td>3.1</td>
                                    <td>40%</td>
                                </tr>                                
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row mt-5 px-4">
                <div className="col-sm-12">
                    <div className="shadow_box">
                        <h4>Surveys</h4>
                        
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th>Name</th>
                                    <th>Questions</th>
                                    <th>Completed</th>
                                    <th>Questions Answered</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>New Road - Survey to capture feedback on the upcoming</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>0</td>
                                    <td><Link to="" className="btn btn-small btn-link">Full Overview ></Link></td>
                                </tr>
                                <tr>
                                    <td>New Road - Survey to capture feedback on the upcoming</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>0</td>
                                    <td><Link to="" className="btn btn-small btn-link">Full Overview ></Link></td>
                                </tr>
                                <tr>
                                    <td>New Road - Survey to capture feedback on the upcoming</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>0</td>
                                    <td><Link to="" className="btn btn-small btn-link">Full Overview ></Link></td>
                                </tr>
                                <tr>
                                    <td>New Road - Survey to capture feedback on the upcoming</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>0</td>
                                    <td><Link to="" className="btn btn-small btn-link">Full Overview ></Link></td>
                                </tr>
                                <tr>
                                    <td>New Road - Survey to capture feedback on the upcoming</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>0</td>
                                    <td><Link to="" className="btn btn-small btn-link">Full Overview ></Link></td>
                                </tr>
                                <tr>
                                    <td>New Road - Survey to capture feedback on the upcoming</td>
                                    <td>4</td>
                                    <td>2</td>
                                    <td>0</td>
                                    <td><Link to="" className="btn btn-small btn-link">Full Overview ></Link></td>
                                </tr>                           
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row mt-5 mb-5 px-4">
                <div className="col-sm-4">
                    <div className="shadow_box">
                        <h4>Gender</h4>
                        <DonutChart />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="shadow_box">
                        <h4>Age</h4>
                        <DonutChart />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="shadow_box">
                        <h4>Employment</h4>
                        <DonutChart />
                    </div>
                </div>
                <div className="col-sm-4 mt-4">
                    <div className="shadow_box">
                        <h4>Favourite Party</h4>
                        <DonutChart />
                    </div>
                </div>
                <div className="col-sm-4 mt-4">
                    <div className="shadow_box">
                        <h4>Mobile Numbers</h4>
                        <DonutChart />
                    </div>
                </div>
                <div className="col-sm-4 mt-4">
                    <div className="shadow_box">
                        <h4>Deceased</h4>
                        <DonutChart />
                    </div>
                </div>
            </div> 

            {/* <div className="row mt-5 px-4">
                <div className="col-sm-4">
                    <div className="shadow_box">
                        <h4>Expected Voter turnout</h4>
                        <DonutChart />
                    </div>
                </div>

                <div className="col-sm-8">
                    <div className="shadow_box">
                        <h4>Volunteer Groups</h4>
                        
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th>Constituency</th>
                                    <th>Group Name</th>
                                    <th>Volunteer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Constituency</td>
                                    <td>Hello Group</td>
                                    <td>1154</td>
                                </tr>
                                <tr>
                                    <td>Constituency</td>
                                    <td>Hello Group</td>
                                    <td>1154</td>
                                </tr>
                                <tr>
                                    <td>Constituency</td>
                                    <td>Hello Group</td>
                                    <td>1154</td>
                                </tr>
                                <tr>
                                    <td>Constituency</td>
                                    <td>Hello Group</td>
                                    <td>1154</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row my-5 px-4">
                <div className="col-sm-4">
                    <div className="shadow_box">
                        <h4>Hits</h4>
                        <DonutChart />
                    </div>
                </div>

                <div className="col-sm-8">
                    <div className="shadow_box">
                        <h4>Previous Year Comparison</h4>
                        <Serial />
                    </div>
                </div>
            </div>          */}

        </div>


      </div>
    )
  }
}

export default Dashboard;
