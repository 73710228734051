/*
 src/reducers/rootReducer.js
*/

/**
 * App Reducers
 */
import { combineReducers} from 'redux';
import ActiveUserReducer  from './ActiveUserReducer';

import CampaignTeamReducer  from './CampaignTeamReducer';
import surveyReducer  from './surveyReducer';

import voterReducer from './voterReducer'
import paymentReducer from './paymentReducer'
import pollReducer from './pollReducer';

const reducers = combineReducers({
  authUser: ActiveUserReducer,
  campTeam: CampaignTeamReducer,
  survey: surveyReducer,
  voter : voterReducer,
  payment:paymentReducer,
  poll:pollReducer,
});

export default reducers;