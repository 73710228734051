import React, { Component } from 'react';

import {Route, Link, NavLink, HashRouter as Router} from 'react-router-dom';

import Dashboard from './Dashboard'
import AnalyticsPreviousResult from './AnalyticsPreviousResult'
import AnalyticsGoogleSurvey from './AnalyticsGoogleSurvey'
import AnalyticsConclusion from './AnalyticsConclusion'

import VoterDatabase from './VoterDatabase'
import CampaignTeam from './CampaignTeam'
import StreetCaptains from './StreetCaptains'
import Survey from './Survey'
import SurveyQuestion from './SurveyQuestions'
import ConstituencyListing from './ConstituencyListing'
import ConstituencyListingDetail from './ConstituencyListingDetail'
import StreetListing from './StreetListing'
import StreetListingDetail from './StreetListingDetail'
import TalkingPoints from './TalkingPoints'
import TalkingView from './TalkingView'
import PollWorker from './PollWorker'
import SurveyWorker from './SurveyWorker'
import VehicleProvider from './VehicleProvider'
import AssignPartyLeaders from './AssignPartyLeaders'
import PaymentRequest from './PaymentRequest'
import PaymentRequestDetail from './PaymentRequestDetail'
import Reports from './Reports'
import Top10RegisteredVotersPDwise from './Top10RegisteredVotersPDwise'
import TenNewVotersPDwise from './TenNewVotersPDwise'
import Top10PDsPartywise from './Top10PDsPartywise'
import HourlySlotVoters from './HourlySlotVoters'
import WinnersandVictoryMargin from './WinnersandVictoryMargin'
import PrintFormatMajorConcern from './PrintFormatMajorConcern'
import DontUse from './DontUse'
import CampaignDetail from './CampaignDetail'
import CampaignWorkerDetail from './CampaignWorkerDetail'














///Street Captain
import StreetDashboard from './StreetCaptain/Dashboard'
import StreetPaymentRequest from './StreetCaptain/PaymentRequest'
import StreetPaymentRequestDetail from './StreetCaptain/PaymentRequestDetail'
import StreetPollWorker from './StreetCaptain/PollWorker'
import StreetPollWorkerView from './StreetCaptain/PollWorkerView'
import StreetSurveyWorker from './StreetCaptain/SurveyWorker'
import StreetSurveyWorkerView from './StreetCaptain/SurveyWorkerView'
import StreetVehicleProvider from './StreetCaptain/VehicleProvider'
import StreetVehicleProviderView from './StreetCaptain/VehicleProviderView'
import StreetSurvey from './StreetCaptain/Survey'
import StreetSurveyQues from './StreetCaptain/SurveyQuestions'







// 
// import Documents from './Documents'
// import SurveyResult from './SurveyResult'
// import ElectionVehicles from './ElectionVehicles'
// import VolunteerSection from './VolunteerSection'
// import VoterListComparison from './VoterListComparison'
// import PredictionResult from './PredictionResult'

import Profile from './Profile'

import ManagePayments from './ManagePayments'



class SuperDashboard extends Component{   

  constructor () {
    super()
    this.state = {
      isHidden: true,
      visible: false,
      role: localStorage.getItem('role'),
      user_name:'',
      constituencyName:'',
      pollingDivisionName:''
    }

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  // componentWillMount(){
  //   console.log(this.props.location.state.role)
  //   //debugger
  // }

  componentWillMount(){
    
    this.setState({
      role: localStorage.getItem('role')
    })
    console.log(localStorage.getItem('role'))
    
  }
  //Get UserName
  async componentDidMount (){
    this.setState({
      user_name: await localStorage.getItem('name'),
      constituencyName:await localStorage.getItem('constituencyName'),
      pollingDivisionName:await localStorage.getItem('pollingDivisionName'),
    })
  }

  toggleHidden () {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleMenu() {
    this.setState({visible: !this.state.visible})
}


   handleLogout=(e)=>
  {
    localStorage.clear();
    this.props.history.push('/')
  }
  render(){
  
    return (
      <div>
        <Router>
          <div>
          <div className="nav_panel">
               <div className="row">
                  {/* <div className="col-sm-2">
                      <h3 className="text-left px-3">EMS</h3>
                  </div> */}
                <div className="col-sm-12 headRight">
                  {/* <Link className=".App-logo" to="/">
                            <img src="img/logo.png" alt="" className="img-fluid logo-light" />
                        </Link> */}
                        <h3 className="text-left px-3">EMS</h3>
                        <h4 className="text-white pull-left mt-4 headRole">{this.state.role === '1' ? `${this.state.user_name} (Campaign Manager)` :`${this.state.user_name} (Street Captains) Counstituency :-${this.state.constituencyName} Polling Div :-${this.state.pollingDivisionName}`}</h4>
                        <div className="nav_profile pull-right">
                            <div className="dropdown">
                            {this.state.role === '1' ? 
                              <img className="rounded-circle mx-auto img-thumbnail" src={(localStorage.getItem('CM_profile')!="")?localStorage.getItem('CM_profile'):'../img/candi_img.png'} alt="Card image cap" onClick={this.toggleHidden.bind(this)} />
                              :<img className="rounded-circle mx-auto img-thumbnail" src={(localStorage.getItem('SC_profile')!="")?localStorage.getItem('SC_profile'):'../img/candi_img.png'} alt="Card image cap" onClick={this.toggleHidden.bind(this)} />
                            }
                               <div className="dropdown-menu">
                                <Link className="dropdown-item" to="/profile"><i className="fa fa-user"></i> &nbsp; Profile</Link>
                                <Link className="dropdown-item" to="/manage-payments"><i className="fa fa-money"></i> &nbsp; Manage Payments</Link>
                                <Link className="dropdown-item" to="" onClick={this.handleLogout}><i className="fa fa-lock"></i> &nbsp; Log Out</Link>
                              </div>
                              
                            </div>
                        </div>
                  </div>              
              </div>
              
          </div>

        <div className="main_container">
        
          <div>
          <div className="admin_sidebar">
            <div className="NavBlocks">

                {this.state.role === '1' ?                 
                
                <ul>                    
                    <li><NavLink to="/"><i className="fa fa-home"></i> Dashboard / Results</NavLink></li>    
                    <li>
                      <Link to="" onClick={this.toggleMenu}>
                        <i className="fa fa-bar-chart"></i> Analytics 
                        
                        {this.state.visible ? <i className="arrow_icon fa fa-chevron-up"></i> : <i className="arrow_icon fa fa-chevron-down"></i> }
                      </Link>
                      {this.state.visible &&<ul className="submenu">
                          <li>
                          
                              {/* <NavLink to="/conclusion" activeClassName="active"><i className="fa fa-bar-chart"></i> Conclusion</NavLink> */}
                              <NavLink to="/google-survey" activeClassName="active"><i className="fa fa-bar-chart"></i> Google VS Survey</NavLink>
                              <NavLink to="/previous-results" activeClassName="active"><i className="fa fa-bar-chart"></i> Previous Results</NavLink>
                          </li>
                      </ul>}
                    </li>      
                    <li><NavLink to="/voter-database" activeClassName="active"><i className="fa fa-users"></i> Voter Database</NavLink></li>
                    {/* <li><NavLink to="/campaign-team"  activeClassName="active"><i className="fa fa-users"></i> Campaign Team</NavLink></li> */}
                    <li><NavLink to="/street-captains"  activeClassName="active"><i className="fa fa-user-circle"></i> Street Captains</NavLink></li>
                    <li><NavLink to="/survey"  activeClassName="active"><i className="fa fa-check-square-o"></i> Survey</NavLink></li>               
                    <li><NavLink to="/talking-points"  activeClassName="active"><i className="fa fa-hand-o-right"></i>Major Concerns</NavLink></li>
                    <li><NavLink to="/reports"  activeClassName="active"><i className="fa fa-bars"></i>Reports</NavLink></li>
                    <li><NavLink to="/payment-request" activeClassName="active"><i className="fa fa-money"></i> Payment Request</NavLink></li>                  
                    <li><NavLink to="/poll-worker" activeClassName="active"><i className="fa fa-hourglass-start"></i> Poll Worker</NavLink></li>
                    <li><NavLink to="/survey-worker" activeClassName="active"><i className="fa fa-list-alt"></i> Survey Worker</NavLink></li>
                    <li><NavLink to="/vehicle-provider" activeClassName="active"><i className="fa fa-car"></i> Vehicle Provider</NavLink></li>
                    {/* <li><NavLink to="/constituency-listing" activeClassName="active"><i className="fa fa-thumbs-up"></i> Constituency Listing</NavLink></li> */}
                    <li><NavLink to="/street-listing" activeClassName="active"><i className="fa fa-th-list"></i> Street Listing</NavLink></li>
                </ul>
                
                : 
                
                
                <ul>                    
                    <li><NavLink to="/"><i className="fa fa-home"></i> Dashboard</NavLink></li>        
                    <li><NavLink to="/voter-database" activeClassName="active"><i className="fa fa-users"></i> Voter Database</NavLink></li>
                    <li><NavLink to="/survey" activeClassName="active"><i className="fa fa-check-square-o"></i> Survey</NavLink></li>               
                    <li><NavLink to="/talking-points" activeClassName="active"><i className="fa fa-hand-o-right"></i>Major Concerns</NavLink></li>
                    <li><NavLink to="/payment-request" activeClassName="active"><i className="fa fa-money"></i> Payment Request</NavLink></li>                  
                    <li><NavLink to="/poll-worker" activeClassName="active"><i className="fa fa-hourglass-start"></i> Poll Worker</NavLink></li>
                    <li><NavLink to="/survey-worker" activeClassName="active"><i className="fa fa-list-alt"></i> Survey Worker</NavLink></li>
                    <li><NavLink to="/vehicle-provider" activeClassName="active"><i className="fa fa-car"></i> Vehicle Provider</NavLink></li>
                </ul>
                
                
                }
                
            </div>
          </div>

              <div className="wrapper" >

                {/*User Routes*/}
                  <Route exact path="/profile" component={Profile}></Route>  
                  <Route exact path="/manage-payments" component={ManagePayments}></Route>                          
                   


                  {/*Campaign Manager Routes*/}
                  {/* <Route exact path="/" component={this.state.role === '1' ? Dashboard : Dashboard}></Route>    */}
                  <Route exact path="/" component={Dashboard}></Route>    
                  <Route exact path="/conclusion" component={AnalyticsConclusion}></Route>   
                  <Route exact path="/previous-results" component={AnalyticsPreviousResult}></Route>   
                  <Route exact path="/google-survey" component={AnalyticsGoogleSurvey}></Route>   
                  <Route exact path="/voter-database" component={VoterDatabase}></Route>                                 
                  {/* <Route exact path="/campaign-team" component={CampaignTeam}></Route> */}
                  <Route exact path="/street-captains" component={StreetCaptains}></Route>
                  <Route exact path="/assign-party-leaders" component={AssignPartyLeaders}></Route>
                  <Route exact path="/survey" component={this.state.role === '1' ? Survey:StreetSurvey}></Route>
                  <Route exact path="/survey-question" component={this.state.role === '1' ? SurveyQuestion:StreetSurveyQues}></Route>
                  <Route exact path="/constituency-listing" component={ConstituencyListing}></Route>
                  <Route exact path="/constituency-listing-detail" component={ConstituencyListingDetail}></Route>
                  <Route exact path="/street-listing" component={StreetListing}></Route>
                  <Route exact path="/street-listing-detail" component={StreetListingDetail}></Route>
                  <Route exact path="/talking-points" component={TalkingPoints}></Route>
                  <Route exact path="/talking-view" component={TalkingView}></Route>
                  <Route exact path="/poll-worker" component={this.state.role === '1' ? PollWorker : StreetPollWorker}></Route>
                  <Route exact path="/survey-worker" component={this.state.role === '1' ? SurveyWorker : StreetSurveyWorker}></Route>
                  <Route exact path="/vehicle-provider" component={this.state.role === '1' ? VehicleProvider : StreetVehicleProvider}></Route>
                  <Route exact path="/payment-request" component={this.state.role === '1' ? PaymentRequest : StreetPaymentRequest}></Route>
                  <Route exact path="/payment-request-detail" component={this.state.role === '1' ? PaymentRequestDetail : StreetPaymentRequestDetail}></Route>
                  <Route exact path="/reports" component={Reports}></Route>
                  <Route exact path="/top-registered-voters-pd-wise" component={Top10RegisteredVotersPDwise}></Route>
                  <Route exact path="/ten-new-voters-pd-wise" component={TenNewVotersPDwise}></Route>
                  <Route exact path="/top-pd-partywise" component={Top10PDsPartywise}></Route>
                  <Route exact path="/hourly-slot-voters" component={HourlySlotVoters}></Route>
                  <Route exact path="/winners-and-victory-margin" component={WinnersandVictoryMargin}></Route>
                  <Route exact path="/print-format-major-concern" component={PrintFormatMajorConcern}></Route>
                  <Route exact path="/dont-use" component={DontUse}></Route>
                  <Route exact path="/campaign-detail" component={CampaignDetail}></Route>
                  <Route exact path="/campaign-worker-detail" component={CampaignWorkerDetail}></Route>

                  
                  

                  
                  
                  

                  {/*StreetCaptain Routes*/}
                 <Route exact path="/poll-worker-view" component={StreetPollWorkerView}></Route>
                 <Route exact path="/survey-worker-view" component={StreetSurveyWorkerView}></Route>
                 <Route exact path="/vehicle-provider-view" component={StreetVehicleProviderView}></Route>

                 

                  

                  
                  
                 
                  {/* <Route exact path="/documents" component={Documents}></Route>
                  <Route exact path="/survey-result" component={SurveyResult}></Route>
                  <Route exact path="/election-vehicles" component={ElectionVehicles}></Route>
                  <Route exact path="/volunteer-section" component={VolunteerSection}></Route>
                  <Route exact path="/voter-list-comparison" component={VoterListComparison}></Route>
                  <Route exact path="/prediction-result" component={PredictionResult}></Route> */}
              </div>      
          </div>       
        
        
          </div>
            
        </div>
        </Router>

      </div>
    )
  }
}

export default SuperDashboard;
