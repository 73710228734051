
/**
 * Voter Reducers
 */
import isEmpty from '../validation/is-empty';
import {
    LIST_VOTER,
    STREET_VOTER,
    HOUSE_VOTER,
    GET_REQUEST,
    VOTER_COUNT_DATA
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
  loading: false,
  voter_res: [],
  houses_res:[],
  street_res:[],
  total_voters:0,
  total_houses:0,
  total_streets:0,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
      
      case GET_REQUEST:
      return {
        ...state,
        loading:true
      };
    
    case LIST_VOTER:
    return {
        ...state,
        voter_res: action.payload.data,
        total_voters:(action.payload.total)?action.payload.total.voters:0,
        total_houses:(action.payload.total)?action.payload.total.houses:0,
        total_streets:(action.payload.total)?action.payload.total.streets:0,
        loading:false

      };

      case VOTER_COUNT_DATA:
        return {
          ...state,
          total_voters:(action.payload.voters)?action.payload.voters:0,
          total_houses:(action.payload.houses)?action.payload.houses:0,
          total_streets:(action.payload.streets)?action.payload.streets:0
        };

      case HOUSE_VOTER:
      return {
        ...state,
        houses_res: action.payload.data,
        total_voters:(action.payload.total)?action.payload.total.voters:0,
        total_houses:(action.payload.total)?action.payload.total.houses:0,
        total_streets:(action.payload.total)?action.payload.total.streets:0,
        loading:false
      };
      case STREET_VOTER:
      return {
        ...state,
        street_res: action.payload.data,
        total_voters:(action.payload.total)?action.payload.total.voters:0,
        total_houses:(action.payload.total)?action.payload.total.houses:0,
        total_streets:(action.payload.total)?action.payload.total.streets:0,
        loading:false
      };
  default:
      return { ...state };
  }
};
