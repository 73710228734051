import React, { Component } from 'react';

import 'react-input-range/lib/css/index.css';

import 'react-rater/lib/react-rater.css'
import InputRange from 'react-input-range';


class SurveySetp1 extends Component{
  constructor(props) {
    super(props);
 
    this.state = { value: 2, value2:1, };
  }

  render(){

    return (
      <div className="row">
            <div className="col-sm-12">
                <h2 className="text-center">Rate your Government</h2>
            </div>

            <div className="col-sm-12 survey_cont">
                  <div className="form-group">
                      <label>Select on basis of your satisfaction in the range of 1-5.</label>
                      <InputRange maxValue={5} minValue={0} value={this.state.value} onChange={value => this.setState({ value })} />
                  </div>  

                  <div className="form-group mt-10">
                      <label>How likely are you to vote for the same government.</label>
                      <InputRange maxValue={5} minValue={0} value={this.state.value2} onChange={value2 => this.setState({ value2 })} />
                  </div> 

                  <div className="form-group mt-10">
                      <label>Overall how happy are you with the government.</label>
                      <div className="rating_emoji">
                        <button>
                            <img src="img/emogi1.png" className="img-fluid" alt="" />
                            <span>Extremely Unhappy</span>
                        </button>
                        <button>
                            <img src="img/emogi2.png" className="img-fluid" alt="" />
                            <span>Unhappy</span>
                        </button>
                        <button>
                            <img src="img/emogi3.png" className="img-fluid" alt="" />
                            <span>Neutral</span>
                        </button>
                        <button>
                            <img src="img/emogi4.png" className="img-fluid" alt="" />
                            <span>Happy</span>
                        </button>
                        <button>
                            <img src="img/emogi5.png" className="img-fluid" alt="" />
                            <span>Extremely Happy</span>
                        </button>
                      </div>
                  </div>  

            </div>
        </div>
    )
  }
}

export default SurveySetp1;
