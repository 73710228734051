import React, { Component } from 'react';

import Modal from 'react-modal';
import 'react-input-range/lib/css/index.css';

import 'react-rater/lib/react-rater.css'
import InputRange from 'react-input-range';


const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

class Documents extends Component{
  constructor(props) {
    super(props);
 
    this.state = { 
        tofuIsReady:false,
        modalIsOpen: false,
        value: 2,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }


  openModal() {
    this.setState({modalIsOpen: true});
  }
 
  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 
    render(){

    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
                      <h1 className="text-primary ">Contact Details</h1>
                  </div>
                  <div className="col-sm-6">
                        <div className="d-flex pull-right">
                            <div className="title_right_sec">
                                <button className="btn_cust btn-primary pull-left mt-3 mr-3" onClick={this.openModal}>+ ADD</button>
                            </div>
                        </div>
                  </div>
            </div>   

            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={false}
            style={customStyles}
            contentLabel="Contact Modal"
            >    
                <div className="modal_head">
                    <h1 className="text-primary ">Fill out the following form</h1>
                    <button className="btn_close" onClick={this.closeModal}>x</button>
                </div>
                
                <div className="modal_body form-horizontal">
                    <div className="row mb-3">
                        <label className="control-label col-sm-3 pr-0" htmlFor="name">Name:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="name" placeholder="Enter name"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="control-label col-sm-3 pr-0" htmlFor="name">Email:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="name" placeholder="Enter email"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="control-label col-sm-3 pr-0" htmlFor="mobile">Mobile No:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="mobile" placeholder="Enter mobile no"/>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="control-label col-sm-3 pr-0" htmlFor="Profession">Profession:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="Profession" placeholder="Enter Profession"/>
                        </div>
                    </div>     


                    <div className="row mb-5">
                      <div className="col-sm-12">
                          <label>Rate your government out of 5:</label>
                          <InputRange maxValue={5} minValue={0} value={this.state.value} onChange={value => this.setState({ value })} />
                      </div>    
                    </div>  

                    <div className="row mb-3 mt-4">
                      <div className="col-sm-12">
                          <label>How Satisfied are you with your government:</label>
                          <div className="rating_emoji">
                            <button>
                                <img src="img/emogi1.png" className="img-fluid" alt="" />
                                <span>Extremely Unhappy</span>
                            </button>
                            <button>
                                <img src="img/emogi2.png" className="img-fluid" alt="" />
                                <span>Unhappy</span>
                            </button>
                            <button>
                                <img src="img/emogi3.png" className="img-fluid" alt="" />
                                <span>Neutral</span>
                            </button>
                            <button>
                                <img src="img/emogi4.png" className="img-fluid" alt="" />
                                <span>Happy</span>
                            </button>
                            <button>
                                <img src="img/emogi5.png" className="img-fluid" alt="" />
                                <span>Extremely Happy</span>
                            </button>
                          </div>
                      </div>    
                    </div>  


                    <div className="row mb-3">
                        <label className="control-label col-sm-3 pr-0" htmlFor="Comments">Comments:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="Comments" placeholder="Enter Comments"/>
                        </div>
                    </div> 

                    <div className="row mb-3">
                        <div className="offset-3 col-sm-9">
                            <button className="btn btn-sm btn-success">Submit</button> 
                        </div>
                    </div>

                </div>

            </Modal>


            <div className="row">
                <div className="col-sm-5 offset-1">
                      <div className="any_detail mt-3">
                          <ul className="list-group">
                            <li className="list-group-item"><span className="text-primary">Name:</span><b className="pull-right"> Pushpendra Singh</b></li>
                            <li className="list-group-item"><span className="text-primary">Email:</span><b className="pull-right"> pushpendrasingh@gmail.com</b></li>
                            <li className="list-group-item"><span className="text-primary">Mobile:</span><b className="pull-right"> +91-8563042698</b></li>
                            <li className="list-group-item"><span className="text-primary">Profession:</span><b className="pull-right"> Front End Developer</b></li>     
                            <li className="list-group-item"><span className="text-primary">Rating:</span><b className="pull-right"> 4/5</b></li>     
                            <li className="list-group-item"><span className="text-primary">Satisfaction Level:</span><b className="pull-right"> Happy</b></li>     
                            <li className="list-group-item"><span className="text-primary">Comments:</span><b className="pull-right"> No Comments</b></li>                          
                          </ul>  
                      </div>
                </div>
                <div className="col-sm-5">
                        <div className="any_detail mt-3">
                          <ul className="list-group">
                            <li className="list-group-item"><span className="text-primary">Name:</span><b className="pull-right"> Pushpendra Singh</b></li>
                            <li className="list-group-item"><span className="text-primary">Email:</span><b className="pull-right"> pushpendrasingh@gmail.com</b></li>
                            <li className="list-group-item"><span className="text-primary">Mobile:</span><b className="pull-right"> +91-8563042698</b></li>
                            <li className="list-group-item"><span className="text-primary">Profession:</span><b className="pull-right"> Front End Developer</b></li>     
                            <li className="list-group-item"><span className="text-primary">Rating:</span><b className="pull-right"> 4/5</b></li>     
                            <li className="list-group-item"><span className="text-primary">Satisfaction Level:</span><b className="pull-right"> Happy</b></li>     
                            <li className="list-group-item"><span className="text-primary">Comments:</span><b className="pull-right"> No Comments</b></li>                          
                          </ul>
                      </div>
                </div>
            </div> 


        </div>


      </div>
    )
  }
}

export default Documents;
