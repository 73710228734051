import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-modal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// redux action
import * as actions from '../actions';
import { connect } from 'react-redux';


 const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


class SurveyQuestions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            modalIsOpen2: false,
            input: "",
            multipleChoiceValues:[],
            options: [],
            survey_id: (props.location.state) ? props.location.state.survey_id : '',
            survey_name: (props.location.state) ? props.location.state.survey_name : '',
            description: (props.location.state) ? props.location.state.description : '',
            is_active: (props.location.state) ? props.location.state.is_active : '',
            survey_ques: [],
            question_text:"",
            errors:{}
        };

        this.openModal = this.openModal.bind(this);
        this.openModal2 = this.openModal2.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeModal2 = this.closeModal2.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addOptions = this.addOptions.bind(this);
        // this.removeOption = this.removeOption.bind(this);    
        this.openModal3 = this.openModal3.bind(this);
        this.closeModal3 = this.closeModal3.bind(this);
    }

    //Call Api for consituency List and survey List
    componentDidMount() {
        this.props.listSurveyQuestion(this.state.survey_id);
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.survey_ques !== prevState.survey_ques) {
            return { survey_ques: nextProps.survey_ques };
        }
        //Get Polling Division Name

        else return null;
    }

    openModal() {
        this.setState({ modalIsOpen: true,options:[],question_text:'',question_type:''});
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModal2(data) {
        this.setState({
          modalIsOpen2: true
        });
      }
    
      closeModal2() {
        this.setState({
          modalIsOpen2: false
        });
      }


    // Third Model Open
    openModal3(data){
        this.setState({
            modalIsOpen3: true,
            multipleChoiceValues : data
        })
    }

    // Third Model Close
    closeModal3() {
        console.log('Yes bro whats up !')
        this.setState({
            modalIsOpen3: false
        })
    }

    handleChange(event) {
        this.setState({ input: event.target.value });
    }

    addOptions() {
        this.setState({ options: [...this.state.options, this.state.input] });
        this.setState({ input: "" });
    }

    //delete Survey
    deleteSurvey=()=>{
        if (window.confirm('Are you sure to delete this Survey?')) {
            const deletedata={
                surveyID:this.state.survey_id  
            }
            this.props.deleteSurvey(deletedata,this.props.history);
        }  
    }
    
   //Update Survey Status
    updatesurveyStatus=()=>{
        const updatedata={
            surveyID:this.state.survey_id,
            is_active:this.state.is_active  
        }
     
        this.props.UpdateSurveyStatus(updatedata);
        this.setState({
            modalIsOpen2: false
          });
    }
    
    deleteQues = (quesID) => {

        if (window.confirm('Are you sure to delete this record?')) {
            const deletedata={
                surveyQuestionID:quesID  
            }
            this.props.deleteQues(deletedata, this.state.survey_id);
        }
    }
    //set state on text change
    onChange = e => this.setState({ [e.target.name]: e.target.value, errors: {} });
    
    //Handle Validation
    handleValidation() {

        let errors = {};
        let formIsValid = true;
        const {
            question_text,
            question_type,
            survey_id,
            options
         } = this.state;
         
         if (!question_text) {
            formIsValid = false;
            errors["question_text"] = "Cannot be empty";
        }

        if (!question_type) {
            formIsValid = false;
            errors["question_type"] = "Please select ques type";
        }
    
    
        this.setState({ errors: errors });
        return formIsValid;
    }


    _addQues = () => {
        var answer=[];
        const {
            question_text,
            question_type,
            survey_id,
            options

        } = this.state;
       
        //For stronly aggree and disagree
        if(question_type=='boolean_2')
        {
         answer=[{text:"Strongly Agree"},{text:"Strongly Disagree"}]  
        }

        if(question_type=='boolean')
       {
        answer=[{text:"Yes"},{text:"No"}]  
      }
       
       if(question_type=='multiplechoice')
       {
          options.map((val)=>{
            answer.push({text:val})   
          })
       }

       if(question_type=='text')
       {
          answer=[]
       }
       if (this.handleValidation()) {
        const quesData = {
            question_text: question_text,
            question_type:question_type,
            surveyID:survey_id,
            answers:answer
        }
    
    console.log(quesData);
   this.props.addSurveyQues(quesData,survey_id);
   this.setState({modalIsOpen:false,options:[]});  

       }
    }




    render() {
     
        return (
            <div>

                <div className="container-fluid">
                    <div className="row my-3 px-4 printRow">
                        <div className="col-sm-6 printfleft">
                            <h1 className="text-primary ">{this.state.survey_name}</h1>
                        </div>
                        <div className="col-sm-6 previCtrl printfright">
                            <button class="btn_cust btn-primary pull-right mt-3 ml-2 printHide"  onClick={() => window.print()}>Download and Print Report</button>
                        </div>
                    </div>


                    <div className="row mt-5 px-4">

                        <div className="col-sm-12">
                            <div className="shadow_box p-0">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h5 className="card-title text-primary">{this.state.survey_name}</h5>
                                                <p className="card-text">{this.state.description}</p>
                                                <div className={(this.state.is_active) ? 'status_corner text-success' : 'status_corner text-danger'}>
                                                    <i className="fa fa-circle"></i> {(this.state.is_active) ? 'Live' : 'Not Live'}
                                                </div>
                                            </div>

                                            <div className="col-sm-6 text-right">
                                                <label><input type="checkbox" checked={(this.state.is_active)?'checked':''} onClick={() => this.setState({is_active:!this.state.is_active})}/> Set Live</label>
                                                <Link className="btn_cust btn-primary mx-3" to="#" onClick={() => this.openModal2()}><i className="fa fa-edit"></i></Link>
                                                <Link className="btn_cust btn-danger" onClick={() => this.deleteSurvey()} to="#"><i className="fa fa-trash"></i></Link>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                    <div className="row mt-5 px-4">
                        <div className="col-sm-6">
                            <h3 className="text-primary">Questions</h3>
                        </div>
                        <div className="col-sm-6">
                            <div className="title_right_sec">
                                <Link className="btn_cust btn-primary" to="#" onClick={this.openModal}>+ ADD QUESTION</Link>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-3 px-4">

                        {
                            (this.state.survey_ques.length > 0) ?
                                this.state.survey_ques.map((quesval) => {
                                    console.log(quesval)
                                    return (
                                        <div className="col-sm-6 mb-4">
                                            <div className="shadow_box p-0">
                                                <div className="card">
                                                    <div className="card-body">

                                                        <div className="row">
                                                            <div className="col-sm-10">
                                                                <h6 className="card-title text-primary">Q. {quesval.question_text}</h6>
                                                                <p className="card-text">A. {(quesval.question_type == "boolean") ? 'Yes/No' : quesval.question_type === "multiplechoice" ? <Link  to="#" onClick={()=>this.openModal3(quesval.answers)}>more</Link> : quesval.question_type==="boolean_2"?"Strongly Agree - Strongly Disagree":quesval.question_type}</p>
                                                            </div>
                                                            <div className="col-sm-2 text-center"><Link className="btn_cust btn-danger" to="#" onClick={() => this.deleteQues(quesval._id)}><i className="fa fa-trash"></i></Link></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : null
                        }

                    </div>



                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                            <h1 className="text-primary text-bold">Question</h1>
                            <button className="btn_close" onClick={this.closeModal}>x</button>
                        </div>

                        <div className="modal_body form-horizontal">

                            <div className="form-group mb-3">
                                <label className="control-label" htmlFor="name">Question</label>
                                <input type="text" className="form-control" id="name" name="question_text"
                                    onChange={this.onChange}
                                    placeholder="Enter your question" />
                             <span className="error">{this.state.errors["question_text"]}</span>
                            </div>

                            <div className="form-group mb-3 mt-3">
                                <label className="control-label font-weight-bold">Question Types</label>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="Radios" id="radio1" value="multiplechoice" onChange={(e) => this.setState({ question_type: e.target.value })} />
                                    <label className="form-check-label" htmlFor="radio1">Multiple choice</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="Radios" id="radio2" value="boolean" onChange={(e) => this.setState({ question_type: e.target.value })} />
                                    <label className="form-check-label" htmlFor="radio2">Yes/No</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="Radios" id="radio3" value="text" onChange={(e) => this.setState({ question_type: e.target.value })} />
                                    <label className="form-check-label" htmlFor="radio3">Text</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="Radios" id="radio4" value="boolean_2" onChange={(e) => this.setState({ question_type: e.target.value })} />
                                    <label className="form-check-label" htmlFor="radio4">Strongly Agree - Strongly Disagree</label>
                                </div>
                                <span className="error">{this.state.errors["question_type"]}</span>
                            </div>

                            <div className="form-group mb-3 mt-3">
                                <label className="control-label font-weight-bold">Multiple Choice Options</label>

                                <div className="row mb-2">
                                    <div className="col-sm-9">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="name" 
                                            placeholder="Enter Option Name" 
                                            onChange={this.handleChange} 
                                            value={this.state.input}
                                            disabled={this.state.question_type === "multiplechoice" ? false : true}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <button className="btn_cust btn-primary btn-block" onClick={this.addOptions} style={{ borderRadius: '0' }}>Add</button>
                                    </div>
                                </div>

                                {this.state.options.map((item, i) =>
                                    (<div className="add_opt" key={i}>
                                        <p className="mt-3">{item}</p>
                                        <button className="btn btn-sm btn-default"><i className="fa fa-times"></i></button>
                                    </div>)
                                )}

                            </div>



                            <hr />
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <button className="btn btn-sm btn-success pull-right" onClick={this._addQues}>Save</button>
                                </div>
                            </div>

                        </div>

                    </Modal>



                    <Modal
                        isOpen={this.state.modalIsOpen2}
                        onRequestClose={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                        <h1 className="text-primary "> Update Live Status </h1>
                        <button className="btn_close" onClick={this.closeModal2}>
                            {' '}
                            x{' '}
                        </button>
                        </div>

                        <div className="modal_body form-horizontal">
                        <div className="mb-3">
                            <label>
                            {`Are you sure, do you want to change the status of this survay ?`}
                            </label>
                        </div>

                        <div className="row mb-3">
                            <div className="offset-4 col-sm-8">
                            <button
                                className="btn btn-sm btn-success"
                                onClick={this.updatesurveyStatus}
                            >
                                {' '}
                                Yes{' '}
                            </button>
                            </div>
                        </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={this.state.modalIsOpen3}
                        onRequestClose={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                            <h1 className="text-primary ">Multiple Choice</h1>
                            <button className="btn_close" onClick={this.closeModal3}>x</button>
                        </div>

                        <div className="modal_body form-horizontal">


                            <div className="row mb-3">
                                
                                <div className="col-sm-8">
                                    {this.state.multipleChoiceValues.map(data => <p>{data.text}</p>)}
                                </div>
                            </div>

                            

                        </div>

                    </Modal>



                </div>

                <NotificationContainer />

            </div>
        )
    }
}

const mapStateToProps = ({ survey }) => {

    const { survey_ques } = survey;
    return { survey_ques }
}
export default connect(mapStateToProps, actions)(SurveyQuestions);
