/*
 src/reducers/ActiveUserReducer.js
*/
/**
 * Auth User Reducers
 */
import isEmpty from '../validation/is-empty';
import {
  ADD_SURVEY,
  LIST_SURVEY,
  LIST_SURVEY_QUES,
  STREET_LIST,
  STREET_All_LIST,
  EXCEL_CONCERN_LIST,
  TEN_NEW_VOTERS_PD_WISE,
  LOADER,
  TOP_10_PARTY_WISE_DATA,
  LOADERTWO,
  TOP_10_REGISTERED_VOTERS_PD_WISE,
  CONSTITUENCY_WISE_WINNING_MARGIN
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
  loading: true,
  survey_res: [],
  survey_ques: [],
  street_list: [],
  street_all_list: [],
  excel_concer_data: [],
  ten_new_voters_pd_wise: [],
  top_10_party_wise_data: [],
  top_10_registered_voters_PD_wise: [],
  loading10NewVoters: true,
  loadingPartyWise: true,
  constituency_wise_winning_margin_data: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SURVEY:
      console.log(action.payload);
      //debugger;
      return {
        ...state,
        survey_res: action.payload
      };

    case LIST_SURVEY_QUES:
      return {
        ...state,
        survey_ques: action.payload
      };
    case STREET_LIST:
      // debugger
      console.log(action.payload)
      return {
        ...state,
        street_list: action.payload
      };

    case STREET_All_LIST:
      return {
        ...state,
        street_all_list: action.payload
      };

    case EXCEL_CONCERN_LIST:
      return {
        ...state,
        excel_concer_data: action.payload
      };

    case TEN_NEW_VOTERS_PD_WISE:
      return {
        ...state,
        ten_new_voters_pd_wise: action.payload, loading10NewVoters: false
      };

    case TOP_10_PARTY_WISE_DATA:
      return {
        ...state,
        top_10_party_wise_data: action.payload, loadingPartyWise: false
      };

    case TOP_10_REGISTERED_VOTERS_PD_WISE:
      return {
        ...state,
        top_10_registered_voters_PD_wise: action.payload,
      };

    case CONSTITUENCY_WISE_WINNING_MARGIN:
      return {
        ...state,
        constituency_wise_winning_margin_data: action.payload,
      };

    case LOADER:
      return {
        ...state,
        loading10NewVoters: true
      };

    case LOADERTWO:
      return {
        ...state,
        loadingPartyWise: true
      };

    default:
      return { ...state };
  }
};
