import React, { Component } from 'react';
import AmCharts from "@amcharts/amcharts3-react";

class Serial extends Component{

  render(){
    const config = {
      "type": "serial",
      "theme": "none",
      "dataProvider": [ {
        "country": "1 Star",
        "visits": 90,
        "color": "#58b0cf",
      }, {
        "country": "2 Star",
        "visits": 75,
        "color": "#58b0cf",
      }, {
        "country": "3 Star",
        "visits": 85,
        "color": "#58b0cf",
      }, {
        "country": "4 Star",
        "visits": 48,
        "color": "#58b0cf",
      },{
        "country": "5 Star",
        "visits": 48,
        "color": "#58b0cf",
      }],
      "valueAxes": [ {
        "gridColor": "#FFFFFF",
        "gridAlpha": 0.2,
        "dashLength": 0
      } ],
      "gridAboveGraphs": true,
      "startDuration": 1,
      "colorField": "#ff0000",
      "graphs": [ {
        "fillColorsField": "color",
        "balloonText": "[[category]]: <b>[[value]]</b>",
        "fillAlphas": 0.8,
        "lineAlpha": 0.2,
        "type": "column",
        "valueField": "visits"
      } ],
      "chartCursor": {
        "categoryBalloonEnabled": false,
        "cursorAlpha": 0,
        "zoomable": false
      },
      "categoryField": "country",
      "categoryAxis": {
        "gridPosition": "start",
        "gridAlpha": 0,
        "tickPosition": "start",
        "tickLength": 20
      },
    };

    return (
      <div>
        <AmCharts.React className="amchart_serial" options={config} />
      </div>
  )

  }
}

export default Serial;
