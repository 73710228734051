/*
 This Action for add survey and add suvey questions.
*/
import jwt_decode from "jwt-decode";
import { NotificationManager } from 'react-notifications';
import {
  LIST_VOTER_POLL, //LIST_VOTER
  VOTED_VOTER, //STREET_VOTER
  NOT_VOTED_VOTER,  //HOUSE_VOTER
  API_URl,
  GET_REQUEST_POLL, //GET_REQUEST
  VOTER_COUNT_DATA_POLL, // VOTER_COUNT_DATA
  VOTER_COUNT_DATA_POLL_NULL //IN CASE OF COUNT IS NULL

} from './types';

// api
import api from '../api';
import axios from 'axios';



//listVoters
export const getVoters_poll = (VotersFilters) => {

  //debugger
  return async (dispatch) => {
    dispatch({ type: GET_REQUEST_POLL });
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      }; //change only the API you are getting
      let voterList = await axios.post(API_URl + "campaignmanagers/getPollDayVotingList", VotersFilters, header, { timeout: 15000 });

      if (voterList.status === 200) {
        if (VotersFilters.waitingForVote == 7) {
          console.log(voterList.data)
          dispatch({ type: NOT_VOTED_VOTER, payload: voterList.data });
          dispatch(getVoters_count_poll(VotersFilters))
        }
         if (VotersFilters.voted == 7) {
          console.log(voterList.data)
          dispatch({ type: VOTED_VOTER, payload: voterList.data });
          dispatch(getVoters_count_poll(VotersFilters))
        }
        if (VotersFilters.notVoted == 8) {
          console.log(voterList.data)
          dispatch({ type: LIST_VOTER_POLL, payload: voterList.data });
          dispatch(getVoters_count_poll(VotersFilters))
        }

      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {

      if (VotersFilters) {
        dispatch({ type: VOTER_COUNT_DATA_POLL_NULL, payload: { data: [] } });
        dispatch(getVoters_count_poll(VotersFilters))
      }
      // else {
      //   dispatch({ type: LIST_VOTER_POLL, payload: { data: [] } });
      // }
      NotificationManager.error("Unable to Fetch !");
    }
  }
}




//listVoters_count
export const getVoters_count_poll = (VotersFilters) => {

  return async (dispatch) => {
    //dispatch({ type: GET_REQUEST_POLL });
    try {
      let header = {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken"),
          'Content-Type': 'application/json'
        }
      };
      let voterList = await axios.post(API_URl + "campaignmanagers/getPollDayCount", VotersFilters, header);

      if (voterList.status === 200) {
        console.log(voterList.data.data)
        dispatch({ type: VOTER_COUNT_DATA_POLL, payload: voterList.data })
      }
      else {
        NotificationManager.error("Unable to Fetch !");
      }
    } catch (error) {

      NotificationManager.error("Unable to Fetch !");
    }
  }
}                                                                                                                                                                                                                                                                                                                                                                                                                                                         

