import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Modal from 'react-modal';

import {Tabs, Tab} from 'react-bootstrap-tabs';
import Toggle from 'react-toggle'
import "react-toggle/style.css"


const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

class PollWorkerView extends Component{
    constructor() {
        super();     
        this.state = {
          modalIsOpen: false,
          role: '1'
        };
             
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
      }
     
      openModal() {
        this.setState({modalIsOpen: true});
      }     
     
      closeModal() {
        this.setState({modalIsOpen: false});
      }

 
    render(){

    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
                      <h1 className="text-primary ">Poll Worker</h1>
                  </div>
            </div>   



            <div className="row mt-5 px-4 leader_row">


                <div className="col-sm-8">
                    <div className="shadow_box leader_box p-0 pt-3">
                        <div className="card">                          
                            <div className="card-body p-0 px-4">
                                <div className="row">
                                    <div className="col-sm-3 mb-2 text-center">
                                        <img className="card-img-top rounded-circle mx-auto img-thumbnail" src="../img/candi_img.png" alt="Card image cap" />
                                    </div>

                                    <div className="col-sm-4">
                                        <h5 className="card-title text-primary">Dr. Jack</h5>
                                        <p className="card-text"><strong>Campaign Team Name</strong></p>
                                        <p className="card-text">8112854511</p>
                                        <p className="card-text">jack255@gmail.com</p>
                                        <p className="card-text">Address wil be here</p>
                                    </div>

                                    <div className="col-sm-3 mb-2">                                      

                                        <Link className="btn_cust btn-danger text-white" to="#" onClick={this.openModal2}><i className="fa fa-trash"></i></Link>
                                        <p className="text-success mt-3"><strong>Active Since</strong></p>
                                        <p>15 August, 2019</p>

                                        <p className="text-danger"><strong>Last Active</strong></p>
                                        <p>12 August, 2019</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div> 
            
                
            <h3 className="mt-5 px-4">Election Date : 20 September, 2019</h3>
           


            <div className="row px-4 mt-4">

                <div className="col-sm-8">
                <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                        <Tab label="Assign Data">
                            <div className="shadow_box mb-5 mt-3 p-0">
                                <div className="card">
                                    <ul className="list-group list-group-flush listContainer">
                                        <li className="list-group-item">   
                                            <button className="btn_trans">                                        
                                                <p className="font-weight-bold">Ava Langdon</p>
                                                <p>1637 Mango Street Apt 190 San Fransico California  94111</p>
                                                <p>Female</p>
                                            </button>     
                                        </li>
                                        <li className="list-group-item">                                            
                                            <button className="btn_trans">                                        
                                                <p className="font-weight-bold">Ava Langdon</p>
                                                <p>1637 Mango Street Apt 190 San Fransico California  94111</p>
                                                <p>Female</p>
                                            </button> 
                                        </li>
                                    </ul>
                                </div>
                            </div>


                               
                        </Tab>

                        <Tab label="Verify Data">
                            
                            <div className="shadow_box mb-5 mt-3 p-0">
                                    <div className="card">
                                        <ul className="list-group list-group-flush listContainer">
                                            <li className="list-group-item">
                                                <button className="btn_trans">     
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <p className="font-weight-bold">4674 Pineapple Street</p>
                                                            <p>1637 Mango Street Apt 190 San Fransico California  94111</p>            
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <p className="text-success text-right pull-right"><strong>20 August, 2019</strong> <br/>5:50 PM </p>                                                        
                                                        </div>
                                                    </div>
                                                </button>        
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                        </Tab>
                        

                        <Tab label="Unverified Data">

                                <div className="shadow_box mb-5 mt-3 p-0">
                                    <div className="card">
                                        <ul className="list-group list-group-flush listContainer">
                                            <li className="list-group-item">
                                                <button className="btn_trans btn_trans_flex">
                                                    <div>
                                                        <p className="font-weight-bold">Apple Avenue</p>
                                                        <p>Richmond California  94111</p>
                                                    </div>
                                                    <div className="right_side_val">
                                                        <div className="rvalue bg-secondary">0</div>
                                                        <div className="rvalue bg-success">2</div>
                                                        <div className="rvalue bg-primary">1</div>
                                                        <div className="rvalue bg-danger">0</div>
                                                    </div>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                        </Tab>
                    </Tabs>
                </div>

            </div>



        </div>


      </div>
    )
  }
}

export default PollWorkerView;
