import React, { Component } from 'react';
import Header from '../Includes/header';

import Serial from '../Components/Serial2';



class LiveTrends extends Component{
    constructor(props){
        super(props);
        this.state = {
            hideSidebar: true
        }
      }

      showPanel(){
        this.setState({hideSidebar: !this.state.hideSidebar})
     }


  render(){
    let ToggleID = this.state.hideSidebar ? "hide" : "show";

    return (
      <div>
        <div className="nav_panel">
             <button className="btn" onClick={this.showPanel.bind(this)}><i className="fa fa-bars"></i></button>
            <h3>Google Trends</h3>
        </div>
        <div className="sideBar" id={ToggleID}>
            <Header />
        </div>

          <div className="container-fluid page page_live_count" style={{marginTop:'7rem'}}>
            
              <div className="trend_cont">
                  <h1 className="live_trend_head">The Next PM: <span>Kamla Persad</span> or <span>Keith Rowley</span>?</h1>
                  <p>Kamla Persad Bissesar seems to enjoy more popularity in the southern states compared to Keith Christopher Rowley.</p>
              </div>

              <div className="row">

                  <div className="col-sm-12 col-lg-10 offset-1 sm-m-0 sm_100 text-center">
                        <div className="live_trend_chart">
                            <Serial />
                        </div>

                        <div className="trend_candi">
                            <div className="candi_box">
                                <img className="img-fluid" src="../img/Kamla_Persad_Bissesar.jpg" />
                                <div className="candi_name">Kamla Persad Bissesar <p>UNC</p></div>
                            </div>   
                            <div className="candi_box">
                                <img className="img-fluid" src="../img/KeithRowley.jpg" />
                                <div className="candi_name">Keith Christopher Rowley <p>PNM</p></div>
                            </div>                          
                        </div>
                  </div>

              </div>
          </div>
      </div>
    )
  }
}

export default LiveTrends;
