import React, { Component } from 'react';
import {Link} from 'react-router-dom';


class AssignPartyLeaders extends Component{
 
    render(){

    return (
      <div>
        <div className="container-fluid">
            <div className="row my-3 px-4">
                  <div className="col-sm-6">
                      <h1 className="text-primary ">Party Leaders</h1>
                  </div>
                  <div className="col-sm-6">
                        <div className="form-group mt-3">
                            <select className="form-control pull-right" id="const_sele">
                                <option>Select a Constituency</option>
                                <option>Constituency 1</option>
                                <option>Constituency 2</option>
                                <option>Constituency 3</option>
                                <option>Constituency 4</option>
                            </select>
                        </div>
                  </div>
            </div>   


            <div className="container-fluid mt-4">
              <div className="row">

                  <div className="col-sm-5 form_field sm-50">
                        <h5>No party leader assigned, Assign One:</h5>
                        
                        <form>
                            <div className="form-group mt-4">
                                <label htmlFor="Constituency">Name*</label>
                                <input name="Constituency" id="Constituency" type="text" className="form-control" placeholder="" />
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="National">Mobile No*</label>
                                <input name="National" id="National" type="text" className="form-control" placeholder="" />
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="address">Address*</label>
                                <textarea className="form-control" id="address" placeholder="" rows="4"></textarea>
                            </div>
                            <div className="form-group mt-4">
                                <label htmlFor="Image">Image</label>
                                <input name="Image" id="Image" type="file" className="form-control" placeholder="" />
                            </div>

                            <input type="submit" className="btn btn-success w-100 mt-2" value="Assign" style={{fontSize:'26px'}} onClick={e => this.onSubmit(e)}/>
                        </form>
                  </div>

              </div>
          </div>


        </div>


      </div>
    )
  }
}

export default AssignPartyLeaders;
