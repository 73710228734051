import React, { Component } from 'react';
import Header from '../Includes/header';


import SurveyStep1 from './surveyStep1';
import SurveyStep2 from './surveyStep2';


class Survey extends Component{
  constructor(props) {
    super(props);
 
    this.state = { 
      value: 2, value2:1, hideSidebar: true,
      stepResults: true
    };
  }
  

  showPanel(){
    this.setState({hideSidebar: !this.state.hideSidebar})
  }



handleChange = input => event => {
    this.setState({ [input] : event.target.value })
}

onNext=()=> {
  this.setState({ stepResults: false });
}
onBack=()=> {
  this.setState({ stepResults: true });
}


  

  render(){
    let ToggleID = this.state.hideSidebar ? "hide" : "show";

    return (
      <div>
        <div className="nav_panel">
            <button className="btn" onClick={this.showPanel.bind(this)}><i className="fa fa-bars"></i></button>
            <h3>Survey</h3>
        </div>
        <div className="sideBar" id={ToggleID}>
            <Header />
        </div>

          <div className="container mt-4 mb-4" >  
              { this.state.stepResults ?<SurveyStep1 />: <SurveyStep2 /> }
              
              { this.state.stepResults ?'':<button className="btn btn-primary mt-3 mr-3" onClick={this.onBack}>Back</button> }
              <button className="btn btn-primary mt-3" onClick={this.onNext}>Save And Continue </button>
          </div>
      </div>
    )
  }
}

export default Survey;
