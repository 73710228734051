/**
 * App Redux Action Types
 */
//export const API_URl = 'http://13.235.92.27:3000/api/';
export const API_URl = 'https://emsapi.shopptrinidad.com/api/';
// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const ELECTION_RESULT = 'election_result';
export const PROFILE_INFO = 'PROFILE_INFO';
export const SET_UPDATED_YEAR_VALUE = 'set_updated_year_value'
export const SET_MARGINAL_COLOR = 'set_marginal_color'
export const TRENDS_RESULTS = 'trends_results'
export const SET_SEARCH_STRING_VALUE = 'set_search_string_value'
export const TRENDS_RESULTS_1 = 'trends_results_1'
export const DASHBOARD_DATA_RESULTS = 'dashboard_data_results'


//New Key Added
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';

//Campaign Team
export const LIST_CONSTITUENCY = 'LIST_CONSTITUENCY';
export const ADD_TEAM = 'ADD_TEAM';
export const LIST_TEAM = 'LIST_TEAM';
export const LIST_POLLING_DIV = 'LIST_POLLING_DIV';
export const LIST_STREET_CAPTAINS_TEAM = 'LIST_STREET_CAPTAINS_TEAM';
export const CLEAR_DATA = 'CLEAR_DATA';
export const LIST_POLL_WORKER = 'LIST_POLL_WORKER'
export const LIST_SURVEY_WORKER = 'LIST_SURVEY_WORKER'
export const LIST_VEHICLE_PROVIDER = 'LIST_VEHICLE_PROVIDER'
export const LIST_STREET_BASED_ON_POLLING_DIVISION = 'LIST_STREET_BASED_ON_POLLING_DIVISION'

//Survey
export const ADD_SURVEY = 'ADD_SURVEY';
export const LIST_SURVEY = 'LIST_SURVEY';
export const LIST_SURVEY_QUES = 'LIST_SURVEY_QUES';
export const STREET_LIST = 'STREET_LIST';
export const STREET_All_LIST = 'STREET_All_LIST';
export const EXCEL_CONCERN_LIST = 'EXCEL_CONCERN_LIST';
export const TEN_NEW_VOTERS_PD_WISE = 'TEN_NEW_VOTERS_PD_WISE';
export const LOADER = 'LOADER'
export const LOADERTWO = 'LOADERTWO'
export const TOP_10_PARTY_WISE_DATA = 'TOP_10_PARTY_WISE_DATA'
export const TOP_10_REGISTERED_VOTERS_PD_WISE = 'TOP_10_REGISTERED_VOTERS_PD_WISE'

export const CONSTITUENCY_WISE_WINNING_MARGIN = 'CONSTITUENCY_WISE_WINNING_MARGIN'


//Voter Database
export const LIST_VOTER = 'LIST_VOTER';
export const HOUSE_VOTER = 'HOUSE_VOTER';
export const STREET_VOTER = 'STREET_VOTER';
export const GET_REQUEST = 'GET_REQUEST';
export const VOTER_COUNT_DATA = 'VOTER_COUNT_DATA';

//Payment sections
export const PAYMENT_REQ_DATA = 'PAYMENT_REQ_DATA';
export const PAYMENT_REQ_BY_SC = 'PAYMENT_REQ_BY_SC';
export const PAYMENT_DETAIL_CM = 'PAYMENT_DETAIL_CM';
export const PAYMENT_RATE = 'PAYMENT_RATE';
export const GET_REQUEST_PAYMENT = 'GET_REQUEST_PAYMENT';




//Major Concern
export const MAJOR_CONCERN = 'MAJOR_CONCERN';

// Hourly time slot POLL

export const LIST_VOTER_POLL = 'LIST_VOTER_POLL';
export const VOTED_VOTER = 'VOTED_VOTER';
export const NOT_VOTED_VOTER = 'NOT_VOTED_VOTER';
export const GET_REQUEST_POLL = 'GET_REQUEST_POLL';
export const VOTER_COUNT_DATA_POLL = 'VOTER_COUNT_DATA_POLL';

export const VOTER_COUNT_DATA_POLL_NULL  = 'VOTER_COUNT_DATA_POLL_NULL';

//temp import .. will remove after the testing..
export const HOURLY_SLOT_VOTERS = 'HOURLY_SLOT_VOTERS';
