import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};


class PaymentRequest extends Component{
    constructor() {
        super();
     
        this.state = {
          modalIsOpen: false,
          seleted_street:""
        };
     
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
      }
     //Get street List 
      async componentDidMount()
      {
       let pollingDivisionID= await localStorage.getItem("pollingDivisionID")
      this.props.getSteet(pollingDivisionID);
      this.props.getPaymentByStreetCaption();  
    }
      
      openModal() {
        this.setState({modalIsOpen: true});
      }
     
      afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
      }
     
      closeModal() {
        this.setState({modalIsOpen: false});
      }

      //on Street changed
      streetChanged = e => {
         this.setState({ seleted_street:e.target.value})
         this.props.getPaymentByStreetCaption(e.target.value);
      };

      payAmt=(userID)=>{
        if (window.confirm('Are you sure want to pay?')) {
        const paydata={
            userID:userID
            }
          console.log(paydata);
         this.props.paymentByStreetCaption(paydata);
        }  
    }  
    
    render(){
        console.log('Street List');
        console.log(this.props.payment_data_sc);
        console.log('++++++++++++')
        const getPaymentreq = () => {
            if(this.props.payment_data_sc)
            {
                if(this.props.payment_data_sc.length>0)
                {
                   let heading="";
                    return this.props.payment_data_sc.map((reqData)=>{
                       if(reqData.userType==5)
                       {
                        return(<div className="col-sm-4">
                        <div className="shadow_box leader_box p-0 pt-3">
                            <div className="card">                          
                                <div className="card-body p-0 px-4">
                                {/* <Link to={{ pathname: '/payment-request-detail', state:{uid:reqData.userID,userType:reqData.userType} }}> */}
                                   <div className="row">
                                        <div className="col-sm-7">
                                            <h5 className="card-title text-primary">{reqData.name}</h5>
                                            <p className="card-text"><strong>Vehicle Provider</strong></p>
                                            <p className="card-text">{reqData.mobile}</p>
                                            <p className="card-text">{reqData.email}</p>
                                           
                                        </div>
    
                                        <div className="col-sm-5 text-right">                                        
                                            <p className="text-danger"><strong>Total Amount</strong></p>
                                            <h3 className="font-weight-bold">$ {reqData.total_amount}</h3>
                                            {reqData.isPaymentDone === 3 ?
                                                <h6>Request already sent!</h6>
                                                :
                                                <button class="btn_cust btn-warning" onClick={()=>this.payAmt(reqData.userID)}>Pay</button>
                                            }
                                        </div>
                                    </div>
    
                                    <div className="bottom_row">
                                        <ul className="block-3">
                                            <li>
                                                <p>Total Trips</p>
                                                <p>0</p>                                            
                                            </li>
                                            <li>
                                                <p>Total KM's</p>
                                                <p>0</p>                                            
                                            </li>
                                            <li>
                                                <p>Total Voters</p>
                                                <p>{reqData.totalVoters}</p>                                            
                                            </li>
                                        </ul>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>)
                       } 
                       if(reqData.userType==4)
                       {
                       
                    return(<div className="col-sm-4">
                    <div className="shadow_box leader_box p-0 pt-3">
                        <div className="card">                          
                            <div className="card-body p-0 px-4">
                            {/* <Link to={{ pathname: '/payment-request-detail', state:{uid:reqData.userID,userType:reqData.userType} }}> */}
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h5 className="card-title text-primary">{reqData.name}</h5>
                                        <p className="card-text"><strong>Survey Worker</strong></p>
                                        <p className="card-text">{reqData.mobile}</p>
                                        <p className="card-text">{reqData.email}</p>
                                    </div>
    
                                    <div className="col-sm-6 text-right">
                                        <p className="text-danger"><strong>Total Amount</strong></p>
                                        <h3 className="font-weight-bold">$ {reqData.total_amount}</h3>
                                        {reqData.isPaymentDone === 3 ?
                                        <h6>Request already sent!</h6>
                                        :
                                        <button class="btn_cust btn-warning" onClick={()=>this.payAmt(reqData.userID)}>Pay</button>
                                        }
                                        
                                    </div>
                                </div>
    
                                <div className="bottom_row">
                                    <ul className="block-3">
                                        <li>
                                            <p>Total Survey</p>
                                            <p>{reqData.total}</p>                                            
                                        </li>
                                        <li>
                                            <p>Total Houses</p>
                                            <p>{reqData.totalHouses}</p>                                            
                                        </li>
                                        <li>
                                            <p>Total Streets</p>
                                            <p>{reqData.totalStreets}</p>                                            
                                        </li>
                                    </ul>
                                </div>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>)
                      }    
                      
                      if(reqData.userType==3)
                      {
                      return(
    
                        <div className="col-sm-4">
                            <div className="shadow_box leader_box p-0 pt-3">
                                <div className="card">                          
                                    <div className="card-body p-0 px-4">
                                    {/* <Link to={{ pathname: '/payment-request-detail', state:{uid:reqData.userID,userType:reqData.userType} }}> */}
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h5 className="card-title text-primary">{reqData.name}</h5>
                                                <p className="card-text"><strong>Poll Worker</strong></p>
                                                <p className="card-text">{reqData.mobile}</p>
                                                <p className="card-text">{reqData.email}</p>
                                            </div>
        
                                            <div className="col-sm-6 text-right">
                                                <p className="text-danger"><strong>Total Amount</strong></p>
                                                <h3 className="font-weight-bold">$ {reqData.total_amount}</h3>
                                                {reqData.isPaymentDone === 3 ?
                                                    <h6>Request already sent!</h6>
                                                    :
                                                    <button class="btn_cust btn-warning"  onClick={()=>this.payAmt(reqData.userID)}>Pay</button>
                                                }
                                            </div>
                                        </div>
        
                                        <div className="bottom_row">
                                            <ul className="block-2">
                                                <li>
                                                    <p>Total Sitting Hours</p>
                                                    <p>0</p>                                            
                                                </li>
                                                <li>
                                                    <p>Total Polling Divisions</p>
                                                    <p>{reqData.totalPollingDivision}</p>                                            
                                                </li>
                                            </ul>
                                        </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>)
                      }  
                    
                    })        
                }
            }
           
        }  
    return (
      <div>

        <div className="container-fluid">           

            <div className="row my-3 px-4 printRow">
                  <div className="col-sm-6 printfleft">
                      <h1 className="text-primary ">Payment Request </h1>
                      <p className="text-primary ">Request by Workers</p>
                  </div>
                  <div className="col-sm-6 printfright">
                        <div className="title_right_sec">
                            <div className="form-group mt-2 ml-3 pull-right">
                                <select className="form-control pull-right" id="const_sele"
                                 onChange={this. streetChanged}
                                >
                                   <option value="">Select a Street</option>
                                        {this.props.street_list.map((data, index) => {
                                        return (
                                            <option value={data._id}>
                                            {data.street_name}
                                            </option>
                                        );
                                        })}
                                </select>
                            </div>


                            <div className="form-group printHide">
                                <button class="btn_cust btn-primary mt-2 ml-3"  onClick={() => window.print()}>Download and Print Report</button>
                            </div>
                            
                            
                        </div>
                  </div>
            </div>

            <hr />

            <div className="row my-3 px-4 printRow">
                  <div className="col-sm-4 printw30">
                      <h4 className="text-primary">Total amount Due</h4>
                  </div>
                  <div className="col-sm-4 text-center printw30">
                      <h4 className="text-primary">No. of People :{this.props.totaluser}</h4>
                  </div>
                  <div className="col-sm-4 text-right printw30">
                      <h4 className="text-primary">$ {this.props.grandTotal}</h4>
                  </div>
            </div>   
            
                <div class="title_right_sec btn_print"><a class="btn_cust btn-primary pull-left mt-3 mr-3" href="javascript:void(0)" onClick={() => window.print()}>Print All</a></div>

            <hr />
            <div className="clearfix">&nbsp;</div>
           

     


            <div className="row mt-5 px-4 leader_row">

            {getPaymentreq()}                            
                
            </div> 


        </div>
        <NotificationContainer />

      </div>
    )
  }
}
const mapStateToProps = ({ survey,payment}) => {
    const {street_list} = survey;
    const {payment_data_sc,grandTotal,totaluser} = payment;
    return {street_list,payment_data_sc,grandTotal,totaluser};
  };
  export default connect(
    mapStateToProps,
    actions
  )(PaymentRequest);