import React, { Component } from 'react';
import Header from '../Includes/header';
import Map from '../Components/Map';
class VoterList extends Component{
    constructor(props){
        super(props);
        this.state = {
            hideSidebar: true
        }
      }
      
      showPanel(){
        this.setState({hideSidebar: !this.state.hideSidebar})
     }

     

  render(){
    let ToggleID = this.state.hideSidebar ? "hide" : "show";


    return (
      <div>
        <div className="nav_panel">
            <button className="btn" onClick={this.showPanel.bind(this)}><i className="fa fa-bars"></i></button>
            <h3>Voter List</h3>
        </div>
        <div className="sideBar" id={ToggleID}>
            <Header />
        </div>

          <div className="container-fluid mt-7 mb-5">
              <div className="row">

                  <div className="col-sm-6 offset-1 sm-m-0 sm-50">
                        <div className="mapshadow_box">
                            <div className="map_cont">
                                {/* <img src="img/trinidadAndTobagoHigh.svg" className="img-fluid" /> */}
                                <Map />
                            </div>
                        </div> 
                  </div>
                
                  <div className="col-sm-4 form_field sm-50">
                      <form>
                          <div className="form-group mt-4">
                              <label htmlFor="Constituency">Constituency Name</label>
                              <input name="Constituency" id="Constituency" type="text" className="form-control" placeholder="" />
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="National">National Identification No</label>
                              <input name="National" id="National" type="text" className="form-control" placeholder="" />
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="Name">Name</label>
                              <input name="Name" id="Name" type="text" className="form-control" placeholder="" />
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="mobile">Mobile No</label>
                              <input name="mobile" id="mobile" type="text" className="form-control" placeholder="" />
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="Comments">Email Id</label>
                              <input name="Contact" id="Contact" type="text" className="form-control" placeholder="" />
                          </div>

                          <input type="submit" className="btn btn-success w-100" value="Search" style={{fontSize:'26px'}} onClick={e => this.onSubmit(e)}/>
                      </form>
                  </div>

              </div>
          </div>
      </div>
    )
  }
}

export default VoterList;
