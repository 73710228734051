import React, { Component } from 'react';
import Header from '../Includes/header';

import Map from '../Components/Map';

class ElectionResults extends Component{
    constructor(props){
    super(props);
    this.state = {
        hideSidebar: true
    }
    }

    showPanel(){
    this.setState({hideSidebar: !this.state.hideSidebar})
    }


  render(){
    let ToggleID = this.state.hideSidebar ? "hide" : "show";

    return (
      <div>
        <div className="nav_panel">
            <button className="btn" onClick={this.showPanel.bind(this)}><i className="fa fa-bars"></i></button>
            <h3>Election Results</h3>
        </div>
        <div className="sideBar" id={ToggleID}>
            <Header />
        </div>

          <div className="container-fluid mt-7">

                <div className="row">
                    <div className="col-sm-6 offset-1 sm-m-0 sm-50">

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Year of Election</label>
                                        <select className="form-control">
                                            <option>Select a Year</option>
                                            <option>2014</option>
                                            <option>2015</option>
                                            <option>2016</option>
                                            <option>2017</option>
                                            <option>2018</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Constituency</label>
                                        <select className="form-control">
                                            <option>Select a Constituency</option>
                                            <option>Constituency 1</option>
                                            <option>Constituency 2</option>
                                            <option>Constituency 3</option>
                                            <option>Constituency 4</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Polling Division Number</label>
                                        <select className="form-control">
                                            <option>Select a Number</option>
                                            <option>Number 1</option>
                                            <option>Number 2</option>
                                            <option>Number 3</option>
                                            <option>Number 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            

                            <div className="mapshadow_box">
                                <div className="map_cont">
                                    {/* <img src="img/trinidadAndTobagoHigh.svg" className="img-fluid" /> */}
                                    <Map />
                                </div>
                            </div>
                    </div>

                    <div className="col-sm-4 col-md-3 sm-50">
                        <div className="parties_const elec_rst">
                            <h2>North Shore:</h2>
                            <h4>Total Voters: 55,273</h4>

                            <div className="part_row mt-3">
                                <div className="row result_head">
                                    <div className="col-sm-4">&nbsp;</div>
                                    <div className="col-sm-2 p-0"><p>Last 50 Years</p></div>
                                    <div className="col-sm-2 p-0"><p>Last 30 Years</p></div>
                                    <div className="col-sm-2 p-0"><p>Last Elections</p></div>
                                    <div className="col-sm-2 p-0"><p>Predicted Result</p></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4"><p>UNC</p></div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_yellow">40</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_yellow">35</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_yellow">30</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_yellow">28</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4"><p>PNM</p></div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_red">29</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_red">31</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_red">30</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_red">29</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4"><p>COP</p></div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_blue">6</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_blue">8</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_blue">10</div>
                                    </div>
                                    <div className="col-sm-2 p-0">
                                        <div className="colorBox color_blue">15</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

          </div>
      </div>
    )
  }
}

export default ElectionResults;
