import React, { Component } from 'react';
import Header from '../Includes/header';
import Map from '../Components/Map';

class PickupService extends Component{
    constructor(props){
        super(props);
        this.state = {
            hideSidebar: true
        }
    }
    
    showPanel(){
        this.setState({hideSidebar: !this.state.hideSidebar})
    }


  render(){
    let ToggleID = this.state.hideSidebar ? "hide" : "show";

    return (
      <div>
        <div className="nav_panel">
            <button className="btn" onClick={this.showPanel.bind(this)}><i className="fa fa-bars"></i></button>
            <h3>Pickup Service</h3>
        </div>
        <div className="sideBar" id={ToggleID}>
            <Header />
        </div>

          <div className="container-fluid"  style={{marginTop:'7rem'}}>
              <div className="row">
                  <div className="col-sm-4 offset-1 form_field sm-m-0 sm-50">
                      <form>
                          <div className="form-group mt-4">
                              <label htmlFor="location">Location</label>
                              <input name="Location" id="location" type="text" className="form-control" placeholder="Enter Location" />
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="Polling_no">Polling Division No</label>
                              <input name="Polling_no" id="Polling_no" type="text" className="form-control" placeholder="Enter Polling Division No" />
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="Number_People">Number of People</label>
                              <input name="Number_People" id="Number_People" type="text" className="form-control" placeholder="Enter Number of People" />
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="time_slot">Time Slot</label>
                              <select className="form-control" id="time_slot">
                                  <option>4:00 PM - 5:00 PM</option>
                                  <option>5:00 PM - 6:00 PM</option>
                                  <option>6:00 PM - 7:00 PM</option>
                                  <option>7:00 PM - 8:00 PM</option>
                              </select>
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="Contact">Contact</label>
                              <input name="Contact" id="Contact" type="text" className="form-control" placeholder="Enter Contact" />
                          </div>
                          <div className="form-group mt-4">
                              <label htmlFor="Comments">Comments</label>
                              <textarea className="form-control" placeholder="Enter Comments" rows="5"></textarea>
                          </div>

                          <input type="submit" className="btn btn-success w-100" value="Submit" style={{fontSize:'26px'}} onClick={e => this.onSubmit(e)}/>
                      </form>
                  </div>


                  <div className="col-sm-6 sm-50">
                        <div className="mapshadow_box">
                            <div className="map_select">
                                <label htmlFor="Contact">Choose your location here</label>
                                <select className="form-control" id="time_slot">
                                    <option>Choose your location here:</option>
                                    <option>Port of Spain</option>
                                    <option>San Fernando</option>
                                    <option>Chaguanas Borough Corporation</option>
                                    <option>Arima</option>
                                </select>
                            </div>
                            <div className="map_cont">
                                <Map />
                            </div>
                        </div> 
                  </div> 
              </div>
          </div>
      </div>
    )
  }
}

export default PickupService;
